import { ClipTask } from "./Threed/Constants";
import * as THREE from 'three'
import { DRONENAKSHA_FEATURES } from '../../Teams';
import { checkURLExist } from '../../ReusableComponents/reusableFunctions';
import { BLOB_URL } from '../../AppConstants.js';
import { OBJLoader } from "./Threed/loaders/OBJLoader.js";
import { MTLLoader } from "./Threed/loaders/MTLLoader.js";
import { get3dPolygonArea, getDistanceIn3d } from "../../ReusableComponents/map/leafletMeasure.js";
import { v4 as uuid, v4 } from 'uuid';
const POLYLINE = "Polyline"
const POLYGON = "Polygon"
const MARKER = "Marker"
const VOLUMEBOX = "VolumeBox"
const hoveredShapeColor = "#FFFFFF"

var utmObj = require('utm-latlng');
var utm = new utmObj();
export class PotreeClass {
    constructor({ potreeContainerDivRef, blob_container, sas_token, task_id, setLoading, setCursor, setMeasurementMenu, setShowVolumeBoxOptions, setSelectedClipTask, setVolumeBoxDrawn }) {
        this.measurements = [];
        this.setVolumeBoxDrawn = setVolumeBoxDrawn;
        this.setSelectedClipTask = setSelectedClipTask;
        this.setMeasurementMenu = setMeasurementMenu;
        this.setShowVolumeBoxOptions = setShowVolumeBoxOptions;
        this.setLoading = setLoading;
        this.blob_container = blob_container;
        this.sas_token = sas_token;
        this.taskId = task_id;
        this.setCursor = setCursor;
        this.cursor = "default";
        this.drawPolyline = false;
        this.drawPolygon = false;
        this.showPointer = true;
        this.drawMarker = false;
        this.loadingMeasurements = true;
        this.modelAvailableData = [];
        this.selectedModel = '';
        this.selectedModel1 = '';
        this.activeModel = '';
        this.activeModel1 = '';
        this.selectedClipTask = "Highlight";
        this.drawnClipVolumes = [];
        this.onHandleEnter = false;
        this.onHandleDown = false;
        this.showTip = false;
        this.potreeContainerDiv = potreeContainerDivRef;
        this.potreeContainerDiv1 = undefined;
        this.unit = 'm';
    }

    loadGeoreferencingOffset = async (cb) => {
        const geoFile = `./tempfiles/odm_georeferencing/coords.txt`;
        const legacyGeoFile = `${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.compare3DMode ? this.selectedModel.task_id : this.taskId}/threeD/georeferencing_model_geo.txt?${this.sas_token}`;
        if (await checkURLExist(legacyGeoFile)) {
            const getGeoOffsetFromUrl = (url) => {
                this.$.ajax({
                    url: url,
                    type: 'GET',
                    error: () => {
                        console.warn(`Cannot find ${url} (not georeferenced?)`);
                        cb({ x: 0, y: 0 });
                    },
                    success: (data) => {
                        const lines = data.split("\n");
                        if (lines.length >= 2) {
                            const [x, y] = lines[1].split(" ").map(parseFloat);
                            cb({ x, y });
                        } else {
                            console.warn(`Malformed georeferencing file: ${data}`);
                            cb({ x: 0, y: 0 });
                        }
                    }
                });
            };

            this.$.ajax({
                type: "HEAD",
                url: legacyGeoFile
            }).done(() => {
                getGeoOffsetFromUrl(legacyGeoFile);
            }).fail(() => {
                getGeoOffsetFromUrl(geoFile);
            });
        } else {
            cb({ x: 0, y: 0 })
        }

    }
    getThreedMeasurements = async (coordinates, activeMeasurement) => {
        return await Promise.all(coordinates.map(async (coord, key) => {
            return ({ lat: coord.lat, lng: coord.lng, x: activeMeasurement.points[key].position.x, y: activeMeasurement.points[key].position.y, elevation: activeMeasurement.points[key].position.z })
        }))
    }
    loadGeoreferencingOffset1 = async (cb) => {
        const geoFile = `./tempfiles/odm_georeferencing/coords.txt`;
        const legacyGeoFile = `${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.selectedModel1.task_id}/threeD/georeferencing_model_geo.txt?${this.sas_token}`;
        if (await checkURLExist(legacyGeoFile)) {
            const getGeoOffsetFromUrl = (url) => {
                this.$.ajax({
                    url: url,
                    type: 'GET',
                    error: () => {
                        console.warn(`Cannot find ${url} (not georeferenced?)`);
                        cb({ x: 0, y: 0 });
                    },
                    success: (data) => {
                        const lines = data.split("\n");
                        if (lines.length >= 2) {
                            const [x, y] = lines[1].split(" ").map(parseFloat);
                            cb({ x, y });
                        } else {
                            console.warn(`Malformed georeferencing file: ${data}`);
                            cb({ x: 0, y: 0 });
                        }
                    }
                });
            };

            this.$.ajax({
                type: "HEAD",
                url: legacyGeoFile
            }).done(() => {
                getGeoOffsetFromUrl(legacyGeoFile);
            }).fail(() => {
                getGeoOffsetFromUrl(geoFile);
            });
        } else {
            cb({ x: 0, y: 0 })
        }

    }

    indicateMarkerInScene = (id) => {
        this.measurements.map(m => {
            if (m.id === id) {
                this.setMeasurementMenu(m)
                m.drawnMeasurement.selectedMarker = true
            }
            else m.drawnMeasurement.selectedMarker = false
        })
    }

    activePointer = () => {
        this.activeMeasurement = undefined
        this.cursor = "default";
        this.setCursor('default')
        this.drawMarker = false;
        this.drawPolyline = false;
        this.drawPolygon = false;
        this.showPointer = true
    }
    saveMeasurement = (measurement) => {
        const activeMeasurement = this.activeMeasurement
        return new Promise(async (resolve, reject) => {
            const coordinates = measurement.type === MARKER ?
                { lat: measurement.coordinates.lat, lng: measurement.coordinates.lng, x: activeMeasurement.points[0].position.x, y: activeMeasurement.points[0].position.y, elevation: activeMeasurement.points[0].position.z }
                : await this.getThreedMeasurements(measurement.coordinates, activeMeasurement)
            let newMeasurement = {
                id: v4(),
                ...measurement,
                coordinates,
                show: true
            }
            this.drawMeasurements([newMeasurement])
            activeMeasurement.visible = false
            resolve()
        })
    }
    startDrawing = async (type) => {

        if (this.activeMeasurement) {
            this.activeMeasurement.showSphere = false
            if (this.activeMeasurement.name !== type) this.activeMeasurement.visible = false;
        }


        if (this.selectedMeasurement) this.setMeasurementEditible(this.selectedMeasurement.id, false)
        this.viewer.scene.removeEventListeners("stop_inserting_measurement")


        this.cursor = "crosshair";
        this.setCursor('crosshair')
        this.drawMarker = type === MARKER ? true : false;
        this.drawPolyline = type === POLYLINE ? true : false;
        this.drawPolygon = type === POLYGON ? true : false;
        this.showPointer = false
        // this.setShowVolumeBoxOptions(false)
        if (type === VOLUMEBOX) {
            this.setShowVolumeBoxOptions(true)
            this.drawVolumeBoxFunc()
        } else {

            this.activeMeasurement = await this.viewer.measuringTool.startInsertion({
                showDistances: true,
                showAngles: false,
                showCoordinates: MARKER ? true : false,
                showArea: type === POLYGON ? true : false,
                closed: type === POLYGON ? true : false,
                maxMarkers: type === MARKER ? 1 : undefined,
                name: type,
                unit: this.unit
            });
            if (!this.activeMeasurement) return;
            if (type === MARKER) {
                this.activeMeasurement.addEventListener("marker_dropped", (e) => {
                    this.saveMeasurement({
                        name: type,
                        type: type,
                        coordinates: utm.convertUtmToLatLng(e.measurement.points[0].position.x, e.measurement.points[0].position.y, this.areaNumber, this.areaCode),
                        description: null,
                        taskId: this.taskId,
                        color: "red"
                    })
                        .then(() => {
                            if (this.drawMarker) this.startDrawing(MARKER);
                            else this.activePointer();
                        })

                })
            } else {
                this.viewer.scene.addEventListener("stop_inserting_measurement", (e) => {
                    this.viewer.scene.removeEventListeners("stop_inserting_measurement")
                    let count = 0
                    let measurement = {
                        name: type,
                        type: type,
                        coordinates: [],
                        description: null,
                        taskId: this.taskId,
                        color: "red"
                    }
                    if (((!e.stoppedFromUI && this.activeMeasurement?.points?.length > 2 || e.stoppedFromUI && this.activeMeasurement?.points?.length > 1) && type === POLYLINE) || ((!e.stoppedFromUI && this.activeMeasurement?.points?.length > 3 || e.stoppedFromUI && this.activeMeasurement?.points?.length > 2) && type === POLYGON)) {
                        e.measure.points.forEach((point) => {
                            measurement.coordinates.push(utm.convertUtmToLatLng(point.position.x, point.position.y, this.areaNumber, this.areaCode))
                            if (++count === e.measure.points.length) {
                                if (!e.stoppedFromUI) measurement.coordinates.pop()
                                this.saveMeasurement(measurement).then(() => {
                                    if (this.drawPolygon) {
                                    }
                                    else if (this.drawPolyline) {
                                    }
                                    else if (this.drawMarker) {
                                    }
                                    else {
                                        this.activePointer()
                                    }
                                })
                            }
                        });
                    }
                    else {
                        if (this.activeMeasurement?.visible)
                            this.activeMeasurement.visible = false; //for remove partially drawn this.measurements
                        if (this.drawPolygon && e.continuosDrawing) { this.startDrawing(POLYGON) }
                        else if (this.drawPolyline && e.continuosDrawing) { this.startDrawing(POLYLINE) }
                        else { this.activePointer() }
                    };

                })
            }
        }
    }

    clickOnMiddleOfCanvas = () => {
        document.querySelector("canvas").dispatchEvent(new MouseEvent("mousedown", {
            clientX: 50,
            clientY: 50
        }));
        document.querySelector("canvas").dispatchEvent(new MouseEvent("mouseup", {
            clientX: 50,
            clientY: 50
        }));
    }

    stopDrawing = async () => {
        return new Promise(async (resolve) => {
            this.setMeasurementMenu(null)
            if (this.selectedMeasurement) this.setMeasurementEditible(this.selectedMeasurement.id, false)

            if (this.activeMeasurement) {
                await this.viewer.dispatchEvent({
                    type: "cancel_insertions"
                });
                if (this.drawPolygon || this.drawPolyline) {
                    this.drawPolygon = false
                    this.drawPolyline = false
                    this.viewer.scene.dispatchEvent({
                        type: 'stop_inserting_measurement',
                        measure: this.activeMeasurement,
                        stoppedFromUI: true
                    });
                    resolve()

                } else {
                    // this is for the close marker
                    this.cursor = "default";
                    this.setCursor('default')
                    this.drawMarker = false;
                    this.drawPolyline = true;
                    this.drawPolygon = false;
                    this.showPointer = false
                    this.startDrawing(POLYLINE).then(async () => {
                        await this.stopDrawing()
                        resolve()
                    })
                }

                if (!(this.compare3DMode)) this.clickOnMiddleOfCanvas()
                // }
            }
            else resolve()
        })
    }

    togglePointsTo = (type) => {
        //type must be elevation or rgba
        this.viewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = type);
    }

    setMeasurementEditible = (id, value) => {
        this.measurements.forEach((measure) => {
            if (measure.id === id && measure.drawnMeasurement) {
                measure.drawnMeasurement.showSphere = value
            }
        })
    }
    deleteMeasurement = (id) => {
        const measurement = this.measurements.find(m => m.id == id)
        this.viewer.scene.removeMeasurement(measurement.drawnMeasurement)
        this.measurements = this.measurements.filter(m => m.id !== id)
    }
    selectMeasurement = (selectById) => {
        if (selectById || this.hoveredMeasurement) {
            if (this.selectedMeasurement) this.setMeasurementEditible(this.selectedMeasurement.id, false); // disable edit  which is enabled by setMeasurementEditible
            // if id pass to this function it wiil select measurement from id otherwise it takes hoverd measurement id if there is any after cicking on the scene.
            this.selectedMeasurement = selectById ? this.measurements.find(measurement => measurement.id === selectById) : this.hoveredMeasurement
            if (this.selectedMeasurement) {
                this.indicateMarkerInScene(null) //indication of all marker will be gone.
                this.setMeasurementColor(this.selectedMeasurement.id, this.selectedMeasurement.color)
                this.setMeasurementEditible(this.selectedMeasurement.id, true)
                this.setMeasurementMenu(this.selectedMeasurement)
            } else {
                this.setMeasurementMenu(null)

            }
        } else {

        }

    }

    updateMeasurement = async (measurement, updatedCoords, redraw) => {
        this.threeJsMeshScene.remove(this.threeJsMeshScene.getObjectByName(measurement.id))
        let measurementObject = {
            ...measurement,
            cut_volume: null,
            fill_volume: null,
            show: true,
            volume: null,
            calc_method: null,
            measurements: undefined,
            is3d: true,
            coordinates: measurement.type === MARKER ? { x: updatedCoords[0].position.x, y: updatedCoords[0].position.y, elevation: updatedCoords[0].position.z, ...(this.areaCode ? { ...utm.convertUtmToLatLng(updatedCoords[0].position.x, updatedCoords[0].position.y, this.areaNumber, this.areaCode) } : {}) } :
                updatedCoords.map((coord) => { return { x: coord.position.x, y: coord.position.y, elevation: coord.position.z, ...(this.areaCode ? { ...utm.convertUtmToLatLng(coord.position.x, coord.position.y, this.areaNumber, this.areaCode) } : {}) } })
        }
        if (measurement.type !== MARKER && !redraw) measurementObject = await this.addThreeJsMeshScene(measurementObject)
        const lengthOrArea = measurementObject.type === POLYGON ? await this.getVolumeAreaString(measurementObject) : measurementObject.type === POLYLINE ? await getDistanceIn3d(measurementObject.coordinates, this.unit, true) : 0
        this.measurements = this.measurements.map((measure) => {
            if (measure.id === measurementObject.id) {
                if (redraw) this.drawMeasurements([measurementObject], measure.id)
                if (measurementObject.type === POLYGON) measure.drawnMeasurement.area = lengthOrArea
                if (measurementObject.type === POLYLINE) measure.drawnMeasurement.lineLength = lengthOrArea
                this.selectedMeasurement = { ...measure, ...measurementObject, color: measure.color }
                return ({ ...measure, ...measurementObject, drawnMeasurement: measure.drawnMeasurement, color: measure.color })
            }
            else return measure
        })
    }

    addThreeJsMeshScene = async (measurement) => {
        if (measurement.type === POLYLINE) {
            const lineGeometry = new THREE.BufferGeometry().setFromPoints(measurement.coordinates.map((coord) => new THREE.Vector3(coord.x, coord.y, coord.elevation)))
            const line = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: 0x0000ff, visible: false }))
            if (measurement.show) this.threeJsMeshScene.add(line);
            line.name = measurement.id
            measurement.threeJsMeasurement = line //saved for delete/update etc
            return Promise.resolve(measurement)
        } else if (measurement.type === POLYGON) {
            let polyShape = new THREE.Shape(measurement.coordinates.map((coord) => new THREE.Vector3(coord.x, coord.y, coord.elevation)))
            const polyGeometry = new THREE.ShapeGeometry(polyShape);
            polyGeometry.setAttribute("position", new THREE.Float32BufferAttribute(measurement.coordinates.map(coord => [coord.x, coord.y, coord.elevation]).flat(), 3))
            let polygon = new THREE.Mesh(polyGeometry, new THREE.MeshBasicMaterial({ color: measurement.color, side: THREE.DoubleSide, visible: false }))
            if (measurement.show) this.threeJsMeshScene.add(polygon);
            polygon.name = measurement.id
            measurement.threeJsMeasurement = polygon //saved for delete/update etc
            return Promise.resolve(measurement)
        }
    }

    getVolumeAreaString = async (measurement) => {
        const area = await get3dPolygonArea(measurement.coordinates, this.unit, true, true)
        return `${area}\u00B2${measurement.volume ?
            (this.unit === 'm' ? `, ${Number(measurement.volume).toFixed(2)} m\u00B3` : `, ${(Number(measurement.volume) * 1.308).toFixed(2)} yd\u00B3`)
            : ""}`
    }

    updateVolume = (id, volume) => {
        this.measurements.forEach(async (measurement) => {
            if (measurement.id === id) {
                measurement.volume = volume
                measurement.drawnMeasurement.area = await this.getVolumeAreaString(measurement)
            }
        })
    }

    drawMeasurements = (measurements, redraw) => {
        measurements.forEach(async (measurement) => {
            let measure = new this.Potree.Measure(measurement.color, false, measurement.type === POLYGON ? await this.getVolumeAreaString(measurement) : undefined, measurement.type === POLYLINE ? await getDistanceIn3d(measurement.coordinates, this.unit, true) : undefined, this.unit); //false for disable drawing 
            // measure.color = measurement.color;
            measure.name = measurement.name;
            measure.closed = measurement.type === POLYGON ? true : false;
            measure.showArea = measurement.type === POLYGON ? true : false;
            measure.showAngles = true
            measure.showEdgeLabels = true
            measure.showCoordinates = measurement.type === MARKER ? true : false;
            measure.visible = measurement.show;
            measure.showSphere = redraw ? true : false
            measure.scene = this.viewer.scene
            if (measurement.type === MARKER) {
                measure.maxMarkers = 1
                if (measurement.coordinates.elevation > 0) measure.addMarker(new THREE.Vector3(measurement.coordinates.x, measurement.coordinates.y, measurement.coordinates.elevation));
                else {
                    measure.visible = false
                }
            }
            else {
                // three js mesh/line for intersect object which is invisible to the UI
                measurement = await this.addThreeJsMeshScene(measurement)

                // potree actual measurement
                measurement.coordinates.forEach((coord) => {
                    if (coord.elevation > 0) measure.addMarker(new THREE.Vector3(coord.x, coord.y, coord.elevation));
                    else measure.visible = false
                });
            }
            //---------- this will fire only when user drop marker ----------//
            measure.addEventListener("marker_dropped", (e) => {
                if (this.selectedMeasurement) this.setMeasurementEditible(this.selectedMeasurement.id, false);
                this.selectedMeasurement = { ...measurement, drawnMeasurement: measure }
                if (measurement.type !== MARKER) {
                    //polygon vertex clicked, delete vertex
                    if (this.mouseDown && ((e.target.closed && e.target.points.length > 3) || (!e.target.closed && e.target.points.length > 2))) {
                        const points = [...e.target.points]
                        points.splice(e.index, 1)
                        this.updateMeasurement(this.measurements.find(m => measurement.id === m.id), points, true)
                    }
                    //polygon vertex dragged update vertex
                    else this.updateMeasurement(this.measurements.find(m => measurement.id === m.id), e.target.points)
                    this.setMeasurementEditible(this.selectedMeasurement.id, true)
                } else {
                    this.indicateMarkerInScene(measurement.id)
                    this.updateMeasurement(measurement, e.target.points)
                }
                // if user clicked on middle point - add new point 
                if (e.measurement.newPoint) {
                    const points = [...e.target.points]
                    points.splice(e.measurement.newPoint.index + 1, 0, { position: e.measurement.newPoint.position })
                    this.updateMeasurement(measurement, points, true)
                }
            })

            //---------------------------------------------------------------//
            this.measurements = redraw ? this.measurements.map((m) => {
                if (m.id === redraw) {
                    m.drawnMeasurement.visible = false
                    return ({ ...measurement, drawnMeasurement: measure })

                } else {
                    return m
                }
            }) : [...this.measurements, { ...measurement, drawnMeasurement: measure }]
            this.viewer.scene.addMeasurement(measure);
        })
    }

    // ----------- Volume clipping functions --------------//

    isVolumeBoxDrawn = () => {
        return this.drawnClipVolumes.length > 0
    }

    clearPartiallyVolume = async () => {
        if (this.drawingClipVolume) {
            this.viewer.scene.removeVolume(this.drawingClipVolume)
            this.drawingClipVolume.cancel.callback()
            this.drawingClipVolume = undefined
            this.clickOnMiddleOfCanvas()
            return
        }
        else return
    }

    exitPointCloudSlicing = async () => {
        if (this.viewer?.scene) {
            await this.clearPartiallyVolume()
            this.viewer.scene.removeAllClipVolumes()
            this.drawnClipVolumes = []
            return
        } else return

    }

    drawVolumeBoxFunc = async () => {
        if (this.pointCloudShow) {
            this.changeClipTask("Highlight")
            await this.stopDrawing()
            this.drawingClipVolume = await this.viewer.volumeTool.startInsertion({ clip: true })
            if (this.drawingClipVolume) {
                this.drawingClipVolume.addEventListener("drop", () => {
                    const drawingClipVolume = this.drawingClipVolume
                    this.drawingClipVolume = undefined
                    if (drawingClipVolume) {
                        this.viewer.inputHandler.toggleSelection(drawingClipVolume)
                        this.activeClipVolume = drawingClipVolume
                        drawingClipVolume.addEventListener("select", (e) => {
                            this.activeClipVolume = e.target
                        })
                        drawingClipVolume.addEventListener("deselect", (e) => {
                            this.activeClipVolume = null
                        })
                        const newVolume = this.drawingClipVolume
                        if (this.drawnClipVolumes) {
                            this.drawnClipVolumes.push(newVolume)
                        }
                        else this.drawnClipVolumes = [newVolume]
                    }
                    this.setVolumeBoxDrawn(this.drawnClipVolumes.length > 0)
                })
            }
        }

    }

    removeClipVolume = async (volume) => {
        if (volume && volume instanceof this.Potree.BoxVolume) {
            this.viewer.scene.removeVolume(volume)
            this.drawnClipVolumes = this.drawnClipVolumes.filter(CV => CV.uuid !== volume.uuid)
        }
        return
    }

    changeClipTask = (type) => {
        this.viewer.setClipTask(ClipTask[type])
        this.selectedClipTask = type
        this.setSelectedClipTask(type)
    }

    // -------- Volume clipping functions end ------------//
    loadPointCloud = async () => {
        let eptURL = `${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.compare3DMode ? this.selectedModel.task_id : this.taskId}/ept/ept.json?${this.sas_token}`
        let cloudUrl = `${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.compare3DMode ? this.selectedModel.task_id : this.taskId}/cloud/cloud.js`
        const url = await checkURLExist(eptURL) ? eptURL : cloudUrl

        if (await checkURLExist(eptURL)) {
            let response = await fetch(eptURL);
            let data = await response.json();
            if (!(data?.srs?.wkt)) {
                this.showTip = true
            }
        }

        this.Potree.loadPointCloud(url, this.sas_token).then(e => {
            let pointcloud = e.pointcloud;
            let material = pointcloud.material;
            material.activeAttributeName = "rgba";
            // material.activeAttributeName = "elevation";
            // pointcloud.material.elevationRange = [60, 120];
            material.minSize = 2;
            material.pointSizeType = this.Potree.PointSizeType.FIXED
            this.viewer.scene.addPointCloud(pointcloud);
            this.viewer.fitToScreen();
            if (this.view === "pointCloudShow" && !(this.view === "ThreeDShow")) {
                this.loading = false
                this.setLoading(false)
            }
        }, e => console.err("ERROR: ", e));

    }



    loadTexture = () => {
        if (this.view === "ThreeDShow" || !(this.view)) {

            this.loading = true;
            this.setLoading(true)
            let manager = new THREE.LoadingManager();
            manager.onLoad = function () {

                this.loading = false;
                this.setLoading(false)
            }.bind(this);
            manager.onError = function () {
                window.alert("Failed to load 3D model");
            }.bind(this);
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                let percentComplete = itemsLoaded / itemsTotal * 100
                let loaded = Math.round(percentComplete, 2)
                this.loaded = loaded;
            }.bind(this);
            let objloader = new OBJLoader(manager);
            let mtlloader = new MTLLoader();
            mtlloader.load(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.compare3DMode ? this.selectedModel.task_id : this.taskId}/threeD/textured_model_geo.mtl?${this.sas_token}`,
                (materials) => {
                    let changeMapKd = async () => {
                        Object.keys(materials.materialsInfo).map((material, key) => {
                            if (materials.materialsInfo[material].map_kd) materials.materialsInfo[material].map_kd = `${materials.materialsInfo[material].map_kd}?${this.sas_token}`
                        })
                    }
                    changeMapKd().then(() => {
                        materials.preload()
                        objloader.setMaterials(materials)
                        objloader.load(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${this.compare3DMode ? this.selectedModel.task_id : this.taskId}/threeD/textured_model_geo.obj?${this.sas_token}`, (object) => {
                            this.loadGeoreferencingOffset((offset) => {
                                object.translateX(offset.x);
                                object.translateY(offset.y);
                                object.name = "3dModel"
                                this.viewer.scene.scene.add(object);
                                this.modelReference = object;
                                if (this.view == "ThreeDShow") {
                                    this.viewer.setEDLEnabled(true);
                                    this.viewer.setEDLOpacity(0);
                                }
                            });
                        })
                    })
                })

        }
    }



    setMeasureShow = (measure, visible) => {
        if (measure && measure.drawnMeasurement) {
            measure.show = visible
            measure.drawnMeasurement.showSphere = false
            let mesh = this.threeJsMeshScene.getObjectByName(measure.id)
            if (visible & !mesh) {
                this.addThreeJsMeshScene(measure)
            }
            else if (mesh) {
                this.threeJsMeshScene.remove(mesh)
            }
            measure.drawnMeasurement.visible = visible
        }
    }

    toggleAllMeasurementsVisibility = (visible) => {
        this.measurements.forEach((measure) => {
            this.setMeasureShow(measure, visible)
        });
    }

    setMeasurementVisibility = (id, visible) => {
        this.setMeasureShow(this.measurements.find((measure) => measure.id === id && measure.drawnMeasurement), visible)
    }

    setMeasurementColor = (id, color, temp) => {
        this.measurements.forEach((measure) => {

            if (measure.id === id && measure.drawnMeasurement) {
                if (!temp) measure.color = color?.hex ? color.hex : color
                measure.drawnMeasurement.color = new this.Potree.Color(color?.hex ? color.hex : color)
            } else if (measure.drawnMeasurement) {
                measure.drawnMeasurement.color = new this.Potree.Color(measure.color)
            }
        })
    }

    hoverMeasurement = () => {
        if (this.hoveredMeasurement) {
            this.setMeasurementColor(this.hoveredMeasurement.id, this.hoveredMeasurement.color)
        }
        let intersects = this.raycaster.intersectObjects(this.threeJsMeshScene.children, true)
        let measurements = this.measurements.filter((measurement) => intersects.map((intersect) => intersect.object.name).includes(measurement.id))
        const lines = measurements.filter(measurement => measurement.type === POLYLINE && measurement.drawnMeasurement && measurement.drawnMeasurement.visible)
        if (lines.length) {
            let line = lines.reduce((prev, curr) => prev.length < curr.length ? prev : curr)
            this.hoveredMeasurement = line
            if (!this.selectedMeasurement || this.selectedMeasurement.id !== line.id) {
                this.setMeasurementColor(line.id, hoveredShapeColor, true)
                this.cursor = "pointer"
                this.setCursor('pointer')
            }
        }
        else {
            const polygons = measurements.filter(measurement => measurement.type === POLYGON && measurement.drawnMeasurement && measurement.drawnMeasurement.visible)
            if (polygons.length > 0) {
                let polygon = polygons.reduce((prev, curr) => prev.area < curr.area ? prev : curr)
                this.hoveredMeasurement = polygon

                if (!this.selectedMeasurement || this.selectedMeasurement.id !== polygon.id) {
                    this.setMeasurementColor(polygon.id, hoveredShapeColor, true)
                    this.cursor = "pointer";
                    this.setCursor('pointer')
                }
            }
            else {
                this.cursor = "default";
                this.setCursor('default')
                this.setMeasurementColor() //default color to all this.measurements
                this.hoveredMeasurement = undefined;
            }
        }
    }

    initPotree = () => {
        return new Promise(resolve => {
            this.Potree = window.Potree
            var width = window.innerWidth;
            var height = window.innerHeight;
            this.$ = window.$
            const viewerElem = this.potreeContainerDiv.current
            this.viewer = new this.Potree.Viewer(viewerElem);
            this.domElement = this.viewer.renderer.domElement;
            this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 2000);
            this.viewer.scene.scene.add(this.camera);
            this.viewer.scene.scene.add(new THREE.AmbientLight(0x404040, 2.0)); // soft white light );
            this.viewer.scene.scene.add(new THREE.DirectionalLight(0xcccccc, 0.5));
            const directional = new THREE.DirectionalLight(0xcccccc, 0.5);
            directional.position.z = 99999999999;
            this.viewer.scene.scene.add(directional);
            this.viewer.setBackground("#000000");
            this.viewer.setEDLEnabled(true);
            this.viewer.setFOV(60);
            this.viewer.setPointBudget(1 * 1000 * 1000);
            this.viewer.loadSettingsFromURL();
            this.viewer.setControls(this.viewer.orbitControls)
            this.threeJsMeshScene = new THREE.Object3D()
            this.viewer.scene.scene.add(this.threeJsMeshScene)
            this.viewer.renderer.domElement.addEventListener('mousedown', () => this.mouseDown = true, false)
            this.viewer.renderer.domElement.addEventListener('pointermove', () => {
                this.mouseDown = false
                const ray = this.Potree.Utils.mouseToRay(this.viewer.inputHandler.mouse, this.viewer.scene.getActiveCamera(), this.viewer.renderer.domElement.clientWidth, this.viewer.renderer.domElement.clientHeight);
                this.raycaster = new THREE.Raycaster(ray.origin, ray.direction);
                this.raycaster.params.Line.threshold = 2
                this.raycaster.params.Mesh.threshold = 2

                if (this.viewer && this.raycaster && !this.activeMeasurement) this.hoverMeasurement()
            });
            this.viewer.renderer.domElement.addEventListener('mouseup', () => { if (this.mouseDown) this.selectMeasurement() }, false)
            resolve()
        })
    }



    isMapChanged = async () => {
        this.viewer.scene.removeAllMeasurements()
        this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
        this.viewer.scene.scene.remove(this.threeJsMeshScene)
        this.threeJsMeshScene = new THREE.Object3D()
        await this.exitPointCloudSlicing()

        this.measurements = [];
        this.initData()
    }

    loadthreeDDataTask = () => {
        return new Promise(async (resolve, reject) => {
            let modelAvailableData = []
            for (let i = 0; i < this.threeDModelData.length; i++) {
                let data = this.threeDModelData[i]
                let indThreeDAvailable = await checkURLExist(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${data.task_id}/threeD/textured_model_geo.obj?${this.sas_token}`) &&
                    await checkURLExist(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${data.task_id}/threeD/textured_model_geo.mtl?${this.sas_token}`) ? true : false

                let indPointCloudAvailable = (await checkURLExist(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${data.task_id}/ept/ept.json?${this.sas_token}`)
                    || await checkURLExist(`${BLOB_URL}/${this.blob_container}/orthomosaicImages/${data.task_id}/cloud/cloud.js?${this.sas_token}`)) ? true : false

                if (indThreeDAvailable || indPointCloudAvailable) {
                    data['models'] = {
                        threeD: indThreeDAvailable,
                        pointsCloud: indPointCloudAvailable
                    }
                    modelAvailableData.push(data)
                }
            }
            let selectedModel = modelAvailableData.find((data) => { return data.task_id == this.taskId })
            let index = modelAvailableData.indexOf(selectedModel)
            let selectedModel1 = modelAvailableData[modelAvailableData.length < 2 || index == modelAvailableData.length - 1 ? index : index + 1]
            let index1 = modelAvailableData.indexOf(selectedModel1)
            this.modelAvailableData = modelAvailableData;
            this.selectedModel = selectedModel;
            this.selectedModel1 = selectedModel1;
            this.activeModel = index;
            this.activeModel1 = index1;
            this.activeView = !(this.selectedModel?.models?.pointsCloud) && (this.selectedModel?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow';
            this.activeView1 = !(this.selectedModel1?.models?.pointsCloud) && (this.selectedModel1?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow';

            resolve()
        })

    }

    removeData = () => {
        return new Promise((resolve, reject) => {
            if (this.modelReference) {
                this.viewer.scene.scene.remove(this.modelReference)
                this.modelReference = undefined
            }
            if (this.modelReference1) {
                this.viewer1.scene.scene.remove(this.modelReference1)
                this.modelReference1 = undefined
            }
            this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
            this.viewer.scene.pointclouds.pop();
            this.viewer1.scene.scenePointCloud.remove(this.viewer1.scene.pointclouds[0]);
            this.viewer1.scene.pointclouds.pop();
            this.onHandleEnter = false;
            this.onHandleDown = false;
            resolve()
        })
    }

    toggleView = () => {
        this.loading = true;
        setTimeout(async () => {
            if (this.compare3DMode) {
                await this.loadthreeDDataTask()
            } else {
                await this.removeData()
            }
            this.initData()
        }, 300)
    }

    initData = () => {
        setTimeout(async () => {
            if (window.Potree) {
                this.pointCloudShow = true;
                this.view = (this.bothShowPT ? undefined : (this.ThreeDShow ? "ThreeDShow" : "pointCloudShow"))
                this.view1 = this.compare3DMode ? (this.activeView1 == 'both' ? undefined : this.activeModel1) : undefined
                this.taskId = this.taskId
                await this.initPotree()
                this.loadPointCloud()
                this.loadTexture()
            } else {
                this.initData()
            }
        }, 500);
    }

    AddLibrary = async (urlOfTheLibrary) => {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    addLibraries = async () => {
        await this.AddLibrary("/potree/jquery/jquery-3.1.1.min.js")
        await this.AddLibrary("/potree/other/BinaryHeap.js")
        await this.AddLibrary("/potree/tween/tween.min.js")
        await this.AddLibrary("/potree/proj4/proj4.js")
        await this.AddLibrary("/potree/openlayers3/ol.js")
        await this.AddLibrary("/potree/plasio/js/laslaz.js")
        await this.AddLibrary("/potree/potree/potree.js")
        this.initData()
    }

    prevModel = () => {
        let activeModelKey = this.activeModel - 1
        this.selectedModel = this.modelAvailableData[activeModelKey];
        this.activeModel = activeModelKey;
        this.activeView = !(this.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
        this.loading = true
        if (this.modelReference) {
            this.viewer.scene.scene.remove(this.modelReference)
            this.modelReference = undefined
            this.viewer.setEDLOpacity(1.0);
        }
        this.view = !(this.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
        this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
        this.viewer.scene.pointclouds.pop();
        this.loadPointCloud()
        this.loadTexture()
    }

    nextModel = () => {
        let activeModelKey = this.activeModel + 1
        this.selectedModel = this.modelAvailableData[activeModelKey];
        this.activeModel = activeModelKey;
        this.activeView = !(this.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow';
        this.loading = true
        if (this.modelReference) {
            this.viewer.scene.scene.remove(this.modelReference)
            this.modelReference = undefined
            this.viewer.setEDLOpacity(1.0);
        }
        this.view = !(this.modelAvailableData[activeModelKey]?.models?.pointsCloud) && (this.modelAvailableData[activeModelKey]?.models?.threeD) ? 'ThreeDShow' : 'pointCloudShow'
        this.viewer.scene.scenePointCloud.remove(this.viewer.scene.pointclouds[0]);
        this.viewer.scene.pointclouds.pop();
        this.loadPointCloud()
        this.loadTexture()
    }



    changeActiveView = (view) => {
        switch (view) {
            case "pointCloudShow":
                this.pointCloudShow = true;
                this.activeView = 'pointCloudShow'
                this.ThreeDShow = false;
                this.bothShowPT = false;
                break;
            case "ThreeDShow":
                this.pointCloudShow = false;
                this.ThreeDShow = true;
                this.bothShowPT = false;
                this.activeView = 'ThreeDShow'
                break;
            case "Both":
                this.pointCloudShow = false;
                this.bothShowPT = true;
                this.ThreeDShow = false;
                this.activeView = 'Both'
                break;
        }
        this.view = (this.bothShowPT ? undefined : (this.ThreeDShow ? "ThreeDShow" : "pointCloudShow"))
        if (this.modelReference) {
            const inSceneAlready = this.viewer.scene.scene.getObjectByName(this.modelReference.name);
            if (inSceneAlready && this.activeView == 'pointCloudShow') {
                //remove 3d model if it is in scene when user wants to show only pointcloud
                this.viewer.scene.scene.remove(this.modelReference)
            } else if (!inSceneAlready) {
                // if user again switch to 3d model and it is already loaded but not in scene, add it to scene
                this.exitPointCloudSlicing()
                this.viewer.scene.scene.add(this.modelReference)
            }
        } else if (this.activeView !== 'pointCloudShow') {
            this.loadTexture()
            this.exitPointCloudSlicing()
            this.setShowVolumeBoxOptions(false)
        }

        // remove pointCloud if it is in scene when user wants to show only 3d model
        if (this.activeView == 'ThreeDShow') {
            this.viewer.setEDLEnabled(true);
            this.viewer.setEDLOpacity(0);
        } else {
            this.viewer.setEDLOpacity(1.0);
        }
    }


    handleEvents = () => {
        this.handleMove = this.onHandleDown && this.onHandleDown ? true : false
    }

}