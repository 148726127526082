import React from 'react'

const DisplayField = ({ label, value, labelFontSize, valueFontSize, justify, display, gap, labelWidth, style }) => {
    return (
        <div className='poppins-font' style={{
            display: display ? display : "grid",
            alignItems: "flex-start",
            justifyContent: justify ? justify : "space-between",
            gridTemplateColumns: "1fr 1fr",
            marginBottom: "3px",
            fontWeight: 400,
            gap: display === "flex" && justify === "flex-start" && gap ? gap : "0px", ...style
        }}>
            <div style={{
                fontSize: labelFontSize ? labelFontSize : "10px",
                color: "#666666",
                width: labelWidth || ''
            }}>
                {`${label} : `}
            </div>
            <div style={{
                fontSize: valueFontSize ? valueFontSize : '12px',
                color: "#3c3c3c",
                overflow: "hidden",
                wordWrap: "break-word",
            }}>
                {value || "-"}
            </div>
        </div>
    )
}

export default DisplayField