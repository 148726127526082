// Compulsory props : selectedItem, itemsList, onChangeHandler

import React, { useState } from 'react'
import Delete from '../icons/deleteIcon.svg';
import Menu from '../icons/MenuWhite.png';
import MenuDark from '../icons/MenuDark.png';
import Rename from '../assets/sideBarIcons/edit.png';
import Duplicate from '../icons/copy.png';
import Manage from '../icons/manage.png';
import Export from '../icons/Export.png';
import Download from '../icons/Download.png'
import Tippy from '@tippyjs/react';

export const DeleteComponentGrid = (props) => {
  const [open, setOpen] = useState(false)
  let fontColor = props.fontColor ? props.fontColor : "#3c3c3c"

  return (
    <>
      <Tippy
        visible={open}
        onClickOutside={() => setOpen(false)}
        animation={false}
        interactive
        onShown={(instance) => {
          document.querySelector('[data-tippy-root]').addEventListener('click', event => {
            instance.hide();
            setOpen(false)
          })
        }}
        onShow={instance => {
          const el = document.querySelector(".scroll-container")
          if (el)
            el.addEventListener('scroll', event => {
              instance.hide()
              setOpen(false)
            })
        }}
        className='tippy-menu'
        arrow={false}
        placement='bottom-end'
        content={
          <div size="sm" title="" className="custom-dropdown-dashboard">
            {props.canManage ?
              <div eventKey="duplicate" className="dropdown-set-padding-dashboard tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onClickManage(e)
                    setOpen(false)
                  }}>
                  <img src={Manage} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Manage</div>
                </div>
              </div>
              : <></>}
            {props.canExport ?
              <div eventKey="rename" className="dropdown-set-padding-dashboard tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onclickExport(e)
                    setOpen(false)
                  }}>
                  <img src={Export} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Export</div>
                </div>
              </div> : <></>}

            {props.canRename ?
              <div eventKey="rename" className="dropdown-set-padding-dashboard tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onClickRename(e)
                    setOpen(false)
                  }}>
                  <img src={Rename} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Rename</div>
                </div>
              </div> : <></>}

            {props.canDuplicate ?
              <div eventKey="duplicate" className="dropdown-set-padding-dashboard tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onclickDuplicate(e)
                    setOpen(false)
                  }}>
                  <img src={Duplicate} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Duplicate</div>
                </div>
              </div>
              : <></>}

            {props.canDownload ?
              <div eventKey="download" className="dropdown-set-padding-dashboard tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    props.onClickDownload(e)
                    setOpen(false)
                  }}>
                  <img src={Download} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Download</div>
                </div>
              </div>
              : <></>}

            <div eventKey="delete" className="dropdown-set-padding-dashboard tippy-menu-hover">
              <div style={{ display: "flex", cursor: props.disableDelete ? 'not-allowed' : 'pointer', opacity: props.disableDelete ? 0.5 : undefined }}
                onClick={(e) => {
                  if (!props.disableDelete) {
                    e.preventDefault()
                    props.onClickDelete(e)
                    setOpen(false)
                  }
                }}>
                <img src={Delete} style={{ height: "16px" }} />
                <div style={{ marginLeft: '7px', color: fontColor }}>Delete</div>
              </div>
            </div>

          </div>
        }
      >
        <div style={{
          position: "relative", height: "15px", width: "15px", marginLeft: "20px", marginBottom: "2px", cursor: "pointer", ...props.style
        }} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(prev => !prev)
        }} >
          <img src={MenuDark} style={{ width: "100%", heigth: "100%" }} />
        </div>
      </Tippy>

    </>
  )
}

// Delete Menu icon for table view
export const DeleteComponentTable = (props) => {
  const [open, setOpen] = useState(false)
  let fontColor = props.fontColor ? props.fontColor : "#3c3c3c"

  return (
    <>
      <Tippy
        visible={open}
        onClickOutside={() => setOpen(false)}
        animation={false}
        interactive
        onShown={(instance) => {
          document.querySelector('[data-tippy-root]').addEventListener('click', event => {
            instance.hide();
            setOpen(false)
          })
        }}
        onShow={instance => {
          const el = document.querySelector(".scroll-container")
          if (el)
            el.addEventListener('scroll', event => {
              instance.hide()
              setOpen(false)
            })
        }}
        className='tippy-menu'
        arrow={false}
        placement='bottom-end'
        content={
          <div size="sm" title="" className="custom-dropdown-dashboard">
            {props.canManage ?
              <div eventKey="duplicate" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClickManage()
                    setOpen(false)
                  }}>
                  <img src={Manage} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Manage</div>
                </div>
              </div>
              : <></>}
            {props.canRename ?
              <div eventKey="rename" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClickRename(e)
                    setOpen(false)
                  }}>
                  <img src={Rename} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Rename</div>
                </div>
              </div> : <></>}
            {props.canEdit ?
              <div eventKey="edit" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClickEdit()
                    setOpen(false)
                  }}>
                  <img src={Rename} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Edit</div>
                </div>
              </div> : <></>}
            {props.canDuplicate ?
              <div eventKey="duplicate" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onclickDuplicate(e)
                    setOpen(false)
                  }}>
                  <img src={Duplicate} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Duplicate</div>
                </div>
              </div>
              : <></>}
            {props.canDownload ?
              <div eventKey="download" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
                <div style={{ display: "flex", cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClickDownload(e)
                    setOpen(false)
                  }}>
                  <img src={Download} style={{ height: "14px" }} />
                  <div style={{ marginLeft: '7px', color: fontColor }}>Download</div>
                </div>
              </div>
              : <></>}
            <div eventKey="delete" className="dropdown-set-padding-dashboard tippy-menu-hover tippy-menu-hover">
              <div style={{ display: "flex", cursor: props.disableDelete ? 'not-allowed' : 'pointer', opacity: props.disableDelete ? 0.5 : undefined }}
                onClick={(e) => {
                  if (!props.disableDelete) {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClickDelete(e)
                    setOpen(false)
                  }
                }}>
                <img src={Delete} style={{ height: "16px" }} />
                <div style={{ marginLeft: '7px', color: fontColor }}>Delete</div>
              </div>
            </div>
          </div>
        }
      >
        <div style={{
          position: "relative", height: "15px", width: "15px", marginLeft: "20px", marginBottom: "2px", cursor: "pointer", ...props.style
        }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpen(prev => !prev) }} >
          <img src={MenuDark} style={{ width: "100%", heigth: "100%" }} />
        </div>
      </Tippy>
    </>
  )
}