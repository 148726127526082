import React, { useRef } from 'react'

const ActionButton = ({ name, isDisabled, icon, onClickCallback, background, isInput, inputName, isMultiple, isBtnActive, width, acceptType }) => {
    const inputRef = useRef(null);
    const inputId = `input-box-${inputName}`;
    const disableFunc = () => { }
    return (
        <div onClick={() => { isDisabled ? disableFunc() : isInput ? inputRef.current.click() : onClickCallback() }}
            style={{
                border: `1px solid #2989cf`,
                borderRadius: "20px",
                color: (isBtnActive ? "white" : "#2989cf"),
                backgroundColor: isBtnActive ? "#2989cf" : ("transparent"),
                padding: "4px 16px",
                fontSize: "11px",
                fontWeight: "600",
                letterSpacing: "0.01em",
                margin: "0px 5px 0px 5px",
                opacity: isDisabled ? "0.5" : "1",
                filter: isDisabled ? "grayscale(100%)" : undefined,
                cursor: isDisabled ? "default" : "pointer",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: width || "fit-content",
                gap: "10px"
            }}>
            <div>
                {name}
            </div>
            {icon && <img src={icon} alt='button icon' style={{ height: "12px" }} />}
            {isInput && <input
                name={inputName}
                ref={inputRef}
                multiple={isMultiple}
                type="file"
                placeholder="Select Reports"
                onChange={(e) => {
                    onClickCallback(e)
                    e.target.value=""
                }}
                style={{ display: "none" }}
                id={inputId}
                accept={acceptType || ''}
            />}
        </div>
    )
}

export default ActionButton