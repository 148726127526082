import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'
import lockIcon from './assets/lock.svg'
import eyeIcon from './assets/eye.svg'
// import googleIcon from './assets/google.png'

class ResetPasswordSuccessful extends Component {
  state = {
  }

  componentWillMount() {
    document.title = "DroneLab-Reset Password";
  }

  render() {
    return (
      <div className="authPage">
        <div style={{height:'28rem'}} className="cardLogin login-card">
          <img src={aeromeghLogo} className="login-logo" />
          <div className="greet login-greet">FORGOT PASSWORD</div>
          <div>
            <div className="content-text" style={{ padding: '20px', fontSize: "16px", lineHeight: '32px', color: 'black' }}>
              Your new password has been sent to your email address.
            </div>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
            <Link to={{
              pathname: "/login",
            }}><button style={{ cursor: 'pointer' }} className="loginButton">LOGIN</button></Link>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordSuccessful
