import React, { useEffect, useState } from 'react'
import LoaderComponent from '../ReusableComponents/LoaderComponent'
import Close from "../icons/blackClose.png"
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { addPropertyType, getPropertyTypes, removePropertyType } from '../api'
import { Popup } from '../ReusableComponents/reusableFunctions'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'

const AddPropertyType = ({ openPropertyTypePopup, onRequestClose }) => {
    const [loading, setLoading] = useState(true)
    const [propertyTypes, setPropertyTypes] = useState([])
    const [inputTerm, setInputTerm] = useState("")

    const getTypesOfProperties = async () => {
        try {
            setLoading(true)
            const types = await getPropertyTypes()
            setPropertyTypes(types?.types_of_properties)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            Popup.alert("ERROR", error || error?.message)
        }
    }

    const addTypeOfProperty = async () => {
        try {
            if (inputTerm === "") {
                Popup.alert("ERROR", "Please input property type.")
                return
            }
            setLoading(true)
            const propertyType = await addPropertyType(inputTerm)
            setPropertyTypes((prev) => [
                inputTerm,
                ...prev
            ])
            setInputTerm("")
            setLoading(false)
            Popup.alert("COMPLETE", propertyType?.message)
        } catch (error) {
            setLoading(false)
            Popup.alert("ERROR", error || error?.message)
        }
    }

    const deleteTypeOfProperty = async (type) => {
        try {
            setLoading(true)
            const propertyType = await removePropertyType(type)
            setPropertyTypes((prevPropertyTypes) => prevPropertyTypes.filter((p) => p !== type));
            setLoading(false)
            Popup.alert("COMPLETE", propertyType?.message)
        } catch (error) {
            console.log('error:', error)
            Popup.alert("ERROR", error || error?.message)
        }
    }

    useEffect(() => {
        getTypesOfProperties()
    }, [])

    return (
        <>
            <AnimatedModal isOpen={openPropertyTypePopup} onRequestClose={() => onRequestClose()} height="auto" >
                {loading ?
                    <LoaderComponent /> :
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        addTypeOfProperty()
                    }} style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", gap: "10px", position: 'relative' }}>
                        <div style={{ padding: "20px", textAlign: 'left', display: 'flex', alignItems: "center", justifyContent: 'space-between', borderBottom: '1px solid #C3CDD9' }}>
                            <span>Manage Property Type</span>
                            <img onClick={onRequestClose} src={Close} style={{ cursor: 'pointer', height: '25px' }} />
                        </div>
                        <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: "column", gap: "10px", padding: "20px", position: "relative" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>
                                        Add new Property Type
                                    </label>
                                    <input
                                        placeholder='Enter City Area Name'
                                        style={{ outline: "none", width: "100%", borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                                        type='text' onChange={e => {
                                            e.persist()
                                            setInputTerm(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", color: "white" }}>
                                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>
                                        Added Property Types
                                    </label>
                                    <div style={{ width: "100%", height: "140px", overflow: 'auto', borderRadius: "8px", border: "1px solid #C3CDD9", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", display: "grid", gridTemplateColumns: '1fr 1fr 1fr', gap: "10px", padding: "10px" }}>
                                        {propertyTypes?.map((p) => {
                                            return <div key={p} style={{ background: "#2989CF", borderRadius: "8px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "35px", padding: "10px", width: "auto" }}>
                                                <div>{p}</div>
                                                <img src={Close} alt='close btn' width={"25px"} style={{ cursor: "pointer" }} onClick={() => {
                                                    deleteTypeOfProperty(p)
                                                }} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "end", padding: "0px 20px 20px" }}>
                            <ButtonWithIcon isBtnActive fontSize={'14px'} width={"115px"} text={"Add"} isDisabled={false} onClick={() => {
                                addTypeOfProperty()
                            }} />
                        </div>
                    </form>
                }
            </AnimatedModal>
        </>
    )
}

export default AddPropertyType