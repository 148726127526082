export const TOOLS = {
    POLYGON: "Polygon",
    POLYLINE: "Polyline",
    MARKER: "Marker",
    SELECTOR: "Selector",
    VOLUME_BOX: "VolumeBox",
    LAYER: "Layer",
    BOX: "Box",
    MULITPOLYGON: "MultiPolygon",
    MARKER: "Marker",
    POINT: "Point",
    LINESTRING: "LineString",
    MULTILINESTRING: "MultiLineString",
    GEOMETRYCOLLECTION: "GeometryCollection",
    COMPARE: "Compare"
}

export const IMAGE_TYPES = {
    GEOJSON: "GEOJSON"
}

export const CURSOR_TYPES = {
    POINTER: "pointer",
    CROSSHAIR: "crosshair"
}

export const LAYER_TYPE = {
    PROPERTY: "Property",
    BLOCK: "Block",
    ENC: "Enc"
}