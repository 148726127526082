import React, { Component } from 'react';
import waiting from '../res/waiting.png';
import Popup from "reactjs-popup";
import closeButton from '../close-button.png';
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import Settings from '../sidebaricon/Settings.png';
import editIcon from '../edit.svg';
import { checkIsUserAdmin } from '../Teams/index.js';

export default class MyProfile extends Component {

    state = {
        subscriptions: [],
        user: {},
        type: "",
        avatarURL: '',
        createIcon: waiting,
        org: {},
        models: [],
        model: {},
        subscriptionTitleMap: {
            "aerogcs_plan_pro": "DroneLab Pro",
            "aerogcs_plan_free": "DroneLab Free",
            "aeromegh_plan_pro": "DroneLab Pro",
            "aeromegh_plan_free": "DroneLab Free",
            "aerostream_plan_starter": "AeroStream Starter",
            "aerostream_plan_pro": "AeroStream Pro"
        },
    }


    toggleSubCancelPopup = () => {
        document.getElementById("send-plan-popup-button").click();

    }

    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans
                }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    componentWillMount() {
        document.title = "My Profile - DroneLab";
        if (this.props) {
            let { user, type } = this.props;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            if (user.avatar_url && user.avatar_url.length > 0) {
                this.setState((state) => ({
                    ...state,
                    avatarURL: `${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
                }))

            } else {
                let avatarURL = `/images/${user.first_name[0].toLowerCase()}.PNG`
                this.setState((state) => ({
                    ...state,
                    avatarURL: avatarURL
                }))
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {

                this.getAeromeghPlans();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }

                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getAeromeghPlans();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, isFromDroneLabDashboard } = this.props
        return (
            user.username ?
                <div className="wrapper">
                    <div>
                        <div style={{ width: "65vw" }}>
                            <div >
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "95px", padding: "11px 70px", background: "#EAEAEA" }}>
                                    <div>
                                        <div className="services-content-title" style={{ fontSize: "27px" }}>{user.first_name + " " + user.last_name}</div>
                                    </div>
                                    <div className='flex-center' style={{ justifyContent: "space-between", height: "100%", width: "9vw" }}>
                                        <div>
                                            {checkIsUserAdmin() &&
                                                <img style={{ cursor: "pointer", height: "24px" }} onClick={() => this.props.edit()} src={editIcon} />
                                            }
                                        </div>
                                        <div>
                                            <img style={{ borderRadius: "50%", height: '70px', width: '70px' }} src={this.state.avatarURL}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="rpas-model-info-bottom-section" style={{ marginTop: "23px", marginLeft: "5%" }}>
                                    <div style={{ display: "flex" }}>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">First Name</div>
                                            <div className="profile-details-value">{`${user.first_name}`}</div>
                                        </div>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">Last Name</div>
                                            <div className="profile-details-value">{`${user.last_name}`}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">Email </div>
                                            <div className="profile-details-value">{`${user.username}`}</div>
                                        </div>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">Phone Number</div>
                                            <div className="profile-details-value">{`${user.phone}`}</div>
                                        </div>
                                    </div>

                                    <div className="rpas-model-info-primary-details-title-row rpas-model-title-width" style={{ marginTop: "23px" }}>
                                        <div className="services-content-title">Address</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">Country</div>
                                            <div className="profile-details-value">{`${user.country == null ? '-' : user.country}`}</div>
                                        </div>
                                        <div className="rpas-model-info-primary-details-row">
                                            <div className="profile-details-title rpas-model-title-width">State</div>
                                            <div className="profile-details-value">{`${user.state == null ? '-' : user.state}`}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="rpas-model-info-primary-details-row" style={{ width: "100%" }}>
                                            <div className="profile-details-title rpas-model-title-width" style={{ width: "23%" }}>Address</div>
                                            <div className="profile-details-value">{user.address}</div>
                                        </div>
                                    </div>
                                    {(!isFromDroneLabDashboard || checkIsUserAdmin()) &&
                                        <>
                                            <div className="rpas-model-info-primary-details-title-row rpas-model-title-width" style={{ marginTop: "23px" }}>
                                                <div className="services-content-title">Two step authentication (2FA)</div>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <label className='toggle-label'>
                                                    <input className='toggle-input'
                                                        value={this.props.user.otp_flag}
                                                        type="checkbox" checked={this.props.user.otp_flag} onClick={() => this.props.toggleTwoStepAuth()} />
                                                    <span className='toggle-span' />
                                                </label>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div />
        );
    }
}
