import React, { useEffect, useState } from 'react'
import LayerColor from '../../Components/LayerColor'
import LayerMenu from '../../Components/LayerMenu'
import { Popup, triggerBlobDownload } from '../../../ReusableComponents/reusableFunctions'
import { useDebouncedCallback } from 'use-debounce'
import * as L from 'leaflet'
import { toggleLayer } from '../Recoil/atom'

export const setLayerStyle = (layer, color) => {
    layer.setStyle({
        color: color,
        fillColor: color,
        fillOpacity: 0.2
    });
    layer.dn_style = {
        color: color,
        fillColor: color
    };
    return layer
}

const LayerHolder = ({ layer, map, isVisible, isColorPicker, isLayerMenu, onUpateLayerColor }) => {
    const [showLayer, setShowLayer] = useState(isVisible)
    const [toggleLayerS, setToggleLayerS] = useState(toggleLayer)

    useEffect(() => {
        setShowLayer(isVisible)
    }, [isVisible])

    const downloadBlob = async (url, fileName, extension) => {
        const response = await fetch(url);
        const blob = await response.blob();
        triggerBlobDownload(blob, `${fileName}.${extension}`);
    }

    const handleLayerDownload = async (imageUrl) => {
        const url = imageUrl + "?" + layer.sas_token;
        await downloadBlob(url, 'output', 'zip');
    }

    const changeLayerColor = useDebouncedCallback(async (layer, color) => {
        try {
            const updatedLayer = setLayerStyle(layer, color)
            onUpateLayerColor(updatedLayer, color)
        } catch (err) {
            Popup.alert("ERROR", err?.message || err)
        }
    }, 300)

    return (
        <div style={{ color: "#3c3c3c", fontSize: "11px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "start" }}>
            <input type='checkbox' disabled={false} style={{ cursor: 'pointer', height: "15px", width: "15px" }} checked={showLayer} onChange={(e) => {
                setShowLayer(e.target.checked)
                setToggleLayerS(!toggleLayerS)
                if (e.target.checked) {
                    map.addLayer(layer.layer)
                    if (layer?.labelLayer) {
                        map.addLayer(layer?.labelLayer)
                    }
                }
                else {
                    map.removeLayer(layer.layer)
                    if (layer?.labelLayer) {
                        map.removeLayer(layer?.labelLayer)
                    }
                }
            }} />
            <div style={{ marginLeft: "10px", minWidth: "55px", backgroundColor: 'white', fontSize: "10px", width: "100%", fontWeight: "400", wordWrap: "break-word" }}>
                {layer.name}
            </div>
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                {(isColorPicker && !(layer.layer instanceof L.Marker)) &&
                    <LayerColor color={layer.color} onChangeColor={(color) => {
                        changeLayerColor(layer.layer, color)
                    }} />
                }
                {isLayerMenu &&
                    <LayerMenu
                        onClickDownload={() => {
                            handleLayerDownload(layer.map_blob_url)
                        }}
                        onClickDelete={() => { }}
                        permissions={{ DOWNLOAD: "download" }}
                    />
                }
            </div>
        </div>
    )
}

export default LayerHolder