import React from 'react';
import closeButton from '../../../close-button.png';
import DeleteIcon from '../../../assets/toolbarIcons/Delete.png';

const HeaderContainer = ({ title, closeCB, containerStyle, titleStyle, btnStyle, showDelete, onDelete }) => {
    return (
        <div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", ...containerStyle }}>
                <div style={{ ...titleStyle }}>
                    {title}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    {showDelete &&
                        <img src={DeleteIcon} alt='close button' style={{ width: "23px", cursor: "pointer" }} onClick={() => {
                            onDelete()
                        }} />
                    }
                    {closeCB &&
                        <img src={closeButton} alt='close-button' onClick={() => closeCB()} style={{ width: "23px", cursor: "pointer", ...btnStyle }} />
                    }
                </div>
            </div>
            <hr style={{ margin: 0 }} />
        </div>
    )
}

export default HeaderContainer