import React, { useState } from 'react'
import { AnimatedModal } from '../../../ReusableComponents/AnimatedModal';
import ButtonWithIcon from '../../../ReusableComponents/ButtonWithIcon';
import transClose from "../../assets/icons/Close.svg"

const LayerLabelContainer = ({ labels, openLabels, onSelectLabel, onCloseCall }) => {
    const [selectedLabel, setSelectedLabel] = useState('')

    return (
        <AnimatedModal
            isOpen={openLabels}
            height="225PX"
            width="290px"
        >
            <div style={{ position: "relative", height: "100%", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 10px 10px 20px", borderBottom: "1px solid #D9D9D9" }}>
                    <div style={{
                        color: "#3C3C3C"
                    }}>
                        Select Label
                    </div>
                    <div style={{ cursor: "pointer" }} >
                        <img src={transClose} style={{ width: '18px', height: '18px', cursor: "pointer" }} onClick={() => {
                            onCloseCall()
                        }} />
                    </div>
                </div>
                <div style={{ height: "125px", textAlign: "left", overflow: "auto", color: "#666666" }}>
                    {
                        labels ? Object.keys(labels).map(label => {
                            if (!(label == "2D Area" || label == "2D Perimet" || label == "3D Area" | label == "3D Perimet")) {
                                return <div onClick={() => {
                                    setSelectedLabel(label)
                                }}
                                    style={{
                                        paddingLeft: "20px",
                                        cursor: "pointer",
                                        backgroundColor: selectedLabel == label && "#2989CF",
                                        color: selectedLabel == label && "white",
                                        height: "30px",
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        fontSize: "13px",
                                    }}
                                >
                                    {label}
                                </div>
                            }
                        }) : <></>
                    }
                </div>
                <div style={{ bottom: "0px", display: "flex", width: "100%", borderTop: "1px solid #D9D9D9", justifyContent: "right", padding: "10px", right: "0px", position: "absolute" }}>
                    <ButtonWithIcon text="Apply" isBtnActive onClick={() => {
                        onSelectLabel(selectedLabel)
                    }}
                    />
                </div>
            </div>
        </AnimatedModal >
    )
}

export default LayerLabelContainer