/*
- this component is used to assign the drone

*/

import React, { Component, useEffect } from "react";
import { addProjectDetails } from "../../redux";
import { addPlan } from "../../redux";
import { BreadCrumbs } from "../../ReusableComponents/BreadCrumbs"
import * as API from "../../api.js";
import service from "../../sidebaricon/Services.png";
import { connect } from "react-redux";
import Help from "../../sidebaricon/Help.png";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import closeButton from '../../close-button.png';
import NoReport from '../../assets/Images/Noreport.png';
import LocationIcon from '../../res/location1.png';
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import nextIcon from "../../assets/Icons/next1.png";
import prevIcon from "../../assets/Icons/back.png";
import closeSlider from "../../assets/Icons/cancel.png";
import panning from "../../assets/Icons/panning.png";
import GCPmarker from "../../assets/Icons/GCPmarker.png";
import Tiff from "tiff.js";
import SliderIcon from "../../assets/Icons/gallery.png";
import greenFlag from "../../assets/Icons/greenFlag.png";
import redFlag from "../../assets/Icons/redFlag.png";
import Loader from '../../icons/loader.svg';
import fullScreen from '../../assets/Icons/full-screen.png'
import LeafletMap from "../../ReusableComponents/map";
import L, { layerGroup } from "leaflet";
import "leaflet-editable"
import Annotation from "./ImageCanvas/index";
import closeButtonWhite from "../../icons/whiteClose.png"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AnimatedModal } from "../../ReusableComponents/AnimatedModal";
import { addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions"
import { DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams";
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import projectIcon from '../../assets/sideBarIcons/ProjectActive.png';
import imageActive from "../../assets/sideBarIcons/Images.png"
import GCPMap from '../../assets/Icons/GCPMap.png'
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import DeleteIcon from '../assets/sidebarIcons/delete.svg';
import { isCirclesOverlapping } from "../../ReusableComponents/map/leafletMeasure.js";
import trackingIcon from '../../Icon/trackingIconWhite.png'
import { BLOB_URL } from "../../AppConstants.js";


Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");

// set response language. Defaults to english.
Geocode.setLanguage("en");

var circles = []
let marker = [];

class aerofarmGCP extends Component {
    constructor(props) {
        super(props);
        // this.initMap = this.initMap.bind(this);
        this.transformRef = React.createRef();
        this.onChangeFullScreen = this.onChangeFullScreen.bind(this);
        this.state = {
            isOwner: false,
            sharedBy: null,
            progress: 0,
            Idx: 0,
            userperpage: 100000,
            currentpage: 1,
            userdisplay: [],
            loader: true,
            services: [],
            counts: 0,
            projects: [],
            collection_name: '',
            images: [],
            loading: true,
            user: {},
            locations: "nashik",
            center: undefined,
            zoom: 16,
            circleStrokeColor: "orange",
            sendingError: false,
            sendingErrorMessage: "",
            circleFillColor: "orange",
            showingInfoWindow: false,
            type: "",
            lattitude: "",
            longitude: "",
            avatarURL: "",
            background: "white",
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            collection_id: '',
            createTaskMsg: "",
            GCPdata: [],
            gcpset: true,
            selectGCP: {},
            delteGCPPopup: false,
            scale: 0,
            imageLoad: false,
            selectedFile: '',
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            thumbURLs: [],
            imageURL: "",
            gcpData: [],
            csvGCP: [],
            imagesToDisplay: [],
            selected: 0,
            showDiv: false,
            showSlider: true,
            predictedGCP: 0,
            onClickOkAction: "delete",
            imageHeight: null,
            imageWidth: null,
            showTip: true,
            COType: 0,
            openFilePopup: false,
            zoneNo: 0,
            epsgCode: 4326,
            annotation: {},
            annotations: [],
            initialOpen: true,
            fullScale: false,
            selectedGCP: {},
            x: 0,
            y: 0,
            baseLayer: '',
            map: '',
            mapLoader: true,
            dragImage: false,
            outId: "",
            resetImagePosition: false
        };
    }


    /*-------------- leaflet function -----------------*/
    setBaseLayerToState = (baseLayer) => {
        this.setState({ baseLayer })
    }

    //leaflet 
    mapInitialized = () => {
        const { map } = this.state
        map.editTools.on('editable:vertex:dragend', e => {
            let pk_id = e.layer.options.title
            let data = circles.find((c => { return ((c.options.title === e.layer.options.title && c.options.radius === e.layer.getRadius()) || isCirclesOverlapping(c, e.layer)) }))
            if (data)
                this.reArrangeAllcircles(true)
            else {
                API.updateRadius(pk_id, { "radius": e.layer.getRadius() }).then((data) => {
                    this.setState((state) => ({
                        ...state,
                        csvGCP: data.gcpData,
                    }), () => this.reArrangeAllcircles(true))
                });
            }
        })
        map.editTools.on('editable:dragend', e => {
            this.reArrangeAllcircles(true)
        })
    }

    /*-------------------------------------------------*/

    fullScreen = () => {
        var element = document.querySelector("#container-full-screen");
        // make the element go to full-screen mode
        element.requestFullscreen()
            .then(function () {
                // element has entered fullscreen mode successfully
            })
            .catch(function (error) {
                // element could not enter fullscreen mode
                console.log(error);
            });
    }
    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onChangeFullScreen, false)
    }
    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.onChangeFullScreen, false)
    }
    onChangeFullScreen = (e) => {
        if (!document.fullscreenElement) {
            this.setState({ fullScale: false })
        }
    }

    inspectGCP = (dontSetCenter) => {
        let { google, map } = this.state
        if (this.state.csvGCP && this.state.csvGCP.length) {
            this.state.csvGCP.forEach((gcp, i) => {
                const { latitude, longitude, radius, gcp_name, pk_id } = gcp;
                const center = [Number(latitude), Number(longitude)];
                const label = gcp_name;

                const cityCircle = L.circle(center, {
                    title: pk_id,
                    color: "#3c3c3c",
                    strokeOpacity: 0.8,
                    weight: 2,
                    fillColor: "#3c3c3c",
                    fillOpacity: 0.8,
                    center: center,
                    radius: Number(radius),
                    zIndex: 1,
                    position: "absolute",
                }).addTo(map).on('click', () => {
                    console.log("data sent through inspectgcp func", gcp);
                    this.getImagesToDisplay(gcp, 0);
                });

                cityCircle.enableEdit();
                cityCircle.bindTooltip(label);
                circles[i] = cityCircle;
            });
            if (!dontSetCenter) map.setView(new L.LatLng(Number(this.state.csvGCP[0].latitude), Number(this.state.csvGCP[0].longitude)), 16);
        }
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
        }))
    }

    // Remove all the circles from map
    reArrangeAllcircles = (dontSetCenter) => {
        for (var i in circles) {
            circles[i].remove();
        }
        circles = [];
        this.inspectGCP(dontSetCenter)
        this.inspectImages();
    }

    // Get images to display when ckicked on any image or on the circle
    getImagesToDisplay = (gcp, index) => {
        if (gcp != null) {
            let { images, GCPdata } = this.state
            var imagesToDisplay = []
            for (let i = 0; i < images.length; i++) {
                let lat = Number(images[i].latitude)
                let lng = Number(images[i].longitude)
                let isInside = this.checkIfInside(Number(gcp.latitude), Number(gcp.longitude), lat, lng, Number(gcp.radius) / 1000)
                if (isInside) {
                    let gcpData = GCPdata.find((data) => data.file_name == images[i].file_name)
                    if (gcpData && gcpData != undefined) {
                        images[i].regionsData = true
                        images[i].region = gcp
                    }
                    images[i].index = i
                    imagesToDisplay.push(images[i])
                }
            }
            if (imagesToDisplay && imagesToDisplay.length > 0) {
                this.setState((state) => ({
                    ...state,
                    Idx: index == 0 ? imagesToDisplay[0].index : index,
                    selected: index == 0 ? 0 : imagesToDisplay.findIndex((data) => this.state.images[index] == data),
                    imagesToDisplay: imagesToDisplay,
                    showTip: true,
                    showSlider: true,
                    initialOpen: true,
                    selectedGCP: gcp
                }))
                this.openImage(this.state.Idx)
            }
        }
        else {
            this.setState((state) => ({
                ...state,
                imagesToDisplay: [],
                initialOpen: false,
                selectedGCP: null
            }))
            this.openImage(index)
        }
    }



    inspectImages = () => {
        const { images, GCPdata } = this.state
        let { google, map } = this.state
        let count = 0;
        var predictedGCP = 0
        images.forEach((image, index) => {
            if (image.latitude == null || image.longitude == null || images[0].latitude == "" || images[0].longitude == "") {
            } else {
                image.regionsData = false
                image.region = null
                if (this.state.GCPdata && this.state.GCPdata.length > 0) {
                    this.state.GCPdata.map((gcp) => {
                        if (gcp.image_name == image.file_name) {
                            image.regionsData = true
                            image.region = gcp
                        }

                    })
                }
                let lat = Number(image.latitude)
                let lng = Number(image.longitude)
                if (this.state.csvGCP && this.state.csvGCP.length > 0) {
                    for (let i = 0; i < this.state.csvGCP.length; i++) {
                        let gcp = this.state.csvGCP[i]
                        let isInside = this.checkIfInside(Number(gcp.latitude), Number(gcp.longitude), lat, lng, Number(gcp.radius) / 1000)
                        if (isInside) {
                            image.predictGCPImage = isInside
                            predictedGCP = predictedGCP + 1
                            image.gcpPoint = gcp  // image is under this GCP point circle
                            break
                        }
                        image.predictGCPImage = isInside
                        image.gcpPoint = null
                    }
                }
                let latlng = [lat, lng];
                let tempMarker = L.circleMarker(latlng, {
                    color: 'black',
                    fillColor: image.regionsData ? '#ffe733' : image.predictGCPImage ? 'red' : '#2989CF',
                    fillOpacity: 1,
                    radius: 5,
                    scale: 5,
                    stroke: false,
                    weight: 1,
                }).addTo(map).on('click', () => {
                    this.getImagesToDisplay(this.state.images[index].gcpPoint, index)
                    this.setState((state) => ({
                        ...state,
                        scale: 0,
                        showTip: true
                    }))
                });
                tempMarker.bindTooltip(image.file_name)
                marker[count++] = tempMarker
            }
        })
        this.setState((state) => ({
            ...state,
            predictedGCP: predictedGCP,
            mapLoader: false
        }))
    }

    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    loading: false,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    onChangeAnnotation = (annotation) => {
        let { Idx, images, completedTask } = this.state
        let x_co = Math.round((annotation.x * images[Idx].width))
        let y_co = Math.round((annotation.y * images[Idx].height))
        if (!completedTask && this.state.selectedGCP && this.state.isOwner && annotation && annotation.x > 0 && annotation.y > 0 && x_co <= images[Idx].width && y_co <= images[Idx].height) {
            this.setState({
                annotations: [
                    {
                        ...annotation,
                        "type": "point",
                        "highlighted": false,
                        "editingLabels": false,
                        "color": "#ff0000",
                        "id": "1"
                    }
                ],
                annotation: annotation
            }, () => this.saveGCP())
        }
        this.setState({ resetImagePosition: false, });
    };

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    closeFilePopup = () => {
        this.setState((state) => ({
            ...state,
            openFilePopup: false,
            COType: 0,
            selectedFile: ''
        }))
    }

    openFilePopup = () => {
        this.setState((state) => ({
            ...state,
            selectedFile: '',
            showProcessPopup: false,
            openFilePopup: true,
            COType: 0
        }))
    }

    handleEPSGChange = (e) => {
        let t = e.target
        if (t.name == 'epsgCode') {
            if (t.value.length >= 0 && t.value.length <= 5) {
                this.setState((state) => ({
                    ...state,
                    [t.name]: t.value
                }))
            }
        }
    }

    //Check if given point is inside the circular geofence

    checkIfInside = (spotLat, spotLon, userLat, userLon, radius) => {
        let newRadius = this.distanceInKmBetweenEarthCoordinates(spotLat, spotLon, userLat, userLon);
        if (newRadius < radius) {
            return true;
        }
        else if (newRadius > radius) {
            return false;
        }
        else {
            return true;
        }
    }

    //Calculate the distnace between given latlongs and user's location
    distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
        var earthRadiusKm = 6371;
        var dLat = this.degreesToRadians(lat2 - lat1);
        var dLon = this.degreesToRadians(lon2 - lon1);
        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadiusKm * c;
    }

    //Degrees to radians conversion
    degreesToRadians = (degrees) => {
        return degrees * Math.PI / 180;
    }

    openImage = (idx) => {
        this.setState((state) => ({
            ...state,
            Idx: idx,
            imageLoad: true,
            scale: 0,
            annotations: [],
            showDiv: true,
            showSlider: this.state.images[idx].gcpPoint || this.state.showDiv ? true : false
        }), () => {
            if (this.state.initialOpen) {
                this.setState({ initialOpen: false })
                document.getElementById('container').scrollLeft += (100 * (this.state.selected));
            }
            this.loadTiffImageSrc(this.state.Idx)
            this.getAnnotations();
        })
    }

    setMapCenterFromImage = () => {
        const { images, map } = this.state
        if (images[0].latitude == null || images[0].longitude == null || images[0].latitude == "" || images[0].longitude == "") {
            for (let i = 1; i < images.length; i++) {
                if (images[i].latitude == null || images[i].longitude == null) {
                } else {
                    break;
                }
            }
        } else {
            //this.setState((state)=>({ ...state,center: [images[0].latitude, images[0].longitude] }))
            map.setView(new L.LatLng(images[0].latitude, images[0].longitude), 16);
        }
    }

    initMap = (mapProps, map) => {
        const { google } = mapProps;
        this.setState((state) => ({
            ...state,
            google: google,
            map: map
        }))
    };

    downloadTemplate = () => {
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) API.downloadCSVTemplate().catch((err) => {
            this.setState((state) => ({
                ...state,
                itemName: "",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong while downloading GCP template",
                showProcessPopup: true,
            }))
        })
    }

    downloadGCPCSVFile = () => {
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) API.downloadGCPCSV(this.state.collection_id, this.state.collection.collection_name).catch((err) => {
            this.setState((state) => ({
                ...state,
                itemName: "",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong while downloading GCP points",
                showProcessPopup: true,
            }))
        })
    }

    setBounds = () => {
        let { map } = this.state.map
        var markers = [];
        this.state.images.forEach((i) => {
            let m = L.marker([i.latitude, i.longitude])
            markers.push(m)
        })
        var group = L.featureGroup(markers).addTo(map);
        setTimeout(function () {
            map.fitBounds(group.getBounds());
        }, 1000);
    }

    getImages = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) API.getImageCollectionImages(this.state.collection_id, " ", userperpage, firstpage).then((data) => {
            console.log("IMAGES : ", data.images)
            this.setState((state) => ({
                ...state,
                loading: false,
                images: data.images,
                sasToken: data.sasToken,
                counts: data.totalCount,
                showCheckboxes: false,
                selectedImageCount: 0,
                mapLoader: false
            }), async () => {
                this.setMapCenterFromImage();
                await this.getCsvGCP()
                this.getGCP();
                this.loadThumbTiff()
            })
        }, (e) => {

            alert('Something went wrong loading with images')
            this.setState((state) => ({
                ...state,
                loading: false,
            }))
        })
        else this.setState((state) => ({
            ...state,
            loading: false,

        }))
    }

    // function to load tiff thumbnails
    loadThumbTiff = async () => {
        let { images, user } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.images.length; i++) {
            if (images[i].file_name) {
                let name = images[i].file_name.toLowerCase()
                // if ((name.indexOf(".tif")) == -1) {
                let turl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/thumb-${images[i].file_name}?${this.state.permissions.st}`
                thumbURL[i] = turl
                this.setState((state) => ({
                    ...state,
                    thumbURLs: thumbURL,
                }))
            }
        }
    }

    // to load tiff image 
    loadTiffImageSrc = (i) => {
        let { images, user } = this.state
        var imageURL = ""
        if (images[i].file_name) {
            let name = images[i].file_name.toLowerCase()
            if ((name.indexOf(".tif")) == -1) {
                let iurl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                imageURL = iurl
                this.setState((state) => ({
                    ...state,
                    imageURL: imageURL,
                }))
            } else {
                // for image
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                let url = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                xhr.open('GET', url);
                xhr.onload = function (e) {
                    var arraybuffer = e.target.response
                    Tiff.initialize({
                        TOTAL_MEMORY: 16777216 * 10
                    });
                    var tiff = new Tiff({
                        buffer: arraybuffer
                    })
                    var dataUrl = tiff.toDataURL();
                    let url = e.target.responseURL
                    var index = 0
                    this.state.images.map((image, j) => {
                        if (url.indexOf(encodeURIComponent(image.file_name)) != -1) {
                            index = j
                        }
                    })
                    imageURL = dataUrl   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        imageURL: imageURL,
                    }))
                }.bind(this);
                xhr.send()
            }
        }
    }

    // //To animate Slider button upwards
    // moveUp() {
    //     var elem = document.getElementById("myAnimation");
    //     var pos = 40;
    //     clearInterval(id);
    //     var id = setInterval(frame, 10);
    //     function frame() {
    //     if (pos == 105) {
    //         clearInterval(id);
    //     } else {
    //         pos++;
    //         elem.style.bottom = pos + 'px';
    //     }
    //     }
    // }

    // //To animate Close button downwards
    // moveDown() {
    //     var elem = document.getElementById("myAnimation");
    //     var pos = 105;
    //     clearInterval(id);
    //     var id = setInterval(frame, 10);
    //     function frame() {
    //     if (pos == 40) {
    //         clearInterval(id);
    //     } else {
    //         pos--;
    //         elem.style.bottom = pos + 'px';
    //     }
    //     }
    // }

    getAnnotations = () => {
        if (this.state.GCPdata.length > 0) {
            this.state.GCPdata.map((gcp) => {
                if (gcp.image_name == this.state.images[this.state.Idx].file_name) {
                    let x_co = gcp.image_x / this.state.images[this.state.Idx].width;
                    let y_co = gcp.image_y / this.state.images[this.state.Idx].height;
                    let regularRegion = {
                        x: Number(x_co), y: Number(y_co),
                        "type": "point",
                        "highlighted": true,
                        "editingLabels": false,
                        "color": "#ff0000",
                        "id": Math.random()
                    }

                    this.state.annotations.push(regularRegion)
                    console.log(this.state.annotations);
                    this.setState((state) => ({
                        ...state,
                        x: Number(gcp.image_x),
                        y: Number(gcp.image_y)
                    }))
                }
            })
        }

    }

    saveGCP = () => {
        let x = Math.round((this.state.annotation.x * this.state.images[this.state.Idx].width))
        let y = Math.round((this.state.annotation.y * this.state.images[this.state.Idx].height))
        let data = {
            "collection_id": this.state.collection_id,
            "image_name": this.state.images[this.state.Idx].file_name,
            "lat": this.state.images[this.state.Idx].latitude,
            "lng": this.state.images[this.state.Idx].longitude,
            "altitude": this.state.images[this.state.Idx].altitude ? this.state.images[this.state.Idx].altitude : 1,
            "image_x": x,
            "image_y": y,
            "gcp_point_id": this.state.selectedGCP ? this.state.selectedGCP.pk_id : this.state.selectedGCP
        }
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) API.saveGCP(data).then((data) => {
            if (data.success) {
                this.setState((state) => ({
                    ...state,
                    x: x,
                    y: y,
                }));
                this.arrangeGCPAfterSave()
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "GCP Data",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Fail to save GCP Data",
                    showProcessPopup: true,
                }));
            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "GCP Data",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }));
        }
        )
    }

    arrangeGCPAfterSave = () => {
        let collectionId = this.state.collection_id
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getGCP(collectionId).then((data) => {
            if (data.collectionGCP) {
                this.setState((state) => ({
                    ...state,
                    GCPdata: data.collectionGCP
                }));
                let images = this.state.imagesToDisplay
                if (images.length > 0) {
                    let region = data.collectionGCP.find((data) => data.image_name == images[this.state.selected].file_name)
                    images[this.state.selected].regionsData = true
                    images[this.state.selected].region = region
                    this.setState((state) => ({
                        ...state,
                        imagesToDisplay: images
                    }));
                }
            } else {
                this.setState((state) => ({
                    ...state,
                    GCPdata: [],
                }), () => {
                    this.state.images.forEach((image, index) => {
                        image.regionsData = false
                        image.region = null
                    })
                });
            }
        })
    }

    getGCP = () => {
        let collectionId = this.state.collection_id
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getGCP(collectionId).then((data) => {
            if (data.collectionGCP) {
                this.setState((state) => ({
                    ...state,
                    GCPdata: data.collectionGCP,
                }), () => {
                    this.inspectImages();
                });
            } else {
                this.setState((state) => ({
                    ...state,
                    GCPdata: [],
                }), () => {
                    this.inspectImages();
                });
            }
        })
    }

    getCsvGCP = async () => {
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getCSV_GCP(this.state.collection_id).then((data) => {
            this.setState((state) => ({
                ...state,
                csvGCP: data.gcp_points,
                center: [data.gcp_points[0] ? data.gcp_points[0].latitude : 0, data.gcp_points[0] ? data.gcp_points[0].longitude : 0]
            }))
            if (this.state.center[0] == 0 && this.state.center[1] == 0)
                this.setMapCenterFromImage()
            this.reArrangeAllcircles()
        })
    }

    getMarkedGCPInCircle = () => {
        var markedGCP = 0
        this.state.GCPdata.forEach(gcp => {
            if ((this.state.imagesToDisplay.findIndex((data) => data.file_name == gcp.image_name)) != -1)
                markedGCP += 1
        });
        return markedGCP
    }

    checkUpload = () => {
        if (this.state.csvGCP && this.state.csvGCP.length != 0) {
            this.setState((state) => ({
                ...state,
                itemName: "GCP Data",
                processPopupIcon: "WARNING",
                processAction: "UPDATE",
                processMessage: "All existing GCP data including marked GCP will be deleted, you still want to upload ?",
                showProcessPopup: true,
                onClickOkAction: "upload",
            }))
        }
        else {
            this.uploadGcpCsv()
        }
    }

    uploadGcpCsv = () => {
        this.setState((state) => ({
            ...state,
            openFilePopup: false,
            itemName: "GCP Data",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Uploading CSV File, Please Wait...",
            showProcessPopup: true,
            onClickOkAction: "upload",
            showDiv: false,
        }))
        const data = new FormData()
        data.append("file", this.state.selectedFile)
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].EDIT) API.uploadGcpCsv(this.state.collection_id, data, this.state.epsgCode).then((getDataFromCsv) => {
            this.setState((state) => ({
                ...state,
                csvGCP: getDataFromCsv.gcp_points,
                itemName: "GCP Data",
            }), () => {
                this.getGCP()
                this.reArrangeAllcircles()
            })
        }).catch(e => {
            this.setState((state) => ({
                ...state,
                itemName: "GCP Data",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: e.message,
                showProcessPopup: true,
                onClickOkAction: "upload"
            }))
        })
    }

    deleteGCP = (listDelete) => {
        if (this.state.isOwner && !this.state.completedTask) {
            if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE) API.deleteGCP(this.state.collection_id, listDelete ? this.state.deleteGCP.image_name : this.state.images[this.state.Idx].file_name).then((data) => {
                if (data.success) {
                    if (this.state.showSlider && this.state.imagesToDisplay.length > 0) {
                        let imagesToDisplay = this.state.imagesToDisplay
                        imagesToDisplay[this.state.selected].regionsData = false
                        imagesToDisplay[this.state.selected].region = null
                    }
                    this.setState(
                        (state) => ({
                            ...state,
                            annotations: [],
                        }), () => { this.getGCP() });
                } else {
                    this.setState((state) => ({
                        ...state,
                        itemName: "GCP Data",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: "Failed To Delete GCP Data",
                        showProcessPopup: true,
                    }));
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "GCP Data",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: "Failed To Delete GCP Data",
                    showProcessPopup: true,
                }));
            })
        }
    }

    onClickListenerFile = (event) => {
        event.target.value = ''
    }

    onlistclick = () => {
        this.state.images.map((image, index) => {
            if (image.file_name == this.state.selectGCP.image_name) {
                let i = this.state.imagesToDisplay.findIndex((data) => data.file_name == image.file_name)
                if (this.getMarkedGCPInCircle() > 0)
                    document.getElementById('container').scrollLeft = (100 * i);
                this.setState((state) => ({
                    ...state,
                    scale: 0,
                    initialOpen: false
                }), () => this.openImage(Number(index))
                )
            }
        })
    }

    nextImage = () => {
        let selected = this.state.selected + 1
        let idx = this.state.imagesToDisplay[selected].index
        this.setState((state) => ({
            ...state,
            selected: selected,
            initialOpen: false
        }), () => {
            this.openImage(idx)
        })
        // document.getElementById('container').scrollLeft += 100;
    }

    prevImage = () => {
        let selected = this.state.selected - 1
        let idx = this.state.imagesToDisplay[selected].index
        this.setState((state) => ({
            ...state,
            selected: selected,
            initialOpen: false
        }), () => {
            this.openImage(idx)
        })
        // document.getElementById('container').scrollLeft -= 100;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.Idx !== this.state.Idx && this.state.Idx) {
            const targetImage = document.getElementById("selected-img")
            if (targetImage)
                targetImage.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest', // Scroll the element to the nearest edge
                    inline: 'center', // Center the element horizontally in the viewport
                    // inlineFragment: 20, // Add padding to the horizontal scroll position
                })
        }
    }
    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {
        document.title = "DroneNaksha - GCP";
        getFeaturesPermissions([DRONENAKSHA_FEATURES.MAPS, DRONENAKSHA_FEATURES.IMAGECOLLECTIONS])
            .then(permissions => {
                this.setState(state => ({ ...state, permissions }), () => {

                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            console.log("ID : ", id)
                            this.setState((state) => ({
                                outId: id
                            }))
                        })


                    if (this.props.location.state.collection) {
                        this.setState((state) => ({
                            ...state,
                            collection_id: this.props.location.state.collection.id,
                        }), () => {
                            this.getImages();
                        })
                    }
                    if (this.props.location.state.user.avatar_url && this.props.location.state.user.avatar_url.length > 0) {
                        this.setState((state) => ({
                            ...state,
                            avatarURL: `${this.props.location.state.user.avatar_url}?${this.props.location.state.user.sas_token
                                }&t${new Date().getTime()}`,
                        }));
                    } else {
                        let avatarURL = `/images/${this.props.location.state.user.first_name[0].toLowerCase()}.PNG`;
                        this.setState((state) => ({
                            ...state,
                            avatarURL: avatarURL,
                        }));
                    }


                    if (this.props.location.state) {
                        let { user, type, project, plan, collection, completedTask } = this.props.location.state;
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            project,
                            plan,
                            collection,
                            completedTask
                        }), () => {
                            this.isUserOwnerOfCollection()
                            API.getUserSubbedServices().then(
                                (response) => {
                                    let services = response[0];
                                    if (services.length == 1 && services[0].title == 'AeroStream') {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: false
                                        }))
                                    } else {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: true
                                        }))
                                    }
                                }
                            )
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }))
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }
                })
            })
    }

    handleResetTransform = () => {
        if (this.transformRef.current) {
            this.transformRef.current.resetTransform();
        }
        this.setState({ resetImagePosition: true, });
    };

    render() {
        let { images, showBackIcon, collection, currentpage, userperpage, user, type, sidebarMode } = this.state

        const handleClick = (event) => {
            this.setState((state) => ({
                ...state,
                showProcessPopup: false
            }))
            document.getElementById("hiddenFileInput").click();
        };

        return (
            <>
                <div className="wrapper">
                    <div style={{ display: 'flex' }}>
                        <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                            <div style={{ display: "flex", height: "40px" }}>
                                <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                    <img
                                        src={AeroMeghLogo}
                                        style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                    />
                                </div>
                            </div>
                            <ul className="sidebar-nav" id="sidebar-nav">
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                state: {
                                                    type,
                                                    user,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={service}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                // onClick={this.changeColor}
                                                >
                                                    {"Services"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>


                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/dronenaksha/dashboard",
                                                state: {
                                                    type,
                                                    user,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={dashboardIcon}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                // onClick={this.changeColor}
                                                >
                                                    {"DroneNaksha"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/dronenaksha/plan",
                                                state: {
                                                    type,
                                                    user,
                                                    plan: this.props.location.state.plan,
                                                    project: this.props.location.state.project,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={projectIcon}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                    // style={{ color: "#2989CF" }}
                                                    onClick={this.changeColor}
                                                >
                                                    {"Plan"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/dronenaksha/image_collections",
                                                state: {
                                                    type,
                                                    user,
                                                    plan: this.props.location.state.plan,
                                                    project: this.props.location.state.project,
                                                },
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={ImageSetInActive}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                // onClick={this.changeColor}
                                                >
                                                    {"Imagesets"}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>
                                <div className={"selectBorder"} >
                                    <li>
                                        <Link
                                            to={{
                                                pathname: `/dronenaksha/images`,
                                                state: {
                                                    user, type, collection,
                                                    plan: this.props.location.state.plan,
                                                    project: this.props.location.state.project,
                                                }
                                            }}
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={imageActive}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                    style={{ color: "#2989CF" }}
                                                >
                                                    Images
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </div>
                                <div className={"nonSelectBorder"} >
                                    <li>
                                        <div
                                            className="onhoverext"
                                            onClick={
                                                () => {
                                                    window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                                }
                                            }
                                        >
                                            <div
                                                className="sidecontent"
                                                id="sidecontent"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="sidebar-icon">
                                                    <img
                                                        className="sidebar-icon"
                                                        src={Help}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                <div
                                                    name="service"
                                                    className={"sidebaropt"}
                                                >
                                                    {"Help"}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                                {/* <div className={"nonSelectBorder"} >
							<li>
								<Link
									to={{
										pathname: "/dronenaksha/tasks",
										state: {
											type,
											user,
											plan: this.props.location.state.plan,
											project: this.props.location.state.project,
										},
									}}
								>
									<div
										className="sidecontent"
										id="sidecontent"
										style={{ cursor: "pointer" }}
									>
										<div className="sidebar-icon">
											<img
												className="sidebar-icon"
												src={TaskIcon}
												alt="serviceIcon"
											/>
										</div>
										<div
											name="service"
											className={"sidebaropt"}
											// style={{ color: "#2989CF" }}
											onClick={this.changeColor}
										>
											{"Tasks"}
										</div>
									</div>
								</Link>
							</li>
						</div> */}

                                {/* <div className={"nonSelectBorder"} >
                            <li>
                                <Link
                                    to={{
                                        pathname: "/dronenaksha/allreports",
                                        state: {
                                            type,
                                            user,
                                            plan: this.props.location.state.plan,
                                            project: this.props.location.state.project,
                                        },
                                    }}
                                >
                                    <div
                                        className="sidecontent"
                                        id="sidecontent"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="sidebar-icon">
                                            <img
                                                className="sidebar-icon"
                                                src={reportIcon}
                                                alt="serviceIcon"
                                            />
                                        </div>
                                        <div
                                            name="service"
                                            className={"sidebaropt"}
                                            // style={{ color: "#2989CF" }}
                                            onClick={this.changeColor}
                                        >
                                            {"Reports"}
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </div> */}
                            </ul>
                        </div>

                    </div>
                    <div className="right-content"
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                        style={{ overflowY: "hidden" }}>


                        <div className="top-bar">
                            <div className="breadCrumbs">
                                <BreadCrumbs data={[
                                    {
                                        displayName: "projects",
                                        pathname: "/dronenaksha/projects",
                                        state: {
                                            type,
                                            user,
                                        },
                                        style: {
                                            color: "black",
                                            fontSize: "12px",
                                        }
                                    },
                                    {
                                        displayName: this.props.location.state.plan.project_name,
                                        pathname: "/dronenaksha/project",
                                        state: {
                                            type,
                                            user,
                                            plan: this.props.location.state.plan,
                                            project: this.props.location.state.project,
                                        },
                                        style: {
                                            color: "black",
                                            fontSize: "12px"
                                        }
                                    },
                                    {
                                        displayName: this.props.location.state.plan.plan_name,
                                        pathname: "/dronenaksha/view_plan",
                                        state: {
                                            type,
                                            user,
                                            plan: this.props.location.state.plan,
                                            project: this.props.location.state.project
                                        },
                                        style: {
                                            color: "black",
                                            fontSize: "12px"
                                        }
                                    },
                                    {
                                        displayName: "Imagesets",
                                        pathname: "/dronenaksha/image_collections",
                                        state: {
                                            type,
                                            user,
                                            plan: this.props.location.state.plan,
                                            project: this.props.location.state.project
                                        },
                                        style: {
                                            color: "black",
                                            fontSize: "12px"
                                        }
                                    },
                                    {
                                        displayName: this.state?.collection?.collection_name,
                                    },
                                ]} />
                                {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                            </div>
                        </div>

                        {/* -------------- Activity Popup ----------------------- */}
                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => this.closeActivityPopup()}
                            onClickOk={() => { this.state.onClickOkAction == "delete" ? this.deleteGCP() : this.uploadGcpCsv() }}
                        />
                        {/* --------------------------------------------------------------- */}
                        {/*----------------- Popup for file type selection -----------------*/}
                        <AnimatedModal
                            height="550px"
                            width="650px"
                            onRequestClose={this.closeFilePopup}
                            isOpen={this.state.openFilePopup}
                        >
                            <div>
                                <div style={{ background: "#2989cf", padding: "2% 3%", display: "flex", justifyContent: "space-between", alignItems: "center", height: "55px" }}>
                                    <div className='services-content-title' style={{ display: "flex", marginLeft: "3%", justifyContent: "space-between", color: "white", width: "130px", alignItems: 'center' }}>
                                        <img src={GCPMap} style={{ height: "24px", width: "auto" }} ></img>
                                        <div>Import CSV </div>
                                    </div>
                                    <div onClick={() => this.closeFilePopup()} style={{ cursor: "pointer", position: "relative", top: "-9px", right: "-7px" }}>
                                        <img src={closeButtonWhite} style={{ height: "22px", width: "auto" }} ></img>
                                    </div>
                                </div>
                                <div className='content-text' style={{ fontSize: "14px", fontWeight: "400", textAlign: "left", margin: "0% 3%" }}>
                                    <div style={{ margin: "20px 20px 10px 20px", display: "flex" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "350px" }}>
                                            <div>
                                                <div className="content-text"> EPSG </div>
                                                <input disabled={!this.state.isOwner || this.state.completedTask} style={{ border: "1px solid rgba(102, 102, 102, 0.3)", background: "#F5F5F5", height: "30px", width: "110px", fontSize: "12px" }} name="epsgCode" type="number" value={this.state.epsgCode} className="add-user-form-text2" placeholder="e.g. 4326" title="Please enter valid EPSG" onChange={this.handleEPSGChange} required />
                                            </div>
                                            <div>
                                                <div className="content-text"> CSV file </div>
                                                <div style={{ width: "230px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <input disabled style={{ border: "1px solid rgba(102, 102, 102, 0.3)", background: "#F5F5F5", width: "255px", height: "30px", fontSize: "12px" }} value={this.state.selectedFile.name} className="add-user-form-text2" title="Please enter valid CSV file" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div disabled={!this.state.isOwner || this.state.completedTask} style={{ display: "flex", position: "absolute", display: 'flex', top: "98px", right: "35px", justifyContent: "space-between", width: "175px" }}>
                                    <button
                                        disabled={this.state.epsgCode.length < 4|| !this.state.isOwner || this.state.completedTask}
                                        style={{ opacity: !this.state.isOwner || this.state.completedTask ||this.state.epsgCode.length < 4? 0.5 : 1, padding: "6px 15px" }}
                                        className="addcustomer-button-empty-dashboard popup-btn-left"
                                        onClick={handleClick}
                                    >
                                        Browse
                                    </button>
                                    <button
                                        disabled={this.state.epsgCode.length < 4 || !this.state.selectedFile}
                                        style={{ opacity: this.state.epsgCode.length < 4 || !this.state.selectedFile || !this.state.isOwner ? 0.5 : 1, paddingTop: "7px", paddingBottom: "6px" }}
                                        className="add-user-form-submit-button popup-btn-right"
                                        onClick={() => this.checkUpload()}
                                    >
                                        Import
                                    </button>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                                    <div style={{ width: "89%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                                </div>
                                <div className='content-text' style={{ fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center", textAlign: "left", margin: "0% 3%" }}>
                                    <div style={{ margin: "0px 0px 20px 20px" }}>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ fontWeight: "500", marginTop: "10px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                <div>GCP Points </div>
                                                {this.state.csvGCP && this.state.csvGCP.length > 0 ? <div className="hover" style={{ color: "#2989cf" }} onClick={this.downloadGCPCSVFile} >Download</div> : <></>}
                                            </div>
                                            {this.state.csvGCP ? <div style={{ height: "308px", overflow: "auto", marginTop: '10px', width: "572px", border: "1px solid rgba(102, 102, 102, 0.3)", borderRadius: "3px" }}>

                                                <table style={{ marginTop: '0px' }} className="customers-table" >
                                                    <col style={{ width: '25%' }} />
                                                    <col style={{ width: '30%' }} />
                                                    <col style={{ width: '30%' }} />
                                                    <col style={{ width: '15%' }} />
                                                    <tr className="customers-table-header-row" style={{ borderBottom: "1px solid rgba(102, 102, 102, 0.3)" }}>
                                                        <th>GCP Name</th>
                                                        <th><div>Latitude</div>{this.state.csvGCP.length > 0 && this.state.csvGCP[0].latitude != this.state.csvGCP[0].original_lat ? <div style={{ fontSize: "10px", color: "#666666" }}>(Northing)</div> : <></>}</th>
                                                        <th><div>Longitude</div>{this.state.csvGCP.length > 0 && this.state.csvGCP[0].latitude != this.state.csvGCP[0].original_lat ? <div style={{ fontSize: "10px", color: "#666666" }}>(Easting)</div> : <></>}</th>
                                                        <th>Altitude</th>
                                                    </tr>

                                                    {this.state.csvGCP.length < 1 ?
                                                        <td colSpan={4} style={{ height: "265px", padding: "5px", color: "#666666", textAlign: "center" }}>No GCP available</td>
                                                        :
                                                        this.state.csvGCP.map((gcp) => {
                                                            return (
                                                                <tr className="table-row" style={{ height: "34px", borderBottom: "1px solid rgba(102, 102, 102, 0.2)" }}>
                                                                    <td>
                                                                        {gcp.gcp_name}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            gcp.latitude === gcp.original_lat || !gcp.original_lat ?
                                                                                <div>
                                                                                    {gcp.latitude.substring(0, 15)}
                                                                                </div> :
                                                                                <div>
                                                                                    <div>{gcp.latitude.substring(0, 15)}</div>
                                                                                    <div style={{ fontSize: "10px", color: "#666666" }}>({gcp.original_lat})</div>
                                                                                </div>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            gcp.longitude === gcp.original_lon || !gcp.original_lon ?
                                                                                <div>
                                                                                    {gcp.longitude.substring(0, 15)}
                                                                                </div> :
                                                                                <div>
                                                                                    <div>{gcp.longitude.substring(0, 15)}</div>
                                                                                    <div style={{ fontSize: "10px", color: "#666666" }}>({gcp.original_lon})</div>
                                                                                </div>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {gcp.altitude}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </table>
                                            </div>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <div style={{ display: "flex", position: "absolute", bottom: "6%", right: "6%", display: 'flex', justifyContent: "space-between", width: "200px" }}>
                                    <button
                                        style={{ paddingTop: "7px", paddingBottom: "7px" }}
                                        className=" addcustomer-button-empty-dashboard popup-btn-left"
                                        onClick={() => this.closeFilePopup()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={this.state.epsgCode.length < 4 || !this.state.selectedFile}
                                        style={{ opacity: this.state.epsgCode.length < 4 || !this.state.selectedFile ? 0.5 : 1, paddingTop: "9px", paddingBottom: "7px" }}
                                        className="add-user-form-submit-button popup-btn-right"
                                        onClick={() => this.checkUpload()}
                                    >
                                        Import
                                    </button>
                                </div> */}
                            </div>
                        </AnimatedModal>
                        {/* /-------------------------------------------------------------- */}
                        <div className="main-content" style={{ padding: "0", height: "calc(100vh - 40px)", position: "relative" }}>
                            <div id='container-full-screen' style={{ display: "flex", height: "100%" }}>

                                <div style={{ width: "78%", height: "100%" }}>
                                    {this.state.showDiv ? (
                                        <div style={{ width: "100%", height: "100%" }}>
                                            {this.state.images && this.state.images.length ? <div style={{ width: "78.2%", borderRadius: "0px", top: "0px", zIndex: 5 }} className='imageinfo'>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "97%" }}>
                                                    <div className="imagePopupTitle">{images[this.state.Idx].file_name.length > 50 ? images[this.state.Idx].file_name.substring(0, 50) : images[this.state.Idx].file_name}</div>
                                                    <div className="imagePopupSubTitleTile">
                                                        <div className="imagePopupSubTitle">{images[this.state.Idx].resolution} MP</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "97%" }}>
                                                    <div className="imagePopupSubTitle">
                                                        {images[this.state.Idx].latitude == null || images[this.state.Idx].latitude == "" ? "-" : `${images[this.state.Idx].latitude.length > 11 ? `${images[this.state.Idx].latitude.substring(0, 11)}` : `${images[this.state.Idx].latitude}`}`}, {images[this.state.Idx].longitude == null || images[this.state.Idx].longitude == "" ? "-" : `${images[this.state.Idx].longitude.length > 11 ? `${images[this.state.Idx].longitude.substring(0, 11)}` : `${images[this.state.Idx].longitude}`}`}
                                                    </div>
                                                    <div className="imagePopupSubTitleTile">
                                                        {this.state.annotations.length ? <div className="imagePopupSubTitle" style={{ marginRight: '5px' }}>X,Y: {this.state.x} , {this.state.y}</div> : <></>}
                                                        <div className="imagePopupSubTitle">{images[this.state.Idx].file_size} MB</div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                            <img
                                                id="close-popup"
                                                src={closeButton} style={{
                                                    width: "auto",
                                                    height: "25px",
                                                    position: "absolute",
                                                    right: "22%",
                                                    cursor: "pointer",
                                                    zIndex: 5
                                                }} onClick={() => {

                                                    this.setState((state) => ({
                                                        ...state,
                                                        annotations: [],
                                                        scale: 0,
                                                        imagesToDisplay: [],
                                                        imageLoad: false,
                                                        showDiv: false
                                                    }), () => this.getCsvGCP())
                                                }} />

                                            <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                                                <div style={{ display: 'flex', width: "74.5vw", height: '100vh' }} >
                                                    {this.state.imageLoad ?
                                                        <div>
                                                            <img src={Loader} style={{ position: "absolute", zIndex: 5, height: "auto", width: "10vw", maxHeight: '95vh', maxWidth: '75.4vw', marginLeft: "35%", marginTop: "20%" }} />
                                                        </div> : ''
                                                    }
                                                    {!this.state.imageLoad && this.state.imagesToDisplay.length > 0 ?
                                                        <div className='flex-center' style={{ marginTop: "45px", marginLeft: "8px", height: "87vh", width: "74vw" }}>
                                                            <Annotation
                                                                key={0}
                                                                fullImageSegmentationMode={false}
                                                                autoSegmentationOptions={{ type: "autoseg" }}
                                                                showTags={true}
                                                                modifyingAllowedArea={false}
                                                                regionClsList={[]}
                                                                regionTagList={[]}
                                                                regions={this.state.annotations}
                                                                imageSrc={this.state.imageURL}
                                                                createWithPrimary={!this.state.dragImage}
                                                                dragWithPrimary={this.state.dragImage}
                                                                zoomWithPrimary={false}
                                                                onMouseUp={(e) => {
                                                                    this.onChangeAnnotation(e)
                                                                }}
                                                                onImageLoaded={(e) => console.log(e, "image-loaded")}
                                                                imageReset={this.state.resetImagePosition}
                                                                imageReseted={(val) => { this.setState({ resetImagePosition: val }) }}
                                                                currTempCalculated={true}
                                                            />
                                                        </div>
                                                        :
                                                        !this.state.imageLoad && this.state.imagesToDisplay.length == 0 ?
                                                            <div className='flex-center'>
                                                                <TransformWrapper ref={this.transformRef} withRef>
                                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                        <TransformComponent>
                                                                            <img id="action-image" style={{ zIndex: 0, opacity: this.state.imageLoad ? 0 : 1, maxWidth: "100%", maxHeight: '86vh' }} loading='lazy'
                                                                                src={this.state.imageURL}
                                                                                onLoad={((load) => {
                                                                                    this.setState((state) => ({
                                                                                        ...state,
                                                                                        imageLoad: false,
                                                                                    }),
                                                                                        resetTransform())
                                                                                })} />
                                                                        </TransformComponent>
                                                                    )}
                                                                </TransformWrapper>
                                                            </div>
                                                            :
                                                            <div>
                                                                <img id="action-image" style={{ zIndex: 0, opacity: 0, maxHeight: '95vh', maxWidth: '75.4vw', margin: 'auto' }} loading='lazy'
                                                                    src={this.state.images && this.state.images.length ? this.state.imageURL : ''}
                                                                    onLoad={((load) => {
                                                                        console.log("image Loaded")
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            imageLoad: false,
                                                                        }))
                                                                    })} />
                                                            </div>
                                                    }
                                                </div>
                                                {this.state.imagesToDisplay.length > 0 ? <div style={{ cursor: 'pointer', zIndex: 3, width: "24%", position: "absolute", right: "10px", bottom: this.state.showSlider && this.state.fullScale ? "155px" : this.state.showSlider && !this.state.fullScale ? "185px" : !this.state.showSlider && !this.state.fullScale ? "82px" : "50px" }}>
                                                    <img
                                                        src={this.state.dragImage ? GCPmarker : panning} style={{
                                                            width: "28px",
                                                            height: "28px",
                                                            borderRadius: "30px",
                                                            backgroundColor: 'rgba(0,0,0,.8)',
                                                        }} onClick={() => {
                                                            this.setState((state) => ({
                                                                dragImage: !state.dragImage
                                                            }))
                                                        }} />
                                                </div> : <></>}
                                                {!this.state.fullScale ? <div style={{ cursor: 'pointer', zIndex: 3, width: "24%", position: "absolute", right: "10px", bottom: this.state.imagesToDisplay.length == 0 ? "18px" : this.state.showSlider ? "154px" : "50px" }}>
                                                    <img
                                                        id="full-screen"
                                                        src={fullScreen} style={{
                                                            width: "28px",
                                                            height: "28px",
                                                            borderRadius: "30px",
                                                            backgroundColor: 'rgba(0,0,0,.8)',
                                                        }} onClick={() => {
                                                            this.setState({ fullScale: true })
                                                            this.fullScreen()
                                                        }} />
                                                </div> : <></>}
                                                <div style={{ cursor: 'pointer', zIndex: 3, width: "24%", position: "absolute", right: "10px", bottom: this.state.imagesToDisplay.length == 0 ? "50px" : this.state.showSlider ? this.state.fullScale ? "190px" : "217px" : "50px" }}>
                                                    <img
                                                        id="full-screen"
                                                        src={trackingIcon} style={{
                                                            padding: "5px",
                                                            width: "28px",
                                                            height: "28px",
                                                            borderRadius: "30px",
                                                            backgroundColor: 'rgba(0,0,0,.8)',
                                                            marginRight: "2px"
                                                        }}
                                                        onClick={(e) => this.handleResetTransform(e)}
                                                    />
                                                </div>

                                                {this.state.imagesToDisplay.length > 0 ? <div id="myAnimation" style={{ cursor: 'pointer', zIndex: 3, width: "24%", position: "absolute", right: "10px", bottom: this.state.showSlider ? "123px" : "18px" }} onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        showSlider: !this.state.showSlider
                                                    }), () => {
                                                        if (this.state.showSlider)
                                                            document.getElementById('container').scrollLeft += (100 * (this.state.selected));
                                                    })

                                                    // ), () => { this.state.showSlider ? this.moveUp() : this.moveDown() })
                                                }
                                                }>
                                                    <img src={this.state.showSlider ? closeSlider : SliderIcon} style={{
                                                        width: "28px",
                                                        height: "28px",
                                                        borderRadius: "30px",
                                                        backgroundColor: 'rgba(0,0,0,.8)',
                                                    }} />
                                                </div> : <></>}
                                                {this.state.showSlider && this.state.imagesToDisplay.length > 0 ?
                                                    (
                                                        <div
                                                            style={{
                                                                height: '115px',
                                                                zIndex: 3,
                                                                width: '78.5%',
                                                                bottom: '1%',
                                                                padding: '0px 10px',
                                                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                                                position: "absolute",
                                                                display: "flex",
                                                                backgroundColor: 'rgba(0,0,0,.5)'
                                                            }}>
                                                            {this.state.selected == 0 ? <div style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                marginRight: "10px",
                                                            }} /> : <div style={{ cursor: 'pointer', margin: 'auto' }} onClick={() => this.prevImage()}>
                                                                <img src={prevIcon} style={{
                                                                    width: "25px",
                                                                    height: "25px",
                                                                    marginRight: "5px",
                                                                    color: "white"
                                                                }} />
                                                            </div>}
                                                            <div id='container' style={{
                                                                width: '100%',
                                                                alignItems: "center",
                                                                padding: '0px 20px 15px 20px',
                                                                overflow: 'auto',
                                                                height: '100%',
                                                            }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        gap: '15px',
                                                                    }}
                                                                >
                                                                    {this.state.imagesToDisplay.map((il, i) => {
                                                                        return <div>
                                                                            {this.state.Idx == il.index ?
                                                                                <div id="selected-img">
                                                                                    {il.regionsData ?
                                                                                        <div onClick={() => {
                                                                                            {
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    deleteGCP: il.region
                                                                                                }), () => this.deleteGCP())
                                                                                            }
                                                                                        }} style={{ position: "relative", left: "84%", bottom: "-18px", cursor: "pointer", zIndex: 3, height: "23px", width: "25px", background: "rgba(0, 0, 0, 0.6)", borderRadius: "0px 0px 0px 30px" }}>
                                                                                            <img style={{ width: "auto", height: "18px", position: "absolute", padding: "2px 0px 1px 8px" }} src={greenFlag} />
                                                                                        </div> :
                                                                                        <div style={{ position: "relative", left: "84%", bottom: "-18px", cursor: "pointer", zIndex: 3, height: "23px", width: "25px", background: "rgba(0, 0, 0, 0.6)", borderRadius: "0px 0px 0px 30px" }}>
                                                                                            <img style={{ width: "auto", height: "18px", position: "absolute", padding: "2px 0px 1px 8px" }} src={redFlag} />
                                                                                        </div>
                                                                                    }
                                                                                    <img
                                                                                        src={this.state.thumbURLs[il.index]}
                                                                                        style={{
                                                                                            width: "105px",
                                                                                            height: "75px",
                                                                                            transition: 'transform .2s',
                                                                                            transform: 'scale(1.2)',
                                                                                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                                                                            zIndex: 2,
                                                                                            border: 'solid 2px #2989CF',
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState((state) => ({
                                                                                                ...state,
                                                                                                selected: i,
                                                                                                annotations: []
                                                                                            }), () => this.openImage(il.index));
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {il.regionsData ? <div style={{ position: "relative", left: "74%", bottom: "-24px", zIndex: 3, height: "23px", width: "25px", background: "rgba(0, 0, 0, 0.6)", borderRadius: "0px 0px 0px 30px" }}>
                                                                                        <img style={{ width: "auto", height: "17px", position: "absolute", padding: "2px 0px 1px 7px" }} src={greenFlag} />
                                                                                    </div> :
                                                                                        <div style={{ position: "relative", left: "74%", bottom: "-24px", zIndex: 3, height: "23px", width: "25px", background: "rgba(0,0, 0, 0.6)", borderRadius: "0px 0px 0px 30px" }}>
                                                                                            <img style={{ width: "auto", height: "17px", position: "absolute", padding: "2px 0px 1px 7px" }} src={redFlag} />
                                                                                        </div>
                                                                                    }
                                                                                    <img
                                                                                        src={this.state.thumbURLs[il.index]}
                                                                                        style={{
                                                                                            width: "105px",
                                                                                            height: "75px",
                                                                                            cursor: "pointer",
                                                                                            border: 'solid 1px white',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState((state) => ({
                                                                                                ...state,
                                                                                                Idx: il.index,
                                                                                                selected: i,
                                                                                                showDiv: true,
                                                                                                annotations: []
                                                                                            }), () => this.openImage(il.index));
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    })

                                                                    }
                                                                </div>
                                                            </div>
                                                            {this.state.selected == this.state.imagesToDisplay.length - 1 ? <div style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                marginRight: "10px",
                                                            }} /> : <div style={{ cursor: 'pointer', margin: 'auto' }} onClick={() => this.nextImage()}>
                                                                <img src={nextIcon} style={{
                                                                    width: "25px",
                                                                    height: "25px",
                                                                    marginLeft: "5px",
                                                                    color: "white"

                                                                }} />
                                                            </div>}
                                                        </div>
                                                    )
                                                    : ''}
                                            </div>
                                        </div>
                                    ) :
                                        (
                                            <LeafletMap
                                                setBaseLayerToState={this.setBaseLayerToState}
                                                initCenter={this.state.center ? this.state.center : [20.008482, 73.756834]}
                                                bounds={this.state.bounds}
                                                map={this.state.map}
                                                maxZoom={21}
                                                initZoom={16}
                                                handleMapClick={this.handleMapClick}
                                                handleMoveStart={this.handleMoveStart}
                                                setMap={(map) => {
                                                    this.setState({ map: map }, () => {
                                                        this.mapInitialized()
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                </div>
                                {/* {this.state.gcpset == "" ? <div /> : */}
                                <div style={{ height: '100%', width: '22%', overflow: 'auto', backgroundColor: 'white', zIndex: 2, borderRadius: '10px' }}>
                                    <div style={{ position: 'absolute', width: '22%', backgroundColor: "white", zIndex: "1" }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '3px 10px', height: "30px", alignItems: "center" }}>
                                            <div className="content-text" style={{ cursor: 'pointer', color: "#2989cf", fontSize: "12px", filter: this.state.permissions?.[DRONENAKSHA_FEATURES.MAPS].EDIT ? "" : "grayscale(100%)" }} onClick={this.state.permissions?.[DRONENAKSHA_FEATURES.MAPS].EDIT && this.downloadTemplate}>
                                                Download CSV Template
                                            </div>
                                            <div
                                                className="add-user-form-text-wrapper"
                                                style={{ marginTop: "0px", display: "flex", justifyContent: "right" }}
                                            >
                                                <input
                                                    name="company_logo"
                                                    id="hiddenFileInput"
                                                    type="file"
                                                    className=""
                                                    required
                                                    onClick={(event) => this.onClickListenerFile(event)}
                                                    onChange={(e) => {
                                                        let selectedFile = (e.target.files[0])
                                                        this.setState((state) => ({
                                                            ...state,
                                                            selectedFile: selectedFile
                                                        }))
                                                    }}
                                                    accept=".csv"
                                                    style={{ display: "none" }}
                                                />
                                                <div className="content-text" style={{ cursor: 'pointer', color: "#2989cf", fontSize: "12px", filter: this.state.permissions?.[DRONENAKSHA_FEATURES.MAPS].EDIT ? "" : "grayscale(100%)" }} onClick={this.state.permissions?.[DRONENAKSHA_FEATURES.MAPS].EDIT && this.openFilePopup}>
                                                    Manage CSV
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ width: "100%", height: '1px', margin: '5px 0px' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '3px 10px', }}>
                                            <div className="services-content-title" style={{ display: 'flex', justifyContent: 'space-between', color: '#3c3c3c' }}>GCP List</div>
                                        </div>
                                        <div style={{ height: '2px', width: '100%' }}>
                                            <hr style={{ width: "100%", height: '1px', marginTop: "5px" }} />
                                        </div>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', padding: '5px', alignItems: 'center' }}>
                                            <div style={{ width: '100%' }}>
                                                <div className="content-text" style={{ marginLeft: "10px", fontSize: "13px" }}>
                                                    <div style={{ display: "flex", width: "100%" }}><div>{this.state.imagesToDisplay.length > 0 ? "GCP Name : " : "Total Predicted GCP : "}</div><div style={{ fontWeight: 500, marginLeft: "5px" }}>{this.state.imagesToDisplay.length > 0 ? this.state.selectedGCP.gcp_name : this.state.predictedGCP}</div></div>
                                                    <div style={{ display: "flex", width: "100%" }}><div>Marked GCP : </div><div style={{ fontWeight: 500, marginLeft: "5px" }}>{this.state.imagesToDisplay.length > 0 ? this.getMarkedGCPInCircle() : this.state.GCPdata.length}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ width: "100%", height: '1px', margin: '5px 0px' }} />
                                    </div>

                                    {this.state.GCPdata.length > 0 ? <div style={{ margin: "145px 10px 0px 10px" }}>
                                        {this.state.GCPdata.map((imageData, key) => {
                                            return (
                                                (this.state.imagesToDisplay.length == 0) || (this.state.showDiv && (this.state.imagesToDisplay.findIndex((data) => data.file_name == imageData.image_name)) != -1) ?
                                                    <div style={{ padding: '3px', overflow: 'auto', height: '50%' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='orthoimagedatatext' style={{ cursor: 'pointer', fontSize: '13px', overflow: "hidden", color: '#3c3c3c' }} onClick={() => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        selectGCP: imageData,
                                                                    }), () => {
                                                                        this.onlistclick();
                                                                    })
                                                                }}>{imageData.image_name}</div>
                                                                {this.state.isOwner && !this.state.completedTask ? <div style={{}}>
                                                                    <img src={DeleteIcon} style={{ marginTop: "3px", width: '20px', height: '20px', cursor: "pointer" }} onClick={() => {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            deleteGCP: imageData,
                                                                            onClickOkAction: "delete",
                                                                        }), () => this.deleteGCP(true))
                                                                    }} />
                                                                </div> : <></>}
                                                            </div>
                                                        </div>
                                                        <div style={{ height: '6px', display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ fontSize: '10px', color: 'grey' }}>
                                                                <img style={{ height: '14px', width: '14px' }} src={LocationIcon} /> {imageData.lat ? imageData.lat.length > 10 ? `${imageData.lat.substring(0, 10)}` : `${imageData.lat}` : ''}, {imageData.lng ? imageData.lng.length > 10 ? `${imageData.lng.substring(0, 10)}` : `${imageData.lng}` : ''} , {imageData.altitude ? imageData.altitude : ''}
                                                            </div>
                                                        </div>
                                                        <div style={{ height: '2px', width: '100%' }}><hr style={{ width: "100%", height: '1px' }} /></div>
                                                    </div> : <></>
                                            )
                                        })
                                        }

                                    </div> : <div
                                        className="rmp-dashboard-empty-data-message"
                                        style={{ margin: "150px 0 0 0" }}
                                    >
                                        <img style={{ height: '18%', width: '18%', marginTop: "3%" }} src={NoReport} />
                                        <p style={{ margin: '10px' }}> No Ground Control Points Available</p>
                                    </div>}
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        projectName: state.project.projectName,
        clientName: state.project.clientName ? state.project.clientName : "asd",
        planName: state.project.planName,
        dateAndTime: state.project.dateAndTime,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addPlan: (payload) => dispatch(addPlan(payload)),
        addProjectDetails: (payload) => dispatch(addProjectDetails(payload)),
    };
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
    LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(aerofarmGCP));