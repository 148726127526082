import React, { useEffect, useState } from 'react'
import * as API from '../../../api.js'
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal.js";
import { Popup } from "../../../ReusableComponents/reusableFunctions.js";
import closeIcon from "../../assets/icons/closeNew.svg"
import LoaderComponent from "../../../ReusableComponents/LoaderComponent.js";
import ActionButton from "./ActionButton.js";
import { SelectComponent } from "../../../ReusableComponents/SelectComponent.js";
import { getActiveTeamId } from '../../../Teams/index.js';

const EnchroachmentEdit = ({ onClose, onDone, existingBlock, taskId, polygonId }) => {
    const [blockDetails, setBlockDeatails] = useState(existingBlock || {
        name: "",
        info: "",
        polygon_id: polygonId,
        property_id: null,
        property_name: "",
        division_name: "",
        division_id: "",
        city_name: "",
        city_id: "",
        city_area_name: "",
        city_area_id: "",
    });

    const [propeties, setProperties] = useState([]);
    const [loading, setloading] = useState(false);

    const getProperties = async () => {
        API.getProperties(taskId, null, null, null, null, false, null, await getActiveTeamId())
            .then((res) => {
                setProperties(res.properties)
            })
            .catch((e) => {
                Popup.alert("ERROR", e.message || e)
            })
    }

    const saveBlockDetails = async () => {
        setloading(true);
        await API.addEnchrochment(blockDetails)
            .then(async (res) => {
                onDone(res.encroachment, "red")
                Popup.alert("COMPLETE", "Encroachment saved successfully !");
                onClose()
                setloading(false);
            })
            .catch((e) => {
                setloading(false);
                Popup.alert("ERROR", e.message || e)
            })
    }

    const updateBlockDetails = () => {
        setloading(true)
        API.updateEnchrochment(blockDetails, existingBlock.id)
            .then((res) => {
                onDone(blockDetails, "red")
                setloading(false)
                Popup.alert("COMPLETE", "Encroachment updated successfully !");
                onClose()
            })
            .catch((e) => {
                setloading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }

    const isformFilled = () => {
        const excludedFields = ['info'];
        for (const key in blockDetails) {
            if (!excludedFields.includes(key)) {
                const value = blockDetails[key];
                if (value === undefined || value === null || value === '') {
                    return false;
                }
            }
        }
        return true;
    }

    useEffect(() => {
        getProperties()
    }, [])

    return (
        <AnimatedModal
            isOpen={true}
            height="408px"
            width="580px"
            padding="0"
        >
            {loading ?
                <LoaderComponent /> :
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 25px", borderBottom: "1px solid #d7d7d7" }}>
                        <div style={{ marginBlock: "10px" }}>{existingBlock ? "Update Encroachment Details" : "Add Encroachment Details"} </div>
                        <img src={closeIcon} alt="close button" style={{ width: "15px", cursor: "pointer" }} onClick={() => { onClose() }} />
                    </div>
                    <form style={{ display: "grid", height: "90%", gridTemplateColumns: "1fr 1fr", rowGap: "16px", columnGap: "52px", padding: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Property</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={blockDetails.property_id ? [{ label: blockDetails.property_name, value: blockDetails.property_id }] : []}
                                    placeholder="Select Property"
                                    searchable={false}
                                    itemsList={propeties?.map((p) => ({
                                        label: p.name,
                                        value: p.id,
                                    }))}
                                    onchangeHandler={(e) => {
                                        let updatedBlockDetails = {
                                            ...blockDetails,
                                            property_id: e[0]?.value,
                                            property_name: e[0]?.label,
                                        }
                                        const selectedProperty = propeties.filter((p) => p.id == e[0]?.value)[0];
                                        setBlockDeatails({
                                            ...updatedBlockDetails,
                                            division_id: selectedProperty.division_id,
                                            division_name: selectedProperty.division_name,
                                            city_id: selectedProperty.city_id,
                                            city_name: selectedProperty.city_name,
                                            city_area_id: selectedProperty.area_id,
                                            city_area_name: selectedProperty.city_area_name
                                        })
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"12px"}
                                    border={"1px solid #c3cdd9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#f4f4f4"}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Division </div>
                            <input
                                maxLength={"50"}
                                disabled={true}
                                value={blockDetails.division_name}
                                style={{ borderRadius: "8px", border: "1px solid #c3cdd9", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", backgroundColor: "#f4f4f4" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>City </div>
                            <input
                                maxLength={"50"}

                                disabled={true}
                                value={blockDetails.city_name}
                                style={{ borderRadius: "8px", border: "1px solid #c3cdd9", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", backgroundColor: "#f4f4f4" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>City area</div>
                            <input
                                maxLength={"50"}

                                disabled={true}
                                value={blockDetails.city_area_name}
                                style={{ borderRadius: "8px", border: "1px solid #c3cdd9", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", backgroundColor: "#f4f4f4" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Name</div>
                            <input
                                maxLength={"50"}
                                name="name"
                                placeholder="Enter name"
                                value={blockDetails.name}
                                onChange={(e) => {
                                    setBlockDeatails({
                                        ...blockDetails,
                                        name: e.target.value
                                    })
                                }}
                                style={{ borderRadius: "8px", border: "1px solid #c3cdd9", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", backgroundColor: "#f4f4f4" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Information</div>
                            <input
                                maxLength={"100"}
                                placeholder="Enter information"
                                value={blockDetails.info}
                                onChange={(e) => {
                                    setBlockDeatails({
                                        ...blockDetails,
                                        info: e.target.value
                                    })
                                }}
                                style={{ borderRadius: "8px", border: "1px solid #c3cdd9", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", backgroundColor: "#f4f4f4" }}
                            />
                        </div>

                    </form>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", padding: "10px 25px 25px 25px", gap: "10px" }}>
                        <ActionButton
                            name={"Cancel"}
                            onClickCallback={() => {
                                onClose()
                            }}
                            background={false}
                            width={"90px"}
                        />
                        <ActionButton
                            isBtnActive
                            isDisabled={!isformFilled()}
                            name={existingBlock ? "Update" : "Save"}
                            onClickCallback={() => {
                                existingBlock ? updateBlockDetails() : saveBlockDetails()
                            }}
                            background={true}
                            width={"90px"}
                        />
                    </div>
                </div>
            }
        </AnimatedModal>
    )
}

export default EnchroachmentEdit