import React from 'react';
import closeIcon from '../assets/icons/closeNew.svg'
import DisplayField from './components/DisplayField.js';

const PropertyHoverPopup = ({ onCloseCallback, property }) => {
    return (
        <div style={{ background: "white", borderRadius: "8px", width: '446px', height: '207px', borderRadius: "8px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", padding: "20px", background: "#FFF3DC", borderRadius: "8px" }}>
                <div style={{ display: 'flex', alignItems: "center", gap: "5px", color: "#3c3c3c", fontSize: "16px", fontWeight: 600 }}>
                    <div>Sr. No</div>
                    <div>-</div>
                    <div>{property.name}</div>
                </div>
                {/* <img src={closeIcon} alt='close icon' style={{ cursor: "pointer", width: "12px" }} onClick={onCloseCallback} /> */}
            </div>
            <div style={{ padding: "20px", display: 'flex', flexDirection: "column", gap: '4px' }}>
                <DisplayField label={"Name of Colony"} value={property.name_of_colony} labelFontSize={"14px"} valueFontSize={"14px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                <DisplayField label={"City"} value={property.city_name} labelFontSize={"14px"} valueFontSize={"14px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                <DisplayField label={"Division"} value={property.division_name} labelFontSize={"14px"} valueFontSize={"14px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                <DisplayField label={"Total Block"} value={property.total_blocks} labelFontSize={"14px"} valueFontSize={"14px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
            </div>
        </div>
    )
}

export default PropertyHoverPopup