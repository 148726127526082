import React from 'react';
import Select from 'react-dropdown-select';
import selectIcon1 from '../icons/selectIcon1.png';

export const SelectComponent = (props) => {
  const {
    searchable,
    disabled,
    itemsList,
    values,
    placeholder,
    valueField,
    labelField,
    onchangeHandler,
    searchBy,
    create,
    onCreateNew,
    width,
    itemFontSize,
    itemFontWeight,
    itemHeight,
    itemBorderBottom,
    iconSize,
    selectIcon,
    padding,
    backgroundColor,
    height,
    minWidth,
    borderRadius,
    selectColor,
    border,
    fontSize,
    fontWeight,
    dropdownGap,
    dropdownHeight,
    dropdownPosition,
  } = props;

  return (
    <div style={{ display: "flex", width: width || "100%" }} className={searchable ? "searchable" : "disableSearchable"}>
      <Select
        disabled={disabled}
        options={itemsList}
        values={values}
        placeholder={placeholder || "Select"}
        valueField={valueField || 'value'}
        labelField={labelField || "label"}
        onChange={onchangeHandler}
        searchBy={searchBy}
        create={create}
        onCreateNew={onCreateNew}
        itemRenderer={({ item, itemIndex, state, methods }) => (
          <div className='selectItem'
            onClick={() => methods.addItem(item)}
            style={{
              width: width || "auto",
              padding: "5px 10px",
              color: methods.isSelected(item) ? 'white' : '#3c3c3c',
              background: methods.isSelected(item) ? '#2989cf' : '',
              fontSize: itemFontSize || '12px',
              fontWeight: itemFontWeight || '400',
              height: itemHeight || "28px",
              borderBottom: itemBorderBottom || '',
              display: "flex",
              alignItems: "center"
            }}
          >
            {item[labelField || 'label']}
          </div>
        )}
        dropdownHandleRenderer={({ state }) => (
          <img style={{ marginLeft: "10px", height: iconSize || "14px" }} src={selectIcon || selectIcon1} alt="dropdown handle" />
        )}
        searchable={searchable}
        color={"#2989CF"}
        style={{
          padding: padding || "none",
          backgroundColor: backgroundColor || "none",
          width: width || "auto",
          minHeight: height || "32px",
          minWidth: minWidth,
          display: "flex",
          justifyContent: "space-between",
          borderRadius: borderRadius || "5px",
          color: selectColor || "#3c3c3c",
          border: border === true ? '1px solid #ced4da' : (border || 'none'),
          boxShadow: "none",
          fontSize: fontSize || "18px",
          fontWeight: fontWeight || 500
        }}
        backspaceDelete={true}
        dropdownGap={dropdownGap || 5}
        dropdownHeight={dropdownHeight || '300px'}
        dropdownPosition={dropdownPosition || 'bottom'}
      />
    </div>
  );
};