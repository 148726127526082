import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { StaticRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as API from '../../../api.js';
import ListIcon from '../../../icons/gridBlue.png'
import GridIcon from '../../../icons/listBlue.png'
import Loader from '../../../icons/loader.svg';
import { updateOutTime, getLocalStorageValue, isTaskEditable, skipAnnotationTaskTour, skipDetectionTaskTour, skipTrainingTaskTour, getPicstorkDefaultPlan, getUniqueCollectionName } from '../../../ReusableComponents/reusableFunctions';
import ImageSetThumb from '../../../icons/ImageSetThumb.png'
import ImageSetInActive from '../../../assets/sideBarIcons/ImageSetInactive.png';
import Noimageset from '../../../assets/Images/Noimageset.png';
import dateFormat from 'dateformat';
import { ActivityPopup } from '../../../ReusableComponents/ActivityPopup.js';
import { SortComponent } from '../../../ReusableComponents/SortComponent';
import AppConstants, { BLOB_URL } from '../../../AppConstants';
import ReactJoyride, { STATUS } from 'react-joyride';
import { checkSpecialCharacterInclude } from '../../../ReusableComponents/reusableFunctions';
import ButtonWithIcon from '../../../ReusableComponents/ButtonWithIcon';
import { PICSTORK_FEATURES } from '../../../Teams';
import EntitySelector from '../../../ReusableComponents/EntitySelector';
import DropDownWithButton from '../../../ReusableComponents/DropDownWithButton';
import whitePlus from "../../../assets/Icons/whiteplus.png"
class SelectImageSet extends Component {

    state = {
        user: {},
        type: "",
        progress: 0,
        showAddImagePopup: false,
        showDownloadPopup: false,
        loader: true,
        services: [],
        projects: [],
        redirect: false,
        collection_name: '',
        createCollectionMsg: '',
        outId: "",
        collections: [],
        showBackIcon: true,
        cancel: false,
        loading: true,
        sidebarMode: (this.props.data ? this.props.data.sidebarMode : "shrunk"),
        imageset: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        oldCollectionId: '',
        showCopyImagePopup: false,
        newCollectionName: '',
        gridView: true,
        sortType: 'Last Modified',
        runTour: false,
        tourSteps: [
            {
                disableBeacon: true,
                target: '#selectImageSet',
                content: (<div style={AppConstants.TEXTSTYLING}><p>Select from existing imageset</p></div>),
                title: (<div style={AppConstants.TITLESTYLING}>Select Imageset</div>),
                placement: 'left',
                styles: {
                    options: {
                        width: 250,
                    },
                },
            }, {
                disableBeacon: true,
                target: '#addImageSetButon',
                content: (<div style={AppConstants.TEXTSTYLING}><p>Alternatively, create a new Imageset</p></div>),
                title: (<div style={AppConstants.TITLESTYLING}>Create Imageset</div>),
                placement: 'left',
                styles: {
                    options: {
                        width: 250,
                    },
                },
            },],
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            showEntitySelector: false
        }))
    }

    getImageCollections = () => {
        if (this.props.permissions?.[PICSTORK_FEATURES.IMAGECOLLECTIONS]?.READ) API.getLatestImageSets("project", 1000000, this.props.data.project.id, 0)
            .then((data) => {
                this.setState((state) => ({
                    ...state,
                    collections: data.imageCollections,
                    collection_name: this.props.data.task.task_name + this.appendTime(),
                    loading: false
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
                this.loadTiff()
                setTimeout(() => {
                    if ((this.props.taskType === 1 && getLocalStorageValue('imageSetTour1') == null) || (this.props.taskType === 2 && getLocalStorageValue('imageSetTour2') == null) || (this.props.taskType === 4 && getLocalStorageValue('imageSetTour3') == null)) {
                        this.setState({
                            runTour: true
                        })
                    }
                }, AppConstants.TRIGGER_DELAY)
            }, (e) => {
                console.log(e);
                alert('Something went wrong');
                this.setState((state) => ({
                    ...state,
                    loading: false
                }))
            })
        else this.setState({
            collections: [],
            collection_name: this.props.data.task.task_name + this.appendTime(),
            loading: false
        })
    }


    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.collections.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.collections.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.collections.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            collections: sort,
        }));
    }

    // function to load tiff thumbnails
    loadTiff = async () => {
        let { collections, user } = this.state
        for (let i = 0; i < this.state.collections.length; i++) {
            if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
                let turl = ((collections[i].file.file_name.toLowerCase()).includes("tif") || (collections[i].file.file_name.toLowerCase()).includes("tiff")) ?
                    `${AppConstants.tilesServer}/getTifPng/${this.state.collections[i].id}/${collections[i].file.file_name}/thumb.png?blobContainer=${this.props.permissions?.container}&sas=${encodeURIComponent(this.props.permissions?.st)}`
                    : `${BLOB_URL}/${this.props.permissions?.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.props.permissions?.st}`

                collections[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    collections
                }))
            }
        }
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (this.props.taskType === 1) localStorage.setItem('imageSetTour1', 1)
            else if (this.props.taskType === 2) localStorage.setItem('imageSetTour2', 1)
            else if (this.props.taskType === 4) localStorage.setItem('imageSetTour3', 1)
            this.setState({
                runTour: false,
            })
        } else if ([STATUS.SKIPPED].includes(status)) {
            if (this.props.taskType === 1) skipTrainingTaskTour()
            else if (this.props.taskType === 2) skipDetectionTaskTour()
            else if (this.props.taskType === 4) skipAnnotationTaskTour()
            this.setState({
                runTour: false,
            })
        }
    };


    appendTime = () => {
        return `_${(new Date()).toLocaleDateString().slice(0, 19).replaceAll("/", "")}${(new Date()).toLocaleTimeString().slice(0, 19).replaceAll(":", "").replaceAll(".", "").replaceAll(" ", "_")}`
    }

    saveImageSet = async (plan) => {
        return new Promise(resolve => {
            API.createImageCollection(this.props.data.project.id, plan.id, this.state.collection_name)
                .then(async (data) => {
                    if (data.collection) {
                        resolve(data.collection)
                    } else if (data.status == 409) {
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: 'Imageset already exist with same name.',
                            showProcessPopup: true,
                        }))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: 'Something went wrong while creating imageset.',
                            showProcessPopup: true,
                        }))
                    }
                }).catch(err => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Imageset",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while creating imageset.',
                        showProcessPopup: true,
                    }))
                })

        })
    }

    createCollection = async () => {
        this.setState((state) => ({
            ...state,
            showAddImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))
        if (this.state.collection_name != "" && this.state.collection_name != " " && this.state.collection_name) {
            this.saveImageSet(this.props.plan)
                .then(collection => {
                    this.props.setCollection(collection)
                })
        } else {
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: 'Something went wrong while creating imageset.',
                showProcessPopup: true,
            }))
        }
    }

    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, AppConstants.SERVICES.PICSTORK).then((data) => {
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                this.getImageCollections()
            })
        }, (e) => {
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }
    handleImportImagesets = async (selectedEntities) => {
        let plan = {}
        try {
            const response = await getPicstorkDefaultPlan(this.state.project)
            plan = response
        } catch (err) { console.log(err) }
        switch (selectedEntities.entityType) {
            case "projects": break;
            case "plans": break;
            case "imagesets":
                // let importedImagesets = []
                let errorOccured = false;
                for (const entity of selectedEntities.entites) {
                    if (errorOccured) break;
                    await new Promise((resolve, reject) => {
                        API.createImageCollection(this.state.project.id, plan.id, `${getUniqueCollectionName(entity.collection_name)}`).then(data => {
                            // const res = await data.json()
                            if (data.collection) {
                                API.duplicateImageSet(this.props.permissions.container, entity.id, data.collection.id, AppConstants.SERVICES.PICSTORK).then((res) => {
                                    if (res.status == 201 || res.status == 202) {
                                        resolve()
                                    } else {
                                        errorOccured = true;
                                        API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.PICSTORK).then((data) => {
                                            resolve()
                                        }).catch(err => {
                                            errorOccured = true
                                            resolve()
                                        })
                                    }

                                }).catch(err => {
                                    errorOccured = true
                                    resolve()
                                }
                                )
                            }
                            else if (data?.status === 409) {
                                errorOccured = true;
                                resolve()
                            }
                        }).catch(err => {
                            console.log(err)
                            errorOccured = true
                            resolve()
                        })
                    })
                }
                if (errorOccured) {
                    this.setState({ showProcessPopup: true, processMessage: "Something went wrong while importing imagesets", processPopupIcon: "ERROR" })
                } else {
                    this.setState({
                        processMessage: "Collections imported succesfully", processPopupIcon: "COMPLETE",
                    }, () => {
                        this.getImageCollections()
                    })
                }

                break;
            default:
        }
    }
    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/picstork/images",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    plan: this.state.plan,
                    project: this.state.project,
                    sidebarMode: this.state.sidebarMode,
                    collection: this.state.newCollecton
                }
            }} />
        }
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }
    componentWillMount() {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.popup-content { width: 30% !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);



        document.title = "PicStork - Image Collections";
        if (this.props.data) {
            let { user, type, task, project, plan } = this.props.data;
            this.setState((state) => ({
                ...state,
                user,
                type,
                task,
                project,
                plan
            }), () => {
                this.getImageCollections()
            })
        }
    }
    handleChange = (e) => {
        checkSpecialCharacterInclude(e.target.value).then(string => {
            this.setState({ collection_name: string })
        })
    }

    selectCollection = (collection) => {
        this.props.setCollection(collection)
        // if (this.props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT && isTaskEditable(this.props.data.task.status)) {
        //     if (this.state.task.tasktype === 4 && collection.images_count > 0 && (collection.file.file_name.toLowerCase().includes(".tiff") || collection.file.file_name.toLowerCase().includes(".tif"))) {
        //         this.setState((state) => ({
        //             itemName: "Imageset",
        //             processAction: "",
        //             processMessage: "Selected Imageset contains tiff images which are not supported for annotation report task",
        //             processPopupIcon: "ERROR",
        //             showProcessPopup: true
        //         }))
        //     } else {
        //         this.props.setCollection(collection)
        //     }
        // }

    }

    handleChangeCopy = (e) => {
        checkSpecialCharacterInclude(e.target.value).then(string => {
            this.setState({ newCollectionName: string })
        })
    }



    render() {

        let { user, type, collections, sidebarMode, showBackIcon, project, plan } = this.state

        return <>
            {this.state.runTour ?
                <ReactJoyride
                    continuous={true}
                    run={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    showSkipButton={true}
                    steps={this.state.tourSteps}
                    disableOverlayClose={true}
                    floaterProps={AppConstants.FLOATER_PROPS}
                    locale={AppConstants.LOCALE_BUTTONS}
                    callback={(e) => this.handleJoyrideCallback(e)}
                    styles={{
                        buttonNext: AppConstants.NEXT_BUTTON,
                        buttonSkip: AppConstants.SKIP_BUTTON,
                        spotlight: AppConstants.SPOTLIGHT,
                        options: AppConstants.MAIN_OPTIONS
                    }}
                /> : <></>

            }

            {this.successRedirectComponent()}

            {/* Entity Selector */}
            {this.state.showEntitySelector &&
                <EntitySelector
                    allowMultipleSelect
                    serviceList={[AppConstants.SERVICES.DRONENAKSHA]}
                    btnTitle={"Import"}
                    show={this.state.showEntitySelector}
                    entity={"imagesets"}
                    onRequestClose={() => {
                        this.setState({ showEntitySelector: false })
                    }}
                    callback={async (selectedEntities) => {
                        this.setState({
                            showProcessPopup: true,
                            processPopupIcon: "WAIT",
                            processMessage: `Importing ${selectedEntities.entityType} please wait...`
                        }, () => {
                            this.handleImportImagesets(selectedEntities)
                        })
                    }}
                />}
            {/* -------------- Activity Popup ----------------------- */}
            <ActivityPopup
                item={this.state.itemName}
                open={this.state.showProcessPopup}
                icon={this.state.processPopupIcon}
                action={this.state.processAction}
                msg={this.state.processMessage}
                close={() => this.closeActivityPopup()}
                height={this.state.processPopupIcon == "WARNING" ? "210px" : null}
                onClickOk={() => this.deleteImageCollection()}
            />
            {/* --------------------------------------------------------------- */}
            {this.state.loading ? <div style={{ height: "85vh", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100%", margin: "auto" }} />
            </div> : <div className="main-content" id='selectImageSet' style={{ background: 'none' }} onClick={
                () => {
                    this.setState((state) => ({
                        ...state, sidebarMode: 'shrunk'
                    }))
                }
            }>
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.showCopyImagePopup}
                    onHide={() => {
                        this.setState((state) => ({
                            ...state,
                            showCopyImagePopup: false,
                            newCollectionName: ''

                        }));
                    }}
                >
                    <Modal.Body >
                        <div style={{ marginBottom: "30px" }}>
                            <div className="services-content-title">{"Copy an Imageset"}</div>
                            {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
                            <input type="text"
                                value={this.state.newCollectionName}
                                className="add-user-form-text"
                                placeholder="eg. Imageset Name"
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => { this.handleChangeCopy(e) }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-left"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showCopyImagePopup: false,
                                }));
                            }}
                        >
                            cancel
                        </button>
                        {this.state.newCollectionName ?
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.createCollectionCopy()
                                }}
                            >
                                Ok
                            </button> :
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                style={{ opacity: '0.5', cursor: 'default' }}
                            >
                                Ok
                            </button>}

                    </Modal.Footer>
                </Modal>
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.showAddImagePopup}
                    onHide={() => {
                        this.setState((state) => ({
                            ...state,
                            showAddImagePopup: false,
                            collection_name: ''
                        }));
                    }}
                >
                    <Modal.Body >
                        <div style={{ marginBottom: "30px" }}>
                            <div className="services-content-title">{"Create an Imageset"}</div>
                            <label className="font-color-secondary" style={{ fontSize: "13px" }}>To upload images you need to create an imageset.</label>
                            <input type="text"
                                value={this.state.collection_name}
                                onFocus={(e) => e.target.select()}
                                className="add-user-form-text"
                                placeholder="eg. Imageset Name"
                                maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                onChange={this.handleChange} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
                            <ButtonWithIcon
                                text="Cancel"
                                width="80px"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showAddImagePopup: false,
                                    }));
                                }}
                            />
                            <ButtonWithIcon
                                text="Ok"
                                width="80px"
                                isBtnActive
                                isDisabled={!this.state.collection_name}
                                onClick={this.state.collection_name ? this.createCollection : false}
                            />
                        </div>

                    </Modal.Footer>
                </Modal>
                {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", width: "100%" }}>
                    <div className="services-content-title">Imagesets
                        <span className='data-count'>({this.state.collections.length})</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DropDownWithButton
                            icon={whitePlus}
                            isButton
                            isBtnActive
                            selectedItem={"Imageset"}
                            isBtnList
                            textColor={"white"}
                            isDisabled={!this.props.permissions[PICSTORK_FEATURES.IMAGECOLLECTIONS].CREATE}
                            list={
                                [
                                    {
                                        name: "Import",
                                        onClick: () => this.setState({ showEntitySelector: true })
                                    },
                                    {
                                        name: "Create",
                                        onClick: () => {
                                            this.setState((state) => ({
                                                ...state,
                                                showAddImagePopup: true,
                                            }))
                                        },

                                    }
                                ]
                            }

                        />

                        <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                            <div
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        gridView: !state.gridView,
                                    }))
                                }}>
                                <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', margin: '0px 4px 0px 14px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.collections.length > 0 ? <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                        <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                    </div >
                    {this.state.collections.length > 0 && !this.state.loading ?
                        <div>
                            <div style={{ height: "30px" }}>
                                <SortComponent
                                    label={'Name'}
                                    sortAscending={this.sortByName}
                                    sortDecending={this.reverseByName}
                                    sortASC={true}
                                />
                            </div>

                            {collections.length > 0 ?
                                this.state.gridView ?
                                    <div style={{ overflowY: 'auto', height: '76vh' }}>
                                        <div className="aerocapture-cards-tray-projects1" style={{ height: 'auto', paddingTop: "10px", gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px));" }}>
                                            {
                                                collections.map((collection, key) => {
                                                    return (
                                                        <div style={{ cursor: this.props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT && isTaskEditable(this.props.data.task.status) ? 'pointer' : 'default' }}>
                                                            <div key={key} onClick={() => {
                                                                this.selectCollection(collection)
                                                            }} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', marginBottom: "30px" }}>
                                                                <div className="card-header customer-cards-dashboard-logo project-card-header" style={{
                                                                    // backgroundImage=`url(${'./assets/map_placeholder.png'})`
                                                                }}>
                                                                    {
                                                                        collection && collection.file && collection.file.file_name && collection.thumbURL ?
                                                                            <img id="ProjectImageRPM" style={{ objectFit: 'cover' }} src={collection.thumbURL} /> :
                                                                            < img id='ProjectImageRPM' src={ImageSetThumb} />
                                                                        // <img id="ProjectImage" src={thumb} />
                                                                    }
                                                                    <div>

                                                                    </div>
                                                                </div>
                                                                <div style={{ paddingTop: "3px !important" }}
                                                                    className="card-body change-color customer-cards-dashboard-text project-card-body"
                                                                >
                                                                    <div>
                                                                        <span>{
                                                                            collection.collection_name.length > 14 ?
                                                                                `${collection.collection_name.substring(0, 13)}...` :
                                                                                `${collection.collection_name}`
                                                                        }</span>
                                                                        <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between", }}>
                                                                            <span>{
                                                                                collection && collection.file ? collection.file.count : '0'
                                                                            }</span>
                                                                            <span>{
                                                                                (() => {
                                                                                    let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                                                    return `${dateFormat(date, "dd-mmm-yyyy")}`
                                                                                })()
                                                                            }</span>
                                                                        </div>
                                                                    </div>
                                                                    {/*<div style={{ height: "24px", overflow: "hidden",fontSize:'14px' }}>{project.name+".jpg"}</div>*/}
                                                                </div>
                                                                {/*<div style={{ height: "24px", overflow: "hidden",fontSize:'14px' }}>{project.name+".jpg"}</div>*/}
                                                            </div>

                                                        </div>)
                                                })
                                            }
                                        </div>
                                    </div> :
                                    <div id='table' style={{ marginTop: '30px', height: "76vh", overflow: 'auto' }}>
                                        <table className="customers-table" style={{ marginTop: '0px' }}>
                                            <col style={{ width: '5%' }} />
                                            <col style={{ width: '30%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '5%' }} />
                                            <tr className="customers-table-header-row" style={{ background: "#EAEAEA" }}>
                                                <th></th>
                                                <th>Name
                                                    <SortComponent
                                                        style={{ marginLeft: '5px' }}

                                                        sortAscending={this.sortByName}
                                                        sortDecending={this.reverseByName}
                                                        sortASC={false}
                                                    />
                                                </th>
                                                <th>Images</th>
                                                <th>Date Created
                                                    <SortComponent
                                                        style={{ marginLeft: '5px' }}

                                                        sortAscending={this.sortByDate}
                                                        sortDecending={this.reverseByDate}
                                                        sortASC={false}
                                                    />
                                                </th>
                                                <th></th>
                                            </tr>
                                            {
                                                this.state.collections.map((collection, i) => {
                                                    return collection && collection.collection_name ?
                                                        <tr className="customers-table-data-row" onClick={() => {
                                                            this.selectCollection(collection)
                                                        }} style={{ height: "45px", cursor: this.props.permissions?.[PICSTORK_FEATURES.TASKS].EDIT && isTaskEditable(this.props.data.task.status) ? 'pointer' : 'default' }}>
                                                            <td>
                                                                {
                                                                    collection && collection.thumbURL ?
                                                                        <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={collection.thumbURL} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ImageSetInActive }} /> :
                                                                        <img id="ProjectTableImage" src={ImageSetInActive} />
                                                                    // <img id="ProjectImage" src={thumb} />
                                                                }
                                                            </td>
                                                            <td>
                                                                {collection.collection_name}<span style={{ color: "#2989cf" }}>{collection && collection.sharedData && collection.sharedData.isShared ? ' (Shared)' : ''}</span>
                                                            </td>
                                                            <td>{collection && collection.file ? collection.file.count : '0'}</td>
                                                            <td>{
                                                                (() => {
                                                                    let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                                    return `${dateFormat(date, "dd mmm yyyy")}`
                                                                })()
                                                            }</td>
                                                            <td>
                                                            </td>
                                                        </tr> : ''
                                                }
                                                )}
                                        </table>
                                    </div> :
                                <div className="rmp-dashboard-empty-data-message" style={{ zIndex: '5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                    <img style={{ height: '130px' }} src={Noimageset} />
                                    <p style={{ marginTop: '10px', fontWeight: 500 }}>Create imageset and upload images.</p>
                                </div>
                            }
                        </div>
                        : <></>}
                </> : <div className="rmp-dashboard-empty-data-message" style={{ zIndex: '5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <img style={{ height: '130px' }} src={Noimageset} />
                    <p style={{ marginTop: '10px', fontWeight: 500 }}>Create imageset and upload images.</p>
                </div>}
            </div>}
        </>
    }
}


const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(SelectImageSet);

