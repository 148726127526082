import React, { Component } from 'react';
import Help from "../../sidebaricon/Help.png";
import Settings from "../../sidebaricon/Settings.png";
import dashboardActive from '../../icons/AeroCapture.svg';
import service from "../../sidebaricon/Services.png";
import ImagesAnnotation from "./ImagesAnnotation";
import AppConstants, { TASK } from '../../AppConstants';
import Images from './Images.js';
import ScreenDivider from './ReusableComp/ScreenDivider';
import TaskDetails from './ReusableComp/TaskDetails';
import VerticalStepper from '../../ReusableComponents/Stepper/VerticalStepper';
import TaskAnnotationSet from './TaskAnnotationSet';

import createTaskDone from '../assets/icons/stepperIcons/createTaskDone.png'
import createTaskActive from '../assets/icons/stepperIcons/createTaskActive.png'
import createTaskInactive from '../assets/icons/stepperIcons/createTaskInactive.png'

import uploadImagesDone from '../assets/icons/stepperIcons/uploadImagesDone.png'
import uploadImagesActive from '../assets/icons/stepperIcons/uploadImagesActive.png'
import uploadImagesInactive from '../assets/icons/stepperIcons/uploadImagesInactive.png'

import reportsDone from '../assets/icons/stepperIcons/reportsDone.png'
import reportsActive from '../assets/icons/stepperIcons/reportsActive.png'
import reportsInactive from '../assets/icons/stepperIcons/reportsInactive.png'

import processDone from '../assets/icons/stepperIcons/processDone.png'
import processActive from '../assets/icons/stepperIcons/processActive.png'
import processInactive from '../assets/icons/stepperIcons/processInactive.png'

import annotationDone from '../assets/icons/stepperIcons/annotationDone.png'
import annotationActive from '../assets/icons/stepperIcons/annotationActive.png'
import annotationInactive from '../assets/icons/stepperIcons/annotationInactive.png'

import CreateTaskView from './ReusableComp/CreateTaskView';
import SelectImageSet from './ReusableComp/SelectImageSet';
import TaskProcessView from './ReusableComp/TaskProcessView';
import * as API from '../../api.js';
import dateFormat from 'dateformat';
import { getLimitedName, getLocalStorageValue, getPicstorkDefaultPlan, isTaskEditable, skipTrainingTaskTour } from '../../ReusableComponents/reusableFunctions';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup';
import ReactJoyride, { STATUS } from 'react-joyride';
import MapAnnotation from './MapAnnotation';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import TourGraphics from '../../assets/Images/tourGraphics.png';
import { ActiveRole, getActiveTeam, getFeaturesPermissions, PICSTORK_FEATURES } from '../../Teams';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';

class TrainingTask extends Component {
    constructor() {
        super();
        this.JoyrideUpdater = React.createRef();
        this.state = {
            classes: [],
            expand: true,
            currentStep: 1,
            joyrideTrigger: false,
            showProcessPopup: false,
            processPopupIcon: null,
            processMessage: null,
            annotated: 0,
            unannotated: 0,
            runTour: false,
            tourSteps: [],
            currentlyActiveTour: 0,
            triggerCreateTaskTour: false,
            showTourPopup: false,
            showTour: false,
            openStartTaskPopup: false,
            imagesToShow: [],
            analyticsCreditExceed: false,
            // isProcessableImageset: false
        };
    }

    // getImages = async () => {
    //     return new Promise((resolve, reject) => {
    //         if (this.state.task.image_collection_id) {
    //             API.getImageCollectionImages(this.state.task.image_collection_id, " ", 1000000, 1).then((data) => {
    //                 let isProcessableImageset = this.checkProcessableImageset(data.images)
    //                 this.setState({ isProcessableImageset }, () => {
    //                     resolve()
    //                 })
    //             })
    //         }
    //         else resolve()
    //     })
    // }

    // checkProcessableImageset = (images) => {
    //     if (this.state.task.tasktype != 1 || this.state.linkedMap !== null )
    //      {   console.log("Inside here ")
    //         return true}
    //     else {
    //         let idata = images.filter((img) => Number(img.resolution) > 9)
    //         return idata.length == 0
    //     }
    // }

    isUserHasEditAccess = () => {
        return (this.state.permissions?.[PICSTORK_FEATURES.TASKS].EDIT)
    }

    setStepData = async (onlySetSteps) => {
        let { task, annotationSet, collection } = this.state
        const isEditableTask = this.isUserHasEditAccess()
        if (task) {
            task.state = task.state == 5 && task.status == AppConstants.TASK.STATUS.FAILED ? 4 : task.state
            let currentStep = onlySetSteps ? this.state.currentStep : task.state ? task.state : 1
            var annotated = this.state.annotated
            var unannotated = this.state.unannotated
            if (!onlySetSteps && task.image_collection_id && task.annotation_set && task.annotation_set.id) {
                let result = await API.getAnnotationSetImages(task.image_collection_id, task.annotation_set.id, 0, 0,task.task_id)
                annotated = result.annotatedCount
                unannotated = result.images.length - annotated
                this.setState({ annotated, unannotated ,showTotalCount:result.showTotalCount})
            }
            this.setState((state) => ({
                ...state,
                currentStep,
                annotationSet: task.annotation_set ? task.annotation_set : annotationSet,
                steps: [
                    {
                        stepName: 'Create task',
                        stepNumber: 1,
                        description1: `Task : ${task ? getLimitedName(task.task_name, 15) : "-"}`,
                        description2: `Created on ${task ? dateFormat(task.date_created, "dd mmm yyyy") : "-"}`,
                        completed: task.state > 1,
                        actionButton: "Create Task",
                        enable: task && task.task_id,
                        stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                        expand: true
                    },
                    {
                        stepName: 'Images',
                        stepNumber: 2,
                        description1: `Count : ${task ? task.images : '0'}`,
                        description2: `Created on ${collection && collection ? dateFormat(collection.created_on, 'dd mmm yyyy') : "-"}`,
                        completed: task.state > 2,
                        actionButton: "Annotate",
                        enable: isEditableTask && currentStep == 2 && (task.images > 19 || this.state.linkedMap),
                        stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                        expand: true
                    },
                    {
                        stepName: 'Annotation',
                        stepNumber: 3,
                        description1: `${annotated || '0'} Annotated`,
                        description2: `${unannotated} Non-annotated`,
                        completed: task.state > 3,
                        actionButton: "Setup",
                        enable: state.steps?.[2].enable,
                        stepIcon: [annotationActive, annotationDone, annotationInactive],
                        expand: true
                    },
                    {
                        stepName: 'Setup',
                        stepNumber: 4,
                        description1: `Status : ${task && (task.state > 4 || task.status == AppConstants.TASK.STATUS.FAILED) ? task.status : '-'}`,
                        description2: `Last updated on ${task && (task.state > 4 || task.status == AppConstants.TASK.STATUS.FAILED) ? dateFormat(task.status_update_timestamp, "dd mmm yyyy") : '-'}`,
                        completed: task.status != AppConstants.TASK.STATUS.FAILED && task.state > 4,
                        actionButton: task.status == AppConstants.TASK.STATUS.FAILED ? 'Restart' : "Start",
                        enable: this.state.permissions?.[PICSTORK_FEATURES.TASKS].CREATE && currentStep == 4 && task.state > 3 && task.accuracy && task.training_type,
                        stepIcon: [processActive, processDone, processInactive],
                        expand: true
                    },
                    {
                        stepName: 'Report',
                        stepNumber: 5,
                        description1: task.status == AppConstants.TASK.STATUS.COMPLETED ? AppConstants.TASK.STATUS.COMPLETED : task.status == AppConstants.TASK.STATUS.IN_PROGRESS ? AppConstants.TASK.STATUS.IN_PROGRESS : '-',
                        description2: '',
                        completed: task.status == AppConstants.TASK.STATUS.COMPLETED || task.state == 5,
                        actionButton: null,
                        enable: task.status == AppConstants.TASK.STATUS.COMPLETED || task.state == 5,
                        stepIcon: [reportsActive, reportsDone, reportsInactive],
                        expand: true
                    },
                ],
            }))
        }
    }

    getTask = (onlyUpdateTask) => {
        if (this.state.permissions?.[PICSTORK_FEATURES.TASKS].READ) API.getAeroCaptureTask(this.state.task.task_id)
            .then(async (data) => {
                this.setState({
                    task: data,
                    linkedMap: data.linked_map,
                    collection: data.image_collection_id ? { id: data.image_collection_id, collection_name: data.collection_name, created_on: data.imageset_created_on } : undefined,
                    annotationSet: data.annotation_set,
                }, async () => {
                    // await this.getImages()
                    if (!onlyUpdateTask) this.setStepData(false)
                })
            }, (e) => {
                console.log("Error : ", e);
            })
        else {
            window.location.replace("/")
        }
    }

    startTask = () => {
        API.startAerocaptureTask(this.state.task.task_id).then((data) => {
            let task = this.state.task
            task.status = AppConstants.TASK.STATUS.PENDING
            this.setState({ task, openStartTaskPopup: true })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Task",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: 'Error occured while restarting the task',
                showProcessPopup: true,
            }))
        })
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            if (this.state.currentlyActiveTour == 1) localStorage.setItem('createTaskTour1', 2)
            else if (this.state.currentlyActiveTour == 3) {
                localStorage.setItem('annotationTour', 3)
                localStorage.setItem('mapAnnotationTour', 3)
            } else if (this.state.currentlyActiveTour == 4) {
                localStorage.setItem('processTour1', 2)
            }
            this.setState({
                runTour: false,
            })
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipTrainingTaskTour()
            this.setState({
                runTour: false,
            })
        }
    };

    openCancelTaskPopup = () => {
        this.setState((state) => ({
            processPopupIcon: "WARNING",
            showProcessPopup: true,
            processMessage: 'Are you sure you want to cancel this task ?',
            itemName: 'Task'
        }))
    }

    cancelTask = async () => {
        API.updateTaskDetails(this.state.task.task_id, { state: 4, status: AppConstants.TASK.STATUS.CREATING }).then((updatedTask) => {
            this.setState({ task: { ...updatedTask.task }, showProcessPopup: false }, () => this.setStepData(false))
        }).catch((e) => {
            console.log("Error : ", e);
        })
    }
    initData = () => {
        let { user, type, task, collection, project, plan } = this.props.location.state;
        this.setState({
            user,
            type,
            task: task || JSON.parse(localStorage.getItem('created_task')),
            project,
            plan,
            collection,
        }, async () => {
            try {
                const permissions = await getFeaturesPermissions([PICSTORK_FEATURES.TASKS, PICSTORK_FEATURES.IMAGECOLLECTIONS, PICSTORK_FEATURES.ANNOTATIONS, PICSTORK_FEATURES.REPORTS])
                const activeTeam = await getActiveTeam()
                this.setState({ permissions, activeTeam }, () => {
                    if (this.state.task && this.state.task.task_id)
                        this.getTask()
                    else {
                        if (getLocalStorageValue('trainingTaskTour') == null) this.setState((state) => ({
                            ...state,
                            showTourPopup: true
                        }))

                        this.setState((state) => ({
                            ...state,
                            steps: [
                                {
                                    stepName: 'Create task',
                                    stepNumber: 1,
                                    description1: `Task : -`,
                                    description2: `Created on : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Create Task",
                                    stepIcon: [createTaskActive, createTaskDone, createTaskInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Images',
                                    stepNumber: 2,
                                    description1: `Count : 0`,
                                    description2: '',
                                    completed: false,
                                    enable: false,
                                    actionButton: "Annotate",
                                    stepIcon: [uploadImagesActive, uploadImagesDone, uploadImagesInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Annotation',
                                    stepNumber: 3,
                                    description1: `Annotated: 0`,
                                    description2: `Unannotated : 0`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Setup",
                                    stepIcon: [annotationActive, annotationDone, annotationInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Setup',
                                    stepNumber: 4,
                                    description1: `Status : -`,
                                    description2: `Last updated : -`,
                                    completed: false,
                                    enable: false,
                                    actionButton: "Start",
                                    stepIcon: [processActive, processDone, processInactive],
                                    expand: true
                                },
                                {
                                    stepName: 'Report',
                                    stepNumber: 5,
                                    description1: '-',
                                    description2: '-',
                                    completed: false,
                                    enable: false,
                                    actionButton: null,
                                    stepIcon: [reportsActive, reportsDone, reportsInactive],
                                    expand: true
                                },
                            ],
                        }))
                    }
                })

            } catch (e) {
                window.location.replace("/")
            }

        })
    }
    componentDidMount() {
        document.title = "Training task";
        this.initData()

    }
    componentDidUpdate = () => {
        if (
            this.state.task?.task_id
            &&
            this.props.location.state.task
            &&
            this.state.task.task_id !== this.props.location.state.task.task_id
        ) this.initData()
    }

    closeActivityPopup = () => {
        this.setState({ showProcessPopup: false })
    }

    addAnnotationSet = (collection) => {
        this.setState({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Please wait we are preparing images for annotation...",
            showProcessPopup: true,
            task: {
                ...this.state.task,
                image_collection_id: this.state.collection.id
            }
        }, () => {
            API.addAnnotationSet(collection.id, this.state.task.task_name + "_annotationset", "", this.state.task.plan_id, this.state.task.task_id).then((data) => {
                console.log(data);
                if (data.status == 201 || data.annotationSet) {
                    this.setState({ annotationSet: data.annotationSet, showProcessPopup: false }, () => {
                        this.updateTask(3, false, data.annotationSet.id)
                    })
                } else if (data?.type === "Storage") {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Task",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: data.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                alert("Something went wrong.")
            })
        })
    }

    createTask = async (step) => {
        let { task, project } = this.state
        const plan = await getPicstorkDefaultPlan(project)
        this.setState({ plan })
        if (task.task_name && task.task_name != "" && project.project_name && project.project_name != "") {
            API.createTask({
                ...task,
                project_name: project.project_name,
                project_id: project.id,
                plan_id: plan.id,
                plan_name: plan.plan_name,
                taskType: "training",
                status: AppConstants.TASK.STATUS.CREATING,
                type: [],
                state: step + 1
            }).then((createdTask) => {
                localStorage.setItem('created_task', JSON.stringify(createdTask.task))
                this.setState({
                    task: createdTask.task,
                    currentStep: step + 1,
                    selectedImageForAnnotation: undefined,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = true
                            s.description1 = `Task : ${task ? getLimitedName(task.task_name, 15) : "-"}`
                            s.description2 = `Created on ${this.state.task ? dateFormat(this.state.task.date_created, "dd mmm yyyy") : "-"}`
                        }
                        return s
                    })
                })
            }).catch((e) => {
                this.setState({
                    showProcessPopup: true,
                    processPopupIcon: 'ERROR',
                    processMessage: e.message,
                    steps: this.state.steps.map((s) => {
                        if (s.stepNumber == step) {
                            s.completed = false
                        }
                        return s
                    })
                })
            })

        } else {
            console.log("Project or plan is missing");
        }
    }

    selectedImageForAnnotation = (ortho) => {
        this.setState({
            selectedImageForAnnotation: ortho
        })
    }

    updateTask = (state, collection_id, annotation_set_id) => {
        let { task } = this.state
        if (isTaskEditable(task.status)) {
            API.updateTaskDetails(task.task_id, { state: state ? state : undefined, accuracy: task.accuracy, training_type: task.training_type, collection_id: collection_id ? collection_id : undefined, annotation_set_id, linked_map: this.state.linkedMap })
                .then((updatedTask) => {
                    console.log(state, collection_id, annotation_set_id, "Update TASK DETAILS : ", updatedTask)
                    if (task.accuracy) {
                        API.updateTask(task.task_id, AppConstants.TASK.STATUS.PENDING, AppConstants.SERVICES.PICSTORK).then((result) => {
                            let task_data = updatedTask && updatedTask.task ? updatedTask.task : task
                            task_data.status = AppConstants.TASK.STATUS.PENDING
                            this.setState({ task: task_data, openStartTaskPopup: true }, () => this.setStepData(false))
                        }).catch((e) => {
                            API.updateTaskDetails(task.task_id, { state: 4 })
                            this.setState({
                                analyticsCreditExceed: e?.creditExceeds,
                                showProcessPopup: !e?.creditExceeds,
                                processPopupIcon: 'ERROR',
                                processMessage: e.message
                            })
                        })
                    } else if (annotation_set_id) {
                        this.setState({
                            currentStep: 3,
                            task: updatedTask && updatedTask.task ? updatedTask.task : task,
                            selectedImageForAnnotation: undefined,
                            collection: {
                                id: updatedTask.task.image_collection_id
                            },
                            steps: this.state.steps.map((s) => {
                                if (s.stepNumber == 2)
                                    s.completed = true
                                return s
                            })
                        }, () => this.setStepData(false))
                    } else if (collection_id) {
                        this.setState({ task: updatedTask.task }, () => this.setStepData())
                    }
                }).catch((e) => {
                    this.setState({
                        showProcessPopup: true,
                        processPopupIcon: 'ERROR',
                        processMessage: e.message
                    })
                })
        }
    }

    updateTaskState = (state) => {
        API.updateTaskDetails(this.state.task.task_id, { state, linked_map: this.state.linkedMap }).then((updatedTask) => {
            let task = updatedTask.task
            task.accuracy = this.state.task.accuracy
            task.training_type = this.state.task.training_type
            this.setState({ task }, () => this.setStepData(false))
        }).catch((e) => {
            console.log("Error : ", e);
        })
    }

    render() {
        let { user, type } = this.state
        let topBar = <div className="top-bar-text">Training Task <ActiveRole /></div>
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardActive,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type
        }
        let leftSide = {
            render:
                <VerticalStepper
                    stepHeight={'110px'}
                    taskType={1}
                    expanded={this.state.expand}
                    steps={this.state.steps}
                    currentStep={this.state.currentStep}
                    onClickStep={(step) => {
                        this.setState({
                            currentStep: step,
                            selectedImageForAnnotation: undefined,
                        }, () => this.setStepData(true))
                    }}
                    onClickExpandStep={(step) => {
                        this.setState({
                            steps: this.state.steps.map((s) => {
                                if (s.stepNumber == step)
                                    s.expand = !s.expand
                                return s
                            })
                        })
                    }}
                    setTourComplete={(t) => {
                        if (t === 1 && getLocalStorageValue('createTaskTour1') == null) {
                            console.log("Inside here starting task tour")
                            this.setState({ triggerCreateTaskTour: true })
                        }
                        else {
                            console.log("INSIDE ELSE PART ")
                            this.setState({ triggerCreateTaskTour: false })
                        }
                    }}
                    joyrideTrigger={this.state.showTour ? true : false}
                    onClickActionButton={(step) => {
                        if (step == 1) {
                            this.setState({
                                steps: this.state.steps.map((s) => {
                                    if (s.stepNumber == step)
                                        s.completed=true;
                                    return s
                                })
                            },()=>{
                                this.createTask(step)
                            })
                        } else if (step == 2 && !this.state.task.annotation_set) {
                            this.addAnnotationSet(this.state.collection)
                        } else if (step == 4) {
                            this.updateTask(step + 1)
                        }
                        else {
                            this.updateTaskState(step + 1)
                            this.setState({
                                currentStep: step + 1,
                                selectedImageForAnnotation: undefined,
                                steps: this.state.steps.map((s) => {
                                    if (s.stepNumber == step)
                                        s.completed = true
                                    return s
                                })
                            })
                        }
                    }}
                />
            ,
            style: {
                width: AppConstants.STEPPER.STEPPERWIDTH,
                padding: "5px 10px",
                background: AppConstants.COLORS.STEPPERCOLOR,
                height: '100%',
                overflow: 'auto'
            }
        }

        let rightSide = {
            render:
                <>
                    <ActivityPopup
                        item={this.state.selectedItem}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => { this.cancelTask() }}
                    />

                    <AnimatedModal
                        isOpen={this.state.analyticsCreditExceed}
                        height="150px"
                        width="460px">
                        <div style={{
                            position: "absolute",
                            padding: "30px",
                            height: "100%",
                            width: "100%",
                        }}>
                            <div style={{
                                borderBottom: "1px solid #dee2e6",
                                height: "calc(100% - 20px)",
                            }}>
                                <div style={{ fontSize: "15px", color: "#3c3c3c" }}>
                                    {this.state.processMessage}
                                </div>
                            </div>
                            <div style={{
                                position: "absolute",
                                right: "0px",
                                margin: "10px 30px 0 0",
                            }} >
                                <ButtonWithIcon
                                    isBtnActive
                                    text={"Buy"}
                                    width={"70px"}
                                    onClick={() => { }}
                                    linkData={{
                                        pathname: "/buy-credit",
                                        state: {
                                            user: user,
                                            creditType: AppConstants.SUBSCRIPTOPTIONS.ITEMS.ANALYTICS_CREDITS,
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </AnimatedModal>

                    {this.state.currentStep === 1 ?
                        <CreateTaskView
                            permissions={this.state.permissions}
                            activeTeam={this.state.activeTeam}
                            ref={this.JoyrideUpdater}
                            taskType={1}
                            startTour={this.state.triggerCreateTaskTour}
                            task={this.state.task}
                            project={this.state.project}
                            plan={this.state.plan}
                            user={this.state.user}
                            setTourComplete={(t) => {
                                if (t === 1) this.setState({ triggerCreateTaskTour: false })
                            }}
                            taskCreationDone={(project, task) => {
                                this.setState({
                                    task,
                                    project,
                                    steps: this.state.steps.map((step => step.stepNumber === 1 ? { ...step, enable: this.state.permissions?.[PICSTORK_FEATURES.TASKS].CREATE && task.task_name && project.id } : step)),
                                    tourSteps: [{
                                        disableBeacon: true,
                                        target: '#button-step1',
                                        content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to create task</p></div>),
                                        title: (<div style={AppConstants.TITLESTYLING}>Create Task</div>),
                                        placement: 'right',
                                        styles: {
                                            options: {
                                                width: 250,
                                            },
                                        },
                                    }],
                                    currentlyActiveTour: 1,
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            runTour: task.task_name && project.id && getLocalStorageValue('createTaskTour1') == 1 ? true : false
                                        })
                                    }, AppConstants.TRIGGER_DELAY)
                                })
                            }} /> :
                        //step two
                        this.state.currentStep === 2 ?
                            (this.state.collection && this.state.collection.id ?
                                // if user selected imageset
                                <Images
                                    permissions={this.state.permissions}
                                    data={
                                        {
                                            collection: this.state.collection,
                                            plan: this.state.plan || { id: this.state.task.plan_id },
                                            project: this.state.project || { id: this.state.task.project_id },
                                            annotationSet: this.state.annotationSet,
                                            user: this.state.user,
                                            task: this.state.task
                                        }
                                    }
                                    taskType={1}
                                    updateImagesCount={async (status, count, linkedMap) => {
                                        if (this.isUserHasEditAccess()) {
                                            this.setState({
                                                linkedMap
                                            })
                                            if (status && count) {
                                                let stepData = this.state.steps
                                                stepData[1].description1 = `Count : ${count}`
                                                stepData[1].enable = linkedMap ? count >= 1 : count >= 20
                                                if (status == false) {
                                                    if (count > 0)
                                                        this.setStepData()
                                                    else if (this.state.task.annotation_set && this.state.task.annotation_set.id) {
                                                        let result = await API.getAnnotationSetImages(this.state.task.image_collection_id, this.state.task.annotation_set.id, 0, 0)
                                                        let annotated = result.annotatedCount
                                                        if (annotated == 0)
                                                            this.updateTaskState(2)
                                                    }
                                                }
                                                else
                                                    this.setState({
                                                        steps: stepData,
                                                    })
                                            }
                                        }

                                    }}
                                    setCollection={(collection) => {
                                        if (this.isUserHasEditAccess()) this.setState({
                                            collection: collection, linkedMap: null,
                                            steps: this.state.steps.map((step => step.stepNumber === 2 ? { ...step, completed: false, enable: false } : step))
                                        });
                                    }}
                                    updateTaskState={(state) => {
                                        if (this.isUserHasEditAccess()) this.updateTaskState(state)
                                    }
                                    }
                                /> :
                                <SelectImageSet
                                    permissions={this.state.permissions}
                                    setCollection={(collection) => {
                                        this.setState({ collection: collection })
                                    }}
                                    taskType={1}
                                    plan={this.state.plan}
                                    data={{
                                        user: this.state.user,
                                        type: this.state.type,
                                        plan: this.state.plan || { id: this.state.task.plan_id },
                                        project: this.state.project || { id: this.state.task.project_id },
                                        task: this.state.task,
                                    }}
                                />
                            ) :

                            //step 3
                            this.state.currentStep === 3 ?
                                (this.state.selectedImageForAnnotation ?
                                    (this.state.selectedImageForAnnotation?.validTiff ?
                                        <MapAnnotation
                                            permissions={this.state.permissions}
                                            showAnnotationTool
                                            selectedImageForAnnotation={this.state.selectedImageForAnnotation}
                                            user={this.state.user}
                                            annotationSetId={this.state.annotationSet.id}
                                            task={this.state.task}
                                            updateTask={(task) => {
                                                this.setState({
                                                    task,
                                                    steps: this.state.steps.map((step => step.stepNumber === 3 ? { ...step, completed: false } : step)),
                                                })

                                            }}
                                            setAnnotationDone={() => {
                                                this.setState({ selectedImageForAnnotation: false })
                                            }}
                                        />
                                        :
                                        <ImagesAnnotation
                                            permissions={this.state.permissions}
                                            location={
                                                {
                                                    state: {
                                                        annotationSet: this.state.annotationSet,
                                                        classes: this.state.classes,
                                                        image: this.state.selectedImageForAnnotation,
                                                        plan: this.state.plan || { id: this.state.task.plan_id },
                                                        project: this.state.project || { id: this.state.task.project_id },
                                                        user: this.state.user,
                                                        task: this.state.task,
                                                        images: this.state.imagesToShow
                                                    }
                                                }
                                            }
                                            updateTaskDetails={(step, task) => {
                                                if (task) this.setState({ task })
                                                this.updateTaskState(step)
                                            }}
                                            setAnnotationDone={() => {
                                                this.setState({ selectedImageForAnnotation: false })
                                            }}
                                        />)
                                    : <TaskAnnotationSet
                                        classes={this.state.task.type}
                                        updateTask={this.getTask}
                                        permissions={this.state.permissions}
                                        data={
                                            {
                                                collection: this.state.collection,
                                                annotationSet: this.state.annotationSet,
                                                plan: this.state.plan || { id: this.state.task.plan_id },
                                                project: this.state.project || { id: this.state.task.project_id },
                                                user: this.state.user
                                            }
                                        }
                                        task={this.state.task}
                                        updateTaskState={(step) => {
                                            if (this.isUserHasEditAccess()) {
                                                this.updateTaskState(step)
                                            }
                                        }}
                                        annotationDoneOrNot={(doneOrNot, annotated, unannotated) => {
                                            return new Promise(resolve => {
                                                if (this.isUserHasEditAccess() && (this.state.task.status === TASK.STATUS.PREPARING || this.state.task.status === TASK.STATUS.CREATING || this.state.task.status === TASK.STATUS.FAILED)) {
                                                    if (!this.state.steps[2].completed && this.state.currentStep === 3) {
                                                        this.updateTaskState(3)
                                                    };
                                                    if (annotated != null && unannotated != null && !this.state.loading) {
                                                        setTimeout(() => {
                                                            this.setState({
                                                                tourSteps: [{
                                                                    disableBeacon: true,
                                                                    target: '#button-step3',
                                                                    content: (<div style={AppConstants.TEXTSTYLING}><p>Setup various parameters of task</p></div>),
                                                                    title: (<div style={AppConstants.TITLESTYLING}>Setup Parameters</div>),
                                                                    placement: 'right',
                                                                    styles: {
                                                                        options: {
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                }],
                                                                currentlyActiveTour: 3,
                                                                runTour: (getLocalStorageValue('annotationTour') == 2 && annotated > 0) || (getLocalStorageValue('mapAnnotationTour') == 2 && annotated > 0) ? true : false
                                                            })
                                                        }, AppConstants.TRIGGER_DELAY)
                                                        this.setState({
                                                            annotated,
                                                            unannotated,
                                                            steps: this.state.steps.map((step => step.stepNumber === 3 ? { ...step, enable: this.state.task.status !== AppConstants.TASK.STATUS.PREPARING && (this.state.linkedMap != null && annotated >= 1 ? doneOrNot : annotated >= 20), description1: `${annotated} Annotated`, description2: `${unannotated} Non-annotated` } : step)),
                                                        }, resolve)
                                                    }
                                                    else
                                                        this.setState({
                                                            steps: this.state.steps.map((step => step.stepNumber === 3 ? { ...step, enable: this.state.task.status !== AppConstants.TASK.STATUS.PREPARING && doneOrNot, completed: doneOrNot } : step))
                                                        }, resolve)
                                                } else {
                                                    resolve()
                                                }
                                            })

                                        }
                                        }
                                        setSelectedImageForAnnotation={(imageData) => {
                                            this.setState({
                                                selectedImageForAnnotation: imageData.image,
                                                imagesToShow: imageData.imagesToShow
                                            })
                                        }}
                                    />)
                                :

                                //step 4
                                this.state.currentStep === 4 ?
                                    <TaskProcessView
                                        permissions={this.state.permissions}
                                        classes={this.state.task.type ? this.state.task.type : []}
                                        plan={this.state.plan || { id: this.state.task.plan_id }}
                                        project={this.state.project || { id: this.state.task.project_id }}
                                        taskType={1}
                                        collection={this.state.collection}
                                        annotationSet={this.state.annotationSet}
                                        task={this.state.task}
                                        showTotalCount={this.state.showTotalCount}
                                        onChangeDetails={(data) => {
                                            if (this.isUserHasEditAccess()) {
                                                let task = this.state.task
                                                task.accuracy = data.accuracy
                                                task.training_type = data.training_type
                                                this.setState((state) => ({
                                                    task: task,
                                                    tourSteps: [{
                                                        disableBeacon: true,
                                                        target: '#button-step4',
                                                        content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to start task</p></div>),
                                                        title: (<div style={AppConstants.TITLESTYLING}>Start Task</div>),
                                                        placement: 'right',
                                                        styles: {
                                                            options: {
                                                                width: 250,
                                                            },
                                                        },
                                                    }],
                                                    currentlyActiveTour: 4,
                                                }), () => {
                                                    this.setStepData(true)
                                                    setTimeout(() => {
                                                        console.log("Process task tour trigger : ", data.accuracy, data.training_type, getLocalStorageValue('processTour1') == 1)
                                                        this.setState({
                                                            runTour: data?.accuracy?.length > 1 && data?.training_type?.length > 1 && getLocalStorageValue('processTour1') == 1 ? true : false
                                                        })
                                                    }, AppConstants.TRIGGER_DELAY)
                                                })
                                            }
                                        }}
                                    /> :

                                    //step 
                                    this.state.currentStep === 5 ?
                                        <TaskDetails
                                            permissions={this.state.permissions}
                                            user={this.state.user}
                                            openStartTaskPopup={this.state.openStartTaskPopup}
                                            closeStartTaskPopup={() => {
                                                this.setState({ openStartTaskPopup: false })
                                            }}
                                            task={this.state.task}
                                            classes={this.state.classes}
                                            cancelTask={this.openCancelTaskPopup}
                                            taskImageType={(name) => { this.setState({ taskImgName: name }) }}
                                        /> :
                                        <></>
                    }
                </>
        }

        return (<>
            {this.state.user ?
                <ScreenDivider
                    sidebarConfigurations={sidebarConfigurations}
                    leftSide={leftSide}
                    rightSide={{
                        ...rightSide,
                    }}
                    topBar={topBar}
                    steps={this.state.steps}
                /> :
                <></>
            }

            {/* -------------- Tour confirmation popup ----------------------- */}
            <AnimatedModal
                height="360px"
                width="500px"
                zIndex={5}
                onRequestClose={this.closeTaskPopup}
                isOpen={this.state.showTourPopup}
            >
                <div className="content-text" style={{ padding: '30px 30px 10px 30px' }}>
                    <div className="services-content-title" style={{ fontSize: "20px", textAlign: 'left' }}>
                        Getting started with Training
                    </div>
                    <div style={{ textAlign: 'left', fontSize: '12px', marginTop: '10px' }}>
                        We would like to guide you through the process, would you like to take a tour ?

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', height: "190px", alignItems: 'center' }}>
                        <img style={{ height: '180px' }} src={TourGraphics} />
                    </div>

                    <div style={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-right btn-ok"
                            onClick={() => {
                                this.setState({
                                    showTourPopup: false,
                                    showTour: true,
                                    joyrideTrigger: true,
                                })
                            }}
                        >
                            Yes
                        </button>
                        <button style={{ marginLeft: "20px", padding: '8px 30px 7px 30px' }}
                            className="add-user-form-submit-button"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showTourPopup: false,
                                    showTour: false
                                }), () => {
                                    skipTrainingTaskTour()
                                });
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>

            </AnimatedModal>
            {/* --------------------------------------------------------------- */}

            <ReactJoyride
                continuous={true}
                run={this.state.runTour}
                hideBackButton={true}
                hideCloseButton={true}
                showSkipButton={true}
                steps={this.state.tourSteps}
                disableOverlayClose={true}
                floaterProps={AppConstants.FLOATER_PROPS}
                locale={AppConstants.LOCALE_BUTTONS}
                callback={(e) => this.handleJoyrideCallback(e)}
                styles={{
                    buttonNext: AppConstants.NEXT_BUTTON,
                    buttonSkip: AppConstants.SKIP_BUTTON,
                    spotlight: AppConstants.SPOTLIGHT,
                    options: AppConstants.MAIN_OPTIONS
                }}
            />
        </>
        );
    }
}

export default TrainingTask