import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import { FormWrapper, InputBox } from "./FormTopBar.js";
// import googleIcon from './assets/google.png'

class ForgotPassword extends Component {
    state = {
        email: "",
        emailSent: false,
        error: null
    }

    componentWillMount() {
        document.title = "DroneLab - Forgot Password";
    }

    resetPassword = (e) => {
        e.preventDefault()
        API.resetPassoword(this.state.email).then(() => {
            this.setState({ emailSent: true })
        }).catch((e) => {
            e.then(error => {
                this.setState({ error: error.message || "Something went wrong..." })
            })
        })
    }

    render() {
        return (
            <div className="authPage">
                {!this.state.emailSent ?
                    <FormWrapper onSubmit={this.resetPassword} error={this.state.error}>
                        <div className="greet login-greet">FORGOT PASSWORD</div>
                        {/* <div className="login-error">{this.state.error || <></>}</div> */}
                        <div className="login-email-wrapper">
                            <InputBox
                                autoFocus
                                onChange={(e) => {
                                    this.setState({
                                        error: null,
                                        email: e.target.value.toLowerCase()
                                    })
                                }}
                                id="username" name="Email" type="email"
                                placeholder="Please enter your email" required />
                        </div>
                        <div className="spacer" style={{ height: '24px' }}> </div>
                        <button disabled={this.state.error || !this.state.email} style={this.state.error || !this.state.email ? { opacity: '0.5', width: '100%', height: "33px", fontSize: '14px', fontWeight: '400', display: 'flex', justifyContent: 'center', alignItems: 'center' } : { cursor: 'pointer', width: '100%', height: "33px", fontSize: '14px', fontWeight: '400', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="loginButton" name="loginButton"
                            id="loginButton">Reset Password</button>
                        <div className="spacer" style={{ height: '15px' }}> </div>
                    </FormWrapper>
                    :
                    <FormWrapper
                        error={this.state.error}
                        onSubmit={this.resetPassword}
                    >
                        <div className="greet login-greet">FORGOT PASSWORD</div>
                        <div style={{
                            fontSize: '17px',
                            fontWeight: '400',
                            color: '#666666',
                            textAlign: 'center',
                            margin: '30px 0',
                        }}> Password reset link has been sent to your email address.</div>
                        <Link to={{
                            pathname: "/login",
                        }}>
                            <button className="loginButton" style={{ width: '100%', cursor: 'pointer' }}>LOGIN</button>
                        </Link>
                    </FormWrapper>
                }
            </div>
        );
    }
}

export default ForgotPassword
