import React from "react"

const LumaAI = ({ modelURL,onLoad }) => {
    return <>
        <iframe onLoad={onLoad} src={modelURL} width="100%" height="100%" frameborder="0" title="luma embed" style={{ border: "none" }}>

        </iframe>
    </>
}

export default LumaAI;