import React from 'react';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';

const PotreeMenu = ({ deleteMeasurement, closePopup, measurement }) => {
    return <div style={{
        position: 'absolute',
        right: 20,
        bottom: 20,
    }}>
        <ButtonWithIcon isBtnActive text={"Delete"} onClick={deleteMeasurement} />
    </div>
};

export default PotreeMenu;
