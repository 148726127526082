import React, { Component } from 'react';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import reportIcon from '../../assets/sideBarIcons/ReportActive.png';
import Popup from "reactjs-popup";
import service from "../../sidebaricon/Services.png";
import { Modal } from "react-bootstrap";
import Search from "../../Icon/Search.png";
import * as API from '../../api.js';
import Notask from '../../assets/Images/Notask.png';
import closeButton from '../../close-button.png';
import waiting from '../../res/waiting.png';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import planActive from '../../assets/sideBarIcons/PlanInactive.png';
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import ProjectIcon from '../../assets/sideBarIcons/ProjectInactive.png';
import TaskIcon from '../../icons/servers.png'
import projectsIcon from '../../assets/sideBarIcons/ProjectsInactive.png';
import myMaps from "../../assets/sideBarIcons/myMaps.png";

class AeroFarmAllReports extends Component {
    //State intialization to user
    state = {
        user: {},
        reportTypes: [{ id: 0, name: 'Detailed Reports' },
        {
            id: 1, name: 'Summary Reports'
        }],
        type: "",
        selectedReportType: "Detailed Reports",
        key: " ", //This will get key for search
        progress: 0,
        project: { id: " " },
        plan: { id: " " },
        showDownloadPopup: false,
        loader: false,
        services: [],
        reports: [],
        plan_id: " ",
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        sharableLink: '',
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    }
    // State End Here-------------
    //Get SearchKey Function Start Here-----
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getAllReports());
        console.log(key)
    }
    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getAllReports();
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getAllReports();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getAllReports();
            });
        } else {
            console.log("Reached next page")
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getAllReports();
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getAllReports());
        }

    }
    // Get Changenumber Page Function Ends here -------------------------

    // getAllTask function starts here---------- 

    getAllReports = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: 0,
            // loading: true
        }))
        API.getAllReports(this.state.project.id, this.state.plan.id, key == "" ? " " : key, this.state.selectedReportType, firstpage, userperpage).then(async (data) => {
            console.log(data);
            if (data.reports && data.reports.length == 0) {
                this.setState((state) => ({
                    ...state,
                    reports: [],
                    counts: 0,
                    loading: false
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    reports: data.reports,
                    loading: false,
                    counts: data.count,
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }
    // getAllTask function ends here----------


    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    changeReportType = (e) => {
        let val = e.target.value;
        this.setState((state) => ({
            ...state,
            selectedReportType: val
        }), () => {
            this.getAllReports()
        })
    }

    // This is lifcycle method this runs before component loaded
    componentWillMount() {
        document.title = "DroneNaksha - Reports";
        if (this.props.location.state) {
            let { user, type, project, plan } = this.props.location.state;
            console.log(this.props);
            if (project && plan) {
                this.setState((state) => ({
                    ...state,
                    project,
                    plan
                }))
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
                // project,
                // plan
            }), () => {
                this.getAllReports();
                console.log("ASKDJHASK", user);
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    console.log("ASKDJHASK", user);
                    this.getAllReports();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    //Function

    //Render function is like main function runs first
    render() {

        let { user, type, sidebarMode, showBackIcon, reports, currentpage, project, plan, statusCount } = this.state

        return (
            user.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"DroneNaksha"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={myMaps}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"My Maps"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/projects",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={projectsIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Projects"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/project",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ProjectIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Project"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/view_plan",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={planActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Plan"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/image_collections",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ImageSetInActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                //style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Imagesets"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/tasks",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={TaskIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Tasks"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div><div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/allreports",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={reportIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Reports"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <Popup
                        trigger={<button id="delete-task-popup-button" style={{
                            display: "none",
                            // width:"30% !important"
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("delete-task-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    createIcon: waiting,
                                    createCollectionMsg: 'Deleting Task Please Wait...',

                                }))


                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto",
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.deletingTaskText
                                }</div>

                            </>

                        </div>
                    </Popup>
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showTaskDeletePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showTaskDeletePopup: false,

                            }));
                        }}
                    >
                        <Modal.Body >
                            <p>Are you sure you want to delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showTaskDeletePopup: false,
                                        }));
                                    }}
                                >
                                    No
                                </button>
                            </div>
                            <div>
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        document.getElementById("delete-task-popup-button").click();
                                        this.setState((state) => ({
                                            ...state,
                                            showTaskDeletePopup: false,
                                            cteateIcon: waiting,
                                            deletingTaskText: 'Deleting Taks Please Wait...',
                                        }), () => {
                                            this.deleteTask();
                                        })
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <div className="top-bar"><div className="top-bar-text">REPORTS</div></div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>

                        <div style={{ display: "flex" }}>
                            <div className="services-content-title">{"All Reports"}</div>
                        </div>
                        <div className="add-user-form-row-left" style={{ marginTop: "2%", display: "flex", justifyContent: "space-between" }}>
                            <div className="services-content-title" style={{ color: 'black' }}>{`Total Count : ${this.state.counts}`}</div>
                            <div className="add-user-form-text-wrapper">
                                <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                <input type="text" className="add-user-form-text" placeholder="Search Report By Task Name" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                            </div>
                        </div>
                        {reports.length === 0 ? <div
                            className="rmp-dashboard-empty-data-message"
                            style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "65vh" }}
                        >
                            <img style={{ height: "25%" }} src={Notask} />
                            <p style={{ margin: '10px' }}> No Reports Available</p>
                        </div> : <div>

                            <table className="customers-table">
                                <col style={{ width: '75%' }} />
                                <col style={{ width: '25%' }} />
                                <tr className="customers-table-header-row">
                                    <th>Report</th>
                                    <th style={{ textAlign: 'center' }}>Created Date</th>
                                </tr>
                                {
                                    reports.map((task) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td>

                                                {/* {this.state.selectedReportType == 'Detailed Reports' ?
                                                <> */}
                                                {task.video_task == true ? <Link
                                                    style={{ color: "#707683" }}
                                                    to={{
                                                        pathname: "/dronenaksha/video_detailed_report",
                                                        state: {
                                                            user,
                                                            type,
                                                            sidebarMode: this.state.sidebarMode,
                                                            task,
                                                            plan,
                                                            project
                                                        },
                                                    }}
                                                >

                                                    {`${task.task_name}`}
                                                </Link> :
                                                    //         <Link
                                                    //             style={{ color: "#707683" }}
                                                    //             to={{
                                                    //                 pathname: "/dronenaksha/detailed_report",
                                                    //                 state: {
                                                    //                     user,
                                                    //                     type,
                                                    //                     sidebarMode: this.state.sidebarMode,
                                                    //                     task
                                                    //                 },
                                                    //             }}
                                                    //         >

                                                    //             {`${task.task_name}`}
                                                    //         </Link>
                                                    //     }
                                                    // </>
                                                    // :
                                                    <Link
                                                        style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: "/dronenaksha/report",
                                                            state: {
                                                                type,
                                                                user,
                                                                plan: this.props.location.state.plan,
                                                                project: this.props.location.state.project,
                                                                orthoImageData: this.state.orthoImageData,
                                                                ndviData: {
                                                                    processTileIndex: this.state.processTileIndex,
                                                                    processTileType: this.state.processTileType,
                                                                    processTileMin: this.state.processTileMin,
                                                                    processTileMax: this.state.processTileMax
                                                                }
                                                            },
                                                        }}
                                                    >
                                                        {`${task.task_name}`}
                                                    </Link>

                                                }
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    (() => {
                                                        let date = (new Date(task.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                        return `${date}`
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )}
                            </table>
                        </div>}
                        {/* This is footer  */}
                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                            <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>


                            </div>
                            <div style={{ float: "right", marginTop: "0.5rem", display: "flex" }}>
                                {this.state.currentpage == 1 ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>}
                                {this.state.currentpage == 1 ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button> :

                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button>}
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>

                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>
                                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                }
                                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button> :
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button>}
                            </div>
                        </footer>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

export default AeroFarmAllReports;