import React, { Component, useEffect } from "react";
import Geocode from "react-geocode";
import { Redirect } from "react-router-dom";
import LeafletMap from '../../ReusableComponents/map'
import L, { layerGroup } from "leaflet";
import "leaflet-editable";
import * as API from "../../api.js";
import { Link } from 'react-router-dom';
import imageSetsActive from '../../sidebaricon/ImageSet.png'
import projectInactive from '../../assets/sideBarIcons/ProjectInactive.png';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import service from "../../sidebaricon/Services.png";
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import TaskIcon from '../../icons/servers.png';
import Help from "../../sidebaricon/Help.png";
import MarkerIcon from '../../assets/marker.png'
import myMaps from "../../assets/sideBarIcons/myMaps.png";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import Loader from '../../icons/loader.svg';
import roadMapView from '../../assets/Images/roadMapViewzz.png'
import sateliteView from '../../assets/Images/sateliteView.png'
import trackingIcon from '../../assets/tracking_black.png'
import { addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions";

import {
  getArea,
  getDistance,
  addEditControl,
  addLineControl,
  addPolygonControl,
  layerAddedEvent,
  layerRemovedEvent,
} from "../../ReusableComponents/map/leafletMeasure.js"
import {
  Map,
  Marker,
  InfoWindow,
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from "../../Teams";
import { MAP_URLS, tilesServer } from "../../AppConstants";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");

let markers = [];
let layers = []
class ImagesetsOnMap extends Component {
  constructor(props) {
    super(props);
    //this.initMap = this.initMap.bind(this);
    this.state = {
      baseLayer: '',
      polygons: [],
      map: '',
      orthoImageData: {},
      layer: {},
      boundsLayer: [],
      bounds: [],
      outId: "",
      defaultOrtho: true,
      band1: "1",
      band2: "2",
      band3: "3",
      tilesBounds: [],
      loading: true,
      activeBaseLayer: 'satelite',
      allTiffBounds: null,
      redirect: false,
      collection_id: null,
      isOwner: true,
      sharedBy: null
    };
  }

  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })
  }


  setMapCenter = async () => {
    if (this.state.map) {
      this.state.map.fitBounds(this.state.allTiffBounds);
    }
  }

  getImageOrthomosaicImages = () => {
    if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getImageOrthomosaicImages(this.props.location.state.plan.id).then(async (data) => {
      let orthoImages = data.images ? data.images.filter(arr => arr.image_type == "orthomosaic") : []

      this.setState((state) => ({
        ...state,
        orthomosaicImages: [...orthoImages],
        orthoImageData: orthoImages[0],
        orthoImageData1: orthoImages[0],
        orthoImageSet: '',
      }), () => {
        this.getTilesBounds1(this.state.orthoImageData.task_id)
        if (this.state.orthoImageData.orthomosaicDetails) {
          let areaOtrho = this.state.orthoImageData.orthomosaicDetails.area.split(" sq.meter")
          let area = (areaOtrho[0] / 1000000)
          this.state.orthoImageData.orthomosaicDetails.area = area
        }
      })
    }, (e) => {
      this.setState((state) => ({
        ...state,
        itemName: "Orthomosaic Data",
        processPopupIcon: "ERROR",
        processAction: "ADD",
        processMessage: "Error while getting Orthomosaic Data",
        showProcessPopup: true,
        loading: false,
        mapDataLoaded: true,
      }))
    })
    else {
      this.setState((state) => ({
        ...state, loading: false
      }))
    }
  }

  getTilesBounds1 = async (taskId) => {
    return new Promise((resolve, reject) => {
      let sas = encodeURIComponent(this.state.permissions.st)
      let blobContainer = this.state.permissions.container
      API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
        this.setState({
          tilesBounds: data.tilesBounds,
          bounds: data.bounds,
          allTiffBounds: this.state.allTiffBounds ? new L.LatLngBounds(this.state.allTiffBounds.getSouthWest(), this.state.allTiffBounds.getNorthEast()).extend(L.latLngBounds(L.latLng(data.bounds?.[1], data.bounds?.[0]), L.latLng(data.bounds?.[3], data.bounds?.[2]))) : L.latLngBounds(L.latLng(data.bounds?.[1], data.bounds?.[0]), L.latLng(data.bounds?.[3], data.bounds?.[2])),
          defaultOrtho: true,
          band1: "1",
          band2: "2",
          band3: "3",
          loading: false,
        }, () => {
          resolve(this.state.bounds)
        })
      })
    })
  }

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  /**
   * Recursive function to load orthomosaics
   * @param {*} i 
   */
  overlayTilesLayerOf = async (i) => {
    let { orthomosaicImages, map, user } = this.state
    if (i < orthomosaicImages.length) {
      let taskid = orthomosaicImages[i].task_id
      await this.getTilesBounds1(taskid)
      await this.drawBoundsborder("boundsLayer" + i, orthomosaicImages[i].collection_id)
      let bound = new L.latLngBounds(L.latLng(this.state.bounds[1], this.state.bounds[0]), L.latLng(this.state.bounds[3], this.state.bounds[2]))
      let center = bound.getCenter()
      console.log("Center : ", center.lat, ",", center.lng);
      let icon = L.icon({
        title: orthomosaicImages[i].collection_id,
        iconUrl: MarkerIcon,
        iconSize: [25, 35],
        iconAnchor: [10, 24]
      });
      var p = new L.tooltip({ direction: "top", offset: [2, -20], permanent: true, className: "map-popup" }).setContent(`${orthomosaicImages[i].task_name}`)
      let Marker = L.marker([Number(center.lat), Number(center.lng)], { icon }).addTo(this.state.map).on('click', () => {
        this.setState((state) => ({
          ...state,
          collection_id: orthomosaicImages[i].collection_id,
          redirect: true
        }))
      })
      Marker.bindTooltip(p).openTooltip()
      markers.push(Marker)
      let { processTileIndex, processTileType, processTileMin, processTileMax, tileMinZoom, tileMaxZoom } = this.state
      let blobContainer = this.state.permissions.container;
      let sas = encodeURIComponent(this.state.permissions.st);
      let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
      let bandsSelection = `${this.state.RGBShow && !(this.state.defaultOrtho) ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
      let orthoLayer = L.tileLayer(`${tilesServer}/tiles/${taskid}/orthomosaic/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
        tileSize: 256,
        maxZoom: 28,
        bounds
      }).bindTooltip("Orthomosaic : " + (i + 1)).addTo(map)

      layers.push(orthoLayer)
      orthoLayer.on("load", async () => {
        this.removeLayer(i)
      });
      await this.overlayTilesLayerOf(i + 1)
    }
  }

  removeLayer = async (i) => {
    let removeLayer = this.state.boundsLayer[i]
    if (removeLayer) {
      //this.state.boundsLayer[i].remove()
      this.state.boundsLayer[i].setStyle({ color: 'white', opacity: 0, weight: 1, fillColor: "none" })
    }
  }

  mapInitialized = () => {
    const { map } = this.state
    addEditControl(map);
    layerAddedEvent(map)
    layerRemovedEvent(map)
  }

  handleMapClick = (e) => {
    console.log(e.latlng);
    this.state.boundsLayer.forEach(async (layer) => {
      let pointsInsidePolygon = layer.getBounds().contains([Number(e.latlng.lat), Number(e.latlng.lng)])
      if (pointsInsidePolygon) {
        this.setState((state) => ({
          ...state,
          collection_id: layer.options.title,
          redirect: true
        }))
      }
    })
  }

  successRedirectComponent = () => {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: "/dronenaksha/view_plan",
        state: {
          user: this.state.user,
          plan: this.props.location.state.plan,
          project: this.props.location.state.project,
          orthoImageCollectionId: this.state.collection_id,
          layerShow: "RGBShow"
        },
      }} />
    }
  }

  isUserOwnerOfCollection = () => {
    const { id } = this.state.project
    API.isUserProjectOwner(id)
      .then((result) => {
        this.setState({
          isOwner: result.isShared ? false : true,
          sharedBy: result.sharedBy
        })
      })
      .catch((err) => {
        this.setState((state) => ({
          ...state,
          itemName: "",
          processPopupIcon: "ERROR",
          processAction: "ADD",
          processMessage: "Something went wrong while getting owner information",
          showProcessPopup: true,
          loading: false,
          isOwner: false,
        }))
      })
  }

  drawBoundsborder = async (layer, collection_id) => {
    const { map, plan } = this.state
    let bounds = [[this.state.bounds?.[1], this.state.bounds?.[0]], [this.state.bounds?.[3], this.state.bounds?.[2]]]
    layer = L.rectangle(bounds, { title: collection_id, color: 'white', weight: 1, fillColor: "none" }).addTo(map);
    layer.addTo(map)
    let boundsLayer = this.state.boundsLayer
    boundsLayer.push(layer)
    this.setState({ boundsLayer })
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
  }

  componentWillMount() {
    getFeaturesPermissions([DRONENAKSHA_FEATURES.IMAGECOLLECTIONS, DRONENAKSHA_FEATURES.MAPS])
      .then(permissions => {
        this.setState(state => ({ ...state, permissions }), () => {

          addUserAnalytic(this.props.location.pathname)
            .then(id => {
              this.setState((state) => ({
                outId: id
              }))
            })
          this.setState((prevState) => ({
            ...prevState,
            user: this.props.location.state.user,
            plan: this.props.location.state.plan,
            project: this.props.location.state.project,
          }), () => {
            this.isUserOwnerOfCollection()
            this.getImageOrthomosaicImages()
          });
        })
      })
  }


  render() {
    const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <div className='sort-type-style'>{this.state.sortType}</div>
      </a>
    ));

    let { user, type, sidebarMode, showBackIcon, } = this.state


    return (
      user?.username ? <div className="wrapper">
        <div style={{ display: 'flex' }}>
          <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
            <div style={{ display: "flex", height: "40px" }}>
              <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                <img
                  src={AeroMeghLogo}
                  style={{ width: "65px", height: "20px", marginTop: "10px" }}
                />
              </div>
            </div>
            <ul className="sidebar-nav" id="sidebar-nav">
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        type,
                        user,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={service}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        {"Services"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/dashboard",
                      state: {
                        type,
                        user,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={dashboardIcon}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        // style={{ color: "#2989CF" }}
                        onClick={this.changeColor}
                      >
                        {"DroneNaksha"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/plan",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={projectInactive}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        {"Plan"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/myMaps",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={myMaps}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        Maps
                      </div>
                    </div>
                  </Link>
                </li>
              </div>

              <div className={"selectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/image_collections",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={imageSetsActive}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        style={{ color: "#2989CF" }}
                        onClick={this.changeColor}
                      >
                        {"Imagesets"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/tasks",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={TaskIcon}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        // style={{ color: "#2989CF" }}
                        onClick={this.changeColor}
                      >
                        {"Tasks"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <div
                    className="onhoverext"
                    onClick={
                      () => {
                        window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                      }
                    }
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={Help}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                      >
                        {"Help"}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        {this.successRedirectComponent()}
        <div className="right-content" style={{ overflow: "hidden" }}
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <div style={{ position: 'relative' }}>
            <div className="top-bar">
              <div className="breadCrumbs">
                <BreadCrumbs data={[
                  {
                    displayName: "projects",
                    pathname: "/dronenaksha/projects",
                    state: {
                      type,
                      user,
                    },
                    style: {
                      color: "black",
                      fontSize: "12px",
                    }
                  },
                  {
                    displayName: this.props.location.state.project.project_name,
                    pathname: "/dronenaksha/project",
                    state: {
                      type,
                      user,
                      plan: this.props.location.state.plan,
                      project: this.props.location.state.project,
                    },
                    style: {
                      color: "black",
                      fontSize: "12px"
                    }
                  },
                  {
                    displayName: this.props.location.state.plan.plan_name,
                    pathname: "/dronenaksha/plan",
                    state: {
                      type,
                      user,
                      plan: this.props.location.state.plan,
                      project: this.props.location.state.project
                    },
                    style: {
                      color: "black",
                      fontSize: "12px"
                    }
                  },
                  {
                    displayName: "Imagesets",
                  }
                ]} />
                {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                <ActiveRole />

              </div>
            </div>
            {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
            {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div> : <div className="main-content" style={{ padding: "0", position: "relative" }} onClick={
              () => {
                this.setState((state) => ({
                  ...state, sidebarMode: 'shrunk'
                }))
              }
            }>
              {/* changeBaseMapView  */}
              <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '24px', right: '60px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                onClick={() => {
                  this.switchBaseLayer()
                }}>
                <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
              </div>

              <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '24px', right: '20px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                onClick={() => this.setMapCenter()}>
                <img style={{ width: '15px', height: '15px', margin: '4.5px' }} src={trackingIcon} />
              </div>
              <div style={{ height: "calc(100vh - 40px)", width: "100%" }}>

                <LeafletMap
                  setBaseLayerToState={this.setBaseLayerToState}
                  initCenter={this.state.center ? this.state.center : [20.008482, 73.756834]}
                  map={this.state.map}
                  bounds={this.state.bounds}
                  maxZoom={24}
                  initZoom={16}
                  handleMapClick={this.handleMapClick}
                  handleMoveStart={this.handleMoveStart}
                  setMap={(map) => {
                    let drawBounds = this.state.map ? false : true
                    this.setState({ map: map }, async () => {
                      this.mapInitialized()
                      await this.overlayTilesLayerOf(0)
                      this.setMapCenter()
                    })
                  }}
                />
              </div>
            </div>}
            :<></>
          </div>
        </div>
      </div >
        : <></>
    );
  }
}


export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})(ImagesetsOnMap);