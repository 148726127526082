import React, { useState, useEffect } from 'react'
import { PICSTORK_FEATURES } from '../../../Teams';
import AppConstants from '../../../AppConstants';
import btnAdd from '../../assets/icons/btnAdd.svg'
import editIcon from '../../../icons/pen.png'
import deleteIcon from '../../../icons/deleteIcon.svg'
import tickIcon from '../../../icons/tick.png'
import menu from "../../../icons/MenuDark.png"
const SingleClassWithColor = (props) => {
    const { cls, key, setToggleColorPicker, setColorPickerClass, setCurrColor } = props;
    const [toggle, setToggle] = useState(false);
    const [selectedClass, setSelectedClass] = useState(undefined)
    const [newClassName, setNewClassName] = useState(undefined)
    const [newClassList, setNewClassList] = useState([])
    const onClickClass = (cls) => {
        if (!(cls.name === selectedClass)) {
            setSelectedClass(undefined)
            setNewClassName(undefined)
            props.onClickClass(cls)
        }
    }


    const onEditClass = (e) => {
        if (e) e.preventDefault();
        if (selectedClass != newClassName) {
            setSelectedClass(undefined)
            props.onEditClass(selectedClass, newClassName)
            setNewClassList(prev => prev.map(cls => {
                if (cls.name === selectedClass) {
                    return { ...cls, name: newClassName }
                } else {
                    return cls
                }
            }))
            setNewClassName(undefined)
            props.setClassListChanged()
        }
    }

    const onDeleteClass = (className) => {
        props.onDeleteClass(className)
    }


    const newClassNameHandler = (e) => {
        e.preventDefault()
        if (!(AppConstants.specialCharReg.test(e.target.value))) {
            setNewClassName(e.target.value.replaceAll(" ", "_"))
        }
    }



    const onClickEditClass = (cls) => {
        setNewClassName(undefined)
        setSelectedClass(cls)
    }


    useEffect(() => {

        const toggleFunc = (e) => {
            if (e.target.className === "toggle-btn") {

            }
            else if (e.target.className !== 'toogle') {
                setToggle(false)
            }
            if (!e.target.closest('#color-picker')) {
                setToggleColorPicker(false)

            }
        }
        document.body.addEventListener("click", toggleFunc)
        return () => document.body.removeEventListener("click", toggleFunc);
    }, [])

    const handleToggle = (clsName) => {

        setToggle(prev => !prev)
    }
    const colorPickerFunc = (e, cls) => {
        setColorPickerClass(cls)
        const el = document.getElementById(e.target.id);
        const colorPicker = document.getElementById("color-picker")

        colorPicker.style.right = "10px";
        colorPicker.style.bottom = "10px";

    }
    return (
        <>
            <div className={`hoverHighlightClass ${props.activeClass === cls.name && !props.selectedRegion ? "classSelected" : ""}`} style={{ position: "relative", color: AppConstants.CLASSLABELS.DARKCLASSTEXT, padding: "5px 0px 5px 5px", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <div style={{ width: props.onEditClass ? "90%" : '100%', display: 'flex', alignItems: "center" }}>
                    {!props.enableColorPicker ?
                        <div style={{
                            margin: "0px 5px",
                            padding: "",
                            borderRadius: "50%",
                            height: "15px",
                            width: "0px"
                        }}>
                        </div> :
                        <div id={`${key}_color`} onClick={(e) => {
                            if (props.onEditClassColor && props?.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && !props.disabledUpdate) {
                                setToggleColorPicker(true);
                                colorPickerFunc(e, cls)
                                setCurrColor(cls.color)
                            };
                        }} style={{
                            cursor: props.onEditClassColor
                                && props?.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT
                                && !props.disabledUpdate ?
                                "pointer"
                                : "not-allowed",
                            backgroundColor: cls.color,
                            margin: "0px 5px",
                            padding: "",
                            borderRadius: "50%",
                            height: "15px",
                            width: "15px"
                        }} />}

                    <div
                        style={{ cursor: "pointer", fontSize: "15px", wordWrap: "break-word", width: "calc(100% - 25px)", marginLeft: "5px" }}
                        onClick={() => {
                            onClickClass(cls)
                            if (props.setSelectedAreaId) props.setSelectedAreaId("")
                        }}>
                        {selectedClass && selectedClass == cls.name ? <div>
                            <form onSubmit={onEditClass}>
                                <input type="text"
                                    maxLength={30}
                                    autoFocus={true}
                                    onFocus={(e) => e.target.select()}
                                    placeholder="Enter class label"
                                    value={newClassName === undefined ? selectedClass : newClassName}
                                    onChange={newClassNameHandler}
                                    id="editClass"
                                    style={{ height: '25px', width: '100%', border: "none", color: "#3c3c3c", backgroundColor: props.activeClass === cls.name && !props.selectedRegion ? `#EAEAEA` : 'none', border: 'none', outline: "none" }} />
                            </form>
                        </div> :
                            <>
                                <div>
                                    {
                                        `${cls.name} (${props.regions ? props.regions.filter(r => r.className === cls.name).length : "-"}) `
                                    }
                                </div>
                                {props.showTotalCount && <div style={{ fontSize: "10px", color: "#666666" }}>
                                    Total Annotations: {cls.totalCount || 0}
                                </div>}
                            </>
                        }
                    </div>
                </div>
                {props.onEditClass ? <div style={{ position: "relative" }}>
                    <div className='toggle-btn' style={{ width: "14px", marginRight: "10px", cursor: "pointer" }} onClick={handleToggle}>
                        <img className='toggle-btn' src={menu} style={{ width: "100%" }} />
                    </div>
                    {toggle && <div className="toogle" style={{ zIndex: 3, borderRadius: "4px", width: "80px", backgroundColor: "white", padding: "", boxShadow: '0px 0px 2px 0px #00000040', position: "absolute", right: "20px", top: "25px" }}>
                        <div className='toogle' id="hoverBtn" src={selectedClass == cls.name ? tickIcon : editIcon} style={{ textAlign: "left", fontSize: "13px", width: "100%", opacity: props.taskIsInCreating() ? 1 : 0.5, cursor: "pointer", padding: "2px 5px", paddingLeft: "10px" }} onClick={() => {
                            if (!props.taskIsInCreating()) {
                                if (props.setError) props.setError("Cannot edit class")
                            } else {
                                selectedClass == cls.name ?
                                    newClassName && newClassName.length > 0 ? onEditClass() : setSelectedClass(undefined)
                                    : onClickEditClass(cls.name)
                                if (selectedClass == cls.name) {
                                    setToggle(false)
                                }

                            }
                        }}>{selectedClass == cls.name ? "Done" : "Edit"}</div>
                        <div className='toogle' id='hoverBtn' src={deleteIcon} style={{ textAlign: "left", borderTop: "1px solid lightgrey", padding: "2px 5px", fontSize: "13px", width: "100%", opacity: props.taskIsInCreating() ? 1 : 0.5, cursor: "pointer", paddingLeft: "10px" }} onClick={() => {
                            if (!props.taskIsInCreating()) {
                                if (props.setError) props.setError("Cannot delete class")
                            } else { onDeleteClass(cls.name) }
                        }} >Delete</div>
                    </div>}
                </div>
                    : <></>}
            </div>
        </>
    )
}

export default SingleClassWithColor