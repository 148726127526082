import React, { useEffect, useState } from "react";
import { addProperty } from "../../../api.js";
import * as API from '../../../api.js'
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal.js";
import { Popup } from "../../../ReusableComponents/reusableFunctions.js";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent.js";
import closeIcon from "../../assets/icons/closeNew.svg"
import { SelectComponent } from "../../../ReusableComponents/SelectComponent.js";
import ActionButton from "./ActionButton.js";


const PropertyDetailsEdit = ({ existingProperty, onClose, taskid, polygonId, onDone }) => {
    const [propertyDetail, setPropertyDetail] = useState(existingProperty || {
        name: '',
        info: "",
        name_of_colony: "",
        total_blocks: "",
        property_type: "",
        area_id: null,
        city_id: null,
        division_id: null,
        polygon_id: polygonId,
        task_id: taskid,
        division_name: '',
        city_name: '',
        city_area_name: '',
        luma_ai_url: '',
        colony_id: null
    });

    const [allDivisions, setAllDivisons] = useState([])
    const [propertyTypes, setPropertyTypes] = useState([])
    const [cities, setCities] = useState([])
    const [areas, setAreas] = useState([])
    const [colonies, setColonies] = useState([])
    const [loading, setLoading] = useState(true)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPropertyDetail({
            ...propertyDetail,
            [name]: value
        });
    };
    const isformFilled = () => {
        const includedFields = ['division_name', "city_name", "city_area_name", "property_type", "name_of_colony", "total_blocks", "name"];
        for (const key in propertyDetail) {
            if (includedFields.includes(key)) {
                const value = propertyDetail[key];
                if (value === undefined || value === null || value === '') {
                    return false;
                }
            }
        }
        return true;
    }

    const getAllDivisins = () => {
        API.getAllDivisions()
            .then((res) => {
                setAllDivisons(res.divisions)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }

    const getColoniesOfArea = (areaId) => {
        console.log('araeaId:', areaId)
        if (!areaId) {
            setColonies([])
            return;
        }
        API.getColoniesOfAreaAdmin(areaId)
            .then((res) => {
                console.log('res:', res)
                setColonies(res?.colonies || [])
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }

    const getPropertyTypes = () => {
        API.getPropertyTypes()
            .then((res) => {
                setPropertyTypes(res.types_of_properties)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }

    const getCitiesById = (division) => {
        API.getCitiesOfDivision(division)
            .then((res) => {
                setCities(res.cities)
            })
            .catch((e) => {
                Popup.alert("ERROR", e.message || e)
            })
    }

    const getAreasById = (city) => {
        if (city == null) {
            setAreas([])
            return;
        }
        API.getAreasOfCity(city)
            .then((res) => {
                setAreas(res.city_areas)
            })
            .catch((e) => {
                Popup.alert("ERROR", e.message || e)
            })
    }

    useEffect(() => {
        if (propertyDetail?.division_id) {
            getCitiesById(propertyDetail?.division_id)
        }
    }, [propertyDetail?.division_id])

    useEffect(() => {
        if (propertyDetail?.city_id) {
            getCitiesById(propertyDetail?.division_id)
        }
    }, [propertyDetail?.city_id])

    useEffect(() => {
        if (propertyDetail?.city_id) {
            getAreasById(propertyDetail?.city_id)
        }
    }, [propertyDetail?.city_id])

    useEffect(() => {
        if (propertyDetail?.area_id) {
            getColoniesOfArea(propertyDetail?.area_id)
        }
    }, [propertyDetail?.area_id])


    const updateProperty = () => {
        setLoading(true)
        API.updateProperty(propertyDetail)
            .then((res) => {
                onDone(propertyDetail, 'blue')
                setLoading(false)
                Popup.alert("COMPLETE", "Property updated successfully !");
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }

    const saveProperty = async () => {
        setLoading(true)
        try {
            const data = await addProperty(propertyDetail)
            onDone(data.property, 'blue')
            setLoading(false)
            Popup.alert("COMPLETE", "Property saved successfully !");
        } catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e.message || e)
        }
    }

    const addNewColony = async ({ name_of_colony, area_id }) => {
        setLoading(true)
        try {
            const data = await API.addColony({ name_of_colony, area_id })
            console.log('data:', data)
            setPropertyDetail({
                ...propertyDetail,
                colony_id: data?.colony?.id,
                name_of_colony: data?.colony?.name_of_colony
            })
            setColonies((prev) => ([
                ...prev,
                data.colony
            ]))
            setLoading(false)
            Popup.alert("COMPLETE", "Colony added successfully !");
        } catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e.message || e)
        }
    }

    useEffect(() => {
        getAllDivisins()
        getPropertyTypes()
    }, [])

    return (
        <AnimatedModal
            isOpen={true}
            // onRequestClose={onClose}
            height="540px"
            width="580px"
            padding="0"
        >
            {loading ?
                <LoaderComponent /> :
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 25px", borderBottom: "1px solid #d7d7d7" }}>
                        <div style={{ marginBlock: "10px" }}>{existingProperty ? "Update Property Details" : "Add Property Details"} </div>
                        <img src={closeIcon} alt="close button" style={{ width: "15px", cursor: "pointer" }} onClick={() => { onClose() }} />
                    </div>
                    <form style={{ display: "grid", height: "90%", gridTemplateColumns: "1fr 1fr", rowGap: "16px", columnGap: "52px", padding: "25px" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "100%" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Division</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={propertyDetail.division_id ? [{ label: propertyDetail.division_name, value: propertyDetail.division_id }] : []}
                                    placeholder="Select Divison"
                                    searchable={false}
                                    disabled={allDivisions.length === 0 || loading}
                                    itemsList={
                                        allDivisions?.map((divison) => ({
                                            label: divison.name,
                                            value: divison.id,
                                        }))
                                    }
                                    onchangeHandler={(e) => {
                                        setPropertyDetail({
                                            ...propertyDetail,
                                            division_id: e[0].value,
                                            division_name: e[0].label,
                                            city_id: null,
                                            city_name: "",
                                            area_id: null,
                                            city_area_name: '',
                                            colony_id: null,
                                            name_of_colony: ""
                                        })
                                        getCitiesById(e[0]?.value)
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"15px"}
                                    border={"1px solid #C3CDD9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#F4F4F4"}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Cities</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={propertyDetail.city_id ? [{ label: propertyDetail.city_name, value: propertyDetail.city_id }] : []}
                                    placeholder="Select City"
                                    searchable={false}
                                    itemsList={
                                        cities?.map((city) => ({
                                            label: city.name,
                                            value: city.id,
                                        }))
                                    }
                                    disabled={loading || cities.length === 0}
                                    onchangeHandler={(e) => {
                                        setPropertyDetail({
                                            ...propertyDetail,
                                            city_id: e[0]?.value,
                                            city_name: e[0]?.label,
                                            area_id: null,
                                            city_area_name: ""
                                        })
                                        getAreasById(e[0]?.value)
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"15px"}
                                    border={"1px solid #C3CDD9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#F4F4F4"}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>City Area</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={propertyDetail.area_id ? [{ label: propertyDetail.city_area_name, value: propertyDetail.area_id }] : []}
                                    placeholder="Select City Area"
                                    searchable={false}
                                    disabled={loading || areas?.length === 0}
                                    itemsList={
                                        areas?.map((area) => ({
                                            label: area.name,
                                            value: area.id,
                                        }))
                                    }
                                    onchangeHandler={(e) => {
                                        setPropertyDetail({
                                            ...propertyDetail,
                                            area_id: e[0]?.value,
                                            city_area_name: e[0]?.label,
                                            colony_id: null,
                                            name_of_colony: ""
                                        })
                                        getColoniesOfArea(e[0]?.value)
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"15px"}
                                    border={"1px solid #C3CDD9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#F4F4F4"}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Name Of Colony</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={propertyDetail.colony_id ? [{ label: propertyDetail.name_of_colony, value: propertyDetail.colony_id }] : []}
                                    placeholder="Select Colony"
                                    searchable={true}
                                    disabled={loading}
                                    itemsList={
                                        colonies?.map((colony) => ({
                                            label: colony.name_of_colony,
                                            value: colony.id,
                                        }))
                                    }
                                    onchangeHandler={(e) => {
                                        setPropertyDetail({
                                            ...propertyDetail,
                                            colony_id: e[0]?.value,
                                            name_of_colony: e[0]?.label
                                        })
                                    }}
                                    create={true}
                                    onCreateNew={(e) => {
                                        addNewColony({ name_of_colony: e?.value, area_id: propertyDetail.area_id })
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"15px"}
                                    border={"1px solid #C3CDD9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#F4F4F4"}
                                    itemHeight={"max-content"}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: "flex" }}>Sr. No</div>
                            <input
                                name="name"
                                placeholder="Enter Sr. no"
                                value={propertyDetail.name}
                                onChange={handleInputChange}
                                maxLength={10}
                                type="text"
                                style={{ borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                            <div style={{ fontSize: "14px", display: "flex" }}>Property type</div>
                            <div style={{ width: "100%" }}>
                                <SelectComponent
                                    values={propertyDetail.property_type ? [{ label: propertyDetail.property_type, value: propertyDetail.property_type }] : []}
                                    placeholder="Select Property Type"
                                    searchable={false}
                                    disabled={loading || propertyTypes.length === 0}
                                    itemsList={
                                        propertyTypes?.map((type) => ({
                                            label: type,
                                            value: type,
                                        }))
                                    }
                                    onchangeHandler={(e) => {
                                        setPropertyDetail({
                                            ...propertyDetail,
                                            property_type: e[0]?.value,
                                        })
                                    }}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                    width={"238px"}
                                    height={"34px"}
                                    iconSize={"15px"}
                                    border={"1px solid #C3CDD9"}
                                    borderRadius={"8px"}
                                    backgroundColor={"#F4F4F4"}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: "flex" }}>Total blocks</div>
                            <input
                                name="total_blocks"
                                type="text"
                                placeholder="Enter blocks"
                                value={propertyDetail.total_blocks}
                                onChange={handleInputChange}
                                maxLength={10}
                                style={{ borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Luma AI URL<span style={{ opacity: "0.5", fontSize: "10px", display: "flex", alignItems: "center" }}>(optional)</span></div>
                            <input
                                name="luma_ai_url"
                                placeholder="Enter luma AI URL"
                                value={propertyDetail.luma_ai_url || ""}
                                onChange={(e) => handleInputChange(e)}
                                maxLength={100}
                                style={{ borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
                            <div style={{ fontSize: "14px", display: 'flex' }}>Information<span style={{ opacity: "0.5", fontSize: "10px", display: "flex", alignItems: "center" }}>(optional)</span></div>
                            <input
                                name="info"
                                placeholder="Enter info"
                                value={propertyDetail.info}
                                onChange={(e) => handleInputChange(e)}
                                maxLength={100}
                                style={{ borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            />
                        </div>
                    </form>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", padding: "10px 25px 25px 25px", gap: "10px" }}>
                        <ActionButton
                            name={"Cancel"}
                            onClickCallback={() => {
                                onClose()
                            }}
                            background={false}
                            width={"90px"}
                        />
                        <ActionButton
                            isDisabled={!isformFilled()}
                            name={existingProperty ? "Update" : "Save"}
                            onClickCallback={() => {
                                existingProperty ? updateProperty() : saveProperty()
                            }}
                            background={true}
                            width={"90px"}
                            isBtnActive={true}
                        />
                    </div>
                </div>
            }
        </AnimatedModal>
    )
}


export default PropertyDetailsEdit;