import React, { Component } from 'react';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import projectInactive from '../../assets/sideBarIcons/ProjectActive.png';
import service from "../../sidebaricon/Services.png";
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import Menu from '../assets/icons/menu.svg';
import { connect } from "react-redux";
import { addUserAnalytic, updateOutTime, getMaxPageIndex } from '../../ReusableComponents/reusableFunctions';
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import TaskIcon from '../../icons/serversActive.png'
import myMaps from "../../assets/sideBarIcons/myMaps.png";
import Help from "../../sidebaricon/Help.png";
import { SortComponent } from '../../ReusableComponents/SortComponent';
import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../Teams';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
class DroneNakshaAllTasks extends Component {
    //State intialization to user
    state = {
        sharedBy: null,
        isOwner: false,
        user: {},
        type: "",
        key: "", //This will get key for search
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        services: [],
        tasks: [],
        plan: " ",
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        sharableLink: '',
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        currentStep: 0,
        createTaskMsg: "",
        collection_id: "",
        collection_name: "",
        imageCount: 0,
        taskType: null,
        showAddTaskPopup: false,
        addDetectionTaskPopup: false,
        models: [],
        videoTask: false,
        video_id: "",
        fps: 1,
        selectedModels: [],
        media_name: "",
        media_id: null,
        scrollHeight: "180px",
        collections: [],
        videos: [],
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        outId: "",
    }

    // State End Here-------------
    //Get SearchKey Function Start Here-----
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getTasks());
        console.log(key)

    }
    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getTasks();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached next page")
        }
    }

    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        let maxPage = getMaxPageIndex(this.state.counts, 8)
        if (isNaN(num) || num > maxPage) {
            return null;
        }
        else if (num !== 0) {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getTasks());
        }

    }
    // Get Changenumber Page Function Ends here -------------------------

    // getAllTask function starts here---------- 

    getTasks = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: 0
            // loading: true
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getAllTask(this.state.project.id, this.state.plan.id, key === "" ? " " : key, firstpage, userperpage, "DroneNaksha").then(async (data) => {

            if (data.tasks && data.tasks.length == 0) {
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    loading: false,
                    statusCount: {}
                }))
            } else {
                try {
                    let pendingTasks = await data.tasks.filter((task) => {
                        if (task.status == 'Pending') {
                            return task
                        }
                    });
                    let tasks = await data.tasks.filter((task) => {
                        if (task.status != 'Pending') {
                            return task
                        }
                    });
                    let allTasks = pendingTasks.concat(tasks)
                    this.setState((state) => ({
                        ...state,
                        tasks: allTasks,
                        loading: false,
                        counts: data.count,
                        statusCount: data.statusCount
                    }), () => {
                        if (this.state.sortType === 'A-Z') {
                            this.sortByName()
                        } else if (this.state.sortType === 'Z-A') {
                            this.reverseByName()
                        } else if (this.state.sortType === 'First Modified') {
                            this.sortByDate()
                        } else if (this.state.sortType === 'Last Modified') {
                            this.reverseByDate()
                        }
                        this.setState({
                            counts: Number(this.state.statusCount.completed_task_count ? this.state.statusCount.completed_task_count : 0) +
                                Number(this.state.statusCount.in_progress_task_count ? this.state.statusCount.in_progress_task_count : 0) +
                                Number(this.state.statusCount.pending_task_count ? this.state.statusCount.pending_task_count : 0) +
                                Number(this.state.statusCount.failed_task_count ? this.state.statusCount.failed_task_count : 0),
                        })
                    })
                } catch (e) {
                    console.log(e);
                }
            }
        }, (e) => {
            console.log(e);
        })
        else {
            this.setState((state) => ({
                ...state,
                tasks: [],
                loading: false,
                counts: 0,
                statusCount: {}
            }))
        }
    }
    // getAllTask function ends here----------



    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.tasks.sort((a, b) => a.task_name.localeCompare(b.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.tasks.sort((a, b) => b.task_name.localeCompare(a.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }



    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    sharedBy: result.sharedBy
                })
                console.log(result)
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    loading: false,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    // This is lifcycle method this runs before component loaded
    componentWillMount() {
        getFeaturesPermissions([DRONENAKSHA_FEATURES.MAPS])
            .then(permissions => {
                this.setState(state => ({ ...state, permissions }), () => {

                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            console.log("ID : ", id)
                            this.setState((state) => ({
                                outId: id
                            }))
                        })
                    document.title = "DroneNaksha - Tasks";
                    if (this.props.location.state) {
                        let { user, type, project, plan } = this.props.location.state;
                        console.log(this.props);
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            project,
                            plan
                        }), () => {
                            this.isUserOwnerOfCollection()
                            this.getTasks();
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }), () => {
                                this.getTasks();
                            })
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }
                })
            })
    }
    //Function

    //Render function is like main function runs first
    render() {

        let { videoTask, selectedModels, media_name, fps, user, type, models, collections, videos, collection_name, showBackIcon, reports, project, plan, steps, currentStep, task_name, addDetectionTaskPopup, tasks, currentpage, statusCount } = this.state

        return (
            user.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"DroneNaksha"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/plan",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={projectInactive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Plan"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={myMaps}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Maps"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/image_collections",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ImageSetInActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                //style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Imagesets"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/tasks",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={TaskIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Tasks"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: this.props.location.state.project.project_name,

                                    pathname: "/dronenaksha/project",
                                    state: {
                                        type,
                                        user,
                                        project: this.props.location.state.project,
                                    }
                                },
                                {
                                    displayName: this.props.location.state.plan.plan_name,

                                    pathname: "/dronenaksha/view_plan",
                                    state: {
                                        type,
                                        user,
                                        plan: this.props.location.state.plan,
                                        project: this.props.location.state.project
                                    }
                                },
                                {
                                    displayName: "TASKS"
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                            <ActiveRole />

                        </div></div>

                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>

                        <div style={{ display: "flex" }}>
                            <div className="services-content-title">{"Tasks"}
                                <span className='data-count'>({
                                    Number(this.state.statusCount.completed_task_count ? this.state.statusCount.completed_task_count : 0) +
                                    Number(this.state.statusCount.in_progress_task_count ? this.state.statusCount.in_progress_task_count : 0) +
                                    Number(this.state.statusCount.pending_task_count ? this.state.statusCount.pending_task_count : 0) +
                                    Number(this.state.statusCount.failed_task_count ? this.state.statusCount.failed_task_count : 0)
                                })</span>
                            </div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto" }}>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            {/* <div className="services-content-title" style={{ color: "black", fontSize: "15px" }}>Pending : {'01'}</div>
                            <div className="services-content-title" style={{ marginLeft: "60px", color: "black", fontSize: "15px" }}>Completed : 0</div>
                            <div className="services-content-title" style={{ marginLeft: "60px", color: "black", fontSize: "15px" }}>In Progress : {'01'}</div> */}

                            <div style={{ display: 'flex' }}>
                                <div className="task-count-number" style={{ color: "#11A087" }}>{
                                    // tasks.reduce((a, v) => (v.status === 'Completed' ? a + 1 : a), 0)
                                    statusCount && statusCount.completed_task_count ? this.state.statusCount.completed_task_count : 0
                                }</div>
                                <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Completed'}`} </div>
                            </div>
                            <div style={{ display: 'flex', marginLeft: '30px' }}>
                                <div className="task-count-number" style={{ color: "#fc6f03" }}>{
                                    // tasks.reduce((a, v) => (v.status === 'Pending' ? a + 1 : a), 0)
                                    statusCount && statusCount.in_progress_task_count ? this.state.statusCount.in_progress_task_count : 0
                                }</div>
                                <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'In Progress'}`} </div>
                            </div>
                            <div style={{ display: 'flex', marginLeft: '30px' }}>
                                <div className="task-count-number" style={{ color: "#3C3C3C" }}>{
                                    // tasks.reduce((a, v) => (v.status === 'Pending' ? a + 1 : a), 0)
                                    statusCount && statusCount.pending_task_count ? this.state.statusCount.pending_task_count : 0
                                }</div>
                                <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Pending'}`} </div>
                            </div>
                            <div style={{ display: 'flex', marginLeft: '30px' }}>
                                <div className="task-count-number" style={{ color: "#F02020" }}>{
                                    // tasks.reduce((a, v) => (v.status === 'Failed' ? a + 1 : a), 0)
                                    statusCount && statusCount.failed_task_count ? this.state.statusCount.failed_task_count : 0
                                }</div>
                                <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Failed'}`} </div>
                            </div>

                        </div>
                        <table className="customers-table">
                            <col style={{ width: '35%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '18%' }} />
                            <col style={{ width: '17%' }} />
                            <tr className="customers-table-header-row">
                                <th>Name
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}
                                        sortAscending={this.sortByName}
                                        sortDecending={this.reverseByName}
                                        sortASC={false}
                                    />
                                </th>
                                <th>Status</th>
                                <th>Images</th>
                                <th>Plan</th>
                                <th>Date Created
                                    <SortComponent
                                        style={{ marginLeft: '5px' }}
                                        sortAscending={this.sortByDate}
                                        sortDecending={this.reverseByDate}
                                        sortASC={false}
                                    />
                                </th>
                            </tr>
                            {tasks.length === 0 ?
                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                    <td colSpan="6">No Tasks Available</td>
                                </tr> :
                                tasks.map((task) =>
                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                        <td>
                                            {<Link
                                                style={{ color: "#707683" }}
                                                to={{
                                                    pathname: "/dronenaksha/images",
                                                    state: {
                                                        user,
                                                        type,
                                                        sidebarMode: this.state.sidebarMode,
                                                        task,
                                                        project,
                                                        plan: this.props.location.state.plan,
                                                        collection: { id: task.image_collection_id, plan_id: task.plan_id, collection_name: task.collection_name }
                                                    },
                                                }}
                                            >
                                                {task.task_name}<span style={{ color: "#2989cf" }}>{!this.state.isOwner ? ' (Shared)' : ''}</span>
                                            </Link>
                                            }
                                        </td>
                                        <td>{task.status}</td>
                                        <td>{task.images}</td>
                                        <td>{task.plan_name}</td>
                                        <td>
                                            {
                                                (() => {
                                                    let date = (new Date(task.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                    return `${date}`
                                                })()
                                            }
                                        </td>
                                    </tr>
                                )}
                        </table>
                        {/* This is footer  */}
                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                            <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>
                            </div>
                            <div style={{ float: "right", marginTop: "0.5rem", display: "flex", alignItems: "baseline" }}>
                                <ButtonWithIcon
                                    text="First"
                                    isBtnActive
                                    isDisabled={this.state.currentpage == 1}
                                    onClick={this.state.currentpage == 1 ? false : this.firstpage}
                                />
                                <ButtonWithIcon
                                    text="Prev"
                                    isDisabled={(this.state.currentpage == 1)}
                                    onClick={(this.state.currentpage == 1) ? false : this.prevpage}
                                />
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>

                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>
                                <ButtonWithIcon
                                    text="Next"
                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.nextpage}
                                />
                                <ButtonWithIcon
                                    text="Last"
                                    isBtnActive
                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.lastpage}
                                />
                            </div>
                        </footer>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(DroneNakshaAllTasks);