import React, { useState } from 'react';
import LayerHolder, { setLayerStyle } from './LayerHolder.js';
import CheckboxHeader from './CheckboxHeader.js';
import * as L from 'leaflet'
import LayerColor from '../../Components/LayerColor.js';
import LayerMenu from '../../Components/LayerMenu.js';
import AppConstants from '../../../AppConstants.js';
import { Popup, getLayerInfo, getSingleGeoJSON, handleLayerDownload, triggerBlobDownload } from '../../../ReusableComponents/reusableFunctions.js';
import LayerLabelContainer from './LayerLabelContainer.js';
import { TOOLS } from '../Constants/constants.js';
import MarkerIcon from '../../../assets/marker.png'
import { useSetRecoilState } from 'recoil';
import { importedLayerPropeties, isRightSlider, layerPropeties } from '../Recoil/atom.js';

let selectedLabel = ''
let lastSelectedLayer = null;

const LayersContainer = ({ propertiesLayers, blockLayers, encLayers, map, onUpateLayerColor, onChangePropertyAsset }) => {
    const setSelectedLayerPropeties = useSetRecoilState(importedLayerPropeties)
    const setOpenRightSlider = useSetRecoilState(isRightSlider)
    const setLayerProperties = useSetRecoilState(layerPropeties)
    const [toggleLayers, setToggleLayers] = useState({
        properties: true,
        blocks: true,
        encroachments: true,
        pMeasurements: true
    })
    const [geoJSON, setGeoJSON] = useState(undefined)
    const [openLabelPopup, setOpenLabelPopup] = useState(false)
    const [layer, setlayer] = useState(undefined)
    const [assetToggle, setAssetToggle] = useState(false)
    const [label, setlabel] = useState(undefined)

    const handleToggleAll = (layers, toggle) => {
        layers.map((p) => {
            if (toggle) {
                map.addLayer(p.layer)
                if (p?.labelLayer) {
                    map.addLayer(p?.labelLayer)
                }
            }
            else {
                map.removeLayer(p.layer)
                if (p?.labelLayer) {
                    map.removeLayer(p?.labelLayer)
                }
            }
        })
    }

    const handleToggleLayer = (asset) => {
        setlayer(asset)
        if (asset?.layerGrps?.[selectedLabel]) {
            setGeoJSON(asset?.geoJSON)
            setOpenLabelPopup(true)
        } else {
            fetchGeoJSONOfLayer(asset)
        }
    }

    const removeLayerFromMap = (asset) => {
        console.log('asset:', asset, "label:", selectedLabel)
        if (asset?.layerGrps?.[selectedLabel]) {
            asset.showLayer = false
            map.removeLayer(asset.layerGrps?.[selectedLabel]);
            onChangePropertyAsset(asset)
        }
        else {
            asset.showLayer = false
            let keys = Object.keys(asset.layerGrps);
            let lastKey = keys[keys.length - 1];
            map.removeLayer(asset.layerGrps?.[lastKey]);
            onChangePropertyAsset(asset)
        }
    }

    const fetchGeoJSONOfLayer = async (asset) => {
        let blobURL = asset.image_url.split(
            asset.image_type === AppConstants.ASSETS.SHP
                ? '.zip'
                : asset.image_type === AppConstants.ASSETS.KML
                    ? '.kml'
                    : asset.image_type === AppConstants.ASSETS.CAD
                        ? '.dxf'
                        : asset.image_type === AppConstants.ASSETS.DETECTION
                            ? '.json'
                            : asset.image_type === AppConstants.ASSETS.GEOJSON
                                ? '.json'
                                : asset.image_type === AppConstants.ASSETS.CONTOUR
                                    ? '.zip'
                                    : ''
        )[0] + '.' + 'json' + `?${propertiesLayers[0]?.sas_token}`;

        try {
            const url = blobURL;
            const response = await fetch(url);

            const blob = await response.blob();
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    const fileContent = reader.result;
                    let geoJSON = await JSON.parse(fileContent)
                    asset.geoJSON = geoJSON
                    setGeoJSON(geoJSON)
                    onChangePropertyAsset(asset)
                    setOpenLabelPopup(true)
                } catch (err) {
                    console.log(err)
                }
            };
            reader.readAsText(blob);
        } catch (error) {
            setOpenLabelPopup(false)
            Popup.alert("ERROR", error)
        }
    }

    const drawGeoJSONLayer = async (event, propertyAsset, showLabel) => {
        console.log('propertyAsset:', propertyAsset)
        if (propertyAsset?.layerGrps?.[showLabel]) {
            let layerGrp = propertyAsset?.layerGrps?.[showLabel]
            propertyAsset.showLayer = true
            onChangePropertyAsset(propertyAsset, layerGrp)
            layerGrp.addTo(map)
            let featureGrp = L.featureGroup(layerGrp.getLayers())
            map.fitBounds(featureGrp.getBounds())
        }
        else {
            const data = event.target ? JSON.parse(event.target.result) : event
            const GeoJSON = Array.isArray(data) ?
                await getSingleGeoJSON(data) :
                data
            if (GeoJSON.features.length == 0) {
                return;
            }
            let layerGrp = L.layerGroup()
            let cadTextGroup = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    return L.divIcon({
                        html: '',
                        className: 'cluster-icon'
                    });
                },
                showCoverageOnHover: false,
                maxClusterRadius: 20
            });
            setTimeout(async () => {
                const groupedData = Array.from({ length: 100 }, (_, i) => GeoJSON.features.slice(i * 100, (i + 1) * 100));

                for (let group of groupedData) {
                    for (let i = 0; i < group.length; i++) {
                        let feature = group[i]
                        await getLayerFromFeature(feature, propertyAsset.image_type, showLabel, propertyAsset?.properties?.color || propertyAsset?.color, layerGrp, cadTextGroup);
                    }

                }

                if (cadTextGroup.getLayers().length > 0) {
                    layerGrp.addLayer(cadTextGroup)
                }
                console.log('layergrp:', layerGrp)
                propertyAsset.layerGrps = {
                    ...propertyAsset.layerGrps,
                    [showLabel]: layerGrp
                }
                propertyAsset.showLayer = assetToggle
                onChangePropertyAsset(propertyAsset, layerGrp)
                layerGrp.addTo(map)

                let featureGrp = L.featureGroup(layerGrp.getLayers())
                map.fitBounds(featureGrp.getBounds())
            }, 300)
        }
    }

    const getLayerFromFeature = async (feature, imageType, showLabel, color, layerGrp, cadTextGroup) => {
        console.log('imageType:', imageType)
        return new Promise(async (resolve) => {
            let coordinates;
            let layer;

            switch (feature?.geometry?.type) {
                case TOOLS.POINT:
                    if (feature.geometry.coordinates?.[0]) {

                        coordinates = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
                        if (imageType === AppConstants.ASSETS.CAD) {
                            layer = L.marker(coordinates, {}).bindTooltip(feature.properties.Text, {
                                className: "leaflet-tooltip-own",
                                permanent: true,
                                direction: "top"
                            });
                            layer.setOpacity(0);
                            if (feature.properties.Text) {
                                cadTextGroup.addLayer(layer);
                            }
                            resolve({ ...layer });
                        } else {
                            layer = createMarkerLayer(coordinates, feature.properties, showLabel, imageType);
                            layer.addTo(map);
                            newLayerDrawn(layer, feature.properties, TOOLS.POINT, showLabel, color, imageType, layerGrp);
                            resolve({ ...layer });
                        }
                    } else resolve()
                    break;

                case TOOLS.POLYGON:
                case TOOLS.BOX:
                    if (feature.geometry?.coordinates?.[0]?.[0]?.[0]) {

                        coordinates = feature.geometry.coordinates[0].map(coord => ([coord[1], coord[0]]));
                    } else if (feature.geometry?.coordinates?.[0]?.[0]) {
                        coordinates = feature.geometry.coordinates.map(coord => ([coord[1], coord[0]]));

                    }
                    layer = createPolygonLayer(coordinates, feature.properties);
                    newLayerDrawn(layer, feature.properties, TOOLS.POLYGON || TOOLS.BOX, showLabel, color, imageType, layerGrp);
                    resolve({ ...layer });
                    break;

                case TOOLS.MULITPOLYGON:
                    const multiPolygons = await Promise.all(feature.geometry.coordinates.map(async (poly) => {
                        return new Promise((resolve) => {

                            if (poly?.[0]?.[0]?.[0]) {

                                const polyCoords = poly[0].map(coord => ([coord[1], coord[0]]));
                                layer = createPolygonLayer(polyCoords, feature.properties);
                                newLayerDrawn(layer, feature.properties, TOOLS.MULITPOLYGON, showLabel, color, imageType, layerGrp);
                                resolve(layer);
                            } else resolve()
                        })
                    }));
                    resolve(multiPolygons);
                    break;

                case TOOLS.LINESTRING:
                    if (feature.geometry?.coordinates?.[0]?.[0]) {

                        coordinates = feature.geometry.coordinates.map(coord => ([coord[1], coord[0]]));
                        layer = createPolylineLayer(coordinates, feature.properties);
                        newLayerDrawn(layer, feature.properties, TOOLS.LINESTRING, showLabel, color, imageType, layerGrp);
                        resolve(layer);
                    } else resolve()
                    break;

                case TOOLS.MULTILINESTRING:
                    if (feature.geometry?.coordinates?.[0]?.[0]?.[0]) {

                        coordinates = feature.geometry.coordinates.map(coords =>
                            coords.map(crd => ([crd[1], crd[0]]))
                        );
                        layer = createPolylineLayer(coordinates, feature.properties);
                        newLayerDrawn(layer, feature.properties, TOOLS.MULTILINESTRING, showLabel, color, imageType, layerGrp);
                        resolve(layer);
                    } else resolve()
                    break;

                case TOOLS.GEOMETRYCOLLECTION:
                    const geometryLayers = await handleGeometryCollection(feature.geometry.geometries, feature.properties, cadTextGroup);
                    resolve({ ...feature, geometry: geometryLayers });
                    break;

                default:
                    resolve();
            }
        });
    }

    const createMarkerLayer = (coordinates, properties, label, imageType) => {
        let style = imageType === AppConstants.ASSETS.CAD ?
            "width: 30px; height: 30px; padding: 8px; display: flex; align-items: center; justify-content: center; color: white;" :
            "width: 30px; height: 30px; padding: 8px; display: flex; align-items: center; justify-content: center; color: red; background: white; border-radius: 50%;";

        const markerOptions = {
            title: properties?.[label],
            clickable: true,
            draggable: false,
            autoPanOnFocus: true,
            icon: properties?.DNtype === TOOLS.MARKER ?
                new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] }) :
                new L.DivIcon({
                    className: 'block-import',
                    html: `<div style="${style}">${properties?.[label]}</div>`
                })
        };

        const layer = new L.Marker(coordinates, markerOptions);
        layer.show = true;
        return layer;
    };



    const createPolygonLayer = (coordinates, properties) => {
        const layer = new L.polygon(coordinates);
        layer.properties = properties;
        layer.show = true
        return layer;
    }

    const createPolylineLayer = (coordinates, properties) => {
        try {
            const layer = new L.polyline(coordinates);
            layer.properties = properties;
            layer.show = true
            return layer;
        } catch (error) {
            console.error("Error creating polyline layer:", error);
            Popup.alert("ERROR", error)
            return null;
        }
    }

    const newLayerDrawn = (layer, properties, layerType, showLabel, color, imageType, layerGrp) => {
        if (layer) {
            let countourOrElevOrKML = properties?.[`${showLabel}`] || properties?.[showLabel]
            if (layerType !== TOOLS.POINT && layerType !== TOOLS.MARKER && countourOrElevOrKML && imageType !== AppConstants.ASSETS.CONTOUR) {
                let p = new L.Tooltip({ className: "countour-popup", pane: 'tooltips', permanent: 'true' }).setContent(`<div>${countourOrElevOrKML}</div>`)
                layer.bindTooltip(p)
                if (showLabel === "icon") {
                } else {
                    var tooltip = layer.getTooltip();
                    if (tooltip) {
                        tooltip.options.zIndex = 1000;
                    }
                }
            }

            layer.addTo(layerGrp)

            if (layerType != TOOLS.POINT) {
                layer.dn_style = { color: color, fillColor: color }
                layer.setStyle({ color: color, fillColor: color })
            }
            if (imageType !== AppConstants.ASSETS.CAD || layerType === TOOLS.MARKER || layerType === TOOLS.POINT) {
                layer.on('click', () => {
                    getClickLayerInfo(layer)
                    if (lastSelectedLayer && lastSelectedLayer !== layer) {
                        lastSelectedLayer.setStyle({
                            color,
                            fillColor: color,
                            fillOpacity: 0.2
                        });
                        lastSelectedLayer.dn_style = { color, fillColor: color };
                    }
                    if (!(layer instanceof L.Marker)) {
                        layer.setStyle({
                            color: "#fce803",
                            fillColor: "#fce803",
                            fillOpacity: 0.2
                        });
                        layer.dn_style = { color: "#fce803", fillColor: color };
                        lastSelectedLayer = layer;
                    }
                    setOpenRightSlider(true)
                })
            }
        }
    }

    const getClickLayerInfo = (layer) => {
        const info = getLayerInfo(layer)
        setSelectedLayerPropeties(info)
        setLayerProperties({})
    }

    const handleGeometryCollection = async (geometries, properties, cadTextGroup) => {
        const layers = await Promise.all(geometries.map(async (gm) => {
            const coordinates = gm.coordinates.map(coord => ([coord[1], coord[0]]));
            if (gm.type === TOOLS.POINT) {
                let layer = L.marker(coordinates, {}).bindTooltip(properties.Text, {
                    className: "leaflet-tooltip-own",
                    permanent: true,
                    direction: "top"
                });
                layer.setOpacity(0);
                if (properties.Text) {
                    cadTextGroup.addLayer(layer);
                }
                return layer;
            } else if (gm.type === TOOLS.POLYGON) {
                return createPolygonLayer(coordinates, properties);
            } else if (gm.type === TOOLS.LINESTRING) {
                return createPolylineLayer(coordinates, properties);
            } else if (gm.type === TOOLS.MULTILINESTRING) {
                return createPolylineLayer(coordinates, properties);
            } else if (gm.type === TOOLS.MULITPOLYGON) {
                // Handle TOOLS.MULITPOLYGON if needed
            }
        }));
        return layers;
    }

    const changeLayerGroupColor = (asset, newColor) => {
        if (asset?.layerGrps) {
            for (const key in asset.layerGrps) {
                if (asset.layerGrps.hasOwnProperty(key) && asset.showLayer) {
                    const layerGroup = asset.layerGrps[key];
                    if (layerGroup) {
                        const layers = layerGroup.getLayers().map((layer) => {
                            console.log('layer:', layer);
                            if (!(layer instanceof L.Marker)) {
                                return setLayerStyle(layer, newColor);
                            }
                            else {
                                return layer
                            }
                        });

                        if (layers.length > 0) {
                            layerGroup.removeFrom(map);

                            const updateGrp = L.layerGroup(layers);
                            asset.layerGrps[key] = updateGrp;
                            onChangePropertyAsset(asset, updateGrp);
                            updateGrp.addTo(map);
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            {propertiesLayers?.length > 0 &&
                <CheckboxHeader
                    label={"All properties"}
                    checked={toggleLayers.properties}
                    onChange={(e) => {
                        setToggleLayers(prev => ({
                            ...prev,
                            properties: e?.target?.checked
                        }))
                        handleToggleAll(propertiesLayers, e?.target?.checked)
                    }}
                />
            }

            {blockLayers.length > 0 &&
                <CheckboxHeader
                    label={"All blocks"}
                    checked={toggleLayers.blocks}
                    onChange={(e) => {
                        setToggleLayers(prev => ({
                            ...prev,
                            blocks: e?.target?.checked
                        }))
                        handleToggleAll(blockLayers, e?.target?.checked)
                    }}
                />
            }

            {encLayers.length > 0 &&
                <CheckboxHeader
                    label={"All encroachments"}
                    checked={toggleLayers.encroachments}
                    onChange={(e) => {
                        setToggleLayers(prev => ({
                            ...prev,
                            encroachments: e?.target?.checked
                        }))
                        handleToggleAll(encLayers, e?.target?.checked)
                    }}
                />
            }

            {propertiesLayers[0]?.measurements?.length > 0 &&
                <CheckboxHeader
                    label={"All measurements"}
                    checked={toggleLayers.pMeasurements}
                    onChange={(e) => {
                        setToggleLayers(prev => ({
                            ...prev,
                            pMeasurements: e?.target?.checked
                        }))
                        handleToggleAll(propertiesLayers[0]?.measurements, e?.target?.checked)
                    }}
                />
            }

            {propertiesLayers?.length > 0 ?
                <div>
                    <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)", borderTop: "1px solid rgb(218,220,224)" }}>
                        Properties
                    </div>
                    {propertiesLayers.map((l) => {
                        return <LayerHolder
                            layer={l}
                            map={map}
                            isVisible={toggleLayers.properties}
                        />
                    })}
                </div> :
                <div style={{ padding: "13px", fontSize: "12px", fontWeight: 400 }}>
                    Please Explore a city first to see layers.
                </div>
            }

            {blockLayers?.length > 0 &&
                <div>
                    <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)", borderTop: "1px solid rgb(218,220,224)" }}>
                        Blocks
                    </div>
                    {blockLayers.map((l) => {
                        return <LayerHolder
                            layer={l}
                            map={map}
                            isVisible={toggleLayers.blocks}
                        />
                    })}
                </div>
            }

            {encLayers?.length > 0 &&
                <div>
                    <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)", borderTop: "1px solid rgb(218,220,224)" }}>
                        Encroachments
                    </div>
                    {encLayers.map((l) => {
                        return <LayerHolder
                            layer={l}
                            map={map}
                            isVisible={toggleLayers.encroachments}
                        />
                    })}
                </div>
            }

            {propertiesLayers[0]?.measurements?.length > 0 &&
                <div>
                    <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)", borderTop: "1px solid rgb(218,220,224)" }}>
                        Measurements
                    </div>
                    {propertiesLayers[0]?.measurements.map((l) => {
                        return <LayerHolder
                            layer={l}
                            map={map}
                            isVisible={toggleLayers.pMeasurements}
                            isColorPicker={true}
                            onUpateLayerColor={(layer, color) => {
                                onUpateLayerColor(layer, color)
                            }}
                        />
                    })}
                </div>
            }

            {propertiesLayers[0]?.propertyAssets?.length > 0 &&
                <div>
                    <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)", borderTop: "1px solid rgb(218,220,224)" }}>
                        Imported Layers
                    </div>
                    {propertiesLayers[0]?.propertyAssets.map((asset, i) => {
                        return (
                            <div key={i} style={{ color: "#3c3c3c", fontSize: "11px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                <input type='checkbox' disabled={false} style={{ cursor: 'pointer', height: "15px", width: "15px" }} checked={asset?.showLayer} onChange={(e) => {
                                    setAssetToggle(e?.target?.checked)
                                    e?.target?.checked ?
                                        handleToggleLayer(asset) :
                                        removeLayerFromMap(asset)
                                }} />
                                <div style={{ marginLeft: "10px", minWidth: "55px", backgroundColor: 'white', fontSize: "10px", width: "100%", fontWeight: "400", wordWrap: "break-word" }}>
                                    {asset.task_name}
                                </div>
                                <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                                    <LayerColor color={asset?.properties?.color || "#E7F6AA"} onChangeColor={(color) => {
                                        console.log('color:', color)
                                        changeLayerGroupColor(asset, color)
                                    }} />
                                    <LayerMenu
                                        onClickDownload={() => {
                                            handleLayerDownload(asset.image_url, asset.image_type, asset.task_name, propertiesLayers[0].sas_token)
                                        }}
                                        onClickDelete={() => { }}
                                        permissions={{ DOWNLOAD: "download" }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            }

            {openLabelPopup &&
                <LayerLabelContainer
                    labels={geoJSON?.features[0]?.properties}
                    openLabels={openLabelPopup}
                    onSelectLabel={(label) => {
                        setlabel(label)
                        selectedLabel = label
                        setOpenLabelPopup(false)
                        drawGeoJSONLayer(geoJSON, layer, label)
                    }}
                    onCloseCall={() => {
                        setOpenLabelPopup(false)
                    }}
                />
            }
        </div>
    )
}

export default LayersContainer