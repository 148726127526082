import React, { useEffect, useState } from 'react'
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { addCityAreaAPI, editCityAreaAPI, getAllDivisions, getCitiesOfDivision } from '../api'
import { Popup } from '../ReusableComponents/reusableFunctions'
import Select from 'react-dropdown-select'
import Close from "../icons/blackClose.png"
import LoaderComponent from '../ReusableComponents/LoaderComponent'

const AddCityArea = ({ selectedArea, division_id, city_id, onCityAreaAdded, onCityAreaEdited, onRequestClose, checkIfDuplicate }) => {
    const [area, setArea] = useState({ name: "", description: "", division_id: null, city_id: null })
    const [loading, setLoading] = useState(true)
    const [divisions, setDivisions] = useState([])
    const [cities, setCities] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (selectedArea && divisions.length > 0) {
            setIsEditing(true)
            setArea({
                id: selectedArea.id,
                name: selectedArea.name,
                description: selectedArea.description,
                city_id: selectedArea.city_id
            })
            const div = divisions.find(d => d.id == selectedArea.city.division_id)
            setSelectedDivision(div)
            getCitiesOfDivisionFunc(div.id)
        }
    }, [selectedArea, divisions.length])

    useEffect(() => {
        if (isEditing && selectedArea && cities?.length > 0) {
            const city = cities.find(c => c.id == selectedArea.city_id)
            setSelectedCity(city)
        }
    }, [cities?.length])

    const handleAddCityArea = async () => {
        setLoading(true)
        try {
            if (checkIfDuplicate(area)) {
                setLoading(false)
                Popup.alert("ERROR", "Area already exists")
            } else {

                const a = await addCityAreaAPI(area)
                onCityAreaAdded({ id: a.id, ...area })
                setLoading(false)
                Popup.alert("COMPLETE", "Area added successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    const handleEditCityArea = async () => {
        setLoading(true)
        try {
            if (selectedArea.name !== area.name && checkIfDuplicate(area)) {
                setLoading(false)
                Popup.alert("ERROR", "Area already exists")
            } else {
                const a = await editCityAreaAPI(area)
                onCityAreaEdited(area)
                setLoading(false)
                Popup.alert("COMPLETE", "Area updated successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    useEffect(() => {
        if (division_id) {
            setArea(prev => ({ ...prev, division_id }))
            setSelectedDivision(divisions.find(div => div.id == division_id))
        }
    }, [division_id, divisions.length])

    useEffect(() => {
        if (city_id) {
            setArea(prev => ({ ...prev, city_id }))
            setSelectedCity(cities.find(city => city.id == city_id))
        }
    }, [city_id, cities.length])

    const getCitiesOfDivisionFunc = async (division_id) => {
        try {
            setSelectedCity(null)
            const result = await getCitiesOfDivision(division_id)
            setCities(result.cities)
        } catch (err) {
            Popup.alert("ERROR", err)
        }
    }

    const getDivisions = async () => {
        try {
            const result = await getAllDivisions()
            setDivisions(result.divisions)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    useEffect(() => {
        getDivisions()
    }, [])

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", gap: "10px", position: 'relative' }}>
            <div style={{ padding: "20px", textAlign: 'left', display: 'flex', alignItems: "center", justifyContent: 'space-between', borderBottom: '1px solid #C3CDD9' }}>
                <span>{isEditing ? "Edit" : "Add"} Area Of The City</span>
                <img onClick={onRequestClose} src={Close} style={{ cursor: 'pointer', height: '25px' }} />
            </div>
            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: "column", gap: "10px", padding: "20px", position: "relative" }}>
                {loading &&
                    <LoaderComponent message={" "} background={"rgb(0 0 0 / 7%)"} position={"absolute"} zIndex={"99"} style={{ left: "0", top: '-10px' }} />
                }
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Division</label>
                        <Select
                            selected
                            style={{ width: "100%", minWidth: "0px", height: "34px" }}
                            values={selectedDivision ? [{ label: selectedDivision.name, value: selectedDivision.id }] : []}
                            placeholder="Select Division"
                            searchable={true}
                            disabled={divisions?.length === 0}
                            noDataRenderer={() => {
                                return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Division not available</span>
                            }}
                            options={
                                divisions?.map((p) => ({
                                    label: p.name,
                                    value: p.id,
                                }))
                            }
                            className="custom_dropdown"
                            onChange={(e) => {
                                if (e?.length > 0 && e[0]?.value) {
                                    getCitiesOfDivisionFunc(e[0].value)
                                    const div = divisions.find(d => d.id == e[0].value)
                                    setArea(prev => ({ ...prev, division_id: e[0].value, city_id: null }))
                                    setSelectedDivision(div)
                                }
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>City</label>
                        <Select
                            selected
                            style={{ width: "100%", minWidth: "0px", height: "34px" }}
                            values={selectedCity ? [{ label: selectedCity.name, value: selectedCity.id }] : []}
                            placeholder="Select City"
                            searchable={true}
                            disabled={cities?.length === 0}
                            noDataRenderer={() => {
                                return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Cities not available</span>
                            }}
                            options={
                                cities?.map((p) => ({
                                    label: p.name,
                                    value: p.id,
                                }))
                            }
                            className="custom_dropdown"
                            onChange={(e) => {
                                if (e?.length > 0) {
                                    setSelectedCity(cities.find(div => div.id == e[0].value))
                                    setArea(prev => ({ ...prev, city_id: e[0].value }))
                                }

                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Name Of City Area</label>
                        <input
                            value={area.name}
                            placeholder='Enter City Area Name'
                            style={{ outline: "none", width: "100%", borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            type='text' onChange={e => {
                                e.persist()
                                setArea(prev => ({ ...prev, name: e.target.value }))
                            }} />
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Description <span style={{ color: "#666666" }}>(Optional)</span></label>
                    <textarea
                        value={area.description}
                        style={{ outline: "none", width: "100%", height: "70px", resize: 'none', borderRadius: "8px", border: "1px solid #C3CDD9", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                        placeholder='Type here...' onChange={e => {
                            e.persist()
                            setArea(prev => ({ ...prev, description: e.target.value }))
                        }} />
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: "20px", right: "20px", zIndex: 99 }}>
                <ButtonWithIcon isBtnActive fontSize={'14px'} width={"115px"} text={isEditing ? "Update" : "Add"} isDisabled={!area.city_id || !area.division_id || !area.name} onClick={() => {
                    if (isEditing) {
                        handleEditCityArea()
                    } else {
                        handleAddCityArea()
                    }
                }} />
            </div>
        </div>
    )
}

export default AddCityArea
