import React, { useEffect, useState } from "react"
import { AnimatedModal } from '../ReusableComponents/AnimatedModal';
import manageAccess from '../assets/manageAccess.png'
import LoaderComponent from "../ReusableComponents/LoaderComponent";
import SearchIcon from '../icons/SearchIcon.png'
import PlusIcon from '../icons/PlusIcon.png'
import UserIcon from '../icons/User.png'
import MinusIcon from '../icons/Minus.png'
import Close from "../icons/closeButton.png"
import { getRoles, getProjectAccessList, addAccessToProject, removeProjectAccess, updateProjectAccess } from ".";

export const ManageProjectAccess = ({ projectId, closeManageAccess, senderData, propsData }) => {
    const [searchUser, setSearchUser] = useState("")
    const [loading, setLoading] = useState(true)
    const [accessList, setAccessList] = useState({})
    const [rolesList, setRoles] = useState([])
    const [error, setError] = useState(false)

    const initData = async () => {
        if (projectId) {
            try {
                setLoading(true)
                const rolesList = await getRoles()
                const accessList = await getProjectAccessList(projectId)
                const projectAccessList = {
                    ...accessList,
                    hasNoAccess: accessList.hasNoAccess.map(a => {
                        return {
                            ...a,
                            role: rolesList[rolesList.length - 1]
                        }
                    })
                }
                setAccessList(projectAccessList)
                setRoles(rolesList)
                setLoading(false)
            } catch (e) {
                setError("Something went wrong while getting data.")
            }
        }
    }



    const updateRole = async (event, receiverData) => {
        let username = receiverData.username;
        try {
            event.preventDefault()
            const roleId = event.target.value
            await updateProjectAccess(projectId, username, roleId, senderData.userid, receiverData.userId, propsData)
            setAccessList({
                ...accessList, hasAccess: accessList.hasAccess.map(a =>
                    a.username === username ?
                        {
                            ...a,
                            role: { id: roleId }
                        } : a)
            })
        }
        catch (e) {
            setError("Something Went wrong while updating access.")
        }
    }

    const removeAccess = async (receiverData) => {
        let username = receiverData.username
        try {
            const accessDeletedUser = accessList.hasAccess.find(a => a.username === username)
            const accessAddedUser = await removeProjectAccess(projectId, username, accessDeletedUser.role.id, senderData.userid, receiverData.userId, propsData)
            setAccessList({
                hasAccess: accessList.hasAccess.filter(a => a.username !== username),
                hasNoAccess: [accessDeletedUser, ...accessList.hasNoAccess]
            })
        } catch (e) {
            setError("Something Went wrong while adding access.")
        }
    }

    const changeHasNotAccessRole = (event, username) => {
        event.preventDefault()
        setAccessList({
            ...accessList, hasNoAccess: accessList.hasNoAccess.map(a =>
                a.username === username ?
                    {
                        ...a,
                        role: { id: event.target.value }
                    } : a)
        })
    }

    const addAccess = async (receiverData) => {
        let username = receiverData.username
        try {
            const userRoleUpdate = accessList.hasNoAccess.find(a => a.username === username)
            await addAccessToProject(projectId, username, userRoleUpdate.role.id, senderData.userid, receiverData.userId, propsData)
            setAccessList({
                hasAccess: [userRoleUpdate, ...accessList.hasAccess],
                hasNoAccess: accessList.hasNoAccess.filter(a => a.username !== username)
            })
        } catch (e) {
            setError("Something Went wrong while adding access.")
        }
    }


    const handleInputChange = async (event) => {
        let username = event.target.value
        setSearchUser(username)
    }

    useEffect(() => {
        initData()
    }, [projectId])

    return (
        <AnimatedModal
            isOpen={projectId}
            height="400px"
            width="700px"
        >
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ width: "35%", backgroundColor: "#EAEAEA", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                    <img src={manageAccess} style={{ margin: "8%" }} />
                </div>
                <div style={{ width: "65%", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", top: '10px', right: "10px", cursor: "pointer" }} onClick={() => {
                        closeManageAccess()
                    }}>
                        <img src={Close} alt="closeButton" style={{ width: "20px" }} />
                    </div>
                    <div className="services-content-title"
                        style={{
                            textAlign: "left",
                            margin: "10% 0 20px 10%",
                            fontWeight: "500",
                            color: "#3C3C3C",
                        }}
                    >
                        Manage Access
                    </div>
                    <form style={{ textAlign: "left" }}>
                        <input
                            onInput={(event) => handleInputChange(event)}
                            value={searchUser}
                            name="searchUser"
                            className="add-user-form-text"
                            style={{ width: "80%", marginLeft: "10%", paddingRight: "50px" }}
                            placeholder="Search User"
                        />
                        <img src={SearchIcon} style={{ width: "15px", marginLeft: "-25px" }} />
                    </form>
                    <div className="accessList" style={{ margin: "5% 0 9.5% 0", height: "100%", overflowY: "auto" }}>
                        {loading ?
                            <LoaderComponent height={"60%"} /> :
                            <>
                                {!(accessList.hasAccess?.length > 0 || accessList.hasNoAccess?.length > 0) &&
                                    <div className="empty-data-message" style={{ height: "90%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            Selected team has no active members.
                                        </div>
                                    </div>
                                }
                                {accessList.hasAccess?.length > 0 && !loading ? accessList.hasAccess
                                    .filter(
                                        manageAccess => manageAccess.userFullName.toLowerCase().includes(searchUser.toLowerCase())
                                    )
                                    .map((manageAccess, key) => {
                                        return <div className="pilot" style={{ display: "flex", margin: "0px 10%", padding: "1.5% 0" }}>
                                            <div style={{ display: "flex", alignItems: "center", width: "60%" }}>
                                                <img src={UserIcon} style={{ width: "26px", marginRight: "10%" }} />
                                                <div style={{ fontSize: "13px", color: "#666666" }}>

                                                    {
                                                        manageAccess.userFullName.length > 14 ?
                                                            `${manageAccess.userFullName.substring(0, 13)}...` :
                                                            `${manageAccess.userFullName}`
                                                    }</div>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", width: "40%", justifyContent: "flex-end" }}>
                                                <select value={manageAccess.role.id}
                                                    onChange={(event) => updateRole(event, manageAccess)}
                                                    style={{
                                                        width: "60%",
                                                        backgroundColor: "#F5F5F5",
                                                        borderRadius: "3%",
                                                        color: "#666666",
                                                        padding: "0 4%",
                                                        fontSize: '13px',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {rolesList.map(role => <option value={role.id}>{role.title}</option>)}
                                                </select>
                                                <img src={MinusIcon} style={{ cursor: "pointer", width: "25px", marginLeft: "10%" }} onClick={() => removeAccess(manageAccess)} />
                                            </div>
                                        </div>
                                    }) : <></>}
                            </>}
                        {accessList.hasNoAccess?.length > 0 && !loading ?
                            accessList.hasNoAccess
                                .filter(
                                    manageAccess => manageAccess.userFullName.toLowerCase().includes(searchUser.toLowerCase())
                                )
                                .map((manageAccess, key) => {
                                    return <div className="pilot" style={{ display: "flex", margin: "0px 10%", padding: "1.5% 0" }}>
                                        <div style={{ display: "flex", alignItems: "center", width: "60%" }}>
                                            <img src={UserIcon} style={{ width: "26px", marginRight: "10%" }} />
                                            <div style={{ fontSize: "13px", color: "#666666" }}> {
                                                manageAccess.userFullName.length > 14 ?
                                                    `${manageAccess.userFullName.substring(0, 13)}...` :
                                                    `${manageAccess.userFullName}`
                                            }</div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", width: "40%", justifyContent: "flex-end" }}>
                                            <select value={manageAccess.role.id}
                                                onChange={(event) => changeHasNotAccessRole(event, manageAccess.username)}
                                                style={{
                                                    width: "60%",
                                                    backgroundColor: "#F5F5F5",
                                                    borderRadius: "3%",
                                                    color: "#666666",
                                                    padding: "0 4%",
                                                    fontSize: '13px',
                                                    borderRadius: '5px'
                                                }}>
                                                {rolesList.map(role => <option value={role.id}>{role.title}</option>)}
                                            </select>
                                            <img src={PlusIcon} style={{ cursor: "pointer", width: "25px", marginLeft: "10%" }} onClick={() => { addAccess(manageAccess) }} />
                                        </div>
                                    </div>
                                }) : <></>}
                    </div>
                </div>
            </div>
        </AnimatedModal>
    )
}