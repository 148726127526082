import React, { useEffect, useState } from "react";
import { SelectComponent } from "../../../ReusableComponents/SelectComponent";
import * as API from "../../../api"
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import PropertyDetailsEdit from "./PropertyDetailsEdit";
import PropertyDetailsView from "./PropertiesDetailsView";
import BlockDetailsView from "./BlockDetailsView";
import BlockDetailsEdit from "./BlockDetailsEdit";
import EncroachmentView from "./EncroachmentView";
import EnchroachmentEdit from "./EnchroachmentEdit";
import { Popup } from "../../../ReusableComponents/reusableFunctions";
import { getActiveTeamId } from "../../../Teams";

const AssestsDetails = ({ taskid, polygonId, onChange, assetType }) => {
    const [properties, setProperties] = useState(null)
    const [existingProperty, setExistingProperty] = useState(null)
    const [existingBlock, setExistingBlock] = useState(null);
    const [isEditProperty, setIsEditProperty] = useState(false)
    const [isEditBlock, setIsEditBlock] = useState(false);
    const [blocks, setBlocks] = useState(null);
    const [existingEnchroachment, setExistingEnchroachment] = useState(null)
    const [isEditEnchroachment, setIsEditEnchroachment] = useState(false)
    const [enchroachment, setEnchroachment] = useState(null)
    const [loading, setLoading] = useState(false)

    const initData = async () => {
        setLoading(true)
        try {
            setProperties(null)
            setExistingProperty(null)
            setExistingBlock(null)
            setExistingEnchroachment(null)
            const propertiesData = await API.getProperties(taskid, null, null, null, null, false, null, await getActiveTeamId())
            const blocks = await API.getBlocksByPolygonId(polygonId)
            const enchrochments = await API.getEnchrochmentByPolygonId(polygonId)
            setProperties(propertiesData.properties)
            setBlocks(blocks)
            setEnchroachment(enchrochments)

            const existProperty = propertiesData?.properties?.find(p => p.polygon_id === polygonId)
            if (existProperty) {
                setExistingProperty(existProperty)
                setExistingBlock(null)
                setExistingEnchroachment(null)
                onChange({ value: "property", label: "property" })
            }
            else if (blocks) {
                setExistingBlock(blocks)
                setExistingProperty(null)
                setExistingEnchroachment(null)
                onChange({ value: 'block', label: 'block' })
            }
            else if (enchrochments) {
                setExistingEnchroachment(enchrochments)
                setExistingBlock(null)
                setExistingProperty(null)
                onChange({ value: 'enchroachment', label: 'enchroachment' })
            }
            else {
                onChange({ value: 'polygon', label: 'polygon' })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", e.message || e)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (taskid)
            initData()
    }, [polygonId, taskid])

    return (
        (!loading) ?
            <>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    {!(existingProperty || existingBlock || existingEnchroachment) && <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", padding: "0px 20px" }}>
                        <label style={{ fontSize: "13px", marginTop: "8px", color: "#333333" }}>Asset Type: </label>
                        <SelectComponent
                            values={assetType ? [assetType] : []}
                            placeholder="Select Asset Type"
                            disabled={!taskid}
                            itemsList={[{ value: "property", label: "Property" }, { value: "block", label: "Block" }, { value: "enchroachment", label: "Encroachment" }, { value: "polygon", label: "Polygon" }]}
                            onchangeHandler={(value) => {
                                if (value[0].value === "property") {
                                    setIsEditProperty(true)
                                } else if (value[0].value === "block") {
                                    setIsEditBlock(true)
                                }
                                else if (value[0].value === "enchroachment") {
                                    setIsEditEnchroachment(true)
                                }
                            }}
                            fontSize={"14px"}
                            fontWeight={400}
                            width={"205px"}
                            height={"34px"}
                            iconSize={"15px"}
                            border={"1px solid #C3CDD9"}
                            borderRadius={"8px"}
                            backgroundColor={"#F4F4F4"}
                        />
                    </div>
                    }
                    {existingProperty && <PropertyDetailsView onEdit={() => setIsEditProperty(true)} property={existingProperty} />}
                    {existingBlock && <BlockDetailsView onEdit={() => setIsEditBlock(true)} block={existingBlock} />}
                    {existingEnchroachment && <EncroachmentView onEdit={() => setIsEditEnchroachment(true)} block={existingEnchroachment} />}
                </div>
                {isEditProperty && <PropertyDetailsEdit
                    onClose={() => setIsEditProperty(false)}
                    onDone={(property, colorCode) => {
                        setExistingProperty(property)
                        setIsEditProperty(false)
                        onChange({ value: "property", label: "property" }, { hex: colorCode })
                    }}
                    existingProperty={existingProperty}
                    taskid={taskid}
                    polygonId={polygonId} />
                }
                {
                    isEditBlock &&
                    <BlockDetailsEdit
                        onClose={() => {
                            setIsEditBlock(false)
                        }}
                        onDone={(block, colorCode) => {
                            setExistingBlock(block)
                            setIsEditBlock(false);
                            onChange({ value: 'block', label: 'block' }, { hex: colorCode })
                        }}
                        existingBlock={existingBlock}
                        taskId={taskid}
                        polygonId={polygonId} />
                }
                {
                    isEditEnchroachment &&
                    <EnchroachmentEdit
                        onClose={() => {
                            setIsEditEnchroachment(false)
                        }}
                        onDone={(block, colorCode) => {
                            setExistingEnchroachment(block)
                            setIsEditEnchroachment(false);
                            onChange({ value: 'enchroachment', label: 'enchroachment' }, { hex: colorCode })
                        }}
                        existingBlock={existingEnchroachment}
                        taskId={taskid}
                        polygonId={polygonId} />
                }
            </>
            : <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} />

    )
}


export default AssestsDetails;