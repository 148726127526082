import Tippy from '@tippyjs/react';
import React, { useState } from 'react'
import Menu from "../../icons/MenuDark.png"
import Download from '../../icons/Download.png'
import Delete from '../../icons/deleteIcon.svg';
const LayerMenu = ({ onClickDownload, onClickDelete, permissions }) => {
    const [open, setOpen] = useState(false)
    return (
        <Tippy
            visible={open}
            onClickOutside={() => setOpen(false)}
            animation={false}
            interactive
            onShown={(instance) => {
                document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                    instance.hide();
                    setOpen(false)
                })
            }}
            onShow={instance => {
                const el = document.querySelector(".scroll-container")
                if (el)
                    el.addEventListener('scroll', event => {
                        instance.hide()
                        setOpen(false)
                    })
            }}
            className='tippy-menu'
            arrow={false}
            offset={[-40, -60]}
            content={
                <div size="sm" title="" className="custom-dropdown-dashboard">
                    {permissions.DOWNLOAD ?
                        <div eventKey="download" className="dropdown-set-padding-dashboard tippy-menu-hover">
                            <div style={{ display: "flex", cursor: permissions.DOWNLOAD ? 'pointer' : 'not-allowed', opacity: permissions.DOWNLOAD ? 1 : 0.5 }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    onClickDownload(e)
                                    setOpen(false)
                                }}>
                                <img src={Download} style={{ height: "16px" }} />
                                <div style={{ marginLeft: '7px', color: '#3c3c3c' }}>Download</div>
                            </div>
                        </div>
                        : <></>}

                    {permissions?.DELETE && <div eventKey="delete" className="dropdown-set-padding-dashboard tippy-menu-hover">
                        <div style={{ display: "flex", cursor: permissions.DELETE ? 'pointer' : 'not-allowed', opacity: permissions.DELETE ? 1 : 0.5 }}
                            onClick={(e) => {
                                if (permissions.DELETE) {
                                    e.preventDefault()
                                    onClickDelete(e)
                                    setOpen(false)
                                }
                            }}>
                            <img src={Delete} style={{ height: "16px" }} />
                            <div style={{ marginLeft: '7px', color: '#3c3c3c' }}>Delete</div>
                        </div>
                    </div>}
                </div>
            }>
            <div style={{ position: "relative", height: "15px", width: "15px", marginLeft: "20px", marginBottom: "2px", cursor: "pointer" }} onClick={() => setOpen(prev => !prev)}><img src={Menu} style={{ width: "100%", heigth: "100%" }} />
            </div>
        </Tippy>
    )
}

export default LayerMenu