import { atom } from "recoil";
import { TOOLS } from "../Constants/constants";

export const createAtom = (key, defaultValue) => atom({
    key,
    default: defaultValue
});

export const propertyDashBoardLoading = createAtom('propertyDashBoardLoading', true)
export const isRightSlider = createAtom('isRightSlider', true)
export const activeDrawingTool = createAtom('tool', TOOLS.SELECTOR)
export const measurementColor = createAtom('measurementColor', undefined)
export const selectedLayer = createAtom('selectedLayer', undefined)
export const layerPropeties = createAtom('layerProperties', {})
export const toggleLayer = createAtom('toggleLayer', true)
export const importedLayerPropeties = createAtom('importedLayerPropeties', {})
export const openCompareTool = createAtom('openCompareTool', false)
