import React, { Component } from 'react';
import * as API from '../../api.js';
import myMapsInactive from "../../assets/sideBarIcons/myMaps.png";
import service from "../../sidebaricon/Services.png";
import ListIcon from '../../icons/gridBlue.png'
import GridIcon from '../../icons/listBlue.png'
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from '../../redux/index.js'
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import Menu from '../assets/icons/menu.svg';
import { checkExpiry, convertToGB, addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import PlanThumb from '../../icons/PlanThumb.png'
import Noimageset from '../../assets/Images/Noimageset.png';
import Help from "../../sidebaricon/Help.png";
import myMaps from "../../assets/sideBarIcons/myMapsActive.png";
import InfiniteScroll from 'react-infinite-scroll-component'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import TaskIcon from '../../icons/servers.png';
import projectInactive from '../../assets/sideBarIcons/ProjectInactive.png';
import SharedIcon from '../../icons/SharedIcon.png'
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { SortComponent } from '../../ReusableComponents/SortComponent.js';
import { DeleteComponentTable, DeleteComponentGrid } from '../../ReusableComponents/Menu.js';

import dateFormat from 'dateformat'; import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../Teams/index.js';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon.js';
import { BLOB_URL } from '../../AppConstants.js';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <img src={Menu} alt="I am alt" />
  </a>
));
class MyMaps extends Component {

  state = {
    user: {},
    type: "",
    key: " ",
    progress: 0,
    userperpage: 12,
    currentpage: 0,
    firstpage: 1,
    counts: 0,
    showDownloadPopup: false,
    projectRenamePopup: false,
    loader: true,
    taskStatusCount: {},
    services: [],
    showBackIcon: true,
    cancel: false,
    loading: true,
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    key: '',
    hasMoreItems: true,
    maps: [],
    count: 0,
    sortType: 'Last Modified',
    outId: "",
    isOwner: true,
    sharedBy: null,
    itemName: "",
    processPopupIcon: "",
    processAction: "",
    processMessage: null,
    showProcessPopup: false,
    gridView: true,
    selectedMapCollectionId: null
  }
  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  //Get SearchKey Function Start Here-----
  search(key) {
    if (key == '') {
      this.setState((state) => ({
        ...state,
        hasMoreItems: true
      }))
    }
    this.setState((state) => ({
      ...state,
      key,
      currentpage: 0
    }),
      () => this.getMaps());
  }

  deleteOrthomosaic = () => {
    this.setState((state) => ({
      ...state,
      itemName: "Map",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }))
    if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE) API.deleteOrthomosaicImage({ collection_id: this.state.selectedMapCollectionId }).then(() => {
      // this.getImageOrthomosaicImages();
      this.setState((state) => ({
        ...state,
        itemName: "Map",
        processPopupIcon: "COMPLETE",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
        selectedMapCollectionId: '',
        loading: true,
        hasMoreItems: true,
        maps: []
      }), () => {
        this.getMaps()
      })
    }).catch(e => {
      this.setState((state) => ({
        ...state,
        showAddImagePopup: false,
        itemName: "Map",
        processPopupIcon: "ERROR",
        processAction: "DELETE",
        processMessage: e,
        showProcessPopup: true,
      }))
    })
  }

  isUserOwnerOfCollection = () => {
    const { id } = this.state.project
    API.isUserProjectOwner(id)
      .then((result) => {
        this.setState({
          isOwner: result.isShared ? false : true,
          sharedBy: result.sharedBy
        })
      })
      .catch((err) => {
        this.setState((state) => ({
          ...state,
          itemName: "",
          processPopupIcon: "ERROR",
          processAction: "ADD",
          processMessage: "Something went wrong while getting owner information",
          showProcessPopup: true,
          loading: false,
          isOwner: false,
        }))
      })
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  getMaps = async () => {
    let { user, key } = this.state;
    key = key == "" ? " " : key
    if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) setTimeout(() => {
      API.getAllMaps(key, this.state.maps.length, 12, this.props.location.state.plan ? "plan" : "user", this.props.location.state.plan ? this.props.location.state.plan.id : undefined).then(async (data) => {
        if (data.data.length == 0 || data.data.length < 12) {
          this.setState((state) => ({
            ...state,
            hasMoreItems: false,
            loading: false
          }));
        }
        var maps = this.state.maps
        maps = maps.concat(data.data)
        this.setState((state) => ({
          ...state,
          maps: maps,
          loading: false,
          count: data.count
        }), () => {
          if (this.state.sortType === 'A-Z') {
            this.sortByName()
          } else if (this.state.sortType === 'Z-A') {
            this.reverseByName()
          } else if (this.state.sortType === 'First Modified') {
            this.sortByDate()
          } else if (this.state.sortType === 'Last Modified') {
            this.reverseByDate()
          }
        })
      }, (e) => {
        console.log(e);
        this.setState((state) => ({
          ...state,
          maps: [],
          loading: false,
          count: 0
        }))
      })
    }, 500)
    else this.setState((state) => ({
      ...state,
      maps: [],
      loading: false,
      count: 0
    }))
  }

  sortByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'A-Z'
    }));
    let sort = this.state.maps.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
    this.setState((state) => ({
      ...state,
      maps: sort,
    }));

  }

  reverseByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Z-A'
    }));
    let sort = this.state.maps.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
    this.setState((state) => ({
      ...state,
      maps: sort,
    }));
  }

  sortByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'First Modified'
    }));
    let sort = this.state.maps.sort((a, b) => a.processing_date.localeCompare(b.processing_date))
    this.setState((state) => ({
      ...state,
      maps: sort,
    }));
  }

  reverseByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Last Modified'
    }));
    let sort = this.state.maps.sort((a, b) => b.processing_date.localeCompare(a.processing_date))
    this.setState((state) => ({
      ...state,
      maps: sort,
    }));
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
  }

  componentWillMount() {
    getFeaturesPermissions([DRONENAKSHA_FEATURES.IMAGECOLLECTIONS, DRONENAKSHA_FEATURES.PLANS, DRONENAKSHA_FEATURES.MAPS])
      .then(permissions => {
        addUserAnalytic(this.props.location.pathname)
          .then(id => {
            console.log("ID : ", id)
            this.setState((state) => ({
              outId: id
            }))
          })

        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.popup-content { width: 30% !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);
        if (this.props.location.state.project) document.title = "DroneNaksha - Maps";
        else document.title = "DroneNaksha - All Maps";
        if (this.props.location.state) {
          let { user, type, sidebarMode, project } = this.props.location.state;
          console.log(this.props);
          this.setState((state) => ({
            ...state,
            user,
            type,
            project,
            permissions,
            sidebarMode
          }), async () => {
            await this.getMaps()
            if (project && project.id)
              this.isUserOwnerOfCollection()
            API.getUserSubbedServices().then(
              (response) => {
                let services = response[0];
                console.log(services);
                if (services.length == 1 && services[0].title == 'AeroStream') {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: false
                  }))
                } else {
                  this.setState((state) => ({
                    ...state,
                    showBackIcon: true
                  }))
                }
              }
            )
          })
        } else {
          API.getUserType().then(([blah, type, user]) => {
            this.setState((state) => ({
              ...state,
              user,
              type
            }), () => {
              this.getMaps()
            })
          }, (e) => {
            window.location.replace("/login");
          })
        }
      })
  }



  render() {
    let { user, type, showBackIcon } = this.state
    const listLoader = <div style={{ height: "80vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
    </div>

    const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <div className='sort-type-style'>{this.state.sortType}</div>
      </a>
    ));

    return (
      user.username ? <div className="wrapper">
        <div style={{ display: 'flex' }}>
          <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
            <div style={{ display: "flex", height: "40px" }}>
              <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                <img
                  src={AeroMeghLogo}
                  style={{ width: "65px", height: "20px", marginTop: "10px" }}
                />
              </div>
            </div>
            <ul className="sidebar-nav" id="sidebar-nav">
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        type,
                        user,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={service}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        {"Services"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/dashboard",
                      state: {
                        type,
                        user,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={dashboardIcon}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        // style={{ color: "#2989CF" }}
                        onClick={this.changeColor}
                      >
                        {"DroneNaksha"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              {this.props.location.state.project ? <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/plan",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={projectInactive}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        {"Plan"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div> : <></>}

              <div className={"selectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/dronenaksha/myMaps",
                      state: {
                        type,
                        user,
                        plan: this.props.location.state.plan,
                        project: this.props.location.state.project,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={myMaps}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        style={{ color: "#2989CF" }}
                        onClick={this.changeColor}
                      >
                        {this.props.location.state.project ? <>Maps</> : <>All maps</>}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              {this.props.location.state.project ?
                <>
                  <div className={"nonSelectBorder"} >
                    <li>
                      <Link
                        to={{
                          pathname: "/dronenaksha/image_collections",
                          state: {
                            type,
                            user,
                            plan: this.props.location.state.plan,
                            project: this.props.location.state.project,
                          },
                        }}
                      >
                        <div
                          className="sidecontent"
                          id="sidecontent"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="sidebar-icon">
                            <img
                              className="sidebar-icon"
                              src={ImageSetInActive}
                              alt="serviceIcon"
                            />
                          </div>
                          <div
                            name="service"
                            className={"sidebaropt"}
                            //style={{ color: "#2989CF" }}
                            onClick={this.changeColor}
                          >
                            {"Imagesets"}
                          </div>
                        </div>
                      </Link>
                    </li>
                  </div>
                  <div className={"nonSelectBorder"} >
                    <li>
                      <Link
                        to={{
                          pathname: "/dronenaksha/tasks",
                          state: {
                            type,
                            user,
                            plan: this.props.location.state.plan,
                            project: this.props.location.state.project,
                          },
                        }}
                      >
                        <div
                          className="sidecontent"
                          id="sidecontent"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="sidebar-icon">
                            <img
                              className="sidebar-icon"
                              src={TaskIcon}
                              alt="serviceIcon"
                            />
                          </div>
                          <div
                            name="service"
                            className={"sidebaropt"}
                            // style={{ color: "#2989CF" }}
                            onClick={this.changeColor}
                          >
                            {"Tasks"}
                          </div>
                        </div>
                      </Link>
                    </li>
                  </div>
                </> : <></>}

              <div className={"nonSelectBorder"} >
                <li>
                  <div
                    className="onhoverext"
                    onClick={
                      () => {
                        window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                      }
                    }
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={Help}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                      >
                        {"Help"}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div style={{ height: "98vh" }} className="right-content"
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <div className="top-bar">
            {(this.props.location.state.project ?
              <div className="breadCrumbs">
                <BreadCrumbs data={[
                  {
                    displayName: "projects",
                    pathname: "/dronenaksha/projects",
                    state: {
                      type,
                      user,
                    },
                    style: {
                      color: "black",
                      fontSize: "12px",
                    }
                  },
                  {
                    displayName: this.props.location.state.project.project_name,
                    pathname: "/dronenaksha/project",
                    state: {
                      type,
                      user,
                      plan: this.props.location.state.plan,
                      project: this.props.location.state.project,
                    },
                    style: {
                      color: "black",
                      fontSize: "12px"
                    }
                  },
                  {
                    displayName: this.props.location.state.plan.plan_name,
                    pathname: "/dronenaksha/plan",
                    state: {
                      type,
                      user,
                      plan: this.props.location.state.plan,
                      project: this.props.location.state.project
                    },
                    style: {
                      color: "black",
                      fontSize: "12px"
                    }
                  },
                  {
                    displayName: "Maps",
                  }
                ]} />
                {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}

                <ActiveRole />
              </div>

              : <>
                <div className="top-bar-text">ALL MAPS <ActiveRole /></div>

              </>
            )
            }
          </div>

          <div className="main-content" style={{ background: 'none' }}>


            {/* --------------Activity Popup ----------------------- */}
            <ActivityPopup
              item={this.state.itemName}
              open={this.state.showProcessPopup}
              icon={this.state.processPopupIcon}
              action={this.state.processAction}
              msg={this.state.processMessage}
              close={() => this.closeActivityPopup()}
              onClickOk={() => this.deleteOrthomosaic()}
            />

            {/* --------------------------------------------------------------- */}
            {this.state.loading ? <div style={{ height: "82vh", width: "91vw", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div> : <div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginBottom: this.state.gridView && this.state.maps.length > 0 ? "0px" : "30px", width: '100%', alignItems: 'center' }}>
                <div className="services-content-title">{`Maps`}
                  <span className='data-count'>({this.state.count})</span>
                </div>
                <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                  {this.props.location.state.plan && this.state.maps.length > 0 ?
                    // <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }}>
                    //   <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", }}>
                    //     {<Link
                    //       to={{
                    //         pathname: "/dronenaksha/imagesets-on-map",
                    //         state: {
                    //           type,
                    //           user,
                    //           plan: this.props.location.state.plan,
                    //           project: this.props.location.state.project
                    //         },
                    //       }}
                    //     >
                    //       <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '20px', paddingRight: '20px' }}>View on Map</div>
                    //     </Link>}
                    //   </div></button>
                    <div style={{ filter: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "" : "grayscale(100%)" }}>

                      <ButtonWithIcon text="View On Map" linkData={{
                        pathname: "/dronenaksha/imagesets-on-map",
                        state: {
                          type,
                          user,
                          plan: this.props.location.state.plan,
                          project: this.props.location.state.project
                        },
                      }} isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ} />
                    </div>
                    : <></>}
                  <div
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        gridView: !state.gridView,
                      }), () => {
                        if (this.state.maps.length > 0 && !this.state.gridView)
                          document.getElementById('table').scrollTop = 0
                      })
                    }}>
                    <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '24px', cursor: 'pointer', margin: '0px 18px' }}
                    />
                  </div>
                </div>
              </div>
              {this.state.maps.length > 0 && this.state.gridView ? <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                  <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                </div>
                {this.state.maps.length > 0 && !this.state.loading ?
                  <div style={{ height: "30px" }}>
                    <SortComponent
                      label={'Name'}
                      sortAscending={this.sortByName}
                      sortDecending={this.reverseByName}
                      sortASC={true}
                    />
                  </div>
                  : <></>}
              </> : <></>}
              {this.state.maps.length > 0 ?
                <InfiniteScroll
                  className='scroll-container'
                  height={this.state.gridView ? "73vh" : "78vh"}
                  dataLength={this.state.maps ? this.state.maps.length : 0} //This is important field to render the next data
                  next={() => {
                    this.getMaps()
                  }}
                  hasMore={this.state.hasMoreItems}
                  loader={listLoader}
                >
                  {this.state.gridView ?
                    <div style={{ width: "85vw" }} className="aerocapture-cards-tray-images-map1 scroll-container">
                      {this.state.maps.map((map, key) => {
                        return (
                          <div>
                            <Link to={{
                              pathname: `/dronenaksha/view_plan`,
                              state: {
                                user, type,
                                plan: { id: map.plan_id, plan_name: map.plan_name, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                project: { id: map.project_id, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                collection: map,
                                orthoImageCollectionId: map.id,
                                layerShow: "RGBShow", sidebarMode: this.state.sidebarMode
                              }
                            }}>
                              {<div key={key} style={{ height: "200px", width: "300px", borderRadius: "10px", boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                <div className="card-header customer-cards-map project-card-header" style={{ borderRadius: "6px" }}>
                                  {
                                    map && map.task_id ?
                                      <img id="ProjectImageMap" style={{ objectFit: 'contain' }} loading="true" src={`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${this.state.permissions.st}`} onError={(e) => { e.currentTarget.src = PlanThumb; }} /> :
                                      <img id="ProjectImageMap" src={PlanThumb} />
                                  }
                                </div>
                                <div style={{ position: "relative", bottom: "62px" }} className="card-body change-color customer-cards-dashboard-text-map project-card-body">
                                  {map.sharedData.isShared ? <div className='shared-badge' style={{ position: "absolute", top: "-136px" }}>
                                    <img src={SharedIcon} style={{ height: "18px" }} />
                                  </div> : <></>}
                                  <DeleteComponentGrid
                                    style={{ position: "absolute", top: "-128px", right: "2px" }}
                                    disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE}
                                    onClickDelete={(e) => {
                                      e.preventDefault()
                                      this.setState((s) => ({
                                        ...s,
                                        selectedMapCollectionId: map.id,
                                        itemName: "Map",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: "Are you sure you want to delete this Map ? ",
                                        showProcessPopup: true,
                                      }))
                                    }} />
                                  <div style={{ fontSize: '13px', display: 'flex', height: "20px", alignItems: "center", justifyContent: 'space-between' }}>
                                    <div style={{ overflow: "hidden", }}>
                                      {
                                        map.collection_name.length > 18 ?
                                          `${map.collection_name.substring(0, 17)}...` :
                                          `${map.collection_name}`
                                      }
                                    </div>
                                    <div style={{ overflow: "hidden" }}>{convertToGB(map.img_size)}</div>
                                  </div>
                                  <div style={{ fontSize: '10px', display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                    <div style={{ overflow: "hidden" }}>{`${map?.gsd?.indexOf('NaN') > -1 ? '-' : map?.gsd}`}</div>
                                    <div style={{ overflow: "hidden" }}>{`${map.images_used} Images`}</div>
                                  </div>
                                  <div style={{ marginTop: "2px", fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ overflow: 'hidden' }}>{map?.area ? map.area : ``}</div>
                                    <div> {
                                      (() => {
                                        let date = (new Date(map.processing_date)).toLocaleDateString('en-US')
                                        date = dateFormat(date, "dd-mmm-yyyy")
                                        return date
                                      })()
                                    }</div>
                                  </div>
                                </div>
                              </div>}
                            </Link>
                          </div>
                        )
                      })}
                    </div> :
                    <div id='table' className='scroll-container'>
                      <table className="customers-table" style={{ marginTop: '0px' }}>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '5%' }} />
                        <tr className="customers-table-header-row" style={{ background: "#EAEAEA" }}>
                          <th></th>
                          <th>Name
                            <SortComponent
                              style={{ marginLeft: '5px' }}
                              sortAscending={this.sortByName}
                              sortDecending={this.reverseByName}
                              sortASC={false}
                            />
                          </th>
                          <th>Size</th>
                          <th>Area</th>
                          <th>Processing Date
                            <SortComponent
                              style={{ marginLeft: '5px' }}
                              sortAscending={this.sortByDate}
                              sortDecending={this.reverseByDate}
                              sortASC={false}
                            />
                          </th>
                          <th></th>
                        </tr>
                        {
                          this.state.maps.map((map) =>
                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                              <td>
                                {
                                  map && map.task_id ?
                                    <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${this.state.permissions.st}`} onError={(e) => { e.currentTarget.src = myMapsInactive; e.target.style = { undefined }; }} /> :
                                    <img id="ProjectTableImage" src={myMapsInactive} />
                                }
                              </td>
                              <td>
                                <Link style={{ color: "#3c3c3c" }}
                                  to={{
                                    pathname: `/dronenaksha/view_plan`,
                                    state: {
                                      user, type,
                                      plan: { id: map.plan_id, plan_name: map.plan_name, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                      project: { id: map.project_id, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                      collection: map,
                                      orthoImageCollectionId: map.id,
                                      layerShow: "RGBShow", sidebarMode: this.state.sidebarMode
                                    }
                                  }}>
                                  {map.collection_name}<span style={{ color: "#2989cf" }}>{map.sharedData.isShared ? ' (Shared)' : ''}</span>
                                </Link>
                              </td>
                              <td>{convertToGB(map.img_size)}</td>
                              <td>{map?.area}</td>
                              <td>
                                {
                                  (() => {
                                    let date = (new Date(map.processing_date)).toLocaleDateString('en-US')
                                    date = dateFormat(date, "dd-mmm-yyyy")
                                    return date
                                  })()
                                }
                              </td>

                              <td>
                                <DeleteComponentTable
                                  style={{ margin: "0 20px" }}
                                  disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].DELETE}
                                  onClickDelete={(e) => {
                                    this.setState((s) => ({
                                      ...s,
                                      selectedMapCollectionId: map.id,
                                      itemName: "Map",
                                      processPopupIcon: "WARNING",
                                      processAction: "DELETE",
                                      processMessage: "Are you sure you want to delete this Map ?",
                                      showProcessPopup: true,
                                    }))
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                      </table>
                    </div>}
                </InfiniteScroll>
                :
                <div className="rmp-dashboard-empty-data-message" style={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                  <img style={{ height: '200px', width: '200px' }} src={Noimageset} />
                  <p style={{ marginTop: '10px' }}>No maps available</p>
                </div>}
            </div>}
          </div>
        </div>
      </div> : <div />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload))
  };
};

const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    firstTimeLoginDN: state.profile.firstTimeLoginDN,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMaps);

