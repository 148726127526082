import React, { useEffect, useState } from 'react';
import pdf from "../../assets/icons/pdf.svg";
import dwg from "../../assets/icons/dwg.svg";
import excel from "../../assets/icons/exel.svg";
import googleDocs from "../../assets/icons/google-docs.png";
import noFiles from "../../assets/icons/noFile.svg"
import jpg from "../../assets/icons/jpg.svg"
import zip from "../../assets/icons/zip-file.png"
import AppConstants from '../../../AppConstants';
import deleteIcon from "../../assets/icons/Delete.svg"

export const excelExtensions = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls', 'xml', 'xml', 'xlam', 'xla', 'xlw', 'xlr'];

const FilesContainer = ({ filesProp, actionCallback, fileType }) => {

  const formatFileName = (filename) => {
    if (filename?.length > 22) {
      return filename.substring(0, 22) + "...";
    }
    return filename;
  }
  let msg = ""
  if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
    msg = "property reports"
  } else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
    msg = "property files"
  }
  else if (fileType == AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
    msg = "block details"
  }
  else if (fileType == AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
    msg = "block reports"
  }
  else if (fileType == AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
    msg = "block files"
  }
  else if (fileType == AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
    msg = "block du details"
  }

  return (
    <div style={{ margin: "25px 0px 25px 0px" }}>
      {filesProp.length > 0 ?
        Array.from(filesProp).map((file) => (
          <div key={file?.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px", gap: "12px", justifyContent: "space-between" }}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center", }}>
              <img src={file?.name?.split('.').pop() === 'pdf' ? pdf :
                file?.name?.split('.').pop() === 'dwg' ? dwg :
                  file?.name?.split('.').pop() === 'zip' ? zip :
                    file?.name?.split('.').pop() === 'jpg' ? jpg :
                      file?.name?.split('.').pop() === 'JPG' ? jpg :
                        excelExtensions.includes(file?.name?.split('.').pop()) ? excel :
                          googleDocs} alt="file icon" style={{ width: "24px" }} />
              <div className='poppins-font' style={{ textDecoration: "underline" }}>{formatFileName(file?.name || file?.files?.name) || '-'}</div>
            </div>
            <img src={deleteIcon} alt='close icon' style={{ width: "18px", cursor: "pointer" }} onClick={() => {
              actionCallback(file.id);
            }} />
          </div>
        )) :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={noFiles} alt='noFiles' style={{ width: "106px" }} />
          <div style={{ fontSize: "14px", marginTop: "10px" }}>You haven’t uploaded any  {msg}  yet</div>
        </div>
      }
    </div>
  );
};


export default FilesContainer;
