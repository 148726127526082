import React from 'react'
import "./MoonLoader.css"
const MoonLoader = () => {
    return (
        <div style={{ width: "100%", height: "100%", position: "absolute", left: "0", top: 0, display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99 }}>
            <div style={{ width: "463px", height: "119px", display: "flex", alignItems: "center", justifyContent: "center", background: "white", borderRadius: "8px", gap: "30px" }}>
                <svg width="86" height="85" viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg" class="loading-svg">
                    <path opacity="0.7" d="M73.0521 42.5C73.0521 59.0973 59.5973 72.5521 43 72.5521C26.4028 72.5521 12.948 59.0973 12.948 42.5C12.948 25.9028 26.4028 12.448 43 12.448C59.5973 12.448 73.0521 25.9028 73.0521 42.5ZM21.9636 42.5C21.9636 54.1181 31.3819 63.5365 43 63.5365C54.6181 63.5365 64.0365 54.1181 64.0365 42.5C64.0365 30.8819 54.6181 21.4636 43 21.4636C31.3819 21.4636 21.9636 30.8819 21.9636 42.5Z" fill="#2989CF" fill-opacity="0.1" />
                    <path d="M38.9986 12.7155C43.6989 12.084 48.4819 12.574 52.9566 14.1452C57.4313 15.7165 61.4705 18.3244 64.7442 21.7559L58.2209 27.9791C55.9294 25.5771 53.1019 23.7515 49.9696 22.6516C46.8374 21.5518 43.4892 21.2088 40.199 21.6509L38.9986 12.7155Z" fill="#2989CF" />
                </svg>
                <div style={{ fontSize: "18px", color: "#3c3c3c" }}>Please Wait... We are loading...</div>
            </div>
        </div>
    )
}

export default MoonLoader