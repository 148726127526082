import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'
import userIcon from './assets/user.svg'
import lockIcon from './assets/lock.svg'
import tickLockIcon from './assets/ticklock.svg'
import googleIcon from './assets/google.png'
import Cookies from 'universal-cookie';
import Loader from '../icons/loader.svg'
import microsoftIcon from './assets/microsoft.png'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import CookiePolicyPopup from "./CookiePolicyPopup.js";
import { FormWrapper, InputBox } from "./FormTopBar.js";

const cookie = new Cookies()
const query = new URLSearchParams(window.location.search);

class userSignup extends Component {
    state = {
        first_name: "",
        last_name: "",
        password: "",
        passwordVerify: "",
        address: "",
        email: "",
        error: "",
        registrationType: "individual",
        checked: false,
        signUpSuccess: false,
        loading: false,
    }

    componentWillMount() {
        if (query.get("token")) {
            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            cookie.set("session_IAMAero", query.get("token"), {
                path: "/"
            });
            window.location.replace("/")
        }
        document.title = "DroneLab - Signup";
    }

    componentDidMount() {
        setTimeout(() => {
            if (query.get("error")) {
                let msg = query.get("error") == 0 ? "Google authentication failed, please try again." : "Microsoft authentication failed, please try again."
                this.setState((state) => ({
                    ...state,
                    itemName: "Login",
                    processPopupIcon: "ERROR",
                    processAction: "",
                    processMessage: msg,
                    showProcessPopup: true,
                }))
            }
        }, 1000)

    }

    onFormSubmit = (formData) => {
        formData.preventDefault()
        if (this.state.password === this.state.passwordVerify) {
            API.signUp(this.state).then((e) => {
                this.setState({ signUpSuccess: true })
            })
                .catch((e) => {
                    e.then(error => {
                        this.setState({
                            error: undefined, error: error.message || "Something went wrong..."
                        })
                    })

                })
        } else {
            this.setState({
                error: "Passwords do not match"
            })
        }
    }

    googleLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/google`)
    }

    microsoftLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/microsoft`)
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    render() {
        return (
            <div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.closeActivityPopup()}
                >

                </ActivityPopup>

                {this.state.loading ?
                    <div style={{ height: "100vh", width: "100vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{
                            height: "30vh",
                            width: "100vw",
                            margin: "auto"
                        }} />
                    </div>
                    :
                    <div className="authPage">
                        <CookiePolicyPopup />
                        {this.state.signUpSuccess ? <div className="cardLogin login-card" style={{ height: '32rem', widthL: '23rem', paddingTop: '40px' }} >
                            <img src={aeromeghLogo} className="login-logo" />
                            <p className="greet login-greet" style={{ marginBottom: '5px' }}>Thanks for registering</p>
                            <div>
                                <div className="content-text" style={{ padding: '60px 40px', fontSize: "16px" }}>
                                    An account activation link has been sent to your email.
                                </div>
                                <div class="spacer" style={{ height: '4.9vh' }}> </div>
                                <Link to={{
                                    pathname: "/login",
                                }}>
                                    <button style={{ cursor: 'pointer' }} className="loginButton" name="loginButton"
                                    >LOGIN</button>
                                </Link>
                                <div class="spacer" style={{ height: '4.9vh' }}> </div>
                            </div>
                        </div> : <>
                            <FormWrapper onSubmit={this.onFormSubmit} error={this.state.error} childrenWidth='80%'>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="greet login-greet" style={{ margin: '0 0 10px 0', fontSize: "16px", fontWeight: "400" }}>SIGN UP TO DRONELAB</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '25px', width: '100%' }}>
                                        {/* First name */}
                                        <InputBox
                                            autoFocus
                                            onChange={(e) => {
                                                this.setState({
                                                    error: undefined,
                                                    first_name: e.target.value,
                                                })
                                            }}
                                            onClick={(e) => { this.setState({ selectedField: e.target.id }) }}
                                            selected={this.state.selectedField == 'first_name'}
                                            id="first_name" name="First Name" type="text"
                                            pattern="([a-zA-Z]{3, 30}\s*)+"
                                            title="First Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters"
                                            placeholder="First Name"
                                            required
                                        />

                                        {/* Last Name */}
                                        <InputBox
                                            autoFocus
                                            onChange={(e) => {
                                                this.setState({
                                                    error: undefined,
                                                    last_name: e.target.value
                                                })
                                            }}
                                            onClick={(e) => { this.setState({ selectedField: e.target.id }) }}
                                            selected={this.state.selectedField == 'last_name'}
                                            id="last_name" name="Last Name" type="text"
                                            pattern="([a-zA-Z]{3,30}\s*)+"
                                            title="Last Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters"
                                            placeholder="Last Name"
                                            required
                                        />
                                    </div>

                                    {/* E-mail */}
                                    <InputBox
                                        autoFocus
                                        onChange={(e) => {
                                            this.setState({
                                                error: undefined,
                                                email: e.target.value.toLowerCase()
                                            })
                                        }}
                                        onClick={(e) => { this.setState({ selectedField: e.target.id }) }}
                                        selected={this.state.selectedField == 'email'}
                                        id="email" name="Email" type="email" placeholder="E-mail"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        title="Last Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters"
                                        maxLength={100}
                                        required
                                    />

                                    {/* Password */}
                                    <InputBox
                                        autoFocus
                                        onChange={(e) => {
                                            this.setState({
                                                error: undefined,
                                                password: e.target.value
                                            })
                                        }}
                                        onClick={(e) => { this.setState({ selectedField: e.target.id }) }}
                                        selected={this.state.selectedField == 'password'}
                                        id="password" name="Password" type="password" placeholder="Password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required
                                    />

                                    {/* Confirm Password */}
                                    <InputBox
                                        autoFocus
                                        onChange={(e) => {
                                            this.setState({
                                                error: undefined,
                                                passwordVerify: e.target.value
                                            })
                                        }}
                                        onClick={(e) => { this.setState({ selectedField: e.target.id }) }}
                                        selected={this.state.selectedField == 'cpassword'}
                                        id="cpassword" name="Confirm Password" type="password"
                                        placeholder="Confirm Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required
                                    />
                                    <div className="signup" style={{ margin: 'auto', color: '#898989', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <input type="checkbox" className="signup" id="tnc" name="tnc"
                                            onChange={() => {
                                                this.setState({
                                                    error: undefined,
                                                    checked: !this.state.checked
                                                })
                                            }}
                                            style={{ display: 'flex' }} />
                                        I agree to
                                        <a className="signup"
                                            href="https://aeromegh.com/index.php/end-user-license-agreement-eula/" target="_blank"
                                            style={{ color: '#898989' }}><u>terms and conditions</u></a>
                                    </div>
                                    <div className="spacer" style={{ height: '1.9vh' }}> </div>

                                    <button type="submit"
                                        name="loginButton"
                                        id="loginButton"
                                        className={(!this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "") ? "loginButtonDisabled" : "loginButton"}
                                        disabled={(!this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "")}
                                        style={{ marginTop: "20px", height: "33px", fontSize: '14px', fontWeight: '400', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >Sign Up
                                    </button>

                                    <div className="spacer" style={{ height: '1.9vh' }}> </div>
                                    <div className="signup" style={{ color: '#104C91', fontSize: "13px", fontWeight: 400 }}>
                                        Already have an account?
                                        <Link className="login-signup-button" to={{
                                            pathname: "/login",
                                        }} style={{ marginLeft: '4px', fontWeight: 500 }}><u>Log in</u></Link>
                                    </div>
                                </div>

                            </FormWrapper>

                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div className="signup" style={{ display: 'flex', width: '18rem', alignItems: 'center', textAlign: 'center', fontSize: '12px', justifyContent: 'center', marginTop: '10px' }}>
                                        <hr style={{ width: '4rem', borderTop: '1px solid #c1c1c1', marignRight: '10px' }} />
                                        <span style={{ margin: '0px -10px' }}>
                                            Or Signup with
                                        </span>
                                        <hr style={{ width: '4rem', borderTop: '1px solid #c1c1c1', marignLeft: '10px' }} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', width: '18rem', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                        <div className="signup sigin-oauth"
                                            style={{ display: 'flex', border: '1px solid #c1c1c1', height: '40px', cursor: 'pointer', width: '8.5rem', alignItems: 'center', textAlign: 'center', justifyContent: 'center', marginTop: '5px', borderRadius: '3px' }}
                                            onClick={() => {
                                                this.googleLogin()
                                            }}
                                        >
                                            <img src={googleIcon} style={{ height: '17px', width: '17px', marginRight: '10px' }} />
                                            Google
                                        </div>

                                        <div className="signup sigin-oauth"
                                            style={{ display: 'flex', border: '1px solid #c1c1c1', height: '40px', cursor: 'pointer', width: '8.5rem', alignItems: 'center', textAlign: 'center', justifyContent: 'center', marginTop: '5px', borderRadius: '3px' }}
                                            onClick={() => {
                                                this.microsoftLogin()
                                            }}
                                        >
                                            <img src={microsoftIcon} style={{ height: '17px', width: '17px', marginRight: '10px' }} />
                                            Microsoft
                                        </div>
                                    </div>
                                </div> */}
                        </>}

                    </div>
                }
            </div>
        );
    }
}

export default userSignup
