import React, { useEffect, useState } from 'react'
import { AnimatedModal } from '../../../ReusableComponents/AnimatedModal'
import manageAccess from '../../../assets/manageAccess.png'
import SearchIcon from '../../../icons/SearchIcon.png'
import Close from "../../../icons/closeButton.png"
import LoaderComponent from '../../../ReusableComponents/LoaderComponent'
import { getPropertiesAccesses } from '../../../api'
import { getOwnTeamMembers } from '../../../Teams'
import { Popup } from '../../../ReusableComponents/reusableFunctions'
import TeamMemebersContainer from './TeamMemebersContainer'
import { useDebouncedCallback } from 'use-debounce';

const updateMembers = () => {

}

const PropertyAccessPopup = ({ selectedProperty, isOpen, onClose }) => {
    const [hasAccessedMembers, setHasAccessedMembers] = useState([]);
    const [hasNoAccessedMembers, setHasNoAccessedMembers] = useState([]);
    const [searchHasAccessedMembers, setSearchHasAccessedMembers] = useState([]);
    const [searchHasNoAccessedMembers, setSearchHasNoAccessedMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getTeamMembersOfOwner = async () => {
        try {
            setLoading(true);
            const { teamMembers } = await getOwnTeamMembers();
            const { propertyAccesses } = await getPropertiesAccesses(selectedProperty?.id);

            const accessed = [];
            const notAccessed = [];
            const searchAccessed = [];
            const searchNotAccessed = [];

            teamMembers.forEach((tM) => {
                const hasAccessMember = propertyAccesses.find((pA) => pA.user_id === tM?.id);
                if (hasAccessMember) {
                    accessed.push({
                        ...tM,
                        hasAccess: true,
                        accessId: hasAccessMember?.id,
                        roleId: hasAccessMember?.role_id
                    });
                    searchAccessed.push({
                        ...tM,
                        hasAccess: true,
                        accessId: hasAccessMember?.id,
                        roleId: hasAccessMember?.role_id
                    });
                } else {
                    notAccessed.push({
                        ...tM,
                        hasAccess: false,
                        accessId: null,
                        roleId: null
                    });
                    searchNotAccessed.push({
                        ...tM,
                        hasAccess: false,
                        accessId: null,
                        roleId: null
                    });
                }
            });

            setHasAccessedMembers(accessed);
            setSearchHasAccessedMembers(searchAccessed);
            setHasNoAccessedMembers(notAccessed);
            setSearchHasNoAccessedMembers(searchNotAccessed);
            setLoading(false);
        } catch (error) {
            console.log('error:', error);
            Popup.alert("ERROR", error || error?.message);
        }
    };

    const updateAccessMembers = (memberList, userId, updateData) => {
        const filteredList = memberList.filter(member => member?.id !== userId);
        const changedMember = memberList.find(member => member?.id === userId);
        return [filteredList, changedMember ? { ...changedMember, ...updateData } : null];
    };

    const handleOnAccessAdd = (accessedProperty) => {
        const updateData = {
            hasAccess: true,
            accessId: accessedProperty?.id,
            roleId: accessedProperty?.role_id,
        };

        const [filteredHasNoAccess, changedAccessMember] = updateAccessMembers(hasNoAccessedMembers, accessedProperty?.user_id, updateData);
        if (changedAccessMember) {
            setHasNoAccessedMembers(filteredHasNoAccess);
            setHasAccessedMembers(prev => [changedAccessMember, ...prev]);
        }

        const [searchFilteredHasNoAccess, searchChangedAccessMember] = updateAccessMembers(searchHasNoAccessedMembers, accessedProperty?.user_id, updateData);
        if (searchChangedAccessMember) {
            setSearchHasNoAccessedMembers(searchFilteredHasNoAccess);
            setSearchHasAccessedMembers(prev => [searchChangedAccessMember, ...prev]);
        }
    };

    const handleOnAccessRemove = (accessedProperty) => {
        const updateData = {
            hasAccess: false,
            accessId: null,
            roleId: null,
        };

        const [filteredHasAccess, changedAccessMember] = updateAccessMembers(hasAccessedMembers, accessedProperty?.user_id, updateData);
        if (changedAccessMember) {
            setHasAccessedMembers(filteredHasAccess);
            setHasNoAccessedMembers(prev => [changedAccessMember, ...prev]);
        }

        const [searchFilteredHasAccess, searchChangedAccessMember] = updateAccessMembers(searchHasAccessedMembers, accessedProperty?.user_id, updateData);
        if (searchChangedAccessMember) {
            setSearchHasAccessedMembers(searchFilteredHasAccess);
            setSearchHasNoAccessedMembers(prev => [searchChangedAccessMember, ...prev]);
        }
    };


    const searchTeamMembers = useDebouncedCallback((searchString) => {
        if (!searchString) {
            setSearchHasAccessedMembers(hasAccessedMembers);
            setSearchHasNoAccessedMembers(hasNoAccessedMembers);
            return;
        }

        const filteredHasAccessedMembers = hasAccessedMembers.filter(hN => hN?.userFullName.toLowerCase().includes(searchString.toLowerCase()));
        const filteredHasNoAccessedMembers = hasNoAccessedMembers.filter(hN => hN?.userFullName.toLowerCase().includes(searchString.toLowerCase()));

        setSearchHasAccessedMembers(filteredHasAccessedMembers);
        setSearchHasNoAccessedMembers(filteredHasNoAccessedMembers);
    }, 300);

    useEffect(() => {
        if (selectedProperty) {
            getTeamMembersOfOwner();
        }
    }, [selectedProperty]);

    return (
        <AnimatedModal
            isOpen={isOpen}
            height="400px"
            width="700px"
        >
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ width: "35%", backgroundColor: "#EAEAEA", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                    <img src={manageAccess} style={{ margin: "8%" }} />
                </div>
                <div style={{ width: "65%", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", top: '10px', right: "10px", cursor: "pointer" }} onClick={() => {
                        onClose()
                    }}>
                        <img src={Close} alt="closeButton" style={{ width: "20px" }} />
                    </div>
                    <div className="services-content-title"
                        style={{
                            textAlign: "left",
                            margin: "10% 0 20px 10%",
                            fontWeight: "500",
                            color: "#3C3C3C",
                        }}
                    >
                        Manage Access
                    </div>
                    <form style={{ textAlign: "left" }}>
                        <input
                            onInput={(e) => {
                                searchTeamMembers(e.target.value)
                            }}
                            value={null}
                            name="searchUser"
                            className="add-user-form-text"
                            style={{ width: "80%", marginLeft: "10%", paddingRight: "50px" }}
                            placeholder="Search User"
                        />
                        <img src={SearchIcon} style={{ width: "15px", marginLeft: "-25px" }} />
                    </form>
                    <div className="accessList" style={{ margin: "25px", height: "100%", overflowY: "auto" }}>
                        {loading ?
                            <LoaderComponent height={"60%"} /> :
                            <>
                                <TeamMemebersContainer
                                    ContainerTitle={"Accessed Members"}
                                    NoMembersMsg={"The selected property does not have any accessed memebers."}
                                    teamMembers={searchHasAccessedMembers}
                                    onRemoveAccess={(accessedProperty) => {
                                        handleOnAccessRemove(accessedProperty)
                                    }}
                                    selectedProperty={selectedProperty}
                                />
                                <TeamMemebersContainer
                                    ContainerTitle={"No Accessed Members"}
                                    NoMembersMsg={"The selected property does not have any no accessed memebers."}
                                    teamMembers={searchHasNoAccessedMembers}
                                    onAddAccess={(accessedProperty) => {
                                        handleOnAccessAdd(accessedProperty)
                                    }}
                                    selectedProperty={selectedProperty}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </AnimatedModal>
    )
}

export default PropertyAccessPopup