import React from "react";
import * as turf from '@turf/turf'
import * as _ from "underscore";
import ImageCanvas from '../ImageCanvas'
import { SamModel } from "./SamPredictor";
// import { SAM } from '@antv/sam';
import { v4 as uuid } from 'uuid';
import AppConstants from "../../../AppConstants";
import { calcDistBetweenTwoPoints } from "../../../ReusableComponents/reusableFunctions";
export const POINTER = "pointer"
export const BOX = 'box'
export const DRAG = "drag"
export const POLYGON = "polygon"
export const POINT = "point"
export const LINE = "line"
export const CIRCLE = "circle"
export const AUTOSEGMENTATIONTOOL = "autosegmentationtool"
const EDITINGCOLOR = "#2989CF"
const COMPLETECOLOR = "#ff0000"
export default class Annotation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgWidth: props.imgHeight,
            imgHeight: props.imgHeight,
            activeRegion: undefined,
            annotatorTool: this.props.autoSegmentationToolActive ? AUTOSEGMENTATIONTOOL : this.props.circleToolActive ? CIRCLE : this.props.polygonToolActive ? POLYGON : this.props.lineToolActive ? LINE : this.props.pointToolActive ? POINT : (this.props.disabledUpdate || this.props.panning) ? DRAG : BOX,
            lastSelectedClass: this.props?.classList?.[0],
            drawing: false,
            rectangle_xy: null,
            polygon_xy: null,
            drawingRegion: null,
            currTempCalculated: true,
        }

        if (!this.props.disabledUpdate) this.props.setActiveClass(this.props.checkForWindMill ? undefined : this.props?.classList?.[0])
    }
    onPressEnter = () => {
        if (this.currentMousePosition && this.state.annotatorTool !== POLYGON) {
            this.onMouseUp(this.currentMousePosition)
        }
        if (this.currentMousePosition && this.state.annotatorTool == POLYGON) {
            this.savePolygon()
        }
    }
    onPressEsc = () => {
        if (this.state.annotatorTool !== CIRCLE) {
            this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, })
        }
        if (this.state.selectedRegion) {
            this.copyRegionDisable()
        }
        if (this.state.selectedRegion && this.state.annotatorTool == DRAG) {
            this.props.changeTool(POINTER)
        }

    }
    onPress_CTRL_C = () => {
        if (this.state.selectedRegion) {
            this.onCopyRegion(this.state.selectedRegion)
        }
    }
    onPressDelete = () => {
        if (this.state.selectedRegion) {
            this.onDeleteRegion(this.state.selectedRegion.id)
        }
    }
    onKeyPressDown = (event) => {
        event.preventDefault();
        const { key } = event
        if (event.ctrlKey && event.key === 'c') {
            this.onPress_CTRL_C()
        }
        if (event.ctrlKey && event.key === 'z') {
        }
        if (event.ctrlKey && event.key === 'y') {
        }
        switch (key) {
            case 'Enter':
                this.onPressEnter()
                break;
            case 'Escape':
                this.onPressEsc()
                break;
            case 'Delete':
                this.onPressDelete()
                break;
        }
    }
    onKeyPressUp = (event) => {
        // event.preventDefault();
        const { key } = event
        switch (key) {
            case 'Escape':
                break;
        }
    }
    addButtonListeners = () => {
        const container = document.getElementById(this.props.eventsContainer)
        if (container) {
            container.focus()
            container.addEventListener('keydown', this.onKeyPressDown);
            container.addEventListener('keyup', this.onKeyPressUp);
        }
    }

    removeButtonListeners = () => {
        const container = document.getElementById(this.props.eventsContainer)
        if (container) {
            container.removeEventListener('keyup', this.onKeyPressUp)
        }
    }

    destroy = () => {
        this.removeButtonListeners()
    }
    componentDidMount() {
        this.setState({ regions: this.props.regions })
        window.addEventListener('contextmenu', this.onRightClick)
        this.addButtonListeners();

    }

    componentWillUnmount() {
        window.removeEventListener('contextmenu', this.onRightClick)
        this.destroy()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageSrc !== this.props.imageSrc) {
            this.setState({ activeRegion: undefined, imageLoaded: false })
            if (this.state.model && this.state.annotatorTool === AUTOSEGMENTATIONTOOL) {
                this.props.setActivityPopup({
                    showProcessPopup: true,
                    processPopupIcon: "WAIT",
                    processMessage: "Please wait, generating image embeddings..."
                }
                )
                this.state.model.changeImage(this.props.imageSrc).then(res => {
                    this.setState({ imageMask: null })
                    this.props.setActivityPopup({
                        showProcessPopup: false,
                        processPopupIcon: "",
                        processMessage: ""
                    })
                }).catch(err => {
                    this.setState({ imageMask: null })
                    this.props.setActivityPopup({
                        showProcessPopup: true,
                        processPopupIcon: "",
                        processMessage: err || "Something went wrong while getting image embeddings!"
                    })
                    this.props.changeTool(POINTER)
                })
            }
        }
        if (prevProps.classList.length !== this.props.classList.length) {
            this.setState({ lastSelectedClass: this.props.classList[this.props.classList.length - 1] })
        }

        if (this.props.disabledUpdate !== prevProps.disabledUpdate || this.props.panning !== prevProps.panning || this.props.lineToolActive !== prevProps.lineToolActive || this.props.pointToolActive !== prevProps.pointToolActive || this.props.polygonToolActive !== prevProps.polygonToolActive || this.props.circleToolActive !== prevProps.circleToolActive || this.props.autoSegmentationToolActive !== prevProps.autoSegmentationToolActive) {
            this.setState({
                annotatorTool: this.props.autoSegmentationToolActive ? AUTOSEGMENTATIONTOOL : this.props.circleToolActive ? CIRCLE : this.props.polygonToolActive ? POLYGON : this.props.lineToolActive ? LINE : this.props.pointToolActive ? POINT : (this.props.disabledUpdate || this.props.panning) ? DRAG : BOX
            },
                () => {
                    this.setState({ imageMask: null })
                    if (this.state.annotatorTool === BOX || this.state.annotatorTool === POLYGON || this.state.annotatorTool === CIRCLE) this.props.setActiveClass(this.state.lastSelectedClass);
                    else if (this.state.annotatorTool === AUTOSEGMENTATIONTOOL) {
                        if (this.state.model?.imageData?.imageUrl !== this.props.imageSrc) {
                            this.props.setActivityPopup({
                                showProcessPopup: true,
                                processPopupIcon: "WAIT",
                                processMessage: "Please wait, generating image embeddings..."
                            })
                            let model = this.state.model;
                            if (!model) {
                                model = new SamModel("/sam_onnx_quantized_example.onnx")
                            }
                            this.setState({ model }, async () => {
                                try {

                                    if (this.props.imageSrc) {

                                        await this.state.model.initImageEmbeddings(this.props.imageSrc)
                                        this.props.setActivityPopup({
                                            showProcessPopup: false,
                                            processPopupIcon: "",
                                            processMessage: ""
                                        })
                                    }
                                } catch (err) {
                                    this.props.setActivityPopup({
                                        showProcessPopup: true,
                                        processPopupIcon: "",
                                        processMessage: err || "Something went wrong while getting image embeddings!"
                                    })
                                    this.props.changeTool(POINTER)
                                }
                            })
                        }
                    }
                    else this.props.setActiveClass(null)

                })
        }


    }

    //update region vertex
    mouseDownOnVertex = (region, vertex) => {
        this.props.removeRegion(region.id)
        this.setState({
            activeUpdateVertex: {
                region, vertex
            },
            selectedCursor: region.type === CIRCLE ? "e-resize" : undefined
        })

    }


    deSelectRegion = () => {
        if (this.state.activeRegion?.type === POLYGON) this.savePolygon()
        else {

            this.setState({ activeRegion: undefined, rectangle_xy: null, drawing: false, selectedRegion: undefined })
        }
        if (this.state.annotatorTool === DRAG) this.props.setActiveClass(null);
        else this.props.setActiveClass(this.state.lastSelectedClass);
    }

    selectRegion = (region) => {
        this.props.onClickRegion(region)
        this.props.setActiveClass(region.className)
        this.setState({
            selectedRegion: region,
            lastSelectedClass: region.className
        })
    }

    getLineCoordinates = (e) => {
        this.setState({ lineStartPoint: { ...this.state.lineStartPoint } })
        return { x1: this.state.lineStartPoint.x, y1: this.state.lineStartPoint.y, x2: e.x > 1 ? 1 : e.x < 0 ? 0 : e.x, y2: e.y > 1 ? 1 : e.y < 0 ? 0 : e.y }
    }
    // getPointCoordinates = (e) => {
    //     this.setState({ point_xy: { ...this.state.point_xy } })
    //     return { x: e.x, y: e.y }
    // }
    getBoxCoords = (e) => {
        if (this.state.rectangle_xy.x < e.x && this.state.rectangle_xy.y < e.y) {
            this.setState({
                rectangle_xy: {
                    ...this.state.rectangle_xy,
                    startPoint: 1
                }
            })
            return { ...this.state.rectangle_xy, h: this.state.rectangle_xy.isFixHW ? 0.028436018957345931 : (e.y > 1 ? 1 : e.y) - this.state.rectangle_xy.y, w: this.state.rectangle_xy.isFixHW ? 0.0748492348287421 : this.state.rectangle_xy.isFixHW ? 0.0748492348287421 : (e.x > 1 ? 1 : e.x) - this.state.rectangle_xy.x }; //from drag left top to right bottom
        } else if (this.state.rectangle_xy.x > e.x && this.state.rectangle_xy.y > e.y) {
            this.setState({
                rectangle_xy: {
                    ...this.state.rectangle_xy,
                    startPoint: 4
                }
            })
            return { x: (e.x < 0 ? 0 : e.x), y: (e.y < 0 ? 0 : e.y), h: this.state.rectangle_xy.isFixHW ? 0.028436018957345931 : this.state.rectangle_xy.y - (e.y < 0 ? 0 : e.y), w: this.state.rectangle_xy.isFixHW ? 0.0748492348287421 : this.state.rectangle_xy.x - (e.x < 0 ? 0 : e.x) }; //from drag right bottom to left top
        } else if (this.state.rectangle_xy.x < e.x && this.state.rectangle_xy.y > e.y) {
            this.setState({
                rectangle_xy: {
                    ...this.state.rectangle_xy,
                    startPoint: 2
                }
            })
            return { x: this.state.rectangle_xy.x, y: (e.y < 0 ? 0 : e.y), h: this.state.rectangle_xy.isFixHW ? 0.028436018957345931 : this.state.rectangle_xy.y - (e.y < 0 ? 0 : e.y), w: this.state.rectangle_xy.isFixHW ? 0.0748492348287421 : (e.x > 1 ? 1 : e.x) - this.state.rectangle_xy.x } //from drag left bottom to right top
        } else if (this.state.rectangle_xy.x > e.x && this.state.rectangle_xy.y < e.y) {
            this.setState({
                rectangle_xy: {
                    ...this.state.rectangle_xy,
                    startPoint: 3
                }
            })
            return { x: (e.x < 0 ? 0 : e.x), y: this.state.rectangle_xy.y, h: this.state.rectangle_xy.isFixHW ? 0.028436018957345931 : (e.y > 1 ? 1 : e.y) - this.state.rectangle_xy.y, w: this.state.rectangle_xy.isFixHW ? 0.0748492348287421 : this.state.rectangle_xy.x - (e.x < 0 ? 0 : e.x) } //from drag right top to left bottom
        } else {
            return { x: e.x, y: e.y }
        }

    }

    drawBox = (e, init, drag, complete, point) => {
        if (this.state.rectangle_xy) {
            if (drag) {
                this.setState({
                    activeRegion: {
                        ...this.getBoxCoords(e, drag),
                        "type": BOX,
                        "highlighted": false,
                        "editingLabels": false,
                        "color": this.props.isThermalImage ? AppConstants.THERMAL.COLOR.BOX : this.props.classesWithColor ? this.props.classesWithColor.find(cls => cls.name === this.state.lastSelectedClass)?.color : AppConstants.COLORS.ANNOTATIONCOLOR,
                        "id": this.state.rectangle_xy.id,
                        "calculated": true,
                    },
                });
            }

            if (complete) {
                if (!((e.x === this.state.rectangle_xy.x && e.y === this.state.rectangle_xy.y) || (e.x - this.state.rectangle_xy.x === 0) || (e.y - this.state.rectangle_xy.y === 0))) {
                    let repeatedRegion = false
                    let co_ordinates = this.getBoxCoords(e)
                    this.props.regions.map((r) => {
                        if (r.x == co_ordinates.x && r.y == co_ordinates.y)
                            repeatedRegion = true
                    })
                    if (!repeatedRegion) {
                        this.setState({
                            activeRegion: {
                                ...this.state.activeRegion, name: `Rect ${(parseInt(this.props.regions.filter(reg => reg.type === BOX).sort((a, b) => {
                                    var numA = parseInt(a?.name?.match(/\d+$/)?.[0]);
                                    var numB = parseInt(b?.name?.match(/\d+$/)?.[0]);
                                    return numB - numA;
                                })[0]?.name?.split(" ")[1]) || 0) + 1}`,
                            },
                            newAdded: true,
                            regions: [...this.props.regions, {
                                ...co_ordinates,
                                ...this.state.activeRegion,
                                className: this.state.lastSelectedClass,

                            }]
                        }, () => {
                            this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, })
                            this.sendRegionToParent();
                        })
                    } else {
                        this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, })
                    }
                } else {
                    this.setState({ activeRegion: undefined, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, })
                }
            }

        }
    }
    drawPoint = (e, init, drag, complete) => {
        if (complete && e.x >= 0.001 && e.x <= 0.999 && e.y >= 0.001 && e.y <= 0.999) {
            this.setState({
                activeRegion: {
                    x: e.x,
                    y: e.y,
                    "type": POINT,
                    "highlighted": false,
                    "editingLabels": false,
                    "color": AppConstants.THERMAL.COLOR.POINT,
                    "id": uuid(),
                },
            }, () => {
                let co_ordinates = e
                this.setState({
                    activeRegion: {
                        ...this.state.activeRegion, name: `Point ${(parseInt(this.props.regions.filter(reg => reg.type === POINT).sort((a, b) => {
                            var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                            var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                            return numB - numA;
                        })[0]?.name?.split(" ")[1]) || 0) + 1}`
                    }
                }, () => {
                    this.sendRegionToParent();
                })
                this.setState({
                    // point_xy: { ...this.state.point_xy, ...this.state.activeRegion },
                    newAdded: true,
                    regions: [...this.props.regions, {
                        ...this.state.activeRegion,
                        ...co_ordinates,
                        className: this.state.lastSelectedClass,
                    }]
                }, () => {
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null })
                })
            })
        }
    }
    drawLine = (e, init, drag, complete) => {
        if (this.state.lineStartPoint) {
            if (drag) {
                this.setState({
                    activeRegion: {
                        ...this.getLineCoordinates(e),
                        "type": LINE,
                        "highlighted": false,
                        "editingLabels": false,
                        "color": AppConstants.THERMAL.COLOR.LINE,
                        "id": this.state.lineStartPoint.id,
                        "calculated": true,
                    },
                });
            }

            if (complete && this.getLineCoordinates(e)?.x1) {
                let co_ordinates = this.getLineCoordinates(e)
                co_ordinates = { ...co_ordinates, x1: co_ordinates.x1 >= 1 ? 0.999 : co_ordinates.x1 <= 0 ? 0.001 : co_ordinates.x1, y1: co_ordinates.y1 >= 1 ? 0.999 : co_ordinates.y1 <= 0 ? 0.001 : co_ordinates.y1, x2: co_ordinates.x2 >= 1 ? 0.999 : co_ordinates.x2 <= 0 ? 0.001 : co_ordinates.x2, y2: co_ordinates.y2 >= 1 ? 0.999 : co_ordinates.y2 <= 0 ? 0.001 : co_ordinates.y2 }
                if (this.state.activeRegion) {
                    this.setState({
                        activeRegion: {
                            ...this.state.activeRegion, name: `Line ${(parseInt(this.props.regions.filter(reg => reg.type === LINE).sort((a, b) => {
                                var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                                var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                                return numB - numA;
                            })[0]?.name?.split(" ")[1]) || 0) + 1}`
                            , x1: this.state.activeRegion?.x1 >= 1 ? 0.999 : this.state.activeRegion?.x1 <= 0 ? 0.001 : this.state.activeRegion?.x1, y1: this.state.activeRegion?.y1 >= 1 ? 0.999 : this.state.activeRegion?.y1 <= 0 ? 0.001 : this.state.activeRegion?.y1, x2: this.state.activeRegion?.x2 >= 1 ? 0.999 : this.state.activeRegion?.x2 <= 0 ? 0.001 : this.state.activeRegion?.x2, y2: this.state.activeRegion?.y2 >= 1 ? 0.999 : this.state.activeRegion?.y2 <= 0 ? 0.001 : this.state.activeRegion?.y2
                        }
                    }, () => {
                        this.sendRegionToParent();
                    })
                    this.setState({
                        newAdded: true,
                        regions: [...this.props.regions, {
                            ...this.state.activeRegion,
                            ...co_ordinates,
                            className: this.state.lastSelectedClass,

                        }]
                    }, () => {
                        this.setState({ activeRegion: undefined, newAdded: false, lineStartPoint: null, rectangle_xy: null, point_xy: null, drawing: false, })
                    })
                }

            }

        }
    }


    savePolygon = () => {
        if (this.state.activeRegion && this.state.activeRegion.type === POLYGON && this.state.activeRegion.points.length > 3) {
            if (this.checkPolygonLineIntersects([...this.state.activeRegion.points, this.state.activeRegion.points[0]])) {

                this.props.setError("You have created an invalid polygon. (Due to line intersection.)", "Error", true)
                this.setState({ activeRegion: undefined, newAdded: false, drawingRegion: undefined, selectedRegion: undefined, polygon_xy: undefined })
            } else
                this.setState({
                    activeRegion: {
                        ...this.state.activeRegion, name: `Polygon ${(parseInt(this.props.regions.filter(reg => reg.type === POLYGON).sort((a, b) => {
                            var numA = parseInt(a?.name?.match(/\d+$/)?.[0]);
                            var numB = parseInt(b?.name?.match(/\d+$/)?.[0]);
                            return numB - numA;
                        })[0]?.name?.split(" ")[1]) || 0) + 1}`,
                    }
                }, () => {

                    this.setState({
                        newAdded: true,
                        regions: [...this.props.regions, { ...this.state.activeRegion, open: false }]
                    }, () => {
                        this.sendRegionToParent()
                        this.setState({ activeRegion: undefined, newAdded: false, drawingRegion: undefined, selectedRegion: undefined, polygon_xy: undefined })
                    })
                })
        } else {
            this.setState({ activeRegion: undefined, newAdded: false, drawingRegion: undefined, selectedRegion: undefined, polygon_xy: undefined })
        }
    }
    checkPolygonLineIntersects = (polygonCoordinates) => {
        const poly = turf.polygon([polygonCoordinates])

        var kinks = turf.kinks(poly);
        return kinks.features.length > 0
    }

    drawPolygon = (e, init, drag, complete) => {
        if (this.state.polygon_xy) {
            if (this.state.activeRegion) {
                this.setState({
                    activeRegion: {
                        ...this.state.activeRegion,
                        points: [
                            ...this.state.activeRegion.points, [e.x, e.y]
                        ],
                        open: this.state.activeRegion.points.length > 2 ? false : true, id: this.state.polygon_xy.id
                    },
                    rectangle_xy: null,
                    circle_xy: null
                }, () => {

                })

            } else {
                this.setState({
                    activeRegion: {
                        type: POLYGON,
                        className: this.state.lastSelectedClass,
                        points: [
                            [e.x, e.y]
                        ],
                        open: true,
                        highlighted: false,
                        color: this.props.classesWithColor ? this.props.classesWithColor.find(cls => cls.name === this.state.lastSelectedClass)?.color : AppConstants.COLORS.ANNOTATIONCOLOR,
                        id: this.state.polygon_xy.id,
                        editingLabels: false
                    },
                    rectangle_xy: null,
                    circle_xy: null
                })
            }
        }
    }

    isCircleInsideImage = (circle) => circle.radius + circle.x <= 1 && circle.y - circle.radius >= 0 &&
        circle.x - circle.radius >= 0 && circle.y + circle.radius <= 1

    getMaxRadiusRespectiveToImageWhileUpdating = (region, e) => {
        const r = calcDistBetweenTwoPoints(region.x, region.y, e.x, region.y)
        if (this.isCircleInsideImage({ ...region, radius: r })) return r
        else return region.radius
    }

    drawCircle = (e, init, dragRadius, complete) => {
        if (this.state.circle_xy) {
            if (this.state.activeRegion) {
                this.setState({
                    activeRegion: {
                        ...this.state.activeRegion,
                        radius: this.getMaxRadiusRespectiveToImageWhileUpdating(this.state.activeRegion, e)
                    },
                })

            } else {
                this.setState({
                    activeRegion: {
                        type: CIRCLE,
                        className: this.state.lastSelectedClass,
                        x: e.x,
                        y: e.y,
                        radius: 0,
                        open: true,
                        highlighted: false,
                        color: this.props.classesWithColor ? this.props.classesWithColor.find(cls => cls.name === this.state.lastSelectedClass)?.color : AppConstants.COLORS.ANNOTATIONCOLOR,
                        id: this.state.circle_xy.id,
                        editingLabels: false
                    },
                    selectedCursor: "e-resize",
                    rectangle_xy: null,
                    polygon_xy: null
                })
            }
        }
        if (complete && this.state.activeRegion) {
            if (this.state.activeRegion.radius > 0) this.setState({
                newAdded: true,
                regions: [...this.props.regions, {
                    ...this.state.activeRegion,
                    className: this.state.lastSelectedClass,
                    name: `Circle ${(parseInt(this.props.regions.filter(reg => reg.type === CIRCLE).sort((a, b) => {
                        var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                        var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name?.split(" ")[1]) || 0) + 1}`

                }]
            }, () => {
                this.setState({ activeRegion: undefined, newAdded: false, circle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, selectedCursor: undefined })
                this.sendRegionToParent();
            })
            else this.setState({ activeRegion: undefined, newAdded: false, circle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, selectedCursor: undefined })
        }
    }


    sendRegionToParent = () => {
        if (this.props.checkForWindMill) this.props.regionAdded({ ...this.state.activeRegion, className: undefined })
        else {

            if (this.props.regionToCopy) this.copyRegionDisable();
            this.props.regionAdded({
                ...this.state.activeRegion, className: this.state.lastSelectedClass, calculated: false, disableTemp: this.props.disableTemp
            })
        }
        this.setState({ maskImg: null })

    }
    setDrawingRegion = (cls) => {

        if (this.state.annotatorTool === BOX) this.props.setActiveClass(cls);
        if (this.state.annotatorTool === POLYGON) this.props.setActiveClass(cls);
        if (this.state.annotatorTool === LINE) this.props.setActiveClass(cls);
        if (this.state.annotatorTool === POINT) this.props.setActiveClass(cls);
        if (this.state.annotatorTool === CIRCLE) this.props.setActiveClass(cls);
        this.setState({ lastSelectedClass: cls })
    }

    flushCopyingLayer = () => {
        this.setState({ drawing: false, rectangle_xy: null, polygon_xy: null, point_xy: null, lineStartPoint: null })
    }

    onRightClick = () => {
        // if (!this.props.disabledUpdate) {
        //     if (this.props.regionToCopy) this.copyRegionDisable();
        //     else {
        //         this.props.togglePanning()
        //     }
        // }


        this.savePolygon()


        // if (this.state.annotatorTool === BOX) this.changeTool(DRAG);
        // else this.changeTool(BOX)
    }

    onMouseDown = (e) => {
        // if (e.x >= 0 && e.y >= 0 && e.x <= 1 && e.y <= 1 && this.state.annotatorTool === DRAG)
        //     this.setState({ rectangle_xy: e })
        if (this.state.annotatorTool !== POINTER && this.state.annotatorTool !== DRAG && this.state.lastSelectedClass) {
            if (this.state.annotatorTool === POLYGON) {

                this.setState({ polygon_xy: this.state.polygon_xy ? this.state.polygon_xy : { ...e, id: uuid(), } }, () => {
                    this.drawPolygon(e);
                })
            }
            if (this.state.annotatorTool === CIRCLE) {
                this.setState({ circle_xy: this.state.circle_xy ? this.state.circle_xy : { ...e, id: uuid(), } }, () => {
                    this.drawCircle(e);
                })
            }
        }
    }

    getCircleRegionWhileVertexDrawing = ({ vertex, region }, mouse) => {
        return {
            ...region,
            radius: this.getMaxRadiusRespectiveToImageWhileUpdating(region, mouse)
        }
    }
    getPolygonRegionWhileVertexDrawing = ({ vertex, region }, mouse) => {
        return {
            ...region, points: region.points.map((point, i) => {
                if (i === vertex) {
                    if (!this.state.tempPolygonPointBeforeIntersect) {
                        this.setState({ tempPolygonPointBeforeIntersect: point })
                    }
                    return [mouse.x, mouse.y]
                }
                else return point
            })
        }
    }
    getBoxRegionWhileVertexDrawing = ({ vertex, region }, mouse) => {
        if (vertex === 0) {
            let h = mouse.y < region.y ? (region.y - mouse.y) + region.h : region.h - (mouse.y - region.y)
            let w = mouse.x < region.x ? (region.x - mouse.x) + region.w : region.w - (mouse.x - region.x)
            return {
                ...region,
                x: w > 0 ? mouse.x : region.x,
                y: h > 0 ? mouse.y : region.y,
                h: h > 0 ? h : region.h,
                w: w > 0 ? w : region.w
            }
        }
        if (vertex === 1) {
            const h = mouse.y > region.y ? region.h - (mouse.y - region.y) : region.h + (region.y - mouse.y)
            const w = mouse.x - region.x
            return {
                ...region,
                x: region.x,
                y: h > 0 ? mouse.y : region.y,
                h: h > 0 ? h : region.h,
                w: w > 0 ? w : region.w
            }
        }

        if (vertex === 2) {
            return {
                ...region,
                h: mouse.y < 1 ? (mouse.y - region.y > 0 ? mouse.y - region.y : region.h) : region.h,
                w: mouse.x < 1 ? (mouse.x - region.x > 0 ? mouse.x - region.x : region.w) : region.w
            }
        }

        if (vertex === 3) {
            const h = mouse.y - region.y
            const w = (region.x + region.w) - mouse.x
            return {
                ...region,
                x: mouse.x,
                y: region.y,
                h: h > 0 ? h : region.h,
                w: w > 0 ? w : region.w
            }
        }
    }
    getLineRegionWhileVertexDrawing = ({ vertex, region }, mouse) => {
        if (vertex == 0) {
            return { ...region, x1: mouse.x >= 1 ? 0.99 : mouse.x <= 0 ? 0.01 : mouse.x, y1: mouse.y >= 1 ? 0.99 : mouse.y <= 0 ? 0.01 : mouse.y }
        }
        else if (vertex == 1) {
            return { ...region, x2: mouse.x >= 1 ? 0.99 : mouse.x <= 0 ? 0.01 : mouse.x, y2: mouse.y >= 1 ? 0.99 : mouse.y <= 0 ? 0.01 : mouse.y }
        }
    }
    getPointRegionWhileVertexDrawing = ({ vertex, region }, mouse) => {
        return { ...region, x: mouse.x >= 1 ? 1 : mouse.x <= 0 ? 0 : mouse.x, y: mouse.y >= 1 ? 1 : mouse.y <= 0 ? 0 : mouse.y }
    }
    setRegionEditable = (id) => {
        this.props.setRegionEditable(id)
    }
    onClickRegionAddPoint = (region, midVertex) => {
        region.points.splice(midVertex.id + 1, 0, [midVertex.x, midVertex.y])
        this.setRegionEditable(region.id)


    }

    mouseDownOnPolygon = (region) => {
        this.props.removeRegion(region.id)
        if (region.type === POLYGON) this.setState({
            mouseDownOnPolygon: region
        })
        if (region.type === CIRCLE) this.setState({
            mouseDownOnCircle: region
        })
    }

    mouseDownOnBox = (region) => {
        this.props.removeRegion(region.id)
        this.setState({
            mouseDownOnBox: region
        })

    }
    mouseDownOnLine = (region) => {
        this.props.removeRegion(region.id)
        this.setState({
            mouseDownOnLine: { ...this.mouseDownOnLine, ...region, w: region.x2 - region.x1, h: region.y2 - region.y1 }
        }, () => {
        })

    }
    mouseDownOnPoint = (region) => {
        this.props.removeRegion(region.id)
        this.setState({
            mouseDownOnPoint: { ...this.mouseDownOnLine, ...region }
        }, () => {
        })
    }
    onMouseUpWhileDraggingPolygon = (e) => {
        if (this.state.mouseDownOnPolygon) {
            this.props.regionAdded(this.state.mouseDownOnPolygon)
            this.setState({
                mouseDownOnPolygon: undefined
            })
        }
    }
    onMouseUpWhileDraggingBox = (e) => {
        if (this.state.mouseDownOnBox) {
            this.props.regionAdded(this.state.mouseDownOnBox, false, false, true)
            this.setState({
                mouseDownOnBox: undefined
            })
        }
    }
    onMouseUpWhileDraggingLine = (e) => {
        if (this.state.mouseDownOnLine) {
            this.props.regionAdded(this.state.mouseDownOnLine, false, false, true)
            this.setState({
                mouseDownOnLine: undefined
            })
        }
    }
    onMouseUpWhileDraggingPoint = (e) => {

        if (this.state.mouseDownOnPoint) {
            this.props.regionAdded(this.state.mouseDownOnPoint, false, false, true)
            this.setState({
                mouseDownOnPoint: undefined
            })
        }
    }
    onMouseUpWhileDraggingCircle = (e) => {
        if (this.state.mouseDownOnCircle) {
            this.props.regionAdded(this.state.mouseDownOnCircle, false, false, true)
            this.setState({
                mouseDownOnCircle: undefined
            })
        }
    }

    dragCircle = (mouse) => {
        if (this.state.selectedRegion && this.state.mouseDownOnCircle
            && this.isCircleInsideImage({
                ...this.state.mouseDownOnCircle,
                x: mouse.x,
                y: mouse.y
            })) {
            this.setState({
                mouseDownOnCircle: {
                    ...this.state.mouseDownOnCircle,
                    x: mouse.x,
                    y: mouse.y
                }
            })
        }
    }

    dragPolygon = async (mouse) => {
        let outOfBounds = false;
        if (this.state.selectedRegion && this.state.mouseDownOnPolygon) {
            let sumX = 0;
            let sumY = 0;
            for (const vertex of this.state.mouseDownOnPolygon.points) {
                sumX += vertex[0];
                sumY += vertex[1]
            }
            const n = this.state.mouseDownOnPolygon.points.length
            const centerX = sumX / n;
            const centerY = sumY / n;
            let offsetX = mouse.x - centerX;
            let offsetY = mouse.y - centerY;
            let newPoints = await Promise.all(this.state.mouseDownOnPolygon.points.map(vertex => {
                return new Promise((resolve, reject) => {
                    let newX = vertex[0] + offsetX
                    let newY = vertex[1] + offsetY
                    if (newX >= 1 || newX <= 0 || newY >= 1 || newY <= 0) {
                        outOfBounds = true;
                        reject();
                    }
                    resolve([newX, newY])
                })
            })).catch(e => {
                outOfBounds = true;
                this.setState({
                    mouseDownOnPolygon: this.state.mouseDownOnPolygon,
                    selectedCursor: "move"
                })
            })
            if (!outOfBounds) {
                this.setState({
                    mouseDownOnPolygon: {
                        ...this.state.mouseDownOnPolygon, points: newPoints
                    },
                    selectedCursor: "move"
                })
            }
        }
    }
    dragBox = (mouse) => {
        if (this.state.selectedRegion && this.state.mouseDownOnBox) {
            let midXCalc = mouse.x - (this.state.mouseDownOnBox.w / 2)
            let midYCalc = mouse.y - (this.state.mouseDownOnBox.h / 2)
            this.setState({
                mouseDownOnBox: {
                    ...this.state.mouseDownOnBox,
                    x: midXCalc + this.state.mouseDownOnBox.w >= 1 ? 1 - this.state.mouseDownOnBox.w : (midXCalc <= 0 ? 0 : midXCalc),
                    y: midYCalc + this.state.mouseDownOnBox.h >= 1 ? 1 - this.state.mouseDownOnBox.h : (midYCalc <= 0 ? 0 : midYCalc)
                    , calculated: false,
                },
                selectedCursor: "move"
            })
        }
    }
    dragLine = (mouse) => {
        if (this.state.selectedRegion && this.state.mouseDownOnLine) {
            let midx1 = mouse.x - (this.state.mouseDownOnLine.w / 2)
            let midy1 = mouse.y - (this.state.mouseDownOnLine.h / 2)
            let midx2 = mouse.x + (this.state.mouseDownOnLine.w / 2)
            let midy2 = mouse.y + (this.state.mouseDownOnLine.h / 2)
            this.setState({
                mouseDownOnLine: (midx1 >= 1 || midx1 <= 0 || midx2 >= 1 || midx2 <= 0 || midy1 >= 1 || midy1 <= 0 || midy2 >= 1 || midy2 <= 0) ? this.state.mouseDownOnLine : {

                    ...this.state.mouseDownOnLine,
                    x1: midx1,
                    y1: midy1,
                    x2: midx2,
                    y2: midy2,
                    calculated: false,
                },
                selectedCursor: "move"
            })
        }
    }
    dragPoint = (mouse) => {
        if (this.state.selectedRegion && this.state.mouseDownOnPoint) {

            this.setState({
                mouseDownOnPoint: (mouse.x >= 1 || mouse.x <= 0 || mouse.y >= 1 || mouse.y <= 0) ? this.state.mouseDownOnPoint : {
                    ...this.state.mouseDownOnPoint,
                    x: mouse.x,
                    y: mouse.y,
                    calculated: false,
                },
                selectedCursor: "move"
            })
        }
    }
    handleMouseMoveForMask = _.throttle(async (e) => {
        // Creates and returns a new, throttled version of the passed function, that, when invoked repeatedly, will only actually call the original function at most once per every wait milliseconds. Useful for rate-limiting events that occur faster than you can keep up with. By default, throttle will execute the function as soon as you call it for the first time, and, if you call it again any number of times during the wait period, as soon as that period is over. If you'd like to disable the leading-edge call, pass {leading: false}, and if you'd like to disable the execution on the trailing-edge, pass {trailing: false}.
        await this.state.model.getPrediction({
            x: Math.round((e.x * 100) * this.props.imgWidth / 100),
            y: Math.round((e.y * 100) * this.props.imgHeight / 100)
        }, false)
        const output = this.state.model.modelOutput
        if (output) {
            const samPredictedPoly = await this.state.model.drawPolygon(output)

            const activeRegion = {
                type: POLYGON,
                className: "",
                points: samPredictedPoly.map((point, i) => [point[0] / this.props.imgWidth, point[1] / this.props.imgHeight]),
                open: false,
                highlighted: false,
                color: "#FFFF00",
                id: uuid(),
                editingLabels: false
            }
            this.setState({
                imageMask: activeRegion,
            })
        }
    }, 15);
    onMouseMove = async (e) => {
        this.currentMousePosition = e
        if (this.state.annotatorTool === AUTOSEGMENTATIONTOOL && (e.x >= 0.001 && e.y >= 0.001 && e.x <= 0.999 && e.y <= 0.999)) {

            this.handleMouseMoveForMask(e)
        }
        if (this.state.annotatorTool !== POINTER && this.state.annotatorTool !== DRAG) {

            if (this.state.annotatorTool === BOX) {
                this.drawBox(e, false, true, false)
            } else if (this.state.annotatorTool === CIRCLE) {
                this.drawCircle(e, false, true, false)
            }
            else if (this.state.annotatorTool === LINE) {
                this.drawLine(e, false, true, false)
            }

        }
        if (this.state.activeUpdateVertex) { //this is when we edit box and move vertex
            let draggedRegion = this.state.activeUpdateVertex.region.type === CIRCLE ? this.getCircleRegionWhileVertexDrawing(this.state.activeUpdateVertex, e) : this.state.activeUpdateVertex.region.type === POLYGON ? this.getPolygonRegionWhileVertexDrawing(this.state.activeUpdateVertex, e) : this.state.activeUpdateVertex.region.type === BOX ? this.getBoxRegionWhileVertexDrawing(this.state.activeUpdateVertex, e) : this.state.activeUpdateVertex.region.type === LINE ? this.getLineRegionWhileVertexDrawing(this.state.activeUpdateVertex, e) : this.state.activeUpdateVertex.region.type === LINE ? this.getPointRegionWhileVertexDrawing(this.state.activeUpdateVertex, e) : undefined;
            this.setState({
                activeUpdateVertex: {
                    ...this.state.activeUpdateVertex,
                    region: { ...draggedRegion, calculated: false, showSeverity: false }
                }
            })
        }

        // for update existing 
        if (this.state.mouseDownOnBox) this.dragBox(e)
        if (this.state.mouseDownOnPolygon) this.dragPolygon(e)
        if (this.state.mouseDownOnLine) this.dragLine(e)
        if (this.state.mouseDownOnPoint) this.dragPoint(e)
        if (this.state.mouseDownOnCircle) this.dragCircle(e)
    }

    onMouseUp = (e) => {
        this.setState({
            selectedCursor: undefined
        })
        if (this.state.annotatorTool !== POINTER && this.state.annotatorTool !== DRAG) {

            if (this.state.annotatorTool === BOX) {
                this.drawBox(e, false, false, true)
            }
            if (this.state.annotatorTool === CIRCLE) {
                this.drawCircle(e, false, false, true);
            }
            if (this.state.annotatorTool === POINT && this.props.currTempCalculated) {
                this.drawPoint(e, false, false, true);
            }
            if (this.state.annotatorTool === LINE) {
                this.drawLine(e, false, false, true);
            }

        }
        if (this.state.activeUpdateVertex) {
            if (this.state.activeUpdateVertex.region.type === POLYGON) {
                let polygonPoints = []
                const points = this.state.activeUpdateVertex.region.points
                //    check if polygon already contains last point as first or not
                if (points[points.length - 1][0] === points[0][0] && points[points.length - 1][1] === points[0][1]) {
                    polygonPoints = points
                } else {
                    polygonPoints = [...this.state.activeUpdateVertex.region.points, this.state.activeUpdateVertex.region.points[0]]
                }
                if (this.checkPolygonLineIntersects(polygonPoints)) {
                    this.setState({
                        activeUpdateVertex: {
                            ...this.state.activeUpdateVertex, region: {
                                ...this.state.activeUpdateVertex.region, points: this.state.activeUpdateVertex.region.points.map((point, i) => {
                                    if (i === this.state.activeUpdateVertex.vertex) return this.state.tempPolygonPointBeforeIntersect
                                    else return point
                                })
                            }
                        }
                    }, () => {
                        this.props.regionAdded({ ...this.state.activeUpdateVertex.region, showSeverity: true })
                        this.setState({
                            activeUpdateVertex: undefined,
                            tempPolygonPointBeforeIntersect: undefined,
                        })
                    })
                } else {
                    this.props.regionAdded({ ...this.state.activeUpdateVertex.region, showSeverity: true })
                    this.setState({
                        activeUpdateVertex: undefined,
                        tempPolygonPointBeforeIntersect: undefined
                    })
                }
            } else {
                this.props.regionAdded({ ...this.state.activeUpdateVertex.region, showSeverity: true }, false, false, true)
                this.setState({
                    activeUpdateVertex: undefined
                })
            }
        }

        // while editing stop drag if enabled.
        this.onMouseUpWhileDraggingBox()
        this.onMouseUpWhileDraggingLine()
        this.onMouseUpWhileDraggingPoint()
        this.onMouseUpWhileDraggingPolygon()
        this.onMouseUpWhileDraggingCircle()


    }

    onDeleteRegion = (id) => {
        // if (!(this.state.activeRegion.id === id)) {
        this.props.onDeleteRegion(id)
        // }
        this.setState({ regions: this.props.regions.filter(item => item.id !== id), selectedRegion: undefined })
    }

    onClassUpdate = (id, data, saveData) => {
        // if (this.state.selectedRegion) {
        //     this.props.onClassUpdate(this.state.selectedRegion.id, data, saveData)
        //     this.setState({
        //         regions: this.props.regions.map(item => {
        //             if (item.id === this.state.selectedRegion.id) {
        //                 return { ...item, data: saveData ? item.data : data, showSeverity: saveData ? false : item.showSeverity }
        //             } else {
        //                 return item
        //             }
        //         }),
        //     })
        // }
        // else {
        // highlight all classes regions 
        //#TODO

        this.props.onClassUpdate(id, data, saveData)
        this.setState({
            regions: this.props.regions.map(item => {
                if (item.id === id) {
                    return { ...item, data: saveData ? item.data : data, showSeverity: saveData ? false : item.showSeverity }
                } else {
                    return item
                }
            }),
        }, () => {
            this.setState({ selectedRegion: this.state.regions.find(reg => reg.id === id) })
        })

        // }
    }

    onClickRegion = (data) => {
        // setting selected region and its active class
        if (data.id && this.props.onClickRegion && !this.props.regionToCopy && this.state.annotatorTool === DRAG) {
            this.selectRegion(data)
        }
    }
    // 8%
    // 65%
    // 138.5213px
    // 643px
    drawWindMillPoint = (e) => {
        this.props.regionAdded({
            ...e,
            h: 0.005,
            w: 0.01,
            className: "",
            data: {},
            "type": BOX,
            "highlighted": false,
            "editingLabels": false,
            "color": AppConstants.PICSTORK_WINDMILL_MARKER.COLOR
        })
    }

    //fired when clicked on the image or an annotation region
    onClickImage = async (data) => {

        if (this.state.model && this.state.annotatorTool === AUTOSEGMENTATIONTOOL && (data.x >= 0.001 && data.y >= 0.001 && data.x <= 0.999 && data.y <= 0.999)) {
            const output = this.state.model.modelOutput
            if (output) {
                const samPredictedPoly = await this.state.model.drawPolygon(output)

                const activeRegion = {
                    type: POLYGON,
                    className: this.state.lastSelectedClass,
                    points: samPredictedPoly.map((point, i) => [point[0] / this.props.imgWidth, point[1] / this.props.imgHeight]),
                    open: false,
                    highlighted: false,
                    color: this.props.classesWithColor ? this.props.classesWithColor.find(cls => cls.name === this.state.lastSelectedClass)?.color : AppConstants.COLORS.ANNOTATIONCOLOR,
                    id: uuid(),
                    editingLabels: false
                }
                this.setState({
                    newAdded: true,
                    activeRegion,
                    regions: [
                        ...this.props.regions,
                        activeRegion]
                }, () => {
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, drawing: false, lineStartPoint: null, point_xy: null, imageMask: undefined })
                    this.sendRegionToParent();
                })

            }

        }
        if (!this.props.currTempCalculated) return;
        if ((!this.props.regionToCopy || this.props.checkForWindMill) && this.state.annotatorTool !== POINTER && this.state.annotatorTool !== DRAG && (this.state.lastSelectedClass || this.props.checkForWindMill)) {
            if (data.x >= 0.001 && data.y >= 0.001 && data.x <= 0.999 && data.y <= 0.999) {
                this.setState({ drawing: !this.state.drawing }, () => {
                    data.id = uuid()
                    this.props.ponitDrawing ? this.drawWindMillPoint(data) : this.setState({ rectangle_xy: (this.state.annotatorTool === BOX) ? this.state.drawing ? data : null : null, lineStartPoint: this.state.annotatorTool === LINE ? this.state.drawing ? data : null : null, point_xy: this.state.annotatorTool === POINT ? this.state.drawing ? data : null : null }, () => {
                    })
                })
            }
        }
        if (!this.props.checkForWindMill) if (!this.state.lastSelectedClass) this.props.setError("Please add at least one class to annotate images.", "Add class")
        if (data && !data.id && data.x <= 0.999 && data.y <= 0.999 && data.x >= 0.001 && data.y > 0.001) {
            if (this.props.regionToCopy) {
                if (this.props.regionToCopy.type === POLYGON) {
                    let copy_region = { className: this.props.regionToCopy.className, color: this.props.regionToCopy.color, h: this.props.regionToCopy.h, id: uuid(), type: this.props.regionToCopy.type, points: this.props.regionToCopy.points }
                    let outOfBounds = false;
                    let sumX = 0;
                    let sumY = 0;
                    for (const vertex of this.props.regionToCopy.points) {
                        sumX += vertex[0];
                        sumY += vertex[1]
                    }
                    const n = this.props.regionToCopy.points.length
                    const centerX = sumX / n;
                    const centerY = sumY / n;
                    let offsetX = data.x - centerX;
                    let offsetY = data.y - centerY;
                    let newPoints = await Promise.all(this.props.regionToCopy.points.map(vertex => {
                        return new Promise((resolve, reject) => {
                            let newX = vertex[0] + offsetX
                            let newY = vertex[1] + offsetY
                            newX = newX >= 1 ? 1 : newX <= 0 ? 0 : newX
                            newY = newY >= 1 ? 1 : newY <= 0 ? 0 : newY
                            resolve([newX, newY])
                        })
                    })).catch(e => {
                    })
                    copy_region.points = newPoints
                    copy_region.color = this.props.isThermalImage ? (this.props.regionToCopy.type === POINT ? AppConstants.THERMAL.COLOR.POINT : this.props.regionToCopy.type === BOX ? AppConstants.THERMAL.COLOR.BOX : this.props.regionToCopy.type === LINE ? AppConstants.THERMAL.COLOR.LINE : "white") : copy_region.color
                    let repeatedRegion = false

                    if (!repeatedRegion) {
                        this.props.regionAdded(copy_region, true)
                        this.setState({
                            newAdded: true,
                            regions: [...this.props.regions, copy_region]
                        }, () => {
                            this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                        })
                    }
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                }
                else if (this.props.regionToCopy.type === BOX) {

                    let copy_region = { className: this.props.regionToCopy.className, color: this.props.regionToCopy.color, h: this.props.regionToCopy.h, id: uuid(), type: this.props.regionToCopy.type, w: this.props.regionToCopy.w, x: data.x - (this.props.regionToCopy.w / 2), y: data.y - (this.props.regionToCopy.h / 2) }
                    copy_region.x = copy_region.x >= 0 ? copy_region.x : 0
                    copy_region.y = copy_region.y >= 0 ? copy_region.y : 0
                    let xmax = (copy_region.x + copy_region.w) <= 1 ? (copy_region.x + copy_region.w) : 1
                    let ymax = (copy_region.y + copy_region.h) <= 1 ? (copy_region.h + copy_region.y) : 1
                    copy_region.w = xmax - copy_region.x
                    copy_region.h = ymax - copy_region.y
                    copy_region.name = `Rect ${(parseInt(this.props.regions.filter(reg => reg.type === BOX).sort((a, b) => {
                        var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                        var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name?.split(" ")[1]) || 0) + 1}`
                    copy_region.color = this.props.isThermalImage ? (this.props.regionToCopy.type === POINT ? AppConstants.THERMAL.COLOR.POINT : this.props.regionToCopy.type === BOX ? AppConstants.THERMAL.COLOR.BOX : this.props.regionToCopy.type === LINE ? AppConstants.THERMAL.COLOR.LINE : "white") : copy_region.color


                    let repeatedRegion = false
                    this.props.regions.map((r) => {
                        if (r.x == copy_region.x && r.y == copy_region.y)
                            repeatedRegion = true
                    })

                    if (!repeatedRegion) {
                        this.props.regionAdded(copy_region, true)
                        this.setState({
                            newAdded: true,
                            regions: [...this.props.regions, copy_region]
                        }, () => {
                            this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                        })
                    }
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                }
                else if (this.props.regionToCopy.type === LINE) {
                    let w = ((this.props.regionToCopy.x2 - this.props.regionToCopy.x1) / 2);
                    let h = ((this.props.regionToCopy.y2 - this.props.regionToCopy.y1) / 2)
                    let copy_region = { className: this.props.regionToCopy.className, color: this.props.regionToCopy.color, id: uuid(), type: this.props.regionToCopy.type, x1: (data.x - w) >= 1 ? 0.99 : (data.x - w) <= 0 ? 0.01 : (data.x - w), y1: (data.y - h) >= 1 ? 0.99 : (data.y - h) <= 0 ? 0.01 : (data.y - h), x2: (data.x + w) >= 1 ? 0.99 : (data.x + w) <= 0 ? 0.01 : (data.x + w), y2: (data.y + h) >= 1 ? 0.99 : (data.y + h) <= 0 ? 0.01 : (data.y + h) }
                    copy_region.name = `Line ${(parseInt(this.props.regions.filter(reg => reg.type === LINE).sort((a, b) => {
                        var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                        var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name?.split(" ")[1]) || 0) + 1}`
                    copy_region.color = this.props.isThermalImage ? (this.props.regionToCopy.type === POINT ? AppConstants.THERMAL.COLOR.POINT : this.props.regionToCopy.type === BOX ? AppConstants.THERMAL.COLOR.BOX : this.props.regionToCopy.type === LINE ? AppConstants.THERMAL.COLOR.LINE : "white") : copy_region.color
                    this.props.regionAdded(copy_region, true)
                    this.setState({
                        newAdded: true,
                        regions: [...this.props.regions, copy_region]
                    }, () => {
                        this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, lineStartPoint: null })
                    })
                    // }
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, lineStartPoint: null })
                } else if (this.props.regionToCopy.type === POINT) {
                    let copy_region = { className: this.props.regionToCopy.className, color: this.props.regionToCopy.color, id: uuid(), type: this.props.regionToCopy.type, x: data.x, y: data.y }
                    copy_region.name = `Point ${(parseInt(this.props.regions.filter(reg => reg.type === POINT).sort((a, b) => {
                        var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                        var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                        return numB - numA;
                    })[0]?.name?.split(" ")[1]) || 0) + 1}`
                    copy_region.color = this.props.isThermalImage ? (this.props.regionToCopy.type === POINT ? AppConstants.THERMAL.COLOR.POINT : this.props.regionToCopy.type === BOX ? AppConstants.THERMAL.COLOR.BOX : this.props.regionToCopy.type === LINE ? AppConstants.THERMAL.COLOR.LINE : "white") : copy_region.color
                    this.props.regionAdded(copy_region, true)
                    this.setState({
                        newAdded: true,
                        regions: [...this.props.regions, copy_region]
                    }, () => {
                        this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, lineStartPoint: null, point_xy: null })
                    })
                    // }
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, lineStartPoint: null, point_xy: null })
                } else if (this.props.regionToCopy.type === CIRCLE) {
                    let copy_region = {
                        ...this.props.regionToCopy,
                        showSeverity: false,
                        id: uuid(),
                        x: data.x,
                        y: data.y,
                        name: `Circle ${(parseInt(this.props.regions.filter(reg => reg.type === CIRCLE).sort((a, b) => {
                            var numA = parseInt(a?.name?.match(/\d+$/)[0]);
                            var numB = parseInt(b?.name?.match(/\d+$/)[0]);
                            return numB - numA;
                        })[0]?.name?.split(" ")[1]) || 0) + 1}`,
                        color: this.props.isThermalImage ? (this.props.regionToCopy.type === POINT ? AppConstants.THERMAL.COLOR.POINT : (this.props.regionToCopy.type === BOX || this.props.regionToCopy.type === CIRCLE) ? AppConstants.THERMAL.COLOR.BOX : this.props.regionToCopy.type === LINE ? AppConstants.THERMAL.COLOR.LINE : "white") : this.props.regionToCopy.color
                    }
                    if (!this.props.regions.some(r => r.type === CIRCLE && r.x === data.x && r.y === data.y && data.radius === data.radius) && this.isCircleInsideImage(copy_region)) {
                        this.props.regionAdded(copy_region, true)
                        this.setState({
                            newAdded: true,
                            regions: [...this.props.regions, copy_region]
                        }, () => {
                            this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                        })
                    }
                    this.setState({ activeRegion: undefined, newAdded: false, rectangle_xy: null, rectangle_xy: null, lineStartPoint: null })
                }
            }
        }
    }
    onCopyRegion = (region) => {
        this.props.onCopyRegion(region)
    }
    onClickRegionPoint = () => {
        this.setState({ drawing: false, rectangle_xy: null, lineStartPoint: null, point_xy: null })
        // if (data.id && this.props.onClickRegion && !this.props.regionToCopy && this.state.annotatorTool === DRAG) {
        //     this.selectRegion(data)
        // }
    }
    copyRegionDisable = () => {
        if (this.props.regionToCopy) this.props.onCopyRegion()
    }

    recenter = () => { }
    render() {
        return (
            <>

                {/* <div style={{ position: "absolute", zIndex: 1, color: "black", top: "135px", left: "50px" }}>
                    <div onClick={() => { this.changeTool(POINTER) }}>
                        Pointer
                    </div>
                    <div onClick={() => { this.changeTool(BOX) }}>
                        Box
                    </div>
                    <div onClick={() => { this.changeTool(POLYGON) }}>
                        Polygon
                    </div>
                    <div onClick={() => { this.changeTool(DRAG) }}>
                        Drag
                    </div>
                </div> */}
                {/* {this.state.maskImg && (
                    <img
                        src={this.state.maskImg.src}
                        style={{ position: "absolute", pointerEvents: "none" }}
                    ></img>
                )} */}
                <ImageCanvas

                    key={"ImageCanvas"}
                    userWidth={this.props.userWidth}
                    defectReport={this.props.defectReport != undefined && this.props.defectReport != null ? this.props.defectReport : false}
                    disableMaxHeight={this.props.disableMaxHeight != undefined && this.props.disableMaxHeight != null ? this.props.disableMaxHeight : true}
                    zoomOnAllowedArea={this.props.zoomOnAllowedArea != undefined && this.props.zoomOnAllowedArea != null ? this.props.zoomOnAllowedArea : true}
                    allowedArea={this.props.allowedArea ? this.props.allowedArea : null}
                    fillRegions={this.props.fillRegions != undefined && this.props.fillRegions != null ? this.props.fillRegions : true}
                    strokeWidth={this.props.strokeWidth ? this.props.strokeWidth : 2}
                    allowZoom={this.props.allowZoom != undefined && this.props.allowZoom != null ? this.props.allowZoom : true}
                    allowPanning={(this.props.allowPanning != undefined && this.props.allowPanning != null) ? this.props.allowPanning : (this.state.activeUpdateVertex || this.state.mouseDownOnBox || this.state.mouseDownOnPolygon || this.state.mouseDownOnCircle || this.state.mouseDownOnLine || this.state.mouseDownOnPoint ? false : true)}
                    searchable={this.props.searchable}
                    creatable={this.props.creatable}
                    disabledUpdate={this.props.disabledUpdate || !this.state.lastSelectedClass}
                    fullImageSegmentationMode={false}
                    autoSegmentationOptions={{ type: "autoseg" }}
                    showTags={this.props.showTags != undefined && this.props.showTags != null ? this.props.showTags : true}
                    newAdded={this.state.newAdded}
                    modifyingAllowedArea={false}
                    classList={this.props.classList}
                    showSeverity={this.props.showSeverity}
                    onDeleteRegion={this.onDeleteRegion}
                    onClassUpdate={this.onClassUpdate}
                    onClickRegion={(e) => {
                        this.onClickRegion(e)
                    }}
                    onCopyRegion={this.onCopyRegion}
                    onClickRegionPoint={this.onClickRegionPoint}
                    drawingRegion={this.state.annotatorTool === CIRCLE ? this.state.circle_xy : this.state.annotatorTool === POLYGON ? this.state.polygon_xy : this.state.annotatorTool === BOX ? this.state.rectangle_xy : this.state.annotatorTool === LINE ? this.state.lineStartPoint : this.state.annotatorTool === POINT ? this.state.point_xy : this.state.rectangle_xy}
                    onRecenter={this.props.onRecenter ? this.props.onRecenter : this.recenter}
                    copyRegionEnable={this.props.regionToCopy}
                    setRegionEditable={this.setRegionEditable}
                    regions={
                        this.state.imageMask ? [...this.props.regions, this.state.imageMask] : this.state.activeRegion ? [...this.props.regions, this.state.activeRegion] : this.state.activeUpdateVertex ? [...this.props.regions, this.state.activeUpdateVertex.region] : (this.state.mouseDownOnCircle ? [...this.props.regions, this.state.mouseDownOnCircle] : this.state.mouseDownOnPolygon ? [...this.props.regions, this.state.mouseDownOnPolygon] : this.state.mouseDownOnBox ? [...this.props.regions, this.state.mouseDownOnBox] : this.state.mouseDownOnLine ? [...this.props.regions, this.state.mouseDownOnLine] : this.state.mouseDownOnPoint ? [...this.props.regions, this.state.mouseDownOnPoint] : this.props.regions)
                    }
                    enabledTools={[
                        "create-polygon"
                    ]}
                    imageSrc={this.props.imageSrc}
                    createWithPrimary={this.state.annotatorTool !== POINTER && this.state.annotatorTool !== DRAG}
                    dragWithPrimary={this.state.annotatorTool === DRAG && !this.state.activeUpdateVertex && !this.state.mouseDownOnBox && !this.state.mouseDownOnLine && !this.state.mouseDownOnPoint && !this.state.mouseDownOnPolygon && !this.state.mouseDownOnCircle}
                    zoomWithPrimary={false}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                    onMouseMove={this.onMouseMove}
                    mouseDownOnBox={this.mouseDownOnBox}
                    mouseDownOnLine={this.mouseDownOnLine}
                    mouseDownOnPoint={this.mouseDownOnPoint}
                    mouseDownOnPolygon={this.mouseDownOnPolygon} // same using for circle as well
                    onClickRegionAddPoint={this.onClickRegionAddPoint}
                    mouseDownOnVertex={this.mouseDownOnVertex} // same using for circle as well
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onClickImage}
                    onImageOrVideoLoaded={(e) => {
                        this.setState({ imageLoaded: true })
                        this.props.onImageOrVideoLoaded(e)
                    }}
                    setError={this.props.setError ? this.props.setError : console.log("ENCOUNTERED ERROR !")}
                    isThermalImage={this.props.isThermalImage}
                    isDataForReport={this.props.isDataForReport}
                    mentionObj={this.props.mentionObj}
                    isDisabled={this.props.isDisabled}
                    selectedCursorProp={this.state.selectedCursor}
                />
            </>
        )
    }
}