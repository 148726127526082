import React, { Component } from 'react';
import dashboardActive from '../../icons/AeroCapture.svg';
import * as API from '../../api.js';
import { checkExpiry, checkSubscriptionAndStorage, getLimitedName, getLocalStorageValue } from '../../ReusableComponents/reusableFunctions';
import service from "../../sidebaricon/Services.png";
import Loader from '../../icons/loader.svg';
import { Link, Redirect } from 'react-router-dom';
import SideBar from '../../SideBar';
import Menu from '../assets/icons/menu.svg';
import dateFormat from 'dateformat';
import { activesubscription } from '../../redux';
import { connect } from "react-redux";
import ImageSetThumb from '../../icons/ImageSetThumb.png'
import PlanThumb from '../../icons/PlanThumb.png'
import ProjectThumb from '../../assets/sideBarIcons/ProjectInactive.png'
import Help from "../../sidebaricon/Help.png";
import Settings from "../../sidebaricon/Settings.png";
import InfiniteScroll from 'react-infinite-scroll-component'
import Plus from '../../assets/Icons/plus.png';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import listViewActiveIcon from '../../assets/Icons/listViewActiveIcon.png'
import gridViewActiveIcon from '../../assets/Icons/gridViewActiveIcon.png'
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import ImageSetActive from '../../assets/Icons/images.png';
import StorageIcon from '../../assets/Icons/storage.png';
import ObjectIcon from '../../assets/Icons/object.png';
import TaskIcon from '../../assets/Icons/task.png'
import DetectorIcon from '../../assets/Icons/detection.png'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import { Pie, Line } from "react-chartjs-2";
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import { DeleteComponentGrid, DeleteComponentTable } from '../../ReusableComponents/Menu'
import { SortComponent } from '../../ReusableComponents/SortComponent'
import AppConstants, { BLOB_URL, SERVICES } from '../../AppConstants';
import ReactJoyride, { STATUS } from 'react-joyride';
import SharedIcon from '../../icons/SharedIcon.png';
import { Modal } from "react-bootstrap";
import StatCard from '../../ReusableComponents/StatCard';
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { getActiveTeam, RoleNameComponent } from '../../Teams';
import { ManageProjectAccess } from '../../Teams/ManageProjectAccess';
import LocationIcon from '../../res/locationwhite.png';
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal';
import DeleteProject from '../../ReusableComponents/DeleteProject';
import Noproject from '../../assets/Images/Noproject.png';
import NoTask from '../../assets/Images/Notask.png';
import Noimageset from '../../assets/Images/Noimageset.png';
import TrainingTask from "../../assets/Images/trainingTask.png";
import DetectionTask from "../../assets/Images/detectionTask.png";
import AnnotationTask from "../../assets/Images/annotationTask.png";
import WelcomeGraphic from '../../assets/Images/Noannotationset.png'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class AeroCaptureDashboard extends Component {

    state = {
        user: {},
        type: "",
        selectedAllItems: "projects",
        key: " ",
        progress: 0,
        pageSize: 25,
        currentpage: -1,
        taskPage: 0,
        firstpage: 1,
        counts: 0,
        showDownloadPopup: false,
        projectRenamePopup: false,
        loader: true,
        taskStatusCount: {},
        services: [],
        projects: [],
        tasks: [],
        reports: [],
        taskCount: 0,
        showBackIcon: true,
        cancel: false,
        loadingMain: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showDeletePopup: false,
        allData: [],
        deleteItemType: "",
        deleteLoader: true,
        infoPopup: false,
        thumbURLs: [],
        projectThumbURL: [],
        recentMaps: [],
        mapsThumbURLs: [],
        recentData: [],
        loadingRecent: true,
        selected: 0,
        gridView: false,
        gridData: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        noContentMsg: "No Projects Available",
        noContentImg: Noproject,
        hasMoreItems: true,
        selectedMapCollectionId: '',
        selectedCollectionId: '',
        currentStep: 0,
        createTaskMsg: "",
        collection_id: "",
        collection_name: "",
        project_name: '',
        plan_name: "",
        imageCount: 0,
        taskType: null,
        showAddTaskPopup: false,
        addDetectionTaskPopup: false,
        addAnnotationTaskPopup: false,
        videoTask: false,
        task_name: "",
        video_id: "",
        fps: 1,
        media_name: null,
        media_id: null,
        scrollHeight: "115px",
        collections: [],
        videos: [],
        selectedProject: -1,
        selectedPlan: -1,
        selectedProjectId: null,
        selectedPlanId: null,
        projectsData: [],
        imageCollectionsData: [],
        loadingPopupData: false,
        sortType: 'Last Modified',
        loadingPopupData: false,
        sortTypeName: false,
        sortTypeDate: false,
        addTrainingTaskPopup: false,
        accuracy: "",
        classes: [],
        imageCount: 0,
        dataCount: 0,
        objectCount: 0,
        detectorCount: 0,
        graphData: [],
        chartData: '',
        pieData: '',
        outId: '',
        taskAnalysis: '',
        redirectToReportPage: false,
        redirectToTaskPage: false,
        selectedItem: "projects",
        statLoading: true,
        graphLoading: true,
        pieLoading: true,
        unProcessedCollections: [],
        redirectToTaskPage: false,
        redirectTaskId: '',
        collectionIdSelected: '',
        projectNameSelected: '',
        projectIdSelected: '',
        task: '',
        selectedTaskType: 0,
        yTicks: {
            padding: 7,
            fill: "black",
            display: true,
            fontSize: 11,
            fontFamily: "Poppins",
            beginAtZero: true,
            fontStyle: "normal",
            fontColor: "#666666",
        },
        classArray: [],
        classError: "",
        class: '',
        messageColor: false,
        taskPurpose: "Counting",
        showDataLoader: true,
        tableHeaders: [],
        showTourPopup: false,
        showTour: false,
        runTour: false,
        tourSteps: [{
            disableBeacon: true,
            target: '#stat-div',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Here you will get all data processing statistics</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Processing Statistics</div>),
            placement: 'right',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#task-button',
            content: (<div style={AppConstants.TEXTSTYLING}><p>You can create a new task from here anytime</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Create a New Task</div>),
            placement: 'right',
            styles: {
                options: {
                    width: 250,
                },
            },
        }],
        newProjectName: '',
        renameProjectPopup: false,
        selectedMangeAccessProject: false,
        selectedProjectData: undefined,
        showSubscriptionExpirePopup: false,
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListenerProject = (e) => {
        e.preventDefault();
        let key = e.target.value
        if (e.target.value !== -1) {
            this.setState((state) => ({
                ...state,
                project_name: this.state.projectsData[key].project_name,
                selectedProjectId: this.state.projectsData[key].id,
                projectIdSelected: this.state.projectsData[key].id,
                projectNameSelected: this.state.projectsData[key].project_name,
                selectedProject: key,
                selectedPlan: -1,
                loadingPopupData: this.state.selectedProject != -1 ? true : false
            }))
        }
    }

    /*--------------------------------------------------------------------------------*/

    deleteTask = () => {
        this.setState((state) => ({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureTask(this.state.selectedTaskId).then((data) => {
            //console.log(data);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                tasks: []
            }), () => {
                this.getAllTasks();
            })
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ task_name: e.target.value });
            }
    }

    handleChange = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else if (e.target.value.includes(' ')) {
            // setError("Spaces are not allowed.")
        } else {
            // setError(undefined)
            this.setState({ newProjectName: e.target.value });
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, SERVICES.PICSTORK).then((data) => {
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                selectedCollectionId: '',
                allItemsLoader: true,
                hasMoreItems: true,
            }), () => {
                this.getImageSets()
            })
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    closeTaskPopup = () => {
        this.setState((state) => ({
            ...state,
            classes: [],
            task_name: "",
            plan_name: "",
            project_name: "",
            accuracy: "",
            selectTask: false,
            classArray: []
        }));
    };

    renameProject = () => {
        this.setState((state) => ({
            ...state,
            itemName: "Project",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))

        API.renameProject(this.state.selectedProject.id, this.state.newProjectName).then((data) => {
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Project",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getProjects()
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Project",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Project already exist with same name.",
                    showProcessPopup: true,
                }))
                // alert("ImageSelt already exist with same name.")
            } else {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Project",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Something went wrong.",
                    showProcessPopup: true,
                }))
                // alert('Something went wrong')
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Project",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: "Something went wrong.",
                showProcessPopup: true,
            }))
        })
    }

    // function to load tiff thumbnails
    loadTiff = async (collections) => {
        let { user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < collections.length; i++) {
            let collection = collections[i]
            let date = (new Date(collections[i].created_on)).toLocaleDateString('en-US')
            collections[i].date = dateFormat(date, "dd-mmm-yyyy")
            collections[i].type = "Imageset"
            collections[i].displayData = [collection.collection_name, collection.images_count, collection.plan_name, collections[i].date]
            collections[i].link_to = {
                pathname: `/picstork/images`,
                state: { user, type, collection, project: { project_name: collection.project_name, id: collection.project_id }, plan: { plan_name: collection.plan_name, id: collection.plan_id, project_name: collection.project_name, }, sidebarMode: this.state.sidebarMode }
            }
            if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
                let name = collections[i].file.file_name.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.state.activeTeam.teamOwner.st}`

                thumbURL[i] = turl
                collections[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    thumbURLs: thumbURL,
                }))
            }
        }
        return collections
    }

    // function to load tiff thumbnails
    loadTasksTiff = async () => {
        let { tasks, user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.tasks.length; i++) {
            let task = tasks[i]
            let date = (new Date(task.date_created)).toLocaleDateString('en-US')
            task.date = dateFormat(date, "dd-mmm-yyyy")
            task.type = "Task"
            task.tasktype = task.tasktype == 1 ? AppConstants.TASK.TYPE.TRAINING : task.tasktype == 2 ? AppConstants.TASK.TYPE.DETECTION : task.tasktype == 4 ? AppConstants.TASK.TYPE.ANNREPORT : task.tasktype
            task.displayData = [task.task_name, task.status, task.images_count, task.date] //area converted from sq.m to sq.km
            task.link_to = task.video_task ? {
                pathname: "/picstork/videoTask",
                state: { user, type, task: { task_id: task.task_id }, collection: { id: task.video_id }, project: { project_name: task.project_name, id: task.project_id }, plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name }, sidebarMode: this.state.sidebarMode }
            } :
                {
                    pathname: task.tasktype == AppConstants.TASK.TYPE.TRAINING ? "/picstork/trainingTask" : task.tasktype == AppConstants.TASK.TYPE.DETECTION ? "/picstork/detectionTask" : "/picstork/annotationReportTask",
                    state: { user, type, task: { task_id: task.task_id }, collection: { id: task.image_collection_id }, project: { project_name: task.project_name, id: task.project_id }, plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name }, sidebarMode: this.state.sidebarMode }
                }
            if (tasks[i].file && tasks[i].file.file_name && tasks[i].file.file_name != undefined) {
                let name = tasks[i].file.file_name.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${tasks[i].image_collection_id}/thumb-${tasks[i].file.file_name}?${this.state.activeTeam.teamOwner.st}`

                thumbURL[i] = turl
                tasks[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    tasks,
                    thumbURLs: thumbURL,
                }))
            } else if (tasks[i].video_task) {
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${this.state.tasks[i].project_id}/thumb-${this.state.tasks[i].plan_id}?${this.state.activeTeam.teamOwner.st}`

                thumbURL[i] = turl
                tasks[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    tasks,
                    thumbURLs: thumbURL,
                }))
            }
        }
        return tasks
    }

    getTask = () => {
        API.getAeroCaptureTask(this.state.redirectTaskId.task_id).then((data) => {
            this.setState((state) => ({
                ...state,
                task: data,
                redirectToReportPage: true,
            }))
        }, (e) => {
            console.log(e);
        })
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('picstorkDashboardTour', 1)
            this.setState({
                runTour: false,
            })
        }
    };

    // function to load tiff thumbnails of project
    loadProjectTiff = async () => {
        let { projects, user, type } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.projects.length; i++) {
            let project = projects[i]
            projects[i].date = dateFormat(project.created_date, "dd-mmm-yyyy")
            projects[i].type = "Project"
            projects[i].displayData = [project.project_name, null, "Tasks : " + project.tasks, project.date, project.location_name]
            projects[i].link_to = {
                pathname: "/picstork/project",
                state: { user, type, sidebarMode: this.state.sidebarMode, project },
            }
            if (projects[i].collection_id && projects[i].collection_id != undefined) {
                let name = projects[i].image.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.activeTeam.teamOwner.container}/FlightImages/collection-${projects[i].collection_id}/thumb-${projects[i].image}?${this.state.activeTeam.teamOwner.st}`
                thumbURL[i] = turl
                projects[i].thumbURL = turl
                this.setState((state) => ({
                    ...state,
                    projectThumbURL: thumbURL,
                    projects
                }))
            }
        }
        return projects
    }

    getAllTasks = async (appendData) => {
        if (!appendData) {
            this.setState({ showDataLoader: true })
            document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            let { user, type, pageSize, tasks } = this.state
            API.GetAllTasksOfUser(this.state.pageSize, appendData ? this.state.taskPage : 0, " ").then(async (data) => {
                if (data.tasks.length == 0 || data.tasks.length < pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        counts: data.count ? data.count : 0,
                        showDataLoader: false
                    }));
                }
                this.setState(state => ({
                    ...state,
                    noContentImg: Noproject,
                    noContentMsg: "No Projects Available",
                    taskPage: this.state.taskPage + 1
                }))
                var tasks = appendData ? this.state.tasks : data.tasks
                if (appendData)
                    tasks = tasks.concat(data.tasks)
                this.setState((state) => ({
                    ...state,
                    tasks: tasks
                }))
                tasks = await this.loadTasksTiff()
                let tableHeaders = ["", "Name", "Status", "Type", "Created at"]
                this.setState((state) => ({
                    ...state,
                    tasks,
                    gridData: tasks,
                    loadingMain: false,
                    counts: data.count ? data.count : 0,
                    noContentImg: NoTask,
                    noContentMsg: "No Tasks Available",
                    showDataLoader: false,
                    tableHeaders
                }), () => {

                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    showDataLoader: false
                }))
            })
        }, 500)
    }

    getImageSets = async (appendData) => {
        if (!appendData) {
            this.setState({ showDataLoader: true })
            document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            API.getLatestImageSets("user", this.state.pageSize, this.state.user.userid, appendData ? this.state.collections.length : 0, SERVICES.PICSTORK).then(async (data) => {
                if (data.imageCollections.length == 0 || data.imageCollections.length < this.state.pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        counts: data.count,
                        showDataLoader: false
                    }));
                }

                var collections = appendData ? this.state.collections : data.imageCollections
                if (appendData)
                    collections = collections.concat(data.imageCollections)
                this.setState((state) => ({
                    ...state,
                    collections: collections,
                    counts: data.count,
                    noContentImg: Noimageset,
                    noContentMsg: "No ImageSets Available",
                    loadingMain: false,
                    showDataLoader: false
                }))
                let collectionsLoad = await this.loadTiff(collections)
                let tableHeaders = ["", "Name", "Images", "Created at", ""]
                this.setState((state) => ({
                    ...state,
                    gridData: collectionsLoad,
                    tableHeaders
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
            }, (e) => {
                console.log(e);
            })
        }, 500)
    }

    getProjects = async (appendData) => {
        if (!appendData) {
            this.setState({ showDataLoader: true })
            document.getElementById('table').scrollTop = 0
        }
        setTimeout(() => {
            const { pageSize, key, activeTeam } = this.state;
            this.setState(state => ({
                ...state,
                noContentImg: Noproject,
                noContentMsg: "No Projects Available",
                currentpage: this.state.currentpage + 1
            }))
            API.getAllProjects(key == "" ? " " : key, pageSize, appendData ? this.state.currentpage : 0, undefined, AppConstants.SERVICES.PICSTORK).then(async (data) => {
                console.log('project data', data);
                if (data.projects && data.projects.length == 0 || data.projects.length < pageSize) {
                    this.setState((state) => ({
                        ...state,
                        hasMoreItems: false,
                        counts: data.count,
                        showDataLoader: false
                    }));
                }
                var projects = this.state.projects

                if (appendData)
                    projects = projects.concat(data.projects)
                else
                    projects = data.projects
                this.setState((state) => ({
                    ...state,
                    projects: projects,
                    counts: data.count,
                }))
                let projectsData = await this.loadProjectTiff()
                let tableHeaders = ["", "Name", "Tasks", "Created at", "Location", ""]
                this.setState((state) => ({
                    ...state,
                    gridData: projectsData,
                    tableHeaders,
                    loadingMain: false,
                    showDataLoader: false,
                    noContentImg: Noproject,
                    noContentMsg: "No Projects Available"
                }), () => {
                    if (this.state.sortType === 'A-Z') {
                        this.sortByName()
                    } else if (this.state.sortType === 'Z-A') {
                        this.reverseByName()
                    } else if (this.state.sortType === 'First Modified') {
                        this.sortByDate()
                    } else if (this.state.sortType === 'Last Modified') {
                        this.reverseByDate()
                    }
                })
                // }
            }, (e) => {
                console.log(e);
                this.setState({
                    showDataLoader: false
                })
            })

        }, 500)
    }

    onChangeEvent = (e) => {
        this.setState({
            selectedItem: e,
            selectedAllItems: e,
            allItemsLoader: true,
            sortType: 'Last Modified',
            // counts: 0,
            showDataLoader: true,
            hasMoreItems: true
        }, () => {
            document.getElementById('table').scrollTop = 0
            if (this.state.selectedAllItems == 'projects')
                this.getProjects()
            else if (this.state.selectedAllItems == 'imagesets')
                this.getImageSets()
            else if (this.state.selectedAllItems == 'tasks')
                this.getAllTasks()
        })
    }

    // function to Get all data under the given project
    getAllDataToDelete = () => {
        this.setState((state) => ({
            ...state,
            deleteLoader: true,
            showDeletePopup: true,
        }))
        API.getProjectData(this.state.deleteItemType, this.state.selectedProject.project_name, this.state.selectedProject.id).then((data) => {
            let keys = Object.keys(data)
            let alldata = []
            keys = keys.reverse()
            keys.forEach(element => {
                let obj = { "name": element, "count": data[element].length, "processIcon": "", data: data[element] }
                alldata.push(obj)
            });
            this.setState((state) => ({
                ...state,
                allData: data.isEmptyProject ? null : alldata,
                deleteLoader: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    next = () => {
        let selected = this.state.selected + 2
        this.setState((state) => ({
            ...state,
            selected: selected,
        }))
        document.getElementById('scroll-div').scrollLeft += 400;
    }

    prev = () => {
        let selected = this.state.selected - 2 < 0 ? 0 : this.state.selected - 2
        this.setState((state) => ({
            ...state,
            selected: selected,
        }))
        document.getElementById('scroll-div').scrollLeft -= 400;
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        if (this.state.selectedAllItems == 'tasks') {
            let sort = this.state.tasks.sort((a, b) => a.task_name.localeCompare(b.task_name))
            this.setState((state) => ({
                ...state,
                tasks: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => a.project_name.localeCompare(b.project_name))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        if (this.state.selectedAllItems == 'tasks') {
            let sort = this.state.tasks.sort((a, b) => b.task_name.localeCompare(a.task_name))
            this.setState((state) => ({
                ...state,
                tasks: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => b.project_name.localeCompare(a.project_name))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        if (this.state.selectedAllItems == 'tasks') {
            let sort = this.state.tasks.sort((a, b) => a.date_created.localeCompare(b.date_created))
            this.setState((state) => ({
                ...state,
                tasks: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => a.created_date.localeCompare(b.created_date))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => a.created_on.localeCompare(b.created_on))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    //GET STATISTICAL DATA
    getGraphData = () => {
        this.setState((state) => ({
            graphLoading: true,
        }))
        API.getGraphData().then(data => {
            let scaleCount = data.data.length
            var temp = []

            for (let i = 1; i <= scaleCount; i++) {
                temp.push(i)
            }
            let yTicks = data.data.every((val, i, arr) => val === arr[0]) ? {
                padding: 7,
                fill: "black",
                display: true,
                fontSize: 11,
                fontFamily: "Poppins",
                beginAtZero: true,
                fontStyle: "normal",
                fontColor: "#666666",
                lineHeight: "14px",
                stepSize: 1
            } : {
                padding: 7,
                fill: "black",
                display: true,
                fontSize: 11,
                fontFamily: "Poppins",
                beginAtZero: true,
                fontStyle: "normal",
                fontColor: "#666666",
                lineHeight: "14px",
            }

            this.setState((state) => ({
                ...state,
                yTicks: yTicks,
            }))
            const data1 = (canvas) => {

                return {
                    labels: temp,
                    datasets: [
                        {
                            data: data.data,
                            fill: true,
                            backgroundColor: (context) => {
                                if (!context.chart.chartArea) return;
                                const chart = context.chart;
                                const { ctx, data, chartArea: { top, bottom }, } = chart;
                                const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                                gradient.addColorStop(0, '#85b4ff');
                                gradient.addColorStop(0.5, '#b5d2ff');
                                gradient.addColorStop(1, '#ebf3ff');
                                return gradient
                            },
                            borderColor: "#2989cf",
                            borderWidth: 0.5,
                        },
                    ],
                }
            }
            this.setState((state) => ({
                graphLoading: false,
                graphData: data.data,
                chartData: data1()
            }))
        })
    }

    getStatData = () => {
        this.setState((state) => ({
            pieLoading: true,
            statLoading: true,
        }))

        API.getStatData().then(data => {
            this.setState((state) => ({
                imageCount: data.images,
                objectCount: data.objects,
                taskCount: data.tasks,
                dataCount: data.storage,
                detectorCount: data.detectors,
            }))
            const data2 = {
                labels: ['Completed', 'Pending', 'In Progress', 'Failed', 'Creating'],
                datasets: [
                    {
                        label: 'Task analysis',
                        data: [data.taskAnalysis.completed, data.taskAnalysis.pending, data.taskAnalysis.inProgress, data.taskAnalysis.failed, data.taskAnalysis.creating],
                        backgroundColor: [
                            'rgba(96, 44, 175, 1)',
                            'rgba(0, 219 ,160, 1)',
                            'rgba(87, 254, 19 , 1)',
                            'rgba(24, 155 ,199 , 1)',
                            'rgba(23, 165, 137, 1)'
                        ],
                        borderColor: [
                            'rgba(255,255,255, 1)',
                            'rgba(255, 255 ,255, 1)',
                            'rgba(255, 255, 255 , 1)',
                            'rgba(255, 255 ,255 , 1)',
                            'rgba(255, 255 ,255 , 1)',
                        ],
                        borderWidth: 2,
                    }
                ]
            }

            this.setState((state) => ({
                pieData: data2,
                pieLoading: false,
                statLoading: false,
            }))
        })
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        if (this.state.selectedAllItems == 'tasks') {
            let sort = this.state.tasks.sort((a, b) => b.date_created.localeCompare(a.date_created))
            this.setState((state) => ({
                ...state,
                tasks: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'projects') {
            let sort = this.state.projects.sort((a, b) => b.created_date.localeCompare(a.created_date))
            this.setState((state) => ({
                ...state,
                projects: sort,
                gridData: sort
            }));
        }
        else if (this.state.selectedAllItems == 'imagesets') {
            let sort = this.state.collections.sort((a, b) => b.created_on.localeCompare(a.created_on))
            this.setState((state) => ({
                ...state,
                collections: sort,
                gridData: sort
            }));
        }
    }

    closeInfoPopup = () => {
        this.setState((state) => ({
            ...state,
            OnboardPopup: false,
        }));
        this.props.updateProfileDetails({
            activeTeamId: "aaaa",
            activeTeam: "",
            isLoggedInUserOwner: false,
            firstTimeLoginDN: this.state.user.userid
        });
    };

    componentWillUnmount() {
        updateOutTime(this.state.outId)
        localStorage.removeItem('created_task')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedAllItems !== this.state.selectedAllItems) {
            this.setState(state => ({
                ...state,
                currentpage: -1,
                taskPage: 0,
            }))
        }
    }

    componentWillMount() {
        document.title = "PicStork - Dashboard";
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler
        );
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam
            }, () => {

                checkSubscriptionAndStorage(AppConstants.SERVICES.PICSTORK).then(data => {
                    if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                    else {
                        this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                        if (getLocalStorageValue('picstorkDashboardTour') == null) this.setState({ showTourPopup: true })
                    }
                })

                addUserAnalytic(this.props.location.pathname)
                    .then(id => {
                        this.setState((state) => ({
                            outId: id
                        }))
                    })

                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 30% !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);

                if (this.props.location.state) {
                    let { user, type, sidebarMode } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        sidebarMode,
                    }), async () => {
                        this.getProjects()
                        if (activeTeam && activeTeam.isOwner) {
                            this.getGraphData()
                            this.getStatData()
                        } else {
                            this.setState({ graphLoading: false, pieLoading: false, statLoading: false, })
                        }

                        API.getUserSubbedServices().then(
                            (response) => {
                                let services = response[0];
                                if (services.length == 1 && services[0].title == 'AeroStream') {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: false
                                    }))
                                } else {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: true
                                    }))
                                }
                            }
                        )
                    })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        this.setState((state) => ({
                            ...state,
                            user,
                            type
                        }), async () => {
                            // await this.getRecentData()
                            this.getProjects()
                            this.getStatData()
                            this.getGraphData()
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })
    }

    render() {
        let { user, type, showBackIcon, } = this.state
        const listLoader = <div style={{ height: "10vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={Loader} style={{ height: "10vh", width: "30vw", margin: "auto" }} />
        </div>
        let sidebarConfigurations = {
            options: showBackIcon == true ? [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardActive,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                    selected: true,
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ] : [
                {
                    icon: dashboardActive,
                    text: "PicStork",
                    path: "/picstork/dashboard",
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            picStorkHelp: true,
        }

        const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <div className='sort-type-style'>{this.state.sortType}</div>
            </a>
        ));

        return (
            user.username ? <div className="wrapper">
                {/* Done */}
                <ManageProjectAccess
                    projectId={this.state.selectedMangeAccessProject}
                    closeManageAccess={() => {
                        this.setState({
                            selectedMangeAccessProject: false,
                            selectedProjectData: undefined,
                        })
                    }}
                    senderData={user}
                    propsData={this.state.selectedProjectData}
                />
                <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        height={this.state.processPopupIcon == "WARNING" && this.state.selectedAllItems == 'imagesets' ? "210px" : null}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => {
                            if (this.state.selectedAllItems == 'tasks') {
                                this.deleteTask()
                            }
                            else if (this.state.selectedAllItems == 'projects') {
                                this.getAllDataToDelete()
                            }
                            else if (this.state.selectedAllItems == 'imagesets') {
                                this.deleteImageCollection()
                            }
                        }}
                    />
                    {/* --------------------------------------------------------------- */}

                    {/* ----------------------------------   TASK TYPE SELECTION POPUP -----------------------------*/}
                    <AnimatedModal
                        height="325px"
                        width="670px"
                        zIndex={5}
                        onRequestClose={this.closeTaskPopup}
                        isOpen={this.state.selectTask}
                    >
                        <div style={{ padding: '30px 30px 10px 30px' }}>
                            <div style={{ display: 'flex' }}>

                                <div className='taskSelectionCard'
                                >
                                    <Link
                                        to={{
                                            pathname: "trainingTask",
                                            state: {
                                                type,
                                                user
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={TrainingTask}
                                                style={{
                                                    margin: "0px 10px 10px 10px",
                                                    height: "90px",
                                                    width: "90px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Training
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            Train and create a detector
                                        </div>
                                    </Link>
                                </div>


                                <div className='taskSelectionCard'>
                                    <Link
                                        to={{
                                            pathname: "annotationReportTask",
                                            state: {
                                                type,
                                                user
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={AnnotationTask}
                                                style={{
                                                    margin: "0px 10px 0px 10px",
                                                    height: "100px",
                                                    width: "100px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Annotation Report
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            Perform manual annotations and create a report
                                        </div>
                                    </Link>
                                </div>


                                <div className='taskSelectionCard' style={{ marginRight: '0px' }}>
                                    <Link
                                        to={{
                                            pathname: "detectionTask",
                                            state: {
                                                type,
                                                user
                                            },
                                        }}
                                        style={{ color: "#212529" }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img
                                                src={DetectionTask}
                                                style={{
                                                    margin: "0px 10px 0px 10px",
                                                    height: "100px",
                                                    width: "100px",
                                                }}
                                            />
                                        </div>
                                        <div className='taskSelectionTitle'>
                                            Detection
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "12px" }}>
                                            Analyse images using detector
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div style={{ width: '100%', marginTop: '24px', display: 'flex', justifyContent: 'end' }}>
                                <button
                                    className="add-user-form-submit-button"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            selectTask: false,
                                        }));
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </AnimatedModal>

                    {this.state.runTour ?
                        <ReactJoyride
                            continuous={true}
                            run={true}
                            hideBackButton={true}
                            hideCloseButton={true}
                            showSkipButton={true}
                            steps={this.state.tourSteps}
                            disableOverlayClose={true}
                            floaterProps={AppConstants.FLOATER_PROPS}
                            locale={AppConstants.LOCALE_BUTTONS}
                            callback={(e) => this.handleJoyrideCallback(e)}
                            styles={{
                                buttonNext: AppConstants.NEXT_BUTTON,
                                buttonSkip: AppConstants.SKIP_BUTTON,
                                spotlight: AppConstants.SPOTLIGHT,
                                options: AppConstants.MAIN_OPTIONS
                            }}
                        /> : <></>}

                    {/* -------------- Tour confirmation popup and subscription ----------------------- */}

                    {
                        this.state.showSubscriptionExpirePopup ?
                            <SubscriptionExpireModal data={this.state.showSubscriptionExpirePopup} /> :
                            <AnimatedModal
                                height="350px"
                                width="500px"
                                zIndex={5}
                                onRequestClose={this.closeTaskPopup}
                                isOpen={this.state.showTourPopup}
                            >
                                <div className="content-text" style={{ padding: '20px 30px 20px 30px' }}>
                                    <div className="services-content-title" style={{ fontSize: "20px", textAlign: 'left' }}>
                                        Welcome to PicStork !
                                    </div>
                                    <div style={{ textAlign: 'left', fontSize: '12px', marginTop: '10px', fontWeight: '400' }}>
                                        The ultimate platform for image <b>Annotations, Training and Detections</b><br /> Happy Machine Learning!
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', height: "180px", alignItems: 'center' }}>
                                        <img style={{ height: '170px' }} src={WelcomeGraphic} />
                                    </div>

                                    <div style={{ width: '100%', marginTop: '24px', display: 'flex', justifyContent: 'end' }}>
                                        <button
                                            className="add-user-form-submit-button popup-btn-right btn-ok"
                                            onClick={() => {
                                                this.setState({
                                                    showTourPopup: false,
                                                    showTour: true,
                                                    runTour: true,
                                                })
                                            }}
                                        >
                                            Lets Go !!
                                        </button>
                                    </div>
                                </div>
                            </AnimatedModal>
                    }

                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.renameProjectPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                renameProjectPopup: false,
                                newProjectName: '',
                                selectedProject: ''
                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title" style={{ fontSize: "18px" }}>{"Rename Project"}</div>
                                <label className="font-color-secondary" style={{ fontSize: "14px" }}>Enter New Name</label>
                                <input type="text" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} value={this.state.newProjectName ? this.state.newProjectName : ''} className="add-user-form-text" placeholder="eg. Project Name"
                                    onChange={this.handleChange} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            renameProjectPopup: false,
                                            newProjectName: '',
                                            selectedProject: ''
                                        }));
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div>
                                {this.state.newProjectName && this.state.newProjectName.length > 0 ?
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                renameProjectPopup: false,
                                            }), () => {
                                                this.renameProject();
                                            })
                                        }}
                                    >
                                        Update
                                    </button>
                                    :
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        style={{ opacity: '0.4' }}
                                    >
                                        Update
                                    </button>
                                }

                            </div>
                        </Modal.Footer>
                    </Modal>

                    {/* ---------------Component to delete project--------------------- */}
                    <DeleteProject
                        service={AppConstants.SERVICES.PICSTORK}
                        projectId={this.state?.selectedProject?.id}
                        show={this.state.showDeletePopup}
                        onClose={(isDeleted) => {
                            this.setState({
                                showDeletePopup: false
                            }, () => {
                                if (isDeleted)
                                    this.getProjects()
                            })
                        }}
                    />

                    {/* --------------------------------------------------------------- */}

                    <div className="top-bar"><div className="top-bar-text">PICSTORK</div></div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    <div className="main-content" style={{ background: 'none', alignItems: "center" }}>
                        {/* {this.successRedirectComponent()} */}
                        {/* <div style={{ paddingTop: '10px', height : 'calc(100%-50px)' }}> */}
                        <div style={{ height: "75px", marginTop: '10px' }}>
                            <div id="stat-div" style={{ display: "flex", justifyContent: "space-around", width: '100%', height: '100%' }}>
                                <StatCard
                                    icon={ImageSetActive}
                                    loading={this.state.statLoading}
                                    statCount={this.state.imageCount}
                                    statName="Images"
                                    isDisabled={this.state.activeTeam.isOwner ? false : true}
                                    width={'15vw'}
                                    minWidth={'200px'}
                                    maxWidth={'300px'}
                                />
                                <StatCard
                                    icon={ObjectIcon}
                                    loading={this.state.statLoading}
                                    statCount={this.state.objectCount}
                                    statName="Objects"
                                    isDisabled={this.state.activeTeam.isOwner ? false : true}
                                    width={'15vw'}
                                    minWidth={'200px'}
                                    maxWidth={'300px'}
                                />
                                <StatCard
                                    icon={TaskIcon}
                                    loading={this.state.statLoading}
                                    statCount={this.state.taskCount}
                                    statName="Tasks"
                                    isDisabled={this.state.activeTeam.isOwner ? false : true}
                                    width={'15vw'}
                                    minWidth={'200px'}
                                    maxWidth={'300px'}
                                    action={{
                                        actionStatName: "View",
                                        actionStatLink: {
                                            pathname: "/picstork/alltasks",
                                            state: {
                                                user,
                                                type,
                                                sidebarMode: this.state.sidebarMode,
                                            },
                                        },
                                    }}
                                />
                                <StatCard
                                    icon={DetectorIcon}
                                    loading={this.state.statLoading}
                                    statCount={this.state.detectorCount}
                                    statName="Detectors"
                                    isDisabled={this.state.activeTeam.isOwner ? false : true}
                                    width={'15vw'}
                                    minWidth={'200px'}
                                    maxWidth={'300px'}
                                    action={{
                                        actionStatName: "View",
                                        actionStatLink: {
                                            pathname: "/picstork/userdetectors",
                                            state: {
                                                user,
                                                type,
                                                sidebarMode: this.state.sidebarMode,
                                            },
                                        },
                                    }}
                                />
                                <StatCard
                                    icon={StorageIcon}
                                    loading={this.state.statLoading}
                                    statCount={this.state.dataCount}
                                    statName="Data"
                                    isDisabled={this.state.activeTeam.isOwner ? false : true}
                                    width={'15vw'}
                                    minWidth={'200px'}
                                    maxWidth={'300px'}
                                />

                            </div>
                        </div>

                        <div style={{ display: "flex", position: "absolute", width: "calc(100% - 57px)", marginTop: "2%", justifyContent: "center" }}>

                            <div style={{ width: "58%", height: "100%" }} >

                                <div style={{ display: "flex", marginTop: '0px', justifyContent: 'space-between', alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {this.state.activeTeam.isOwner ?
                                            <DropDownWithButton
                                                // isButton
                                                selectedItem={this.state.selectedItem}
                                                width='113.5px'
                                                isDisabled={this.state.showDataLoader || this.state.loadingMain}
                                                list={
                                                    [
                                                        {
                                                            name: "projects", onClick: () => {
                                                                this.onChangeEvent("projects")
                                                            }
                                                        },
                                                        {
                                                            name: "imagesets", onClick: () => {
                                                                this.onChangeEvent("imagesets")
                                                            }
                                                        },
                                                        {
                                                            name: "tasks", onClick: () => {
                                                                this.onChangeEvent("tasks")
                                                            }
                                                        }
                                                    ]
                                                }
                                            /> : <div>Projects</div>}
                                        <span className='data-count'>({this.state.counts})</span>
                                    </div>

                                    <div style={{ display: 'flex', height: '30px', alignItems: "center" }} >
                                        <div id='task-button' style={{ display: 'flex' }}>
                                            {<div style={{ display: "flex", filter: this.state.activeTeam.isOwner ? "" : "grayscale(100%)" }}>
                                                <div  >
                                                    {/* <div>New Task <img src={Plus} style={{ height: "12px", marginLeft: "10px" }} /></div> */}
                                                    <div>
                                                        <ButtonWithIcon
                                                            isDisabled={this.state.activeTeam.isOwner ? false : true}
                                                            text="Task"
                                                            icon={Plus}
                                                            onClick={() => {
                                                                this.state.activeTeam.isOwner &&
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        selectTask: true,
                                                                    }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                        <img src={this.state.gridView ? listViewActiveIcon : gridViewActiveIcon} style={{ height: '22px', width: '22px', cursor: 'pointer', margin: '0px 10px' }}
                                            onClick={() => {
                                                document.getElementById('table').scrollTop = 0
                                                this.setState((state) => ({
                                                    ...state,
                                                    gridView: !this.state.gridView,
                                                }))
                                            }} />
                                    </div>
                                </div>
                                {/* <ActivityPopup
                                    item={this.state.selectedItem}
                                    open={this.state.showDataLoader}
                                    icon={"WAIT"}
                                    action={this.state.processAction}
                                    msg={`Getting ${this.state.selectedAllItems}, please wait...`}
                                    close={() => this.closeActivityPopup()}
                                /> */}
                                <div id='table' style={{ border: "1px solid #c2c2c2", backgroundColor: "#FAFAFA", marginTop: "10px", borderRadius: "5px", height: '68vh' }}>
                                    <div>
                                        {this.state.showDataLoader ? <div style={{ position: "absolute", height: "67%", width: "56%", display: "flex", justifyContent: 'center', alignItems: "center", paddingLeft: "3%", zIndex: 2 }}>
                                            <img src={Loader} style={{ height: "18vh", width: "15vw" }} />
                                        </div> : <></>}
                                        {this.state.gridView ?
                                            this.state.gridData.length > 0 ?
                                                <div>
                                                    <div style={{ padding: "10px 10px 0px 10px" }}>
                                                        <SortComponent
                                                            label={'Name'}
                                                            sortAscending={this.sortByName}
                                                            sortDecending={this.reverseByName}
                                                            sortASC={true}
                                                        />
                                                    </div>

                                                    <InfiniteScroll
                                                        className='scroll-container'
                                                        height={"61vh"}
                                                        dataLength={this.state.gridData.length} //This is important field to render the next data
                                                        next={() => {
                                                            if (this.state.selectedAllItems == 'projects') {
                                                                this.getProjects(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'tasks') {
                                                                this.getAllTasks(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'imagesets') {
                                                                this.getImageSets(true)
                                                            }
                                                        }}
                                                        hasMore={this.state.hasMoreItems}
                                                        loader={listLoader}
                                                    >
                                                        <div className="cards-tray-projects" style={{ opacity: this.state.showDataLoader ? 0.5 : 1, paddingTop: "15px", paddingLeft: "15px", gridGap: "30px", columnGap: "30px" }} >
                                                            {this.state.gridData.map((data, key) => {
                                                                return <div>
                                                                    <Link to={data.link_to}>
                                                                        {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>

                                                                            {(this.state.selectedAllItems == 'imagesets' || this.state.selectedAllItems == 'tasks') ?
                                                                                data.sharedData.isShared ?
                                                                                    <div className='shared-badge'>
                                                                                        <img src={SharedIcon} style={{ height: "18px" }} />
                                                                                    </div>
                                                                                    : <></>
                                                                                : <></>}

                                                                            {this.state.activeTeam.isOwner && <DeleteComponentGrid
                                                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                                                canRename={this.state.selectedAllItems == 'projects'}
                                                                                canManage={this.state.activeTeam.isOwner && this.state.selectedAllItems == 'projects' && !data.sharedData?.isShared}
                                                                                onClickManage={() => {
                                                                                    this.setState({
                                                                                        selectedMangeAccessProject: data.id,
                                                                                        selectedProjectData: { pathname: data.link_to.pathname, state: { project: { ...data.link_to.state.project, link_to: undefined } } }
                                                                                    })
                                                                                }}
                                                                                onClickRename={(e) => {
                                                                                    if (this.state.selectedAllItems == 'projects') {
                                                                                        e.preventDefault()
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            selectedProject: data,
                                                                                            newProjectName: data.project_name,
                                                                                            renameProjectPopup: true,
                                                                                        }))
                                                                                    }
                                                                                }}
                                                                                onClickDelete={(e) => {
                                                                                    e.preventDefault()
                                                                                    if (this.state.selectedAllItems == 'maps') {
                                                                                        this.setState((s) => ({
                                                                                            ...s,
                                                                                            selectedMapCollectionId: data.id,
                                                                                            itemName: "Map",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "Are you sure you want to delete this Map ? ",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                    else if (this.state.selectedAllItems == 'projects') {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            selectedProject: data,
                                                                                            deleteItemType: "project"
                                                                                        }), () => {
                                                                                            this.getAllDataToDelete()
                                                                                        })
                                                                                    }
                                                                                    else if (this.state.selectedAllItems == 'imagesets') {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            selectedCollectionId: data.id,
                                                                                            deleteItem: "IMAGESET",
                                                                                            itemName: "Imageset",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                                "\n Are you sure you want to delete this Imageset ?",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                }}
                                                                            />}

                                                                            <div className="card-header customer-cards-dashboard-logo project-card-header" style={{ height: data.type == "Project" ? "45px" : "62px" }}>
                                                                                {
                                                                                    data && data.type == "Task" ?
                                                                                        (data.file && data.file.file_name || data.video_task) ?
                                                                                            <div style={{ borderRadius: "6px", height: "111px", width: "100%", background: "black" }} >
                                                                                                <img id="ProjectImage" style={{ objectFit: 'cover' }} loading="true" src={data.thumbURL} />
                                                                                            </div>
                                                                                            :
                                                                                            <img id="ProjectImage" src={PlanThumb} />
                                                                                        :
                                                                                        data.type == "Plan" || data.type == "Project" ?
                                                                                            data.image ?
                                                                                                <img id="ProjectImage" style={{ objectFit: 'cover' }} loading="true" src={data.thumbURL} />
                                                                                                :
                                                                                                <img id="ProjectImage" src={PlanThumb} />
                                                                                            :
                                                                                            data.file && data.file.file_name ?
                                                                                                <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                                                    src={data.thumbURL}
                                                                                                    onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} />
                                                                                                :
                                                                                                <img id="ProjectImage" style={{ objectFit: 'cover' }} src={ImageSetThumb} />
                                                                                }
                                                                            </div>
                                                                            <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                                                <div style={{ display: 'flex', height: "24px", alignItems: "center", justifyContent: 'space-between' }}>
                                                                                    <div style={{ overflow: "hidden", fontSize: '11px' }}>
                                                                                        {
                                                                                            data.displayData[0] && data.displayData[0].length > 10 ?
                                                                                                `${data.displayData[0].substring(0, 10)}...` :
                                                                                                `${data.displayData[0]}`
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{ overflow: "hidden", fontSize: '11px' }}>{data.displayData[1]}</div>
                                                                                </div>
                                                                                <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <div style={{ overflow: 'hidden' }}>{
                                                                                        data.displayData[2] && data.displayData[2].length > 10 ?
                                                                                            `${data.displayData[2].substring(0, 10)}...` :
                                                                                            `${data.displayData[2]}`
                                                                                    }</div>
                                                                                    <div> {data.date}</div>
                                                                                </div>
                                                                                {data.type == "Project" ? <div style={{ fontSize: '10px', width: "100%", marginTop: "2px" }}><img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} /> {data.displayData[4] ? data.displayData[4].length > 20 ? `${data.displayData[4].substring(0, 20)}...` : data.displayData[4] : '-'}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </InfiniteScroll>
                                                </div>
                                                : !this.state.showDataLoader ? <div className="rmp-dashboard-empty-data-message" style={{ height: "67vh" }}>
                                                    <img style={{ height: '35%', marginTop: "20%" }} src={this.state.noContentImg} />
                                                    <p style={{ margin: '10px' }}>{this.state.noContentMsg}</p>
                                                </div>
                                                    : <></>
                                            :
                                            <div>
                                                {this.state.gridData.length === 0 ?
                                                    !this.state.showDataLoader ? <div className="rmp-dashboard-empty-data-message" style={{ height: "67vh" }}>
                                                        <img style={{ height: '35%', marginTop: "20%" }} src={this.state.noContentImg} />
                                                        <p style={{ margin: '10px' }}>{this.state.noContentMsg}</p>
                                                    </div> : <></> :
                                                    <InfiniteScroll
                                                        className='scroll-container'
                                                        height={"67vh"}
                                                        dataLength={this.state.gridData.length} //This is important field to render the next data
                                                        next={() => {
                                                            if (this.state.selectedAllItems == 'projects') {
                                                                this.getProjects(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'tasks') {
                                                                this.getAllTasks(true)
                                                            }
                                                            else if (this.state.selectedAllItems == 'imagesets') {
                                                                this.getImageSets(true)
                                                            }
                                                        }}
                                                        hasMore={this.state.hasMoreItems}
                                                        loader={listLoader}
                                                    >
                                                        <table className="customers-table scroll-container" style={{ opacity: this.state.showDataLoader ? 0.5 : 1, marginTop: '0px' }}>
                                                            {this.state.gridData[0].type == 'Project' ? <>
                                                                <col style={{ width: '5%' }} />
                                                                <col style={{ width: '35%' }} />
                                                                <col style={{ width: '10%' }} />
                                                                <col style={{ width: '20%' }} />
                                                                <col style={{ width: '25%' }} />
                                                                <col style={{ width: '5%' }} /></>
                                                                : this.state.gridData[0].type == 'Imageset' ?
                                                                    <>
                                                                        <col style={{ width: '5%' }} />
                                                                        <col style={{ width: '40%' }} />
                                                                        <col style={{ width: '7%' }} />
                                                                        <col style={{ width: '20%' }} />
                                                                        <col style={{ width: '3%' }} />
                                                                    </> : this.state.gridData[0].type == 'Task' ?
                                                                        <>
                                                                            <col style={{ width: '20%' }} />
                                                                            <col style={{ width: '20%' }} />
                                                                            <col style={{ width: '20%' }} />
                                                                            <col style={{ width: '20%' }} />
                                                                            <col style={{ width: '20%' }} />
                                                                        </> : <></>}
                                                            <tr className="customers-table-header-row">
                                                                {this.state.tableHeaders.map((hd) => {
                                                                    return <th>
                                                                        {hd}
                                                                        {hd == "Name" ?
                                                                            <SortComponent
                                                                                style={{ marginLeft: '5px' }}

                                                                                sortAscending={this.sortByName}
                                                                                sortDecending={this.reverseByName}
                                                                                sortASC={false}
                                                                            /> : hd == 'Created at' ?
                                                                                <SortComponent
                                                                                    style={{ marginLeft: '5px' }}

                                                                                    sortAscending={this.sortByDate}
                                                                                    sortDecending={this.reverseByDate}
                                                                                    sortASC={false}
                                                                                /> : ''
                                                                        }
                                                                    </th>
                                                                })}
                                                            </tr>
                                                            {
                                                                this.state.gridData.map((data, key) =>
                                                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                                        <td>
                                                                            {
                                                                                data && data.thumbURL ?
                                                                                    <img id="ProjectTableImage" style={{ objectFit: 'cover', padding: 'unset' }} loading="true" src={data.thumbURL} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ProjectThumb }} /> :
                                                                                    <img id="ProjectTableImage" src={ProjectThumb} />
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                style={{ color: "#707683" }}
                                                                                to={data.link_to}
                                                                            >
                                                                                {this.state.gridData[0].type == 'Imageset' || this.state.gridData[0].type == 'Task' || this.state.gridData[0].type == 'Project' ?
                                                                                    <>
                                                                                        <span title={data?.classes?.length > 0 ?
                                                                                            data?.classes?.map(c => c?.name).join(",") : "No classes available"}>{`${getLimitedName(data.displayData[0], 35)}`}</span>
                                                                                        <span style={{ color: '#2989cf' }}> {data.sharedData.isShared ? '( shared )'
                                                                                            : <></>}
                                                                                        </span>
                                                                                    </> :
                                                                                    <>{getLimitedName(data.displayData[0], 20)} {data.role_name && <RoleNameComponent roleName={data.role_name} />} </>}
                                                                            </Link>
                                                                        </td>
                                                                        {
                                                                            data.displayData[1] ? this.state.gridData[0].type == 'Task' ?
                                                                                <td
                                                                                    // <div className="aerocapture-detection-type-list"
                                                                                    style={{
                                                                                        color: data.displayData[1] == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : data.displayData[1] == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : data.displayData[1] == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                                                        // background: data.displayData[1] == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : data.displayData[1] == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : data.displayData[1] == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                                                        // fontWeight: 400, margin: "2px 4px"
                                                                                    }}>
                                                                                    {data.displayData[1]}

                                                                                </td> :
                                                                                <td>{data.displayData[1]}</td>
                                                                                : ''
                                                                        }

                                                                        {
                                                                            data.displayData[2] ? this.state.gridData[0].type == 'Imageset' ? <></> :
                                                                                <td>{this.state.gridData[0].type == 'Project' ? data.tasks : this.state.gridData[0].type == 'Task' ? data.tasktype : getLimitedName(data.displayData[2], 14)}</td> : ''
                                                                        }
                                                                        {
                                                                            data.displayData[3] ?
                                                                                <td>{data.displayData[3]}</td> : ''
                                                                        }
                                                                        {data.type == 'Project' ? <td>{data.displayData[4] ? data.displayData[4].length > 20 ? `${data.displayData[4].substring(0, 20)}..` : data.displayData[4] : '-'}</td> : ''}
                                                                        {
                                                                            this.state.gridData[0].type != 'Task' ?
                                                                                <td>
                                                                                    {this.state.activeTeam?.isOwner && <DeleteComponentTable
                                                                                        style={{ marginRight: '10px' }}
                                                                                        canRename={this.state.selectedAllItems == 'projects'}
                                                                                        canManage={this.state.activeTeam.isOwner && this.state.selectedAllItems == 'projects' && !data.sharedData?.isShared}
                                                                                        onClickManage={() => {
                                                                                            this.setState({
                                                                                                selectedMangeAccessProject: data.id,
                                                                                                selectedProjectData: { pathname: data.link_to.pathname, state: { project: { ...data.link_to.state.project, link_to: undefined } } }
                                                                                            })
                                                                                        }}
                                                                                        onClickDelete={(e) => {
                                                                                            e.preventDefault()
                                                                                            if (this.state.selectedAllItems == 'maps') {
                                                                                                this.setState((s) => ({
                                                                                                    ...s,
                                                                                                    selectedMapCollectionId: data.id,
                                                                                                    itemName: "Map",
                                                                                                    processPopupIcon: "WARNING",
                                                                                                    processAction: "DELETE",
                                                                                                    processMessage: "Are you sure you want to delete this Map ? ",
                                                                                                    showProcessPopup: true,
                                                                                                }))
                                                                                            }
                                                                                            else if (this.state.selectedAllItems == 'projects') {
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    selectedProject: data,
                                                                                                    deleteItemType: "project"
                                                                                                }), () => {
                                                                                                    this.getAllDataToDelete()
                                                                                                })
                                                                                            }
                                                                                            else if (this.state.selectedAllItems == 'imagesets') {
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    selectedCollectionId: data.id,
                                                                                                    deleteItem: "IMAGESET",
                                                                                                    itemName: "Imageset",
                                                                                                    processPopupIcon: "WARNING",
                                                                                                    processAction: "DELETE",
                                                                                                    processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                                                                                        "\n Are you sure you want to delete this Imageset ?",
                                                                                                    showProcessPopup: true,
                                                                                                }))
                                                                                            }
                                                                                        }}

                                                                                        onClickRename={(e) => {
                                                                                            if (this.state.selectedAllItems == 'projects') {
                                                                                                e.preventDefault()
                                                                                                this.setState((state) => ({
                                                                                                    ...state,
                                                                                                    selectedProject: data,
                                                                                                    newProjectName: data.project_name,
                                                                                                    renameProjectPopup: true,
                                                                                                }))
                                                                                            }
                                                                                        }}
                                                                                    />}
                                                                                </td> : ''
                                                                        }
                                                                    </tr>
                                                                )}
                                                        </table>
                                                    </InfiniteScroll>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "38%", marginLeft: "2%", height: "72vh", filter: this.state.activeTeam.isOwner ? "" : "grayscale(100%)", opacity: this.state.activeTeam.isOwner ? "1" : "0.5" }}>

                                <div style={{ width: "100%", marginRight: "2%", height: '52%' }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "100%", display: "flex", }}>
                                            <div className="services-content-title">Objects detected</div>
                                            <span style={{ fontSize: "11px", color: "#2989cf", marginLeft: "5px", marginTop: "6px" }}>(This month)</span>
                                        </div>
                                    </div>

                                    {
                                        this.state.graphLoading || !this.state.activeTeam.isOwner ? this.state.chartData ?
                                            <div style={{
                                                height: "35vh", width: "100%", display: "flex", alignItems: 'center', justifyContent: "center", border: "1px solid #c2c2c2", borderRadius: "5px", marginTop: "15px", backgroundColor: "#FAFAFA"
                                            }}>
                                                <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '10%' }} />
                                            </div> : <div style={{
                                                height: "35vh", width: "100%", display: "flex", alignItems: 'center', justifyContent: "center", border: "1px solid #c2c2c2", borderRadius: "5px", marginTop: "15px", backgroundColor: "#FAFAFA"
                                            }}>
                                                No Data Available
                                            </div>
                                            :

                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    border: "1px solid #c2c2c2",
                                                    width: "100%",
                                                    height: '88%',
                                                    padding: "20px 20px 15px 5px",
                                                    marginTop: "13px",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#FAFAFA",
                                                }}
                                            >
                                                <Line
                                                    width={"55%"}
                                                    height={"20%"}
                                                    options={{
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        plugins: {
                                                            legend: {
                                                                display: false
                                                            }
                                                        },
                                                        elements: {
                                                            point: {
                                                                pointStyle: 'circle',
                                                                backgroundColor: "#000000",
                                                                borderColor: "#2989cf"
                                                            }
                                                        },
                                                        scales: {
                                                            x: {
                                                                barPercentage: 0.5,
                                                                axis: {
                                                                    color: "#2989cf",
                                                                },
                                                                grid: {
                                                                    borderColor: "#2989cf",
                                                                    display: true,
                                                                    drawOnChartArea: false,
                                                                    drawTicks: true,
                                                                    lineWidth: 1
                                                                },
                                                                ticks: {
                                                                    padding: 2,
                                                                    color: "black",
                                                                    display: true,
                                                                    fontSize: 11,
                                                                    font: {
                                                                        family: "Poppins",
                                                                        style: "normal"
                                                                    },
                                                                    color: "#666666",
                                                                    lineHeight: "auto"
                                                                },
                                                                title: {
                                                                    display: true,
                                                                    fontSize: 11,
                                                                    font: {
                                                                        family: "Poppins",
                                                                        style: "normal"
                                                                    },
                                                                    color: "#666666",
                                                                    text: "Days",
                                                                    borderColor: "#666666"
                                                                }
                                                            },
                                                            y: {
                                                                beginAtZero: true,
                                                                barPercentage: 0.5,
                                                                axis: {
                                                                },
                                                                grid: {
                                                                    borderColor: "#2989cf",
                                                                    value: "none",
                                                                    display: true,
                                                                    drawOnChartArea: false,
                                                                    drawTicks: false,
                                                                    lineWidth: 1
                                                                },
                                                                ticks: this.state.yTicks,
                                                                title: {
                                                                    fontSize: 11,
                                                                    font: {
                                                                        family: "Poppins",
                                                                        style: "normal"
                                                                    },
                                                                    color: "#666666",
                                                                    display: true,
                                                                    text: "Objects Detected"
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    data={this.state.chartData}
                                                />
                                            </div>

                                    }
                                </div>

                                <div style={{ width: "100%", marginTop: '5%', height: '47%' }}>
                                    <div style={{ display: "flex" }}>
                                        <div className="services-content-title">Task Analysis</div>
                                        <span style={{ fontSize: "11px", color: "#2989cf", marginLeft: "5px", marginTop: "7px" }}>(Till now)</span>
                                    </div>

                                    {this.state.pieLoading ?
                                        <div style={{ border: "1px solid #c2c2c2", display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: 'center', borderRadius: "5px", height: "82%" }}>
                                            <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '10%' }} />
                                        </div>
                                        :
                                        <div style={{
                                            border: "1px solid #c2c2c2",
                                            backgroundColor: "#FAFAFA",
                                            marginTop: "8px",
                                            // alignItems: "left",
                                            paddingLeft: "10px",
                                            borderRadius: "5px",
                                            paddingRight: this.state.taskCount > 0 ? "100px" : "15px",
                                            height: '81%',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative"
                                        }}>
                                            {
                                                this.state.taskCount > 0 && this.state.activeTeam.isOwner ? <>
                                                    <div style={{ width: '100%', height: '80%' }}>
                                                        <Pie
                                                            height={'100%'}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    legend: {
                                                                        display: true,
                                                                        position: "right",
                                                                        labels: {
                                                                            usePointStyle: true,
                                                                            pointStyle: "circle",
                                                                            padding: 15,
                                                                            font: {
                                                                                family: "Poppins"
                                                                            },
                                                                            color: '#666666',
                                                                            fontSize: 12,
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            data={this.state.pieData}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div></div>
                                                    </div>
                                                </>
                                                    :
                                                    <div className="rmp-dashboard-empty-data-message" >
                                                        <img style={{ height: '25%', width: '25%', padding: '0px', marginTop: '10px' }} src={NoTask} />
                                                        <p style={{ marginTop: '5px' }}>No Tasks Available</p>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div > :
                <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activesubscription: (payload) => dispatch(activesubscription(payload))
    };
};

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AeroCaptureDashboard);
