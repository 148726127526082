import React, { useEffect, useState } from 'react'
import * as API from "./api"
import service from "./sidebaricon/Services.png";
import Loader from './icons/loader.svg';
import PropertyCard from './AdminManageLocationComponents/PropertyCard';
import { Popup } from './ReusableComponents/reusableFunctions';
import AddDivision from './AdminManageLocationComponents/AddDivision';
import { AnimatedModal } from './ReusableComponents/AnimatedModal';
import AddCity from './AdminManageLocationComponents/AddCity';
import AddCityArea from './AdminManageLocationComponents/AddCityArea';
import DroneLabWrapper from './AeroFarm/droneLab/components/DroneLabWrapper';
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon';
import AddPropertyType from './AdminPropertyTypes/AddPropertyType';

const AdminManageLocations = (props) => {
    const [componentData, setComponentData] = useState({ user: "", type: "", sidebarMode: "", showBackIcon: true })
    const [loading, setLoading] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [divisionsLoading, setDivisionsLoading] = useState(false)
    const [cities, setCities] = useState([])
    const [citiesLoading, setCitiesLoading] = useState(false)
    const [areas, setAreas] = useState([])
    const [areasLoading, setAreasLoading] = useState(false)
    const [openAddDivisionPopup, setOpenAddDivisionPopup] = useState(false)
    const [openAddCityPopup, setOpenAddCityPopup] = useState(false)
    const [openAddAreaPopup, setOpenAddAreaPopup] = useState(false)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedDivisionForEdit, setSelectedDivisionForEdit] = useState(null)
    const [selectedCityForEdit, setSelectedCityForEdit] = useState(null)
    const [selectedAreaForEdit, setSelectedAreaForEdit] = useState(null)
    const [openPropertyTypePopup, setOpenPropertyTypePopup] = useState(false)
    let { user, type } = componentData

    let sidebarConfigurations = {

        options: [
            {
                icon: service,
                text: "Services",
                path: "/"
            },
        ],
        user,
        type,
        mode: componentData.sidebarMode,

    }

    const getDivisions = async () => {
        setDivisionsLoading(true)
        try {
            const result = await API.getAllDivisions()
            setDivisions(result?.divisions || [])
            setDivisionsLoading(false)
        } catch (err) {
            setDivisionsLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    const deleteDivision = async (division_id) => {
        setDivisionsLoading(true)
        try {
            const div = await API.deleteDivisionAPI(division_id)
            setDivisions(prev => prev.filter(d => d?.id !== div?.deletedDivision?.id))
            setCities(prev => prev.filter(c => !div?.deletedCities.some(dc => dc?.id === c?.id)))
            setAreas(prev => prev.filter(a => !div?.deletedAreas.some(da => da?.id === a?.id)))
            setSelectedDivision(null)
            setSelectedCity(null)
            setDivisionsLoading(false)
            Popup.alert("COMPLETE", "Division deleted successfully")
        } catch (err) {
            console.log(err)
            setDivisionsLoading(false)
            Popup.alert("ERROR", err?.message || err)
        }
    }

    const getCitiesOfDivisionFunc = async (division_id) => {
        setCitiesLoading(true)
        try {
            const result = await API.getCitiesOfDivision(division_id)
            setCities(result?.cities || [])
            setCitiesLoading(false)
        } catch (err) {
            setCitiesLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    const deleteCity = async (city_id) => {
        try {
            const city = await API.deleteCityAPI(city_id)
            setCities(prev => prev.filter(c => c?.id !== city?.deletedCity?.id))
            setAreas(prev => prev.filter(a => !city?.deletedAreas.some(da => da?.id === a?.id)))
            setSelectedCity(null)
            setLoading(false)
            Popup.alert("COMPLETE", "City deleted successfully")
        } catch (err) {
            console.log(err)
            setLoading(false)
            Popup.alert("ERROR", err?.message || err)
        }
    }

    const getAreasOfCityFunc = async (city_id, division_id) => {
        setAreasLoading(true)
        try {
            const result = await API.getAreasOfCity(city_id, division_id)
            setAreas(result?.city_areas || [])
            setAreasLoading(false)
        } catch (err) {
            setAreasLoading(false)
            Popup.alert("ERROR", err)
        }
    }

    const deleteArea = async (area_id) => {
        try {
            const area = await API.deleteAreaAPI(area_id)
            setAreas(prev => prev.filter(a => a?.id !== area?.deletedArea?.id))
            setLoading(false)
            Popup.alert("COMPLETE", "Area deleted successfully")
        } catch (err) {
            console.log(err)
            setLoading(false)
            Popup.alert("ERROR", err?.message || err)
        }
    }

    const handleSelectDivision = (division) => {
        if (selectedDivision?.id !== division.id) {
            getCitiesOfDivisionFunc(division.id)
            setAreas([])
            setSelectedDivision(division)
            setSelectedCity(null)
            setDivisions(prev => {
                return prev.map(d => {
                    if (d.id == division.id) return { ...d, selected: true }
                    else return { ...d, selected: false }
                })
            })
        }
    }

    const handleSelectCity = (city) => {
        if (city.id !== selectedCity?.id) {
            getAreasOfCityFunc(city.id)
            setSelectedCity(city)
            setCities(prev => {
                return prev.map(d => {
                    if (d.id == city.id) return { ...d, selected: true }
                    else return { ...d, selected: false }
                })
            })
        }
    }

    useEffect(() => {
        setLoading(true)
        if (props.location.state) {
            let { user, type, sidebarMode } = props.location.state;

            setComponentData({
                ...componentData,
                user,
                type, sidebarMode
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {

                setComponentData({
                    ...componentData,
                    user,
                    type
                })


            }, (e) => {
                window.location.replace("/login");
            })
        }
        getDivisions()
        getCitiesOfDivisionFunc()
        getAreasOfCityFunc()
        setLoading(false)
    }, [])

    return (
        <DroneLabWrapper header={""} sidebarConfig={sidebarConfigurations}>
            {openAddDivisionPopup &&
                <AnimatedModal isOpen={openAddDivisionPopup} width="700px" height="700px" onRequestClose={() => setOpenAddDivisionPopup(false)}>
                    <AddDivision
                        selectedDivision={selectedDivisionForEdit}
                        checkIfDuplicate={(division) => {
                            return (divisions.find(d => d.name.toLowerCase() == division.name.toLowerCase()))
                        }}
                        onRequestClose={() => setOpenAddDivisionPopup(false)}
                        onDivisionAdded={(division) => {
                            setDivisions(prev => [...prev, division])
                        }}
                        onDivisionEdited={(division) => {
                            setDivisions(prev => prev.map(d => {
                                if (d.id == division.id) return division;
                                else return d;
                            }))
                            setSelectedDivisionForEdit(null)
                        }}
                    />
                </AnimatedModal>}

            {openAddCityPopup &&
                <AnimatedModal isOpen={openAddCityPopup} width="700px" height="700px" onRequestClose={() => setOpenAddCityPopup(false)}>
                    <AddCity
                        selectedCity={selectedCityForEdit}
                        division_id={selectedDivision?.id}
                        checkIfDuplicate={(city) => {
                            return (cities.find(d => d.name.toLowerCase() == city.name.toLowerCase()))
                        }}
                        onCityAdded={(city) => {
                            setCities(prev => ([...prev, city]))
                        }}
                        onCityEdited={(city) => {
                            setCities(prev => prev.map(c => {
                                if (c.id == city.id) return city;
                                else return c;
                            }))
                            setSelectedCityForEdit(null)
                        }}
                        onRequestClose={() => setOpenAddCityPopup(false)}
                    />
                </AnimatedModal>}

            {openAddAreaPopup &&
                <AnimatedModal isOpen={openAddAreaPopup} width="700px" height="700px" onRequestClose={() => setOpenAddAreaPopup(false)}>
                    <AddCityArea
                        selectedArea={selectedAreaForEdit}
                        division_id={selectedDivision?.id}
                        checkIfDuplicate={(area) => {
                            return (areas.find(d => d.name.toLowerCase() == area.name.toLowerCase()))
                        }}
                        city_id={selectedCity?.id}
                        onCityAreaAdded={async (area) => {
                            setAreas(prev => [...prev, area])
                        }}
                        onCityAreaEdited={async (area) => {
                            setAreas(prev => prev.map(ca => {
                                if (ca.id == area.id) return area;
                                else return ca;
                            }))
                            setSelectedAreaForEdit(null)
                        }}
                        onRequestClose={() => setOpenAddAreaPopup(false)}
                    />
                </AnimatedModal>
            }
            {openPropertyTypePopup &&
                <AddPropertyType openPropertyTypePopup={openPropertyTypePopup} onRequestClose={() => setOpenPropertyTypePopup(false)} />
            }
            {loading ?
                <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                <div style={{ width: "100%", height: "calc(100% - 60px)", padding: "10px 65px", fontFamily: 'Lato', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingTop: "10px" }}>
                        <ButtonWithIcon
                            isBtnActive
                            fontSize={'12px'}
                            width={"137px"}
                            text={"Add Property Type"}
                            isDisabled={false} onClick={() => {
                                setOpenPropertyTypePopup(true)
                            }}
                        />
                    </div>
                    <div style={{ fontSize: '22px', padding: '25px 0px', display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                        <span>Manage Locations</span>
                        {selectedDivision && <span onClick={() => {
                            setSelectedDivision(null)
                            setSelectedCity(null)
                            setSelectedAreaForEdit(null)
                            setSelectedCityForEdit(null)
                            setSelectedDivisionForEdit(null)
                            getDivisions()
                            getCitiesOfDivisionFunc()
                            getAreasOfCityFunc()
                        }} style={{ cursor: 'pointer', textDecoration: "underline", color: "#2989cf", fontSize: "16px", marginLeft: "20px" }}>Clear Filters</span>}
                    </div>
                    <div style={{ display: "flex", gap: "55px", height: "calc(100% - 90px)" }}>
                        <PropertyCard
                            id={"Division"}
                            loading={divisionsLoading}
                            header={`Divisions (${divisions.length})`}
                            onClickItem={handleSelectDivision}
                            onDeleteItem={(id) => deleteDivision(id)}
                            onEditItem={(division) => {
                                setSelectedDivisionForEdit(division)
                                setOpenAddDivisionPopup(true)
                            }}
                            onClickAdd={() => setOpenAddDivisionPopup(true)}
                            selected={selectedDivision?.id}
                            list={divisions}
                        />
                        <PropertyCard
                            id={"City"}
                            loading={citiesLoading}
                            header={selectedDivision ? `Cities - ${selectedDivision.name}` : `Cities (${cities?.length})`}
                            onClickItem={handleSelectCity}
                            onEditItem={(city) => {
                                setSelectedCityForEdit(city)
                                setOpenAddCityPopup(true)
                            }}
                            onDeleteItem={(id) => { deleteCity(id) }}
                            onClickAdd={() => setOpenAddCityPopup(true)}
                            selected={selectedCity?.id}
                            list={cities}
                        />
                        <PropertyCard
                            id={"Area"}
                            loading={areasLoading}
                            header={selectedCity ? `City Areas - ${selectedCity.name}` : `City Areas (${areas.length})`}
                            onDeleteItem={(id) => { deleteArea(id) }}
                            onClickAdd={() => setOpenAddAreaPopup(true)}
                            onEditItem={(area) => {
                                const city = cities.find(c => c.id == area.city_id)
                                area.city = city;
                                setSelectedAreaForEdit(area)
                                setOpenAddAreaPopup(true)
                            }}
                            list={areas}
                        />
                    </div>
                </div>
            }
        </DroneLabWrapper>
    )
}

export default AdminManageLocations