import React, { useEffect, useState } from 'react'
import SingleMap from '../../../ReusableComponents/map';
import L from "leaflet";
import MarkerIcon from '../../../assets/marker.png'
import { MAP_URLS } from '../../../AppConstants';

let mapInState = undefined
let mapMarker = undefined
let polygon
const PropertyMap = ({ coordinates }) => {
    const handleMapClick = (e) => {
    }

    const initMap = (map) => {
    }



    const drawPolygon = (coordinates) => {
        polygon = L.polygon(coordinates, { color: 'red' }).addTo(mapInState);
        mapInState.fitBounds(polygon.getBounds())
    }


    useEffect(() => {
        if (polygon) {
            mapInState.removeLayer(polygon)
        }
        if (mapInState && coordinates.length > 0) {
            drawPolygon(coordinates);
        }
    }, [coordinates.length]);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <SingleMap
                initCenter={[22.2587, 71.1924]}
                initZoom={28}
                handleMapClick={handleMapClick}
                maxZoom={23}
                setBaseLayerToState={(base) => {
                    base.setUrl(MAP_URLS.ROAD_MAP)
                }}
                setMap={(map) => {
                    mapInState = map;
                    initMap(map)
                }}
                bounds={[
                    70.8178711,
                    22.1467078,
                    70.8068848,
                    21.9787076
                ]}
            />
        </div>
    )
}

export default PropertyMap