import React, { useEffect, useState } from 'react'
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import LoaderComponent from '../ReusableComponents/LoaderComponent'
import Menu from "../icons/MenuDark.png"
import Plus from "../assets/Icons/whiteplus.png"
import Tippy from '@tippyjs/react'
import Delete from '../icons/deleteIcon.svg';
import View from '../icons/open.svg';
import Edit from "../icons/Edit.svg"
import MapViewPopup from './MapViewPopup'

const PropertyCard = ({ id, header, onClickAdd, list, onClickItem, loading, onDeleteItem, onEditItem, selected }) => {
    const [permissions, setPermissions] = useState({ delete: true, view: true, edit: true })
    useEffect(() => {
        let perms = window.localStorage.getItem("ghb_permissions")
        if (perms) {
            setPermissions(prev => ({ ...prev, ...JSON.parse(perms) }))
        }
    }, [])
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", border: "1px solid #C3CDD9", borderRadius: "8px", overflow: 'hidden', maxHeight: "700px", height: "100%" }}>
            <div style={{ background: '#F4F4F4', borderBottom: "1px solid #C3CDD9", padding: "30px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span style={{ fontSize: '19px' }}>{header}</span>
                <button onClick={onClickAdd} style={{ background: "#2989cf", borderRadius: "50%", width: "32px", height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ height: '16px' }} src={Plus} /></button>
            </div>
            <div className={`scroll-container-${id}`} style={{ height: "100%", overflowY: 'auto', background: '#F9F9F9' }}>
                {loading ?
                    <LoaderComponent message={" "} />
                    :
                    list.length == 0 ?
                        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <span style={{ color: "#666666" }}> No {id} Available.</span>
                        </div>
                        :
                        list.map(item => <PropertyCardListItem
                            selected={selected}
                            item={item}
                            onClickItem={onClickItem}
                            onDeleteItem={onDeleteItem}
                            onEditItem={onEditItem}
                            permissions={permissions} />)}
            </div>
        </div>
    )
}
const PropertyCardListItem = ({ permissions, onClickItem, item, onDeleteItem, onEditItem, selected }) => {
    const [open, setOpen] = useState(false)
    const [mapView, setMapView] = useState(false)
    return <>
        {mapView?.show &&
            <MapViewPopup
                asset={mapView.asset}
                onRequestClose={() => setMapView(false)}
            />}
        <div onClick={(e) => {
            e.stopPropagation()
            if (onClickItem) {
                onClickItem(item)
            }
        }} className={`ghb-list-item  ${(onClickItem && item.id == selected) ? 'ghb-list-selected' : "ghb-list-non-hover"} ${onClickItem && 'ghb-list-hover'}`} style={{ display: "flex", alignItems: "center", padding: "15px 30px", justifyContent: "space-between" }}>
            <span >{item.name}</span>
            {<Tippy
                visible={open}
                onClickOutside={() => setOpen(false)}
                animation={false}
                interactive
                onShown={(instance) => {
                    document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                        instance.hide();
                        setOpen(false)
                    })
                }}
                onShow={instance => {
                    const el1 = document.querySelector(".scroll-container-Area")
                    if (el1)
                        el1.addEventListener('scroll', event => {
                            instance.hide()
                            setOpen(false)
                        })
                    const el2 = document.querySelector(".scroll-container-City")
                    if (el2)
                        el2.addEventListener('scroll', event => {
                            instance.hide()
                            setOpen(false)
                        })
                    const el3 = document.querySelector(".scroll-container-Divsion")
                    if (el3)
                        el3.addEventListener('scroll', event => {
                            instance.hide()
                            setOpen(false)
                        })
                }}
                className='tippy-menu'
                arrow={false}
                offset={[-40, -60]}
                content={
                    <div size="sm" title="" className="custom-dropdown-dashboard">
                        {permissions.edit ?
                            <div eventKey="download" className=" tippy-menu-hover" style={{ padding: "5px 10px" }}>
                                <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-around", cursor: permissions.edit ? 'pointer' : 'not-allowed', opacity: permissions.edit ? 1 : 0.5 }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setOpen(false)
                                        onEditItem(item)
                                    }}>
                                    <div style={{ marginRight: '7px', color: '#3c3c3c', fontSize: "13px", fontWeight: '500' }}>Edit</div>
                                    <img src={Edit} style={{ height: "12px" }} />
                                </div>
                            </div>
                            : <></>}

                        {permissions.delete && <div eventKey="delete" className=" tippy-menu-hover" style={{ padding: "5px 10px", borderTop: '1px solid rgb(60 60 60 / 26%)' }}>
                            <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-around", cursor: permissions.delete ? 'pointer' : 'not-allowed', opacity: permissions.delete ? 1 : 0.5 }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setOpen(false)
                                    onDeleteItem(item.id)
                                }}>
                                <div style={{ marginRight: '7px', color: '#3c3c3c', fontSize: "13px", fontWeight: '500' }}>Delete</div>
                                <img src={Delete} style={{ height: "12px" }} />
                            </div>
                        </div>}
                        {permissions.view && item.coordinates ?
                            <div eventKey="view" className=" tippy-menu-hover" style={{ padding: "5px 10px", borderTop: '1px solid rgb(60 60 60 / 26%)' }}>
                                <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-around", cursor: permissions.view ? 'pointer' : 'not-allowed', opacity: permissions.view ? 1 : 0.5 }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setOpen(false)
                                        setMapView({ show: true, asset: item })
                                    }}>
                                    <div style={{ marginRight: '7px', color: '#3c3c3c', fontSize: "13px", fontWeight: '500' }}>View</div>
                                    <img src={View} style={{ height: "12px" }} />
                                </div>
                            </div>
                            : <></>}
                    </div>
                }
            >
                <img onClick={(e) => {
                    e.stopPropagation()
                    setOpen(prev => !prev)
                }} style={{ height: "20px", cursor: 'pointer' }} src={Menu} />
            </Tippy>}
        </div>
    </>
}
export default PropertyCard
