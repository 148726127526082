import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SideBar extends Component {
    state = {
        sidebarOpen:
            this.props.location == undefined
                ? false
                : this.props.location.state.sidebarOpen,
        selected: "service",
        user: {},
        type: "",
        services: [],
        avatarURL: "",
        config: this.props.config,
    };

    componentWillMount() {
        let { config } = this.props;
        let { user, type } = config;
        if (user.avatar_url && user.avatar_url.length > 0) {
            this.setState((state) => ({
                ...state,
                avatarURL: `${user.avatar_url}?${user.sas_token
                    }&t${new Date().getTime()}`,
            }));
        } else {
            let avatarURL = `/images/${user.first_name?.[0].toLowerCase()}.PNG`;
            this.setState((state) => ({
                ...state,
                avatarURL: avatarURL,
            }));
        }
    }

    toggleSidebarMode = () => {
        this.setState((state) => ({
            ...state,
            config: {
                ...state.config,
                mode: state.config.mode == "shrunk" ? "expanded" : "shrunk",
            },
        }));
        this.props.parentSidebarSwitch();
    };

    render() {
        let { config } = this.props;
        let { user, type } = config;
        config.mode = "sidebar-expanded";

        return (
            <div
                className={
                    config.mode == "shrunk" ? "ghb-sidebar-shrunk" : "ghb-sidebar-expanded"
                }
                style={{ paddingTop: "0px", backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'white', height: this.props.height || "" }}
            >
                <ul className="ghb-sidebar-nav" id="ghb-sidebar-nav" style={{ padding: '20px 1px 1px 1px' }}>
                    {config.options
                        .filter((el) => {
                            return el.text !== "Help";
                        })
                        .map((el) => {
                            if (el.text != "hr") {
                                el.text = el.text.replace(/\b[a-z]/g, (match) =>
                                    match.toUpperCase()
                                );
                            }
                            return el;
                        })
                        .map((item) => {
                            return item.text == "hr" ? (
                                <hr
                                    className={
                                        config.mode == "shrunk"
                                            ? "ghb-sidebar-shrunk-separator"
                                            : "ghb-sidebar-expanded-separator"
                                    }
                                />
                            ) : (
                                <div
                                    style={{ borderLeft: `solid 4px ${item.selected ? this.props.borderColor ? this.props.borderColor : '#2989CF' : 'transparent'}` }}
                                >
                                    <li>
                                        {item.text == "Back" ? (
                                            <div
                                                className="ghb-sidecontent"
                                                id="ghb-sidecontent"
                                                onClick={() => {
                                                    window.history.back();
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="ghb-sidebar-icon">
                                                    <img
                                                        className="ghb-sidebar-icon"
                                                        src={item.icon}
                                                        alt="serviceIcon"
                                                    />
                                                </div>
                                                {item.selected ? (
                                                    <div
                                                        name="service"
                                                        className={
                                                            config.mode == "shrunk"
                                                                ? "ghb-sidebaropt-hidden"
                                                                : "ghb-sidebaropt"
                                                        }
                                                        style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF", fontSize: '600' }}
                                                        onClick={this.changeColor}
                                                    >
                                                        {item.text}
                                                    </div>
                                                ) : (
                                                    <div style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c', fontSize: '600' }}
                                                        name="service"
                                                        className={
                                                            config.mode == "shrunk"
                                                                ? "ghb-sidebaropt-hidden"
                                                                : "ghb-sidebaropt"
                                                        }
                                                        onClick={this.changeColor}
                                                    >
                                                        {item.text}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <Link
                                                to={{
                                                    pathname: item.path,
                                                    state: {
                                                        config,
                                                        sidebarMode: config.mode,
                                                        user,
                                                        type,
                                                    },
                                                }}
                                            >
                                                <div className="ghb-sidecontent" id="ghb-sidecontent">
                                                    <div className="ghb-sidebar-icon">
                                                        <img
                                                            className="ghb-sidebar-icon"
                                                            src={item.icon}
                                                            alt="serviceIcon"
                                                        />
                                                    </div>
                                                    {item.selected ? (
                                                        <div
                                                            name="service"
                                                            className={
                                                                config.mode == "shrunk"
                                                                    ? "ghb-sidebaropt-hidden"
                                                                    : "ghb-sidebaropt"
                                                            }
                                                            style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF" }}
                                                            onClick={this.changeColor}
                                                        >
                                                            {item.text}
                                                        </div>
                                                    ) : (
                                                        <div style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c' }}
                                                            name="service"
                                                            className={
                                                                config.mode == "shrunk"
                                                                    ? "ghb-sidebaropt-hidden"
                                                                    : "ghb-sidebaropt"
                                                            }
                                                            onClick={this.changeColor}
                                                        >
                                                            {item.text}
                                                        </div>
                                                    )}
                                                </div>
                                            </Link>
                                        )}
                                    </li>

                                </div>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}