import React, { useEffect, useState } from 'react'
import FileImporter from '../ReusableComponents/FileImporter'
import { addDivisionAPI, convertKMLToGeoJSON, updateDivisionAPI } from '../api'
import PropertyMap from "../AeroCapture/Components/ReusableComp/PropertyMap"
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { Popup } from '../ReusableComponents/reusableFunctions'
import Loader from '../icons/loader.svg'
import Close from "../icons/blackClose.png"
import LoaderComponent from '../ReusableComponents/LoaderComponent'
const AddDivision = ({ selectedDivision, onDivisionAdded, onDivisionEdited, onRequestClose, checkIfDuplicate }) => {
    const [division, setDivision] = useState({ id: "", name: "", description: "", coordinates: null })
    const [mapCoordinates, setMapCoordinates] = useState([])
    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    useEffect(() => {
        if (selectedDivision) {
            setIsEditing(true)
            let coordinates = selectedDivision.coordinates
            if (typeof coordinates == "string") {
                coordinates = JSON.parse(selectedDivision.coordinates)
            }
            setDivision({
                id: selectedDivision.id,
                name: selectedDivision.name,
                description: selectedDivision.description,
                coordinates
            })
            setMapCoordinates(coordinates)
        }
    }, [selectedDivision])
    const handleImportedFile = async (file) => {
        try {
            if (file) {

                const geoJson = await convertKMLToGeoJSON(file)
                if (geoJson) {
                    const coords = geoJson.features.find(f => f.geometry?.type == "Polygon").geometry.coordinates[0].map(c => [c[0], c[1]])
                    setDivision(prev => ({ ...prev, coordinates: coords }))
                    setMapCoordinates(coords.map(c => c.reverse()))
                }
            } else {
                setMapCoordinates([])
                setDivision(prev => ({ ...prev, coordinates: null }))
            }
        } catch (err) {
            console.log(err)
        }
    }
    const handleAddDivision = async () => {
        setLoading(true)
        try {
            if (checkIfDuplicate(division)) {
                setLoading(false)
                Popup.alert("ERROR", "Division already exists")
            } else {
                const div = await addDivisionAPI(division)
                onDivisionAdded(div)
                setLoading(false)
                Popup.alert("COMPLETE", "Division added successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
            Popup.alert("ERROR", err)
        }
    }
    const handleEditDivision = async () => {
        setLoading(true)
        try {
            if (selectedDivision.name !== division.name && checkIfDuplicate(division)) {
                setLoading(false)
                Popup.alert("ERROR", "Division already exists")
            } else {
                const div = await updateDivisionAPI(division)
                onDivisionEdited(division)
                setLoading(false)
                Popup.alert("COMPLETE", "Division updated successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
            Popup.alert("ERROR", err)
        }
    }
    return (

        <div style={{ position: 'relative', width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", gap: "10px", position: "relative" }}>
            <div style={{ padding: "20px", textAlign: 'left', display: 'flex', alignItems: "center", justifyContent: 'space-between', borderBottom: '1px solid #C3CDD9' }}>
                <span>{isEditing ? "Edit" : "Add"} Division</span>
                <img onClick={onRequestClose} src={Close} style={{ cursor: 'pointer', height: '25px' }} />
            </div>
            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: "column", gap: "10px", padding: "20px", position: "relative" }}>
                {loading &&
                    <LoaderComponent message={" "} background={"rgb(0 0 0 / 7%)"} position={"absolute"} zIndex={"99"} style={{ left: "0", top: '-10px' }} />
                }
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Name of Division</label>
                    <input
                        value={division.name}
                        placeholder='Enter Division Name'
                        style={{ outline: "none", width: "50%", borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                        type='text' onChange={e => {
                            e.persist()
                            setDivision(prev => ({ ...prev, name: e.target.value }))
                        }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Description <span style={{ color: "#666666" }}>(Optional)</span></label>
                    <textarea
                        value={division.description}
                        style={{ outline: "none", width: "100%", height: "70px", resize: 'none', borderRadius: "8px", border: "1px solid #C3CDD9", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                        placeholder='Type here...'
                        onChange={e => {
                            e.persist()
                            setDivision(prev => ({ ...prev, description: e.target.value }))
                        }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <FileImporter
                        placeholder={"Upload your KML File"}
                        accept={[".kml", "application/vnd.google-earth.kml+xml"]}
                        onImportFile={file => {
                            handleImportedFile(file)
                        }} />
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    <PropertyMap coordinates={mapCoordinates} />
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: "50px", right: "50px", zIndex: 99 }}>
                <ButtonWithIcon isBtnActive text={isEditing ? "Update" : "Add"} fontSize={'14px'} width={"115px"} isDisabled={!division.name || !division.coordinates} onClick={() => {
                    if (isEditing) {
                        handleEditDivision()
                    } else {
                        handleAddDivision()
                    }
                }} />
            </div>
        </div>
    )
}

export default AddDivision
