import {
    UPDATE_PROFILE,
    UPDATE_SUBSCRIPTION_DATA,
    ACTIVE_SUBSCRIPTION
} from './profileTypes'

const teamState = {
    activeTeamId: undefined,
    activeTeam: undefined,
    user: '',
    type: '',
    isLoggedInUserOwner: false,
    firstTimeLoginGCSE : '',
    firstTimeLoginDN : '',
    firstTimeLoginAC : '',
    isAdmin: false
};

const subscriptionState = {
    subscriptionData: undefined,
}

const activeSubscriptionState = {
    activesubscription: undefined
}

export const profileReducer = (state = teamState, action) => {
    switch (action.type) {
        case UPDATE_PROFILE:
            return {
                ...state,
                activeTeamId: action.payload.activeTeamId || state.activeTeamId,
                activeTeam: action.payload.activeTeam || state.activeTeam ,
                user: action.payload.user ? action.payload.user : state.user,
                type: action.payload.type ? action.payload.user : state.type,
                isLoggedInUserOwner: action.payload.isLoggedInUserOwner,
                firstTimeLoginGCSE:action.payload.firstTimeLoginGCSE ? action.payload.firstTimeLoginGCSE : state.firstTimeLoginGCSE,
                firstTimeLoginDN:action.payload.firstTimeLoginDN ? action.payload.firstTimeLoginDN : state.firstTimeLoginDN,
                firstTimeLoginAC:action.payload.firstTimeLoginAC ? action.payload.firstTimeLoginAC : state.firstTimeLoginAC,
                isAdmin:action.payload.isAdmin ? action.payload.isAdmin : state.isAdmin
            };
        default:
            return state;
    }
};


export const subscriptionReducer = (state = subscriptionState, action) => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_DATA:
            return {
                ...state,
                subscriptionData : action.payload.subscriptionData
            };
        default:
            return state;
    }
};

export const activeSubscriptionReducer = ( state = activeSubscriptionState, action) => {
    switch (action.type) {
            case ACTIVE_SUBSCRIPTION:
                return {
                    ...state,
                    activesubscription : action.payload.activesubscription
                };
        default:
            return state;
    }

}
