import React from 'react';
import { useRecoilState } from 'recoil';
import { toggleLayer } from '../Recoil/atom';

const CheckboxHeader = ({ label, checked, onChange }) => {
    const [toggleLayerS, setToggleLayerS] = useRecoilState(toggleLayer)
    const headerStyle = {
        color: "#3c3c3c",
        fontSize: "11px",
        padding: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "start"
    };

    const checkboxStyle = {
        cursor: 'pointer',
        height: "15px",
        width: "15px"
    };

    const labelStyle = {
        marginLeft: "10px",
        minWidth: "55px",
        backgroundColor: 'white',
        fontSize: "10px",
        width: "100%",
        fontWeight: "400",
        wordWrap: "break-word"
    };

    return (
        <div style={headerStyle}>
            <input
                type="checkbox"
                style={checkboxStyle}
                checked={checked}
                onChange={(e) => {
                    setToggleLayerS(!toggleLayerS)
                    onChange(e)
                }}
            />
            <div style={labelStyle}>{label}</div>
        </div>
    );
};

export default CheckboxHeader;
