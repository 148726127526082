import React, { createRef, useEffect, useRef, useState } from 'react'
import { PotreeClass } from './PotreeClass';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import PotreeMenu from './PotreeMenu';
import { ClipTask } from './Threed/Constants';
let p;
const GHBPotreeViewer = ({ selectedProperty, potreeCallback,setVolumeBoxDrawn }) => {
    const clipTaskKeys = Object.keys(ClipTask)
    const potreeContainerDivRef = useRef()
    const [potree, setPotree] = useState()
    const [loading, setLoading] = useState(true)
    const [cursor, setCursor] = useState('default')
    const [measurementMenu, setMeasurementMenu] = useState(false)
    const [showVolumeBoxOptions, setShowVolumeBoxOptions] = useState(false)
    const [selectedClipTask, setSelectedClipTask] = useState('Highlight')
    useEffect(() => {
        if (selectedProperty) {
            const { task_id, sas_token, blob_container } = selectedProperty
            p = new PotreeClass({ potreeContainerDivRef, blob_container, sas_token, task_id, setLoading, setCursor, setMeasurementMenu, setShowVolumeBoxOptions, setSelectedClipTask,setVolumeBoxDrawn })
            p.addLibraries()
            setPotree(p)
            potreeCallback(p)
        }

    }, [])
    return potree ? <>
        {loading && <LoaderComponent message={" "} height="100%" width="100%" />}
        <div ref={potreeContainerDivRef} style={{ height: "100vh", cursor, width: '100vw' }} className={"potree_container "}>
            {measurementMenu && <PotreeMenu
                measurement={measurementMenu}
                closePopup={() => setMeasurementMenu(false)}
                deleteMeasurement={() => {
                    potree.deleteMeasurement(measurementMenu.id)
                    setMeasurementMenu(false)
                }} />}

        </div >
        {showVolumeBoxOptions ? <div
            className={"hoverPointer AeroFarm"}
            style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                bottom: '15px',
                color: "#3C3C3C",
                fontWeight: "500",
                bottom: '6%',
            }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    borderRadius: "10px",
                    width: clipTaskKeys.length * 70 + "px"
                }}
            >
                {clipTaskKeys.map((ct, key) => {
                    return <div
                        style={{
                            width: "70px",
                            borderRadius: key === 0 ? "5px 0px 0px 5px" : (
                                key == clipTaskKeys.length - 1 ? "0px 5px 5px 0px" :
                                    "0px 0px 0px 0px"
                            ),
                            textAlign: "center",
                            margin: 'auto',
                            borderWidth: "1px 0.5px 1px 1px",
                            borderStyle: "solid",
                            borderColor: 'rgb(102, 102, 102, 0.3)',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        data-tip data-for={ct}
                        onClick={() => {
                            potree.changeClipTask(ct)
                        }}
                        className={selectedClipTask === ct ? "toggle-layer-active-buttons-ghb" : "toggle-layer-inactive-buttons"}
                    >
                        <span style={{ color: selectedClipTask === ct ? "#FFFFFF" : "#3C3C3C" }}>{ct}</span>
                        {/* <img width='17px' height="17px" src={icon} /> */}
                        {/* <ReactTooltip border='true' borderColor="rgb(102, 102, 102, 0.3)" className="tooltipclass" offset={{ top: 0 }} id={clipTaskName} place="top" type="light" effect="solid"><span style={{ fontSize: '14px', padding: '0px', margin: '0px', zIndex: '3', position: 'relative' }}>{clipTaskName.replace("_", " ")}</span></ReactTooltip> */}
                        {/* <div style={{ marginLeft: "5px" }}></div> */}
                    </div>
                })}

            </div>
        </div> : <></>}
    </> : <>Loading...</>
}

export default GHBPotreeViewer
