import React, { useEffect, useState } from "react";
import AppConstants from "../../../AppConstants.js";
import FilesContainer from "./FilesContainer.js";
import * as API from '../../../api.js'
import { Popup } from "../../../ReusableComponents/reusableFunctions.js";
import ActionButton from "./ActionButton.js";
import upload from "../../assets/icons/UploadSimple.svg"
import DisplayField from "./DisplayField.js";
import pencilDash from "../../assets/icons/pencilDash.svg"
import LoaderComponent from "../../../ReusableComponents/LoaderComponent.js";
import { filterFiles } from "./ReuseableFunctions.js";
import PropertyAccessPopup from "./PropertyAccessPopup.js";

const PropertyDetailsView = ({ onEdit, property }) => {
    const [propertyReport, setPropertyReport] = useState([])
    const [propertyFile, setPropertyFile] = useState([])
    const [propertyBlockDetails, setPropertyBlockDetails] = useState([])
    const [propertyReportLoading, setpropertyReportLoading] = useState(false)
    const [propertyFilesLoading, setpropertyFilesLoading] = useState(false)
    const [blockDetailsLoading, setblockDetailsLoading] = useState(false);
    const [openManageAccessPopup, setOpenManageAccessPopup] = useState(false)

    const getUploadedFiles = (fileType) => {
        if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
            setpropertyReportLoading(true);
        }
        else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
            setpropertyFilesLoading(true);
        }
        else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
            setblockDetailsLoading(true);
        }
        API.getFiles(AppConstants.DRONELAB.ASSET_TYPES.PROPERTY, fileType, property.id)
            .then((res) => {
                if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {

                    setPropertyReport(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
                    setPropertyFile(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
                    setPropertyBlockDetails(res.assetFiles)
                }
            }).finally(() => {
                setpropertyReportLoading(false);
                setpropertyFilesLoading(false);
                setblockDetailsLoading(false);
            })
            .catch((e) => {
                Popup.alert("ERROR", e.message || e)
            })
    }

    const getOnMountUploadedFiles = () => {
        for (const key in AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES) {
            getUploadedFiles(AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES[key])
        }
    }

    const uploadAssetFiles = async (assets) => {
        return new Promise(async (resolve, reject) => {
            if (assets.length > 0) {
                Popup.alert("WAIT", "Uploading file, please wait...")
                if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
                    setpropertyReportLoading(true);
                }
                else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
                    setpropertyFilesLoading(true);
                }
                else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
                    setblockDetailsLoading(true);
                }
                try {
                    for (const asset of assets) {
                        const fileType = asset.file_type
                        const assetType = asset.asset_type
                        const file = asset?.files
                        if (file) {
                            try {
                                await API.uploadFile(file, assetType, fileType, property.id)
                            }
                            catch (e) {
                                throw new Error(e.message || e)
                            }
                        }
                    }
                    if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
                        setPropertyReport([
                            ...propertyReport,
                            ...assets
                        ])
                    } else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
                        setPropertyFile([
                            ...propertyFile,
                            ...assets
                        ])
                    }
                    else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
                        setPropertyBlockDetails([
                            ...propertyBlockDetails,
                            ...assets
                        ])
                    }
                    Popup.alert("COMPLETE", "Files uploaded successfully !");
                    setpropertyReportLoading(false)
                    setpropertyFilesLoading(false)
                    setblockDetailsLoading(false)
                    getUploadedFiles(assets[0].file_type)
                    resolve()
                } catch (err) {
                    getUploadedFiles(assets[0].file_type)
                    setpropertyReportLoading(false)
                    setpropertyFilesLoading(false)
                    setblockDetailsLoading(false)
                    Popup.alert("ERROR", err?.message ? err.message : "Somethng went wrong while uploading file !");
                }
            }
            else resolve()
        })
    }

    const deleteFile = (fileId) => {
        Popup.alert("WARNING", "Are you sure want to delete this file?", () => {
            Popup.alert("WAIT", "Deleting file, please wait...")
            API.deleteAssetFile(fileId)
                .then((res) => {
                    if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
                        setPropertyReport(propertyReport.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    else if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
                        setPropertyFile(propertyFile.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    else if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
                        setPropertyBlockDetails(propertyBlockDetails.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    Popup.alert("COMPLETE", res.message)
                })
                .catch((e) => {
                    Popup.alert("ERROR", e.message || e)
                })
        })
    }

    useEffect(() => {
        getOnMountUploadedFiles()
    }, [])

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", padding: "0px 30px" }}>
                {(property?.id && openManageAccessPopup) &&
                    <PropertyAccessPopup
                        selectedProperty={property}
                        isOpen={(property?.id && openManageAccessPopup)}
                        onClose={() => {
                            setOpenManageAccessPopup(false)
                        }}
                    />
                }
                <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)", fontSize: "14px" }}>Property details</div>
                <ActionButton
                    name={"Permissions"}
                    icon={pencilDash}
                    onClickCallback={() => {
                        setOpenManageAccessPopup(true)
                    }}
                />
                <ActionButton
                    name={"Edit"}
                    icon={pencilDash}
                    onClickCallback={() => {
                        onEdit()
                    }}
                />
            </div>
            <form style={{ padding: "0px 30px", overflow: "auto", height: "190px" }}>
                <DisplayField label="Division" value={property.division_name} />
                <DisplayField label="City" value={property.city_name} />
                <DisplayField label="Sr. No" value={property.name} />
                <DisplayField label="City Area" value={property.city_area_name} />
                <DisplayField label="Name of Colony" value={property.name_of_colony} />
                <DisplayField label="Property Type" value={property.property_type} />
                <DisplayField label="Total Blocks" value={property.total_blocks} />
                <DisplayField label="Information" value={property.info} />
            </form>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)", fontSize: "14px" }}>{`Property Reports (${propertyReport?.length || 0})`}</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.dwg', '.dxf', '.zip', '.jpg', '.pdf', '.xls', '.JPG'])
                            const updatedReports = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.PROPERTY,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT,
                                files: file
                            }));
                            await uploadAssetFiles([...updatedReports])

                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        inputName={"Report"}
                        isMultiple={true}
                        acceptType={".dwg, .dxf, .zip, .jpg, .pdf, .xls , .JPG"}
                    />
                </div>
                {propertyReportLoading ?
                    <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} /> :
                    <FilesContainer
                        filesProp={propertyReport}
                        actionCallback={(fileId) => {
                            deleteFile(fileId)
                        }}
                        fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT}
                    />}
            </div>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)", fontSize: "14px" }}>{`Property Files (${propertyFile?.length || 0})`}</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.xls', '.jpg', '.pdf', '.JPG'])
                            const updatedPropertFiles = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.PROPERTY,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE,
                                files: file
                            }))

                            await uploadAssetFiles([...updatedPropertFiles])
                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        inputName={"propertyFiles"}
                        isMultiple={true}
                        acceptType={".xls, .pdf, .jpg , .JPG"}
                    />
                </div>
                {propertyFilesLoading ?
                    <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} /> :
                    <FilesContainer
                        filesProp={propertyFile}
                        actionCallback={(fileId) => {
                            deleteFile(fileId)
                        }}
                        fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE}
                    />}
            </div>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)", fontSize: "14px" }}>{`Block Details (${propertyBlockDetails?.length || 0})`}</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.xls', '.jpg', '.pdf', '.JPG'])
                            const updatedBlockDetails = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.PROPERTY,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS,
                                files: file
                            }))

                            await uploadAssetFiles([...updatedBlockDetails])
                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        inputName={"blockDetails"}
                        isMultiple={false}
                        isDisabled={propertyBlockDetails.length === 1}
                        acceptType={".xls, .pdf, .jpg, .JPG"}
                    />
                </div>
                {blockDetailsLoading ?
                    <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} />
                    :
                    <FilesContainer
                        filesProp={propertyBlockDetails}
                        actionCallback={(fileId) => {
                            deleteFile(fileId)
                        }}
                        fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS}
                    />}
            </div>
            <hr />
        </div>
    )
}


export default PropertyDetailsView;