import * as API from '../api.js';
import * as turf from '@turf/turf'
import AppConstants, { IMAGE_SET_RENAME_VARIABLE } from '../AppConstants.js';
import dateFormat from 'dateformat';
import shp from 'shpjs';
import { getStorageUserByService } from '../subscription/UserSubscription.js';
import ExifReader from 'exifreader';
import moment from 'moment';
import { ActivityPopup } from './ActivityPopup.js';
import React from "react";
import ReactDOM from "react-dom";
import { getArea, getDistance } from './map/leafletMeasure.js';
import * as L from 'leaflet'
export const isServiceAeroGCS = (url) => {
    if ((url.includes("green_"))) return false
    return (url.includes('/aerogcsenterprise') || url.includes('/allFlightPlans') || url.includes('/drone_management') || url.includes('/drone_control_center') || url.includes('/remote_mission') || (url.includes('/projects') && !url.includes("/dronenaksha/projects")) || url.includes('/plans') || url.includes('/request_a_drone'))
}
export const checkExpiry = (date) => {
    const currentDate = new Date()
    const expiryDate = new Date(date)
    if (currentDate.valueOf() > expiryDate.valueOf()) {
        return true
    } else {
        return false
    }
};

export const getSingleGeoJSON = async (arrayOfGeoJSON) => {
    return {
        "type": "FeatureCollection",
        "features": arrayOfGeoJSON.map(
            geoJSON =>
                Array.isArray(geoJSON.features) ? geoJSON.features : []
        ).flat()
    }
}

export const getSevirityNumber = (checkNumber) => {
    if (checkNumber == 1) return AppConstants.SEVIRITY._1
    if (checkNumber == 2) return AppConstants.SEVIRITY._2
    if (checkNumber == 3) return AppConstants.SEVIRITY._3
    if (checkNumber == 4) return AppConstants.SEVIRITY._4
    if (checkNumber == 5) return AppConstants.SEVIRITY._5
    if (checkNumber == "None") return AppConstants.SEVIRITY.NONE
    return undefined
}

export const checkURLExist = (url) => {
    return new Promise((resolve) => {
        fetch(url, { method: 'HEAD' })
            .then(async response => {
                if (response.ok) {
                    resolve(true);
                }
                else resolve(false)
            })
            .catch(() => {
                resolve(false);
            });
    })
}

export const convertInMbGB = (bytes, decimals = 1) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    const totalSize = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    return (totalSize)
}

export const convertToGB = (size) => {
    var sizeData = 0
    if (Number(size) > 0)
        sizeData = Number(size) > 1024 ? `${parseFloat(size / 1024).toFixed(2)} GB` : `${Number(size).toFixed(2)} MB`
    return sizeData
}

export const addUserAnalytic = async (route) => {
    return new Promise((resolve, reject) => {
        let pageData = AppConstants.PAGES.find((data) => {
            return data.ROUTE === route
        })

        if (pageData) {
            API.addUserAnalytic(pageData.PAGE_NAME)
                .then(data => {
                    let id = data.id
                    resolve(id)
                })
        }
    })
}

export const updateOutTime = (id) => {
    if (id) {
        API.updateUserAnalytic(id)
            .then(data => {
            })
    }
}

/**
 * To get the limited string of name of plan,project,imageset,etc.
 * @param {*} name : String which needs to be limit
 * @param {*} limit : required No. of characters in the string
 * @returns 
 */
export const getLimitedName = (name, limit, dontUseEllipses) => {
    let length_limit = limit ? limit : 18
    if (name && name.length > length_limit) {
        return `${(name.replace(IMAGE_SET_RENAME_VARIABLE, "").substring(0, length_limit)) + (dontUseEllipses ? "" : "...")}`
    }
    else return name?.replace(IMAGE_SET_RENAME_VARIABLE, "")
}

/** 
 * To get line and polygon intersect point 
 * @param {*} startPoint : Line start point, object contains latlng.
 * @param {*} endPoint : Line end point, , object contains latlng.
 * @param {*} polygon : Polygon, leaflet map layer of polygon.
 * @param {*} map : Map which needs to get intersect point. 
 * @returns
 */

export const polygonPolyLineIntersect = (startPoint, endPoint, polygon, map) => {
    return new Promise(async (resolve, reject) => {
        let intersectPoints = []
        let polygonPoints = polygon.toGeoJSON().geometry.coordinates[0]
        let X1Y1 = map.latLngToLayerPoint(startPoint)
        let X2Y2 = map.latLngToLayerPoint(endPoint)
        for (let i = 0; i < polygonPoints.length - 1; i++) {
            let X3Y3 = map.latLngToLayerPoint({ lat: polygonPoints[i][1], lng: polygonPoints[i][0] })
            let X4Y4 = map.latLngToLayerPoint({ lat: polygonPoints[i + 1][1], lng: polygonPoints[i + 1][0] })
            let intersectXY = await intersect(X1Y1.x, X1Y1.y, X2Y2.x, X2Y2.y, X3Y3.x, X3Y3.y, X4Y4.x, X4Y4.y)
            if (intersectXY != null) {
                if (intersectXY.seg1 == true && intersectXY.seg2 == true) {
                    let intersectPoint = map.layerPointToLatLng(intersectXY)
                    intersectPoint['vertex'] = i
                    intersectPoints.push(intersectPoint)
                }
            }
        }
        resolve(intersectPoints)
    })

}

/** 
 * To get two lines intersect point 
 * @param {*} startPoint : First Line start point, object contains latlng.
 * @param {*} endPoint : First Line end point, , object contains latlng.
 * @param {*} startPoint1 : Second Line start point, object contains latlng.
 * @param {*} endPoint1 : Second Line end point, , object contains latlng.
 * @param {*} map : Map which needs to get intersect point. 
 * @returns
 */

export const twoPolyLineIntersect = (startPoint, endPoint, startPoint1, endPoint1, map) => {
    return new Promise(async (resolve, reject) => {
        let intersectPoint = null
        let X1Y1 = map.latLngToLayerPoint(startPoint)
        let X2Y2 = map.latLngToLayerPoint(endPoint)
        let X3Y3 = map.latLngToLayerPoint(startPoint1)
        let X4Y4 = map.latLngToLayerPoint(endPoint1)
        let intersectXY = await intersect(X1Y1.x, X1Y1.y, X2Y2.x, X2Y2.y, X3Y3.x, X3Y3.y, X4Y4.x, X4Y4.y)
        if (intersectXY != null) {
            if (intersectXY.seg1 == true && intersectXY.seg2 == true) {
                intersectPoint = map.layerPointToLatLng(intersectXY)
            }
        }
        resolve(intersectPoint)
    })
}

const intersect = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    return new Promise((resolve, reject) => {
        var ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
        if (denom == 0) {
            resolve(null);
        }
        ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
        ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
        resolve({
            x: x1 + ua * (x2 - x1),
            y: y1 + ua * (y2 - y1),
            seg1: ua >= 0 && ua <= 1,
            seg2: ub >= 0 && ub <= 1
        })
    })
}

export const getLocalStorageValue = (parameter) => {
    return localStorage.getItem(parameter)
}

export const isTaskEditable = (status, sharedBy) => {
    return ((status == AppConstants.TASK.STATUS.PREPARING || status == AppConstants.TASK.STATUS.CREATING || status == AppConstants.TASK.STATUS.FAILED) && (sharedBy && sharedBy.isShared ? sharedBy.access_level == AppConstants.accessLevels.DOWNLOAD : true))
}
export const skipTrainingTaskTour = () => {
    localStorage.setItem('trainingTaskTour', true)
    localStorage.setItem('createTaskTour1', 2)
    localStorage.setItem('imageSetTour1', 3)
    localStorage.setItem('annotationTour', 3)
    localStorage.setItem('mapAnnotationTour', 3)
    localStorage.setItem('processTour1', 2)
}

export const skipDetectionTaskTour = () => {
    localStorage.setItem('detectionTaskTour', true)
    localStorage.setItem('createTaskTour2', 2)
    localStorage.setItem('imageSetTour2', 3)
    localStorage.setItem('processTour2', 2)
}

export const skipAnnotationTaskTour = () => {
    localStorage.setItem('annotationTaskTour', true)
    localStorage.setItem('createTaskTour3', 2)
    localStorage.setItem('imageSetTour3', 3)
    localStorage.setItem('processTour3', 2)
}

export const getFormattedObject = (object) => {
    var finalObject = object
    if (object && object.length > 0) {
        // const arr = object.split("_");
        // for (var i = 0; i < arr.length; i++) {
        //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        // }
        // finalObject = arr.join(" ");
        finalObject = finalObject.replaceAll("_", " ")
    }
    return finalObject
}

export const getMaxPageIndex = (totalItems, itemsPerPage) => {
    if (totalItems > itemsPerPage) {
        if (totalItems % itemsPerPage === 0) {
            return totalItems / itemsPerPage
        } else {
            return Math.trunc(totalItems / itemsPerPage) + 1
        }
    }
}

export const getRandomHexColour = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase();
}

export const triggerBlobDownload = (blob, fileName) => {
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

export const checkSpecialCharacterInclude = (string) => {
    return new Promise((resolve, reject) => {
        const format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(string)) {
            reject()
        } else {
            resolve(string.replace(" ", "_"))
        }
    })
}

export const getOriginalImageSetName = (string) => {
    if (string.includes(AppConstants.IMAGE_SET_RENAME_VARIABLE)) {
        return string.split(AppConstants.IMAGE_SET_RENAME_VARIABLE)[0]
    } else return string
}

export const getPicstorkDefaultPlan = async (project) => {
    return new Promise(async (resolve) => {
        let planName = AppConstants.PICSTORK_PLAN_NAME

        let Plan = {};
        let planConfiguration = {
            projectDetails: [
                {
                    clientName: 'asd',
                    'dateTime': dateFormat(new Date(), 'mmm dd, yyyy HH:MM:ss '),
                    flightCompletedCount: 0,
                    projectName: project.project_name,
                    projectId: project.id,
                    planName,
                    planCompletedStatus: 0,
                    softwareVerison: "1.0",
                    linkedMap: null
                }
            ]
        }
        Plan.planConfiguration = await planConfiguration;
        Plan.planAddress = {};
        Plan.missionStatistics = {
            "missionStatistics": [{
                "missionType": 'notGCSPlan',
            }]
        };
        Plan.DGCAfence = [{}];
        Plan.RallyPointData = [];
        Plan.corridor = [];
        Plan.Mission =
        {
            waypoints: [{

            }]
        }
        Plan.Geofence =
        {
            geofence: [{
                "lat": 0,
                "lon": 0,
                "alt": 0
            }]
        }
        Plan.Assets = {
            "planMap.png": ""
        }
        API.createPlan(
            Plan
        ).then(
            async (data) => {
                if (data.plan) {
                    resolve(data.plan)
                }
                else if (data.error) {
                    //setError("Something went wrong while creating task")
                }
            }
        );

    })
}


export const importShapeFile = (file) => {

    return new Promise((resolve, reject) => {
        const invalidShapeFile = () => {
            reject({ "message": "invalid file" });
        };

        const cleanUp = () => {
            reader.onload = null;
            reader.abort();
            // reader = null;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            if (e.target.readyState !== 2 || e.target.error) {
                invalidShapeFile();
                // cleanUp()
            } else {
                shp(e.target.result)
                    .then((geoJSON) => {
                        resolve(geoJSON);
                        cleanUp()
                    })
                    .catch((e) => {
                        invalidShapeFile();
                        cleanUp()
                    });
            }
        };
        reader.readAsArrayBuffer(file);
    });
};

export const isSelectedDataExceedStorageLimit = async (fileArr, serviceName) => {
    let serviceStorage = await getStorageUserByService(serviceName)
    let availableServiceStorage = serviceStorage.storageLimit - serviceStorage.storageUsed;
    const selectedDataSize = fileArr.reduce((acc, obj) => acc + (obj.size / (1024 * 1024)), 0);
    return !(selectedDataSize > availableServiceStorage)
}

export const checkSubscriptionAndStorage = (serviceName) => {
    return new Promise(async (resolve, reject) => {
        try {
            const serviceInfo = await getStorageUserByService(serviceName)

            resolve({
                ...serviceInfo,
                storageExceed: serviceInfo.storageLimit <= serviceInfo.storageUsed
            })
        } catch (err) {
            reject({ error: err })
        }
    })
}
export const getAmountInK = (amount) => {
    if (typeof amount === 'number') {
        if (amount < 1000) return amount
        // Divide the amount by 1000 and add 'K' at the end
        const amountInK = amount / 1000;
        return amountInK.toString() + 'K';
    } else {
        // If the input is not a number, return an error message or handle it as needed
        return amount;
    }
}
const generateRandomWord = () => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'; // Define the alphabet
    let randomWord = '';

    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length); // Generate a random index
        randomWord += alphabet[randomIndex]; // Add a random letter to the word
    }

    return randomWord;
}
export const getUniqueCollectionName = (col_name) => {
    if (col_name.includes("_copy_")) {
        return col_name.split("_copy_")[0] + '_copy_' + (generateRandomWord())
    } else
        return col_name + '_copy_' + (generateRandomWord())
}
export function extractMessageAfterMention(inputText) {
    // Use a regular expression to find mentions and capture the text after them

    const mentionRegex = /@\[[^\]]+\]\([^)]+\)/g;
    const mentions = inputText ? inputText.match(mentionRegex) : undefined;

    if (mentions) {
        // Extract the message after mentions
        let message = inputText;
        mentions.forEach((mention) => {
            const index = message.indexOf(mention);
            if (index !== -1) {
                message = message.substring(index + mention.length).trim();
            }
        });
        return message;
    }
    return inputText;
}

export const calcDistBetweenTwoPoints = (x1, y1, x2, y2) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

export const calculateCirclePoints = (cx, cy, radius, numPoints) =>
    Array.from({ length: numPoints }).reduce((points, _, i) => {
        const angle = (i / numPoints) * 2 * Math.PI;
        const x = cx + radius * Math.cos(angle);
        const y = cy + radius * Math.sin(angle);
        return [...points, [x, y]];
    }, []);

export const isTiffMultiSpectral = async (files) => {
    let isMultiSpectral = false
    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
            const readFileMetadata = async () => {
                return new Promise((resolve, reject) => {

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const arrayBuffer = e.target.result;

                        const tags = ExifReader.load(arrayBuffer);

                        // Do something with the metadata (e.g., update state)
                        if (tags.BandName) {
                            // file is multi spectral data
                            isMultiSpectral = true
                        } else {
                            isMultiSpectral = false;
                        }
                        resolve(isMultiSpectral)
                    };

                    // Read the file as a binary data
                    reader.readAsArrayBuffer(file);
                })
            }
            isMultiSpectral = await readFileMetadata()
        }
        if (!isMultiSpectral) break;
    }
    return isMultiSpectral
}

export const calculateCenterOfRegion = (region) => {
    const tempPoly = region.coordinates.map(coord => {
        return [coord.lat, coord.lng]
    })
    const polygon = turf.polygon([[...tempPoly, tempPoly[0]]]);
    const center = turf.centerOfMass(polygon)?.geometry?.coordinates || [0, 0]
    return center ? center : [0, 0]
}

export const getFormatedDate = (startDate, endDate) => {
    let b = moment.utc(startDate);
    let a = moment.utc(endDate);
    let m = a.diff(b, 'minutes'); // Difference in minutes
    let s = a.diff(b, 'seconds') % 60; // Remaining seconds after minutes
    return `${m} min ${s} sec`;
}


export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}
export function getCurrentWeekDates() {
    let currentDate = new Date();
    let currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    let mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // Adjust for Sunday
    let dates = [];

    // Loop from Monday to Sunday and push each date to the array
    for (let i = 0; i < 7; i++) {
        let date = new Date(mondayDate);
        date.setDate(mondayDate.getDate() + i);

        dates.push(dateFormat(date, 'dd mmm yyyy'));
    }

    return dates;
}

export const Popup = {
    alert: (icon, msg, okCb, onClose, height, customNoText, customYesText) => {
        ReactDOM.render(
            <ActivityPopup
                open={true}
                icon={icon}
                msg={msg}
                onClickOk={() => {
                    ReactDOM.unmountComponentAtNode(
                        document.getElementById("modalHolder")
                    );
                    if (okCb) {
                        okCb();
                    }
                }}
                close={() => {
                    ReactDOM.unmountComponentAtNode(
                        document.getElementById("modalHolder")
                    );
                    if (onClose) {
                        onClose();
                    }
                }}
                height={height}
                customNoText={customNoText}
                customYesText={customYesText}
            />
            ,
            document.getElementById("modalHolder")
        );
    },
    close: () => {
        ReactDOM.unmountComponentAtNode(
            document.getElementById("modalHolder")
        );
    }
};

export const closePopUp = () => {
    ReactDOM.unmountComponentAtNode(
        document.getElementById("modalHolder")
    );
}

export const downloadContourFile = async (url, fileName) => {
    const response = await fetch(url);
    const blob = await response.blob();
    triggerBlobDownload(blob, fileName)
}

export const handleLayerDownload = async (image_url, image_type, file_name, sasToken) => {
    const url = `${image_url}?${sasToken}`;

    const response = await fetch(url);
    const blob = await response.blob();

    switch (image_type) {
        case AppConstants.ASSETS.DETECTION:
            triggerBlobDownload(blob, `${file_name || 'detection'}.json`);
            break;
        case AppConstants.ASSETS.KML:
            triggerBlobDownload(blob, `${file_name || 'output'}.kml`);
            break;
        case AppConstants.ASSETS.SHP:
            triggerBlobDownload(blob, `${file_name || 'output'}.zip`);
            break;
        case AppConstants.ASSETS.GEOJSON:
            triggerBlobDownload(blob, `${file_name || 'output'}.json`);
            break;
        case AppConstants.ASSETS.CONTOUR:
            downloadContourFile(url, "contour_shape.zip");
            const dxf_url = url.replace('contour_shape', 'contour_dxf');
            downloadContourFile(dxf_url, "contour_dxf.zip");
            break;
        default:
            const link = document.createElement('a');
            link.href = url;
            link.click();
            break;
    }
}

const getCommonProperties = (layer) => {
    return { ...layer.properties };
};

// Helper to get coordinates for Marker
const getMarkerCoordinates = (layer) => {
    const coordinates = layer.getLatLng();
    return {
        lat: coordinates.lat,
        lng: coordinates.lng
    };
};

// Helper to calculate distance for Polyline
const calculatePolylineDistance = (layer) => {
    const distance = getDistance(layer.getLatLngs(), 'm', true, false);
    return {
        "2D Length": distance,
    };
};

// Helper to calculate area and perimeter for Polygon
const calculatePolygonAreaAndPerimeter = (layer) => {
    const area = getArea(layer.getLatLngs()[0], "m", true, false);
    const distance = getDistance(layer.getLatLngs()[0], 'm', true, false);
    return {
        Area: area,
        "2D Perimeter": distance
    };
};

// Main function
export const getLayerInfo = (layer) => {
    let properties = getCommonProperties(layer);

    if (layer instanceof L.Marker) {
        properties = { ...properties, ...getMarkerCoordinates(layer) };
    } else if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
        properties = { ...properties, ...calculatePolylineDistance(layer) };
    } else if (layer instanceof L.Polygon) {
        properties = { ...properties, ...calculatePolygonAreaAndPerimeter(layer) };
    }

    return properties;
};

export const getOverlayTilesLayer = async (orthomosaic, taskId, sasToken, blobContainer, tiffType) => {
    let bnds = [[orthomosaic?.bounds[1], orthomosaic?.bounds[0]], [orthomosaic?.bounds[3], orthomosaic?.bounds[2]]];
    let bandsSelection = "viewDefault=true";
    const url = `${AppConstants.tilesServer}/tiles/${taskId}/${tiffType}/{z}/{x}/{y}.png?sas=${sasToken}&blobContainer=${blobContainer}&${bandsSelection}`;
    const options = {
        tileSize: 256,
        maxZoom: 28,
        bounds: bnds,
    };
    return { url, options };
};
