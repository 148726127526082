import React, { Component } from 'react';
import * as API from './api.js';

import CustomersInactive from './customers.png';
import Users from './users.png';
import service from "./sidebaricon/Services.png";
import TaskIcon from './icons/servers.png'
import CanvasJSReact from './canvasjs.react';
import UsersStats from './res/stats.png'
import SupportIcon from './icons/support.png'
import logList from './icons/logList.png'
import install from './icons/AeroGCSInactive.png'
import detectors from './icons/detector.png'
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import SideBar from './SideBar';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class AdminUsersStatistics extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        currentpage: 1,
        userperpage: 8,
        currentCount: 0,
        allUsersTimeline: [],
        newUsersTimeline: [],
        key: " ",
        successParams: {
            title: "ADD USER",
            message1: "User has been added successfully",
            message2: "To go back to users, click",
            hereLink: "/admin/users",
            buttonText: "Add new user",
            buttonLink: "/admin/add_npnt_user"
        },
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }


    componentWillMount() {
        document.title = "Users - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }

    componentDidMount() {
        API.getUsersStats().then((results) => {
            let { allUsersTimeline, newUsersTimeline, currentCount } = results
            this.setState((prevState) => ({
                ...prevState,
                allUsersTimeline,
                newUsersTimeline,
                currentCount
            }))
        }).catch(e => {
            console.log("Failed to get page data.")
            console.log(e)
        })
    }


    render() {
        const { user, type, sidebarMode } = this.state;
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: UsersStats,
                    text: "Statistics",
                    path: "/admin/users_stats",
                    selected: true,
                },

                {
                    icon: CustomersInactive,
                    text: "Add NPNT Customer",
                    path: "/admin/add_npnt_customer"
                },

                {
                    icon: Users,
                    text: "Users",
                    path: "/admin/users",
                },
                
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: detectors,
                    text: "Detectors",
                    path: "/admin/detectors"
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar"><div className="top-bar-text">USER STATISTICS</div></div>
                    <div className="main-content">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px", display: "flex" }}>
                            <div style={{ display: "block", margin: "auto" }}>
                                <div style={{ display: "flex" }}>
                                    <Link to={{
                                        pathname: "/admin/users",
                                        state: {
                                            user,
                                            type,
                                            sidebarMode
                                        }
                                    }} style={{
                                        color: "",
                                        margin: "40px 0px 0px auto"
                                    }}>
                                        <div className="addcustomer-button-empty-dashboard">
                                            All Users
                                        </div>
                                    </Link>
                                </div>
                                <div style={{
                                    display: "flex",
                                    marginTop: "-80px"
                                }}>
                                    <div style={{ margin: "auto", fontSize: "160px", fontWeight: "bold", lineHeight: "200px" }}>{this.state.currentCount}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ margin: "auto", fontSize: "20px", fontWeight: "bold" }}>Total DroneLab Users</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "80px" }}>
                                    <div style={{ margin: "auto", width: "700px" }}>
                                        <CanvasJSChart options={{
                                            theme: "light2",
                                            animationEnabled: true,
                                            zoomEnabled: true,
                                            title: {
                                                text: "Total Users"
                                            },
                                            data: [{
                                                type: "area",
                                                dataPoints: this.state.allUsersTimeline.map(dp => ({ x: new Date(dp.timestamp), y: dp.count }))
                                            }]
                                        }} style={{
                                            width: "400px"
                                        }}>

                                        </CanvasJSChart>
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginTop: "80px" }}>
                                    <div style={{ margin: "auto", width: "700px" }}>
                                        <CanvasJSChart options={{
                                            theme: "light2",
                                            animationEnabled: true,
                                            zoomEnabled: true,
                                            title: {
                                                text: "New Users"
                                            },
                                            data: [{
                                                type: "area",
                                                dataPoints: this.state.newUsersTimeline.map(dp => ({ x: new Date(dp.timestamp), y: dp.count }))
                                            }]
                                        }} style={{
                                            width: "400px"
                                        }}>

                                        </CanvasJSChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
