// side bar


// side bar 


import React, { Component } from "react";
import service from "./sidebaricon/Services.png";
import AeroMeghLogo from "./userAuth/assets/ghblogo.png";
import Help from "./sidebaricon/Help.png";

import { Link } from "react-router-dom";

export default class SideBar extends Component {
  state = {
    sidebarOpen:
      this.props.location == undefined
        ? false
        : this.props.location.state.sidebarOpen,
    selected: "service",
    user: {},
    type: "",
    services: [],
    avatarURL: "",
    config: this.props.config,
    isFromDroneLabDashboard: this.props.isFromDroneLabDashboard
  };

  componentWillMount() {
    let { services, config } = this.props;
    let { user, type } = config;
    if (user.avatar_url && user.avatar_url.length > 0) {
      this.setState((state) => ({
        ...state,
        avatarURL: `${user.avatar_url}?${user.sas_token
          }&t${new Date().getTime()}`,
      }));
    } else {
      let avatarURL = `/images/${user.first_name?.[0].toLowerCase()}.PNG`;
      this.setState((state) => ({
        ...state,
        avatarURL: avatarURL,
      }));
    }
  }

  toggleSidebarMode = () => {
    this.setState((state) => ({
      ...state,
      config: {
        ...state.config,
        mode: state.config.mode == "shrunk" ? "expanded" : "shrunk",
      },
    }));
    this.props.parentSidebarSwitch();
  };

  render() {
    let { services, config, showMainDashboardIcons } = this.props;
    let { user, type } = config;
    config.mode = "sidebar-expanded";

    return (
      <div
        className={
          config.mode == "shrunk" ? "sidebar-shrunk" : "sidebar-expanded"
        }
        style={{ paddingTop: "0px", backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'white',height:this.props.height||"" }}
      >
        <div style={{ display: "flex", height: "40px" }}>
          <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
            <img
              src={this.props.AeroMeghLogo ? this.props.AeroMeghLogo : AeroMeghLogo}
              style={{ width: "65px", height: "20px", marginTop: "10px" }}
            />
          </div>
        </div>
        <ul className="sidebar-nav" id="sidebar-nav">
          {config.options
            .filter((el) => {
              return el.text !== "Help";
            })
            .map((el) => {
              if (el.text != "hr") {
                el.text = el.text.replace(/\b[a-z]/g, (match) =>
                  match.toUpperCase()
                );
              }
              return el;
            })
            .map((item) => {
              return item.text == "hr" ? (
                <hr
                  className={
                    config.mode == "shrunk"
                      ? "sidebar-shrunk-separator"
                      : "sidebar-expanded-separator"
                  }
                />
              ) : (
                <div style={{ borderLeft: `solid 2px ${item.selected ? this.props.borderColor ? this.props.borderColor : '#2989CF' : 'transparent'}` }}>
                  <li>
                    {item.text == "Back" ? (
                      <div
                        className="sidecontent"
                        id="sidecontent"
                        onClick={() => {
                          window.history.back();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="sidebar-icon">
                          <img
                            className="sidebar-icon"
                            src={item.icon}
                            alt="serviceIcon"
                          />
                        </div>
                        {item.selected ? (
                          <div
                            name="service"
                            className={
                              config.mode == "shrunk"
                                ? "sidebaropt-hidden"
                                : "sidebaropt"
                            }
                            style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF" }}
                            onClick={this.changeColor}
                          >
                            {item.text}
                          </div>
                        ) : (
                          <div style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c' }}
                            name="service"
                            className={
                              config.mode == "shrunk"
                                ? "sidebaropt-hidden"
                                : "sidebaropt"
                            }
                            onClick={this.changeColor}
                          >
                            {item.text}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        to={{
                          pathname: item.path,
                          state: {
                            config,
                            sidebarMode: config.mode,
                            user,
                            type,
                          },
                        }}
                      >
                        <div className="sidecontent" id="sidecontent">
                          <div className="sidebar-icon">
                            <img
                              className="sidebar-icon"
                              src={item.icon}
                              alt="serviceIcon"
                            />
                          </div>
                          {item.selected ? (
                            <div
                              name="service"
                              className={
                                config.mode == "shrunk"
                                  ? "sidebaropt-hidden"
                                  : "sidebaropt"
                              }
                              style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF" }}
                              onClick={this.changeColor}
                            >
                              {item.text}
                            </div>
                          ) : (
                            <div style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c' }}
                              name="service"
                              className={
                                config.mode == "shrunk"
                                  ? "sidebaropt-hidden"
                                  : "sidebaropt"
                              }
                              onClick={this.changeColor}
                            >
                              {item.text}
                            </div>
                          )}
                        </div>
                      </Link>
                    )}
                  </li>
                  <li>
                    {item.text == "Back" ? (
                      <Link
                        to={{
                          pathname: "/",
                          state: {
                            config,
                            sidebarMode: config.mode,
                            user,
                            type,
                          },
                        }}
                      >
                        {showMainDashboardIcons == false ? (
                          ""
                        ) : (
                          <div
                            className="sidecontent"
                            id="sidecontent"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="sidebar-icon">
                              <img
                                className="sidebar-icon"
                                src={service}
                                alt="serviceIcon"
                              />
                            </div>
                            {item.selected ? (
                              <div
                                name="service"
                                className={
                                  config.mode == "shrunk"
                                    ? "sidebaropt-hidden"
                                    : "sidebaropt"
                                }
                                style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF" }}
                                onClick={this.changeColor}
                              >
                                {"Services"}
                              </div>
                            ) : (
                              <div
                                name="service"
                                className={
                                  config.mode == "shrunk"
                                    ? "sidebaropt-hidden"
                                    : "sidebaropt"
                                }
                                onClick={this.changeColor}
                              >
                                {"Services"}
                              </div>
                            )}
                          </div>
                        )}
                      </Link>
                    ) : (
                      <div />
                    )}
                  </li>
                </div>
              );
            })
          }
          {!this.state.isFromDroneLabDashboard &&
              <div className={"nonSelectBorder"} >
                <li>
                  <div
                    className="onhoverext"
                    onClick={
                      
                      () => {
                        const url = config.aeroGCSHelp
                          ? "https://aerogcs-docs.aeromegh.com/"
                          : config.droneNakshaHelp
                          ? "https://dronenaksha-docs.aeromegh.com/"
                          : config.picStorkHelp
                          ? "https://picstork-docs.aeromegh.com/"
                          : "https://aeromegh.com/docs/";
                        window.open(url, "_blank");
                      }
                    }
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={Help}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                      >
                        {"Help"}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
          }
        </ul>
      </div>
    );
  }
}