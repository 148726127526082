import { AnimatedModal } from "./AnimatedModal";
import { CSSTransition } from "react-transition-group";
import React, { Component } from "react";
import * as API from "../api.js";
import waiting from "../res/waiting.png";
import DeleteImage from "../icons/ProjectDeleteGraphics.png";
import Loader from "../icons/loader.svg";
import CloudStorage from "../icons/CloudStorage.png";
import ProjectManagement from "../icons/ProjectManagement.png";
import TeamWork from "../icons/TeamWork.png";
import FlightLog from "../icons/FlightLog.png";
import closeButton from '../close-button.png';
import Next from "../icons/next.png";
import Prev from "../icons/prev.png";

/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) show as flag
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export default class SignUpInfoPopup extends Component {
  state = {
    data: [
      {
        img: CloudStorage,
        title: "Enterprise Features",
        info: "Connect with DroneLab cloud account and use enterprise features. Track flights on cloud at one place, sync all your project and backup on secure storage. Utilise the captured images and videos in other DroneLab services such as PicStork and DroneNaksha instantly."
      },
      {
        img: ProjectManagement,
        title: "Project Management",
        info: "Manage your flight plans at one place seamlessly using projects. Plan from DroneLab account and sync with AeroGCS."
      }, {
        img: TeamWork,
        title: "Collaborate With Teams",
        info: "Use smart team management and share your flight plans with team members. Read only access to plans ensures safety and security of data. Easily switch between teams and access flight plans swiftly on cloud or AeroGCS."
      },
      {
        img: FlightLog,
        title: "Detailed Flight Log",
        info: "Get access to most detailed logs on a GCS. Save logs offline or on cloud, get interesting and useful analytics about your team resources . Plan your project efficiently."
      }
    ],
    currentData: {
      img: CloudStorage,
      title: "Enterprise Features",
      info: "Connect with DroneLab cloud account and use enterprise features. Track flights on cloud at one place, sync all your project and backup on secure storage. Utilise the captured images and videos in other DroneLab services such as PicStork and DroneNaksha instantly."
    },
    currentpage: 0,
    imgLoad: true
  };

  closePopup = () => {
    this.setState(
      (state) => ({
        ...state,
        currentData: this.state.data[0],
        currentpage: 0,
      }))
    this.props.close();
  };

  prevpage = () => {
    console.log("Clicked previous page : ", this.state.currentpage);

    if (this.state.currentpage > 0) {
      this.setState(
        (state) => ({
          ...state,
          loading: true,
          currentpage: this.state.currentpage - 1,
          currentData: this.state.data[this.state.currentpage - 1]
        }))
    } else {
      console.log("Reached previous page");
    }
  };

  nextpage = () => {
    console.log("Clicked next page: ", this.state.currentpage);

    if (
      this.state.currentpage < 3
    ) {
      this.setState(
        (state) => ({
          ...state,
          loading: true,
          currentpage: this.state.currentpage + 1,
          currentData: this.state.data[this.state.currentpage + 1]
        }));
    } else {
      console.log("Reached next page");
    }
  };

  componentWillMount() { }
  render() {
    const { currentpage, data, currentData } = this.state;
    return (
      <div>
        <AnimatedModal
          height="480px"
          width="715px"
          isOpen={this.props.show}
          onRequestClose={() => {
            this.closePopup();
          }}
        >
          <div>
            <div onClick={() => { this.closePopup(); }} style={{ width: "97%", display: "flex", justifyContent: "right", marginTop: "3%", cursor: "pointer" }}>
              <img
                style={{
                  height: "23px",
                  width: "23px",
                }}
                src={closeButton}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
              <div onClick={() => { this.prevpage() }} style={{ width: "6%", display: "flex", marginTop: "25%", justifyContent: "right" }}>
                {currentpage > 0 &&
                  <img
                    style={{
                      height: "18px",
                      width: "18px",
                      cursor: "pointer"
                    }}
                    src={Prev}
                  />}
              </div>
              <div style={{ display: "flex", margin: "8% 2%" }}>
                <img
                  style={{
                    width: "285px",
                    opacity: this.state.imgLoad ? 0 : 1
                  }}
                  src={currentData.img}
                  loading='lazy'
                  onLoad={() => {
                    this.setState((state) => ({
                      ...state,
                      imgLoad: false
                    }))
                  }}
                ></img>
              </div>

              <div style={{ display: "flex", flexDirection: "column", textAlign: "justify", alignItems: "start", margin: "8% 3% 0% 3%", width: "35%" }}>
                <div className="table-header" style={{ fontWeight: 450 }}>{currentData.title}</div>
                <div className="table-header" style={{ marginTop: "5%", fontSize: "14px" }}>{currentData.info}</div>
              </div>
              <div onClick={() => { this.nextpage() }} style={{ width: "5%", display: "flex", marginTop: "25%", justifyContent: "right" }}>
                {currentpage < data.length - 1 &&
                  <img
                    style={{
                      height: "18px",
                      width: "18px",
                      cursor: "pointer"
                    }}
                    src={Next}
                  />}
              </div>
            </div>
          </div>
        </AnimatedModal>
      </div>
    );
  } Detailed
}
