import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SingleMap from '../../../ReusableComponents/map'
import { AnnotationTool } from "./MapAnnotationTool";
import * as API from "../../../api.js";
import { skipTrainingTaskTour, getLocalStorageValue, isTaskEditable, getLimitedName } from "../../../ReusableComponents/reusableFunctions";
import ReactJoyride, { BeaconRenderProps, TooltipRenderProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import AppConstants, { MAP_URLS } from "../../../AppConstants";
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import LoaderComponent from "../../../ReusableComponents/LoaderComponent";
import ImportExportRegions from "./ImportExportRegions";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import { PICSTORK_FEATURES } from "../../../Teams";

let extendBounds = 0.00075

// pass annotationTool as true if you want annotation feature, otherwise only orthomosaic will be loaded omn map
const MapAnnotation = (props) => {
    const [map, setMap] = useState(undefined)
    const [mapInfo, setMapInfo] = useState(undefined)
    const [bounds, setBounds] = useState(undefined)
    const [joyrideTrigger, setJoyrideTrigger] = useState(undefined)
    const [errorMessage, setError] = useState(undefined)
    const [showProcessPopup, setShowProcessPopup] = useState(false);
    const [processPopupIcon, setPopupIcon] = useState(undefined)
    const [processMessage, setProcessMessage] = useState(undefined)
    const [annotations, setAnnotations] = useState(0)
    const [annotationToolLoaded, setAnnotationToolLoaded] = useState(false)
    const [showAnnotationTool, setShowAnnotationTool] = useState(null)
    const [guideSteps, setGuideSteps] = useState([
        {
            disableBeacon: true,
            target: '#semi-step1',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Start adding class labels which will be used for annotations</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Add Class Label</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#draw-tool',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Start annotations using drawing tool</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Start Annotations</div>),
            placement: 'left',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
        {
            disableBeacon: true,
            target: '#done-button',
            content: (<div style={AppConstants.TEXTSTYLING}><p>Click here to save annotations</p></div>),
            title: (<div style={AppConstants.TITLESTYLING}>Save Annotations</div>),
            placement: 'bottom',
            styles: {
                options: {
                    width: 250,
                },
            },
        },
    ])
    const annotationTool = useRef();
    // useEffect(() => {
    //     if (map) getMapInfo(map)
    // }, [props.selectedImageForAnnotation])

    useEffect(() => {
        if (annotationTool.current && !annotationToolLoaded) {
            setAnnotationToolLoaded(true)
        }
    }, [annotationTool.current])


    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem('mapAnnotationTour', 2)
        } else if ([STATUS.SKIPPED].includes(status)) {
            skipTrainingTaskTour()
        }
    };

    const mapInitialized = (map) => {
        map.createPane('accuracyAreas')
        map.createPane('ortho');
        map.createPane('annotation')
        setMap(map)
        getMapInfo(map)

    }

    const getMapInfo = (map) => {
        API.getTilesBounds({
            taskId: props.selectedImageForAnnotation.collection_id,
            tiffType: props.selectedImageForAnnotation.file_name,
            sas: encodeURIComponent(props.permissions?.st),
            blobContainer: props.permissions?.container
        }).then((mapInfo) => {
            setMapInfo(mapInfo)
            setMapCenter(mapInfo, map)
            loadOrtho(mapInfo, map)
            drawBoundsBorder(map, mapInfo.bounds)
        })
    }

    const setMapCenter = async (info, map1) => {
        if (info && map1) {
            let bounds = [[info.bounds[1] - extendBounds, info.bounds[0] - extendBounds], [info.bounds[3] + extendBounds, info.bounds[2] + extendBounds]]
            map1.fitBounds(bounds);
        } else {
            let bounds = [[mapInfo.bounds[1] - extendBounds, mapInfo.bounds[0] - extendBounds], [mapInfo.bounds[3] + extendBounds, mapInfo.bounds[2] + extendBounds]]
            map.fitBounds(bounds);
        }
    }

    const closeActivityPopup = () => {
        setShowProcessPopup(false)
    }

    const drawBoundsBorder = async (map, bounds) => {
        const drawingBounds = [[bounds[1], bounds[0]], [bounds[3], bounds[2]]]
        // const orthoBackground = L.rectangle(drawingBounds, { color: '#666666', weight: 1, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const orthoBackground = L.rectangle(drawingBounds, { color: '#2989CF', weight: 2, fillColor: "white", fillOpacity: 1, interactive: false }).addTo(map)
        const boundsLayer = L.rectangle(drawingBounds, { color: 'red', weight: 1, fillColor: "none", interactive: false, }) // for blink
        setBounds({
            drawingBounds, boundsLayer
        })
        setJoyrideTrigger(getLocalStorageValue('mapAnnotationTour') == 1 && props.showAnnotationTool)
    }
    const changeZIndex = (zidx) => {
        map.getPane('accuracyAreas').style.zIndex = zidx

    }
    const loadOrtho = (mapInfo, map) => {
        map.getPane('accuracyAreas').style.zIndex = 1001
        map.getPane('annotation').style.zIndex = 1002
        const sas = encodeURIComponent(props.permissions?.st)
        let bounds = [[mapInfo.bounds[1], mapInfo.bounds[0]], [mapInfo.bounds[3], mapInfo.bounds[2]]]
        let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${props.selectedImageForAnnotation.collection_id}/${props.selectedImageForAnnotation.file_name}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${props.permissions?.container}`, {
            tileSize: 256,
            maxZoom: 28,
            pane: 'ortho',
            bounds: bounds
        }).addTo(map);
        if (props.onOrthoLoad) props.onOrthoLoad(map)
    }

    const loaderFordownload = (event, msg, icon) => {
        if (event) {
            setPopupIcon(icon)
            setProcessMessage(msg)
            setShowProcessPopup(true)
        }
        else setShowProcessPopup(false)
    }
    useEffect(() => {
        setShowAnnotationTool(props.showAnnotationTool)
    }, [props.showAnnotationTool])
    return <>
        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={showProcessPopup}
            icon={processPopupIcon}
            msg={processMessage}
            close={() => closeActivityPopup()}
            onClickOk={() => {
                annotationTool.current.clearAllAnnotations()
                closeActivityPopup()
            }}
        />
        {/* --------------------------------------------------------------- */}
        {
            joyrideTrigger ?
                <ReactJoyride
                    continuous={true}
                    run={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    showSkipButton={true}
                    steps={guideSteps}
                    disableOverlayClose={true}
                    floaterProps={AppConstants.FLOATER_PROPS}
                    locale={AppConstants.LOCALE_BUTTONS}
                    callback={(e) => handleJoyrideCallback(e)}
                    styles={{
                        buttonNext: AppConstants.NEXT_BUTTON,
                        buttonSkip: AppConstants.SKIP_BUTTON,
                        spotlight: AppConstants.SPOTLIGHT,
                        options: AppConstants.MAIN_OPTIONS
                    }}
                /> : <></>
        }

        {bounds || !props.showAnnotationTool ? <></> : <div style={{ width: "85%", height: "94%", position: "absolute", backgroundColor: "#eaedf0", zIndex: 2 }}><LoaderComponent height={"90%"} message={"Loading map please wait..."} /></div>}
        {
            showAnnotationTool ?
                <>
                    <div style={{ display: "flex", justifyContent: "space-between", height: '35px', alignItems: 'center' }}>
                        <div
                            className="services-content-title"
                            style={{ color: "#3c3c3c" }}
                        >
                            <span style={{ color: "#2989cf" }}>{props.selectedImageForAnnotation ? getLimitedName(props.selectedImageForAnnotation.file_name, 30) : "Orthomosaic"}</span>
                            <span
                                className="content-text"
                                style={{ color: "#3c3c3c", fontWeight: 500, marginLeft: "20px" }}
                            >
                                Annotations : <span style={{ color: "#2989cf", fontSize: "12px", marginRight: "20px" }}>{annotations}</span>
                            </span>
                        </div>

                        <div style={{ display: "flex" }}>
                            {(props.task.tasktype === 2 || props.task.tasktype === 4) && props.task.linked_map &&
                                <ButtonWithIcon
                                    text={"View report"}
                                    isDisabled={annotations === 0 || props.validTiff}
                                    linkData={
                                        {
                                            pathname: props?.task.task_purpose === AppConstants.TASKPURPOSE.COUNTING ? "/picstork/Pdf_Download" : "/picstork/defect_report",
                                            state: {
                                                user: props.user,
                                                type: props.selectedReportType,
                                                task: props.task,
                                            }
                                        }
                                    }
                                />}
                            {annotationToolLoaded ?
                                <ImportExportRegions
                                    permissions={props.permissions}
                                    regions={annotations}
                                    importRegions={annotationTool.current.importAnnotations}
                                    exportRegions={annotationTool.current.exportAnnotations}
                                /> : <></>}
                            <ButtonWithIcon
                                text="Clear" width="94px"
                                isDisabled={annotations < 1 || !props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT}
                                onClick={(event) => {
                                    if (props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].EDIT && annotations && isTaskEditable(props.task.status, props.task.sharedBy)) {
                                        setPopupIcon("WARNING")
                                        setProcessMessage('Are you sure you want to clear all annotations ?')
                                        setShowProcessPopup(true)
                                    } else annotationTool.current.setUpdateNotAllowed()
                                }}
                            />
                            <ButtonWithIcon text="Done" width="94px" onClick={() => props.setAnnotationDone(1)} />
                        </div>
                    </div>
                    <div style={{ height: 'calc(100% - 44px)', width: '100%', marginTop: '8px', background: 'white', display: "flex", justifyContent: "space-between", paddingBottom: "24px" }}>
                        <div style={{ height: '100%', width: '80%', }}>
                            <SingleMap
                                // setBaseLayerToState={this.props.setBaseLayerToState}
                                // initCenter={this.props.initCenter}
                                initBaseLayerURL={MAP_URLS.ROAD_MAP}
                                initZoom={18}
                                // bounds={this.getCurrentMapExtent()}
                                // handleMapClick={this.props.handleMapClick}
                                // handleMoveStart={this.props.handleMoveStart}
                                maxZoom={28}
                                setMap={(map) => {
                                    mapInitialized(map)
                                }}
                                isTaskTraining={props.task.tasktype === 1 && props.task.linked_map}
                            >
                            </SingleMap>
                        </div>
                        {map && bounds && <div style={{ height: '100%', width: '20%', }}>
                            <AnnotationTool
                                permissions={props.permissions}
                                showLoader={loaderFordownload}
                                ref={annotationTool}
                                setAnnotationsCount={setAnnotations}
                                bounds={bounds}
                                task={props.task}
                                annotationSetId={props.annotationSetId}
                                imageInfo={props.selectedImageForAnnotation}
                                map={map}
                                updateTask={props.updateTask}
                                setMapCenter={setMapCenter}
                            />
                        </div>}
                    </div>
                </> :
                showAnnotationTool !== null?
                    <>
                        <SingleMap
                            // setBaseLayerToState={this.props.setBaseLayerToState}
                            // initCenter={this.props.initCenter}
                            initBaseLayerURL={MAP_URLS.ROAD_MAP}
                            initZoom={18}
                            // bounds={this.getCurrentMapExtent()}
                            // handleMapClick={this.props.handleMapClick}
                            // handleMoveStart={this.props.handleMoveStart}
                            maxZoom={25}
                            setMap={(map) => {
                                mapInitialized(map)
                            }}
                        >
                        </SingleMap>

                    </> : <></>
        }
    </>
}

export default MapAnnotation;