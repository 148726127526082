import React from 'react'
import noFiles from "../../assets/icons/noFile.svg"

const NoFilesFallback = ({ message }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={noFiles} alt='noFiles' style={{ width: "106px" }} />
            <div style={{ fontSize: "14px", marginTop: "10px" }}>{message}</div>
        </div>
    )
}

export default NoFilesFallback