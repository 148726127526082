import React, { useState } from 'react'
import saveIcon from '../../../assets/toolbarIcons/save.png';
import tickIcon from '../../../icons/tick.png'

const MeasurementDetails = ({ title, value, onUpdateMeasurementDetails }) => {
    const [targetValue, setTargetValue] = useState(value)
    const [isSave, setIsSave] = useState(false)
    return (
        <div>
            <div style={{ backgroundColor: 'white', fontSize: "10px", fontWeight: "400", color: "#666666" }}>
                {title}
            </div>

            <div className="content-text" style={{ marginTop: "3px", backgroundColor: 'white', color: "#3c3c3c", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <input type="text" className="textbox" maxlength="25" style={{ fontSize: "10px", padding: "5px 8px", width: "calc(100% - 40px)", border: "1px solid #6666668f", borderRadius: "2px", color: "#3c3c3c", borderColor: "#6666668f" }} value={targetValue} onChange={(e) => {
                    setIsSave(false)
                    setTargetValue(e.target.value)
                }} />
                <div style={{ backgroundColor: 'white', padding: '5px', }}>
                    <img style={{ width: '18px', height: '18px', cursor: "pointer", marginLeft: "10px" }}
                        src={isSave ? tickIcon : saveIcon}
                        onClick={() => {
                            onUpdateMeasurementDetails(targetValue)
                            setIsSave(true)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default MeasurementDetails