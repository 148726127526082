import React, { useEffect, useState } from 'react'
import FileImporter from '../ReusableComponents/FileImporter'
import { addCityAPI, convertKMLToGeoJSON, getAllDivisions, updateCityAPI } from '../api'
import PropertyMap from "../AeroCapture/Components/ReusableComp/PropertyMap"
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { Popup } from '../ReusableComponents/reusableFunctions'
import Loader from '../icons/loader.svg'
import Select from 'react-dropdown-select'
import Close from "../icons/blackClose.png"
import LoaderComponent from '../ReusableComponents/LoaderComponent'
const AddCity = ({ selectedCity, division_id, onCityAdded, onCityEdited, onRequestClose, checkIfDuplicate }) => {
    const [city, setCity] = useState({ name: "", description: "", coordinates: null, division_id })
    const [loading, setLoading] = useState(true)
    const [divisions, setDivisions] = useState([])
    const [selectedDivision, setSelectedDivsion] = useState(null)
    const [mapCoordinates, setMapCoordinates] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    useEffect(() => {
        if (selectedCity && divisions?.length > 0) {
            setIsEditing(true)
            let coordinates = selectedCity.coordinates
            if (typeof coordinates == 'string') {
                coordinates = JSON.parse(coordinates)
            }
            setCity({
                id: selectedCity.id,
                name: selectedCity.name,
                description: selectedCity.description,
                coordinates,
                division_id: selectedCity.division_id
            })
            setMapCoordinates(coordinates)
            const div = divisions.find(d => d.id == selectedCity.division_id)
            setSelectedDivsion(div)
        }
    }, [selectedCity, divisions?.length])
    const handleImportedFile = async (file) => {
        try {
            if (file) {

                const geoJson = await convertKMLToGeoJSON(file)
                if (geoJson) {
                    const coords = geoJson.features.find(f => f.geometry?.type == "Polygon").geometry.coordinates[0].map(c => [c[0], c[1]])
                    setCity(prev => ({ ...prev, coordinates: coords }))
                    setMapCoordinates(coords.map(c => c.reverse()))
                }
            } else {
                setMapCoordinates([])
                setCity(prev => ({ ...prev, coordinates: null }))
            }
        } catch (err) {
            console.log(err)
        }
    }
    const handleAddCity = async () => {
        setLoading(true)
        try {
            if (checkIfDuplicate(city)) {
                setLoading(false)
                Popup.alert("ERROR", "City already exists")
            } else {
                const c = await addCityAPI(city)
                setLoading(false)
                onCityAdded(c)
                Popup.alert("COMPLETE", "City added successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
            Popup.alert("ERROR", err)
        }
    }

    const handleEditCity = async () => {
        setLoading(true)
        try {
            if (selectedCity.name !== city.name && checkIfDuplicate(city)) {
                setLoading(false)
                Popup.alert("ERROR", "City already exists")
            } else {
                const c = await updateCityAPI(city)
                setLoading(false)
                onCityEdited(city)
                Popup.alert("COMPLETE", "City updated successfully", onRequestClose, onRequestClose)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
            Popup.alert("ERROR", err)
        }
    }
    const getDivisions = async () => {
        try {
            const result = await getAllDivisions()
            setDivisions(result.divisions)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("ERROR", err)
        }
    }
    useEffect(() => {
        getDivisions()
    }, [])
    useEffect(() => {
        if (division_id) {
            setCity(prev => ({ ...prev, division_id }))
            setSelectedDivsion(divisions.find(div => div.id == division_id))
        }
    }, [divisions.length, division_id])
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", gap: "10px", position: "relative" }}>

            <div style={{ padding: "20px", textAlign: 'left', display: 'flex', alignItems: "center", justifyContent: 'space-between', borderBottom: '1px solid #C3CDD9' }}>
                <span>{isEditing ? 'Edit' : 'Add'} City</span>
                <img onClick={onRequestClose} src={Close} style={{ cursor: 'pointer', height: '25px' }} />
            </div>
            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: "column", gap: "10px", padding: "20px", position: "relative" }}>
                {loading &&
                    <LoaderComponent message={" "} background={"rgb(0 0 0 / 7%)"} position={"absolute"} zIndex={"99"} style={{ left: "0", top: '-10px' }} />
                }
                <div style={{ display: "flex", gap: "20px", alignItems: "baseline" }}>


                    {/* division */}
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Division</label>
                        <Select
                            selected
                            style={{ minWidth: "0px", minHeight: "0px", height: '34px', width: "100%" }}
                            values={selectedDivision ? [{ label: selectedDivision.name, value: selectedDivision.id }] : []}
                            placeholder="Select Division"
                            searchable={true}
                            disabled={divisions?.length === 0}
                            noDataRenderer={() => {
                                return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Division not available</span>
                            }}
                            options={
                                divisions?.map((p) => ({
                                    label: p.name,
                                    value: p.id,
                                }))
                            }
                            className="custom_dropdown"
                            onChange={(e) => {
                                if (e?.length > 0) {
                                    setSelectedDivsion(divisions.find(div => div.id == e[0].value))
                                    setCity(prev => ({ ...prev, division_id: e[0].value }))
                                }
                            }}
                        />
                    </div>
                    {/* name */}

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Name Of City</label>
                        <input
                            value={city.name}
                            placeholder='Enter City Name'
                            style={{ outline: "none", width: "100%", borderRadius: "8px", border: "1px solid #C3CDD9", height: "34px", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                            type='text' onChange={e => {
                                e.persist()
                                setCity(prev => ({ ...prev, name: e.target.value }))
                            }} />
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label style={{ textAlign: "left", color: "#3c3c3c", fontSize: '14px' }}>Description <span style={{ color: "#666666" }}>(Optional)</span></label>
                    <textarea
                        value={city.description}
                        style={{ outline: "none", width: "100%", height: "70px", resize: 'none', borderRadius: "8px", border: "1px solid #C3CDD9", fontWeight: "400", fontSize: "14px", background: "#F4F4F4", paddingLeft: "6px" }}
                        placeholder='Type here...' onChange={e => {
                            e.persist()
                            setCity(prev => ({ ...prev, description: e.target.value }))
                        }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <FileImporter
                        placeholder={"Upload your KML File"}
                        accept={[".kml", "application/vnd.google-earth.kml+xml"]}
                        onImportFile={file => {
                            handleImportedFile(file)
                        }} />
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    <PropertyMap coordinates={mapCoordinates} />
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: "50px", right: "50px", zIndex: 99 }}>
                <ButtonWithIcon isBtnActive fontSize={'14px'} width={"115px"} isDisabled={!city.division_id || !city.coordinates || !city.name} text={isEditing ? "Update" : "Add"} onClick={() => {
                    if (isEditing) {
                        handleEditCity()
                    } else {
                        handleAddCity()
                    }
                }} />
            </div>
        </div>
    )
}

export default AddCity
