import React, { Component } from 'react';
import * as API from '../api.js';
import backIcon from "../back.svg";
import Settings from '../sidebaricon/Settings.png';
import Loader from '../icons/loader.svg';
import { Modal } from "react-bootstrap";
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon.js';
import ChangeMobileNumber from '../userAuth/ChangeMobileNumber.js';
export default class editUser extends Component {

    state = {
        user: {},
        type: "",
        countryLoader: true,
        stateLoader: true,
        country: '',
        avatarURL: '',
        countries: [],
        states: [],
        state: '',
        user: {
            first_name: "",
            last_name: "",
            username: "",
            address: "",
            password: "",
            country: '',
            state: '',
            phone: "",
            password: "-"
        },
        redirect: false,
        loading: false,
        config: {},
        sizeCheckPopup: false,
        selectedFile: {},
        FILE_SIZE_LIMIT: 5,
        countryChange: false,
        avatarUrlData: '',
        avatarUrlData1: '',
    }
    submitEditUser = (e, mobileVerified) => {
        if (e) e.preventDefault()
        this.setState((state, user) => ({
            ...state,
            loading: true,
        }))
        let user = this.state.user
        user.country = user.country == '-' ? '-' : user.country
        user.state = user.state == '-' ? '-' : user.state
        user.phone = mobileVerified || this.state.user.phone
        API.Useredit(user).then((message) => {
            console.log(message);
            this.setState((state, user) => ({
                ...state,
                user,
            }))
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                this.setState((state) => ({
                    ...state,
                    user,
                    user: user,
                    type,
                }), () => {
                    this.props.save()
                })
            })
        }, (e) => {
            console.log(e)
        })
    }

    onChangeListener = (e) => {
        let t = e.target;
        if (t.name == 'phone') {
            let format = /[a-zA-z!@#$%^&*()\-=\[\]{};':"\\|,.<>\/?]+/

            if (format.test(e.target.value)) {
            } else if (e.target.value.includes(' ')) {
            } else {
                if (t.value.length <= 13)
                    this.setState((state) => ({
                        ...state,
                        user: {
                            ...state.user,
                            [t.name]: t.name === 'email' ? t.value.toLowerCase() : t.value
                        }
                    }))
            }
        }
        else
            this.setState((state) => ({
                ...state,
                user: {
                    ...state.user,
                    [t.name]: (t.name == "first_name") || (t.name == "last_name") ? t.value.trim() : t.value.trimLeft()
                }
            }))
    }

    getCountries = () => {
        API.getCountries().then((data) => {
            let country = this.state.user.country && this.state.user.country != '-' ? this.state.user.country : '-'
            this.setState((state) => ({
                ...state,
                countryLoader: false,
                countries: data.countries,
                user: {
                    ...state.user,
                    country: country,
                }
            }))
            if (country != '-') {
                country = this.state.countries.find(data => data.country == country)
                this.getStates(country.iso_code)
            }
        }, (e) => {
            console.log(e);
        })
    }
    getStates = (iso_code) => {
        API.getStates(iso_code).then((data) => {
            let userstate = this.state.countryChange && data.states && data.states.length > 0 ? '-' : this.state.user.state
            if (!this.state.countryChange && this.state.user.state)
                userstate = this.state.user.state
            this.setState((state) => ({
                ...state,
                stateLoader: false,
                states: data.states,
                user: {
                    ...state.user,
                    state: userstate,
                    countryChange: false
                }
            }))
        }, (e) => {
            console.log(e);
        })
    }


    getBase64 = file => {
        return new Promise(resolve => {
            let fileSize = file.size / 1000
            if (fileSize > (this.state.FILE_SIZE_LIMIT * 1000)) {
                this.setState((state) => ({
                    ...state,
                    selectedFile: file,
                    sizeCheckPopup: true
                }))
            }
            else {
                let baseURL = "";
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    baseURL = reader.result;
                    resolve(baseURL);
                };
            }
        });
    };
    isNoAnyChangesMade = () => {
        return (
            this.state.user.address === this.props.user.address
            && this.state.user.first_name === this.props.user.first_name
            && this.state.user.last_name === this.props.user.last_name
            && this.state.user.state === this.props.user.state
            && this.state.user.country === this.props.user.country
            && this.state.user.phone === this.props.user.phone
            && this.state.avatarUrlData == this.state.avatarUrlData1
        )
    }

    componentWillMount() {
        this.getCountries();

        document.title = "Edit Users - Management Services";
        if (this.props) {
            let { user, type, sidebarMode, first_name, last_name, email, country, address, phone, userid, avatarURL, sas_token } = this.props;
            if (user.avatar_url && user.avatar_url.length > 0) {
                this.setState((state) => ({
                    ...state,
                    avatarUrlData: user.avatar_url,
                    avatarUrlData1: user.avatar_url,
                    avatarURL: `${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
                }))

            } else {
                let avatarURL = `/images/${user.first_name[0].toLowerCase()}.PNG`
                this.setState((state) => ({
                    ...state,
                    avatarUrlData: avatarURL,
                    avatarUrlData1: avatarURL,
                    avatarURL: avatarURL
                }))
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
                mode: sidebarMode,

                sas_token,

                user: {
                    first_name,
                    last_name,
                    username: email,
                    address,
                    state: user.state,
                    country,
                    phone,
                    userid,
                    blob_container: user.blob_container,
                },
                config:
                {
                    options:
                        [
                            {
                                icon: backIcon,
                                text: "Back",
                                path: "/"
                            },
                            {
                                icon: null,
                                text: "hr",
                                path: null
                            },
                            {
                                icon: Settings,
                                text: "Settings",
                                path: "/profile-settings"
                            }
                        ],
                    user,
                    type,
                },

            }), () => {
            })
        } else {
            window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/management_services/user")
        }
    }

    render() {
        let { user, type, customerDisplay, organizations } = this.state

        return (
            user.username ? <div className="wrapper">
                <Modal
                    style={{ marginTop: '150px' }}
                    show={this.state.sizeCheckPopup}
                >
                    <Modal.Body >
                        <p>Please upload an image of size less than {this.state.FILE_SIZE_LIMIT} MB.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        sizeCheckPopup: false,
                                    }))
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
                {false && <ChangeMobileNumber
                    phone={this.state.user.phone}
                    user={this.props.user}
                    sendOTPbyDefault
                    onClose={() => this.setState({ changeMobileNumberPopup: false })}
                    onMobileNumberChanged={(phone) => {
                        this.setState({
                            changeMobileNumberPopup: false
                        })
                        this.submitEditUser(false, phone)
                    }}
                />}
                <div>
                    <div className="top-bar">
                        <div className="top-bar-text">
                            EDIT USER
                        </div>
                    </div>
                    <div style={{ width: "65vw" }}>
                        {this.state.loading ?
                            <div style={{ display: "flex", height: "70vh", alignItems: "center", justifyContent: "center" }}>
                                <img src={Loader} style={{ height: "30vh", width: "70vw", margin: "auto" }} />
                            </div> : (
                                <div style={{ margin: "3% 10%" }}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="services-content-title">
                                            User Information
                                        </div>
                                        <div className="rpas-model-info-photo-wrapper" style={{ height: 'auto', width: 'max-content', marginLeft: 'auto' }}>
                                            <img style={{ borderRadius: "50%", marginLeft: 'auto !important', marginRight: '0px', cursor: 'pointer', height: '12vh', width: '12vw' }} onClick={() => {
                                                let avatarClick = document.getElementById('avatarUpload').click();
                                            }} src={`${this.state.avatarURL}`}>
                                            </img>
                                            <input id="avatarUpload" type="file"
                                                style={{ display: 'none', width: '0px', height: '0px' }}
                                                name="profile_photo" placeholder="Photo" onChange={async (e) => {
                                                    let val;
                                                    try {
                                                        val = URL.createObjectURL(e.target.files[0]);
                                                    } catch (e) {

                                                    }
                                                    let avatar = await this.getBase64(e.target.files[0])
                                                    this.setState((state) => ({
                                                        ...state,
                                                        avatarURL: val,
                                                        avatarUrlData1: avatar,
                                                        user: {
                                                            ...state.user,
                                                            avatar: avatar
                                                        }
                                                    }))
                                                }} required="" capture />
                                        </div>
                                    </div>
                                    <form onSubmit={this.submitEditUser} action={this.submitEditUser}>
                                        <div className="add-user-form-row" style={{ display: 'flex', marginTop: "2%" }}>
                                            <div className="add-user-form-row-left">
                                                <div className="add-user-form-label">
                                                    First Name
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <input name="first_name" type="text" style={{ width: "25vw" }} className="add-user-form-text" placeholder="eg. Scott"
                                                        onChange={this.onChangeListener}
                                                        value={this.state.user.first_name}
                                                        maxLength={25} required
                                                    />
                                                </div>
                                            </div>
                                            <div className="add-user-form-row-right">
                                                <div className="add-user-form-label">
                                                    Last Name
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <input name="last_name" type="text" style={{ width: "25vw" }} className="add-user-form-text" placeholder="eg. Smith"
                                                        onChange={this.onChangeListener}
                                                        value={this.state.user.last_name}
                                                        maxLength={25} required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-user-form-row" style={{ display: 'flex', marginTop: "2%" }}>
                                            <div className="add-user-form-row-left">
                                                <div className="add-user-form-label">
                                                    Email
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <input disabled={true} style={{ width: "25vw", opacity: '0.4' }} name="username" type="email" className="add-user-form-text" placeholder="eg. scott.smith@pdrl.in" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={this.onChangeListener} value={this.state.user.username.trim()} />
                                                </div>
                                            </div>
                                            <div className="add-user-form-row-right">
                                                <div className="add-user-form-label">
                                                    Phone Number
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <input name="phone" type="text" maxLength={10} style={{ width: "25vw" }} className="add-user-form-text" placeholder="eg. 9978645321" onChange={this.onChangeListener} value={this.state.user.phone} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-user-form-row" style={{ display: 'flex', marginTop: "2%" }}>
                                            <div className="add-user-form-row-left">
                                                <div className="add-user-form-label">
                                                    Country
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <select name="country" style={{ width: "25vw" }} className="add-user-form-text" value={this.state.user.country} required
                                                        onChange={(e) => {
                                                            let val = e.target.value;
                                                            let t = e.target;
                                                            this.setState((state) => ({
                                                                ...state,
                                                                countryChange: true,
                                                                user: {
                                                                    ...state.user,
                                                                    [t.name]: t.value
                                                                }
                                                            }), () => {
                                                                this.state.countries.forEach(country => {
                                                                    if (country.country == val) {
                                                                        this.getStates(country.iso_code)
                                                                    }
                                                                });
                                                            })
                                                        }}
                                                    >
                                                        <option value={'-'} disabled>
                                                            {this.state.countryLoader == true ?
                                                                `Loading Countries` : `Select Country`}
                                                        </option>
                                                        {this.state.countries.length > 0 ?
                                                            this.state.countries.map((country) => {
                                                                return <option key={country.iso_code} value={country.country}>
                                                                    {country.country}
                                                                </option>
                                                            }) :
                                                            <option>no countries found</option>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="add-user-form-row-right">
                                                <div className="add-user-form-label">
                                                    State
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <select name="state" style={{ width: "25vw" }} className="add-user-form-text" value={this.state.user.state} required onChange={this.onChangeListener}>
                                                        <option value={'-'} disabled>
                                                            {
                                                                `Select State`
                                                            }
                                                        </option>
                                                        {this.state.states.map((state, key) => {
                                                            return <option value={state.name}>{state.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-user-form-row" style={{ display: 'flex', marginTop: "2%" }}>
                                            <div className="add-user-form-row-left">
                                                <div className="add-user-form-label">
                                                    Address
                                                </div>
                                                <div className="add-user-form-text-wrapper">
                                                    <input name="address" type="text" maxLength={"70"} style={{ width: "25vw" }} className="add-user-form-text" placeholder="eg. Mountain View, California, United States Zip: 1600" required
                                                        onInput={this.onChangeListener}
                                                        value={this.state.user.address}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-user-form-row" style={{ display: 'flex', width: "100%", justifyContent: "right", marginTop: "3%" }}>
                                            <button className="addcustomer-button-empty-dashboard" onClick={() => this.props.cancel()}>
                                                Cancel
                                            </button>
                                            <ButtonWithIcon
                                                isDisabled={this.isNoAnyChangesMade() || !(this.state.user
                                                    && this.state.user.phone
                                                    && this.state.user.address
                                                    && this.state.user.first_name
                                                    && this.state.user.last_name
                                                    && this.state.user.state
                                                    && this.state.user.country
                                                )}
                                                onClick={this.submitEditUser}
                                                isBtnActive
                                                width={84}
                                                height={28}
                                                text={"Save"}
                                            />
                                        </div>
                                    </form>
                                </div>
                            )}
                    </div>
                </div>
            </div> :
                <div />
        );
    }
}
