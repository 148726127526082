import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import btnAdd from '../assets/icons/btnAdd.svg'
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import { StaticRouter, Redirect } from "react-router-dom";
import reportIcon from '../../assets/sideBarIcons/Report.png';
import { connect } from "react-redux";
import * as API from '../../api.js';
import myMaps from "../../assets/sideBarIcons/myMaps.png";
import AeroMeghLogo from ".././../userAuth/assets/aeromeghLogo.png";
import ImageSetInActive from '../../assets/sideBarIcons/ImageSetInactive.png';
import ProjectIcon from '../../assets/sideBarIcons/Project.png';
import TaskIcon from '../../icons/servers.png'
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import Menu from '../assets/icons/menu.svg';
import Noimageset from '../../assets/Images/Noimageset.png';
import Notask from '../../assets/Images/Notask.png';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import service from "../../sidebaricon/Services.png";
import dateFormat from 'dateformat';
import { convertToGB, addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import ImageSetThumb from '../../icons/ImageSetThumb.png'
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Help from "../../sidebaricon/Help.png";
import SharedIcon from '../../icons/SharedIcon.png'
import { DeleteComponentGrid, DeleteComponentTable } from '../../ReusableComponents/Menu'
import { SortComponent } from '../../ReusableComponents/SortComponent';
import AppConstants, { BLOB_URL } from '../../AppConstants';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Plus from "../../icons/addBlue.png";
import { ActiveRole, DRONENAKSHA_FEATURES, getFeaturesPermissions } from '../../Teams';
import EntitySelector from '../../ReusableComponents/EntitySelector';
import { getUniqueCollectionName } from "../../ReusableComponents/reusableFunctions"
import DropDownWithButton from '../../ReusableComponents/DropDownWithButton';
import whitePlus from "../../assets/Icons/whiteplus.png"

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
const CustomToggleOne = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={btnAdd} alt="I am alt" width="30px" height="26px" />
    </a>
));
class AeroFarmPlan extends Component {
    state = {
        isOwner: false,
        sharedBy: null,
        user: {},
        type: "",
        key: " ",
        reportTypes: [{ id: 0, name: 'Detailed Reports' },
        {
            id: 1, name: 'Summary Reports'
        }],
        stream_key: 'uq1dwicb',
        selectedReportType: "Detailed Reports",
        currentpage: 1,
        userperpage: 5,
        progress: 0,
        files: {},
        _progressInfos: [
            { percentage: 0, fileName: '' },
            { percentage: 0, fileName: '' },
            { percentage: 0, fileName: '' },
            { percentage: 0, fileName: '' },
            { percentage: 0, fileName: '' },
            { percentage: 0, fileName: '' },
        ],
        toShowProgressOf: {
        },
        showVideoUploadPopup: false,
        videos: [],
        taskStatusCount: {},
        showDownloadPopup: false,
        loader: true,
        services: [],
        plan: {},
        project: {},
        collections: [],
        tasks: [],
        reports: [],
        redirect: false,
        showBackIcon: true,
        cancel: false,
        loading: true,
        flights: 0,
        showAeroStreamUploadPopup: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        currentStep: 0,
        createTaskMsg: "",
        collection_id: "",
        collection_name: "",
        imageCount: 0,
        taskType: null,
        showAddTaskPopup: false,
        addDetectionTaskPopup: false,
        models: [],
        videoTask: false,
        video_id: "",
        fps: 1,
        selectedModels: [],
        media_name: "",
        media_id: null,
        scrollHeight: "180px",
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        deleteItem: "",
        thumbURLs: [],
        oldCollectionId: '',
        showCopyImagePopup: false,
        newCollectionName: '',
        recentMaps: [],
        mapCount: '',
        collectionCount: '',
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        renameCollection: false,
        loadingImageset: true,
        mapLoading: true,
        taskCount: 0,
        outId: '',
        orthoImageData: [],
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            showEntitySelector: false,
            processMessage: null
        }))
    }

    getRecentMaps = async () => {
        this.setState((state) => ({
            ...state,
            mapLoading: true,
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ)
            API.getAllMaps(" ", 0, 4, this.state.plan ? "plan" : "user", this.state.plan ? this.state.plan.id : undefined)
                .then(async (data) => {
                    this.setState((state) => ({
                        ...state,
                        recentMaps: data.data,
                        mapCount: data.count,
                        mapLoading: false,
                    }))
                }).catch(e => {
                    this.setState((state) => ({
                        ...state,
                        recentMaps: [],
                        mapLoading: false,
                    }))
                })
        else this.setState((state) => ({
            ...state,
            recentMaps: [],
            mapLoading: false,
            mapCount: 0,
        }))
    }

    getImageOrthomosaicImages = () => {
        API.getImageOrthomosaicImages(this.state.plan.id).then(e => { this.setState({ orthoImageData: e.images }) })
    }

    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy,
                })
                console.log(result)
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    // function to load tiff thumbnails
    loadTiff = async () => {
        let { collections, user } = this.state
        var thumbURL = []
        for (let i = 0; i < this.state.collections.length; i++) {
            if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
                let name = collections[i].file.file_name.toLowerCase()
                let turl = `${BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.state.permissions.st}`
                thumbURL[i] = turl
                this.setState((state) => ({
                    ...state,
                    thumbURLs: thumbURL,
                }))
            }
        }
    }

    //Get Task Function here
    getTasks = () => {
        const { user, type, userdisplay, currentpage, key, taskCount, requestStatus, userperpage } = this.state;
        let state = this.props.location.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            taskCount: 0,
            // loading: true
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ) API.getAllTask(this.state.project.id, this.state.plan.id, key, firstpage, userperpage, "DroneNaksha").then(async (data) => {
            //console.log(data);
            if (data.tasks && data.tasks.length == 0) {
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    loading: false
                }))
            } else {
                try {
                    let pendingTasks = await data.tasks.filter((task) => {
                        if (task.status == 'Pending') {
                            return task
                        }
                    });
                    let tasks = await data.tasks.filter((task) => {
                        if (task.status != 'Pending') {
                            return task
                        }
                    });
                    let allTasks = await pendingTasks.concat(tasks)
                    this.setState((state) => ({
                        ...state,
                        tasks: allTasks,
                        loading: false,
                        taskCount: data.count,
                        statusCount: data.statusCount
                    }), () => {
                        if (this.state.sortType === 'A-Z') {
                            this.sortByName()
                        } else if (this.state.sortType === 'Z-A') {
                            this.reverseByName()
                        } else if (this.state.sortType === 'First Modified') {
                            this.sortByDate()
                        } else if (this.state.sortType === 'Last Modified') {
                            this.reverseByDate()
                        }
                    })
                } catch (e) {
                    console.log(e);
                    this.setState((state) => ({
                        ...state,
                        tasks: [],
                        taskCount: 0,
                        loading: false
                    }))
                }
            }
        }, (e) => {
            //console.log(e);
        })
        else {
            this.setState((state) => ({
                ...state,
                tasks: [],
                loading: false
            }))
        }
    }

    //Get recent Imagesets function 
    getLatestImageSets = () => {
        this.setState((state) => ({
            ...state,
            loadingImageset: true
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ) API.getLatestImageSets("plan", "4", this.state.plan.id, 0).then((data) => {
            console.log("getLatestImageSets", data.imageCollections)
            this.setState((state) => ({
                ...state,
                collections: data.imageCollections,
                collectionCount: data.count,
                loadingImageset: false
            }))
            this.loadTiff();
        }, (e) => {
            console.log(e);
        })
        else {
            this.setState((state) => ({
                ...state,
                collections: [],
                collectionCount: 0,
                loadingImageset: false
            }))
        }
    }
    handleImportImagesets = async (selectedEntities) => {
        switch (selectedEntities.entityType) {
            case "projects": break;
            case "plans": break;
            case "imagesets":
                // let importedImagesets = []
                let errorOccured = false;
                for (const entity of selectedEntities.entites) {
                    if (errorOccured) break;
                    await new Promise((resolve, reject) => {
                        API.createImageCollection(this.state.project.id, this.state.plan.id, `${getUniqueCollectionName(entity.collection_name)}`).then(data => {
                            // const res = await data.json()
                            if (data.collection) {
                                API.duplicateImageSet(this.state.permissions.container, entity.id, data.collection.id, AppConstants.SERVICES.DRONENAKSHA, [".tif", ".TIF"]).then((res) => {
                                    if (res.status == 201 || res.status == 202) {
                                        resolve()
                                    } else {
                                        errorOccured = true;
                                        API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
                                            resolve()
                                        }).catch(err => {
                                            errorOccured = true
                                            resolve()
                                        })
                                    }

                                }).catch(err => {
                                    console.log(err)
                                    errorOccured = true
                                    resolve()
                                }
                                )
                            }
                            else if (data?.status === 409) {
                                errorOccured = true;
                                resolve()
                            }
                        }).catch(err => {
                            console.log(err)
                            errorOccured = true
                            resolve()
                        })
                    })
                }
                if (errorOccured) {
                    this.setState({ showProcessPopup: true, processMessage: "Something went wrong while importing imagesets", processPopupIcon: "ERROR" })
                } else {
                    this.setState({
                        processMessage: "Collections imported succesfully", processPopupIcon: "COMPLETE",
                    }, () => {
                        this.getLatestImageSets()
                    })
                }

                break;
            default:
        }
    }
    createCollectionCopy = () => {
        this.setState((state) => ({
            ...state,
            showCopyImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Duplicating your image collection please Wait...",
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE) API.createImageCollection(this.state.project.id, this.state.plan.id, this.state.newCollectionName).then((data) => {
            if (data.collection) {
                API.duplicateImageSet(this.state.permissions.container, this.state.oldCollectionId, data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((res) => {
                    if (res.status == 201 || res.status == 202) {
                        this.getLatestImageSets();
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Imageset copied successfully.',
                            showProcessPopup: true,
                        }))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            newCollecton: data.collection,
                            itemName: "Imageset",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: 'Something went wrong when copying images.',
                            showProcessPopup: true,
                        }), () => {
                            API.deleteAerocaptureImageCollection(data.collection.id, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
                                console.log(data);
                                this.getLatestImageSets()
                            })
                        })
                    }

                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong",
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })


    }

    // Rename image collection 

    renameCollection = () => {
        let renameData = {
            "componentId": this.state.selectedCollectionId,
            "newName": this.state.collection_name,
            "type": "ImageCollection"
        }

        this.setState((state) => ({
            ...state,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT) API.renameCollection(renameData).then((data) => {
            if (data.status == 200) {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getLatestImageSets();
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
                // alert("ImageSelt already exist with same name.")
            } else {
                this.setState((state) => ({
                    ...state,
                    collection_name: '',
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "Something went wrong.",
                    showProcessPopup: true,
                }))
                // alert('Something went wrong')
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: "Something went wrong.",
                showProcessPopup: true,
            }))
        })
    }

    createCollection = () => {
        this.setState((state) => ({
            ...state,
            showAddImagePopup: false,
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE) API.createImageCollection(this.state.project.id, this.state.plan.id, this.state.collection_name).then((data) => {
            if (data.collection) {
                this.setState((state) => ({
                    ...state,
                    newCollecton: data.collection,
                    itemName: "Imageset",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getLatestImageSets();
                    setTimeout(() => {
                        this.setState((state) => ({
                            ...state,
                            redirect: true
                        }))
                    }, 2000);
                })
            } else if (data.status == 409) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Imageset already exist with same name.",
                    showProcessPopup: true,
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Imageset",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong",
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            // console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Something went wrong",
                showProcessPopup: true,
            }))
        })
    }

    deleteImageCollection = () => {
        this.setState((state) => ({
            itemName: "Imageset",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            console.log(data);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                this.getLatestImageSets()
                this.getRecentMaps()
                this.getImageOrthomosaicImages()
                this.getTasks()
            })
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Imageset",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/dronenaksha/images",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    plan: this.state.plan,
                    project: this.state.project,
                    sidebarMode: this.state.sidebarMode,
                    collection: this.state.newCollecton
                }
            }} />
        }
    }



    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.tasks.sort((a, b) => a.task_name.localeCompare(b.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.tasks.sort((a, b) => b.task_name.localeCompare(a.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }


    /*----------Life Cycle Method ----------- */
    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentWillMount() {

        getFeaturesPermissions([DRONENAKSHA_FEATURES.IMAGECOLLECTIONS, DRONENAKSHA_FEATURES.PLANS, DRONENAKSHA_FEATURES.MAPS])
            .then(permissions => {
                addUserAnalytic(this.props?.location?.pathname)
                    .then(id => {
                        this.setState((state) => ({
                            outId: id
                        }))
                    }).catch(err => {
                        console.log(err)
                    })
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 30% !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);

                document.title = "DroneNaksha - Plan";
                if (this.props.location.state) {
                    let { user, type, task, project, plan } = this.props.location.state;

                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        task,
                        project,
                        permissions,
                        plan,
                    }), () => {
                        this.isUserOwnerOfCollection()
                        this.getRecentMaps()
                        this.getImageOrthomosaicImages()
                        this.getLatestImageSets()
                        this.getTasks()
                        API.getUserSubbedServices().then(
                            (response) => {
                                let services = response[0];
                                //console.log(services);
                                if (services.length == 1 && services[0].title == 'AeroStream') {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: false
                                    }))
                                } else {
                                    this.setState((state) => ({
                                        ...state,
                                        showBackIcon: true
                                    }))
                                }
                            }
                        )

                        //console.log("ASKDJHASK", user);
                    })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        //console.log("ROLE ", type);
                        //console.log("USER ", user)
                        this.setState((state) => ({
                            ...state,
                            user,
                            type
                        }), () => {
                            this.getTasks()
                            this.getLatestImageSets()
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
    }
    handleChange = (event) => {
        console.log(event.target.value);
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(event.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (event.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ collection_name: event.target.value });
            }

    }

    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ newCollectionName: e.target.value });
            }
    }

    showLayer = () => {
        let typArr = this.state.orthoImageData.map(e => { return e.image_type });
        let fnltyp = typArr.includes("orthomosaic") ? "RGBShow"
            : typArr.some(typ => typ == "dem" || typ == "dtm") ? "DEMShow"
                : typArr.some(typ => typ == "pointCloud" || typ == "threeD") ? "pointCloudShow" : "RGBShow"
        return fnltyp;
    }


    render() {
        let { videoTask, selectedModels, media_name, fps, user, type, models, collections, videos, collection_name, showBackIcon, tasks, reports, project, plan, statusCount, steps, currentStep, task_name, accuracy, annotation_set_name, annotationSets, addDetectionTaskPopup, addTrainingTaskPopup } = this.state
        let sidebarConfigurations = {
            options: showBackIcon == true ? [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: dashboardIcon,
                    text: "DroneNaksha",
                    path: "/dronenaksha/dashboard",
                },
                // {
                //     icon: projectsIcon,
                //     text: "Projects",
                //     path: "/dronenaksha/projects",
                // },
                // {
                //     icon: projectActive,
                //     text: "Project",
                //     path: "/dronenaksha/project",
                //     selected: true,
                // },
                {
                    icon: reportIcon,
                    text: "Reports",
                    path: "/dronenaksha/allreports",
                },

            ] : [
                {
                    icon: dashboardIcon,
                    text: "DroneNaksha",
                    path: "/dronenaksha/dashboard",
                },
                // {
                //     icon: projectsIcon,
                //     text: "Projects",
                //     path: "/dronenaksha/projects",
                // },
                // {
                //     icon: projectActive,
                //     text: "Project",
                //     path: "/dronenaksha/project",
                //     selected: true,
                // },
                {
                    icon: reportIcon,
                    text: "Reports",
                    path: "/dronenaksha/allreports",
                },

            ],
            user,
            type,
            mode: this.state.sidebarMode,

        }


        return (
            user.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"DroneNaksha"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/plan",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ProjectIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Plan"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={myMaps}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                Maps
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>

                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/image_collections",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={ImageSetInActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                //style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Imagesets"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/tasks",
                                            state: {
                                                type,
                                                user,
                                                plan: this.props.location.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={TaskIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Tasks"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    {/*--------Pop up starts here----- */}
                    {this.successRedirectComponent()}
                    {/* Entity Selector */}
                    {this.state.showEntitySelector &&
                        <EntitySelector
                            allowMultipleSelect
                            serviceList={[AppConstants.SERVICES.PICSTORK]}
                            btnTitle={"Import"}
                            show={this.state.showEntitySelector}
                            entity={"imagesets"}
                            onRequestClose={() => {
                                this.setState({ showEntitySelector: false })
                            }}
                            callback={async (selectedEntities) => {
                                this.setState({
                                    showProcessPopup: true,
                                    processPopupIcon: "WAIT",
                                    processMessage: `Importing ${selectedEntities.entityType} please wait...`
                                }, () => {
                                    this.handleImportImagesets(selectedEntities)
                                })

                            }}
                        />}
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        height={this.state.processPopupIcon == "WARNING" && this.state.itemName == 'Imageset' ? "180px" : null}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteImageCollection()}
                    >
                    </ActivityPopup>
                    {/* --------------------------------------------------------------- */}

                    {/*------------------- Rename image collection ------------------ */}
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.renameCollection}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                renameCollection: false,
                                collection_name: ''
                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title">{"Rename Imageset"}</div>
                                <label className="font-color-secondary" style={{ fontSize: "12px" }}>Enter New Name</label>
                                <input type="text" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset Name"
                                    onChange={this.handleChange} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <ButtonWithIcon text="Cancel" width="80px"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            renameCollection: false,
                                            collection_name: ''
                                        }));
                                    }}
                                />
                            </div>
                            <div>
                                <ButtonWithIcon text="Update" width="80px"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            renameCollection: false,
                                        }), () => {
                                            this.renameCollection();
                                        })
                                    }}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                    {/* ------------------------------------------------------------- */}


                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showCopyImagePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showCopyImagePopup: false,
                                newCollectionName: ''
                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title">{"Copy an Imageset"}</div>
                                {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
                                <input type="text" value={this.state.newCollectionName} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT}
                                    onChange={(e) => { this.handleChangeCopy(e) }} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonWithIcon text="Cancel" width="80px"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showCopyImagePopup: false,
                                    }));
                                }}
                            />
                            {this.state.newCollectionName ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.createCollectionCopy()
                                    }}
                                >
                                    Ok
                                </button> :
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    style={{ opacity: '0.5', cursor: 'default' }}
                                >
                                    Ok
                                </button>}

                        </Modal.Footer>
                    </Modal>

                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showAddImagePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showAddImagePopup: false,
                                collection_name: ''

                            }));
                        }}
                    >
                        <Modal.Body >
                            <div style={{ marginBottom: "30px" }}>
                                <div className="services-content-title">{"Create an Imageset"}</div>
                                <label className="font-color-secondary" style={{ fontSize: "13px" }}>To upload images you need to create an imageset.</label>
                                <input type="text" value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.IMAGESET_NAME_LIMIT}
                                    onChange={this.handleChange} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <ButtonWithIcon
                                    text="Cancel" width="80px"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showAddImagePopup: false,
                                        }));
                                    }}
                                />
                            </div>
                            {this.state.collection_name ?
                                <ButtonWithIcon text="Ok" width="80px" isBtnActive onClick={this.createCollection} />
                                : <ButtonWithIcon text="Ok" isDisabled isBtnActive width="80px" />}
                        </Modal.Footer>
                    </Modal>

                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",

                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: project.project_name,

                                    pathname: "/dronenaksha/project",
                                    state: {
                                        type,
                                        user,
                                        project,
                                    }
                                },
                                {
                                    displayName: this.state.plan.plan_name
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}
                            <ActiveRole />

                        </div>{ }</div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }} onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }>
                        {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                        {this.state.recentMaps.length > 0 ?
                            <>
                                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', }}>
                                    <div className="services-content-title"> Maps
                                        <span className='data-count'>({this.state.mapCount})</span>
                                    </div>
                                    <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", marginRight: this.state.isOwner ? "42px" : "8px", filter: this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ ? "" : "grayscale(100%)" }}>
                                        {/* {<Link to={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                                plan: this.state.plan,
                                                project: this.props.location.state.project,
                                            },
                                        }}>
                                        <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px' }}>View All</div>
                                        </Link>
                                    } */}
                                        <ButtonWithIcon linkData={{
                                            pathname: "/dronenaksha/myMaps",
                                            state: {
                                                type,
                                                user,
                                                plan: this.state.plan,
                                                project: this.props.location.state.project,
                                            }
                                        }} text="View All" isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ} />
                                    </div>
                                </div>

                                {this.state.recentMaps.length > 0 ?
                                    <div className="aerocapture-cards-tray-projects" style={{ overflowY: 'hidden' }}>
                                        <>{
                                            this.state.recentMaps.map((map, key) => {
                                                return (
                                                    <div>
                                                        <Link to={{
                                                            pathname: `/dronenaksha/view_plan`,
                                                            state: {
                                                                user, type,
                                                                plan: { id: map.plan_id, plan_name: map.plan_name, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                                                project: { id: map.project_id, project_name: map.project_name, sharedData: map.sharedData, collection_id: map.id },
                                                                collection: map,
                                                                orthoImageCollectionId: map.id,
                                                                layerShow: "RGBShow", sidebarMode: this.state.sidebarMode
                                                            }
                                                        }}>
                                                            {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                                                <div className="card-header customer-cards-dashboard-logo project-card-header">
                                                                    {
                                                                        map && map.task_id ?
                                                                            <div style={{ borderRadius: "6px", height: "111px", width: "100%", background: "black" }} >
                                                                                <img style={{ objectFit: 'contain', height: "111px", width: "170px" }} loading="true" src={`${BLOB_URL}/${this.state.permissions.container}/orthomosaicImages/${map.task_id}/orthomosaicThumb.png?${this.state.permissions.st}`} onError={(e) => { e.currentTarget.src = ImageSetThumb; }} />
                                                                            </div> :
                                                                            <img id="ProjectImage" src={ImageSetThumb} />
                                                                        // <img id="ProjectImage" src={thumb} />
                                                                    }
                                                                </div>
                                                                {map.sharedData.isShared ? <div className='shared-badge'>
                                                                    <img src={SharedIcon} style={{ height: "18px" }} />
                                                                </div> : <></>}
                                                                <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                                    <div style={{ display: 'flex', height: "24px", alignItems: "center", justifyContent: 'space-between' }}>
                                                                        <div style={{ overflow: "hidden" }}>
                                                                            {
                                                                                map.collection_name.length > 10 ?
                                                                                    `${map.collection_name.substring(0, 10)}...` :
                                                                                    `${map.collection_name}`
                                                                            }
                                                                        </div>
                                                                        <div style={{ overflow: "hidden" }}>{convertToGB(map.img_size)}</div>
                                                                    </div>
                                                                    <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div style={{ overflow: 'hidden' }}>{(map && map.area) ? (map.area.split(" ")[0] / 1000000).toFixed(3) + " sq.km" : ``}</div>
                                                                        <div> {
                                                                            (() => {
                                                                                let date = (new Date(map.processing_date)).toLocaleDateString('en-US')
                                                                                return `${dateFormat(date, "dd-mmm-yyyy")}`
                                                                            })()
                                                                        }</div>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </>
                                    </div>
                                    : <div className="rmp-dashboard-empty-data-message" >
                                        <img style={{ height: '10%', width: '10%', marginTop: "2%", marginTop: "2%" }} src={Noimageset} />
                                        <p style={{ margin: '10px' }}>No Recent Maps Available</p>
                                    </div>
                                }
                            </> : <></>}

                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="services-content-title">Imagesets
                                <span className='data-count'>({this.state.collectionCount})</span>
                            </div>

                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", filter: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ ? "" : "grayscale(100%)" }}>
                                {this.state.collectionCount ?
                                    <ButtonWithIcon
                                        text="View All"
                                        width="104px"
                                        linkData={
                                            {
                                                pathname: "/dronenaksha/image_collections",
                                                state: {
                                                    type,
                                                    user,
                                                    plan: this.props.location.state.plan,
                                                    project: this.props.location.state.project,
                                                }
                                            }
                                        }
                                        isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].READ}
                                    /> : <></>}
                            </div>
                            <DropDownWithButton
                                icon={whitePlus}
                                isButton
                                isBtnActive
                                selectedItem={"Imageset"}
                                isBtnList
                                textColor={"white"}
                                isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE}
                                list={
                                    [
                                        {
                                            name: "Import",
                                            onClick: () => this.setState({ showEntitySelector: true })
                                        },
                                        {
                                            name: "Create",
                                            onClick: () => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    showAddImagePopup: true,
                                                }))
                                            },
                                        }
                                    ]
                                }

                            />

                            <div style={{ filter: this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE ? "" : "grayscale(100%)" }}>
                            </div>
                            <div>
                                <ButtonWithIcon
                                    text="View Map" isBtnActive
                                    linkData={{
                                        pathname: "/dronenaksha/view_plan",
                                        state: {
                                            user,
                                            type,
                                            plan: this.props.location.state.plan,
                                            project: this.props.location.state.project,
                                            orthoImageCollectionId: this.state.recentMaps.length > 0 ? this.state.recentMaps[0].id : this.state.orthoImageData.length > 0 ? this.state.orthoImageData[0].collection_id : undefined,
                                            layerShow: this.showLayer()
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {this.state.loadingImageset ? <div style={{ height: "200px", width: "81vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "10vh", width: "100vw", margin: "auto" }} />
                        </div> : <div>
                            {this.state.collections.length ?
                                <div className="aerocapture-cards-tray-projects scroll-container" style={{ overflowY: 'hidden' }}>
                                    <>   {
                                        this.state.collections.map((collection, key) => {
                                            return (
                                                <div>
                                                    <Link to={{
                                                        pathname: `/dronenaksha/images`,
                                                        // pathname: "/dronenaksha/view_plan",
                                                        state: {
                                                            user,
                                                            type,
                                                            collection,
                                                            project: this.state.project,
                                                            plan: this.state.plan,
                                                            sidebarMode: this.state.sidebarMode
                                                        },
                                                        // state: {
                                                        //     user,
                                                        //     plan: this.state.plan,
                                                        //     project:this.state.project,
                                                        //     orthoImageCollectionId: collection.id,
                                                        //     layerShow: "RGBShow"
                                                        // },
                                                    }}>
                                                        {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                                                            <div className="card-header customer-cards-dashboard-logo project-card-header" style={{
                                                                // backgroundImage=`url(${'./assets/map_placeholder.png'})`
                                                            }}>
                                                                {
                                                                    collection && collection.file && collection.file.file_name ?
                                                                        <img id="ProjectImage" style={{ objectFit: 'cover' }}
                                                                            src={this.state.thumbURLs[key]} onError={(e) => { e.target.onerror = null; e.target.src = ImageSetThumb }} />

                                                                        :
                                                                        < img id='ProjectImage' src={ImageSetThumb} />
                                                                    // <img id="ProjectImage" src={thumb} />
                                                                }

                                                                <div>
                                                                    <DeleteComponentGrid
                                                                        style={{ position: "absolute", top: "5px", right: "5px" }}
                                                                        canRename={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].EDIT}
                                                                        canDownload={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DOWNLOAD && collection.images_count > 0}
                                                                        canDuplicate={this.state.isOwner && this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].CREATE && collection.images_count > 0}
                                                                        disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.IMAGECOLLECTIONS].DELETE}
                                                                        onClickDelete={(e) => {
                                                                            e.preventDefault()
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                selectedCollectionId: collection.id,
                                                                                deleteItem: "IMAGESET",
                                                                                itemName: "Imageset",
                                                                                processPopupIcon: "WARNING",
                                                                                processAction: "DELETE",
                                                                                processMessage: "All data including orthomosaic images, tasks, annotationssets, gcp points, images will be deleted." +
                                                                                    "\n Are you sure you want to delete this Imageset ?",
                                                                                showProcessPopup: true,
                                                                            }))
                                                                        }}
                                                                        onClickDownload={(e) => {
                                                                            API.donwloadCollectionZip(collection.id, collection.collection_name)
                                                                        }}
                                                                        onClickRename={(e) => {
                                                                            e.preventDefault()
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                selectedCollectionId: collection.id,
                                                                                renameCollection: true,
                                                                                collection_name: collection.collection_name
                                                                            }))
                                                                        }}
                                                                        onclickDuplicate={(e) => {
                                                                            e.preventDefault()
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                showCopyImagePopup: true,
                                                                                oldCollectionId: collection.id,
                                                                                newCollectionName: `${collection.collection_name}Copy`,
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {collection.sharedData.isShared ? <div className='shared-badge'>
                                                                <img src={SharedIcon} style={{ height: "18px" }} />
                                                            </div> : <></>}
                                                            <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                                                                <div style={{ height: "24px", overflow: "hidden" }}>
                                                                    {
                                                                        collection.collection_name.length > 15 ?
                                                                            `${collection.collection_name.substring(0, 15)}...` :
                                                                            `${collection.collection_name}`
                                                                    }
                                                                </div>
                                                                <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div>
                                                                        {
                                                                            `${collection.images_count}`
                                                                        }
                                                                    </div>
                                                                    <div> {
                                                                        (() => {
                                                                            let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                                                            return `${dateFormat(date, "dd-mmm-yyyy")}`
                                                                        })()
                                                                    }</div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                    </>
                                </div> : <div className="rmp-dashboard-empty-data-message" >
                                    <img style={{ height: '10%', width: '10%', marginTop: "2%", marginTop: "2%" }} src={Noimageset} />
                                    <p style={{ margin: '10px' }}>No Recent Imagesets Available</p>
                                </div>
                            }
                        </div>}

                        <div style={{ display: "flex", marginTop: '25px', justifyContent: "space-between", }}>
                            <div className="services-content-title">Tasks
                                <span className='data-count'>
                                    {statusCount ? Number(statusCount.completed_task_count) +
                                        Number(statusCount.in_progress_task_count) +
                                        Number(statusCount.pending_task_count) +
                                        Number(statusCount.failed_task_count) : (0)}
                                </span>
                            </div>

                            {this.state.taskCount ?
                                <ButtonWithIcon text="View All"
                                    width="104px"
                                    linkData={{
                                        pathname: "/dronenaksha/tasks",
                                        state: { user, type, sidebarMode: this.state.sidebarMode, project, plan, plan_id: this.state.plan.id, }
                                    }} isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.MAPS].READ} /> : <></>}

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <div style={{ display: "flex" }}>
                                {tasks.length === 0 ? <div /> : <div style={{ display: 'flex' }}>
                                    <div className="task-count-number" style={{ color: "#11A087" }}>{
                                        // tasks.reduce((a, v) => (v.status === 'Completed' ? a + 1 : a), 0)
                                        statusCount && statusCount.completed_task_count ? statusCount.completed_task_count : 0
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Completed'}`} </div>
                                </div>}
                                {tasks.length === 0 ? <div /> : <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#fc6f03" }}>{
                                        // tasks.reduce((a, v) => (v.status === 'Pending' ? a + 1 : a), 0)
                                        statusCount && statusCount.in_progress_task_count ? statusCount.in_progress_task_count : 0
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'In Progress'}`} </div>
                                </div>}
                                {tasks.length === 0 ? <div /> : <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#3C3C3C" }}>{
                                        // tasks.reduce((a, v) => (v.status === 'Pending' ? a + 1 : a), 0)
                                        statusCount && statusCount.pending_task_count ? statusCount.pending_task_count : 0
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Pending'}`} </div>
                                </div>}
                                {tasks.length === 0 ? <div /> : <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#F02020" }}>{
                                        // tasks.reduce((a, v) => (v.status === 'Failed' ? a + 1 : a), 0)
                                        statusCount && statusCount.failed_task_count ? statusCount.failed_task_count : 0
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Failed'}`} </div>
                                </div>}
                            </div>
                        </div>
                        {tasks.length === 0 ?
                            <div className="rmp-dashboard-empty-data-message" >
                                <img style={{ height: '12%', width: '12%', padding: '0px', marginTop: "2%" }} src={Notask} />
                                <p style={{ marginTop: '10px' }}>No Recent Tasks Available</p>
                            </div> :

                            <table className="customers-table">
                                <col style={{ width: '35%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '18%' }} />
                                <col style={{ width: '17%' }} />
                                <tr className="customers-table-header-row">
                                    <th>Name
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={this.sortByName}
                                            sortDecending={this.reverseByName}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Status</th>
                                    <th>Images</th>
                                    <th>Plan</th>
                                    <th>Date Created
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}
                                            sortAscending={this.sortByDate}
                                            sortDecending={this.reverseByDate}
                                            sortASC={false}
                                        />
                                    </th>
                                </tr>
                                {tasks.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colSpan="6">No Tasks Available</td>
                                    </tr> :
                                    tasks.map((task) =>

                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                            <td>
                                                {/* {
                                                    <div className="add-user-form-text-wrapper" style={{ marginRight: '20px' }} >
                                                        <Link to={{
                                                            pathname: "/dronenaksha/view_plan",
                                                            state: {
                                                                user,
                                                                plan: this.props.location.state.plan,
                                                                project,
                                                                orthoImageCollectionId: task.image_collection_id,
                                                                layerShow: "RGBShow"
                                                            },
                                                        }}>
                                                            {task.task_name}<span style={{ color: "#2989cf" }}>{!this.state.isOwner ? ' (Shared)' : ''}</span>
                                                        </Link>
                                                    </div>
                                                } */}
                                                {<Link
                                                    style={{ color: "#707683" }}
                                                    to={{
                                                        pathname: "/dronenaksha/images",
                                                        state: {
                                                            user,
                                                            sidebarMode: this.state.sidebarMode,
                                                            task,
                                                            project,
                                                            plan: this.props.location.state.plan,
                                                            collection: { id: task.image_collection_id, plan_id: task.plan_id, collection_name: task.collection_name }
                                                        }
                                                    }}
                                                >
                                                    {task.task_name}<span style={{ color: "#2989cf" }}>{!this.state.isOwner ? ' (Shared)' : ''}</span>
                                                </Link>
                                                }
                                            </td>
                                            <td>{task.status}</td>
                                            <td>{task.images}</td>
                                            <td>{task.plan_name}</td>
                                            <td>
                                                {
                                                    (() => {
                                                        let date = (new Date(task.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                        return `${date}`
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )}
                            </table>}
                    </div>}
                </div>
            </div > : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(AeroFarmPlan);


