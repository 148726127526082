import React, { Component } from 'react';
import * as API from '../api.js';
import service from "../sidebaricon/Services.png";
import Loader from '../icons/loader.svg';
import SideBar from '../SideBar';
import { Link, Redirect } from 'react-router-dom';
import btnAdd from '../AeroCapture/assets/icons/btnAdd.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Menu from '../AeroCapture/assets/icons/menu.svg';
import { AnimatedModal } from './Reusable/AnimatedModal';
import dateFormat from 'dateformat';
import fail from '../assets/error.png';
import install from '../icons/AeroGCSInactive.png'
import TaskIcon from '../icons/servers.png'
import SupportIcon from '../icons/support.png'
import logList from '../icons/logList.png'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class allSubscription extends Component {
    state = {
        user: {},
        type: "",
        userperpage: 10,
        currentpage: 1,
        firstpage: 1,
        showBackIcon: true,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        subscriptionDeletePopup: false,
        subscriptionupdatePopup: false,
        plan_status: 'Active',
        plan_name: '',
        plans: [],
        redirect: false,
        currentPlan: {},
        service_id: 0,
        services: [],
        plan_id: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        discountCoupon: { discount: 0 }
    }
    
    onChangeListener = async (e) => {
        let t = e.target;

        let format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(t.value)) {
        } else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }

    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    saveSubscriptionPlan = () => {
        this.setState((state) => ({
            itemName: "Subscription Plan",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.saveSubscriptionPlan(this.state.plan_name, this.state.plan_status, this.state.service_id).then((result) => {
            if (result.data) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                    currentPlan: result.data,
                    service_id: this.state.services[0].id,
                    redirect: true
                }))
            } else {
                this.setState((state) => ({
                    ...state,
                    deletingTaskText: result.message,
                    createIcon: fail
                }))
            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    updateSubscriptionPlan = () => {
        this.setState((state) => ({
            itemName: "Subscription Plan",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.updateSubscriptionPlan(this.state.plan_id, this.state.plan_status, this.state.plan_name).then((data) => {
            if (data.data) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "COMPLETE",
                    processAction: "UPDATE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getSubscriptionPlan();
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "ERROR",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))

            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processMessage: e,
                showProcessPopup: true,
            }))
        })

    }

    getSubscriptionPlan = () => {
        API.getAllSubscriptionPlans().then((data) => {
            this.setState((state) => ({
                ...state,
                plans: data.plans,
                loading: false
            }))
        })
    }

    getServices = () => {
        API.getUserSubbedServices().then((data) => {
            this.setState((state) => ({
                ...state,
                services: data[1],
                loading: false,
                service_id: data[1][0].id
            }))
        })
    }
    deleteSubscriptionPlan = () => {
        this.setState((state) => ({
            itemName: "Subscription Plan",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteSubscriptionPlan(this.state.deletePlan.id).then((data) => {
            if (data.message == 'Subscription plan deleted.') {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                }), () => {
                    this.getSubscriptionPlan();
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Subscription Plan",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: data.message,
                    showProcessPopup: true,
                }))

            }
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                itemName: "Subscription Plan",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))

        })
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/admin/new_subscription",
                state: {
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode,
                    config: this.state.config,
                    plan: this.state.currentPlan
                }
            }} />
        }
    }

    componentWillMount() {
        document.title = "DroneLab - Subscription";
        if (this.props.location.state) {
            let { user, type, sidebarMode } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type, sidebarMode
            }), () => {
                this.getSubscriptionPlan()
                this.getServices()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getSubscriptionPlan()
                    this.getServices()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }



    render() {
        let { user, type } = this.state


        let sidebarConfigurations = {

            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/"
                },
                {
                    icon: install,
                    text: "Installations",
                    path: "/admin/gcs-installation",
                },
                // {
                //     icon: service,
                //     text: "Subscription",
                //     path: "/"
                // },
                {
                    icon: TaskIcon,
                    text: "Tasks",
                    path: "/admin/tasks",
                },
                {
                    icon: logList,
                    text: "Task Logs",
                    path: "/admin/taskLogs",
                },
                {
                    icon: SupportIcon,
                    text: "Support",
                    path: "/admin/supportEmails",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,

        }


        return (
            user.username ?
                <div className="wrapper">

                    <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
                    <div className="right-content"
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >
                        {this.successRedirectComponent()}

                        {/* -------------- Activity Popup ----------------------- */}

                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => this.closeActivityPopup()}
                            onClickOk={() => this.deleteSubscriptionPlan()}
                        />

                        {/* --------------------------------------------------------------- */}
                        <div className="top-bar"><div className="top-bar-text">Subscription</div></div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div className="main-content" style={{ background: 'none' }}>
                                <AnimatedModal
                                    isOpen={this.state.subscriptionAddPopup}
                                    onRequestClose={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            inviteModal: false,
                                        }))
                                    }}
                                    height="460px"
                                    width="500px"
                                >

                                    <div style={{ textAlign: "center", margin: '20px 0px', height: '40px', fontSize: '22px', fontWeight: '500' }}>Add Subscription Plan</div>
                                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                                    <div style={{ margin: '10px 50px' }}>
                                        <div className='add-user-form-label'>Subscription Plan Name</div>
                                        <div className="add-user-form-text-wrapper">
                                            <input
                                                className="sub-add-user-form-text"
                                                type="text"
                                                name="plan_name"
                                                placeholder="Enter Subscription Name"
                                                onChange={this.onChangeListener}
                                                value={this.state.plan_name}
                                            />
                                        </div>
                                        <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                            Service
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <select
                                                name="service_id"
                                                onChange={this.onChangeListener}
                                                value={this.state.service_id}
                                                className="sub-add-user-form-text"
                                            >
                                                {this.state.services.map((item) => {
                                                    return <option value={item.id}>{item.title}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                            Subscription Status
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <select
                                                name="plan_status"
                                                onChange={this.onChangeListener}
                                                value={this.state.plan_status}
                                                className="sub-add-user-form-text">
                                                {/* <option>Select Status</option> */}
                                                <option value={'Active'}>Active</option>
                                                <option value={'Inactive'}>Inactive</option>
                                                {/* {this.state.projectNameList.map((projectName) =>
                                                    <option>{projectName}</option>
                                                )} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                                        <button
                                            className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    subscriptionAddPopup: false,
                                                }));
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        {this.state.plan_name ?
                                            // <Link
                                            // to={{
                                            //     pathname: `/admin/new_subscription`,
                                            //     state: { user, type, sidebarMode: this.state.sidebarMode }
                                            // }}
                                            // >
                                            <button
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        subscriptionAddPopup: false,
                                                    }), () => {
                                                        this.saveSubscriptionPlan();
                                                    })
                                                }}
                                            >
                                                Create
                                            </button>
                                            // </Link>
                                            : <button style={{ opacity: '0.5' }}
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                            >
                                                Create
                                            </button>}
                                    </div>
                                </AnimatedModal>

                                <AnimatedModal
                                    isOpen={this.state.subscriptionupdatePopup}
                                    onRequestClose={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            inviteModal: false,
                                        }))
                                    }}
                                    height="460px"
                                    width="500px"
                                >

                                    <div style={{ textAlign: "center", margin: '20px 0px', height: '40px', fontSize: '22px', fontWeight: '500' }}>Update Subscription Plan</div>
                                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                                    <div style={{ margin: '10px 50px' }}>
                                        <div className='add-user-form-label'>Subscription Plan Name</div>
                                        <div className="add-user-form-text-wrapper">
                                            <input
                                                className="sub-add-user-form-text"
                                                type="text"
                                                name="plan_name"
                                                placeholder="Enter Subscription Name"
                                                onChange={this.onChangeListener}
                                                value={this.state.plan_name}
                                            />
                                        </div>
                                        <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                            Service
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <select
                                                disabled
                                                name="service_id"
                                                onChange={this.onChangeListener}
                                                value={this.state.service_id}
                                                className="sub-add-user-form-text"
                                            >
                                                {this.state.services.map((item) => {
                                                    return <option value={item.id}>{item.title}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div className="add-user-form-label" style={{ marginTop: '15px' }}>
                                            Subscription Status
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <select
                                                name="plan_status"
                                                onChange={this.onChangeListener}
                                                value={this.state.plan_status}
                                                className="sub-add-user-form-text">
                                                {/* <option>Select Status</option> */}
                                                <option>Active</option>
                                                <option>Inactive</option>
                                                {/* {this.state.projectNameList.map((projectName) =>
                                                    <option>{projectName}</option>
                                                )} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                                        <button
                                            className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    subscriptionupdatePopup: false,
                                                }));
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        {this.state.plan_name ?
                                            // <Link
                                            // to={{
                                            //     pathname: `/admin/new_subscription`,
                                            //     state: { user, type, sidebarMode: this.state.sidebarMode }
                                            // }}
                                            // >
                                            <button
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        subscriptionupdatePopup: false,
                                                    }), () => {
                                                        this.updateSubscriptionPlan();
                                                    })
                                                }}
                                            >
                                                Update
                                            </button>
                                            // </Link>
                                            : <button style={{ opacity: '0.5' }}
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                            >
                                                Update
                                            </button>}
                                    </div>
                                </AnimatedModal>
                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                    <div className="services-content-title">{"Subscriptions"}</div>
                                    <div style={{ display: 'flex' }}>

                                        <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto" }}>
                                            <Link to={{ pathname: "/admin/discount_coupons", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px',marginRight:"20px" }}>Discount coupons</div>
                                            </Link>
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto" }}>
                                            <Link to={{ pathname: "/admin/subscription_items", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px' }}>View Items</div>
                                            </Link>
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                            {/* <Link to={{ pathname: "/aerocapture/create_project", state: { user, type, sidebarMode: this.state.sidebarMode } }}> */}
                                            <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    plan_name: '',
                                                    plan_status: 'Active',
                                                    subscriptionAddPopup: true,
                                                }));
                                            }} />
                                            {/* </Link> */}
                                        </div>


                                    </div>
                                </div>
                                <div>
                                    <table className="customers-table">
                                        <col style={{ width: '35%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '5%' }} />
                                        <tr className="customers-table-header-row">
                                            <th style={{ textAlign: 'center' }}>id</th>
                                            <th>Title</th>
                                            <th style={{ textAlign: 'center' }}>Date</th>
                                            <th >Status</th>
                                            <th></th>
                                        </tr>
                                        {this.state.plans.length === 0 ?
                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                <td colspan="4">
                                                    {/* <img style={{height:'30%', width:'30%'}} src={Noproject} /> */}
                                                    <p style={{ fontSize: '18px' }}>No plans available</p>
                                                </td>
                                            </tr> :
                                            this.state.plans.map((plan) =>
                                                <tr className="customers-table-data-row" style={{ height: "45px", cursor: 'pointer' }}>
                                                    <td style={{ textAlign: 'center' }}>{plan.id}</td>
                                                    <td>
                                                        <Link
                                                            style={{ color: "#707683" }}
                                                            to={{
                                                                pathname: "/admin/new_subscription",
                                                                state: {
                                                                    user,
                                                                    type,
                                                                    sidebarMode: this.state.sidebarMode,
                                                                    plan
                                                                },
                                                            }}
                                                        >
                                                            {plan.plan_name} {plan.plan_type}
                                                        </Link>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(plan.created_date, "dd-mm-yyyy")}</td>
                                                    <td >{plan.status}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Dropdown style={{ margin: "0 20px" }}>
                                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu size="sm" title=""
                                                                className="custom-dropdown-settings"
                                                            >
                                                                <Dropdown.Item eventKey="delete"
                                                                    className="dropdown-set-padding"
                                                                >
                                                                    <div style={{ display: "flex", cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                plan_name: plan.plan_name,
                                                                                plan_status: plan.status,
                                                                                subscriptionupdatePopup: true,
                                                                                plan_id: plan.id,
                                                                                service_id: plan.service_id
                                                                            }))
                                                                        }}
                                                                    >
                                                                        {/* <img src={Delete} /> */}
                                                                        <div style={{ marginLeft: '10px' }}>Edit</div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item eventKey="delete"
                                                                    className="dropdown-set-padding"
                                                                >
                                                                    <div style={{ display: "flex", cursor: 'pointer' }}
                                                                        onClick={(e) => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                plan_name: plan.plan_name,
                                                                                deletePlan: plan,
                                                                                itemName: "Subscription Plan",
                                                                                processPopupIcon: "WARNING",
                                                                                processAction: "DELETE",
                                                                                processMessage: null,
                                                                                showProcessPopup: true,
                                                                            }))
                                                                        }}
                                                                    >
                                                                        {/* <img src={Delete} /> */}
                                                                        <div style={{ marginLeft: '10px' }}>Delete</div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>


                                            )}
                                    </table>

                                </div>
                            </div>}
                        
                    </div>
                </div> : <div />
        );
    }
}

export default allSubscription;

