import React, { useState } from 'react';
import Upload from "../icons/Upload.svg"
import Close from "../icons/blackClose.png"
const FileImporter = ({ placeholder, onImportFile, accept, multiple }) => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFiles(Array.from(event.target.files))
        const reader = new FileReader();

        reader.onload = (e) => {
            const content = e.target.result;
            onImportFile(content)
        };

        reader.readAsText(file);
        event.target.value = ''
    };

    return (
        <div >
            <input multiple={multiple} accept={accept} type="file" id="fileInput" class="ghb-file-input" onChange={handleFileChange} />
            {selectedFiles.length == 0 && <div class="ghb-custom-file-label" onClick={() => {
                document.getElementById("fileInput").click()
            }}>
                <div style={{ display: 'flex', alignItems: "baseline", height: '100%' }}>
                    <img src={Upload} style={{ height: '16px' }} />
                </div>
                <span for="fileInput" >{placeholder ? placeholder : 'Choose File'}</span>
            </div>}
            <div style={{ display: 'flex', gap: "10px" }}> {(selectedFiles).map((file, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", gap: "5px", fontSize: "14px", color: "#2989cf", height: '32px' }}>
                    <span> {file.name}</span>
                    <img src={Close} style={{ cursor: "pointer", height: "20px" }} onClick={() => {
                        onImportFile()
                        setSelectedFiles(prev => prev.filter(f => f.name !== file.name))
                    }} />
                </div>
            ))}</div>
        </div>
    );
};

export default FileImporter;
