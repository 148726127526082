import React, { useEffect, useState } from 'react'
import ghbLogo from '../../../userAuth/assets/ghblogo.png'
import { useDispatch, useSelector } from 'react-redux';
import Logout from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider
} from '@mui/material'
import { Link } from 'react-router-dom';
import { Popup, addUserAnalytic } from '../../../ReusableComponents/reusableFunctions.js';
import { getUserType } from '../../../api.js';
import { updateProfileDetails } from '../../../redux/index.js';
import SideBar from './SideBar.js';
import { getActiveTeam, getTeams, setActiveTeam } from '../../../Teams/index.js';
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { isRightSlider } from '../Recoil/atom.js';

const DroneLabWrapper = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const activeProfile = useSelector((state) => state.profile)
    const isRightSliderOpen = useRecoilValue(isRightSlider)
    const [user, setUser] = useState(false)
    const [teams, setTeams] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currActiveTeam, setCurrActiveTeam] = useState({})
    const open = Boolean(anchorEl);


    const switchTeam = async (team, noReload) => {
        await setActiveTeam(team)
        dispatch(updateProfileDetails({
            activeTeamId: team.teamId,
            activeTeam: team.teamName,
            isLoggedInUserOwner: team.isOwner
        }))
        if (!(noReload) && location.pathname.includes('/ghb') && (location.pathname.includes('/planner') || (location.pathname == '/') || (location.pathname.includes('dashboard') && !location.pathname.includes("aerogcsenterprise"))))
            window.location.reload()
        return
    }

    const getTeamsListOfUser = async () => {
        const teams = getTeams()
        const activeTeam = await getActiveTeam()
        setCurrActiveTeam(activeTeam)
        if (activeTeam)
            dispatch(updateProfileDetails({
                activeTeamId: activeTeam.teamId,
                activeTeam: activeTeam.teamName,
                isLoggedInUserOwner: activeTeam.isOwner
            }))
        setTeams(teams)
    }

    const getTypeOfUser = async () => {
        try {
            const [type, undefined, user] = await getUserType();
            setUser(user);
            dispatch(updateProfileDetails({ type, user }));
        } catch (e) {
            console.error(e);
            Popup.alert('ERROR', e)
        }
    }

    useEffect(() => {
        getTypeOfUser()
        getTeamsListOfUser()
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clearLocalStorage = () => {
        localStorage.removeItem("recentSearches")
        localStorage.removeItem('dateUnit')
        addUserAnalytic('/logout')
    }

    return (
        <div className="wrapper" >
            <div style={{ height: '100%', width: '100%', overflow: isRightSliderOpen ? '' : 'hidden' }}>
                <div style={{ position: 'relative', top: '0px', left: '0px', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 45px 0 14px', background: '#104C91' }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                        <img src={ghbLogo} style={{ height: '45px' }} />
                        <div style={{ fontWeight: 600, color: "white", marginTop: "10px" }}>
                            {props.header}
                        </div>
                    </div>
                    {user &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <React.Fragment>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <IconButton onClick={handleClick} size="small" sx={{ p: 0 }}>
                                        <img className="sidebar-user-picture" style={{ width: 40, height: 40 }}
                                            src={user.avatar_url === null ? `/images/${user.first_name[0].toLowerCase()}.PNG` : `${user.avatar_url}?${user.sas_token
                                                }&t${new Date().getTime()}`}
                                        />
                                    </IconButton>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            width: "170px",
                                            fontSize: "10px",
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {(activeProfile?.activeTeam || currActiveTeam) &&
                                        <div style={{
                                            overflow: "auto",
                                            maxHeight: "400px"
                                        }}>
                                            {
                                                teams?.map(team => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                switchTeam(team)
                                                            }} style={{
                                                                color: "black"
                                                            }}
                                                        >
                                                            {team.isOwner ?
                                                                <div style={{ background: (activeProfile.activeTeam == team.teamName || getActiveTeam()?.teamId == team?.teamId) ? "#dcdcfb" : "" }}>
                                                                    <MenuItem>
                                                                        <span style={{ color: "#2989CF" }} className="profile-toggle-text" >
                                                                            {team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}
                                                                        </span>
                                                                    </MenuItem>
                                                                </div> :
                                                                <div style={{ background: (activeProfile.activeTeam == team.teamName || getActiveTeam()?.teamId == team?.teamId) ? "#dcdcfb" : "" }}>
                                                                    <MenuItem>
                                                                        <span className="profile-toggle-text">
                                                                            {team.teamName.length > 15 ? `${team.teamName.substring(0, 15)}...` : team.teamName}
                                                                        </span>
                                                                    </MenuItem>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            <Divider />
                                        </div>
                                    }
                                    <Link
                                        to={{
                                            pathname: "/profile-settings",
                                            state: {
                                                isFromDroneLabDashboard: true
                                            }
                                        }}
                                        style={{ color: "black" }}
                                    >
                                        <MenuItem>
                                            <ListItemIcon>
                                                <SettingsIcon fontSize="small" />
                                            </ListItemIcon>
                                            <span className="profile-toggle-text">
                                                Settings
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <Link onClick={() => clearLocalStorage()} to="/logout" style={{ color: "black" }}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <Logout fontSize="small" />
                                            </ListItemIcon>
                                            <span className="profile-toggle-text">
                                                Logout
                                            </span>
                                        </MenuItem>
                                    </Link>
                                </Menu>
                            </React.Fragment>
                            <div style={{ fontSize: '14px', color: "#ffffff", textAlign: 'end' }}>
                                {`${user.first_name}`}  {`${user.last_name}`}
                            </div>
                        </div>
                    }
                </div>
                <div style={{ display: 'flex', height: 'calc(100vh - 60px)', position: 'relative' }}>
                    <SideBar
                        config={props.sidebarConfig}
                        height={"initial"}
                        borderColor={'#017bff'}
                    />
                    {props.children}
                </div>
            </div>
        </div >
    )
}

export default DroneLabWrapper