import React, { Component } from "react";
import * as API from "./api.js";
import ServicesTileTray from "./servicesTileTray";
import ServicesCard from "./ServicesCard";
import TeamsInActive from './sidebaricon/TeamsInactive.png'

import Help from "./sidebaricon/Help.png";
import Settings from "./sidebaricon/Settings.png";
import Planner from "./icons/calenderInactive.png"
import Serviceactive from "./sidebaricon/ServicesActive.png";

import Loader from "./icons/loader.svg";

// import Settings from './settings.png';

import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from "./redux";

import workflowIcon from "./workflow.svg";
import SideBar from "./SideBar";
import { Modal } from "react-bootstrap";
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions'
import WelcomePopup from "./WelcomePopup.js";
import ServeyPopup from "./SurveyPopup.js";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import compDashboardInactive from './icons/compDashboardInactive.png'
import { isTeamOwner } from "./Teams";
import { sideBarPaths } from "./sideBarPaths.js";

class Services extends Component {
  state = {
    user: {},
    type: "",
    leftBorder: false,
    services: [],
    loading: true,
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    showWelcomePopup: false,
    showServeyPopup: false,
    isAdmin: false,
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    surveyStatus: false,
    outId: "",
    isOwner: undefined
  };

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  getSubbedServices = async () => {
    API.getUserSubbedServices().then(
      (services) => {
        if (services && services[0] && services[0][0]) {
          services[0][0].description = "Updated DroneLab settings";
        }
        let storeServices = services[0].filter(
          (service) =>
            service.title === "COVID-19" ||
            service.title === "ML Tool" ||
            service.title === "Micro CRM" ||
            service.title === "AeroStream" ||
            service.title === "NPNT Services" ||
            service.title === "AI Workflows" ||
            service.title === "Admin Tools" ||
            service.title === "GCS Suite Configuration" ||
            service.title === "PicStork" ||
            service.title === "AeroGCS Enterprise" ||
            service.title === "DroneNaksha" ||
            service.title === "Analytic Tool"
        );

        let names = services[0].map((service) => { return service.title })
        var admin = false
        if (names.findIndex(data => data == "Admin Tools") != -1)
          admin = true
        this.checkIsOwner()
        this.setState((state) => ({
          ...state,
          services: storeServices,
          loading: false,
          isAdmin: admin
        }));
      },
      (e) => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  componentDidMount() {
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  checkIsOwner = async () => {
    this.setState({
      isOwner: await isTeamOwner()
    })
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
  }

  componentWillMount() {
    addUserAnalytic("/")
      .then(id => {
        this.setState((state) => ({
          outId: id
        }))
      })
    document.title = "Services - DroneLab";
    localStorage.removeItem('dateUnit')
    this.checkSurveyStatus()
    this.checkIsOwner()
    if (this.props.location.state) {
      let { user, type } = this.props.location.state;
      this.props.activesubscription({
        activesubscription: undefined,
      });
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
        }),
        () => {
          this.props.updateProfileDetails({
            user: user,
            type: type,
          });
          this.getSubbedServices();
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => {
              this.props.updateProfileDetails({
                user: user,
                type: type,
              });
              this.getSubbedServices();
            }
          );
        },
        (e) => {
          console.log(e)
          window.location.replace("/login");
        }
      );
    }
  }


  downloadGCSFunc = () => {
    window.open(
      "https://download.aeromegh.in/api/files/latest/AeroGCS/stable",
      "_blank"
    );
  };

  submitSurvey = (data) => {
    this.setState((state) => ({
      ...state,
      showServeyPopup: false,
    }));
    let { industry, role, teamMembers, phoneNo, country } = data
    API.addUserSurvey(industry, role, teamMembers, phoneNo, country).then((data) => {
    }).catch((err) => {
      this.setState((state) => ({
        ...state,
        itemName: "",
        processPopupIcon: "ERROR",
        processAction: "ADD",
        processMessage: "Something went wrong while adding your details",
        showProcessPopup: true,
      }))
    })
  }

  checkSurveyStatus = () => {
    API.checkSurveyStatus().then((data) => {
      this.setState((state) => ({
        ...state,
        showWelcomePopup: !data.survey
      }))
    })
  }

  closeWelcomePopup = () => {
    this.setState((state) => ({
      ...state,
      showWelcomePopup: false,
      showServeyPopup: true
    }));
  };

  changeColor = (e) => {
    e.preventDefault();
    const refs = ["home", "customer", "service"];
    refs.map((ref) => this.refs[ref].classList.remove("text-color"));
    e.target.classList.add("text-color");

    const { name } = Object.values(e.target)[1];

    this.setState((state) => ({
      ...state,
      selected: name,
    }));
  };

  render() {
    let { user, type, services, sidebarMode } = this.state;
    let sidebarConfigurations = {
      options: !this.state.isOwner ? [
        {
          icon: Serviceactive,
          text: "Services",
          path: "/",
          selected: true,
        },
        {
          icon: TeamsInActive,
          text: "Teams",
          path: "/teams",
        },
        {
          icon: Planner,
          text: "Planner",
          path: "/planner",
        },
        {
          icon: Settings,
          text: "Settings",
          path: "/profile-settings",
        },
        {
          icon: Help,
          text: "Help",
          path: "/feedback",
        },
      ] :
        [
          {
            icon: Serviceactive,
            text: "Services",
            path: "/",
            selected: true,
          },
          {
            icon: TeamsInActive,
            text: "Teams",
            path: "/teams",
          },
          {
            icon: Planner,
            text: "Planner",
            path: "/planner",
          },
          {
            icon: compDashboardInactive,
            text: "Dashboard",
            path: "/progress-dashboard",
          },
          {
            icon: Settings,
            text: "Settings",
            path: "/profile-settings",
          },
          {
            icon: Help,
            text: "Help",
            path: "/feedback",
          },
        ]
      ,
      user,
      type,
      mode: this.state.sidebarMode,
    };

    let unsubbedServices = [
      {
        image: workflowIcon,
        title: "AI Workflow",
        description: "Build and deploy data analytics workflows",
        otherDetails:
          "Supporting text to explain the services in max 50 words. this would have some details of the service. Supporting text to explain the services in max 50 words. this would have some details of the service. Supporting text to explain the services in max 50 words. this would have some details of the serviceSupporting text to explain the services in max 50 words. this would have some details of the service",
      },
    ];

    return user.username ? (
      <div className="wrapper">
        <div style={{ zIndex: this.state.showWelcomePopup || this.state.showServeyPopup ? 1 : 99 }}>
          <SideBar
            config={sidebarConfigurations}
            parentSidebarSwitch={this.parentSidebarSwitch}
          /></div>
        <div
          className="right-content"
          onClick={() => {
            this.setState((state) => ({
              ...state,
              sidebarMode: "shrunk",
            }));
          }}
        >

          {/* ------- Welcome Popup ------- */}
          <WelcomePopup
            show={this.state.showWelcomePopup}
            user={user}
            close={() => {
              this.closeWelcomePopup();
            }}
          />

          <ServeyPopup
            show={this.state.showServeyPopup}
            user={user}
            submit={(data) => {
              this.submitSurvey(data);
            }}
          />

          {/* -------------- Activity Popup ----------------------- */}
          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.closeActivityPopup()}
          />
          {/* --------------------------------------------------------------- */}

          <div className="top-bar">
            <div className="top-bar-text">SERVICES</div>
          </div>
          {/* <TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar> */}
          {this.state.loading ? (
            <div
              style={{
                height: "85vh",
                width: "91vw",
                display: "flex",
                textAlign: "center",
              }}
            >
              <img
                src={Loader}
                style={{ height: "30vh", width: "100vw", margin: "auto" }}
              />
            </div>
          ) : (
            <div className="main-content" style={{ background: "white" }}>
              {/* <TileTray tiles={services} user={user} type={type} sidebarMode={sidebarMode}></TileTray> */}
              {this.props.SubscriptionData ? (
                <></>
              ) : (
                <Modal
                  style={{ marginTop: "150px" }}
                  show={true}
                  onHide={() => {
                    window.location.replace("/login");
                  }}
                >
                  <Modal.Body>
                    <p>
                      Your don't have any active subscription plan, please
                      contact us and we will help you
                      with necessary actions.{" "}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <button
                        className="add-user-form-submit-button popup-btn-right btn-ok"
                        onClick={() => {
                          window.location.replace("/login");
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
              {this.state.isAdmin}
              {this.state.isAdmin ?
                <ServicesTileTray
                  mainServices="true"
                  tiles={services}
                  user={user}
                  type={type}
                  sidebarMode={sidebarMode}
                /> : (
                  <ServicesCard
                    mainServices="true"
                    tiles={services}
                    user={user}
                    type={type}
                    sidebarMode={sidebarMode} />
                )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    firstTimeLoginGCSE: state.profile.firstTimeLoginGCSE,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
