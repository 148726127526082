import React, { useEffect, useState } from 'react';
import pdf from "../../assets/icons/pdf.svg";
import dwg from "../../assets/icons/dwg.svg";
import excel from "../../assets/icons/exel.svg";
import zip from "../../assets/icons/zip-file.png";
import dxf from "../../assets/icons/dxf-file.png";
import download from "../../assets/icons/download.svg";
import jpg from "../../assets/icons/jpg.svg";
import googleDocs from "../../assets/icons/google-docs.png";
import { excelExtensions } from './FilesContainer';

const FileHolder = ({ file }) => {
    const [icon, setIcon] = useState(googleDocs)

    const identifyFileType = () => {
        file?.name?.split('.').pop() === 'pdf' ? setIcon(pdf) :
            file?.name?.split('.').pop() === 'dwg' ? setIcon(dwg) :
                file?.name?.split('.').pop() === 'zip' ? setIcon(zip) :
                    file?.name?.split('.').pop() === 'dxf' ? setIcon(dxf) :
                        file?.name?.split('.').pop() === 'jpg' ? setIcon(jpg) :
                            file?.name?.split('.').pop() === 'JPG' ? setIcon(jpg) :
                                excelExtensions.includes(file?.name?.split('.').pop()) ? setIcon(excel) :
                                    setIcon(googleDocs)
    }

    const openFileInNewTab = (fileUrl) => {
        var anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.target = '_blank';
        anchor.click();
    }

    const formatFileName = (filename) => {
        if (filename?.length > 30) {
            return filename.substring(0, 30) + "...";
        }
        return filename;
    }

    const downloadFileInNewTab = async (fileUrl, fileName) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Download error:', error);
        }
    };

    useEffect(() => {
        identifyFileType()
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: "center", gap: "15px", flexDirection: 'column', cursor: "pointer" }}>
            <div style={{ width: "115px", position: "relative", display: "flex", flexDirection: "column", alignItems: 'center', height: "115px" }} onClick={() => {
                openFileInNewTab(file.blob_url)
            }}>
                <img src={icon} alt='file icon' style={{ width: "64px", marginBottom: "15px" }} />
                <div style={{ textAlign: "center", fontSize: "12px", width: "115px", wordBreak: "break-all" }}>{formatFileName(file.name)}</div>
                <img src={download} alt='download' style={{ position: "absolute", bottom: "45px", right: "20px", height: "27px", display: 'flex', alignItems: "center", justifyContent: "center" }} onClick={(e) => {
                    e.stopPropagation();
                    downloadFileInNewTab(file.blob_url, file.name);
                }} />
            </div>
        </div>
    )
}

export default FileHolder