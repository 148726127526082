import React from 'react'
import Select from "react-select"

const SelectDropDown = (props) => {
    const noOptionsMessage = () => `${props.noOptionsMessage}`;
    const defaultStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            borderRadius: "13px",
            borderColor: '#D9D9D9',
            fontWeight: 600,
            color: "black",
            minHeight: "30px",
            height: "max-content",
            boxShadow: "none",
            outline: "none",
            cursor: "pointer",
            opacity: props.isDisabled ? "0.5" : "1",
            ...props.controlStyle
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShdow: "rgba(98, 115, 43, 0.1) 0px 0px 8px 2px",
            color: state.isFocused && !state.isSelected ? "#3c3c3c" : state.isSelected ? "white" : state.isSelected && state.isFocused ? "white" : "#3c3c3c",
            backgroundColor: state.isFocused && !state.isSelected ? "rgba(0, 0, 0, 0.1)" : state.isSelected ? "#0F5EFF" : "white",
            height: "max-content",
            fontWeight: '400',
            ...props.optionStyle,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "2px 11px",
            ...props.valueContainerStyle,

        }),
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            border: "#C3CDD9",
            borderRadius: "12px",
            overflowX: "hidden",
            overflowY: 'auto',
            fontFamily: '"Poppins", sans-serif',
            fontSize: '12px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            maxHeight: 160,
            width: "100%",
            borderRadius: "12px"
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#66666680",
            fontSize: "13px",
            fontWeight: '400'
        }),
        indicatorSeparator: () => ({
            display: !props.separator ? "none" : "flex"
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28px',
            padding: '7px',
            ...props.indicatorsContainerStyle
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            ...props.indicatorContainerStyle
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            ...props.dropdownIndicatorStyle
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...props.singleValueStyle,
            fontFamily: '"Poppins", sans-serif', // Change this to your desired font family
            fontSize: '12px',
            fontWeight: '400'
        })
    };

    return (
        <Select
            noOptionsMessage={noOptionsMessage}
            menuPlacement='auto'
            placeholder={props.placeholder}
            className={props.className || ""}
            classNamePrefix="react-select"
            components={props.components}
            value={props.value}
            onChange={props.onChange}
            isLoading={false}
            options={props?.list}
            styles={defaultStyles}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: "#0F5EFF",
                    primary: "#0F5EFF"
                }
            })}
            isSearchable={props.isSearchable ? props.isSearchable : false}
            isDisabled={props.isDisabled}
        />
    )
}

export default SelectDropDown;