import React from 'react';
import HeaderContainer from './HeaderContainer';
import LayersContainer from './LayersContainer';
import { importedLayerPropeties, layerPropeties } from '../Recoil/atom';
import { useRecoilValue } from 'recoil';
import LayerPropertiesContainer from './LayerPropertiesContainer';
import MeasurementDetails from './MeasurementDetails';

const RightSlider = ({ close, handleClose, propertiesLayers, blockLayers, encLayers, map, onUpdateMeasurement, selectedMeasurement, onDeleteMeasurement, onChangePropertyAsset }) => {
    const layerPropety = useRecoilValue(layerPropeties)
    const importedLayerPropetiesValue = useRecoilValue(importedLayerPropeties)
    return (
        <div style={{ position: 'absolute', right: close ? "0" : "-300px", top: '70px', width: "300px", height: "100%", zIndex: 1, background: "white", transition: "right 0.5s" }}>
            <HeaderContainer
                title={"Layers"}
                closeCB={() => {
                    handleClose()
                }}
                showDelete={selectedMeasurement?.id}
                onDelete={() => {
                    onDeleteMeasurement()
                }}
            />
            <div style={{ height: "calc(100% - 130px)", overflow: "auto" }}>
                {selectedMeasurement?.id &&
                    <div style={{ padding: "12px", borderBottom: "1px solid rgb(218,220,224)" }}>
                        <MeasurementDetails
                            title={"Name"}
                            value={selectedMeasurement.name}
                            onUpdateMeasurementDetails={(name) => {
                                onUpdateMeasurement(selectedMeasurement.layer, undefined, name)
                            }}
                        />
                        <MeasurementDetails
                            title={"Description"}
                            value={selectedMeasurement.description}
                            onUpdateMeasurementDetails={(color) => {
                                onUpdateMeasurement(selectedMeasurement.layer, undefined, undefined, color)
                            }}
                        />
                    </div>
                }
                {(Object.entries(layerPropety).length > 0 && selectedMeasurement.id) &&
                    <LayerPropertiesContainer
                        properties={layerPropety}
                        selectedMeasurement={selectedMeasurement}
                        onUpateLayerColor={(layer, color) => {
                            onUpdateMeasurement(layer, color)
                        }}
                    />
                }

                {Object.entries(importedLayerPropetiesValue).length > 0 &&
                    <LayerPropertiesContainer
                        properties={importedLayerPropetiesValue}
                        selectedMeasurement={undefined}
                        onUpateLayerColor={(layer, color) => {
                        }}
                    />
                }

                <LayersContainer
                    propertiesLayers={propertiesLayers}
                    blockLayers={blockLayers}
                    encLayers={encLayers}
                    map={map}
                    onUpateLayerColor={(layer, color) => {
                        onUpdateMeasurement(layer, color)
                    }}
                    onChangePropertyAsset={(propertyAsset, layerGrp) => {
                        onChangePropertyAsset(propertyAsset, layerGrp)
                    }}
                />
            </div>
        </div>
    )
}

export default RightSlider