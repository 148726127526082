import React from 'react';
import closeIcon from '../assets/icons/closeNew.svg'

const PolygonNamePopup = ({ onCloseCallback, name }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", padding: "7px", background: "#FFF3DC", borderRadius: "8px", width: "max-content", gap: "7px" }}>
            <div style={{ display: 'flex', alignItems: "center", gap: "5px", color: "#3c3c3c", fontSize: "12px", fontWeight: 600 }}>
                <div>{name}</div>
            </div>
            {onCloseCallback &&
                <img src={closeIcon} alt='close icon' style={{ cursor: "pointer", width: "7px" }} onClick={onCloseCallback} />
            }
        </div>
    )
}

export default PolygonNamePopup