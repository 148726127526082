import React, { Component } from 'react';
import dashboardIcon from '../../icons/AeroCaptureInactive.png';
import service from "../../sidebaricon/Services.png";
import Search from "../../Icon/Search.png";
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import Menu from '../assets/icons/menu.svg';
import { connect } from "react-redux";
import { addUserAnalytic, updateOutTime, getMaxPageIndex, checkSubscriptionAndStorage } from '../../ReusableComponents/reusableFunctions';
import detailsIcon from '../../assets/Icons/setDetails.png'
import classesIcon from '../../assets/Icons/setClasses.png'
import summaryIcon from '../../assets/Icons/setSummary.png'
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import TaskIcon from '../../icons/serversActive.png'
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import Help from "../../sidebaricon/Help.png";
import { DeleteComponentTable } from '../../ReusableComponents/Menu';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import AppConstants from '../../AppConstants';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { ActiveRole } from '../../Teams';
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));
class PicStorkAllTasks extends Component {
    //State intialization to user
    state = {
        user: {},
        type: "",
        key: "", //This will get key for search
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        services: [],
        tasks: [],
        plan: " ",
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        sharableLink: '',
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        annotationSets: [],
        currentStep: 0,
        task_name: "",
        accuracy: "",
        steps: [
            {
                title: "Details",
                icon: detailsIcon,
            },
            {
                title: "Accuracy",
                icon: classesIcon,
            },
            {
                title: "Summary",
                icon: summaryIcon,
            },
        ],
        createTaskMsg: "",
        addTrainingTaskPopup: false,
        classes: [],
        collection_id: "",
        collection_name: "",
        outId: "",
        imageCount: 0,
        taskType: 0,
        showAddTaskPopup: false,
        addDetectionTaskPopup: false,
        models: [],
        videoTask: false,
        video_id: "",
        fps: 1,
        selectedModels: [],
        media_name: "",
        media_id: null,
        scrollHeight: "180px",
        collections: [],
        videos: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        editTask: false,
        selectedTask: {},
        popupTitle: "",
        sortType: '',
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        completedCount: 0,
        failedCount: 0,
        inprogressCount: 0,
        pendingCount: 0,
        pageSize: 8,
        tableLoading: false,
        shareInviteModal: false,
        showSubscriptionExpirePopup: false
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    closeTaskPopup = () => {
        this.setState((state) => ({
            ...state,
            addTaskPopup: false,
            addDetectionTaskPopup: false,
            addTrainingTaskPopup: false,
            currentStep: 0,
            media_name: "",
            selected_video: "",
            fps: 1,
            task_name: "",
            accuracy: "",
            selectedModels: []
        }))
    }
    onClickNext = () => {
        let { steps, currentStep } = this.state;
        this.setState((state) => ({
            currentStep: currentStep + 1,
        }))
    }

    // State End Here-------------
    //Get SearchKey Function Start Here-----
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => {
                this.getTasks()
            }
        );
    }

    showEditTaskPopup = () => {
        if (this.state.selectedTask.tasktype == 1) this.openTrainingTaskPopup();
        else this.openDetectionTaskPopup();
    };

    handleChangeCopy = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(e.target.value)) {
            // setError("Spacial characters are not allowed.")
        } else
            if (e.target.value.includes(' ')) {
                // setError("Spaces are not allowed.")
            } else {
                // setError(undefined)
                this.setState({ task_name: e.target.value });
            }
    }

    onClickNext = () => {
        let { steps, currentStep } = this.state;
        this.setState((state) => ({
            currentStep: currentStep + 1,
        }));
    };

    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getTasks();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached next page")
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getTasks();
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        let maxPage = getMaxPageIndex(this.state.counts, 8)
        if (isNaN(num) || num > maxPage) {
            return null;
        }
        else if (num != 0) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.abs(num),
            }), () => this.getTasks());
        }
    }
    // Get Changenumber Page Function Ends here -------------------------

    getTasks = () => {
        this.setState(state => ({
            ...state,
            loading: false,
            tableLoading: true
        }))
        const { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userdisplay.slice(firstpage, lastIndex);
        // const totalpages = userdisplay.length / userperpage;
        let state = this.props.location.state
        API.GetAllTasksOfUser(userperpage, this.state.currentpage-1, key === "" ? " " : key).then(async (data) => {
            console.log(data);
            if (data.tasks && data.tasks.length == 0) {
                this.setState((state) => ({
                    ...state,
                    tasks: [],
                    tableLoading: false,
                    statusCount: {},
                }))
            } else {
                try {
                    let pendingTasks = await data.tasks.filter((task) => {
                        if (task.status == "Pending") {
                            return task
                        }
                    });
                    let tasks = await data.tasks.filter((task) => {
                        if (task.status != "Pending") {
                            return task
                        }
                    });
                    let allTasks = pendingTasks.concat(tasks)
                    this.setState((state) => ({
                        ...state,
                        counts: data.count,
                        tasks: allTasks,
                        tableLoading: false,
                        statusCount: data.statusCount,
                        completedCount: data.taskStats.completed_task_count == null ? 0 : data.taskStats.completed_task_count,
                        pendingCount: data.taskStats.pending_task_count == null ? 0 : data.taskStats.pending_task_count,
                        failedCount: data.taskStats.failed_task_count == null ? 0 : data.taskStats.failed_task_count,
                        inprogressCount: data.taskStats.in_progress_task_count == null ? 0 : data.taskStats.in_progress_task_count

                    }), () => {
                        if (this.state.sortType === 'A-Z') {
                            this.sortByName()
                        } else if (this.state.sortType === 'Z-A') {
                            this.reverseByName()
                        } else if (this.state.sortType === 'First Modified') {
                            this.sortByDate()
                        } else if (this.state.sortType === 'Last Modified') {
                            this.reverseByDate()
                        }
                    })
                } catch (e) {
                    console.log(e);
                }
            }
        }, (e) => {
            console.log(e);
        })
    }
    // getAllTask function ends here----------

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.tasks.sort((a, b) => a.task_name.localeCompare(b.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.tasks.sort((a, b) => b.task_name.localeCompare(a.task_name))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => a.date_created.localeCompare(b.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.tasks.sort((a, b) => b.date_created.localeCompare(a.date_created))
        this.setState((state) => ({
            ...state,
            tasks: sort,
        }));
    }



    deleteTask = () => {
        this.setState((state) => ({
            itemName: "Task",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        API.deleteAerocaptureTask(this.state.selectedTaskId).then((data) => {
            //console.log(data);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                tasks: []
            }), () => {
                this.getTasks();
            })
        }, (e) => {
            //console.log(e);
            this.setState((state) => ({
                itemName: "Task",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }


    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    // This is lifcycle method this runs before component loaded

    componentWillUnmount() {
        updateOutTime(this.state.outId)
        localStorage.removeItem('created_task')
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname)
            .then(id => {
                console.log("ID : ", id)
                this.setState((state) => ({
                    outId: id
                }))
            })

        document.title = "PicStork - Tasks";
        if (this.props.location.state) {
            let { user, type, project, plan } = this.props.location.state;
            console.log(this.props);
            this.setState((state) => ({
                ...state,
                user,
                type,
                project,
                plan
            }), () => {
                this.getTasks()
                checkSubscriptionAndStorage(AppConstants.SERVICES.PICSTORK).then(data => {
                    if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                    else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                })
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getTasks()
                    checkSubscriptionAndStorage(AppConstants.SERVICES.PICSTORK).then(data => {
                        if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                        else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                    })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    //Function

    //Render function is like main function runs first
    render() {

        let { user, type, sidebarMode, showBackIcon, tasks, currentpage, project, statusCount, videoTask, selectedModels,
            media_name, fps, models, collections, videos, steps, currentStep, task_name, accuracy, annotation_set_name,
            annotationSets, addDetectionTaskPopup, addTrainingTaskPopup, classes, plan, } = this.state

        return (
            user.username ? <div className="wrapper">
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                                project,
                                                plan
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/picstork/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"PicStork"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"selectBorder"} >
                                <li>
                                    <div
                                        className="sidecontent"
                                        id="sidecontent"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="sidebar-icon">
                                            <img
                                                className="sidebar-icon"
                                                src={TaskIcon}
                                                alt="serviceIcon"
                                            />
                                        </div>
                                        <div
                                            name="service"
                                            className={"sidebaropt"}
                                            style={{ color: "#2989CF" }}
                                            onClick={this.changeColor}
                                        >
                                            {"Tasks"}
                                        </div>
                                    </div>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>

                        </ul>
                    </div>
                </div>
                <div className="right-content"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {/*---------------------------------------------------------------- */}

                    {
                        this.state.showSubscriptionExpirePopup ?
                            <SubscriptionExpireModal
                                data={this.state.showSubscriptionExpirePopup}
                            /> : <></>
                    }
                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteTask()}
                    />
                    {/* --------------------------------------------------------------- */}

                    <div className="top-bar">
                        <div className="top-bar-text">
                            TASKS <ActiveRole />
                        </div>
                    </div>

                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div className="main-content" style={{ background: 'none' }}>

                            <div style={{ display: "flex" }}>
                                <div className="services-content-title">{"Tasks"}
                                    <span className='data-count'>({this.state.counts})</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "20px" }}>
                                <div style={{ display: 'flex' }}>
                                    <div className="task-count-number" style={{ color: "#11A087" }}>{
                                        this.state.completedCount
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Completed'}`} </div>
                                </div>
                                <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#fc6f03" }}>{
                                        this.state.inprogressCount
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'In Progress'}`} </div>
                                </div>
                                <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#3C3C3C" }}>{
                                        this.state.pendingCount
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Pending'}`} </div>
                                </div>
                                <div style={{ display: 'flex', marginLeft: '30px' }}>
                                    <div className="task-count-number" style={{ color: "#F02020" }}>{
                                        this.state.failedCount
                                    }</div>
                                    <div className="services-content-title" style={{ color: "black", fontSize: "12px", marginLeft: '12px', paddingTop: '2px' }}>{` ${'Failed'}`} </div>
                                </div>

                                <div className="add-user-form-row-left" style={{ marginLeft: 'auto' }}>
                                    <div className="add-user-form-text-wrapper">
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search Task" style={{ height: '30px !important', width: "230px", paddingLeft: '28px' }}
                                            onChange={(event) => this.search(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <table className="customers-table scroll-container">
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <col style={{ width: "15%" }} />
                                <tr className="customers-table-header-row">
                                    <th>Name
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}

                                            sortAscending={this.sortByName}
                                            sortDecending={this.reverseByName}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Task type</th>
                                    <th>Status</th>
                                    <th>Images</th>
                                    <th>Date Created
                                        <SortComponent
                                            style={{ marginLeft: '5px' }}

                                            sortAscending={this.sortByDate}
                                            sortDecending={this.reverseByDate}
                                            sortASC={false}
                                        />
                                    </th>
                                    <th>Project</th>
                                    <th></th>
                                </tr>
                                {this.state.tableLoading ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="7">  <img src={Loader} style={{ margin: "auto", height: '6%', width: '6%' }} /></td>
                                    </tr>
                                    :
                                    tasks.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="6">No Tasks Available</td>
                                        </tr> :
                                        tasks.map((task) =>
                                            <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                <td>{task.video_task == true ?
                                                    <Link
                                                        style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: "/picstork/videoTask",
                                                            state: {
                                                                user,
                                                                type,
                                                                sidebarMode: this.state.sidebarMode,
                                                                task: { task_id: task.task_id },
                                                                project: { project_name: task.project_name, id: task.project_id },
                                                                plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name },
                                                            },
                                                        }}
                                                    >
                                                        {task.task_name}
                                                    </Link> :
                                                    <Link
                                                        style={{ color: "#707683" }}
                                                        to={{
                                                            pathname: task.tasktype == 1 ? "/picstork/trainingTask" : task.tasktype == 2 ? "/picstork/detectionTask" : "/picstork/annotationReportTask",
                                                            state: {
                                                                user,
                                                                type,
                                                                task: { task_id: task.task_id },
                                                                collection: { id: task.image_collection_id },
                                                                project: { project_name: task.project_name, id: task.project_id },
                                                                plan: { plan_name: task.plan_name, id: task.plan_id, project_name: task.project_name },
                                                                sidebarMode: this.state.sidebarMode
                                                            }
                                                        }}
                                                    >
                                                        <> {task.task_name} <span style={{ color: '#2989cf' }}> {task.sharedData.isShared ? '( shared )' : <></>}  </span> </>
                                                    </Link>
                                                }
                                                </td>
                                                <td>{task.tasktype == 1 ? "Training" : task.tasktype == 2 ? "Detection" : "Annotation Report"}</td>
                                                <td
                                                    // <div className="aerocapture-detection-type-list"
                                                    style={{
                                                        color: task.status == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : task.status == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : task.status == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                        // background: data.displayData[1] == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : data.displayData[1] == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : data.displayData[1] == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00',
                                                        // fontWeight: 400, margin: "2px 4px"
                                                    }}>
                                                    {task.status}
                                                </td>
                                                <td>{task.images_count}</td>
                                                <td>
                                                    {
                                                        (() => {
                                                            let date = (new Date(task.date_created)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                            return `${date}`
                                                        })()
                                                    }
                                                </td>
                                                <td>{task.project_name}</td>

                                                <td>
                                                    <DeleteComponentTable
                                                        style={{ margin: "0 10px" }}
                                                        onClickDelete={(e) => {
                                                            e.preventDefault();
                                                            this.setState((state) => ({
                                                                ...state,
                                                                selectedTaskId: task.task_id,
                                                                itemName: "Task",
                                                                processPopupIcon: "WARNING",
                                                                processAction: "DELETE",
                                                                processMessage: null,
                                                                showProcessPopup: true,
                                                            }))
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                            </table>
                            {/* This is footer  */}
                            <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                                <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginTop: "0.5rem", display: "flex", alignItems: "baseline" }}>
                                    <ButtonWithIcon
                                        text="First"
                                        isBtnActive
                                        isDisabled={this.state.currentpage == 1}
                                        onClick={this.state.currentpage == 1 ? false : this.firstpage}
                                    />
                                    <ButtonWithIcon
                                        text="Prev"
                                        isDisabled={(this.state.currentpage == 1)}
                                        onClick={(this.state.currentpage == 1) ? false : this.prevpage}
                                    />
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>

                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>
                                    <ButtonWithIcon
                                        text="Next"
                                        isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                        onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.nextpage}
                                    />
                                    <ButtonWithIcon
                                        text="Last"
                                        isBtnActive
                                        isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                        onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.lastpage}
                                    />
                                </div>
                            </footer>
                        </div>}
                </div>
            </div > : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps)(PicStorkAllTasks);