import React from 'react'
import { getArea, getDistance } from '../../../ReusableComponents/map/leafletMeasure'
import { TOOLS } from '../Constants/constants'

const MapLayerMenu = ({ onClickDelete, onClickClose, layer }) => {
    if (layer.type === TOOLS.POLYGON || layer.type === TOOLS.POLYLINE || layer.type === TOOLS.MARKER) {
        const unit = "m"
        const latLngs = layer.type === TOOLS.POLYLINE ? layer.getLatLngs() : layer.type === TOOLS.POLYGON ? layer.getLatLngs()[0] : null // polygon , polyline
        const latLng = layer.type === TOOLS.MARKER && layer.getLatLng() // Marker
        if (latLngs) {
            layer.area = getArea(latLngs, unit, false, false)
            layer.distance = getDistance(layer.type === TOOLS.POLYLINE ? latLngs : [...latLngs, latLngs[0]], unit, false)
            layer.selectedUnit = unit
        }
        if (latLng) {
            layer.latLng = latLng
        }
    }
    return (
        <div style={{ width: "200px", padding: "10px", height: "80px", display: 'flex', flexDirection: "column", justifyContent: "space-between", fontSize: '11px' }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                {layer.type === TOOLS.POLYLINE &&
                    <div><span style={{ fontWeight: "400" }}>Length </span> <span style={{ fontWeight: 600 }}>{`: ${layer.distance} ${layer.selectedUnit}`}</span></div>}
                {layer.type === TOOLS.POLYGON && <div>
                    <div><span style={{ fontWeight: "400" }}>Perimeter </span><span style={{ fontWeight: 600 }}> {`: ${layer.distance} ${layer.selectedUnit}`}</span></div>
                    <div><span style={{ fontWeight: "400" }}>Area </span> <span style={{ fontWeight: 600 }}> {`: ${layer.area} sq.${layer.selectedUnit}`}</span></div>
                </div>
                }
                {layer.type === TOOLS.MARKER &&
                    <div><span style={{ fontWeight: "400" }}>LatLng </span> <span style={{ fontWeight: 600 }}>{`: ${layer.latLng.lat.toFixed(6)}, ${layer.latLng.lng.toFixed(6)}`}</span></div>
                }
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button onClick={onClickDelete} style={{ fontWeight: 500, color: "#2989cf", background: 'transparent', margin: 0, padding: 0 }}>Delete</button>
                <button onClick={onClickClose} style={{ fontWeight: 500, color: "#2989cf", background: 'transparent', margin: 0, padding: 0 }}>Close</button>
            </div>
        </div>
    )
}

export default MapLayerMenu
