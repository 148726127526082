import React, { useEffect, useState } from "react";
import * as API from '../../../api.js'
import { AnimatedModal } from "../../../ReusableComponents/AnimatedModal.js";
import { Popup } from "../../../ReusableComponents/reusableFunctions.js";
import closeIcon from "../../assets/icons/closeNew.svg"
import LoaderComponent from "../../../ReusableComponents/LoaderComponent.js";
import ActionButton from "./ActionButton.js";
import { SelectComponent } from "../../../ReusableComponents/SelectComponent.js";
import { getActiveTeamId } from "../../../Teams/index.js";

const BlockDetailsEdit = ({ onClose, onDone, existingBlock, taskId, polygonId }) => {
  const [blockDetails, setBlockDeatails] = useState(existingBlock || {
    name: "",
    info: "",
    polygon_id: polygonId,
    property_id: null,
    property_name: "",
    division_name: "",
    division_id: "",
    city_name: "",
    city_id: "",
    city_area_name: "",
    city_area_id: "",
  });
  const [propeties, setProperties] = useState([]);
  const [loading, setloading] = useState(false);

  const isformFilled = () => {
    const includedFields = ['name', 'property_name', 'division_name', 'city_name', 'city_area_name'];
    for (const key in blockDetails) {
      if (includedFields.includes(key)) {
        const value = blockDetails[key];
        if (value === undefined || value === null || value === '') {
          return false;
        }
      }
    }
    return true;
  }

  const getProperties = async () => {
    setloading(true)
    API.getProperties(taskId, null, null, null, null, false, null, await getActiveTeamId())
      .then((res) => {
        setProperties(res.properties)
        setloading(false)
      })
      .catch((e) => {
        setloading(false)
        Popup.alert("ERROR", e.message || e)
      })
  }

  const saveBlockDetails = async () => {
    setloading(true);
    await API.addBlock(blockDetails)
      .then(async (res) => {
        onDone(res.block, "yellow")
        setloading(false);
        Popup.alert("COMPLETE", "Block saved successfully !");
        onClose()
      })
      .catch((e) => {
        setloading(false);
        Popup.alert("ERROR", e.message || e)
      })
  }

  const updateBlockDetails = () => {
    setloading(true)
    API.updateBlock(blockDetails)
      .then((res) => {
        onDone(blockDetails, "yellow")
        setloading(false)
        Popup.alert("COMPLETE", "Block updated successfully !");
        onClose()
      })
      .catch((e) => {
        setloading(false)
        Popup.alert("ERROR", e.message || e)
      })
  }

  useEffect(() => {
    getProperties()
  }, [])

  return (
    <AnimatedModal
      isOpen={true}
      // onRequestClose={onClose}
      height="410px"
      width="580px"
      padding="0"
    >
      {loading ?
        <LoaderComponent /> :
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 25px", borderBottom: "1px solid #d7d7d7" }}>
            <div style={{ marginBlock: "10px" }}>{existingBlock ? "Update Block Details" : "Add Block Details"} </div>
            <img src={closeIcon} alt="close button" style={{ width: "15px", height: "15px", cursor: "pointer" }} onClick={() => onClose()} />
          </div>
          <form style={{ display: "grid", height: "90%", gridTemplateColumns: "1fr 1fr", rowGap: "16px", columnGap: "52px", padding: "25px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>Property</div>
              <div style={{ width: "100%" }}>
                <SelectComponent
                  values={blockDetails.property_id ? [{ label: blockDetails.property_name, value: blockDetails.property_id }] : []}
                  placeholder="Select Property"
                  searchable={false}
                  disabled={loading || propeties.length === 0}
                  itemsList={propeties?.map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onchangeHandler={(e) => {
                    let updatedBlockDetails = {
                      ...blockDetails,
                      property_id: e[0]?.value,
                      property_name: e[0]?.label,
                    }
                    const selectedProperty = propeties.filter((p) => p.id == e[0]?.value)[0];
                    setBlockDeatails({
                      ...updatedBlockDetails,
                      division_id: selectedProperty.division_id,
                      division_name: selectedProperty.division_name,
                      city_id: selectedProperty.city_id,
                      city_name: selectedProperty.city_name,
                      city_area_id: selectedProperty.area_id,
                      city_area_name: selectedProperty.city_area_name
                    })
                  }}
                  fontSize={"14px"}
                  fontWeight={400}
                  width={"238px"}
                  height={"34px"}
                  iconSize={"12px"}
                  border={"1px solid #C3CDD9"}
                  borderRadius={"8px"}
                  backgroundColor={"#F4F4F4"}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>Division </div>
              <input
                disabled={true}
                value={blockDetails.division_name}
                style={{ borderRadius: "8px", border: "2px solid #ced4da", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", background: "#F4F4F4" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>City </div>
              <input
                disabled={true}
                value={blockDetails.city_name}
                style={{ borderRadius: "8px", border: "2px solid #ced4da", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", background: "#F4F4F4" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>City area</div>
              <input
                disabled={true}
                value={blockDetails.city_area_name}
                style={{ borderRadius: "8px", border: "2px solid #ced4da", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", background: "#F4F4F4" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>Block number</div>
              <input
                name="name"
                maxLength={10}
                placeholder="Enter Block Number"
                value={blockDetails.name}
                onChange={(e) => {
                  setBlockDeatails({
                    ...blockDetails,
                    name: e.target.value
                  })
                }}
                style={{ borderRadius: "8px", border: "2px solid #ced4da", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", background: "#F4F4F4" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", width: "238px" }}>
              <div style={{ fontSize: "14px", display: 'flex' }}>Information<span style={{ opacity: "0.5", fontSize: "10px", display: "flex", alignItems: "center" }}>(optional)</span></div>
              <input
                maxLength={100}
                placeholder="Enter information"
                value={blockDetails.info}
                onChange={(e) => {
                  setBlockDeatails({
                    ...blockDetails,
                    info: e.target.value
                  })
                }}
                style={{ borderRadius: "8px", border: "2px solid #ced4da", height: "34px", fontWeight: "400", fontSize: "14px", paddingLeft: "6px", background: "#F4F4F4" }}
              />
            </div>

          </form>
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", padding: "10px 25px 25px 25px", gap: "10px" }}>
            <ActionButton
              name={"Cancel"}
              onClickCallback={() => {
                onClose()
              }}
              width={"90px"}
              background={false}
            />
            <ActionButton
              isDisabled={!isformFilled()}
              isBtnActive={true}
              name={existingBlock ? "Update" : "Save"}
              onClickCallback={() => {
                existingBlock ? updateBlockDetails() : saveBlockDetails()
              }}
              width={"90px"}
              background={true}
            />
          </div>
        </div>
      }
    </AnimatedModal>
  )
}


export default BlockDetailsEdit