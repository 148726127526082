import React, { useEffect, useState } from 'react'
import FilesContainer from './FilesContainer.js';
import AppConstants from '../../../AppConstants.js';
import * as API from '../../../api.js'
import { Popup } from '../../../ReusableComponents/reusableFunctions.js';
import ActionButton from "./ActionButton.js";
import pencilDash from "../../assets/icons/pencilDash.svg"
import DisplayField from "./DisplayField.js";
import upload from "../../assets/icons/UploadSimple.svg"
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';
import { filterFiles } from './ReuseableFunctions.js';


const BlockDetailsView = ({ onEdit, block }) => {
    const [loading, setLoading] = useState(false)
    const [blockReport, setBlockReport] = useState([])
    const [blockFiles, setBlockFiles] = useState([])
    const [duDetails, setDuDetails] = useState([])
    const [blockReportLoading, setBlockReportLoading] = useState(false)
    const [blockFileLoading, setBlockFileLoading] = useState(false)
    const [blockDuDetailsLoading, setBlockDuDetailsLoading] = useState(false);


    const getUploadedFiles = (fileType) => {
        if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
            setBlockReportLoading(true);
        }
        else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
            setBlockFileLoading(true);
        }
        else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
            setBlockDuDetailsLoading(true);
        }
        API.getFiles(AppConstants.DRONELAB.ASSET_TYPES.BLOCK, fileType, block.id)
            .then((res) => {
                if (res.assetFiles[0]?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
                    setBlockReport(res.assetFiles)
                }
                else if (res.assetFiles[0]?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
                    setBlockFiles(res.assetFiles)
                }
                else if (res.assetFiles[0]?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
                    setDuDetails(res.assetFiles)
                }
            }).finally(() => {
                setBlockReportLoading(false)
                setBlockFileLoading(false)
                setBlockDuDetailsLoading(false)
            })
            .catch((e) => {
                Popup.alert("ERROR", e.message || e)
            })
    }


    const uploadAssetFiles = (assets) => {
        return new Promise(async (resolve, reject) => {
            if (assets.length > 0) {
                Popup.alert("WAIT", "Uploading file, please wait...")
                if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
                    setBlockReportLoading(true);
                }
                else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
                    setBlockFileLoading(true);
                }
                else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
                    setBlockDuDetailsLoading(true);
                }
                try {
                    for (const asset of assets) {
                        const fileType = asset.file_type
                        const assetType = asset.asset_type
                        const file = asset?.files
                        if (file) {
                            try {
                                await API.uploadFile(file, assetType, fileType, block.id)
                            } catch (e) {
                                throw new Error(e.message || e)
                            }
                        }
                    }

                    if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
                        setBlockReport([
                            ...blockReport,
                            ...assets
                        ])
                    } else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
                        setBlockFiles([
                            ...blockFiles,
                            ...assets
                        ])
                    }
                    else if (assets[0].file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
                        setDuDetails([
                            ...duDetails,
                            ...assets
                        ])
                    }
                    Popup.alert("COMPLETE", "Files uploaded successfully !");
                    setBlockReportLoading(false)
                    setBlockFileLoading(false)
                    setBlockDuDetailsLoading(false)
                    getUploadedFiles(assets[0].file_type)
                    resolve()

                } catch (err) {
                    getUploadedFiles(assets[0].file_type)
                    setBlockReportLoading(false)
                    setBlockFileLoading(false)
                    setBlockDuDetailsLoading(false)
                    Popup.alert("ERROR", err?.message ? err.message : "Somethng went wrong while uploading file !");
                }
            }
            else resolve()
        })
    }

    const deleteFile = (fileId) => {
        Popup.alert("WARNING", "Are you sure want to delete this file?", () => {
            Popup.alert("WAIT", "Deleting file, please wait...")
            API.deleteAssetFile(fileId)
                .then((res) => {
                    if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
                        setBlockReport(blockReport.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    else if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
                        setBlockFiles(blockFiles.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    else if (res.deletedFile?.file_type === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
                        setDuDetails(duDetails.filter((asset) => asset.id !== res.deletedFile.id))
                    }
                    Popup.alert("COMPLETE", res.message)
                })
                .catch((e) => {
                    Popup.alert("ERROR", e.message || e)
                })
        })
    }

    const getOnMountUploadedFiles = () => {
        for (const key in AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK) {
            getUploadedFiles(AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK[key])
        }
    }

    useEffect(() => {
        getOnMountUploadedFiles()
    }, [])

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", padding: "0px 30px", fontSize: "14px" }}>
                <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)" }}>Block details</div>
                <ActionButton
                    name={"Edit"}
                    icon={pencilDash}
                    onClickCallback={() => {
                        onEdit()
                    }}
                />
            </div>
            <form style={{ padding: "0px 30px", height: "145px", overflow: "auto" }}>
                <DisplayField label="Division" value={block.division_name} />
                <DisplayField label="City" value={block.city_name} />
                <DisplayField label="City Area" value={block.city_area_name} />
                <DisplayField label="Block Number" value={block.name} />
                <DisplayField label="Information" value={block.info} />
            </form>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", fontSize: "14px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)" }}>Reports ({blockReport?.length || "0"})</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        acceptType={".xls, .pdf, .jpg, .JPG"}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.xls', '.jpg', '.pdf', '.JPG'])
                            const updatedReports = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.BLOCK,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT,
                                files: file
                            }));
                            await uploadAssetFiles([...updatedReports])
                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        isMultiple={true}
                    />
                </div>
                {
                    blockReportLoading ? <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} />
                        :
                        <FilesContainer
                            filesProp={blockReport}
                            actionCallback={(fileId) => {
                                deleteFile(fileId)
                            }}
                            fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT}

                        />

                }
            </div>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", fontSize: "14px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)" }}>Files ({blockFiles?.length || "0"})</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.xls', '.jpg', '.pdf', '.JPG'])
                            const updatedPropertFiles = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.BLOCK,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE,
                                files: file
                            }))
                            await uploadAssetFiles([...updatedPropertFiles])
                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        inputName={"Report"}
                        acceptType={".xls, .pdf, .jpg, .JPG"}
                        isMultiple={true}
                    />
                </div>
                {
                    blockFileLoading ? <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} />
                        :
                        <FilesContainer
                            filesProp={blockFiles}
                            actionCallback={(fileId) => {
                                deleteFile(fileId)
                            }}
                            fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE}
                        />
                }
            </div>
            <hr />
            <div style={{ padding: "0px 30px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", fontSize: "14px" }}>
                    <div style={{ marginBlock: "10px", color: "rgb(41, 137, 207)" }}>DU details ({duDetails?.length || "0"})</div>
                    <ActionButton
                        name={"Upload"}
                        icon={upload}
                        isInput={true}
                        onClickCallback={async (e) => {
                            const files = Array.from(e.target.files);
                            let { fFiles, unsupportedFiles } = await filterFiles(files, ['.xls', '.jpg', '.pdf', '.JPG'])
                            const updatedBlockDetails = fFiles.map(file => ({
                                asset_type: AppConstants.DRONELAB.ASSET_TYPES.BLOCK,
                                file_type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS,
                                files: file
                            }))
                            await uploadAssetFiles([...updatedBlockDetails])
                            if (unsupportedFiles.length != 0) {
                                let flnames = unsupportedFiles.map(f => { return f.name }).join(', ')
                                Popup.alert("ERROR", `${flnames}${unsupportedFiles.length > 1 ? ' files are' : ' file is'} not supported!`)
                            }
                        }}
                        inputName={"Report"}
                        isMultiple={false}
                        acceptType={".xls, .pdf, .jpg, .JPG"}
                        isDisabled={duDetails.length === 1}
                    />
                </div>
                {
                    blockDuDetailsLoading ? <LoaderComponent imgWidth="125px" imgMargin={"0px 0px -35px 55px"} />
                        :
                        <FilesContainer
                            filesProp={duDetails}
                            actionCallback={(fileId) => {
                                deleteFile(fileId)
                            }}
                            fileType={AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS}
                        />
                }
            </div>
            <hr />
        </div>
    )
}

export default BlockDetailsView