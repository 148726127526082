import React, { Component } from 'react';
import Loader from './icons/loader.svg';
import axios from 'axios';
import waiting from './res/waiting.png';
import * as API from './api.js';

import service from "./sidebaricon/Services.png";
import { connect } from "react-redux";
import { updateSubscriptionData } from './redux'
import Help from "./sidebaricon/Help.png";
import Settings from './sidebaricon/SettingsActive.png';
import Switch from "react-switch";
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import AppConstants from './AppConstants';
import Confetti from 'react-confetti'
import { AnimatedModal } from './ReusableComponents/AnimatedModal';
import { ActivityPopup } from "./ReusableComponents/ActivityPopup"
import DropDownWithButton from './ReusableComponents/DropDownWithButton';
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon';
const AEROMEGH_PRO_MONTHLY_PRICE = 99


class AeroMeghPlans extends Component {
    state = {
        user: {},
        loading: true,
        type: "",
        term: "Yearly",
        serviceName: "",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        selectedItems: undefined,
        droneQty: 1,
        prevDroneQuantity: 0,
        droneBuyObjs: { id: "", qty: 1 },
        pendingPayments: [],
    }


    // placeAeroMeghPlanPurchaseOrder = (count) => {
    //     API.placeAeroMeghPlanSubscriptionOrder(this.state.term, count).then(res => {
    //         let { invoiceID } = res
    //         this.setState((prevState) => ({
    //             ...prevState,
    //             invoiceID,
    //             redirectToInvoicePage: true
    //         }))
    //     }).catch(e => {
    //         this.setState((prevState) => ({
    //             ...prevState,
    //             aeromeghPlanPurchaseError: e
    //         }))
    //     })
    // }




    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    // convCurrency = async (plan) => {
    //     if (this.state.localeData.country_code == 'IN') {
    //         const converter = await new Converter();
    //         const a = await converter.convert(plan.amount, plan.currency, "INR");
    //         // let a = await convertCurrency('USD', 'EUR', 100)
    //         // let a = await currency.convertCurrencyByCode({ value: plan.amount, code: plan.currency }, { code: 'INR' })
    //         console.log(plan.currency);
    //         console.log(plan.amount);
    //         // let a = 500;
    //         console.log(a);

    //         let temp = await plan;
    //         temp.amount = await a;
    //         temp.currency_symbol = await '₹'
    //         console.log("testing", temp);
    //         return temp


    //     } else {
    //         const converter = await new Converter();
    //         let a = await converter.convert(plan.amount, plan.currency, "USD");

    //         let temp = await plan;
    //         temp.amount = await a;
    //         temp.currency_symbol = await '$'
    //         return temp
    //     }
    // }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.droneQty !== this.state.droneQty) {
            this.buildDroneBuyURL()
        }
    }
    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans,
                    loading: false,
                }), () => {
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    loading: false,
                }))
            }

        }).catch(e => {
            console.log(e)
            alert("Failed to fetch required page data")
            // window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))
        })



    }



    async componentWillMount() {
        document.title = "Plans - DroneLab";

        // axios.get('https://ipapi.co/json/')
        //     .then((response) => {
        //         // handle success
        //         console.log(response);
        //         let localeData = response.data;
        //         this.setState((state) => ({
        //             ...state,
        //             localeData: localeData,
        //         }), () => {
        //             this.getAeromeghPlans();
        //         })

        //     })
        //     .catch((error) => {
        //         // handle error
        //         console.log(error);
        //     })


        // if (query.get("canceled")) {
        //     // setMessage(
        //     //     "Order canceled -- continue to shop around and checkout when you're ready."
        //     // );
        // }

        API.getUserType().then(([blah, type, user]) => {
            this.setState((state) => ({
                ...state,
                user,
                type,
                serviceName: this.props.location.state?.serviceName
            }), () => {

                const query = new URLSearchParams(window.location.search);
                if (query.get("success") && query.get("session_id")) {
                    const sessionId = query.get("session_id")
                    const planId = query.get('plan_id')
                    if (planId) //subscription payment
                        API.getPaymentDetails(sessionId, AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.SERVICES_PLAN).then(paymentResponse => {
                            this.setState({
                                sessionId,
                                paymentResponse,
                                loading: false
                            }, () => {
                                API.getUserSubscriptionPlan().then((sub) => {
                                    if (sub.plans) {
                                        this.props.updateSubscriptionData({
                                            subscriptionData: sub
                                        })
                                    } else {
                                        this.props.updateSubscriptionData({
                                            subscriptionData: undefined
                                        })
                                    }
                                }).catch(err => {
                                    // this.props.updateSubscriptionData({
                                    //     subscriptionData: undefined
                                    // })
                                    this.setState({ loading: false, errorOccured: { message: err.message, status: true } })
                                })
                            })
                        }).catch(err => {
                            console.log(err)
                            this.setState({ loading: false, errorOccured: { message: err.message, status: true } })
                        })
                    else { //credits payment
                        API.getPaymentDetails(sessionId, AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS).then(paymentResponse => {
                            this.setState({
                                sessionId,
                                paymentResponse,
                                loading: false
                            }, () => {
                            })
                        }).catch(err => {
                            console.log(err)
                            this.setState({ loading: false, errorOccured: { message: err.message, status: true } })

                        })
                    }

                } else if (query.get("cancelled")) {
                    const serviceName = query.get('serviceName')
                    if (serviceName) {
                        this.setState({ serviceName }, () => {
                            this.getAeromeghPlans();
                        })
                    }
                } else {
                    this.getAeromeghPlans();

                }

            })
        }, (e) => {
            window.location.replace("/login");
        })
    }


    render() {
        let { user, type, plans, term, sessionId, paymentResponse } = this.state
        let sidebarConfigurations = {
            options: [
                {
                    icon: service,
                    text: "Services",
                    path: "/",
                },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                    selected: true,
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            user.username ?
                <div className="wrapper">

                    {paymentResponse?.paymentDetails?.payment_status === 'paid' && !this.state.errorOccured?.status && <Confetti recycle={false} numberOfPieces={500} />}

                    <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                    <div className="right-content"
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >


                        <div className="top-bar">
                            <div className="top-bar-text">Subscription Plans</div>
                        </div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div className="main-content">
                                <div style={{ display: "flex", justifyContent: this.state.serviceName === AppConstants.SERVICES.DRONE_CONTROL_CENTER ? "" : "center", alignItems: "center", height: "85vh", flexDirection: this.state.serviceName === AppConstants.SERVICES.DRONE_CONTROL_CENTER ? "column" : "" }}>

                                    {/* iff error occured anywhere during  or after payment */}
                                    {this.state.errorOccured?.status && <div className="page" style={{ padding: "40px", minHeight: "auto", position: "relative" }}>
                                        <div style={{ textAlign: "center" }}>{this.state.errorOccured.message}</div>
                                        <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                            <Link to={{
                                                pathname: "/profile-settings",
                                                state: { ...this.state, view: 2 }
                                            }} style={{
                                                color: "#2989cf"
                                            }}>

                                                Ok
                                            </Link>
                                        </div>
                                    </div>}

                                    {/* if payment is paid */}
                                    {paymentResponse && paymentResponse?.paymentDetails?.payment_status === 'paid' &&
                                        <div className="page" style={{ padding: "40px", minHeight: "auto", position: "relative" }}>
                                            <div style={{ textAlign: "center" }}>{paymentResponse?.message}</div>
                                            <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                                <Link to={{
                                                    pathname: "/profile-settings",
                                                    state: { ...this.state, view: 2 }
                                                }} style={{
                                                    color: "#2989cf"
                                                }}>

                                                    Ok
                                                </Link>
                                            </div>
                                        </div>}


                                    {/* if payment failed */}
                                    {paymentResponse && paymentResponse?.paymentDetails?.payment_status !== "paid" &&
                                        <div className="page" style={{ padding: "40px", minHeight: "auto" }}>
                                            <div> We regret to inform you that there was an issue with recent payment transaction on {new Date(paymentResponse.paymentDetails.date_time).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}. Unfortunately, the payment could not be successfully processed.</div>

                                            <div style={{ marginTop: "5px", fontWeight: "500" }}>Payment Details:</div>

                                            <div><span style={{ fontWeight: "500" }}>Payment id:</span> {paymentResponse.paymentDetails.id}</div>
                                            <div><span style={{ fontWeight: "500" }}>Payment Amount:</span> {paymentResponse.paymentDetails.amount}</div>
                                            <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                                <Link to={{
                                                    pathname: "/profile-settings",
                                                    state: { ...this.state, view: 2 }
                                                }} style={{
                                                    color: "#2989cf"
                                                }}>

                                                    Ok
                                                </Link>
                                            </div>
                                        </div>}


                                    {/* if normal plans */}
                                    {!paymentResponse?.paymentDetails?.payment_status && this.state.serviceName && this.state.serviceName != AppConstants.SERVICES.DRONE_CONTROL_CENTER ?
                                        < div className="page" style={{ padding: "0px", height: "auto", minHeight: "auto" }}>
                                            <div className="rpas-model-info-bottom-section">
                                                <div id="aeromegh-plan-validity-selector-wrapper">
                                                    <div style={{ width: "30%" }}>
                                                        <div>
                                                            <div className="services-content-title">Choose Your Plan</div>
                                                        </div>
                                                    </div>

                                                    <div id="aeromegh-plan-validity-selector" style={{ width: "33%" }}>
                                                        <div id="aeromegh-plan-validity-selector-contents">
                                                            <div style={{ lineHeight: "30px", marginLeft: "auto" }}>Annually</div>
                                                            <Switch
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={"#666666"}
                                                                offColor={"#2989CF"}
                                                                checked={this.state.term === "Monthly"}
                                                                onChange={(checked) => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        term: (checked ? "Monthly" : "Yearly")
                                                                    }))

                                                                }}
                                                            />
                                                            <div style={{ lineHeight: "30px", marginRight: "0px" }}>Monthly</div>
                                                        </div>

                                                    </div>
                                                    <div style={{ width: "37%", textAlign: "end", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                        <div>
                                                            <button
                                                                className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                                                onClick={() => {
                                                                    window.open("https://aeromegh.com/product-pricing/")
                                                                }}
                                                            >
                                                                Compare plans
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="aeromegh-plans-tables-wrapper">
                                                    {plans ? plans.filter(plan => plan.plan_type === term && this.state.serviceName === plan.service).map((plan, key) =>
                                                        <table key={key} className="aeromegh-plan-table">
                                                            {/* <table key={key} className={this.state.term ==="Yearly" ? "aeromegh-plan-table highlighted-aeromegh-plan" : "aeromegh-plan-table"}> */}
                                                            <tr className="aeromegh-plan-table-name"><div>{plan.plan_name}</div></tr>
                                                            <tr className="aeromegh-plan-table-price" >
                                                                {/* <tr className= {this.state.term ==="Yearly" ? "aeromegh-plan-table-price highlighted-aeromegh-plan-price" : "aeromegh-plan-table-price"} > */}
                                                                <div>
                                                                    {/* <div style={this.state.term ==="Yearly" ? {color:"white"} : undefined}> */}
                                                                    ₹ {plan.plan_cost - (plan.plan_cost * (plan.discount / 100))}
                                                                    {this.state.term == 'Monthly' ?

                                                                        <span className="font13" style={{ textAlign: "center" }}>
                                                                            /Month
                                                                        </span> : <span className="font13" style={{ textAlign: "center" }}>
                                                                            /Year
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </tr>
                                                            <tr className="aeromegh-plan-table-detail gray-row" ><div>{plan.description}</div></tr>
                                                            <tr className="aeromegh-plan-table-price highlighted-aeromegh-plan-price unselectable-text" style={{ background: "white", height: "60px" }}>
                                                                <div className="buy-button" style={{ width: "120px", "display": "flex", cursor: "pointer", width: 'auto !important' }}>
                                                                    <form action={`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/makepayment/${plan.id}/${plan.price_id}?email=${user.username}&serviceName=${this.state.serviceName}`} method="POST">
                                                                        <button className='remove-botton-styling' style={{ fontSize: "12px", margin: "auto", color: "white", padding: "12px 20px 10px 20px" }} type="submit" >Buy Now</button>
                                                                    </form>
                                                                </div>
                                                            </tr>
                                                        </table>
                                                    ) : <></>}
                                                </div>
                                            </div>
                                        </div> :
                                        <>
                                        </>
                                    }

                                </div>
                            </div>}
                    </div>
                </div > : <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSubscriptionData: (payload) => dispatch(updateSubscriptionData(payload))
    };
};

export default (connect(null, mapDispatchToProps)(AeroMeghPlans));