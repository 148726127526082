import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';
import "../Excel.css";
import square from "../../assets/icons/square.png";

const ExcelViewer = ({ url }) => {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        try {
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching or parsing Excel file:', error);
        }
    };

    const enterFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    };

    const handleFullscreen = () => {
        const tableWrapper = document.querySelector('.table-wrapper');
        if (tableWrapper) {
            enterFullscreen(tableWrapper);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
            {data ? (
                <div className="table-wrapper">
                    <table className="excel-table">
                        <thead>
                            {data.slice(0, 1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <th key={cellIndex}>{cell}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div style={{
                    width: "100%",
                    height: "48%",
                    padding: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <LoaderComponent />
                </div>
            )}
            {data?.slice(1)?.length > 0 &&
                <img src={square} alt='full-screen' className='fullscreen-img' onClick={handleFullscreen} />
            }
        </div>
    );
};

export default ExcelViewer;
