import React, { useEffect, useState } from 'react'
import propertySvg from "../../assets/icons/property.svg"
import closeIcon from '../../assets/icons/closeNew.svg'
import TabSwitcher from '../components/TabSwitcher.js';
import AppConstants from '../../../AppConstants.js';
import DisplayField from '../components/DisplayField.js';
import FileHolder from './FileHolder.js';
import * as API from "../../../api.js"
import { Popup } from '../../../ReusableComponents/reusableFunctions.js';
import NoFilesFallback from './NoFilesFallback.js';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';
import ExcelViewer from './ExcelViewer.js';
import ActionButton from './ActionButton.js';

const PropertyDetailsPopup = ({ onCloseCallback, property, onExploreProperty, showExplore }) => {
    const [selectedTab, setSelectedTab] = useState(AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_DETAILS)
    const [propertyReport, setPropertyReport] = useState([])
    const [propertyFile, setPropertyFile] = useState([])
    const [propertyBlockDetails, setPropertyBlockDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedPropetry, setSelectedProperty] = useState({})

    const getUploadedFiles = (fileType) => {
        setLoading(true)
        API.getFiles(AppConstants.DRONELAB.ASSET_TYPES.PROPERTY, fileType, selectedPropetry.id)
            .then((res) => {
                if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT) {
                    setPropertyReport(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE) {
                    setPropertyFile(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS) {
                    setPropertyBlockDetails(res.assetFiles)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }


    useEffect(() => {
        setSelectedProperty(property)
    }, [property])

    return (
        <div style={{ background: "white", borderRadius: "8px", width: '624px', height: '362px' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", padding: "25px" }}>
                    <div style={{ display: 'flex', alignItems: "center", gap: "20px" }}>
                        <img src={propertySvg} alt='property image' />
                        <div>
                            <div style={{ fontSize: "24px", fontWeight: "500", textAlign: "left" }}>{property.name}</div>
                            <div style={{ color: "#666666", fontSize: "14px", fontWeight: "400", textAlign: "left" }}>Sr. No</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        {showExplore && <ActionButton
                            name={"Explore"}
                            isBtnActive={true}
                            onClickCallback={() => {
                                onExploreProperty(property.id)
                            }}
                        />}
                        <img src={closeIcon} alt='close icon' style={{ cursor: "pointer" }} onClick={onCloseCallback} />
                    </div>
                </div>
                <TabSwitcher
                    tabs={[
                        {
                            title: 'Property Details',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_DETAILS
                        },
                        {
                            title: 'Reports',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT
                        },
                        {
                            title: 'Property Files',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE
                        },
                        {
                            title: 'Block Details',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_BLOCK_DETAILS
                        },
                    ]}
                    switchCallback={(type) => {
                        setSelectedTab(type)
                        getUploadedFiles(type)
                    }}
                    selectedTab={selectedTab}
                />
                <hr style={{ margin: 0 }} />
                {selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_DETAILS ?
                    <div style={{ padding: "0px 25px 0px 25px", display: 'flex', flexDirection: "column", gap: '', height: "220px", overflow: "auto", margin: "25px 0px 25px 0px" }}>
                        <DisplayField label={"Name Of Colony"} value={property.name_of_colony} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"Area Of City"} value={property.city_area_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"City"} value={property.city_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"Division"} value={property.division_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"Total Block"} value={property.total_blocks} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"Property Types"} value={property.property_type} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} style={{ marginBottom: "10px" }} />
                        <DisplayField label={"Information"} value={property.info} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                    </div> :
                    selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_REPORT ?
                        loading ?
                            <div style={{
                                width: "100%",
                                height: "57%",
                                padding: "0px 40px 40px 40px",
                                overflow: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <LoaderComponent />
                            </div> :
                            <div style={{
                                width: "100%",
                                height: propertyReport.length === 0 ? "100%" : "53%",
                                padding: "30px 40px",
                                overflow: "auto",
                                display: propertyReport.length === 0 ? "flex" : "grid",
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                alignItems: "center",
                                justifyContent: propertyReport.length === 0 ? "center" : "space-between",
                                rowGap: "20px",
                            }}>
                                {propertyReport.length === 0 ?
                                    <NoFilesFallback message={"The property does not have any reports yet !"} /> :
                                    propertyReport.map((file) => (
                                        <FileHolder file={file} />
                                    ))}
                            </div>
                        :
                        selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.PROPERTIES.PROPERTY_FILE ?
                            loading ?
                                <div style={{
                                    width: "100%",
                                    height: "57%",
                                    padding: "0px 40px 40px 40px",
                                    overflow: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <LoaderComponent />
                                </div> :
                                <div style={{
                                    width: "100%",
                                    height: propertyFile.length === 0 ? "100%" : "53%",
                                    padding: "30px 40px",
                                    overflow: "auto",
                                    display: propertyFile.length === 0 ? "flex" : "grid",
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    alignItems: "center",
                                    justifyContent: propertyFile.length === 0 ? "center" : "space-between",
                                    rowGap: "20px",
                                }}>
                                    {propertyFile.length === 0 ?
                                        <NoFilesFallback message={"The property does not have any files yet !"} /> :
                                        propertyFile.map((file) => (
                                            <FileHolder file={file} />
                                        ))}
                                </div> :
                            <div style={{
                                width: "100%",
                                height: propertyBlockDetails.length === 0 ? "100%" : "48%",
                                padding: "0px 40px",
                                margin: "30px 0px",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center'
                            }}>
                                {propertyBlockDetails.length === 0 ?
                                    <NoFilesFallback message={"The property does not have any block details yet !"} /> :
                                    propertyBlockDetails[0].name.split('.')[1] === 'jpg' || propertyBlockDetails[0].name.split('.')[1] === 'pdf' || propertyBlockDetails[0].name.split('.')[1] === 'JPG' ?
                                        propertyBlockDetails.map((file) => (
                                            <FileHolder file={file} />
                                        )) :
                                        <ExcelViewer url={propertyBlockDetails[0].blob_url} />
                                }
                            </div>
                }
            </div>
        </div>
    );
}

export default PropertyDetailsPopup