import React, { useState } from 'react'
import ActionButton from "./ActionButton.js";
import pencilDash from "../../assets/icons/pencilDash.svg"
import DisplayField from "./DisplayField.js";

export default function EncroachmentView({ onEdit, block }) {
    return (
        <div style={{ height: "100%", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", padding: "0px 30px" }}>
                <div style={{ marginBlock: "10px", color: "#2989cf", fontSize: "14px" }}>Encroachment details</div>
                <ActionButton
                    name={"Edit"}
                    icon={pencilDash}
                    onClickCallback={() => {
                        onEdit()
                    }}
                />
            </div>
            <form style={{ padding: "0px 30px", height: "125px", overflow: "auto" }}>
                <DisplayField label="Division" value={block.division_name} />
                <DisplayField label="City" value={block.city_name} />
                <DisplayField label="City Area" value={block.city_area_name} />
                <DisplayField label="Name" value={block.name} />
                <DisplayField label="Information" value={block.info} />
            </form>
            <hr />
        </div>
    )
}
