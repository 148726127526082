import React, { useEffect, useState } from 'react'
import PropertyMap from '../AeroCapture/Components/ReusableComp/PropertyMap'
import { Popup } from '../ReusableComponents/reusableFunctions'
import Close from "../icons/blackClose.png"
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
const MapViewPopup = ({ asset, onRequestClose }) => {
    const [coordinates, setCoordinates] = useState([])
    useEffect(() => {
        try {
            if (asset) {
                setCoordinates(JSON.parse(asset.coordinates))
            }
        } catch (err) {
            onRequestClose()
            Popup.alert("ERROR", "Please provide valid coordinates")
        }
    }, [asset])

    return (<AnimatedModal isOpen={true} width="1000px" height="650px" onRequestClose={onRequestClose}>
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ padding: "20px 15px", textAlign: 'left', display: 'flex', alignItems: "start", justifyContent: 'space-between', borderBottom: '1px solid #C3CDD9' }}>
                <div>
                    <div style={{ textAlign: "left", fontSize: '14px' }}><span style={{ color: "#666666" }}>Name </span><span style={{margin:"0px 5px"}}>:</span><span>{asset.name}</span></div>
                    <div style={{ textAlign: "left", fontSize: '14px' }}><span style={{ color: "#666666" }}>Description</span><span style={{margin:"0px 5px"}}>:</span><span>{asset.description || "-"}</span></div>
                </div>
                <img onClick={onRequestClose} src={Close} style={{ cursor: 'pointer', height: '25px' }} />
            </div>
            <PropertyMap coordinates={(coordinates) || []} />
        </div>
    </AnimatedModal>
    )
}

export default MapViewPopup
