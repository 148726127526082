import React, { useEffect, useState } from 'react';
import prev from "../../../icons/back1.png";
import next from "../../../icons/next1.png";
import dateFormat from 'dateformat';

const CompareTool = ({ list, onNext, onPrev, disableNext, disablePrev, handleDropdownChange }) => {
    const [selectedValue, setSelectedValue] = useState(list && list[0] ? JSON.stringify(list[0]) : '');

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        handleDropdownChange(newValue === "Base" ? newValue : JSON.parse(newValue));
    };

    useEffect(() => {
        list &&
            setSelectedValue(list[0] ? JSON.stringify(list[0]) : '')
    }, [list?.[0]?.id])

    return (
        <div style={{ width: "371px", height: "48px", borderRadius: '10px', backgroundColor: 'rgba(60, 60,60,0.6)', color: 'white', textAlign: 'center', pointerEvents: "all", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px" }}>
            <img
                src={prev}
                alt="Previous"
                style={{ height: '15px', cursor: disablePrev ? "not-allowed" : "pointer" }}
                onClick={() => {
                    if (!disablePrev) onPrev();
                }}
            />
            <div>
                <div>
                    {list && list[0]?.task_name}
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "40px" }}>
                    <div style={{ fontSize: "10px" }}>
                        {dateFormat(list?.date_created, 'dd mmm yyyy hh:MM TT')}
                    </div>
                    <select
                        style={{ width: "105px", border: "none", outline: "none", color: "white", fontSize: "12px", backgroundColor: 'transparent' }}
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        {list && list.map((l, index) => (
                            <option
                                key={index}
                                style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }}
                                value={JSON.stringify(l)}
                            >
                                {l?.image_type}
                            </option>
                        ))}
                        <option
                            key={'Base'}
                            style={{ backgroundColor: 'rgba(60, 60,60,0.6)' }}
                            value={"Base"}
                        >
                            Base
                        </option>
                    </select>
                </div>
            </div>
            <img
                src={next}
                alt="Next"
                style={{ height: '15px', cursor: disableNext ? "not-allowed" : "pointer" }}
                onClick={() => {
                    if (!disableNext) onNext();
                }}
            />
        </div>
    );
}

export default CompareTool;