import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tool = ({ id, toolType, activeTool, activeImg, inactiveImg, toggleTool, tooltipText, isDisable }) => {
    return (
        <div
            className={"hoverPointer AeroFarm"}
            onClick={() => isDisable ?
                '' :
                toggleTool(toolType)
            }
            style={{ height: "8.2%", opacity: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: isDisable ? 'not-allowed' : "pointer" }}
        >
            {activeTool === toolType
                ? <img id={id} src={activeImg} data-tip data-for={id} width='17px' height="17px" />
                : <img src={inactiveImg} data-tip data-for={id} width='17px' height="17px" />
            }
            {!isDisable && <ReactTooltip offset={{ left: 10 }} id={id} place="left" type="light" effect="solid">
                <span style={{ zIndex: '3', position: 'relative' }}>{tooltipText}</span>
            </ReactTooltip>}
        </div>
    );
};

export default Tool;