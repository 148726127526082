import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from "react-dom";
import L from "leaflet";
import * as API from '../../api.js'
import SingleMap from '../../ReusableComponents/map/index.js';
import MarkerIcon from '../assets/icons/markerWithBase.svg'
import BlockDetailsPopup from './components/BlockDetailsPopup.js';
import Home from '../assets/sidebarIcons/Home.svg'
import PropertyDetailsPopup from './components/PropertyDetailsPopup.js';
import gujratData from './gujratData.json'
import { Popup, addUserAnalytic, checkURLExist } from '../../ReusableComponents/reusableFunctions.js';
import BlockHoverPopup from './BlockHoverPopup.js';
import PropertyHoverPopup from './PropertyHoverPopup.js';
import SelectDropDown from './components/Selectdropdown.js';
import { components } from 'react-select'
import DropDownIcon from "../../assets/dropDownIcon.svg";
import roadMapView from '../../assets/Images/roadMapViewzz.png'
import sateliteView from '../../assets/Images/sateliteView.png'
import service from "../../sidebaricon/Services.png";
import { BLOB_URL, MAP_URLS, tilesServer } from '../../AppConstants.js';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal.js';
import MapDrawingTool from './components/MapDrawingTool.js';
import MoonLoader from './components/MoonLoader.js';
import LumaAI from './LumaAI.js';
import GHBPotreeViewer from '../Components/GHBPotreeViewer.js';
import Tippy from '@tippyjs/react';
import DroneLabWrapper from './components/DroneLabWrapper.js';
import RightSlider from './components/RightSlider.js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeDrawingTool, isRightSlider, layerPropeties, openCompareTool, propertyDashBoardLoading, toggleLayer } from './Recoil/atom.js';
import { TOOLS } from './Constants/constants.js';
import OrthomosaicCompare from './components/OrthomosaicCompare.js';
import { checkIsUserAdmin, getActiveTeamId } from '../../Teams/index.js';
import { useHistory } from 'react-router-dom';

let mapInState
let divisionsGrp;
let citiesGrp;
let propertiesGrp;
let blocksGrp;
let encsGrp;
let orthoLayer;
let baseLayer
let isDivisionsAll = true
let isCitiesAll = false
const ZOOM_EXTRA_LEVELS = 6
let measurements = []
let blockLabelsGrp;
let importedLayers = []
let leftLayer
let rightLayer
let controlLayer

const drawGujratBoundary = async () => {
  const polygon = L.polygon(gujratData.gujrat, { color: '#ad2a21', fillOpacity: 0 })
  mapInState.fitBounds(polygon.getBounds())
  polygon.addTo(mapInState)
}

const PropertyDashboard = () => {
  const history = useHistory();
  const navigateTo = () => history.push('/logout');
  const [openRightSlider, setOpenRightSlider] = useRecoilState(isRightSlider)
  const isCompareTool = useRecoilValue(openCompareTool)
  const setActiveTool = useSetRecoilState(activeDrawingTool)
  const setLayerProperties = useSetRecoilState(layerPropeties)
  const toggleLayerS = useRecoilValue(toggleLayer)

  const [showThreed, setShowThreed] = useState(false)
  const [user, setUser] = useState(false)
  const [divisons, setDivisons] = useState([])
  const [cities, setCities] = useState([])
  const [areas, setAreas] = useState([])
  const [colonies, setColonies] = useState([])
  const [properties, setProperties] = useState([])
  const [blocks, setBlocks] = useState([])
  const [encs, setEncs] = useState([])
  const [selectedDivision, setSelectedDivsion] = useState({})
  const [selectedCity, setSelectedCity] = useState({})
  const [selectedArea, setSelectedArea] = useState({})
  const [selectedColony, setSelectedColony] = useState({})
  const [selectedProperty, setSelectedProperty] = useState({})
  const selectedPropertyRef = useRef(selectedProperty)
  const [selectedBlock, setSelectedBlock] = useState({})
  const [selectedEnc, setSelectedEnc] = useState({})
  const [loading, setLoading] = useRecoilState(propertyDashBoardLoading)
  const [propertyDetailsPopupData, setPropertyDetailsPopupData] = useState({ show: false, showExplore: true })
  const [blockDetailsPopupData, setblockDetailsPopupData] = useState({ show: false });
  const [propertyTypes, setPropertyTypes] = useState([])
  const [selectedPropertyType, setSelectedPropertyType] = useState("")
  const [width, setWidth] = useState('100%');
  const [activeBaseLayer, setActiveBaseLayer] = useState('satelite')
  const [showPotreeViewer, setShowPotreeViewer] = useState(false)
  const [potree, setPotree] = useState(false)
  const [openThreeDMenu, setOpenThreeDMenu] = useState(false)
  const [showPointCloud, setShowPointCloud] = useState(false)
  const [showTexture, setShowTexture] = useState(false)
  const [showBoth, setShowBoth] = useState(false)
  const [showPotreeViewerButton, setShowPotreeViewerButton] = useState(false)
  const [pointCloudAvailable, setPointCloudAvailable] = useState(false)
  const [textureAvailable, setTextureAvailable] = useState(false)
  const [removeMapSwitch, setRemoveMapSwitch] = useState(false)
  const [volumeBoxDrawn, setVolumeBoxDrawn] = useState(false)
  const [fetchMeasurements, setFetchMeasurements] = useState([])
  const [selectedMeasurement, setSelectedMeasurement] = useState({})
  const [activeTeamId, setActiveTeamId] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const activeTeamRef = useRef(activeTeamId)

  let sidebarConfigurations = {
    options: isAdmin ?
      [
        {
          icon: service,
          text: "Services",
          path: "/",
          selected: false
        },
        {
          icon: Home,
          text: "Home",
          path: "/ghb/dashboard",
          selected: true
        },

      ] :
      [
        {
          icon: Home,
          text: "Home",
          path: "/",
          selected: true
        },
      ],
    user
  };

  const switchBaseLayer = () => {
    const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
    setActiveBaseLayer(activeLayer)
    if (baseLayer) {
      baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    }
  }

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={DropDownIcon} alt="dropdown icon" style={{ width: 15, height: 15 }} />
      </components.DropdownIndicator>
    );
  };

  const drawDivisionsOnMap = async (divisions) => {
    return new Promise(async (resolve) => {

      if (divisionsGrp) {
        mapInState.removeLayer(divisionsGrp)
      }

      mapInState.createPane('divisions-pane')
      mapInState.getPane('divisions-pane').style.zIndex = 895
      const divisonsLayerGroup = L.layerGroup()
      const newDivisions = await Promise.all(divisions.map(async div => {
        return new Promise((resolve) => {

          const coordinates = JSON.parse(div.coordinates)
          const polygon = L.polygon(coordinates, { color: '#ad2a21', fillOpacity: 0, pane: 'divisions-pane' })

          polygon.bindTooltip(div.name, {
            permanent: false,
            direction: 'center',
            className: 'custom-leaflet-tooltip',
            pane: 'tool-tip-pane'
          })
          function highlight_on() {
            if (isDivisionsAll) {
              polygon.setStyle({ fillOpacity: 0.2 })
            }
          }
          function highlight_off() {
            if (isDivisionsAll) {
              polygon.setStyle({ fillOpacity: 0 })
            }
          }

          polygon.on('click', async function (e) {
            clearCities()
            await drawDivisionsOnMap([div])
            getCitiesById(div)
            setSelectedDivsion(div)
          })
          polygon.on("mouseover", highlight_on)
          polygon.on("mouseout", highlight_off)
          polygon.on('click', async function (e) {
            if (isDivisionsAll) {
              isDivisionsAll = false
              isCitiesAll = true
              clearCities()
              await drawDivisionsOnMap([div])
              getCitiesById(div)
              setSelectedDivsion(div)
            }
          })

          divisonsLayerGroup.addLayer(polygon)
          div.layer = polygon
          resolve(div)
        })

      }))

      divisonsLayerGroup.addTo(mapInState)
      divisionsGrp = divisonsLayerGroup
      let featureGrp = L.featureGroup(divisonsLayerGroup.getLayers())
      mapInState.fitBounds(featureGrp.getBounds())
      resolve(newDivisions)
    })
  }

  const drawCitiesOnMap = async (divison, cities) => {
    return new Promise(async (resolve, reject) => {

      if (citiesGrp) {
        mapInState.removeLayer(citiesGrp)
      }
      mapInState.createPane('cities-pane')
      mapInState.getPane('cities-pane').style.zIndex = 896
      const citiesLayerGroup = L.layerGroup()
      const newCities = await Promise.all(cities.map(async city => {
        return new Promise((resolve) => {

          const coordinates = JSON.parse(city.coordinates)
          const polygon = L.polygon(coordinates, { color: '#d46e15', fillOpacity: 0, pane: 'cities-pane' })

          polygon.bindTooltip(city.name, {
            permanent: false,
            direction: 'center',
            className: 'custom-leaflet-tooltip',
            pane: 'tool-tip-pane'
          })
          function highlight_on() {
            if (isCitiesAll) {
              polygon.setStyle({ fillOpacity: 0.2 })
            }
          }
          function highlight_off() {
            if (isCitiesAll) {
              polygon.setStyle({ fillOpacity: 0 })
            }
          }

          polygon.on('click', async function (e) {
            clearProperties()
            clearAreas()
            await drawCitiesOnMap(undefined, [city])
            setLoading(true)
            await Promise.all([
              await getAreasById(city),
              await getPropertyTypes(),
              await getProperties(null, null, city, null)
            ])
            setLoading(false)
            setSelectedCity(city)
          })
          polygon.on("mouseover", highlight_on)
          polygon.on("mouseout", highlight_off)
          polygon.on('click', async function (e) {
            if (isCitiesAll) {
              clearDivisions()
              isCitiesAll = false
              clearProperties()
              clearAreas()
              await drawCitiesOnMap(undefined, [city])
              setLoading(true)
              await Promise.all([await getAreasById(city),
              await getPropertyTypes(),
              await getProperties(null, null, city, null)])
              setLoading(false)
              setSelectedCity(city)
            }
          })
          citiesLayerGroup.addLayer(polygon)
          city.layer = polygon
          resolve(city)
        })

      }))
      citiesLayerGroup.addTo(mapInState)
      citiesGrp = citiesLayerGroup
      if (divison && divison.layer) {
        mapInState.fitBounds(divison.layer.getBounds())
      } else {
        let featureGrp = L.featureGroup(citiesLayerGroup.getLayers())
        mapInState.fitBounds(featureGrp.getBounds())
      }
      resolve(newCities)
    })
  }

  const drawPropertiesOnMap = async (city, properties, showAsMarker) => {
    if (propertiesGrp) {
      mapInState.removeLayer(propertiesGrp)
    }
    if (properties?.length > 0 || properties) {
      return new Promise(async (resolve, reject) => {

        mapInState.createPane('properties-pane')
        mapInState.getPane('properties-pane').style.zIndex = 897
        let propertiesLayerGroup = L.layerGroup()
        const newProperties = await Promise.all(properties?.map(async prop => {
          return new Promise((resolve) => {
            let coordinates = JSON.parse(prop?.coordinates)
            coordinates = coordinates.map(c => [c.lat, c.lng])
            const polygon = L.polygon(coordinates, { color: 'blue', fillOpacity: 0, pane: 'properties-pane' })
            if (showAsMarker) {
              const center = polygon.getBounds().getCenter()
              const markerIcon = L.icon({
                iconUrl: MarkerIcon,
                iconSize: [24, 32],
                iconAnchor: [10, 28]
              })
              const marker = L.marker(center, {
                icon: markerIcon
              })
              const popup = L.popup({
              })

              marker.bindPopup(popup, { offset: [0, -20] })
              marker.on('mouseover', function (e) {
                marker.openPopup();
                const container = document.createElement('div');
                container.setAttribute('id', "leafletPopup");
                const popupFunc = () => {
                  ReactDOM.render(
                    <PropertyHoverPopup
                      onCloseCallback={() => {
                        marker.closePopup();
                        ReactDOM.unmountComponentAtNode(document.getElementById("leafletPopup"));
                      }}
                      property={prop}
                    />,
                    container
                  );
                  return container;
                };
                popup.setContent(popupFunc);
              })

              marker.on('mouseout', () => {
                marker.closePopup()
              })

              marker.on('click', function (e) {
                setPropertyDetailsPopupData(prev => ({ ...prev, show: true, showExplore: true, ...prop }))
              })

              propertiesLayerGroup.addLayer(marker)
              prop.layer = marker
            } else {
              polygon.on('click', function (e) {
                setPropertyDetailsPopupData(prev => ({ ...prev, show: true, ...prop, showExplore: false }))
              })
              propertiesLayerGroup.addLayer(polygon)
              prop.layer = polygon
            }
            resolve(prop)
          })

        }))
        propertiesLayerGroup.addTo(mapInState)
        propertiesGrp = propertiesLayerGroup

        if (city && city.layer) {
          const cityBounds = city.layer.getBounds();
          if (cityBounds.isValid()) {
            mapInState.fitBounds(cityBounds);
          } else {
            console.error("City bounds are not valid:", cityBounds);
          }
        } else {
          const featureGrp = L.featureGroup(propertiesLayerGroup.getLayers());
          const featureBounds = featureGrp.getBounds();
          if (featureBounds.isValid()) {
            mapInState.fitBounds(featureBounds);
          } else {
            console.error("Feature group bounds are not valid:", featureBounds);
          }
        }
        resolve(newProperties)
      })
    }
  }

  const createBlockLabel = (coordinates, label) => {
    let style = "width: 30px; height: 30px; padding: 8px; display: flex; align-items: center; justify-content: center; color: red; background: white; border-radius: 50%; pointer-events: none;"

    const markerOptions = {
      title: label,
      clickable: true,
      draggable: false,
      autoPanOnFocus: true,
      icon: new L.DivIcon({
        className: 'block-import',
        html: `<div style="${style}">${label}</div>`
      })
    };

    const layer = new L.Marker(coordinates, markerOptions);
    layer.show = true;
    return layer;
  };

  const drawBlocksOnMap = async (property, blocks) => {
    return new Promise(async (resolve, reject) => {
      if (blocksGrp) {
        mapInState.removeLayer(blocksGrp)
      }
      const blocksPane = mapInState.createPane('blocks-pane')
      mapInState.getPane('blocks-pane').style.zIndex = 899
      const blocksLayerGroup = L.layerGroup()
      const blockLabelLayerGrp = L.layerGroup()
      const newBlocks = await Promise.all(blocks?.map(async block => {
        return new Promise((resolve) => {
          let coordinates;
          try {
            coordinates = JSON.parse(block.coordinates);
          } catch (error) {
            console.error('Error parsing block.coordinates:', error);
            resolve(null);
            return;
          }

          coordinates = coordinates.map(c => {
            if (c && typeof c.lat === 'number' && typeof c.lng === 'number') {
              return [c.lat, c.lng];
            } else {
              return null;
            }
          }).filter(c => c !== null);

          if (coordinates.length === 0) {
            console.error('No valid coordinates found');
            resolve(null);
            return;
          }

          const polygon = L.polygon(coordinates, { color: 'yellow', fillOpacity: 0, pane: 'blocks-pane' });
          const center = polygon.getBounds().getCenter();
          const labelLayer = createBlockLabel(center, block.name)
          block.labelLayer = labelLayer
          blockLabelLayerGrp.addLayer(labelLayer)
          const popup = L.popup({});

          polygon.bindPopup(popup, { offset: [0, -20] });

          polygon.on('mouseover', function (e) {
            polygon.openPopup();
            const container = document.createElement('div');
            container.setAttribute('id', "leafletPopup");
            const popupFunc = () => {
              ReactDOM.render(
                <BlockHoverPopup
                  onCloseCallback={() => {
                    polygon.closePopup();
                    ReactDOM.unmountComponentAtNode(document.getElementById("leafletPopup"));
                  }}
                  block={block}
                />,
                container
              );
              return container;
            };
            popup.setContent(popupFunc);
          });

          polygon.on('mouseout', () => {
            polygon.closePopup();
          });

          polygon.on('click', () => {
            setblockDetailsPopupData(prev => ({ ...prev, show: true, ...block }));
          });

          blocksLayerGroup.addLayer(polygon);
          block.layer = polygon;
          resolve(block);
        });
      }))
      blocksLayerGroup.addTo(mapInState)
      blocksGrp = blocksLayerGroup
      blockLabelLayerGrp.addTo(mapInState)
      blockLabelsGrp = blockLabelLayerGrp
      if (property && property.layer) {
        mapInState.fitBounds(property.layer.getBounds())
      } else {
        let featureGrp = L.featureGroup(blocksLayerGroup.getLayers())
        mapInState.fitBounds(featureGrp.getBounds())
      }
      resolve(newBlocks)
    })
  }

  const drawEncroachmentsOnMap = async (property, encs) => {
    return new Promise(async (resolve, reject) => {

      if (encsGrp) {
        mapInState.removeLayer(encsGrp)
      }
      const encsPane = mapInState.createPane('encs-pane')
      mapInState.getPane('encs-pane').style.zIndex = 898
      const encsLayerGroup = L.layerGroup()
      const newEncs = await Promise.all(encs?.map(async block => {
        return new Promise((resolve) => {

          let coordinates = JSON.parse(block.coordinates)
          coordinates = coordinates?.map(c => [c.lat, c.lng])
          const polygon = L.polygon(coordinates, { color: 'red', fillOpacity: 0, pane: 'encs-pane' })
          encsLayerGroup.addLayer(polygon)
          block.layer = polygon
          resolve(block)
        })

      }))
      encsLayerGroup.addTo(mapInState)
      encsGrp = encsLayerGroup
      if (property && property.layer) {
        mapInState.fitBounds(property.layer.getBounds())
      } else {
        let featureGrp = L.featureGroup(encsLayerGroup.getLayers())
        mapInState.fitBounds(featureGrp.getBounds())
      }
      resolve(newEncs)
    })
  }

  const getEncroachments = (property) => {
    setLoading(true)
    API.getEncroachments(property.id)
      .then(async (res) => {
        const newEncs = await drawEncroachmentsOnMap(property, res.encroachments)
        setEncs(newEncs)
        setSelectedEnc({ id: "All", name: "All" })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const getPropertyTypes = async () => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      API.getPropertyTypes()
        .then((res) => {
          setPropertyTypes(res.types_of_properties)
          setSelectedPropertyType("All")
          setLoading(false)
          resolve()
        })
        .catch((e) => {
          setLoading(false)
          Popup.alert("ERROR", e.message || e)
          reject(e)
        })
    })
  }

  const getBlocks = (property) => {
    setLoading(true)
    API.getBlocks(property.id)
      .then(async (res) => {
        const newBlocks = await drawBlocksOnMap(property, res.blocks)
        setBlocks(newBlocks)
        setSelectedBlock({ id: "All", name: "All" })
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const clearLocalStorage = () => {
    localStorage.removeItem("recentSearches")
    localStorage.removeItem('dateUnit')
    addUserAnalytic('/logout')
  }

  const getProperties = async (taskId, divisionId, city, areaId, propertyType, colonyId) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      API.getProperties(taskId, divisionId, city?.id, areaId, propertyType && propertyType !== "All" ? propertyType : null, true, colonyId, activeTeamRef.current)
        .then(async (res) => {
          const newProps = await drawPropertiesOnMap(city || selectedCity, res.properties, true)
          setProperties(newProps)
          setSelectedProperty({ name: "All", id: "All" })
          setLoading(false)
          resolve()
        })
        .catch((e) => {
          setLoading(false)
          console.log(e)
          Popup.alert("ERROR", e, () => {
            clearLocalStorage()
            navigateTo()
          }, () => {
            clearLocalStorage()
            navigateTo()
          })
          reject(e)
        })
    })
  }

  const getAreasById = async (city) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      API.getAreasOfCity(city.id)
        .then((res) => {
          setAreas(res.city_areas)
          setSelectedArea({ name: "All", id: "All" })
          setLoading(false)
          resolve()
        })
        .catch((e) => {
          setLoading(false)
          console.log(e)
          reject(e)
        })
    })
  }

  const getColoniesByAreaId = async (areaId, divisionId, cityId, propertyType) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      API.getColoniesOfArea(areaId, divisionId, cityId, propertyType)
        .then((res) => {
          setColonies(res?.colonies || [])
          setSelectedColony({ name: "All", id: "All" })
          setLoading(false)
          resolve()
        })
        .catch((e) => {
          setLoading(false)
          console.log(e)
          reject(e)
        })
    })
  }

  const getCitiesById = (divison) => {
    setLoading(true)
    API.getCitiesOfDivision(divison.id)
      .then(async (res) => {
        const newCities = await drawCitiesOnMap(divison, res.cities)
        setSelectedCity({ id: "All", name: "All" })
        setCities(newCities)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const getDivisions = () => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      API.getAllDivisions()
        .then(async (res) => {
          const newDivisions = await drawDivisionsOnMap(res.divisions)
          setSelectedDivsion({ id: "All", name: "All" })
          setDivisons(newDivisions)
          setLoading(false)
          resolve()
        })
        .catch((e) => {
          setLoading(false)
          Popup.alert("ERROR", e)
          reject(e)
        })
    })
  }

  const getTypeOfUser = async () => {
    try {
      const [registered, type, user, user_info] = await API.getUserType()
      setUser(user)
      if (checkIsUserAdmin()) {
        getDivisions()
      }
      else {
        if (activeTeamRef.current) {
          const propertyAccesses = await getPropertyAccessByUser(user?.userid)
          if (propertyAccesses?.length === 0) {
            Popup.alert("ERROR", "You are not authorized to access this resource. Please contact the team admin or try switching the team from your profile.", () => { }, () => { })
          }
          else {
            getDivisions()
          }
        }
        else {
          Popup.alert("ERROR", "You are not authorized to access this resource. Please contact admin.", () => {
            clearLocalStorage()
            navigateTo()
          }, () => {
            clearLocalStorage()
            navigateTo()
          })
        }
      }
    } catch (error) {
      console.log(error)
      Popup.alert("ERROR", error)
    }
  }

  const getActiveTeamIdOfLoginUser = async () => {
    const teamId = await getActiveTeamId()
    setActiveTeamId(teamId)
    activeTeamRef.current = teamId
  }

  const clearDivisions = () => {
    if (divisionsGrp) {
      mapInState.removeLayer(divisionsGrp)
    }
  }

  const clearEncs = () => {
    setEncs([])
    setSelectedEnc({ id: "", name: "" });
    if (encsGrp) {
      mapInState.removeLayer(encsGrp)
    }
  }

  const clearBlocks = () => {
    setBlocks([])
    setSelectedBlock({ id: "", name: "" });
    if (blocksGrp) {
      mapInState.removeLayer(blocksGrp)
    }
    if (blockLabelsGrp) {
      mapInState.removeLayer(blockLabelsGrp)
    }
  }

  const clearCompareLayer = () => {
    if (leftLayer) {
      mapInState.removeLayer(leftLayer);
    }
    if (rightLayer) {
      mapInState.removeLayer(rightLayer);
    }
    if (controlLayer) {
      mapInState.removeControl(controlLayer);
    }
  }

  const clearProperties = () => {
    clearCompareLayer()
    setProperties([])
    setSelectedProperty({ id: "", name: "" })
    if (propertiesGrp) {
      mapInState.removeLayer(propertiesGrp)
    }
    if (measurements.length > 0) {
      measurements.map((m) => {
        mapInState.removeLayer(m)
      })
      measurements = []
    }
    if (importedLayers.length > 0) {
      importedLayers.map((m) => {
        mapInState.removeLayer(m)
      })
      importedLayers = []
    }
    clearBlocks()
    clearEncs()
  }

  const clearAreas = () => {
    setAreas([])
    setSelectedArea({ id: "", name: "" })
  }

  const clearCities = () => {
    setCities([])
    setSelectedPropertyType("All")
    setSelectedCity(prev => ({}))
    if (citiesGrp) {
      mapInState.removeLayer(citiesGrp)
    }
    clearProperties()
    clearAreas()
  }

  const clearCitiesGrpAfterProperty = () => {
    if (citiesGrp) {
      mapInState.removeLayer(citiesGrp)
    }
  }

  const getOrthoTilesBounds = async () => {
    return new Promise(async (resolve, reject) => {
      setLoading(true)
      let tiffType = "orthomosaic"
      const { task_id, sas_token, blob_container } = selectedProperty
      let taskId = task_id
      let sas = encodeURIComponent(sas_token)
      let blobContainer = blob_container
      API.getTilesBounds({ taskId, tiffType, sas, blobContainer }).then(async (data) => {
        if (data.tilesBounds) {

          const orthoDetails = {
            tilesBounds: data.tilesBounds,
            bandsList: data.band_descriptions,
            bounds: data.bounds,
            tileMinZoom: data.minZoom - ZOOM_EXTRA_LEVELS,
            tileMaxZoom: data.maxZoom + ZOOM_EXTRA_LEVELS,
            defaultOrtho: true,
            band1: "1",
            band2: "2",
            band3: "3"
          }
          setLoading(false)
          resolve(orthoDetails)
        } else {
          setLoading(false)
          resolve(null)
        }
      })
    })
  }

  const overlayTilesLayerOf = async (orthomosaic) => {
    setLoading(true)
    if (orthoLayer) {
      mapInState.removeLayer(orthoLayer)
    }
    const { task_id, sas_token, blob_container } = selectedProperty

    let taskid = task_id
    let blobContainer = blob_container;
    let sas = encodeURIComponent(sas_token);
    let bnds = [[orthomosaic?.bounds[1], orthomosaic?.bounds[0]], [orthomosaic?.bounds[3], orthomosaic?.bounds[2]]]
    let bandsSelection = "&viewDefault=true"
    orthoLayer = L.tileLayer(`${tilesServer}/tiles/${taskid}/orthomosaic/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
      tileSize: 256,
      maxZoom: 28,
      bounds: bnds,
    }).addTo(mapInState);
    setLoading(false)
  }

  const setupOrthoMosaicForProperty = async () => {
    const ortho = await getOrthoTilesBounds()
    if (ortho) {
      overlayTilesLayerOf(ortho)
    }
  }

  const savePropertyMeasurement = (name, coordinates, layer, color, type) => {
    const propertyId = selectedPropertyRef.current.id
    let newLayer = layer
    API.addPropertyMeasurement({ name, description: '', coordinates, propertyId, color, type })
      .then((res) => {
        newLayer.id = res.propertyMeasurement.id
        setSelectedProperty((prev) => ({
          ...prev,
          measurements: [
            ...(prev?.measurements || []),
            {
              ...res.propertyMeasurement,
              layer: newLayer
            }
          ]
        }))
      })
      .catch((e) => {
        Popup.alert("ERROR", e || e.message)
      })
  }

  const deteletMeasurementOfProperty = (mId) => {
    API.deletePropertyMeasurement(mId)
      .then((res) => {
        const filteredMeasurements = selectedPropertyRef.current.measurements.filter((m) => m.id !== res.id)
        setSelectedProperty((prev) => ({
          ...prev,
          measurements: filteredMeasurements
        }))
      })
      .catch((e) => {
        Popup.alert("ERROR", e || e.message)
      })
  }

  const getMeasurementsOfProperty = () => {
    setLoading(true)
    const propertyId = selectedPropertyRef.current.id
    API.getPropertyMeasurements(propertyId)
      .then((res) => {
        setFetchMeasurements(res.propertyMeasurements)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        Popup.alert("ERROR", e || e.message)
      })
  }

  const updateMeasurementOfProperty = (layer, name, description, color) => {
    const id = layer?.id || selectedMeasurement.id || selectedPropertyRef.current.measurements[selectedPropertyRef.current.measurements.length - 1].id
    let cood
    if (layer instanceof L.Polygon) {
      cood = layer.getLatLngs()[0].map(c => ({ lat: c.lat, lng: c.lng }))
    }
    else if (layer instanceof L.Polyline) {
      cood = layer.getLatLngs().map(c => ({ lat: c.lat, lng: c.lng }))
    }
    else if (layer instanceof L.Marker) {
      cood = layer.getLatLng()
    }
    let measurementToBeUpdated = selectedPropertyRef.current.measurements.filter((m) => m.id === id)[0]
    measurementToBeUpdated = {
      ...measurementToBeUpdated,
      coordinates: cood || measurementToBeUpdated.coordinates,
      name: name || measurementToBeUpdated.name,
      description: description || measurementToBeUpdated.description,
      color: color || measurementToBeUpdated.color,
      layer: undefined
    }

    API.updatePropertyMeasurement(id, measurementToBeUpdated)
      .then((res) => {
        const updatedMeasurement = {
          ...res,
          layer: layer
        }
        setSelectedProperty((prev) => ({
          ...prev,
          measurements: prev.measurements.map((m) => {
            if (m.id === updatedMeasurement.id)
              return updatedMeasurement
            else
              return m
          })
        }))
        setSelectedMeasurement(updatedMeasurement)
      })
      .catch((e) => {
        Popup.alert("ERROR", e || e.message)
      })
  }

  const change3DMenu = (activeBtn) => {
    switch (activeBtn) {
      case "pointcloud":
        setShowTexture(false)
        setShowPointCloud(true)
        setShowBoth(false)
        break;
      case "texture":
        setShowTexture(true)
        setShowPointCloud(false)
        setShowBoth(false);
        break;
      case "both":
        setShowTexture(false)
        setShowPointCloud(false)
        setShowBoth(true);
        break;
    }
    setOpenThreeDMenu(false)
  }

  const checkIf3dFilesExist = async () => {
    const { blob_container, sas_token, task_id } = selectedProperty
    let indTextureAvailable = await checkURLExist(`${BLOB_URL}/${blob_container}/orthomosaicImages/${task_id}/threeD/textured_model_geo.obj?${sas_token}`) &&
      await checkURLExist(`${BLOB_URL}/${blob_container}/orthomosaicImages/${task_id}/threeD/textured_model_geo.mtl?${sas_token}`) ? true : false

    let indPointCloudAvailable = (await checkURLExist(`${BLOB_URL}/${blob_container}/orthomosaicImages/${task_id}/ept/ept.json?${sas_token}`)
      || await checkURLExist(`${BLOB_URL}/${blob_container}/orthomosaicImages/${task_id}/cloud/cloud.js?${sas_token}`)) ? true : false
    setPointCloudAvailable(indPointCloudAvailable)
    setTextureAvailable(indTextureAvailable)
    if (indTextureAvailable || indPointCloudAvailable)
      setShowPotreeViewerButton(true)
  }

  const getMapAssetLayers = () => {
    setLoading(true)
    API.getMapAssetLayers(selectedProperty?.task_id)
      .then((res) => {
        setSelectedProperty((prev) => ({
          ...prev,
          propertyAssets: res?.assets
        }))
        setLoading(false)
      }).catch((e) => {
        Popup.alert("ERROR", e || e?.message)
        setLoading(false)
      })
  }

  const getPropertyAccessByUser = (userId) => {
    setLoading(true)
    return API.getPropertiesAccessesByUser(userId, activeTeamRef.current)
      .then((res) => {
        setLoading(false);
        return res.propertyAccesses;
      })
      .catch((e) => {
        Popup.alert("ERROR", e?.message || "An unexpected error occurred");
        setLoading(false);
      });
  }

  useEffect(() => {
    getActiveTeamIdOfLoginUser()
    getTypeOfUser()
  }, [])

  useEffect(() => {
    if (selectedProperty?.id && selectedProperty?.id !== "All") {
      getMapAssetLayers()
    }
  }, [selectedProperty.id])

  useEffect(() => {
    selectedPropertyRef.current = selectedProperty
    if (selectedProperty?.id && selectedProperty?.id !== "All") {
      checkIf3dFilesExist()
      setupOrthoMosaicForProperty()
    } else {
      if (orthoLayer) {
        mapInState.removeLayer(orthoLayer)
      }
    }
  }, [selectedProperty?.id])

  useEffect(() => {
    selectedPropertyRef.current = selectedProperty
  }, [selectedProperty])

  useEffect(() => {
    if (selectedMeasurement.id && !toggleLayerS) {
      setSelectedMeasurement({})
    }
  }, [toggleLayerS])

  useEffect(() => {
    clearCompareLayer()
  }, [isCompareTool])

  useEffect(() => {
    setIsAdmin(checkIsUserAdmin())
  }, [checkIsUserAdmin()])


  return (
    <DroneLabWrapper header={"Drone Dashboard"} sidebarConfig={sidebarConfigurations}>
      <AnimatedModal
        isOpen={propertyDetailsPopupData?.show}
        height={'max-content'}
        padding={"0px"}
      >
        <PropertyDetailsPopup
          onCloseCallback={() => {
            setPropertyDetailsPopupData((prev) => ({ ...prev, show: false }));
          }}
          showExplore={propertyDetailsPopupData.showExplore}
          property={propertyDetailsPopupData}
          onExploreProperty={async (propertyId) => {
            setPropertyDetailsPopupData((prev) => ({ ...prev, show: false }));
            clearCitiesGrpAfterProperty()
            clearBlocks()
            clearEncs()
            const prop = properties.find(p => p.id === propertyId)
            setLoading(true)
            await drawPropertiesOnMap(undefined, [prop])
            setLoading(false)
            setSelectedProperty(prop)
            getBlocks(prop)
            getEncroachments(prop)
            getMeasurementsOfProperty()
          }}
        />
      </AnimatedModal>
      <AnimatedModal
        isOpen={blockDetailsPopupData?.show}
        height={'max-content'}
        padding={"0px"}
      >
        <BlockDetailsPopup
          onCloseCallback={() => {
            setblockDetailsPopupData((prev) => ({ ...prev, show: false }));
          }}
          block={blockDetailsPopupData}
        />
      </AnimatedModal>

      {showThreed &&
        <div style={{ width: "100%", position: "fixed", height: "calc(100vh - 60px)", zIndex: 2 }}>
          <LumaAI modelURL={selectedProperty.luma_ai_url} onLoad={() => { setLoading(false) }} />
        </div>
      }
      {selectedProperty?.task_id && showPotreeViewer && <div style={{ width: "100%", position: "fixed", height: "calc(100vh - 60px)", zIndex: 2, backgroundColor: '#000000' }}>
        <GHBPotreeViewer pointCloudAvailable={pointCloudAvailable} textureAvailable={textureAvailable} selectedProperty={selectedProperty} potreeCallback={(potree) => {
          setPotree(potree)
        }}
          setVolumeBoxDrawn={(val) => {
            setVolumeBoxDrawn(val)
          }}
        />
      </div>}
      <div style={{ display: "flex", flexDirection: "column", height: '100%', width: '100%', }}>
        <div style={{
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 65px',
          width: '100%',
          top: "0",
          gap: '2%',
          left: "0",
          background: " #FFFFFFB2",
          zIndex: 10,
          position: "relative",
          height: '70px'
        }}>
          {/* Divisons */}
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              Division
            </div>
            <SelectDropDown
              placeholder=""
              noOptionsMessage={"No divisions"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              separator={true}
              controlStyle={{ width: width }}
              isLoading={loading}
              list={
                [
                  { label: "All", value: "All" },
                  ...divisons?.map((divison) => ({
                    label: divison.name,
                    value: divison.id,
                    coordinates: divison.coordinates
                  }))
                ]
              }
              onChange={async (e) => {
                setSelectedColony({ id: 'All', name: "All" })
                setColonies([])
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  isDivisionsAll = true
                  setSelectedColony({})
                  clearCities()
                  getDivisions()
                }
                else {
                  isDivisionsAll = false
                  isCitiesAll = true
                  clearCities()
                  setSelectedColony({ id: 'All', name: "All" })
                  const division = divisons.find(div => div.id == e?.value)
                  if (division) {
                    await drawDivisionsOnMap([division])
                    getCitiesById(division)
                    setSelectedDivsion(division)
                  }
                }
              }}
              value={selectedDivision.id ? [{ label: selectedDivision.name, value: selectedDivision.id }] : []}
              isDisabled={divisons.length === 0 || loading}
            />
          </div>
          {/* Cities */}
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              City
            </div>
            <SelectDropDown
              placeholder=""
              noOptionsMessage={"No cities"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              controlStyle={{ width: width }}
              list={
                [
                  { label: "All", value: "All" },
                  ...cities?.map((city) => ({
                    label: city.name,
                    value: city.id,
                    coordinates: city.coordinates
                  }))
                ]
              }
              isLoading={loading}
              onChange={async (e) => {
                setSelectedColony({ id: 'All', name: "All" })
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  isCitiesAll = true
                  clearProperties()
                  clearAreas()
                  setColonies([])
                  setSelectedColony({})
                  await drawDivisionsOnMap([selectedDivision])
                  getCitiesById(selectedDivision)
                }
                else {
                  isCitiesAll = false
                  setLoading(true)
                  clearDivisions()
                  clearProperties()
                  clearAreas()
                  const cty = cities.find(c => c.id === e?.value)
                  await drawCitiesOnMap(undefined, [cty])
                  await Promise.all([await getAreasById(cty), await getColoniesByAreaId(null, null, cty.id), await getPropertyTypes(), await getProperties(null, null, cty, null)])
                  setSelectedCity(cty)
                }
              }}
              value={selectedCity.id ? [{ label: selectedCity.name, value: selectedCity.id }] : []}
              isDisabled={cities.length === 0 || loading}
            />
          </div>
          {/* Areas */}
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              Area Of City
            </div>
            <SelectDropDown
              placeholder=""
              noOptionsMessage={"No area"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              controlStyle={{ width: width }}
              value={selectedArea.id ? [{ label: selectedArea.name, value: selectedArea.id }] : []}
              isLoading={loading}
              list={
                [{ label: "All", value: "All" }
                  , ...areas?.map((area) => ({
                    label: area.name,
                    value: area.id,
                    coordinates: area.coordinates
                  }))
                ]
              }
              onChange={async (e) => {
                if (!e?.value || selectedArea.id == e?.value) return;
                setLoading(true)
                setSelectedColony({ id: 'All', name: "All" })
                setSelectedPropertyType("All")
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  setSelectedArea({ id: 'All', name: "All" })
                  clearProperties()
                  await getProperties(null, null, selectedCity, null, null)
                  await getColoniesByAreaId(null, selectedDivision.id, selectedCity.id)
                } else {
                  clearProperties()
                  const area = areas.find(a => a.id === e?.value)
                  setSelectedArea(area)
                  await getColoniesByAreaId(area.id, selectedDivision.id, selectedCity.id)
                  await getProperties(null, null, null, area.id, null)
                }
                setLoading(false)

              }}
              isDisabled={!selectedCity.id || areas.length === 0 || loading}
            />
          </div>
          {/* PropertyType */}
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              Property Type
            </div>
            <SelectDropDown
              placeholder=""
              noOptionsMessage={"No property type"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              controlStyle={{ width: width }}
              value={selectedPropertyType ? [{ label: selectedPropertyType, value: selectedPropertyType }] : []}
              isLoading={loading}
              list={
                [
                  { label: "All", value: "All" },
                  ...propertyTypes?.map((type) => ({
                    label: type,
                    value: type,
                  }))
                ]
              }
              onChange={async (e) => {
                if (!e?.value || selectedPropertyType == e?.value) return;
                setLoading(true)
                setSelectedColony({ id: 'All', name: "All" })
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  setSelectedPropertyType("All")
                  clearProperties()
                  await getProperties(null, null, selectedCity)
                  await getColoniesByAreaId(selectedArea.id, selectedDivision.id, selectedCity.id)
                } else {
                  clearProperties()
                  setSelectedPropertyType(e?.value)
                  await getProperties(null, null, selectedCity, selectedArea.id == "All" ? "" : selectedArea.id, e?.value)
                  await getColoniesByAreaId(selectedArea.id, selectedDivision.id, selectedCity.id, e?.value)
                }
                setLoading(false)
              }}
              isDisabled={selectedCity.id === "All" || !selectedCity.id || propertyTypes.length === 0 || loading}
            />
          </div>
          {/* Colonies */}
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              Name of Colony
            </div>
            <SelectDropDown
              placeholder=""
              noOptionsMessage={"No proeprties"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              controlStyle={{ width: width }}
              value={selectedColony.id ? [{ label: selectedColony.name, value: selectedColony.id }] : []}
              isLoading={loading}
              list={
                [
                  { label: "All", value: "All" },
                  ...colonies?.map((prop) => ({
                    label: prop.name_of_colony,
                    value: prop.id
                  }))
                ]
              }
              onChange={async (e) => {
                if (!e?.value || selectedColony.id == e?.value) return;
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  setLoading(true)
                  setSelectedColony({ name: "All", id: "All" })
                  await drawCitiesOnMap(undefined, [selectedCity])
                  clearBlocks()
                  clearEncs()
                  const prop = await getProperties(null, null, selectedCity, selectedArea.id == "All" ? "" : selectedArea.id, selectedPropertyType)
                  if (prop?.length > 0 || prop)
                    await drawPropertiesOnMap(selectedCity, prop, true)
                  setLoading(false)
                } else {
                  setLoading(true)
                  setSelectedColony({ name: e?.label, id: e?.value })
                  await drawCitiesOnMap(undefined, [selectedCity])
                  clearBlocks()
                  clearEncs()
                  const prop = await getProperties(null, null, selectedCity, selectedArea.id == "All" ? "" : selectedArea.id, selectedPropertyType, e?.value)
                  if (prop?.length > 0 || prop)
                    await drawPropertiesOnMap(selectedCity, prop, true)
                  setLoading(false)
                }
              }}
              isDisabled={colonies.length === 0 || loading}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div style={{
              fontSize: "11px",
              color: "#3c3c3c",
              fontWeight: "600",
              padding: "0px 2px 2px 2px",
            }}>
              Block Number
            </div>
            <SelectDropDown
              isLoading={loading}
              placeholder=""
              noOptionsMessage={"No block"}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              controlStyle={{ width: width }}
              value={selectedBlock.id ? [{ label: selectedBlock.name, value: selectedBlock.id }] : []}
              disabled={blocks.length === 0}
              list={
                [
                  { label: "All", value: "All" },
                  ...blocks?.map((block) => ({
                    label: block?.name,
                    value: block?.id
                  }))
                ]
              }
              onChange={async (e) => {
                if (!e?.value || selectedBlock.id == e?.value) return;
                if (showThreed)
                  setShowThreed(false)
                if (e?.value == "All") {
                  getBlocks(selectedProperty)
                }
                else {
                  setLoading(true)
                  const block = blocks.find(b => b?.id === e?.value)
                  await drawBlocksOnMap(undefined, [block])
                  setSelectedBlock(block)
                  setLoading(false)
                }
              }}
              isDisabled={blocks.length === 0 || loading}
            />
          </div>
        </div>
        {loading &&
          <MoonLoader />
        }

        <div style={{ height: "100%", width: "calc(100% - 75px)", position: 'absolute' }}>
          <SingleMap
            setBaseLayerToState={(bL) => {
              baseLayer = bL
            }}
            initCenter={[22.6708, 71.5724]}
            initZoom={22}
            maxZoom={23}
            bounds={[
              68.5076056,
              24.6535766,
              74.7917853,
              20.3513937,
            ]}
            setMap={(map) => {
              map.createPane("tool-tip-pane")
              map.getPane('tool-tip-pane').style.zIndex = 999
              mapInState = map;
              drawGujratBoundary()
            }}
          >

          </SingleMap>
          <div style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1', position: "absolute", right: "10px", bottom: '24px' }}
            onClick={switchBaseLayer}>
            <img style={{ width: '80px', height: '25px', borderRadius: '4px' }} src={activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
          </div>
        </div>
      </div>
      {!removeMapSwitch && selectedProperty.task_id && (showPotreeViewerButton || selectedProperty?.luma_ai_url) &&
        <div style={{
          display: "flex",
          position: "absolute",
          zIndex: "2",
          background: "#104c91",
          bottom: "6%",
          left: "50%",
          height: "35px",
          justifyContent: "space-around",
          border: "3px solid #104c91",
          borderRadius: "0px",
          transform: 'translateX(-25%)',
          gap: '1px'

        }}>
          <div onClick={() => {
            setShowThreed(false)
            setShowPotreeViewer(false)
          }}
            style={{
              width: "85px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: "12px",
              fontWeight: "500",
              color: !showThreed && !showPotreeViewer ? "white" : "#104c91",
              backgroundColor: showThreed || showPotreeViewer ? "white" : "#104c91",
              cursor: 'pointer',
            }}
          >
            Map
          </div>
          {selectedProperty.luma_ai_url &&
            <div onClick={() => {
              if (!showThreed) {
                setLoading(true)
                setShowThreed(true)
                setShowPotreeViewer(false)
              }
            }}
              style={{
                width: "85px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: "12px",
                fontWeight: "500",
                color: showThreed ? "white" : "#104c91",
                backgroundColor: !showThreed ? "white" : "#104c91",
                cursor: 'pointer',

              }}
            >
              3D View
            </div>
          }

          {showPotreeViewerButton && <Tippy
            visible={openThreeDMenu}
            onClickOutside={() => setOpenThreeDMenu(false)}
            interactive
            animation={false}
            className='tippy-menu'
            onShown={(instance) => {
              document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                instance.hide();
                setOpenThreeDMenu(false)
              })
            }}
            content={<div
              style={{ fontSize: "9px", width: "125px", borderRadius: '5px', backgroundColor: "white", fontWeight: "500", }}>

              {pointCloudAvailable && <div style={{ fontSize: '14px', margin: "10px auto 5px 15px", color: showPointCloud ? "#2989cf" : "" }} onClick={
                () => {
                  change3DMenu("pointcloud")
                  if (potree) {
                    potree.changeActiveView("pointCloudShow")
                  }
                }
              } >Points Cloud</div>}
              <hr style={{ margin: "0px" }} />
              {textureAvailable && <div style={{ fontSize: '14px', margin: "5px auto 10px 15px", color: showTexture ? "#2989cf" : "" }} onClick={
                () => {
                  change3DMenu('texture')
                  if (potree) {
                    potree.changeActiveView("ThreeDShow")
                  }
                }
              } >Texture View</div>}
              <hr style={{ margin: "0px" }} />
              {pointCloudAvailable && textureAvailable &&
                <div style={{ fontSize: '14px', margin: "5px auto 10px 15px", color: showBoth ? "#2989cf" : "" }} onClick={
                  () => {
                    change3DMenu('both')
                    if (potree) {
                      potree.changeActiveView("Both")
                    }
                  }
                } >Both</div>
              }
            </div>}>
            {
              <div onClick={() => {
                setShowPotreeViewer(prev => true)
                setShowThreed(false)
                setOpenThreeDMenu(true)
                if (!showBoth && !showTexture) {
                  setShowPointCloud(true)
                }
              }}
                style={{
                  width: "85px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: "12px",
                  fontWeight: "500",
                  color: showPotreeViewer ? "white" : "#104c91",
                  backgroundColor: !showPotreeViewer ? "white" : "#104c91",
                  cursor: 'pointer',
                }}
              >3D Model</div>
            }
          </Tippy>}

        </div>
      }
      <div style={{ position: 'absolute', right: openRightSlider ? 320 : 20, top: 50, transition: "right 0.5s" }}>
        {mapInState &&
          <MapDrawingTool
            volumeBoxDrawn={volumeBoxDrawn}
            map={mapInState}
            potree={potree}
            mapSwitched={showPotreeViewer}
            removeMapSwitchFunc={(val) => {
              setRemoveMapSwitch(val)
            }}
            toolChangeCB={(type) => {
              setOpenRightSlider(type === TOOLS.LAYER ? !openRightSlider : false)
              setSelectedMeasurement({})
              setLayerProperties({})
            }}
            onDrawingComplete={(name, coordinates, layer, color, type) => {
              measurements.push(layer)
              savePropertyMeasurement(name, coordinates, layer, color, type)
            }}
            fetchMeasurements={fetchMeasurements}
            onDrawnFetchMeasurement={(measurement, layer) => {
              measurements.push(layer)
              setSelectedProperty((prev) => ({
                ...prev,
                measurements: [
                  ...(prev?.measurements || []),
                  {
                    ...measurement,
                    layer: layer
                  }
                ]
              }))
            }}
            deleteMeasurement={(id) => {
              deteletMeasurementOfProperty(id || selectedPropertyRef.current.measurements[selectedPropertyRef.current.measurements.length - 1].id)
            }}
            onUpdateExistingMeasurement={(layer) => {
              measurements.push(layer)
              updateMeasurementOfProperty(layer)
            }}
            disableTools={(selectedProperty.id && selectedProperty.id !== 'All') ? {} : { "Polygon": true, "Polyline": true, "Marker": true, "Layer": true, "Compare": true }}
            getSelectedLayer={(layer) => {
              const selectedM = selectedPropertyRef.current.measurements.filter((m) => m.id === layer.id)[0]
              setSelectedMeasurement(selectedM)
            }}
            mainLayer={orthoLayer}
          />
        }
      </div>
      <RightSlider
        close={openRightSlider}
        handleClose={() => {
          setActiveTool(TOOLS.SELECTOR)
          setOpenRightSlider(false)
        }}
        propertiesLayers={selectedProperty.id && selectedProperty.id !== 'All' ? [selectedProperty] : properties}
        blockLayers={selectedBlock.id && selectedBlock.id !== 'All' ? [selectedBlock] : blocks}
        encLayers={selectedEnc.id && selectedEnc.id !== 'All' ? [selectedEnc] : encs}
        map={mapInState}
        onUpdateMeasurement={(layer, color, name, description) => {
          updateMeasurementOfProperty(layer, name, description, color)
        }}
        selectedMeasurement={selectedMeasurement}
        onDeleteMeasurement={() => {
          mapInState.removeLayer(selectedMeasurement.layer)
          setOpenRightSlider(false)
          setLayerProperties({})
          deteletMeasurementOfProperty(selectedMeasurement.id)
        }}
        onChangePropertyAsset={(propertyAsset, layerGrp) => {
          if (layerGrp)
            importedLayers.push(layerGrp)
          setSelectedProperty((prev) => ({
            ...prev,
            propertyAssets: prev.propertyAssets.map(asset => {
              if (asset.id == propertyAsset.id) return propertyAsset;
              else return asset
            })
          }))
        }}
      />
      {selectedProperty.id && selectedProperty.id !== 'All' && isCompareTool &&
        <OrthomosaicCompare
          property={selectedPropertyRef.current}
          map={mapInState}
          onLayerChange={(lLayer, rLayer, cLayer) => {
            leftLayer = lLayer
            rightLayer = rLayer
            controlLayer = cLayer
          }}
          mainLayer={orthoLayer}
        />
      }
    </DroneLabWrapper>
  )
}

export default PropertyDashboard