import React from 'react'
import DisplayField from './DisplayField'
import { setLayerStyle } from './LayerHolder'
import LayerColor from '../../Components/LayerColor'
import * as L from "leaflet"

const LayerPropertiesContainer = ({ properties, selectedMeasurement, onUpateLayerColor }) => {
    return (
        <div>
            <div style={{ fontSize: "12px", fontWeight: 400, padding: "12px", borderBottom: "1px solid rgb(218,220,224)" }}>
                Measurement info
            </div>
            <div style={{ borderBottom: "1px solid rgb(218,220,224)", padding: "12px" }}>
                {(selectedMeasurement && !(selectedMeasurement?.layer instanceof L.Marker)) &&
                    <div style={{
                        display: 'grid',
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        gridTemplateColumns: "1fr 1fr",
                        marginBottom: "3px",
                        fontWeight: 400,
                    }}>
                        <div style={{
                            fontSize: "12px",
                            color: "#666666"
                        }}>
                            {`Color : `}
                        </div>
                        <LayerColor
                            color={selectedMeasurement?.color}
                            onChangeColor={(color) => {
                                const updateLayer = setLayerStyle(selectedMeasurement?.layer, color)
                                onUpateLayerColor(updateLayer, color)
                            }}
                            style={{
                                borderRadius: "3px",
                                width: "20px",
                                height: "20px",
                            }}
                        />
                    </div>
                }
                {Object.entries(properties).map((p) => {
                    return <DisplayField label={p[0]} value={p[1]} labelFontSize={"12px"} />
                })}
            </div>
        </div>
    )
}

export default LayerPropertiesContainer