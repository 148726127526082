import React, { useEffect, useState } from 'react';
import * as API from '../../../api.js';
import { Popup, checkURLExist, getOverlayTilesLayer } from '../../../ReusableComponents/reusableFunctions.js';
import CompareTool from './CompareTool.js';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-side-by-side";
import closeButton from '../../../close-button.png';
import { useSetRecoilState } from 'recoil';
import { openCompareTool, propertyDashBoardLoading } from '../Recoil/atom.js';

const OrthomosaicCompare = ({ property, map, onLayerChange, mainLayer }) => {
    const setCompareTool = useSetRecoilState(openCompareTool)
    const setLoading = useSetRecoilState(propertyDashBoardLoading)
    const [orthomosaics, setOrthomosaics] = useState([]);
    const [leftSelectedLayer, setLeftSelectedLayer] = useState({});
    const [rightSelectedLayer, setRightSelectedLayer] = useState({});
    const [leftSelectedTask, setLeftSelectedTask] = useState(undefined);
    const [rightSelectedTask, setRightSelectedTask] = useState(undefined);
    const [leftLayerIndex, setLeftLayerIndex] = useState(0);
    const [rightLayerIndex, setRightLayerIndex] = useState(0);
    const [leftLayer, setLeftLayer] = useState(null);
    const [rightLayer, setRightLayer] = useState(null);
    const [compareControl, setCompareControl] = useState(null);

    const getTilesBounds = async (orthoImg) => {
        let tiffType = orthoImg?.image_type;
        let taskId = orthoImg?.task_id;
        let sas = encodeURIComponent(property?.sas_token);
        let blobContainer = property?.blob_container;

        try {
            setLoading(true)
            const check = await checkURLExist(`${orthoImg.image_url}?${property.sas_token}`);
            if (check) {
                const tilebounds = await API.getTilesBounds({ taskId, tiffType, sas, blobContainer });
                if (tilebounds?.tilesBounds) {
                    const orthoLayer = await getOverlayTilesLayer(tilebounds, orthoImg?.task_id, sas, property?.blob_container, tiffType);
                    setLoading(false)
                    return orthoLayer;
                } else {
                    setLoading(false)
                    console.log("not found bounds", tilebounds);
                }
            } else {
                setLoading(false)
                throw new Error("URL does not exist");
            }
        } catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e?.message || e);
            throw e;
        }
    };

    const getOrthoImages = async () => {
        try {
            setLoading(true)
            const res = await API.getOrthoImagesForCompare(property?.task_id);
            setOrthomosaics(res?.orthoImages || []);

            if (res?.orthoImages) {
                for (const key in res.orthoImages) {
                    const orthoImageArray = res.orthoImages[key];
                    for (let i = 0; i < orthoImageArray.length; i++) {
                        const image = orthoImageArray[i];
                        if (image?.task_id === property?.task_id) {
                            setLeftSelectedTask(orthoImageArray);
                            setRightSelectedTask(orthoImageArray);
                            const orthoLayer = await getTilesBounds(image);
                            setLeftLayerIndex((prev) => prev + 1);
                            setRightLayerIndex((prev) => prev + 1);
                            setLeftSelectedLayer(orthoLayer);
                            setRightSelectedLayer(orthoLayer);
                            setLoading(false)
                            return;
                        }
                    }
                }
            }
        } catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e.message || e);
        }
    };

    const handleNextLeft = async () => {
        if (leftLayerIndex < orthomosaics.length - 1) {
            const newIndex = leftLayerIndex + 1;
            setLeftLayerIndex(newIndex);
            setLeftSelectedTask(orthomosaics[newIndex]);
            const orthoLayer = await getTilesBounds(orthomosaics[newIndex][0]);
            setLeftSelectedLayer(orthoLayer);
        }
    };

    const handlePrevLeft = async () => {
        if (leftLayerIndex > 0) {
            const newIndex = leftLayerIndex - 1;
            setLeftLayerIndex(newIndex);
            setLeftSelectedTask(orthomosaics[newIndex]);
            const orthoLayer = await getTilesBounds(orthomosaics[newIndex][0]);
            setLeftSelectedLayer(orthoLayer);
        }
    };

    const handleNextRight = async () => {
        if (rightLayerIndex < orthomosaics.length - 1) {
            const newIndex = rightLayerIndex + 1;
            setRightLayerIndex(newIndex);
            setRightSelectedTask(orthomosaics[newIndex]);
            const orthoLayer = await getTilesBounds(orthomosaics[newIndex][0]);
            setRightSelectedLayer(orthoLayer);
        }
    };

    const handlePrevRight = async () => {
        if (rightLayerIndex > 0) {
            const newIndex = rightLayerIndex - 1;
            setRightLayerIndex(newIndex);
            setRightSelectedTask(orthomosaics[newIndex]);
            const orthoLayer = await getTilesBounds(orthomosaics[newIndex][0]);
            setRightSelectedLayer(orthoLayer);
        }
    };

    useEffect(() => {
        if (property?.id && property?.id !== 'All') {
            getOrthoImages();
        }
    }, [property?.id]);

    const removeLayerFromMap = () => {
        if (leftLayer) {
            map.removeLayer(leftLayer);
        }
        if (rightLayer) {
            map.removeLayer(rightLayer);
        }
        if (compareControl) {
            map.removeControl(compareControl);
        }
    }

    useEffect(() => {
        if (map && leftSelectedLayer?.url && rightSelectedLayer?.url) {
            setLoading(true)
            const newLeftLayer = L.tileLayer(leftSelectedLayer.url, leftSelectedLayer.options);
            const newRightLayer = L.tileLayer(rightSelectedLayer.url, rightSelectedLayer.options);

            removeLayerFromMap()

            newLeftLayer.addTo(map);
            newRightLayer.addTo(map);

            const newCompareControl = L.control.sideBySide(newLeftLayer, newRightLayer);
            newCompareControl.addTo(map);

            setLeftLayer(newLeftLayer);
            setRightLayer(newRightLayer);
            setCompareControl(newCompareControl);
            onLayerChange(newLeftLayer, newRightLayer, newCompareControl)
            setLoading(false)
        }
    }, [leftSelectedLayer, rightSelectedLayer]);

    return (
        <div style={{ position: 'absolute', top: "80px", left: "80px", width: "100%", height: "50px", display: 'flex', alignItems: "center", justifyContent: "space-around", pointerEvents: "none" }}>
            <CompareTool
                list={leftSelectedTask}
                onNext={handleNextLeft}
                onPrev={handlePrevLeft}
                disableNext={leftLayerIndex === orthomosaics.length - 1}
                disablePrev={leftLayerIndex === 0}
                handleDropdownChange={async (image) => {
                    if (image === "Base") {
                        if (leftLayer)
                            map.removeLayer(leftLayer);
                        if (mainLayer)
                            map.removeLayer(mainLayer)
                    }
                    else {
                        const orthoLayer = await getTilesBounds(image);
                        setLeftSelectedLayer(orthoLayer);
                    }
                }}
            />
            <CompareTool
                list={rightSelectedTask}
                onNext={handleNextRight}
                onPrev={handlePrevRight}
                disableNext={rightLayerIndex === orthomosaics.length - 1}
                disablePrev={rightLayerIndex === 0}
                handleDropdownChange={async (image) => {
                    if (image === "Base") {
                        if (rightLayer)
                            map.removeLayer(rightLayer);
                        if (mainLayer)
                            map.removeLayer(mainLayer)
                    }
                    else {
                        const orthoLayer = await getTilesBounds(image);
                        setRightSelectedLayer(orthoLayer);
                    }
                }}
            />
            <div style={{ cursor: 'pointer', color: 'white', right: '5px', borderRadius: '5px', backgroundColor: 'rgba(60, 60,60,0.6)', padding: '5px', position: "absolute", top: "7px", zIndex: "9", pointerEvents: "all", right: "100px" }}
                onClick={() => {
                    if (mainLayer)
                        mainLayer.addTo(map)
                    removeLayerFromMap()
                    setCompareTool(false)
                }}>
                <img src={closeButton} style={{ width: '25px', height: '23px' }} />
            </div>
        </div>
    );
};

export default OrthomosaicCompare;