import React from 'react'

const TabSwitcher = ({ tabs, switchCallback, selectedTab }) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {tabs.map((tab) => (
                <div onClick={() => switchCallback(tab.type)} style={{
                    borderBottom: selectedTab === tab.type && "3px solid #2989CF",
                    color: selectedTab === tab.type ? "#2989CF" : "#3C3C3C",
                    cursor: 'pointer',
                    width: "175px",
                    transition: "width 2s",
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "3px",
                    fontSize: "14px"
                }}>
                    {tab.title}
                </div>
            ))}
        </div>
    )
}

export default TabSwitcher