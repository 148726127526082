import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import * as L from 'leaflet'
import { drawingEndEvent, editEnableEvent, getArea, getDistance, mapClickedEvent, onUpdateLayer } from '../../../ReusableComponents/map/leafletMeasure'
import MarkerBlueIcon from '../../assets/icons/markerBlueWithBase.svg'
import MarkerBlueActive from '../../assets/icons/markerActive.png'
import pointerActive from '../../../../src/assets/toolbarIcons/pointerActive.png'
import lineActive from '../../../../src/assets/toolbarIcons/lineActive.png'
import lineInactive from '../../../../src/assets/toolbarIcons/lineInactive.png'
import polygonActive from '../../../../src/assets/toolbarIcons/polygonActive.png'
import polygonInactive from '../../../../src/assets/toolbarIcons/polygonInactive.png'
import markerActive from '../../../../src/assets/toolbarIcons/markerActive.png'
import markerInactive from '../../../../src/assets/toolbarIcons/marker.png'
import pointerInactive from '../../../../src/assets/toolbarIcons/pointerInactive.png'
import VolumeBoxIcon from '../../../../src/assets/Icons/VolumeBoxIcon.png'
import VolumeBoxIconActive from '../../../../src/assets/Icons/VolumeBoxIconActive.png'
import { TOOLS } from '../Constants/constants'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { activeDrawingTool, importedLayerPropeties, isRightSlider, layerPropeties, openCompareTool } from '../Recoil/atom'
import Tool from './Tool'
import showLayers from '../../../assets/toolbarIcons/layersActive.png'
import hideLayers from '../../../assets/toolbarIcons/layersInactive.png'
import { getLayerInfo } from '../../../ReusableComponents/reusableFunctions'
import compareInactiveIcon from '../../../assets/toolbarIcons/compareInactive.png'
import compareactiveIcon from '../../../assets/toolbarIcons/compareactiveIcon.png'


const topMostPane = 'top-most-pane'
let eventListerAdded = false
let currentSelectedMarker = null;
let currentHTMLId;

const MapDrawingTool = ({ map, potree, removeMapSwitchFunc, mapSwitched, volumeBoxDrawn, toolChangeCB, onDrawingComplete, fetchMeasurements, onDrawnFetchMeasurement, deleteMeasurement, onUpdateExistingMeasurement, disableTools, getSelectedLayer, mainLayer }) => {
    const [activeTool, setActiveTool] = useRecoilState(activeDrawingTool)
    const [isCompareTool, setIsCompareTool] = useRecoilState(openCompareTool)
    const setSelectedLayerPropeties = useSetRecoilState(layerPropeties)
    const setSelectedImportedLayerPropeties = useSetRecoilState(importedLayerPropeties)
    const [openRightSlider, setOpenRightSlider] = useRecoilState(isRightSlider)
    const [selectedLayer, setSelectedLayer] = useState(null)
    const [activeDrawingLayer, setActiveDrawingLayer] = useState(null)
    const [drawVolumeBox, setDrawVolumeBox] = useState(false)
    const [textureMode, setTextureMode] = useState(false)
    const activeToolRef = useRef(activeTool);

    const handleMarkerWrongClick = (e) => {
        currentHTMLId = e.target.id
    }

    useEffect(() => {
        if (volumeBoxDrawn) {
            toggleTool(TOOLS.SELECTOR)
        }
    }, [volumeBoxDrawn])

    useEffect(() => {
        (!isCompareTool) &&
            toggleTool(TOOLS.SELECTOR)
    }, [isCompareTool])

    useEffect(() => {
        if (potree?.ThreeDShow || potree?.bothShowPT) {
            setTextureMode(true)
            setDrawVolumeBox(false)
            toggleTool(TOOLS.SELECTOR)
        } else {
            setTextureMode(false)
            toggleTool(TOOLS.SELECTOR)
        }
    }, [potree?.ThreeDShow, potree?.bothShowPT])

    useEffect(() => {
        toggleTool(TOOLS.SELECTOR)
    }, [mapSwitched])

    useEffect(() => {
        if (activeTool == TOOLS.MARKER && !potree) {
            currentHTMLId = undefined
            window.addEventListener("click", handleMarkerWrongClick)
        }
        activeToolRef.current = activeTool;
        onChangeTool(activeTool)
        return () => window.removeEventListener('click', handleMarkerWrongClick)
    }, [activeTool]);

    useEffect(() => {
        map.createPane(topMostPane)
        const pane = map.getPane(topMostPane)
        pane.style.zIndex = 999
        addMapEventListeners()
    }, [])

    useEffect(() => {
        if (map && fetchMeasurements.length > 0) {
            fetchMeasurements.forEach((measurement) => {
                const layer = renderMeasurement(measurement);
                onDrawnFetchMeasurement(measurement, layer)
            });
        }
    }, [map, fetchMeasurements]);


    const renderMeasurement = (measurement) => {
        let layer;

        switch (measurement.type) {
            case TOOLS.POLYGON:
                layer = L.polygon(measurement.coordinates, {
                    color: measurement.color,
                    pane: 'top-most-pane',
                    fillOpacity: 0
                }).addTo(map);
                layer.type = TOOLS.POLYGON
                layer.id = measurement.id
                break;
            case TOOLS.POLYLINE:
                layer = L.polyline(measurement.coordinates, {
                    color: measurement.color,
                    pane: 'top-most-pane'
                }).addTo(map);
                layer.type = TOOLS.POLYLINE
                layer.id = measurement.id
                break;
            case TOOLS.MARKER:
                layer = L.marker(measurement.coordinates, {
                    pane: 'top-most-pane',
                    clickable: true,
                    draggable: true,
                    autoPanOnFocus: true,
                    icon: new L.Icon({ iconUrl: MarkerBlueIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
                }).addTo(map);
                layer.type = TOOLS.MARKER
                layer.id = measurement.id
                break;
            default:
                console.error('Unknown measurement type:', measurement.type);
                return;
        }

        addEventsToLayer(layer);
        return layer
    };

    const onChangeTool = (tool) => {
        disableEdit().then(async () => {
            switch (tool) {
                case TOOLS.POLYGON:
                    if (potree) {
                        potree.startDrawing("Polygon");
                    } else {
                        map.editTools.startPolygon()
                    }
                    toolChangeCB(TOOLS.POLYGON)
                    break;
                case TOOLS.POLYLINE:
                    if (potree) {
                        potree.startDrawing("Polyline");
                    } else {
                        map.editTools.startPolyline()
                    }
                    toolChangeCB(TOOLS.POLYLINE)
                    break;
                case TOOLS.MARKER:
                    if (potree) {
                        potree.startDrawing("Marker");
                    } else {
                        map.editTools.startMarker()
                    }
                    toolChangeCB(TOOLS.MARKER)
                    break;
                case TOOLS.VOLUME_BOX:
                    if (drawVolumeBox) {
                        await potree.startDrawing("VolumeBox");
                        removeMapSwitchFunc(true)
                    }
                    toolChangeCB(TOOLS.VOLUME_BOX)
                    break;
                case TOOLS.LAYER:
                    toolChangeCB(TOOLS.LAYER)
                    break;
                case TOOLS.COMPARE:
                    toolChangeCB(TOOLS.COMPARE)
                    break;

            }
        })
    }

    const getClickLayerInfo = (layer) => {
        const info = getLayerInfo(layer)
        setSelectedLayerPropeties(info)
        setSelectedImportedLayerPropeties({})
    }

    const selectLayer = (layer) => {
        layer.enableEdit()
        setSelectedLayer(layer)
        getSelectedLayer(layer)
        getClickLayerInfo(layer)
        setOpenRightSlider(true)
    }

    const deselectLayer = () => {
        if (selectedLayer) {
            selectedLayer.disableEdit()
            selectedLayer.closePopup()
            selectedLayer.unbindPopup()
            setSelectedLayer(undefined)
        }
    }

    const changeMarkerIcon = (marker, icon, size) => {
        const newIcon = L.icon({
            iconUrl: icon,
            iconSize: size,
            iconAnchor: [10, 28]
        });
        marker.setIcon(newIcon);
    }

    const addEventsToLayer = (layer) => {
        layer.on('click', () => {
            const menuContainer = document.getElementById("layer-menu")
            if (menuContainer) {
                ReactDOM.unmountComponentAtNode(menuContainer);
            }
            if (layer.type === TOOLS.MARKER) {
                if (currentSelectedMarker && currentSelectedMarker !== layer) {
                    changeMarkerIcon(currentSelectedMarker, MarkerBlueIcon, [20, 28]);
                }

                changeMarkerIcon(layer, MarkerBlueActive, [30, 30]);
                currentSelectedMarker = layer;
            }
            if (layer.type !== TOOLS.MARKER) {
                layer.bringToBack()
            }
            if (activeToolRef.current === TOOLS.SELECTOR) {
                map.editTools.startPolygon()
                map.editTools.stopDrawing()
                selectLayer(layer)
            }
        })
        function highlight_on() {
            layer.setStyle({ fillOpacity: 0.2 })
        }
        function highlight_off() {
            layer.setStyle({ fillOpacity: 0 })
        }
        if (layer.type == TOOLS.POLYGON) {
            layer.on("mouseover", highlight_on)
            layer.on("mouseout", highlight_off)
        }

        layer.on('contextmenu', () => {
            if (selectedLayer?._leaflet_id === layer._leaflet_id) setSelectedLayer(undefined)
            layer.remove()
        })
    }

    const onDrawingEnd = (e) => {
        const layer = e.layer
        layer.options.pane = topMostPane
        if (layer.type === TOOLS.POLYGON) {
            if (activeToolRef.current === TOOLS.POLYGON) map.editTools.startPolygon();
            const coordinates = layer.getLatLngs()[0]
            if (coordinates.length > 2) {
                const polygon = L.polygon(layer.getLatLngs(), {
                    color: "#2989cf",
                    pane: topMostPane,
                    fillOpacity: 0
                }).addTo(map)
                polygon.type = layer.type
                polygon.dragging = false
                map.removeLayer(layer)
                addEventsToLayer(polygon)
                onDrawingComplete(TOOLS.POLYGON, coordinates, polygon, "#2989cf", TOOLS.POLYGON)
            }
        }
        if (layer.type === TOOLS.POLYLINE) {
            if (activeToolRef.current === TOOLS.POLYLINE) map.editTools.startPolyline();
            const coordinates = layer.getLatLngs()
            if (coordinates.length > 1) {
                const polyline = L.polyline(layer.getLatLngs(), {
                    color: "#2989cf",
                    pane: topMostPane
                }).addTo(map)
                polyline.type = layer.type
                polyline.dragging = false
                map.removeLayer(layer)
                addEventsToLayer(polyline)
                onDrawingComplete(TOOLS.POLYLINE, coordinates, polyline, "#2989cf", TOOLS.POLYLINE)
            }
        }
        if (layer.type === TOOLS.MARKER) {
            if (currentHTMLId == TOOLS.POLYGON || currentHTMLId == TOOLS.POLYLINE || currentHTMLId == TOOLS.SELECTOR) return;
            if (activeToolRef.current === TOOLS.MARKER) map.editTools.startMarker();
            const coordinates = layer.getLatLng()
            var markerOptions = {
                pane: topMostPane,
                clickable: true,
                draggable: true,
                autoPanOnFocus: true,
                icon: new L.Icon({ iconUrl: MarkerBlueIcon, iconSize: [20, 28], iconAnchor: [10, 28] }),
            }
            let Marker = new L.Marker(coordinates, markerOptions).addTo(map)
            Marker.type = TOOLS.MARKER
            Marker.type = layer.type
            map.removeLayer(layer)
            addEventsToLayer(Marker)
            onDrawingComplete(TOOLS.MARKER, coordinates, Marker, "#2989cf", TOOLS.MARKER)
        }
    }

    const addMapEventListeners = () => {
        if (!eventListerAdded) {
            eventListerAdded = true
            editEnableEvent(map, (leafletLayer) => {
                if (activeToolRef.current !== TOOLS.SELECTOR) {
                    leafletLayer.type = activeToolRef.current
                    setActiveDrawingLayer(leafletLayer)
                }
            });

            onUpdateLayer(map, (layer) => {
                onUpdateExistingMeasurement(layer)
            })

            drawingEndEvent(map, onDrawingEnd)

            mapClickedEvent(map, (mapClicked) => {
            })
        }
    }

    const disableEdit = async () => {
        if (potree) {
            potree.setVolumeBoxDrawn(false)
            await potree.clearPartiallyVolume()
            await potree.stopDrawing();
            potree.indicateMarkerInScene(null);
        }
        if (activeDrawingLayer) {
            activeDrawingLayer.disableEdit()
            activeDrawingLayer.closePopup()
            activeDrawingLayer.unbindPopup()
            setActiveDrawingLayer(null)
        }
        deselectLayer()
        return
    }

    const toggleTool = (type) => {
        if (currentSelectedMarker) {
            changeMarkerIcon(currentSelectedMarker, MarkerBlueIcon, [20, 28])
            currentSelectedMarker = null
        }
        if (type === activeTool) {
            setActiveTool(TOOLS.SELECTOR)
            toolChangeCB(type)
        }
        else if (type !== activeTool) {
            if (type === TOOLS.LAYER && openRightSlider) {
                setActiveTool(TOOLS.SELECTOR)
                setOpenRightSlider(false)
            }
            else
                setActiveTool(type)
        }
        else if (type === TOOLS.SELECTOR) {
            disableEdit()
        }
        else if (type === TOOLS.LAYER) {
            activeTool === type ? setActiveTool(TOOLS.SELECTOR) : setActiveTool(type)
            toolChangeCB(type)
        }
    }

    return <div className='slide' style={{
        fontSize: "9px",
        borderRadius: '7px',
        right: '0px',
        top: "90px",
        position: 'absolute',
        color: "white",
        background: "white",
        fontWeight: "500"
    }}>
        <div className='slide' style={{ fontSize: "9px", zIndex: '1', borderRadius: '7px', backgroundColor: 'white', textAlign: 'center', width: '32px', height: "", padding: '10px', color: "white", fontWeight: "500", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
            <Tool
                id={TOOLS.SELECTOR}
                toolType={TOOLS.SELECTOR}
                activeTool={activeTool}
                activeImg={pointerActive}
                inactiveImg={pointerInactive}
                toggleTool={(toolType) => {
                    deselectLayer()
                    toggleTool(toolType)
                }}
                tooltipText={"Select Pointer"}
            />
            {potree &&
                <Tool
                    id={TOOLS.VOLUME_BOX}
                    toolType={TOOLS.VOLUME_BOX}
                    activeTool={activeTool}
                    activeImg={VolumeBoxIconActive}
                    inactiveImg={VolumeBoxIcon}
                    toggleTool={(toolType) => {
                        if (!textureMode) {
                            if (drawVolumeBox) {
                                toggleTool(TOOLS.SELECTOR)
                                potree.exitPointCloudSlicing()
                                potree.setShowVolumeBoxOptions(false)
                                removeMapSwitchFunc(false)
                            } else {
                                toggleTool(toolType)
                            }
                            setDrawVolumeBox(prev => !prev)
                        }
                    }}
                    tooltipText={"Volume Box"}
                />}
            <Tool
                id={TOOLS.POLYGON}
                toolType={TOOLS.POLYGON}
                activeTool={activeTool}
                activeImg={polygonActive}
                inactiveImg={polygonInactive}
                toggleTool={(toolType) => {
                    toggleTool(toolType)
                }}
                tooltipText={"Polygon"}
                isDisable={disableTools[TOOLS.POLYGON]}
            />
            <Tool
                id={TOOLS.POLYLINE}
                toolType={TOOLS.POLYLINE}
                activeTool={activeTool}
                activeImg={lineActive}
                inactiveImg={lineInactive}
                toggleTool={(toolType) => {
                    toggleTool(toolType)
                }}
                tooltipText={"Polyline"}
                isDisable={disableTools[TOOLS.POLYLINE]}
            />
            <Tool
                id={TOOLS.MARKER}
                toolType={TOOLS.MARKER}
                activeTool={activeTool}
                activeImg={markerActive}
                inactiveImg={markerInactive}
                toggleTool={(toolType) => {
                    toggleTool(toolType)
                }}
                tooltipText={"Marker"}
                isDisable={disableTools[TOOLS.MARKER]}
            />
            <Tool
                id={TOOLS.LAYER}
                toolType={TOOLS.LAYER}
                activeTool={activeTool}
                activeImg={showLayers}
                inactiveImg={hideLayers}
                toggleTool={(toolType) => {
                    toggleTool(toolType)
                }}
                tooltipText={"Layers"}
            />
            <Tool
                id={TOOLS.COMPARE}
                toolType={TOOLS.COMPARE}
                activeTool={activeTool}
                activeImg={compareactiveIcon}
                inactiveImg={compareInactiveIcon}
                toggleTool={(toolType) => {
                    if (isCompareTool) {
                        if (mainLayer)
                            mainLayer.addTo(map)
                        setIsCompareTool(false)
                        toggleTool(TOOLS.SELECTOR)
                    }
                    else {
                        setIsCompareTool(true)
                        toggleTool(toolType)
                    }
                }}
                tooltipText={"Compare"}
                isDisable={disableTools[TOOLS.COMPARE]}
            />
        </div>
    </div>
}

export default MapDrawingTool;