import React, { Component } from "react";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import { FormWrapper } from "./FormTopBar";
class accountActivetionmsg extends Component {
  state = {
    username: "",
    password: "",
    showPassword: false
  }

  componentWillMount() {
    document.title = "DroneLab-Account_Activeted";
  }

  render() {
    return (
      <div className="authPage">
        <FormWrapper>
          <div className="greet login-greet">Sign Up to DroneLab</div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div className="content-text" style={{ paddingBottom: '20px', fontSize: "16px", lineHeight: '32px', color: 'b#3c3c3c' }}>
              Your account has been successfully activated.
            </div>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
            <Link to={{
              pathname: "/login",
            }}><button style={{ cursor: 'pointer' }} className="loginButton">LOGIN</button></Link>
            <div class="spacer" style={{ height: '4.9vh' }}> </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

export default accountActivetionmsg
