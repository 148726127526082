import React, { useState, useEffect } from "react";
import * as API from '../../../api'
import AppConstants from "../../../AppConstants";
import { getLimitedName, getOriginalImageSetName } from "../../../ReusableComponents/reusableFunctions";
import dateFormat from 'dateformat';
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
import ShareAssetsPopup from "../../../ReusableComponents/ShareAssetsPopup";
import Plus from '../../../assets/Icons/plus.png';
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import TrainingDetectionResult from "./TrainingDetectionResult";
import { PICSTORK_FEATURES } from "../../../Teams";

const TaskDetails = (props) => {
    const [accColor, setAccColor] = useState("grey");
    const [detector, setDetector] = useState(null)
    const [noOfDetection, setNoOfDetection] = useState(0);
    const [taskStartPopup, setTaskStartPopup] = useState(props.openStartTaskPopup)
    const taskStatusColor = props.task.status == AppConstants.TASK.STATUS.COMPLETED ? '#009900' : props.task.status == AppConstants.TASK.STATUS.PENDING ? '#2989cf' : props.task.status == AppConstants.TASK.STATUS.FAILED ? '#F02020' : '#ffcc00'
    const getDetectorDetails = () => {
        API.getDetectorDetails(props.task.task_id).then((result) => {
            if (result.detector)
                setDetector(result.detector)
        })
    }

    const [shareTask, setShareTask] = useState(false);
    const [legendDetails, setLegendDetails] = useState({ trainingAreaColor: "", testingAreaColor: "", accuracyAreaColor: "", totalAnnotations: 0, classLabels: [], detectorAccuracy: 0 })
    useEffect(() => {
        if (props.task.tasktype == 1)
            getDetectorDetails()
    }, [taskStartPopup])

    useEffect(() => {
        API.getDetections(props.task.task_id, []).then(
            (data) => {
                props.taskImageType(data.image_data[0].file_name)
                let totalDetections = 0;
                data.ret.forEach((item) => {
                    for (const key in item.result.detections) {
                        if (item.result.detections.hasOwnProperty(key)) {
                            totalDetections += item.result.detections[key].count;
                        }
                    }
                })
                setNoOfDetection(totalDetections)
            }
        )
    }, [])
    useEffect(() => {
        const acc = parseInt(("" + legendDetails?.detectorAccuracy).split("%")[0])
        if (isNaN(acc)) setAccColor("grey")
        if (acc <= 25) setAccColor("red")
        else if (acc <= 50) setAccColor("orange")
        else if (acc <= 100) setAccColor("green")
        else return
    }, [legendDetails.detectorAccuracy])
    return <>
        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
            open={taskStartPopup}
            icon={'COMPLETED'}
            title={"Started task"}
            height={190}
            msg={'Your task is pending and added to the execution queue. It will be processed as soon as resource will be available.'}
            close={() => {
                setTaskStartPopup(false)
                props.closeStartTaskPopup()
            }}
        />
        {/* --------------------------------------------------------------- */}
        <div style={{ display: 'flex', margin: "1%", width: '98%', height: '32px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="services-content-title" style={{ display: "flex", width: '20%' }}>
                Task Details
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                <div style={{ display: 'flex', alignItems: "baseline" }}>
                    {/* {props.task.status === AppConstants.TASK.STATUS.PENDING && props.task.tasktype !== 4 ?
                        <ButtonWithIcon
                            isDisabled={!props.permissions?.[PICSTORK_FEATURES.TASKS].CREATE}
                            text="Cancel"
                            onClick={() => { props.cancelTask() }}
                        />
                        :
                        <></>
                    } */}
                    {((props.task.tasktype == 2 || props.task.tasktype == 4)) ?
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {props.task.sharedBy && props.task.sharedBy.isShared ?
                                <></> :
                                <ButtonWithIcon
                                    text="Share Task"
                                    isDisabled={!(props.permissions?.[PICSTORK_FEATURES.TASKS].SHARE && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                                    onClick={() => props.task.status != AppConstants.TASK.STATUS.COMPLETED ? false : setShareTask(props.task)}
                                />
                            }
                            <ButtonWithIcon
                                text="Summary Report"
                                isDisabled={!(props.permissions?.[PICSTORK_FEATURES.REPORTS].READ && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                                onClick={() => { if (props.task.status == AppConstants.TASK.STATUS.COMPLETED) props.changeView(1) }}
                            />

                            <ButtonWithIcon
                                text="Detail Report"
                                isDisabled={!(props.permissions?.[PICSTORK_FEATURES.REPORTS].READ && props.task.status === AppConstants.TASK.STATUS.COMPLETED)}
                                onClick={() => {
                                    if (props.task.status == AppConstants.TASK.STATUS.COMPLETED)
                                        props.changeView(2)
                                }}
                            />
                        </div>
                        : detector && props.task.tasktype == 1 ?
                            <div>
                                <div
                                    style={{ textAlign: "end", marginRight: "5px" }}
                                >
                                    <ButtonWithIcon
                                        icon={Plus}
                                        text="Detection Task"
                                        isDisabled={!props.permissions?.[PICSTORK_FEATURES.TASKS].CREATE}
                                        linkData={
                                            {
                                                pathname: "/picstork/detectionTask",
                                                state: {
                                                    models: [detector],
                                                    user: props.user,
                                                    project:{id:props?.task?.project_id,project_name:props?.task?.project_name},
                                                    fromTrainingTask: { isTiff: props.task.linked_map ? props.task.linked_map : undefined },
                                                },
                                            }
                                        }
                                    />
                                </div>

                            </div>
                            : <></>}

                </div>
                {detector && props.task.tasktype == 1 && <div style={{ padding: "5px 10px 0 0", fontSize: '10px', }}>
                    You can create detection task using this detector.
                </div>}
            </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", height: props.task.linked_map ? "93%" : "90%", width: props.task.linked_map ? "" : "98%", background: "#fff", boxShadow: "0px 0px 10px rgba(0,0,0,0.25)" }}>
            <div style={{ minWidth: "500px", width: props.task && props.task.tasktype === 1 && props.task.linked_map ? "46%" : "100%", padding: props.task.linked_map ? "20px" : "0% 4% 2%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: props.task && props.task.tasktype === 1 && props.task.linked_map ? "58px" : "" }}>

                <div style={{ width: "98%", height: '100%', background: '#fff', justifyContent: "center", alignItems: "center", margin: '1%' }}>

                    {shareTask ? <ShareAssetsPopup
                        shareInviteModal={shareTask ? true : false}
                        user={props.user}
                        defaultAccessLevel={'READ'}
                        task={shareTask}
                        linkedMap={props.task.linked_map}
                        setShareAssetsModal={setShareTask} /> : <></>}

                    <div>
                        {/* Detector details */}
                        {detector && props.task.tasktype == 1 ? <>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ display: "flex", width: "100%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                    <div style={{ margin: '8px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                        <div className='task-key' >Detector Name</div>
                                        <div className='task-value'>
                                            {getLimitedName(detector.display_name, 30)}
                                        </div>
                                    </div>
                                    <div style={{ margin: '8px', display: "flex", flexDirection: "column", width: "105px", textAlign: "left" }}>
                                        <div className='task-key'>Created On</div>
                                        <div className='task-value'>{dateFormat(detector.date_created, "dd mmm yyyy")}</div>
                                    </div>
                                </div>
                            </div>
                        </> :
                            <></>}

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <div style={{ height: "285px" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                <div style={{ margin: '8px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='task-key' >Task Name</div>

                                    <div className='task-value'>{getLimitedName(props.task.task_name, 30)}
                                        {(props.task.sharedBy && props.task.sharedBy.isShared) ?
                                            <span style={{ color: '#2989cf', fontSize: '10px' }}>
                                                ( sharedBy, {props.task.sharedBy.sharedBy.first_name + " " + props.task.sharedBy.sharedBy.last_name})
                                            </span>
                                            : <></>}
                                    </div>

                                </div>
                                <div style={{ margin: '8px 0px', display: "flex", flexDirection: "column", width: "105px", textAlign: "left" }}>
                                    <div className='task-key'>Created Date</div>
                                    <div className='task-value'>{dateFormat(props.task.date_created, "dd mmm yyyy")}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                <div style={{ margin: '8px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='task-key'>Imageset</div>
                                    <div className='task-value' >{getLimitedName(props.task.collection_name, 30)}</div>
                                </div>
                                <div style={{ margin: '8px 0px', display: "flex", flexDirection: "column", width: "105px", textAlign: "left" }}>
                                    <div className='task-key'>No. of Images</div>
                                    <div className='task-value'>{props.task.images}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                <div style={{ margin: '8px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='task-key' >{props.task.tasktype == 1 ? "Training type" : 'Detection type'}</div>
                                    <div className='task-value'>{props.task.tasktype == 1 ? props.task.training_type : props.task.task_purpose}</div>
                                </div>
                                {props.task.status == AppConstants.TASK.STATUS.COMPLETED || props.task.status == AppConstants.TASK.STATUS.FAILED ?
                                    <>
                                        <div style={{ margin: '8px 0px', display: "flex", flexDirection: "column", width: "105px", textAlign: "left" }}>
                                            <div className='task-key'>{`${props.task.status} at`}</div>
                                            <div className='task-value'>{dateFormat(props.task.status_update_timestamp, 'dd mmm yyyy')}</div>
                                        </div>
                                    </> : <div style={{ width: "122px", height: "100%" }}></div>}
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                <div style={{ margin: '8px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='task-key'>Status</div>
                                    <div className="aerocapture-detection-type-list" style={{ width: 'fit-content', color: 'white', background: taskStatusColor, fontWeight: 600, margin: "" }}>{props.task.status}</div>
                                </div>

                                {props.task.tasktype == 1 ? <div style={{ margin: '8px', display: "flex", flexDirection: "column", width: "105px", textAlign: "left" }}>
                                    <div className='task-key'>Accuracy</div>
                                    <div className='task-value'>{props.task.accuracy}</div>
                                </div> : <></>}
                                {props.task.tasktype == 1 ? <></>
                                    : <div style={{ display: "flex", flexDirection: "column", marginTop: legendDetails.detectorAccuracy === "N/A" ? "16px" : "13px", width: "105px", textAlign: "left", }}>
                                        <div style={{ fontSize: "11px", color: "#666666" }}>No of Detections</div>
                                        <div style={{ fontSize: "13px" }}>{noOfDetection}</div>
                                    </div>}
                            </div>
                            <div style={{ display: props.task && props.task.tasktype === 1 && props.task.linked_map ? "flex" : "", width: "100%", height: "60%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                {props.task.tasktype == 1 || props.task.tasktype == 4 ?
                                    <div style={{ display: "flex", width: "50%", height: "100%" }}>
                                        <div style={{ overflow: "", margin: '8px 0px', width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                                            {props.task.tasktype === 2 ? <></> : <div className='task-key'>Class labels</div>}
                                            <div style={{
                                                fontFamily: "Poppins",
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                lineHeight: "20px",
                                                color: "#3c3c3c",
                                                padding: "0px 0px 4px 0px",
                                                overflowY: "scroll",
                                                height: "50%",
                                                minHeight: "100px",
                                                maxHeight: "200px"
                                            }}>
                                                {props.task.type.map((obj, i) => {
                                                    const color = legendDetails.classLabels.find(region => region.type === obj)?.color;
                                                    return (
                                                        <button
                                                            className="aerocapture-detection-type-list"
                                                        // style={{ fontSize: "12px", margin: "2px 4px", backgroundColor: color, color: "white" }}
                                                        >
                                                            {obj.length >= 20 ? obj.substring(0, 21) : obj}
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> : <>{props.task.models ? <div>
                                        <div style={{ marginTop: "1%" }} className='task-key'>Detectors</div>
                                        <div style={{ display: "flex", width: '100%', justifyContent: 'center', overflow: 'auto' }}>
                                            <div style={{ width: "98%", marginTop: '1%', overflow: "auto", height: "26vh" }}>{
                                                props.task.models && props.task.models.map((model, m) => {
                                                    return <>
                                                        <div className="itemKey" style={{ padding: "2px 0px", fontSize: '12px', }}>{model.display_name}</div>
                                                        <div className="rpas-model-info-primary-details-value1" style={{ marginTop: '-4px' }}>{
                                                            // detectionType.toString()
                                                            model.objects.map((type) => {
                                                                return <button className="aerocapture-detection-type-list" style={{ padding: "0px 8px" }}>{type}</button>
                                                            })
                                                        }</div>
                                                        <div style={{ width: "98%", margin: "7px 5px 5px 5px", borderTop: "1px solid grey" }} />
                                                    </>
                                                })
                                            }</div>
                                        </div>
                                    </div> : <></>}
                                    </>
                                }
                                {props.task && props.task.tasktype === 1 && props.task.linked_map && !props.task.models &&
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: legendDetails.detectorAccuracy === "N/A" ? "16px" : "13px", width: "105px", textAlign: "left", marginLeft: "14px", marginRight: "10px" }}>
                                        <div style={{ fontSize: "11px", marginBottom: "8px", color: "#666666" }}>Detector Accuracy </div>
                                        <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                                    </div>}
                            </div>
                        </div>
                        {(props.task && props.task.tasktype === 1 && props.task.linked_map) && (props.task.models) &&
                            <div style={{ display: "flex", flexDirection: "column", marginTop: legendDetails.detectorAccuracy === "N/A" ? "16px" : "13px", width: "105px", textAlign: "left", marginLeft: "14px", marginRight: "10px" }}>
                                <div style={{ fontSize: "11px", marginBottom: "8px", color: "#666666" }}>Detector Accuracy </div>
                                <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                            </div>}
                        {/* <div>
                            <div style={{ display: props.task && props.task.tasktype === 1 && props.task.linked_map ? "flex" : "", width: "100%", height: "60%", justifyContent: props.task && props.task.tasktype === 1 && props.task.linked_map ? "space-around" : "" }}>
                                {props.task.tasktype == 1 || props.task.tasktype == 4 ?
                                    <div style={{ display: "flex", width: "60%", height: "100%" }}>
                                        <div style={{ overflow: "", margin: '8px 0px', width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                                            {props.task.tasktype === 2 ? <></> : <div className='task-key'>Class labels</div>}
                                            <div style={{
                                                fontFamily: "Poppins",
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                lineHeight: "20px",
                                                color: "#3c3c3c",
                                                padding: "0px 0px 4px 0px",
                                                overflowY: "scroll",
                                                height: "50%",
                                                minHeight: "100px",
                                                maxHeight: "200px"
                                            }}>
                                                {props.task.type.map((obj, i) => {
                                                    console.log("obj", obj)
                                                    const color = legendDetails.classLabels.find(region => region.type === obj)?.color;
                                                    console.log("color", color)
                                                    return (
                                                        <button
                                                            className="aerocapture-detection-type-list"
                                                        // style={{ fontSize: "12px", margin: "2px 4px", backgroundColor: color, color: "white" }}
                                                        >
                                                            {obj.length >= 20 ? obj.substring(0, 21) : obj}
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> : <>{props.task.models ? <div>
                                        <div style={{ marginTop: "1%" }} className='task-key'>Detectors</div>
                                        <div style={{ display: "flex", width: '100%', justifyContent: 'center', overflow: 'auto' }}>
                                            <div style={{ width: "98%", marginTop: '1%', overflow: "auto", height: "26vh" }}>{
                                                props.task.models && props.task.models.map((model, m) => {
                                                    return <>
                                                        <div className="itemKey" style={{ padding: "2px 0px", fontSize: '12px', }}>{model.display_name}</div>
                                                        <div className="rpas-model-info-primary-details-value1" style={{ marginTop: '-4px' }}>{
                                                            // detectionType.toString()
                                                            model.objects.map((type) => {
                                                                return <button className="aerocapture-detection-type-list" style={{ padding: "0px 8px" }}>{type}</button>
                                                            })
                                                        }</div>
                                                        <div style={{ width: "98%", margin: "7px 5px 5px 5px", borderTop: "1px solid grey" }} />
                                                    </>
                                                })
                                            }</div>
                                        </div>
                                    </div> : <></>}
                                    </>
                                }
                                {props.task && props.task.tasktype === 1 && props.task.linked_map && !props.task.models &&
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: legendDetails.detectorAccuracy === "N/A" ? "16px" : "13px", width: "105px", textAlign: "left", marginLeft: "14px", marginRight: "10px" }}>
                                        <div style={{ fontSize: "11px", marginBottom: "8px", color: "#666666" }}>Detector Accuracy </div>
                                        <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                                    </div>}
                            </div>
                            {(props.task && props.task.tasktype === 1 && props.task.linked_map) && (props.task.models) &&
                                <div style={{ display: "flex", flexDirection: "column", marginTop: legendDetails.detectorAccuracy === "N/A" ? "16px" : "13px", width: "105px", textAlign: "left", marginLeft: "14px", marginRight: "10px" }}>
                                    <div style={{ fontSize: "11px", marginBottom: "8px", color: "#666666" }}>Detector Accuracy </div>
                                    <div style={{ fontSize: "23px", fontWeight: "600", color: accColor }}>{legendDetails.detectorAccuracy}</div>
                                </div>}
                        </div> */}
                    </div>

                </div>

            </div>
            {props.task && props.task.tasktype === 1 && props.task.linked_map &&
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: "12px", padding: "10px", display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Total Annotations : {legendDetails.totalAnnotations}</div>
                        {legendDetails.trainingAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Training Area <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.trainingAreaColor, marginLeft: "10px" }}></div></div>}
                        {legendDetails.testingAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Testing Area  <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.testingAreaColor, marginLeft: "10px" }}></div></div>}
                        {legendDetails.accuracyAreaColor && <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>Accuracy Area  <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: legendDetails.accuracyAreaColor, marginLeft: "10px" }}></div></div>}

                    </div>

                    {props.task && props.task.tasktype === 1 && props.task.linked_map && <TrainingDetectionResult user={props.user} permissions={props.permissions} task={props.task} setLegendDetails={setLegendDetails} />}
                </div>
            }
        </div >
    </>
}

export default TaskDetails; 