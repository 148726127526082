import React, { Component } from "react";
import * as API from "../../api.js";
import Loader from '../../icons/loader.svg';
import { addUserAnalytic, getLimitedName, updateOutTime, getLocalStorageValue, getOriginalImageSetName } from "../../ReusableComponents/reusableFunctions";
import Noimageset from '../../assets/Images/Noimageset.png';
import ReactJoyride, { STATUS } from 'react-joyride';
import AppConstants, { BLOB_URL, TASK } from "../../AppConstants.js";
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup";
import ButtonWithIcon from "../../ReusableComponents/ButtonWithIcon.js";
import { PICSTORK_FEATURES } from "../../Teams/index.js";
let timer = null
export default class TaskImageAnnotation extends Component {
    state = {
        user: {},
        type: "",
        key: " ",
        currentpage: 1,
        userperpage: 10,
        taskName: "",
        imageType: "",
        images: [],
        counts: "",
        loading: true,
        classes: [],
        outId: "",
        annotatedImages: [],
        unAnnotatedImages: [],
        allImages: [],
        imagesToShow: [],
        sidebarMode: this.props.data
            ? this.props.data.sidebarMode
            : "shrunk",
        annotationSet: {},
        imageCount: 0,
        unAnnCount: 0,
        annCount: 0,
        isAnnotable: false,
        allimagesShow: false,
        startTour: false,
        guideSteps: [
            {
                disableBeacon: true,
                target: '#segmented-button',
                content: (<div style={AppConstants.TEXTSTYLING}><p>Browse and select images for annotation</p></div>),
                title: (<div style={AppConstants.TITLESTYLING}>Browse Images</div>),
                placement: 'left',
                styles: {
                    spotlight: { border: "2px solid #05426f", borderRadius: '5px' },
                    options: {
                        width: 250,
                    },
                },
            },],

        activityPopup: undefined,
    };

    getImages = () => {
        if (this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].READ) {
            API.getAnnotationSetImages(
                this.state.annotationSet.image_collection_id,
                this.state.annotationSet.id,
                0,
                0,
                this.props.task.task_id,
            )
                .then(async (result) => {
                    let annotated = result.images.filter(
                        (data) => data.annotation_status == true
                    );
                    let unannotated = result.images.filter(
                        (data) => data.annotation_status == false
                    );
                    if (annotated.length > 0 && this.state.annotationDoneOrNot)
                        this.props.updateTaskState(3)
                    await this.props.annotationDoneOrNot(this.props.task.linked_map ? this.state.isAnnotable : annotated.length > 0, result.annotatedCount, result.unannotatedCount)
                    this.setState(
                        (state) => ({
                            ...state,
                            classes: result.classes || this.state.classes,
                            showTotalCount: result.showTotalCount,
                            allImages: result.images,
                            validTiff: (result.images[0].file_name.toLowerCase()).includes("tiff") || (result.images[0].file_name.toLowerCase()).includes("tif"), // #TODO check this from backend 
                            counts: result.totalCount,
                            annotatedImages: annotated,
                            imageType: this.state.imageType === "" ? undefined : this.state.imageType,
                            imageCount: this.state.imageType === "" ? result.images.length : (this.state.imageType === true ? result.annotatedCount : (this.state.imageType === false ? result.unannotatedCount : result.images.length)),
                            imagesToShow: this.state.imageType === "" ? result.images : (this.state.imageType === true ? annotated : (this.state.imageType === false ? unannotated : result.images)),
                            unAnnotatedImages: unannotated,
                            annCount: result.annotatedCount,
                            unAnnCount: result.unannotatedCount,
                            loading: false,
                        }),
                        () => {
                            setTimeout(() => {
                                if ((getLocalStorageValue('imageSetTour1') > 1 && getLocalStorageValue('annotationTour') == null) || (getLocalStorageValue('imageSetTour1') > 1 && getLocalStorageValue('mapAnnotationTour') == null)) {
                                    this.setState({ startTour: true })
                                } else {
                                    this.setState({ startTour: false })
                                }
                            }, AppConstants.TRIGGER_DELAY);
                        }
                    );

                })
                .catch((e) => {
                    this.setState((state) => ({
                        ...state,
                        loading: false
                    }))
                });
        } else {
            this.setState({
                allImages: [],
                validTiff: false,
                counts: 0,
                annotatedImages: [],
                imageCount: 0,
                imagesToShow: [],
                unAnnotatedImages: 0,
                annCount: 0,
                unAnnCount: 0,
                loading: false,
            })
        }
    };

    getAnnotation = (image) => {
        API.getAnnotation(image.file_name, image.collection_id, (this.props.task.annotation_set || this.state.annotationSet).id).then(data => {
            if (data.data && data.data.annotationData) {
                this.setState({ isAnnotable: (((data.data?.annotationData.trainingAreaAnnotations || []).length + (data.data?.annotationData.testingAreaAnnotations || []).length + (data.data.annotationData?.accuracyAreaAnnotations || []).length) >= 20) && data.data?.annotationData.trainingAreaAnnotations.length >= 3 && data.data?.annotationData.testingAreaAnnotations.length >= 3 && data.data.annotationData?.accuracyAreaAnnotations.length >= 3 }, () => {
                    this.getImages();
                })
            } else {
                this.getImages()
            }
        })

    }

    componentWillUnmount() {
        // this.props.setSelectedImageForAnnotation(undefined)
        this.props.annotationDoneOrNot(false, null, null)
        if (timer) clearInterval(timer)
        updateOutTime(this.state.outId)
    }

    // this will check is image resizing done or not, this is depends on task status, we are checking this fromn task status.
    // we are setting task status preparing
    checkImageResizing = () => {
        timer = setInterval(async () => {
            if (this.props.task.status === TASK.STATUS.PREPARING) {
                await this.props.updateTask(true)
                this.initData()
            } else {
                this.initData()
                clearInterval(timer)
            }
        }, 1000);
    }

    exportData = () => {
        let annotationObjs = {
            "setName": this.state.annotationSet.annotation_set_name,
            "annotationSetId": this.state.annotationSet.id,
            "collectionId": this.state.annotationSet.image_collection_id,
        }

        API.exportAnnotationData(annotationObjs)
            .then(
                (res) => {
                    res.blob().then(blob => window.URL.createObjectURL(blob))
                        .then(uril => {
                            var link = document.createElement("a");
                            link.href = uril;
                            link.download = `${this.state.annotationSet.annotation_set_name}.zip`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            this.setState((state) => ({
                                ...state,
                                savingLoader: false,
                                activityPopup: false,
                            }));
                        })
                }
            ).catch((e) => {
                this.setState({
                    activityPopup: {
                        popupIcon: "ERROR",
                        popupTitle: "Error !",
                        popupMessage: "Something Went Wrong !"
                    }
                })
            })
    }

    initData = async () => {
        const data = await API.getImageCollectionImages(this.props.task.image_collection_id, " ", 1, 0)
        if (data.images.length > 0) {
            if (this.props.task.linked_map) {
                this.getAnnotation(data.images[0])
            } else {
                this.getImages();
            }
        }
    }
    componentWillMount() {

        addUserAnalytic("/picstork/taskImageAnnotation")
            .then(id => {
                this.setState((state) => ({
                    outId: id
                }))
            })

        if (this.props.data) {
            let { user, type, project, annotationSet, plan } = this.props.data;
            this.setState(
                (state) => ({
                    ...state,
                    loading: true,
                    user,
                    type,
                    project,
                    plan,
                    annotationSet,
                    classes: annotationSet.classes && annotationSet.classes.length > 0 ? annotationSet.classes.split(",") : [],
                }),
                () => {
                    this.checkImageResizing()
                }
            );
        } else {
            API.getUserType().then(
                ([blah, type, user]) => {
                    this.setState(
                        (state) => ({
                            ...state,
                            user,
                            type,
                        }),
                        () => {
                            this.checkImageResizing()
                        }
                    );
                },
                (e) => {
                    window.location.replace("/login");
                }
            );
        }
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('annotationTour', 1)
            localStorage.setItem('mapAnnotationTour', 1)
            this.setState({ startTour: false })
        }
    };

    render() {
        const {
            imageType,
        } = this.state;

        return (
            <>

                {this.state.activityPopup ? <ActivityPopup
                    open={this.state.activityPopup}
                    icon={this.state.activityPopup.popupIcon}
                    title={this.state.activityPopup.popupTitle}
                    msg={this.state.activityPopup.popupMessage}
                    close={() => this.setState({ activityPopup: undefined })}

                /> : <></>}

                {this.state.loading ? (
                    <div
                        style={{ height: "100%", width: "100%", display: "flex" }}
                    >
                        <img
                            src={Loader}
                            style={{
                                height: "30vh",
                                width: "100%",
                                margin: "auto"
                            }}
                        />
                    </div>
                ) : (
                    <div className="main-content" style={{ paddingTop: "5px" }} >
                        <div style={{ width: "100%", display: 'flex', height: 'auto', justifyContent: "space-between", alignItems: 'baseline' }}>
                            <div style={{ display: "flex" }}>
                                {/* <div className="vl"></div> */}
                                <div style={{ padding: '5px 40px 5px 0px' }}>
                                    <div style={{ fontSize: "12px", lineHeight: '22px' }} className="itemKey">
                                        Imageset
                                    </div>
                                    <div style={{ fontSize: "14px", lineHeight: '22px' }} className="itemValue">
                                        {getLimitedName(getOriginalImageSetName(this.props.task.collection_name), 30)}
                                    </div>
                                </div>
                                {/* <div className="vl"></div> */}
                                <div style={{ padding: '5px 40px 5px 0px' }}>
                                    <div className="itemKey" style={{ fontSize: '12px', lineHeight: '22px' }}>
                                        Class labels
                                    </div>
                                    {this.props.classes && this.props.classes.length > 0 ?
                                        <div className="step3-class-labels">
                                            {
                                                this.state.classes.map((cls, i) => {
                                                    return  <span style={{fontSize: "14px", lineHeight: '22px'}}>
                                                    {`${cls.name}`}
                                                    {this.state.showTotalCount && <span style={{fontWeight:"500",margin:"0px 5px",fontSize:"12px"}}>{`(${cls.totalCount})`}</span>}
                                                   <span style={{margin:"0px 5px",fontWeight:500}}>
                                                     {`${i== this.state.classes.length-1?"":", "}`}
                                                    </span>
                                                </span>
                                                })
                                            }
                                        </div>
                                        : '-'}
                                </div>
                            </div>
                            <ButtonWithIcon
                                text="Export Data"
                                isDisabled={!this.props.permissions?.[PICSTORK_FEATURES.ANNOTATIONS].DOWNLOAD || this.state.annCount === 0}
                                onClick={() => { if (this.state.annCount !== 0) this.exportData() }}
                            />
                        </div>
                        {
                            this.state.startTour ?
                                <ReactJoyride
                                    continuous={true}
                                    run={true}
                                    hideBackButton={true}
                                    hideCloseButton={true}
                                    showSkipButton={true}
                                    steps={this.state.guideSteps}
                                    disableOverlayClose={true}
                                    floaterProps={AppConstants.FLOATER_PROPS}
                                    locale={AppConstants.LOCALE_BUTTONS}
                                    callback={(e) => this.handleJoyrideCallback(e)}
                                    styles={{
                                        buttonNext: AppConstants.NEXT_BUTTON,
                                        buttonSkip: AppConstants.SKIP_BUTTON,
                                        spotlight: AppConstants.SPOTLIGHT,
                                        options: AppConstants.MAIN_OPTIONS
                                    }}
                                />
                                : <></>}

                        <div id='segmented-button' style={{ marginTop: "1%" }}>
                            <div
                                style={{
                                    padding: "1% 5% 1% 0%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center"
                                }}
                            >
                                <div className="imageViewButton1"
                                    style={{
                                        display: "flex",
                                        height: '24px',
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "7px 0px 0px 7px",
                                            borderRight: '1px solid #2989cf',
                                        }}
                                        onClick={() => {
                                            this.setState(
                                                (state) => ({
                                                    ...state,
                                                    allimagesShow: true,
                                                    imageType: undefined,
                                                    imageCount: this.state.counts,
                                                    imagesToShow: state.allImages
                                                })
                                            );
                                        }}
                                        className={
                                            this.state.imageType === undefined
                                                ? "annotation-set-active-buttons"
                                                : "annotation-set-inactive-buttons"
                                        }
                                    >
                                        All <span style={{ fontSize: '11px', marginLeft: '3px' }}>({this.state.counts})</span>
                                    </div>
                                    <div
                                        style={{
                                            borderRight: '1px solid #2989cf',
                                            borderLeft: '1px solid #2989cf',
                                        }}
                                        onClick={() => {
                                            let images = this.state.allImages.filter(image => image.annotation_status == true)
                                            this.setState(
                                                (state) => ({
                                                    ...state,
                                                    imageType: true,
                                                    allimagesShow: false,
                                                    imageCount: this.state.annCount,
                                                    imagesToShow: images
                                                })
                                            );
                                        }}
                                        className={
                                            imageType === true
                                                ? "annotation-set-active-buttons"
                                                : "annotation-set-inactive-buttons"
                                        }
                                    >
                                        Annotated <span style={{ fontSize: '11px', marginLeft: '3px' }}>({this.state.annCount})</span>
                                    </div>
                                    <div
                                        style={{ borderRadius: "0px 7px 7px 0px", borderLeft: '1px solid #2989cf' }}
                                        onClick={() => {
                                            let images = this.state.allImages.filter(image => image.annotation_status == false)
                                            this.setState(
                                                (state) => ({
                                                    ...state,
                                                    imageType: false,
                                                    allimagesShow: false,
                                                    imageCount: this.state.unAnnCount,
                                                    imagesToShow: images
                                                })
                                            );
                                        }}
                                        className={
                                            imageType === false
                                                ? "annotation-set-active-buttons"
                                                : "annotation-set-inactive-buttons"
                                        }
                                    >
                                        Non-annotated <span style={{ fontSize: '11px', marginLeft: '3px' }}>({this.state.unAnnCount})</span>
                                    </div>
                                </div>
                            </div>
                            {this.props.task.tasktype === 1 ?
                                <div>
                                    {this.state.annCount < 20 && <div style={{ margin: "5px 2px", fontSize: '11px', color: "#2989cf", fontWeight: 600 }}>{!this.props.task?.linked_map ? "Annotate at least 20 images to set up." : "Add at least 20 annotations inside marked areas of the orthomosaic image, with each area containing at least 3 annotations."}</div>}
                                    {this.props.task.status === TASK.STATUS.PREPARING && !this.props.task?.linked_map ? <>
                                        <div style={{ margin: "5px 2px", fontSize: '12px', color: "red", fontWeight: 600 }}>{"Resizing is in progress. Until then, you can start annotating images."}</div>
                                    </> : <></>}
                                </div> : <></>}
                            <div
                                style={{ background: "none", height: '66vh', overflow: 'auto' }}
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        sidebarMode: "shrunk",
                                    }));
                                }}
                            >
                                {this.state.imagesToShow.length ? (
                                    <>
                                        <div
                                            className="aerocapture-cards-tray-projects"
                                            style={{
                                                columnGap: "15px",
                                                rowGap: "26px",
                                                gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px))",
                                                width: "100%",
                                            }}
                                        >
                                            {
                                                this.state.imagesToShow.map((image, key) => {
                                                    return (
                                                        <div
                                                            onClick={() => this.props.setSelectedImageForAnnotation({ image: { ...image, validTiff: this.state.validTiff }, imagesToShow: this.state.imagesToShow })}
                                                            key={key}
                                                            className="customer-cards-dashboard card"
                                                            style={
                                                                {
                                                                    opacity: 1,
                                                                    pointerEvents: "all",
                                                                    boxShadow: "0px 6px 10px rgb(0 0 0 / 30%)",
                                                                    height: "105px"
                                                                }}
                                                        >
                                                            <div
                                                                className="card-header customer-cards-dashboard-logo project-card-header"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {
                                                                    <img
                                                                        id="ProjectImageFarm"
                                                                        style={{ objectFit: "cover", height: "104px" }}
                                                                        loading="lazy"
                                                                        src={this.state.validTiff ?
                                                                            `${AppConstants.tilesServer}/getTifPng/${this.state.annotationSet.image_collection_id}/${image.file_name}/thumb.png?blobContainer=${this.props.permissions.container}&sas=${encodeURIComponent(this.props.permissions.st)}` :
                                                                            `${BLOB_URL}/${this.props.permissions.container}/FlightImages/collection-${this.state.annotationSet.image_collection_id}/thumb-${image.file_name}?${this.props.permissions.st}`}
                                                                    />
                                                                }
                                                            </div>
                                                            <div
                                                                style={{ paddingTop: "3px !important" }}
                                                                className="card-body change-color customer-cards-dashboard-text project-card-body"
                                                            >
                                                                <div style={{ display: "flex" }}>
                                                                    <span style={{ fontSize: "11px" }}>
                                                                        {getLimitedName(image.file_name, 14)}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <span style={{ fontSize: "10px" }}>
                                                                        {image.file_size}MB
                                                                    </span>
                                                                    <span style={{ fontSize: "10px" }}>
                                                                        {image.resolution != '0.00' ? image.resolution + 'MP' : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <div className="rmp-dashboard-empty-data-message" style={{ height: '58vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                        <img style={{ height: '150px', width: '150px' }} src={Noimageset} />
                                        <p className="content-text" style={{ marginTop: '10px' }}>No images available.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}