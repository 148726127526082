import React from "react";
import ghbLogo from './assets/ghblogo.png'

export const FormWrapper = ({ onSubmit, children, error, childrenWidth }) => {
    return <form style={{ fontFamily: "Arial" }} className="cardLogin login-card" onSubmit={onSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: "12px" }}>
            <div style={{ backgroundColor: "#104C91", padding: "20px 0", borderRadius: "5px 5px 0 0", width: '100%' }}>
                <img src={ghbLogo} />
            </div>
            <div style={{ width: childrenWidth || '70%', textAlign: 'left', margin: '45px 0 25px 0' }}>
                {children}

                <div style={{ marginTop: "15px", textAlign: "center" }} className="login-error">{error || <></>}</div>
            </div>

        </div>

    </form>
}

export const InputBox = (props) => {
    return <div style={{ padding: '10px 0', fontSize: "14px", color: "#3c3c3c", width: '100%', display: 'flex', flexDirection: 'column', gap: '3px' }}>
        <div style={{ color: props.selected ? '#104C91' : '' }}>{props.name}</div>
        <input className="loginSignupInput" style={{ width: '100%', marginTop: "1px", border: props.selected ? '1px solid #104C91' : 'none' }} {...props} />
    </div>
}
