import React, { useEffect, useState } from 'react';
import PlusIcon from '../../../icons/PlusIcon.png'
import UserIcon from '../../../icons/User.png'
import MinusIcon from '../../../icons/Minus.png'
import { getOwnerTeamId, getRoles } from '../../../Teams';
import { Popup } from '../../../ReusableComponents/reusableFunctions';
import { addPropertyAccess, removePropertyAccess } from '../../../api';

const TeamMemebersContainer = ({ ContainerTitle, NoMembersMsg, teamMembers, onAddAccess, onRemoveAccess, selectedProperty }) => {
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const [ownerTeamId, setOwnerTeamId] = useState(undefined)

    const getRoleList = async () => {
        try {
            setLoading(true)
            const rolesList = await getRoles()
            const filteredRoles = rolesList?.filter((rL) => rL.title === "VIEWER")
            setRoles(filteredRoles)
            setLoading(false)
        } catch (error) {
            console.log(error)
            Popup.alert('ERROR', error || error?.message)
        }
    }

    const getTeamIdOfOwner = async () => {
        const teamId = await getOwnerTeamId()
        setOwnerTeamId(teamId)
    }

    const checkMemberIsActive = (member) => {
        if (member?.status !== 'Active') {
            throw new Error("The selected member is inactive.");
        }
    }

    const givePropertyAccess = async (member) => {
        try {
            setLoading(true)
            checkMemberIsActive(member)
            const { accessedProperty } = await addPropertyAccess(selectedProperty?.id, ownerTeamId, roles[0]?.id, member?.id)
            onAddAccess(accessedProperty)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            Popup.alert('ERROR', error || error?.errorMessage)
        }
    }

    const removeAccessToProperty = async (accessId) => {
        try {
            setLoading(true)
            const { removedAccess } = await removePropertyAccess(accessId)
            onRemoveAccess(removedAccess)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            Popup.alert('ERROR', error || error?.errorMessage)
        }
    }

    useEffect(() => {
        getRoleList()
        getTeamIdOfOwner()
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "baseline", width: "100%" }}>
            <div style={{ display: "flex", fontSize: '14px' }}>
                {ContainerTitle}
            </div>
            {teamMembers?.length === 0 ?
                <div className="empty-data-message" style={{ width: '340px', margin: "10px 0px" }}>
                    {NoMembersMsg}
                </div> :
                teamMembers.map((member) => {
                    return <div className="pilot" style={{ display: "flex", padding: "5px", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", width: "60%" }}>
                            <img src={UserIcon} style={{ width: "26px", marginRight: "10%" }} />
                            <div style={{ fontSize: "13px", color: "#666666" }}>
                                {
                                    member.userFullName.length > 14 ?
                                        `${member.userFullName.substring(0, 13)}...` :
                                        `${member.userFullName}`
                                }
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", width: "40%", justifyContent: "flex-end" }}>
                            <select
                                value={member?.roleId}
                                onChange={(e) => { console.log('eeee:', e.target.value) }}
                                disabled={true}
                                style={{
                                    width: "60%",
                                    backgroundColor: "#F5F5F5",
                                    borderRadius: "3%",
                                    color: "#666666",
                                    padding: "0 4%",
                                    fontSize: '13px',
                                    borderRadius: '5px'
                                }}>
                                {roles?.map(role => <option value={role?.id}>{role?.title}</option>)}
                            </select>
                            {member?.hasAccess ?
                                <img src={MinusIcon} style={{ cursor: "pointer", width: "25px", marginLeft: "10%" }} onClick={() => {
                                    removeAccessToProperty(member?.accessId)
                                }} /> :
                                <img src={PlusIcon} style={{ cursor: "pointer", width: "25px", marginLeft: "10%" }} onClick={() => {
                                    givePropertyAccess(member)
                                }} />
                            }
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default TeamMemebersContainer