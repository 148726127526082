import React, { Component } from 'react';
import dashboardIcon from '../../assets/sideBarIcons/dashboard.png';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import projectActive from '../../assets/sideBarIcons/Project.png';
import * as API from '../../api.js';
import service from "../../sidebaricon/Services.png";
import NoPlan from '../../assets/Images/Noplan.png';
import Loader from '../../icons/loader.svg';
import { Link } from 'react-router-dom';
import AeroMeghLogo from "../../userAuth/assets/aeromeghLogo.png";
import Menu from '../assets/icons/menu.svg';
import ViewImagesOnMap from '../../ReusableComponents/inspectOnMap';
import CloseBtn from '../../assets/Icons/Close.png';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal'
import { CreateProjectPlanModal } from '../../ReusableComponents/CreateProjectPlanModal';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from '../../redux'
import Help from "../../sidebaricon/Help.png"
import MapIcon from '../../sidebaricon/Plan.png'
import ListIcon from '../../icons/listBlue.png'
import { getMaxPageIndex } from '../../ReusableComponents/reusableFunctions.js';
import { DeleteComponentTable } from '../../ReusableComponents/Menu'
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions'
import { SortComponent } from '../../ReusableComponents/SortComponent';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Plus from "../../icons/addBlue.png";
import { ActiveRole, DRONENAKSHA_FEATURES, getActiveTeam, getFeaturesPermissions, setActiveRoleForProject } from '../../Teams';
import Planner from '../../Teams/Planner';
import AppConstants, { SERVICES } from '../../AppConstants';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={Menu} alt="I am alt" />
    </a>
));

class AeroCaptureProject extends Component {

    state = {
        sharedBy: null,
        isOwner: false,
        activeTeam: undefined,
        user: {},
        type: "",
        key: " ",
        progress: 0,
        showDownloadPopup: false,
        loader: false,
        plans: [],
        showBackIcon: true,
        cancel: false,
        userperpage: 8,
        currentpage: 1,
        counts: 0,
        loading: true,
        userdisplay: [],
        mapView: false,
        listView: true,
        plansonMap: [],
        planstat: {},
        selectMapPlan: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        outId: "",
        planStatModal: false,
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    isUserOwnerOfCollection = () => {
        const { id } = this.state.project
        API.isUserProjectOwner(id)
            .then((result) => {
                this.setState({
                    isOwner: result.isShared ? false : true,
                    isAllowToDownload: result.access_level == "DOWNLOAD" ? true : false,
                    sharedBy: result.sharedBy
                })
            })
            .catch((err) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Something went wrong while getting owner information",
                    showProcessPopup: true,
                    loading: false,
                    isOwner: false,
                    isAllowToDownload: false
                }))
            })
    }

    getPlansOnMap = () => {
        if (this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ) API.getPlansOnMap(this.state.project.project_name, this.props.location.state.project.id).then((projectplans) => {
            console.log("PLANS DATA ON MAP", projectplans.plans)
            this.setState((state) => ({
                ...state,
                plansonMap: projectplans?.plans || [],
                counts: projectplans?.plans?.length || 0,
            }))
        }, (e) => {
            console.log(e);
            alert(e)
            // this.setState((state) => ({
            //     deletingTaskText: e,
            //     createIcon: fail
            // }))
        })
        else this.setState((state) => ({
            ...state,
            plansonMap: []
        }))

    }

    openPlanstat = (idx) => {
        this.setState((state) => ({
            ...state,
            loadingData: true,
            selectMapPlan: this.state.plansonMap[idx],
            planStatModal: true,
        }), () => {
            if (this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ) API.getPlanOnMapDetails(this.state.plansonMap[idx].plan_id).then((plandata) => {
                console.log("PLANS DATA ON MAP", plandata.statistics)
                this.setState((state) => ({
                    ...state,
                    planstat: plandata.statistics
                }), () => {
                    this.setState((state) => ({
                        ...state,
                        loadingData: false
                    }))
                    this.state.plans.map((plan) => {
                        if (plan.id == this.state.plansonMap[idx].plan_id) {
                            this.setState((state) => ({
                                ...state,
                                planFromMap: plan
                            }))
                        } else {
                            console.log("plans not match with selected plan from map")
                        }
                    })
                })
            }, (e) => {
                console.log(e);
                alert(e)
                // this.setState((state) => ({
                //     deletingTaskText: e,
                //     createIcon: fail
                // }))
            })
            else {
                this.setState((state) => ({
                    ...state,
                    planstat: {},
                }))
            }
        })
    }

    getPlans = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, project } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        

        let state = this.props.location.state
        this.setState(state => ({
            ...state,
            // logsDisplay: [],
            counts: this.state.plansonMap?.length || 0,
            // loading: true
        }))
        if (this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ)
            API.getPlans(project.project_name, key == "" ? " " : key, userperpage, firstpage, 3, this.props.location.state.project.id).then(async (data) => {
                if (data.plans && data.plans.length == 0) {
                    this.setState((state) => ({
                        ...state,
                        plans: [],
                        loading: false
                    }))
                } else {
                    this.setState((state) => ({
                        ...state,
                        plans: data.plans,
                        loading: false,
                        counts: this.state.plansonMap.length || 0,
                    }), () => {
                        if (this.state.sortType === 'A-Z') {
                            this.sortByName()
                        } else if (this.state.sortType === 'Z-A') {
                            this.reverseByName()
                        } else if (this.state.sortType === 'First Modified') {
                            this.sortByDate()
                        } else if (this.state.sortType === 'Last Modified') {
                            this.reverseByDate()
                        }
                    })
                }
            }, (e) => {
                console.log(e);
            })
        else this.setState((state) => ({
            ...state,
            plans: [],
            loading: false
        }))
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getPlans();
            });
        } else {
            console.log("Reached first page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getPlans();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getPlans();
            });
        } else {
            console.log("Reached next page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getPlans();
            });
        }
        else if (this.state.currentpage <= Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getPlans();
            });
        }
        else {
            console.log("Reached last page")
        }
    }


    deletePlan = () => {
        this.setState((state) => ({
            itemName: "Plan",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))

        if (this.state.permissions[DRONENAKSHA_FEATURES.PLANS].DELETE) API.deleteAerocapturePlan(this.state.selectedPlanId, AppConstants.SERVICES.DRONENAKSHA).then((data) => {
            console.log(data);
            this.setState((state) => ({
                itemName: "Plan",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                plansonMap: this.state.plansonMap.filter(plan => plan.plan_id !== this.state.selectedPlanId)
            }), () => {
                this.getPlans()
            })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                itemName: "Project",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
        else {

        }
    }

    changepage(num) {
        let maxPage = getMaxPageIndex(this.state.counts, 8)
        if (!(isNaN(num) || num > maxPage) && num != 0) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.abs(num),
            }), () => this.getPlans());
        }
        else if (num != 0) {

        }
    }

    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getPlans());
        console.log(key)
    }



    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.plans.sort((a, b) => a.plan_name.localeCompare(b.plan_name))
        this.setState((state) => ({
            ...state,
            plans: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.plans.sort((a, b) => b.plan_name.localeCompare(a.plan_name))
        this.setState((state) => ({
            ...state,
            plans: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.plans.sort((a, b) => a.date_and_time.localeCompare(b.date_and_time))
        this.setState((state) => ({
            ...state,
            plans: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.plans.sort((a, b) => b.date_and_time.localeCompare(a.date_and_time))
        this.setState((state) => ({
            ...state,
            plans: sort,
        }));
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.state.notificationId != this.props.location.state.notificationId) {
            getFeaturesPermissions([DRONENAKSHA_FEATURES.PLANS])
                .then(permissions => {
                    let { user, type, project } = this.props.location.state;
                    this.setState(state => ({
                        ...state,
                        permissions,
                        user,
                        type,
                        project
                    }), () => {
                        this.getPlansOnMap();
                        this.getPlans();
                    })
                })
        }
    }

    componentWillMount() {
        document.title = "DroneNaksha - Plans";

        setActiveRoleForProject(this.props.location.state.project.id).then(role => {
            getFeaturesPermissions([DRONENAKSHA_FEATURES.PLANS])
                .then(permissions => {
                    this.setState(state => ({ ...state, permissions }), () => {

                        addUserAnalytic(this.props.location.pathname)
                            .then(id => {
                                console.log("ID : ", id)
                                this.setState((state) => ({
                                    outId: id
                                }))
                            })
                        if (this.props.location.state) {
                            let { user, type, project } = this.props.location.state;
                            console.log(this.props);
                            this.setState((state) => ({
                                ...state,
                                user,
                                type,
                                permissions,
                                project
                            }), () => {
                                this.isUserOwnerOfCollection()
                                API.getUserSubbedServices().then(
                                    (response) => {
                                        let services = response[0];
                                        console.log(services);
                                        if (services.length == 1 && services[0].title == 'AeroStream') {
                                            this.setState((state) => ({
                                                ...state,
                                                showBackIcon: false
                                            }))
                                        } else {
                                            this.setState((state) => ({
                                                ...state,
                                                showBackIcon: true
                                            }), () => {
                                                this.getPlansOnMap();
                                                this.getPlans();
                                            })
                                        }
                                    }
                                )
                            })
                        } else {
                            API.getUserType().then(([blah, type, user]) => {
                                this.setState((state) => ({
                                    ...state,
                                    user,
                                    type
                                }), () => {
                                    this.getPlans();
                                    this.getPlansOnMap();
                                })
                            }, (e) => {
                                window.location.replace("/login");
                            })
                        }
                    })
                })
        })
    }

    render() {
        const { userdisplay, currentpage, userperpage } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        let { user, type, sidebarMode, showBackIcon, plans, project } = this.state
        return (
            user.username ? <div className="wrapper">
                {/* <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
                <div style={{ display: 'flex' }}>
                    <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
                        <div style={{ display: "flex", height: "40px" }}>
                            <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                                <img
                                    src={AeroMeghLogo}
                                    style={{ width: "65px", height: "20px", marginTop: "10px" }}
                                />
                            </div>
                        </div>
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={service}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Services"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>


                            <div className={"nonSelectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/dashboard",
                                            state: {
                                                type,
                                                user,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={dashboardIcon}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                // style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"DroneNaksha"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"selectBorder"} >
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/dronenaksha/project",
                                            state: {
                                                type,
                                                user,
                                                project: this.props.location.state.project,
                                            },
                                        }}
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={projectActive}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                                style={{ color: "#2989CF" }}
                                                onClick={this.changeColor}
                                            >
                                                {"Project"}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                            <div className={"nonSelectBorder"} >
                                <li>
                                    <div
                                        className="onhoverext"
                                        onClick={
                                            () => {
                                                window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                                            }
                                        }
                                    >
                                        <div
                                            className="sidecontent"
                                            id="sidecontent"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="sidebar-icon">
                                                <img
                                                    className="sidebar-icon"
                                                    src={Help}
                                                    alt="serviceIcon"
                                                />
                                            </div>
                                            <div
                                                name="service"
                                                className={"sidebaropt"}
                                            >
                                                {"Help"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="right-content" style={{ display: "flex", flexDirection: "column", height: "100%" }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    {/* --------------Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deletePlan()}
                    />
                    {/* --------------------------------------------------------------- */}
                    <AnimatedModal
                        isOpen={this.state.planStatModal}
                        // onRequestClose={() => {
                        //   this.setState((state) => ({
                        //     ...state,
                        //     manageAccessModal: false,
                        //     manageAccessList: [],
                        //     notInAccessList: [],
                        //     givePermissionToUser: "",
                        //     givePermission: {},
                        //     activePlan: "",
                        //     searchUserKeyword: ""
                        //   }))
                        // }}
                        height="360px"
                        width="300px"
                    >
                        <img
                            id="close-popup"
                            src={CloseBtn} style={{
                                width: "auto",
                                height: "20px",
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                cursor: "pointer"

                            }} onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    planStatModal: false,
                                    regions: [],
                                    scale: 0,
                                }));

                            }}></img>
                        {this.state.loadingData ? <div style={{ width: '290px', height: '418px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}> */}
                            <img src={Loader} style={{ marginLeft: '100px' }} />
                            {/* </div>  */}
                        </div>
                            : <div>
                                <div className="services-content-title1 long-text-fix" style={{ textAlign: 'center', margin: '15px 0px' }}>{this.state.selectMapPlan.plan_name}</div>
                                <div style={{ backgroundColor: '#EAEAEA', borderRadius: '0px 0px 15px 15px' }}>
                                    <div style={{ padding: '5px 15px' }}>
                                        <div className='content-title2'>
                                            <span className='content-title'>Imagesets</span>
                                            <span className='content-title1'>{this.state.planstat.image_sets}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Images</span>
                                            <span className='content-title1'>{this.state.planstat.images}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Tasks</span>
                                            <span className='content-title1'>{this.state.planstat.tasks}</span>
                                        </div>
                                        {/* <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Detections</span>
                                            <span className='content-title1'>{this.state.planstat.detections_count}</span>
                                        </div> */}
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Orthomosaics</span>
                                            <span className='content-title1'>{this.state.planstat.orthomosaic_maps}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                        <div className='content-title2'>
                                            <span className='content-title'>Videos</span>
                                            <span className='content-title1'>{this.state.planstat.videos}</span>
                                        </div>
                                        <hr style={{ height: '2px', margin: '0px' }} />
                                    </div> <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                                        <Link
                                            style={{ color: "#707683", }}
                                            to={{
                                                pathname: '/dronenaksha/plan',
                                                state: {
                                                    user,
                                                    type,
                                                    sidebarMode: this.state.sidebarMode,
                                                    plan: this.state.planFromMap,
                                                    project
                                                },
                                            }}
                                        > <button className="add-user-form-submit-button btn-ok" >
                                                View
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </div>}
                    </AnimatedModal>

                    <div className="top-bar">
                        <div className="breadCrumbs" >
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/dronenaksha/projects",
                                    state: {
                                        type,
                                        user,
                                    },
                                    style: {
                                        color: "black",
                                        fontSize: "12px"
                                    }
                                },
                                {
                                    displayName: this.state.project ? this.state.project.project_name : "Plans"
                                }
                            ]} />
                            {this.state.sharedBy == null || !(this.state.sharedBy) ? <></> : <span className='dn-shared-by'>- Shared by {this.state.sharedBy.first_name} {this.state.sharedBy.last_name}</span>}

                            <ActiveRole />
                        </div>

                    </div>
                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div className="main-content" style={{ background: 'none', display: "flex", gap: "20px", height: "100%" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }}>
                                <CreateProjectPlanModal
                                    serviceName={AppConstants.SERVICES.DRONENAKSHA}
                                    user={this.state.user}
                                    type={this.state.type}
                                    createProjectPlanModal={this.state.createProjectPlanModal}
                                    closeCreateProjectPlanModal={(newItem) => {
                                        if (newItem && newItem.includes("PLAN")) {
                                            this.getPlans();
                                            this.getPlansOnMap();
                                        }
                                        this.setState({ createProjectPlanModal: false })
                                    }}
                                    project={this.state.project}
                                    createType={"plan"}
                                />
                                <div style={{ display: "flex", margin: '10px 0px 3px 0px' }}>
                                    <div className="services-content-title">{"Plans"}
                                        <span className='data-count'>({this.state.counts})</span>
                                    </div>
                                    <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto", display: 'flex', alignItems: "center", }}>
                                        <div style={{ filter: this.state.permissions[DRONENAKSHA_FEATURES.PLANS].CREATE ? "" : "grayscale(100%)" }}>

                                            {this.state.isOwner ?
                                                <ButtonWithIcon
                                                    text="New Plan" icon={Plus} margin="0px 15px 0px 0px"
                                                    onClick={() => {
                                                        this.setState({ createProjectPlanModal: true })
                                                    }}
                                                    isDisabled={!this.state.permissions[DRONENAKSHA_FEATURES.PLANS].CREATE}
                                                />
                                                : <></>
                                            }
                                        </div>

                                        <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', filter: this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ ? "" : "grayscale(100%)", opacity: this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ ? "1" : "0.5" }} >
                                            <div
                                                onClick={() => {
                                                    this.state.permissions[DRONENAKSHA_FEATURES.PLANS].READ &&
                                                        this.setState((state) => ({
                                                            ...state,
                                                            listView: !state.listView,
                                                        }))
                                                }}>
                                                <img src={this.state.listView ? MapIcon : ListIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', marginRight: "15px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!this.state.listView ? <div>
                                    <ViewImagesOnMap
                                        service={AppConstants.SERVICES.DRONENAKSHA}
                                        height={'82vh'}
                                        width={'100%'}
                                        inspect={this.state.plansonMap}
                                        icon={'plan'}
                                        onClickOnPoint={idx => this.openPlanstat(idx)} />
                                </div> :
                                    <><div style={{ display: "flex", marginTop: "0px" }}>
                                        {/* <div className="services-content-title" style={{ color: '#3c3c3c' }}>{`Total Count : ${this.state.counts}`}</div> */}
                                    </div>
                                        {plans && plans.length === 0 ?
                                            <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                                                <img style={{ height: '180px' }} src={NoPlan} />
                                                <p style={{ marginTop: '10px' }}>No plan available</p>
                                            </div> :
                                            <table className="customers-table scroll-container" style={{ marginTop: '0px' }}>
                                                <col style={{ width: '40%' }} />
                                                <col style={{ width: '20%' }} />
                                                <col style={{ width: '30%' }} />
                                                <col style={{ width: '10%' }} />
                                                <tr className="customers-table-header-row">
                                                    <th>Plan Name
                                                        <SortComponent
                                                            style={{ marginLeft: '5px' }}
                                                            sortAscending={this.sortByName}
                                                            sortDecending={this.reverseByName}
                                                            sortASC={false}
                                                        />
                                                    </th>
                                                    <th>Tasks</th>
                                                    <th>Date Created
                                                        <SortComponent
                                                            style={{ marginLeft: '5px' }}
                                                            sortAscending={this.sortByDate}
                                                            sortDecending={this.reverseByDate}
                                                            sortASC={false}
                                                        />
                                                    </th>
                                                    <th>Images</th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    plans.map((plan) =>
                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td>
                                                                <Link
                                                                    style={{ color: "#707683" }}
                                                                    to={{
                                                                        pathname: `/dronenaksha/plan`,
                                                                        state: { user, type, plan, sidebarMode: this.state.sidebarMode, project }
                                                                    }}
                                                                >
                                                                    {plan.plan_name}<span style={{ color: "#2989cf" }}>{!this.state.isOwner ? ' (Shared)' : ''}</span>
                                                                </Link>
                                                            </td>
                                                            <td>{plan.tasks}</td>
                                                            <td>
                                                                {
                                                                    (() => {
                                                                        let date = (new Date(plan.date_and_time)).toGMTString().split(' ').slice(1, 4).join(' ');
                                                                        return `${date}`
                                                                    })()
                                                                }
                                                            </td>
                                                            <td>
                                                                {plan.images == null ? 0 : plan.images
                                                                }</td>
                                                            <td>
                                                                <DeleteComponentTable
                                                                    style={{ marginRight: '10px' }}
                                                                    onClickDelete={(e) => {
                                                                        e.preventDefault()
                                                                        this.state.permissions[DRONENAKSHA_FEATURES.PLANS].DELETE &&
                                                                            this.setState((s) => ({
                                                                                ...s,
                                                                                selectedPlanId: plan.id,
                                                                                itemName: "Plan",
                                                                                processPopupIcon: "WARNING",
                                                                                processAction: "DELETE",
                                                                                processMessage: null,
                                                                                showProcessPopup: true,
                                                                            }))
                                                                    }}
                                                                    disableDelete={!this.state.permissions[DRONENAKSHA_FEATURES.PLANS].DELETE}

                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                            </table>}
                                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }} >
                                            <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                                    Page : {currentpage}
                                                </div>
                                            </div>
                                            <div style={{ float: "right", marginTop: "0.5rem", display: "flex", alignItems: "baseline" }}>
                                                <ButtonWithIcon
                                                    text="First"
                                                    isBtnActive
                                                    isDisabled={this.state.currentpage == 1}
                                                    onClick={this.state.currentpage == 1 ? false : this.firstpage}
                                                />
                                                <ButtonWithIcon
                                                    text="Prev"
                                                    isDisabled={(this.state.currentpage == 1)}
                                                    onClick={(this.state.currentpage == 1) ? false : this.prevpage}
                                                />
                                                <form onSubmit={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                            Go to page :
                                                        </div>

                                                        <div className="add-user-form-text-wrapper">
                                                            <input type="text" className="add-user-form-text"
                                                                onFocus={(e) => e.target.select()}
                                                                style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                                onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                                onChange={(event) => {
                                                                    this.changepage(parseInt(event.target.value))
                                                                }} name="currentpage" value={currentpage} />
                                                        </div>
                                                    </div>
                                                </form>
                                                <ButtonWithIcon
                                                    text="Next"
                                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.nextpage}
                                                />
                                                <ButtonWithIcon
                                                    text="Last"
                                                    isBtnActive
                                                    isDisabled={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage))}
                                                    onClick={(this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage)) ? false : this.lastpage}
                                                />
                                            </div>
                                        </footer>
                                    </>}
                            </div>
                            <Planner
                                service={SERVICES.DRONENAKSHA}
                                atProjectLevel={true}
                                selectedProject={this.state.project}
                                location={this.props.location}
                                hasTeamsAccess={this.state.permissions[DRONENAKSHA_FEATURES.PLANS].CREATE}
                            />
                        </div>
                    }
                </div>

            </div> : <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
        activesubscription: (payload) => dispatch(activesubscription(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        SubscriptionData: state.subscription.subscriptionData,
        activesubscription: state.activesubscription.activesubscription,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        firstTimeLoginDN: state.profile.firstTimeLoginDN,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AeroCaptureProject);

