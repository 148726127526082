import React, { useEffect, useState } from 'react'
import propertySvg from "../../assets/icons/property.svg"
import closeIcon from '../../assets/icons/closeNew.svg'
import TabSwitcher from './TabSwitcher.js';
import AppConstants from '../../../AppConstants.js';
import DisplayField from './DisplayField.js';
import FileHolder from './FileHolder';
import * as API from "../../../api.js"
import { Popup } from '../../../ReusableComponents/reusableFunctions.js';
import NoFilesFallback from './NoFilesFallback.js';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';
import ExcelViewer from './ExcelViewer.js';

const BlockDetailsPopup = ({ onCloseCallback, block }) => {
    const [selectedTab, setSelectedTab] = useState(AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DETAILS)
    const [blockReport, setBlockReport] = useState([])
    const [blockFiles, setBlockFiles] = useState([])
    const [blockDUDetails, setBlockDUDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState({})

    const getUploadedFiles = (fileType) => {
        setLoading(true)
        API.getFiles(AppConstants.DRONELAB.ASSET_TYPES.BLOCK, fileType, selectedBlock.id)
            .then((res) => {
                if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT) {
                    setBlockReport(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE) {
                    setBlockFiles(res.assetFiles)
                }
                else if (fileType === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS) {
                    setBlockDUDetails(res.assetFiles)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                Popup.alert("ERROR", e.message || e)
            })
    }


    useEffect(() => {
        setSelectedBlock(block)
    }, [block])

    return (
        <div style={{ background: "white", borderRadius: "8px", width: '624px', height: '362px' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", padding: "25px" }}>
                    <div style={{ display: 'flex', alignItems: "center", gap: "20px" }}>
                        <img src={propertySvg} alt='block image' />
                        <div>
                            <div style={{ fontSize: "24px", fontWeight: "500", textAlign: 'left' }}>{block.name}</div>
                            <div style={{ color: "#666666", fontSize: "14px", fontWeight: "400", textAlign: 'left' }}>Block Number</div>
                        </div>
                    </div>
                    <img src={closeIcon} alt='close icon' style={{ cursor: "pointer" }} onClick={onCloseCallback} />
                </div>
                <TabSwitcher
                    tabs={[
                        {
                            title: 'Block Details',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DETAILS
                        },
                        {
                            title: 'Block Reports',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT
                        },
                        {
                            title: 'Block Files',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE
                        },
                        {
                            title: 'DU Details',
                            type: AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DU_DETAILS
                        },
                    ]}
                    switchCallback={(type) => {
                        setSelectedTab(type)
                        getUploadedFiles(type)
                    }}
                    selectedTab={selectedTab}
                />
                <hr style={{ margin: 0 }} />
                {selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_DETAILS ?
                    <div style={{ padding: "0px 25px 0px 25px", display: 'flex', flexDirection: "column", gap: '10px', height: "220px", overflow: "auto", margin: "25px 0px 25px 0px" }}>
                        <DisplayField label={"Property Name"} value={block.property_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                        <DisplayField label={"Name Of Colony"} value={block.name_of_colony} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                        <DisplayField label={"City"} value={block.city_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                        <DisplayField label={"City Area"} value={block.city_area_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                        <DisplayField label={"Division"} value={block.division_name} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                        <DisplayField label={"Information"} value={block.info} labelFontSize={"16px"} valueFontSize={"16px"} justify={"flex-start"} display={"flex"} gap={"10px"} />
                    </div> :
                    selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_REPORT ?
                        loading ?
                            <div style={{
                                width: "100%",
                                height: "57%",
                                padding: "0px 40px 40px 40px",
                                overflow: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <LoaderComponent />
                            </div> :
                            <div style={{
                                width: "100%",
                                height: blockReport.length === 0 ? "100%" : "53%",
                                padding: "30px 40px",
                                overflow: "auto",
                                display: blockReport.length === 0 ? "flex" : "grid",
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                alignItems: "center",
                                justifyContent: blockReport.length === 0 ? "center" : "space-between",
                                rowGap: "20px",

                            }}>
                                {blockReport.length === 0 ?
                                    <NoFilesFallback message={"The block does not have any reports yet !"} /> :
                                    blockReport.map((file) => (
                                        <FileHolder file={file} />
                                    ))}
                            </div>
                        :
                        selectedTab === AppConstants.DRONELAB.ASSET_FILE_TYPE.BLOCK.BLOCK_FILE ?
                            loading ?
                                <div style={{
                                    width: "100%",
                                    height: "57%",
                                    padding: "0px 40px 40px 40px",
                                    overflow: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    <LoaderComponent />
                                </div> :
                                <div style={{
                                    width: "100%",
                                    height: blockFiles.length === 0 ? "100%" : "53%",
                                    padding: "30px 40px",
                                    overflow: "auto",
                                    display: blockFiles.length === 0 ? "center" : "grid",
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    alignItems: "center",
                                    justifyContent: blockFiles.length === 0 ? "center" : "space-between",
                                    rowGap: "20px",

                                }}>
                                    {blockFiles.length === 0 ?
                                        <NoFilesFallback message={"The block does not have any files yet !"} /> :
                                        blockFiles.map((file) => (
                                            <FileHolder file={file} />
                                        ))}
                                </div> :
                            <div style={blockDUDetails[0]?.name.split('.')[1] === 'jpg' || 'pdf' ? {
                                width: "100%",
                                height: blockDUDetails.length === 0 ? "100%" : "48%",
                                padding: "25px 40px 0px",
                                overflow: "auto",
                                margin: "30px 0px",
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: "center"
                            } : {
                                width: "100%",
                                height: blockDUDetails.length === 0 ? "100%" : "48%",
                                padding: "25px 40px 0px",
                                overflow: "auto",
                                margin: "30px 0px"
                            }}>
                                {blockDUDetails.length === 0 ?
                                    <NoFilesFallback message={"The block does not have any block details yet!"} /> :
                                    blockDUDetails[0].name.split('.')[1] === 'jpg' || blockDUDetails[0].name.split('.')[1] === 'pdf' ?
                                        blockDUDetails.map((file) => (
                                            <FileHolder file={file} />
                                        )) :
                                        <ExcelViewer url={blockDUDetails[0].blob_url} />
                                }
                            </div>
                }
            </div>
        </div>
    );
}

export default BlockDetailsPopup